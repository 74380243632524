import React, { Component } from 'react'
import { Modal, Card, Button,Row,Col } from "react-bootstrap";
import "./_subscribe-plans.scss";

import { withRouter, Link } from "react-router-dom";
import * as Cookies from "es-cookie";
import courseRestriction from "../../../images/course_restriction.png";

class VideoPopupSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
           

        }
      
    }
    
    render() {
       

        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
            <Button variant="link p-0 close" onClick={() => { this.props.onHide() }}> <i className="fas fa-times" /> </Button>
                <Modal.Body className="">
                    <Card>
                        <h6 className='font-weight-bold'>{this.props.videoTitle_data} : </h6>
                        <iframe className='video_block_overview'
                            src={`https://www.youtube.com/embed/${this.props.videoUrl_data}`}
                            allowFullScreen
                        >
                        </iframe>
                    </Card>
                    
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default withRouter((VideoPopupSection));
  

