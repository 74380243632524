import React, { Component } from 'react'
import { Modal, Card, Button, Row, Col, Media, Image, Container } from "react-bootstrap";
import "./_subscribe-plans.scss";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter, Link } from "react-router-dom";
import * as Cookies from "es-cookie";
import self_study from '../../../images/self_study.png';
import ipe_lec from '../../../images/ipe_lec.png';
import courseRestriction from "../../../images/course_restriction.png";
import LongtermBell from '../../../images/lt_bell.png'

const FETCH_PLANS = gql`
query($mobile:String){
        getSubscriptionPlans(mobile:$mobile) {
            id
            name
            exam_id
            student_plan_id
            order_by
            image_path
            description
            studentPlans{
                id
                plan_name
                plan_title
                amount
                exam_id
                original_amount
                valid_days
                image
                additional_text
                plan_features
                popup_plan_desc
                color
                sort_order
                coupon_display
                url
                expiry_date
                upgrade_access
                upgrade_amount
                upgrade_description
          }
        }
}
`

class EamcetRestrictionAlert extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subscribeTrending: false,
            selectedPlanDetailsShow: '',
            course_currentPlanId: 0

        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));

        }
    }

    paymentOrderFun = (data) => {
        this.props.history.push({
            pathname: "/student/subscribe-plans-order-summary",
            state: {
                data: data

            }
        })
    }

    paymentFun = (activePlan) => {

        this.props.history.push({
            pathname: "/student/plans_section",
            state: {
                type: "subscribe",
                //oneweekplan:this.props.oneweekplan
                activePlan: activePlan

            }
        })

    }



    render() {
        const getSubscriptionPlans = this.props.getSubscriptionPlans;
        const loading5 = getSubscriptionPlans.loading;
        const error5 = getSubscriptionPlans.error

        if (error5 !== undefined) {
            alert("Server Error. " + error5.message);
            return null;
        }

        return (
                (this.props.planDetailsShow != undefined && this.props.planDetailsShow == "lt_course" && this.props.getAccessSubjectNames.length == 0) || (this.props.buildType == "lt_course") ?
                    (
                        <Modal {...this.props}
                            size="md" aria-labelledby="example-modal-sizes-title-lg" className="lt_subscribe_popup">
                            <Button variant="link p-0 close" onClick={() => { this.props.onHide() }}> <i className="fas fa-times" /> </Button>
                            <Modal.Body className="">
                                <Card>
                                    {
                                        loading5 == true ?
                                            (
                                                <Container>
                                                    <Row className="my-3">
                                                        <Col>
                                                            <div class="spinner-border text-primary text-center loading-img-center"></div>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            )
                                            :
                                            (
                                                getSubscriptionPlans != undefined && getSubscriptionPlans.getSubscriptionPlans.map(plans_data => (
                                                    plans_data.studentPlans.filter(item => item.id == 65).map(plans => {
                                                        console.log("plans", plans)
                                                        return (
                                                            <>
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        <img src={LongtermBell} alt="long-term" className='lt_bell' />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        <Row>
                                                                            <Col sm={12}>
                                                                                <p className='lt_pop_text'>HEY! A QUICK <span className='lt_pop_yellow'>REMINDER</span></p>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm={12}>
                                                                                <p className='lt_pop_des'>Looks like you decided to go for our free trial, but remember you can only enjoy limited number of features. So Explore our <span className='lt_bold'>SUBSCRIPTION</span> plans and prepare to the highest quality</p>

                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm={12}>
                                                                                <p className='lt_price'>{plans.amount}/-</p>
                                                                            </Col>
                                                                        </Row>
                                          				<Row>
                                                                        <Col sm={12}>
                                                                            {
                                                                                plans.upgrade_access == true ?
                                                                                    (<p className='text-center lt_upgrage font-weight-bold blink_me'> You can upgrade to this plan with <span className='upgrade_amount'>{plans.upgrade_amount + " /-" }</span> only</p>                                                                                    )
                                                                                    :
                                                                                    ("")
                                                                            }

                                                                        </Col>
                                                                    </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <Button onClick={() => this.paymentOrderFun(plans)} className="mt-2 lt_subscribe">SUBSCRIBE</Button>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <p className='mt-2 lt_info'>Do you Need More Information on Plans</p>
                                                                                <p className='mt-1 lt_contact'><span className='color_orange'>Contact </span> +91 7075448813</p>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        )
                                                    })
                                                ))
                                            )
                                    }
                                </Card>
                            </Modal.Body>
                        </Modal>
                    )
                    :
                    this.props.getAccessSubjectNames != undefined && this.props.getAccessSubjectNames.length > 0 ?
                        (
                            <Modal {...this.props}
                                size="md" aria-labelledby="example-modal-sizes-title-lg" className="scribePlan-modal-courses">
                                <Button variant="link p-0 close" onClick={() => { this.props.onHide() }}> <i className="fas fa-times" /> </Button>
                                <Modal.Body className="">

                                    <Card>
                                        <Card.Img variant="top" src={courseRestriction} />
                                        <div className='caret'></div>
                                        <Card.Body>
                                            <Card.Text>
                                                Hi, You haven't subscribed to this subject classes. Please upgrade your current subscription plans to join these classes. Contact us now at <b>+91 7075448813</b> to upgrade your subscription
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>

                                </Modal.Body>
                            </Modal>
                        )
                        :
                        this.props.buildType != undefined && this.props.buildType == "college_predictor" ?
                            (
                                <Modal {...this.props}
                                    size="md" aria-labelledby="example-modal-sizes-title-lg" className="eamcet-scribePlan-modal-courses">
                                    <Button variant="link p-0 close" onClick={() => { this.props.onHide() }}> <i className="fa fa-times" /> </Button>
                                    {
                                        loading5 == true ?
                                            (
                                                <Container>
                                                    <Row className="my-3">
                                                        <Col>
                                                            <div class="spinner-border text-primary text-center loading-img-center"></div>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            )
                                            :
                                            (
                                                getSubscriptionPlans != undefined && getSubscriptionPlans.getSubscriptionPlans.map(plans_data => (
                                                    plans_data.studentPlans.filter(item => item.id == 61).map(plans => {
                                                        let splitArr = plans.url.split('/');
                                                        let embdUrl = splitArr[splitArr.length - 1];
                                                        return (
                                                            <>
                                                                <h4 className='color_orange text-center'>Subscribe</h4>
                                                                <h6 className="text-center font-weight-normal mt-2 color_4444">{plans.popup_plan_desc}</h6>
                                                                <Row className='justify-content-center'>
                                                                    <Col sm={12} className="mt-2">
                                                                        <h2 className="text-center mt-2">
                                                                            <span className=""><i class="fas fa-rupee-sign f-22"></i> {plans.amount}/-</span>
                                                                        </h2>
                                                                        <Button onClick={() => this.paymentOrderFun(plans)} variant="outline-warning" className="mt-2 center-auto"  >Subscribe Now</Button>
                                                                        <p className="text-center font-weight-bold mt-2">{plans.additional_text}</p>
                                                                        <h6 className="text-center font-weight-normal mt-2 color_4444">Watch the video and know how our college and branch predictor helps you pick the right college and branch.</h6>
                                                                        <iframe className="embed-responsive-item mt-2 iframeWidth_popup" src={`https://www.youtube.com/embed/${embdUrl}`} title='video' allowFullScreen ></iframe>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <p className='mt-4 text-center f-22'>Do you Need More Information on Plans</p>
                                                                        <p className='mt-2 text-center f-22'><span className='color_orange'>Contact </span> +91 7075448813</p>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        )
                                                    })
                                                ))
                                            )
                                    }
                                </Modal>
                            )
                            :
                            (
                                <Modal {...this.props}
                                    size="max_width_eamcet_popup" aria-labelledby="example-modal-sizes-title-lg" className="eamcet-scribePlan-modal-courses">
                                    <Button variant="link p-0 close" onClick={() => { this.props.onHide() }}> <i className="fas fa-times" /> </Button>

                                    <Modal.Body className="p-2">
                                        <div className="text-center mb-4">

                                            <h4 className="mb-1 text-blue text-uppercase font-weight-bold" >Subscribe</h4>
                                            <h6 className="font-weight-normal text-blue text-uppercase">Unlock &amp; Get Ready for exam</h6>

                                        </div>
                                        {loading5 ? (
                                            <Card>
                                                <div className="d-flex justify-content-center">
                                                    <div class="spinner-border text-primary"></div>
                                                </div>
                                            </Card>
                                        ) : (<React.Fragment>
                                            {
                                                getSubscriptionPlans.getSubscriptionPlans != undefined ?
                                                    (
                                                        getSubscriptionPlans.getSubscriptionPlans.sort((a, b) => b.id - a.id).map(mainPlan => (
                                                            mainPlan.studentPlans.map((subplan, index) => {
                                                                let colorname = "";
                                                                if (subplan.id == "44") {
                                                                    colorname = "eamcet_self_study";
                                                                }
                                                                else if (subplan.id == "65") {
                                                                    colorname = "eamcet_self_study_PREMIUM";
                                                                }
                                                                else if (subplan.id == "61") {
                                                                    colorname = "branch_predictor";
                                                                }
                                                                else if (subplan.id == "63") {
                                                                    colorname = "foundation";
                                                                }
                                                                else if (subplan.id == "66") {
                                                                    colorname = "foundation";
                                                                }
                                                                else if (subplan.id == "62") {
                                                                    colorname = "eamcet_self_study_PREMIUM";
                                                                }
                                                                else if (subplan.id == "64") {
                                                                    colorname = "eamcet_self_study";
                                                                }
                                                                else if (subplan.id == "56") {
                                                                    colorname = "foundation";
                                                                }
                                                                else {
                                                                    colorname = "eamcet_self_study";
                                                                }
                                                                let features = subplan.popup_plan_desc.split("#");
                                                                return (
                                                                    <Card style={{ cursor: "pointer" }} onClick={() => this.paymentFun(mainPlan.id)} key={index} as={Card.Body} className={`subscribe-eamcet-plans pl-4 py-2 ${colorname}`}>
                                                                        {subplan.id == "63" || subplan.id == "66" ? (<div className="subscribe-lable text-uppercase">Trending</div>) : ("")}
                                                                        <div className="subscribe-content d-flex justify-content-between align-items-start">
                                                                            <div className="content-left">
                                                                                <h6 className="text-dark text-capitalize mt-2">{subplan.plan_name}
                                                                                    {
                                                                                        subplan.plan_title == "" ?
                                                                                            ("") :
                                                                                            (<span className="text-capitalize text_color_cls"> - {subplan.plan_title} Plan</span>)
                                                                                    }
                                                                                </h6>
                                                                                <ul className='plan_features_desc'>
                                                                                    {subplan.popup_plan_desc != "" ? (
                                                                                        <React.Fragment>
                                                                                            {features.map((imap, index) => {
                                                                                                return (
                                                                                                    imap != null ? (<li>{imap}</li>) : ("")
                                                                                                )
                                                                                            })}
                                                                                        </React.Fragment>

                                                                                    ) : ("")}
                                                                                </ul>
                                                                            </div>
                                                                            <div className="content-right">
                                                                                <div className="price-block shadow-sm p-2">
                                                                                    <h6 className="mb-0"><i className="fas fa-rupee-sign text-white"></i> </h6>
                                                                                    <div className="text-right">
                                                                                        <div className="plan-price text-white f-22 font-weight-bold">
                                                                                            {
                                                                                                subplan.original_amount == subplan.amount ?

                                                                                                    (<><span className="f-22">{subplan.amount}/-</span></>)
                                                                                                    :
                                                                                                    (
                                                                                                        <h6 className='font-weight-normal'>
                                                                                                            <span class="color_strike"> {subplan.original_amount}/-</span>
                                                                                                            <span className="ml-2 f-22"> {subplan.amount}/-</span>
                                                                                                        </h6>
                                                                                                    )
                                                                                            }
                                                                                        </div>
                                                                                        <small className="only_text">only</small>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            subplan.upgrade_access == true && subplan.upgrade_amount != subplan.amount ?
                                                                                (
                                                                                    <p className='text-center  text_color_cls font-weight-bold blink_me'> Upgrade to this plan with {subplan.upgrade_amount + " /-" + "only"}</p>
                                                                                )
                                                                                :
                                                                                ("")
                                                                        }
                                                                    </Card>
                                                                )
                                                            })
                                                        ))
                                                    )
                                                    :
                                                    ("")
                                            }
                                        </React.Fragment>)}
                                    </Modal.Body>
                                </Modal>
                            )


        )
    }
}
export default withRouter(compose(
    graphql(FETCH_PLANS,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: 'network-only'
            }), name: "getSubscriptionPlans"
        })
)(EamcetRestrictionAlert));
