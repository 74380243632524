import React, { Component } from 'react'
import { Row, Col, Card } from 'react-bootstrap';
import './_home.scss';
import ErrorSkippedModal from "./ErrorSkippedModal";
class ErrorSkippedModalSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
            modalType: "",
        }
    }
    modalShowFun = (type) => {
        console.log("modalShowFun");
        this.setState({
            modalType: type,
            modalShow: true
        });

    }
    render() {

        return (
            <React.Fragment>
                <Row className="question-type">
                    <Col xl={4} lg={4} md={6} sm={12} className="my-2">
                        <Card className="single-block h-100 flex-row justify-content-between align-items-center p-3">
                            <div className="d-flex align-items-center">
                                <a> <i onClick={(e) => this.modalShowFun("skipped")} className="fal fa-plus-square mr-3" /></a>

                                <h6 className="mb-0">Skipped Questions</h6>
                            </div>
                            <h6 className="mb-0">{this.props.dashBoardData.skipped_questions}%</h6>
                        </Card>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} className="my-2">
                        <Card className="single-block h-100 flex-row justify-content-between align-items-center p-3">
                            <div className="d-flex align-items-center">
                                <a> <i onClick={(e) => this.modalShowFun("error")} className="fal fa-plus-square mr-3" /></a>
                                <h6 className="mb-0">Error Questions</h6>
                            </div>
                            <h6 className="mb-0">{this.props.dashBoardData.error_questions}%</h6>
                        </Card>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} className="my-2">
                        <Card className="single-block h-100 flex-row justify-content-between align-items-center p-3">
                            <div className="d-flex align-items-center">
                                <a><i onClick={(e) => this.modalShowFun("errorskipped")} className="fal fa-plus-square mr-3" /></a>
                                <h6 className="mb-0">Most Error and Skipped Questions</h6>
                            </div>
                            <h6 className="mb-0">{this.props.dashBoardData.most_error_questions}%</h6>
                        </Card>
                    </Col>
                </Row>
                <ErrorSkippedModal
                    geterrorskippeddata={this.props.geterrorskippeddata}
                    data={this.state.modalType}
                    show={this.state.modalShow}
                    onHide={() => this.setState({ modalShow: false })}
                />
            </React.Fragment>
        );



    }
}

export default (ErrorSkippedModalSection);
