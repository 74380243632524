import React, { Component } from 'react'
import * as Cookies from "es-cookie";
import AsideNavbar from '../components/navbars/AsideNavbar'
import { withRouter } from "react-router-dom";
import ReferFriendSecton from '../components/referFriend/ReferFriendSecton'
import { Container, Row, Col, Image } from 'react-bootstrap'

import Navbars from '../components/navbars/Navbars'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar';

// const FETCH_REFERAL_DATA = gql`
// query($referred_user: String!){
//     getReferralTotalData(referred_user:$referred_user){
//         referred_user
//          total_days
//          total_points
//          claimed_days
//          claimed_points
//          remaining_days
//          remaining_points
//        }  
// }
// `;

// const REWARDS_TYPE_DISPLAY = gql`
// query($id :Int!){
//     getGlobalType(id: $id) {
//         global_data {
//           value
//         }
//       }
// }
// `

const GET_REWARDS_DETAILS = gql`
query($id :Int){
    getReferralStaticData(id:$id){
        no_of_days
        referral_points
        no_of_points
        converted_days

      }
}
`
// const LOGIN_USER = gql`
//   mutation($username: String!, $token: String) {
//     studentLogin(username: $username, token: $token) {
//         token
//         refreshToken
//         user{
//             name
//             email
//             mobile
//             valid
//             class_id
//             exam_id
//             current_plan_id
//             mobile_verified
//             target_year
//             videos
//             branch_name
//             profile_pic
//             userlevel
            
            
//         }
//     }
//   }
// `;

const GET_CLAIMED_HISTORY = gql`
query($referred_user : String!){
    getReferralClaimedPoints(referred_user:$referred_user){
        referred_user
        claimed_days
        claimed_points
        claimed_time
        plan_expiry_time
        no_of_days
        name
      }
}
`

const GET_STUDENT_DATA = gql`
query($referred_user : String!){
    getTotalReferrals(referred_user:$referred_user){
        name
        timestamp
      }
}
`

class ReferFriend extends Component {
    constructor(props) {
        super(props)

        this.state = {
            headerBottomImg: {
                Img: require('../../images/Vieos Image.png'),
                Title: "Refer A Friend",
                width: 100,
                helpImg: require('../../images/Ask_me_for_Help.gif'),
                pageType: "ReferFriend",
                type:"referfriend"
            },
            toggled: isMobile == true && Cookies.get("institution_id") == 30 ? "wrapper" : Cookies.get("toggle"),
            loader: 0,
            submitError: ""
        }
    }

    menuToggler = () => {
        const toggled = this.state.toggled;
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };


    // handleFormSubmit = e => {
    //     // e.preventDefault();
    //     this.setState({
    //         loader: 1
    //     });
    //     console.log("Form submitted");
    //     console.log(this.state);
    //     console.log("autologin", Cookies.get("mobile"), Cookies.get("studenttoken"));
    //     if (Cookies.get("mobile") && Cookies.get("studenttoken")) {
    //         this.login(Cookies.get("mobile"), Cookies.get("studenttoken")).catch(error => {
    //             console.log("catch if error");
    //             console.log(error);
    //             this.setState({
    //                 submitError: error.graphQLErrors.map(x => x.message), loader: 0
    //             });
    //             console.error("ERR =>", error.graphQLErrors.map(x => x.message));
    //         });
    //     } else {
    //         this.setState({ submitError: "Please fill all the values to proceed", loader: 0 });
    //     }



    // };
    // login = async (username, token) => {
    //     await this.props.login({
    //         variables: {
    //             username,
    //             token
    //         },
    //         update: (store, { data }) => {
    //             console.log("datadata", data)
    //             console.log("data.studentLogin.user.branch_name", data.studentLogin.user);
    //             Cookies.set("studenttoken", data.studentLogin.token);
    //             Cookies.set("studentrefreshtoken", data.studentLogin.refreshToken);
    //             Cookies.set("studentusername", data.studentLogin.user.name);
    //             Cookies.set("studentemail", data.studentLogin.user.email);
    //             Cookies.set("mobile", data.studentLogin.user.mobile);
    //             Cookies.set("classid", data.studentLogin.user.class_id);
    //             Cookies.set("examid", data.studentLogin.user.exam_id);
    //             Cookies.set("mobileverified", data.studentLogin.user.mobile_verified);
    //             Cookies.set("targetyear", data.studentLogin.user.target_year);
    //             Cookies.set("videos", data.studentLogin.user.videos);
    //             Cookies.set("branch_name", data.studentLogin.user.branch_name);
    //             Cookies.set("role", "student");
    //             Cookies.set("profile_pic", data.studentLogin.user.profile_pic);
    //             Cookies.set("student_userlevel", data.studentLogin.user.userlevel);

    //             localStorage.setItem("profile_pic", data.studentLogin.user.profile_pic);
    //             localStorage.setItem("packageplan", "referFriend")


    //             if (data.studentLogin) {
    //                 this.setState({ loader: 0 });
    //                 this.props.history.push("/student/loading");
    //             }
    //         }
    //     });
    // };

    render() {
        // const getReferralTotalData = this.props.getReferralTotalData;
        // const loading5 = getReferralTotalData.loading;
        // const error5 = getReferralTotalData.error;

        // const getGlobalType = this.props.getGlobalType
        // const loading7 = getGlobalType.loading;
        // const error7 = getGlobalType.error;

        const getReferralStaticData = this.props.getReferralStaticData;
        const loading6 = getReferralStaticData.loading;
        const error6 = getReferralStaticData.error;


        const getReferralClaimedPoints = this.props.getReferralClaimedPoints;
        const loading8 = getReferralClaimedPoints.loading;
        const error8 = getReferralClaimedPoints.error;


        const getTotalReferrals = this.props.getTotalReferrals;
        const loading9 = getTotalReferrals.loading;
        const error9 = getTotalReferrals.error;


        if (error9 !== undefined) {
            alert("Server Error. " + error9.message);
            return null;
        }
        if (error8 !== undefined) {
            alert("Server Error. " + error8.message);
            return null;
        }

        if (error6 !== undefined) {
            alert("Server Error. " + error6.message);
            return null;
        }

        // if (error5 !== undefined) {
        //     alert("Server Error. " + error5.message);
        //     return null;
        // }
        // if (error7 !== undefined) {
        //     alert("Server Error. " + error7.message);
        //     return null;
        // }

        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        return (
            <div className={this.state.toggled}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} pageType={"ReferFriend"} />
                </div>
                <div className="main-wrapper bg-white">
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0 customCategoryVideos" style={{ minHeight: "50vh" }}>
                        {
                            loading6 || loading8 || loading9 ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <ReferFriendSecton
                                        // getReferralTotalData={getReferralTotalData.getReferralTotalData}
                                        // getGlobalType={getGlobalType.getGlobalType}
                                        // handleFormSubmit={this.handleFormSubmit}
                                        getReferralStaticData={getReferralStaticData.getReferralStaticData}
                                        getReferralClaimedPoints={getReferralClaimedPoints.getReferralClaimedPoints}
                                        getTotalReferrals={getTotalReferrals.getTotalReferrals}
                                    />
                                )
                        }

                    </div>
                </div>
            </div>

        )

    }


}

export default withRouter(compose(
    // graphql(FETCH_REFERAL_DATA,
    //     {
    //         options: props => ({
    //             variables: {
    //                 referred_user: Cookies.get("mobile")
    //             }
    //             ,
    //             fetchPolicy: "cache-and-network"
    //         }), name: "getReferralTotalData"
    //     }),

    // graphql(REWARDS_TYPE_DISPLAY,
    //     {
    //         options: props => ({
    //             variables: {
    //                 id: 3
    //             }
    //             ,
    //             fetchPolicy: "cache-and-network"
    //         }), name: "getGlobalType"
    //     }),
    graphql(GET_STUDENT_DATA,
        {
            options: props => ({
                variables: {
                    referred_user: Cookies.get("mobile")
                }
                ,
                fetchPolicy: "cache-and-network"
            }), name: "getTotalReferrals"
        }),
    graphql(GET_CLAIMED_HISTORY,
        {
            options: props => ({
                variables: {
                    referred_user: Cookies.get("mobile")
                }
                ,
                fetchPolicy: "cache-and-network"
            }), name: "getReferralClaimedPoints"
        }),
    graphql(GET_REWARDS_DETAILS,
        {
            options: props => ({
                variables: {
                    id: 0
                }
                ,
                fetchPolicy: "cache-and-network"
            }), name: "getReferralStaticData"
        }),

    // graphql(LOGIN_USER, {
    //     name: "login"
    // })
)(ReferFriend));