import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import EamcetPreviousPapersSection from '../components/eamcetPreviousPapers/EamcetPreviousPapersSection';
import * as Cookies from "es-cookie";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';

import { withRouter } from "react-router-dom";
import { Container, Row, Col, Image } from 'react-bootstrap'

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';


const FETCH_GLOBALS = gql`
  query($mobile: String) {
    studentGlobals(mobile: $mobile) {
        previousSets {
            year
            qset
            id
            exam
            pexamtype
            enabled
            attempted
            examgroup
            examsessionid
            is_started
            start_session_id
            exam_duration_min
          }
    }
  }
`;

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;
class EamcetPreviousPapers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerBottomImg: {
                type: "eamcet_view",
                title: "PREVIOUS YEAR TEST"
            },
            toggled: "wrapper sidebar-enable",
             submitError:""


        }

    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    componentDidMount = () => {
        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Eamcet_Previous_Papers;
        ReactGA.pageview('/student/StatePreviousPapers', ["rizee"], title);
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 117,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    render() {
        const studentGlobals = this.props.studentGlobalsPreviousPapers;
        const loading1 = studentGlobals.loading;
        const error1 = studentGlobals.error;
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars
                        headerBottom={this.state.headerBottomImg}
                        onClick={() => this.menuToggler()}
                    />                </div>
                <div className="main-wrapper">
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper" style={{ paddingTop: 10, minHeight: "80vh", backgroundColor: "#f6f7fb" }}>
                        {
                            loading1 ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <EamcetPreviousPapersSection
                                        studentGlobals={studentGlobals.studentGlobals}
                                    />
                                )
                        }



                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(

    graphql(FETCH_GLOBALS, {
        options: props => ({
            variables: {
                mobile: Cookies.get("mobile"),
            },
            fetchPolicy: 'network-only'
        }), name: "studentGlobalsPreviousPapers"
    }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
)(EamcetPreviousPapers));


