import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import BookmarkHeaderSection from '../components/bookmarks/bookmark_videos/BookmarkHeaderSection'
import BookmarkShortnotesMaterialsSection from '../components/bookmarks/bookmark_shortnotes_materials/BookmarkShortnotesMaterialsSection';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as Cookies from "es-cookie";
import * as compose from 'lodash.flowright';
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import { withRouter } from 'react-router';
const FETCH_GLOBALS = gql` 
query($mobile: String!) {
    studentGlobals(mobile: $mobile){
        tags{
            id
            tag
            type
            count
        }
        contentTypes{
            id
            customcontent
        }
    }
}

`;

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;


class BookmarkShortnotesMaterials extends Component {
    constructor(props) {
        super(props)
        let tagid = "";
        if (props.history.location.state.tagid != "") {
            tagid = props.history.location.state.tagid;
        }

        let subjectid = 0;
        if (props.history.location.state.subjectid != "") {
            subjectid = props.history.location.state.subjectid;
        }
        console.log("tagid", tagid);
        let defaultActiveKey = "first";
        console.log("props.history.location.state.defaultActiveKey", props.history.location.state.defaultActiveKey);
        if (props.history.location.state.defaultActiveKey != undefined) {
            defaultActiveKey = props.history.location.state.defaultActiveKey;
        }

        this.state = {
            toggled: "wrapper sidebar-enable",
            subjectId: subjectid,
            chapterId: 0,
            tags: tagid,
            currentStep: 1,
            defaultActiveKey: defaultActiveKey,
            submitError:""

        }

    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
         if (toggled === "wrapper") {
             this.setState({toggled:"wrapper sidebar-enable"});
             Cookies.set("toggle", "wrapper sidebar-enable");
         } else {
             this.setState({toggled:"wrapper"});
             Cookies.set("toggle", "wrapper");
         }
     };
    componentDidMount() {
        this.addStudentAnalytics();

        window.addEventListener('contextmenu',
            event => event.preventDefault());


        // window.addEventListener("mousedown", e => {
        //     e.preventDefault();
        //     e.returnValue = "false";

        // });
        window.addEventListener("selectstart", e => {
            e.preventDefault();
            e.returnValue = "false";

        });
    }
    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 85,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    componentWillUnmount() {

        window.removeEventListener('contextmenu', () => { });
        // window.removeEventListener("mousedown", () => { });
        window.removeEventListener("selectstart", () => { });

    }
    handleSelectInputChange = (name, value) => {
        console.log("handleSelectInputChange", "name", name, "value", value);
        this.setState({ [name]: value });
    }
    TaghandleSelectInputChange = e => {
        //console.log("TaghandleSelectInputChange", e);
        let tagsValues = Array();
        if (e != null) {
            for (let i = 0; i < e.length; i++) {
                const tagval = e[i];
                tagsValues.push(tagval.value);
            }
            this.setState({
                tags: tagsValues.toString()
            });
        }
        //console.log("tagsValues", tagsValues);

    }

    render() {
        console.log("BookmarkShortnotesMaterials", this.state);

        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        

        const studentGlobals = this.props.studentGlobals;
        const loading3 = studentGlobals.loading;
        const error3 = studentGlobals.error;

       

        if (error3 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }
        let globalsubjects = "";
        if (JSON.parse(localStorage.getItem("globalsubjects")) != "") {
            globalsubjects = JSON.parse(localStorage.getItem("globalsubjects"));
        }
        else {
            this.props.history.push("/student/login");
        }
        //console.log("getStudentTags", getStudentTags.getStudentTags);

        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper">
                    {(loading3 == true) && (<PreloaderTwo />)}
                    <AsideNavbar onClick={() => this.menuToggler()}/>
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0">
                        {
                            !loading3 && (
                                <React.Fragment>
                                    <BookmarkHeaderSection
                                        type="bookmark"
                                        ctype="short"
                                        studentGlobals={studentGlobals.studentGlobals}
                                        getSubjects={globalsubjects}
                                        phandleSelectInputChange={this.handleSelectInputChange}
                                        pTaghandleSelectInputChange={this.TaghandleSelectInputChange}
                                        stateData={this.state}
                                        
                                    />
                                    <BookmarkShortnotesMaterialsSection
                                    
                                        getData={this.props.history.location.state}
                                        studentGlobals={studentGlobals.studentGlobals}
                                        getSubjects={globalsubjects}
                                        stateData={this.state}
                                        parentremovebookhandleFormSubmit={this.removebookhandleFormSubmit}
                                    />
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default
    withRouter( compose(

        graphql(FETCH_GLOBALS,
            {
                options: props => ({
                    variables: {
                        mobile: Cookies.get("mobile")
                    },
                    fetchPolicy: "cache-and-network"
                }), name: "studentGlobals"
            }),
            graphql(UPDATE_STUDENT_ANALYTICS, {
                name: "submitpt"
            }),
        

    )(BookmarkShortnotesMaterials));
