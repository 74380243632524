import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import { Row, Col, Card, Button, Link } from 'react-bootstrap'
import "./_syllabus.scss"
import AwordIcon from "../../../images/syllIcon.png"
import { withRouter } from "react-router-dom"

class SyllabusPdf extends React.Component {
    
    onSelect = (sub) => {
        return(
            <>
        {
        Cookies.get('examid') == 3 && sub == "maths" ?(window.open('https://www.rizee.in/syllabus/AP_ENG_MATHS.pdf','_blank')):("")
        }
        {
        Cookies.get('examid') == 6 && sub == "maths" ?(window.open('https://www.rizee.in/syllabus/TS_ENG_MATHS.pdf','_blank')):("")
         }
         {
        Cookies.get('examid') == 3 && sub == "phy" ?(window.open('https://www.rizee.in/syllabus/AP_ENG_PHYSICS.pdf','_blank')):("")
        }
        {
        Cookies.get('examid') == 6 && sub == "phy" ?(window.open('https://www.rizee.in/syllabus/TS_ENG_PHYSICS.pdf','_blank')):("")
         }
         {
        Cookies.get('examid') == 7 && sub == "phy" ?(window.open('https://www.rizee.in/syllabus/AP_AGRI_PHYSICS.pdf','_blank')):("")
        }
        {
        Cookies.get('examid') == 8 && sub == "phy" ?(window.open('https://www.rizee.in/syllabus/TS_AGRI_PHYSICS.pdf','_blank')):("")
         }
          {
        Cookies.get('examid') == 3 && sub == "chem" ?(window.open('https://www.rizee.in/syllabus/AP_ENG_CHEMISTRY.pdf','_blank')):("")
        }
        {
        Cookies.get('examid') == 6 && sub == "chem" ?(window.open('https://www.rizee.in/syllabus/TS_ENG_CHEMISTRY.pdf','_blank')):("")
         }
         {
        Cookies.get('examid') == 7 && sub == "chem" ?(window.open('https://www.rizee.in/syllabus/AP_AGRI_CHEMISTRY.pdf','_blank')):("")
        }
        {
        Cookies.get('examid') == 8 && sub == "chem" ?(window.open('https://www.rizee.in/syllabus/TS_AGRI_CHEMISTRY.pdf','_blank')):("")
         }
         {
        Cookies.get('examid') == 7 && sub == "btny" ?(window.open('https://www.rizee.in/syllabus/AP_AGRI_BOTANY.pdf','_blank')):("")
        }
        {
        Cookies.get('examid') == 8 && sub == "btny" ?(window.open('https://www.rizee.in/syllabus/TS_AGRI_BOTONY.pdf','_blank')):("")
         }
         {
        Cookies.get('examid') == 7 && sub == "zlgy" ?(window.open('https://www.rizee.in/syllabus/AP_AGRI_ZOOLOGY.pdf','_blank')):("")
        }
        {
        Cookies.get('examid') == 8 && sub == "zlgy" ?(window.open('https://www.rizee.in/syllabus/TS_AGRI_ZOOLOGY.pdf','_blank')):("")
         }

        </>)
      };
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    {Cookies.get('examid') == 3 || Cookies.get('examid') == 7 ?(<h2 className="head-pre ml-3">AP EAPCET Syllabus</h2>):("")}
                    {Cookies.get('examid') == 6 || Cookies.get('examid') == 8 ?(<h2 className=" head-pre ml-3">TS EAMCET Syllabus</h2>):("")}
                    <Col className="mt-3">
                        <Row className="justify-content-around ">
                            {
                                Cookies.get('examid') == 3 || Cookies.get('examid') == 6 ? (
                                    <Card className="award-main">
                                        <Card.Body>
                                            <Card.Img variant="top" src={AwordIcon} className="award-img" />
                                            <Card.Title className="award-title">Maths<br /> Syllabus</Card.Title>
                                            <Button variant="outline-" className="award-btn"onClick={() => {this.onSelect("maths")}} >Preview </Button>
                                        </Card.Body>
                                    </Card>
                                ) : ("")
                            }
                            <Card className="award-main">
                                <Card.Body>
                                    <Card.Img variant="top" src={AwordIcon} className="award-img" />
                                    <Card.Title className="award-title">Physics<br /> Syllabus</Card.Title>
                                    <Button variant="outline-" className="award-btn" onClick={() => {this.onSelect("phy")}}> 
                                        Preview
                                </Button>
                                </Card.Body>
                            </Card>
                            <Card className="award-main">
                                <Card.Body>
                                    <Card.Img variant="top" src={AwordIcon} className="award-img" />
                                    <Card.Title className="award-title">Chemistry <br />Syllabus</Card.Title>

                                    <Button variant="outline-" className="award-btn" onClick={() => {this.onSelect("chem")}}>
                                        Preview
                                </Button>
                                </Card.Body>
                            </Card>
                            {
                                Cookies.get('examid') == 3 || Cookies.get('examid') == 6 ? ("") : (
                                    <Card className="award-main">
                                        <Card.Body>
                                            <Card.Img variant="top" src={AwordIcon} className="award-img justify-content-end" />
                                            <Card.Title className="award-title">Botany<br /> Syllabus</Card.Title>
                                            <Button variant="outline-" className="award-btn" onClick={() => {this.onSelect("btny")}} >
                                                Preview
                                </Button>
                                        </Card.Body>
                                    </Card>
                                )}
                        </Row>

                        <Row className="zlgy ml-2">
                            {
                                Cookies.get('examid') == 3 || Cookies.get('examid') == 6 ? ("") : (

                                    <Card className="award-main mt-4 zlgy">
                                        <Card.Body>
                                            <Card.Img variant="top" src={AwordIcon} className="award-img" />
                                            <Card.Title className="award-title">Zoology <br />Syllabus</Card.Title>
                                            <Button variant="outline-" className="award-btn" onClick={() => {this.onSelect("zlgy")}}>
                                                Preview
                                </Button>
                                        </Card.Body>
                                    </Card>
                                )
                            }

                        </Row>
                    </Col>
                </div> 
            </React.Fragment>
        )
    }
}
export default SyllabusPdf;



