import { Modal, Image, Card, Button } from 'react-bootstrap'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import  '../../Neet_Crash_Course/_neetCrashCourse.scss';
import parse, { domToReact } from 'html-react-parser';

 class WatchNowVideosModal extends Component {

    render() {
        console.log("this.props.Data", this.props.data);
        return (
            
            <Modal {...this.props}
                size="lg" aria-labelledby="example-modal-sizes-title-lg" className="">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <iframe className="embed-responsive-item video_alignment" src={`https://www.youtube.com/embed/${this.props.data}`} title='video' allowFullScreen ></iframe>
                         {
                            this.props.videoDescription != undefined ? (
                                this.props.videoDescription.map((item) => {
                                    return (
                                        <>
                                            <p className='description mt-3'>{parse(item.description)}</p>
                                        </>
                                    )

                                    console.log("item", item);
                                }
                                )

                            ) : ("")
                        }
                </Modal.Body>
            </Modal>
        )
    }
}

export default WatchNowVideosModal
