import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table, Button, FormCheck } from 'react-bootstrap';
import * as Cookies from "es-cookie";
// import Switch from 'react-switch';
import PDF from 'react-pdf-js-infinite';
import Pdf from './../short_notes/pdf.js';
import SyllabusPdfTable from './../short_notes/syllabusPdfTable';

import { Url } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import "./switch.css";
import UserRestrictionAlertInstitute from '../../../student_module/components/home/UserRestrictionAlertInstitute'
import LockSimple from './LockSimple.png'

const TsMpcData = [


    ////// //TS MPC ENGINEERING PAPERS        

    {
        DATE: "11-SEP-2020 MORNING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/TS/EN/TSM20SEP11M.pdf"),
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/EN/TSM20SEP11M_KEY.pdf",

    },
    {
        DATE: "14-SEP-2020 MORNING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/TS/EN/TSM20SEP14M.pdf"),
        KEYSOLUTIONS: ("https://rizee.in/econtent/previous_papers/TS/EN/TSM20SEP14M_KEY.pdf"),

    },
    {
        DATE: "03-MAY-2019 EVENING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/TS/EN/TM193MAYSHT2.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/EN/TM193MAYSHT2_KEY.pdf",

    },
    {
        DATE: "04-MAY-2019 EVENING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/TS/EN/TM194MAYSHT2.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/EN/TM194MAYSHT2_KEY.pdf",

    },
    {
        DATE: "04-MAY-2018 MORNING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/TS/EN/TM18May4thSHT1.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/EN/TM18May4thSHT1_KEY.pdf",

    },
    {
        DATE: "05-MAY-2018 EVENING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/TS/EN/TM18May5thSHT2.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/EN/TM18May5thSHT2_KEY.pdf",

    },
    {
        DATE: "12-MAY-2017 MORNING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/TS/EN/TM17MAY12TH.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/EN/TM17MAY12TH_KEY.pdf",

    },
    {
        DATE: "15-MAY-2016 MORNING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/TS/EN/TM1615thmaySHT1.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/EN/TM1615thmaySHT1_KEY.pdf",

    }]
const TsAgData = [
    // // ///TS AGRICULTURE PAPERS
    {
        DATE: "28-SEP-2020 MORNING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/TS/AG/TSB20SEP28M.pdf"),
        KEYSOLUTIONS: ("https://rizee.in/econtent/previous_papers/TS/AG/TSB20SEP28M_KEY.pdf"),
    },
    {
        DATE: "29-SEP-2020 MORNING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/TS/AG/TSB20SEP29M.pdf"),
        KEYSOLUTIONS: ("https://rizee.in/econtent/previous_papers/TS/AG/TSB20SEP29M_KEY.pdf"),
    },
    {
        DATE: "08-MAY-2019 MORNING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/TS/AG/TB198MAYSHT2.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/AG/TB198MAYSHT2_KEY.pdf",
    },
    {
        DATE: "09-MAY-2019 MORNING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/TS/AG/TB199MAYSHT1.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/AG/TB199MAYSHT1_KEY.pdf",
    },
    {
        DATE: "02-MAY-2018 MORNING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/TS/AG/TB182ndMaySHT1.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/AG/TB182ndMaySHT1_KEY.pdf",
    },
    {
        DATE: "03-MAY-2018 MORNING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/TS/AG/TB183rdMaySHT_1.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/AG/TB183rdMaySHT_1_KEY.pdf",
    },
    {
        DATE: "12-MAY-2017 EVENING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/TS/AG/TB17May12thSHT1.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/AG/TB17May12thSHT1_KEY.pdf",
    },
    {
        DATE: "15-MAY-2016 EVENING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/TS/AG/TB1615thmaySHT2.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/TS/AG/TB1615thmaySHT2_KEY.pdf",
    }
]
const ApMpcData = [
    // /// /// AP ENGINEERING PAPERS
    {
        DATE: "21-SEP-2020 MORNING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/AP/EN/APM20SEP21M.pdf"),
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/EN/APM20SEP21M_KEY.pdf",
    },
    {
        DATE: "22-SEP-2020 EVENING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/AP/EN/APM20SEP22E.pdf"),
        KEYSOLUTIONS: ("https://rizee.in/econtent/previous_papers/AP/EN/APM20SEP22E_KEY.pdf"),
    },
    {
        DATE: "20-APR-2019 MORNING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/AP/EN/AM19EMAPR20M.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/EN/AM19EMAPR20M_KEY.pdf",
    },
    {
        DATE: "21-APR-2019 EVENING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/AP/EN/AM19EMAPR21E.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/EN/AM19EMAPR21E_KEY.pdf",
    },
    {
        DATE: "22-APR-2018 MORNING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/AP/EN/AM1818EMAPR22M.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/EN/AM1818EMAPR22M_KEY.pdf",
    },
    {
        DATE: "22-APR-2018 EVENING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/AP/EN/AM1818EMAPR22E.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/EN/AM1818EMAPR22E_KEY.pdf",
    },
    {
        DATE: "26-APR-2017 MORNING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/AP/EN/AM17APR26SHT1.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/EN/AM17APR26SHT1_KEY.pdf",
    },
    {
        DATE: "2-MAY-2016 MORNING",
        QUESTIONPAPER: "https://rizee.in/econtent/previous_papers/AP/EN/AM162ndmaySHT1.pdf",
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/EN/AM162ndmaySHT1_KEY.pdf",
    }
]


/////////AP AGRICULTURE PAPERS
const ApAgData = [
    
    {
        DATE: "23-SEP-2020 EVENING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/AP/AG/APB20SEP23E.pdf"),
        KEYSOLUTIONS: ("https://rizee.in/econtent/previous_papers/AP/AG/APB20SEP23E_KEY.pdf"),

    },
    {
        DATE: "24-SEP-2020 EVENING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/AP/AG/APB20SEP24E.pdf"),
        KEYSOLUTIONS: ("https://rizee.in/econtent/previous_papers/AP/AG/APB20SEP24E_KEY.pdf"),

    },
    {
        DATE: "23-APR-2019 EVENING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/AP/AG/AB19APR23SHT_2.pdf"),
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/AG/AB19APR23SHT_2_KEY.pdf",

    },
    {
        DATE: "24-APR-2019 EVENING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/AP/AG/AB19APR24SHT2.pdf"),
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/AG/AB19APR24SHT2_KEY.pdf",

    },
    {
        DATE: "25-APR-2018 MORNING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/AP/AG/AB18APR25SHT1.pdf"),
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/AG/AB18APR25SHT1_KEY.pdf",

    },
    {
        DATE: "25-APR-2018 EVENING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/AP/AG/AB18APR25SHT_2.pdf"),
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/AG/AB18APR25SHT_2_KEY.pdf",

    },
    {
        DATE: "28-APR-2017 MORNING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/AP/AG/AB17APR28thSHT1.pdf"),
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/AG/AB17APR28thSHT1_KEY.pdf",

    },
    {
        DATE: "15-MAY-2016 EVENING",
        QUESTIONPAPER: ("https://rizee.in/econtent/previous_papers/AP/AG/AB162ndmaySHT2.pdf"),
        KEYSOLUTIONS: "https://rizee.in/econtent/previous_papers/AP/AG/AB162ndmaySHT2_KEY.pdf",

    },

];

const Exam_Names_ID_Map = {
    "AP_ENG": 3,
    "TS_ENG": 6,
    "AP_AG": 7,
    "TS_AG": 8,
}

const ENG_IDS = ["3", "6"];

const AP_IDS = ["3", "7"];

var qValue;
var kValue;

class PreviousPapersModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAp: AP_IDS.includes(Cookies.get("examid")),
            userRestionModalShow: false,
		showPdf:false,
            pdfUrl:"",
        }
    }
    modalaFun = (props,info) => {
        console.log(props);
        let isStudentUserValid = ""
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        console.log("plan", isStudentUserValid.current_plan_id, this.props)
        this.setState({
            userRestionModalShow: true,
        });
        return(
            this.handlePdfView(info.QUESTIONPAPER,0)
           )
        /*if (Cookies.get("institution_id") == 30 && isStudentUserValid.current_plan_id != 24) {
          //  console.log(props.row.KEYSOLUTIONS);
            return(
                <>
            {console.log(props)}
             <a href={props} target="_blank" class="btn btn-success buttongreen"    ></a>
             </>
            );
        } else {
            this.setState({
                userRestionModalShow: true,
            });
        }*/

    }
    handlePdfView = (QUESTIONPAPER,val)=> {
        console.log(QUESTIONPAPER);
        if(val == 0){
            this.setState({
                showPdf: !this.state.showPdf,
                pdfUrl:QUESTIONPAPER
            });
        }else{
            this.setState({
                showPdf: false,
                pdfUrl:""
            });
        }
        
    }

    actionFormatter(cell, row, rowIndex) {
        console.log(row.QUESTIONPAPER);
        if (row.QUESTIONPAPER !== "Coming Soon") {
            qValue = "View Paper";
        } else {
            qValue = "Coming Soon";
        }
        return (
            <div className="d-flex justify-content-center align-items-center">
                <a  class="btn btn-primary buttonblue  " onClick={()=> this.handlePdfView(row.QUESTIONPAPER,0)}>{qValue}</a>
                <a href={row.QUESTIONPAPER} download className="ml-3 mt-1"><i class="fas fa-download"></i></a>
            </div>
            // <a href={row.QUESTIONPAPER} target="_blank" <Button variant="outline-primary">{qValue}</Button></a>
        )
    }


    actionFormatterSol(cell, row, rowIndex) {

        if (row.KEYSOLUTIONS !== "Coming Soon") {
            kValue = "View Solution";
        } else {
            kValue = "Coming Soon";
        }
        let isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"))
            console.log(row)
            console.log(Cookies.get("institution_id"))
            console.log(isStudentUserValid.current_plan_id)
            return (
                <div className="d-flex justify-content-center align-items-center">
                    <a onClick={()=> this.handlePdfView(row.KEYSOLUTIONS,0)} class="btn btn-success buttongreen" >{kValue}</a>
                    <a href={row.KEYSOLUTIONS} download className="ml-3 mt-1"> <i class="fas fa-download"></i></a>
                </div>
            )
    }

    //    examIDVerify=Cookies.get("examId");
    //   if(examIDVerify == 3){
    //       paperData = ApMpcData;
    //   };

    colFunction() {

        let columns = [
            {
                dataField: 'DATE',
                text: 'DATE',
                style:  {
                    fontWeight: "bold !important",
                    color:"Black",
                },
            },
            {
                dataField: 'QUESTIONPAPER',
                text: 'QUESTION PAPER',
                formatter: this.actionFormatter.bind(this)
                //url:"https://rizee.in/econtent/previous_papers/AP/AG/28_sep_2020_M_Bipc.pdf",
            },
            {
                dataField: 'KEYSOLUTIONS',
                text: 'KEY/SOLUTIONS',
                formatter: this.actionFormatterSol.bind(this)
                // url:"https://rizee.in/econtent/previous_papers/AP/AG/28_sep_2020_M_Bipc.pdf"
            }

        ];
        return columns;
    }

    render() {
        return (
            <>
             <div>
            {
                !this.state.showPdf ?
                (
                    <>
                <Row>
                <h2 className="pre-head ml-3">Previous Year Papers</h2>
                    <Col align="right">
                        <label style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center"
                        }}>
                            <p style={{
                                marginRight: "20px"
                                 
                            }}>{ENG_IDS.includes(Cookies.get("examid")) ? "AP EAPCET" : "AP EAPCET"}</p>
                            <label class="switch">
                                <input type="checkbox"
                                    onChange={() => {
                                        this.setState((state) => {
                                            return {
                                                isAp: !state.isAp
                                            }
                                        })
                                    }}
                                    checked={!this.state.isAp} />
                                <span class="slider round"></span>
                            </label>
                            <p style={{
                                marginLeft: "20px"
                            }}>{ENG_IDS.includes(Cookies.get("examid")) ? "TS EAMCET" : "TS EAMCET"}</p>
                        </label>
                    </Col>
                </Row>



                <Row>
                    <Col>
                        {
                            ENG_IDS.includes(Cookies.get("examid"))
                                ? this.state.isAp
                                    ? <Card>
                                        <BootstrapTable
                                            keyField="id"
                                            columns={this.colFunction()}
                                            data={ApMpcData}
                                            defaultSorted={true}
                                            wrapperClasses="table-responsive"
                                            bootstrap4
                                        />
                                    </Card>
                                    : <Card>
                                        <BootstrapTable
                                            keyField="id"
                                            columns={this.colFunction()}
                                            data={TsMpcData}
                                            defaultSorted={true}
                                            wrapperClasses="table-responsive"
                                            bootstrap4
                                        />
                                    </Card>
                                : this.state.isAp
                                    ? <Card>
                                        <BootstrapTable
                                            keyField="id"
                                            columns={this.colFunction()}
                                            data={ApAgData}
                                            defaultSorted={true}
                                            wrapperClasses="table-responsive"
                                            bootstrap4
                                        />
                                    </Card>
                                    : <Card>
                                        <BootstrapTable
                                            keyField="id"
                                            columns={this.colFunction()}
                                            data={TsAgData}
                                            defaultSorted={true}
                                            wrapperClasses="table-responsive"
                                            bootstrap4
                                        />
                                    </Card>
                        }

                    </Col>
                </Row>
                </>
                ):("")
            }
                </div>
               
                <SyllabusPdfTable
                    showPdf={this.state.showPdf}
                    pdfUrl={this.state.pdfUrl}
                    pageType="previousPapergePage"
                    handlePdfView={this.handlePdfView}
                />
                <UserRestrictionAlertInstitute
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />
            </>
        )
    }
}

export default PreviousPapersModel;

