import { thisExpression } from '@babel/types';
import React, { Component } from 'react';
import { Row, Tabs, Tab, Card, Image, Button, Col, Table } from 'react-bootstrap';
import './shortnotes.scss';
import SyllabusPdfTable from './syllabusPdfTable';
import UserRestrictionAlertInstitute from '../home/UserRestrictionAlertInstitute';
import * as Cookies from "es-cookie";
import LockSimple from './LockSimple.png'

class MathsShortNotes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPdf: false,
            pdfUrl: "",
            userRestionModalShow: false
        }
        this.data = [
            {
                name: 'TRIGONOMETRIC RATIOS (A)',
                url: "https://rizee.in/econtent/short_notes/MATH6_7378_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'COMPOUND ANGLES & MULTIPLE & SUB-MULTIPLE ANGELS (A)',
                url: "https://rizee.in/econtent/short_notes/MATH8_5377_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'TRANSFORMATIONS & TRIGONOMETRIC IDENTITIES (A)',
                url: "https://rizee.in/econtent/short_notes/MATH10_8261_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'PERIODICITY & EXTREME VALUES HYPERBOLIC FUNCTIONS (A)',
                url: "https://rizee.in/econtent/short_notes/MATH7_4761_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'TRIGONOMETRIC EQUATIONS (A)',
                url: "https://rizee.in/econtent/short_notes/MATH11_6086_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'INVERSE TRIGONOMETRY (A)',
                url: "https://rizee.in/econtent/short_notes/MATH12_4026_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'PROPERTIES OF TRIANGLE-1 (A)',
                url: "https://rizee.in/econtent/short_notes/MATH14_4809_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'PROPERTIES OF TRIANGLE-2 (A)',
                url: "https://rizee.in/econtent/short_notes/MATH14A_4509_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'FUNCTIONS-1 (A)',
                url: "https://rizee.in/econtent/short_notes/MATH1_1701_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'FUNCTIONS-2 (A)',
                url: "https://rizee.in/econtent/short_notes/MATH1_1739_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'MATHEMATICAL INDUCTION (A)',
                url: "https://rizee.in/econtent/short_notes/MATH2_9078_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'MATRICES-1 (A)',
                url: "https://rizee.in/econtent/short_notes/MATH3_8915_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'MATRICES-2 (A)',
                url: "https://rizee.in/econtent/short_notes/MATH3A_8655_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'ADDITION OF VECTORS (A)',
                url: "https://rizee.in/econtent/short_notes/MATH4_2538_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'PRODUCT OF VECTORS-1 (A)',
                url: "https://rizee.in/econtent/short_notes/MATH5_1315_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'PRODUCT OF VECTORS-2 (A)',
                url: "https://rizee.in/econtent/short_notes/MATH5A_1392_SHRT.pdf",
                value: "View Notes",
                year: "1A"
            },
            {
                name: 'COMPLEX NUMBERS (A)',
                url: "https://rizee.in/econtent/short_notes/MATH32_2588_SHRT.pdf",
                value: "View Notes",
                year: "2A"
            },
            {
                name: 'DE-MOIVERS THEOREM (A)',
                url: "https://rizee.in/econtent/short_notes/MATH33_2631_SHRT.pdf",
                value: "View Notes",
                year: "2A"
            },
            {
                name: 'QUADRATIC EQUATIONS (A)',
                url: "https://rizee.in/econtent/short_notes/MATH34_8003_SHRT.pdf",
                value: "View Notes",
                year: "2A"
            },
            {
                name: 'THEORY OF EQUATIONS (A)',
                url: "https://rizee.in/econtent/short_notes/MATH35_8555_SHRT.pdf",
                value: "View Notes",
                year: "2A"
            },
            {
                name: 'PERMUTATIONS & COMBINATIONS (A)',
                url: "https://rizee.in/econtent/short_notes/MATH36_5609_SHRT.pdf",
                value: "View Notes",
                year: "2A"
            },
            {
                name: 'PROBABILITY-1 (A)',
                url: "https://rizee.in/econtent/short_notes/MATH40_7345_SHRT.pdf",
                value: "View Notes",
                year: "2A"
            },
            {
                name: 'PROBABILITY-2 (A)',
                url: "https://rizee.in/econtent/short_notes/MATH40A_7545_SHRT.pdf",
                value: "View Notes",
                year: "2A"
            },
            {
                name: 'RANDOM VARIABLES (A)',
                url: "https://rizee.in/econtent/short_notes/MATH39_1258_SHRT.pdf",
                value: "View Notes",
                year: "2A"
            },
            {
                name: 'PARTIAL FRACTIONS (A)',
                url: "https://rizee.in/econtent/short_notes/MATH38_8958_SHRT.pdf",
                value: "View Notes",
                year: "2A"
            },
            {
                name: 'BINOMIAL THEOREM (A)',
                url: "https://rizee.in/econtent/short_notes/MATH50_79064_SHRT.pdf",
                value: "View Notes",
                Year: "2A"
            },
            {
                name: 'MEASURES OF DISPERSSION (A)',
                url: "https://rizee.in/econtent/short_notes/MATH41_6286_SHRT.pdf",
                value: "View Notes",
                year: "2A"
            },
            {
                name: 'CO-ORDINATE GEOMETRY (B)',
                url: "https://rizee.in/econtent/short_notes/MATH15_3229_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'LOCUS & CHANGE OF AXIS (B)',
                url: "https://rizee.in/econtent/short_notes/MATH16_7746_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'STRAIGHT LINES-1 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH18_8761_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'STRAIGHT LINES-2 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH18A_87861_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'PAIR OF STRAIGHT LINES-1 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH19_8932_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'PAIR OF STRAIGHT LINES-2 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH19A_5632_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'THREE DIMENSIONAL GEOMETRY & DIRECTION RATIOS & DIRECTION COSINES (B)',
                url: "https://rizee.in/econtent/short_notes/MATH20_1039_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: '3D PLANES & 3D LINES (B)',
                url: "https://rizee.in/econtent/short_notes/MATH22_9508_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'LIMITS & CONTINUITY-1 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH24_2106_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'LIMITS & CONTINUITY-2 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH25_4339_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'DIFFERENTIATION-1 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH26_6304_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'DIFFERENTIATION-2 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH26A_6524_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'ERRORS & APPROXIMATION & RATE OF CHANGE (B)',
                url: "https://rizee.in/econtent/short_notes/MATH27_7616_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'TANGENT & NORMALS (B)',
                url: "https://rizee.in/econtent/short_notes/MATH28_8534_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'MAXIMA & MINIMA & MEAN VALUE THEOREM (B)',
                url: "https://rizee.in/econtent/short_notes/MATH30_9175_SHRT.pdf",
                value: "View Notes",
                year: "1B"
            },
            {
                name: 'CIRCLES-1 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH42_8338_SHRT.pdf",
                value: "View Notes",
                year: "2B"
            },
            {
                name: 'CIRCLES-2 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH42A_8352_SHRT.pdf",
                value: "View Notes",
                year: "2B"
            },
            {
                name: 'SYESTEM OF CIRCLES (B)',
                url: "https://rizee.in/econtent/short_notes/MATH43_7318_SHRT.pdf",
                value: "View Notes",
                year: "2B"
            },
            {
                name: 'PARABOLA (B)',
                url: "https://rizee.in/econtent/short_notes/MATH44_9605_SHRT.pdf",
                value: "View Notes",
                year: "2B"
            },
            {
                name: 'ELLIPSE (B)',
                url: "https://rizee.in/econtent/short_notes/MATH45_8052_SHRT.pdf",
                value: "View Notes",
                year: "2B"
            },
            {
                name: 'HYPERBOLA (B)',
                url: "https://rizee.in/econtent/short_notes/MATH46_3912_SHRT.pdf",
                value: "View Notes",
                year: "2B"
            },
            {
                name: 'INTEGRATION-1 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH47_6061_SHRT.pdf",
                value: "View Notes",
                year: "2B"
            },
            {
                name: 'INTEGRATION-2 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH47A_67861_SHRT.pdf",
                value: "View Notes",
                year: "2B"
            },
            {
                name: 'DEFINITE INTEGRATION-1 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH48_5632_SHRT.pdf",
                value: "View Notes",
                year: "2B"
            },
            {
                name: 'DEFINITE INTEGRATION-2 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH48a_55632_SHRT.pdf",
                value: "View Notes",
                year: "2B"
            },
            {
                name: 'DIFFERENTIAL EQUATIONS-1 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH49_7064_SHRT.pdf",
                value: "View Notes",
                year: "2B"
            },
            {
                name: 'DIFFERENTIAL EQUATIONS-2 (B)',
                url: "https://rizee.in/econtent/short_notes/MATH49a_79064_SHRT.pdf",
                value: "View Notes",
                year: "2B"
            },
        ]
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }
    handlePdfView = (url) => {
        console.log("maths")
        console.log(url)
        this.setState({
            showPdf: !this.state.showPdf,
            pdfUrl: url
        });
    }
    modalaFun = (info, index) => {
        console.log(info)
        let isStudentUserValid = ""
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        console.log(isStudentUserValid)
        console.log(isStudentUserValid.current_plan_id)
        console.log(Cookies.get("institution_id"))

        return (
            this.handlePdfView(info.url)
        )

    }

    render() {
        return (


            <Row>
                {
                    !this.state.showPdf && this.data.map((cardInfo, index) => {
                        return (
                            <Col sm={6} className="mt-4">
                                <div class="bg_white">
                                    <Row>
                                        <Col sm={8}>
                                            <h6 class="title mt-2">{cardInfo.name} </h6>

                                        </Col>
                                        <Col sm={4}>
                                            {
                                                cardInfo.value === "Coming Soon"
                                                    ? <h6 className="float-right viewNotesComingSoonCls" onClick={() => this.modalaFun(cardInfo, index)}>{cardInfo.value}</h6> :
                                                    <h6 className="float-right viewNotesCls" onClick={() => this.modalaFun(cardInfo, index)}>{cardInfo.value}</h6>
                                            }

                                        </Col>
                                    </Row>
                                </div>
                            </Col>


                        )
                    })
                }
                <SyllabusPdfTable
                    showPdf={this.state.showPdf}
                    pdfUrl={this.state.pdfUrl}
                />
                <UserRestrictionAlertInstitute
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />
            </Row>
        )

        /*return (
            <div class="container">
                <Row>
                    {
                        !this.state.showPdf && this.data.map((cardInfo) => {
                            return (
                                <Col>
                                    <Card className="card_main mt-3">
                                        <Card.Body className="d-flex justify-content-between">
                                            <Card.Title className="card_title">{cardInfo.name}</Card.Title>
                                            <Button variant="info" size="md" className="mat content-style" onClick={()=> this.handlePdfView(cardInfo.url)}>{cardInfo.value}</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
                <SyllabusPdfTable
                         showPdf={this.state.showPdf}
                         pdfUrl={this.state.pdfUrl}
                />
            </div>
        )*/
    }
}
















export default MathsShortNotes;