import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import { Row, Tabs, Tab, Card, Image, Button, Col, Table, Media, Container } from 'react-bootstrap';
import { withRouter, Link } from "react-router-dom";


class Restrictionpage extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }

    }

    goToPlans=()=>{
        this.props.history.push({
            pathname: "/student/plans_section",
        })
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <h5 className='text-center mt-5'>Please subscribe to our study plans to access this feature</h5>
                    </Col>
                </Row>

                <Row className='mt-5'>
                    <Col sm={12}>
                            <button className='view_plans_btn' onClick={()=>this.goToPlans()}>View our plans</button>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default withRouter(Restrictionpage);