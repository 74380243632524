import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "lightgreen" : "#fff",
  border:'1px dashed #3C78EE',
  fontSize:'13px',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250,
});

const DraggableCollegeSection = ({onDragEndC,data}) => {
    
  /*const [items, setItems] = useState([]);


  useEffect(() => {
    setItems(data);
  }, []);

 

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

   const reorderedItems  = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    
    setItems(reorderedItems);
    console.log("itemsss",reorderedItems);
  };*/

  return (
    <DragDropContext onDragEnd={onDragEndC}>
   <Droppable droppableId="droppable">
     {(provided, snapshot) => (
       <div
         {...provided.droppableProps}
         ref={provided.innerRef}
         //style={getListStyle(snapshot.isDraggingOver)}
         className='block_list_style'
       >
         <h6 className="text-center">COLLEGES</h6>
         <h6 className="text-center center_text_color mt-2 mb-3">Priotirize your favourite COLLEGES by simple drag and drop from the below list</h6>
         <ul className="list-type2-css">
          {data.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided, snapshot) => (
                <div
                className={item.index_val == 0 ? "card new_college_branch_added" : "card"}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <li className="ml-2">{item.college_name}</li>
                </div>
              )}
            </Draggable>
          ))}
         </ul>
         {provided.placeholder}
       </div>
     )}
   </Droppable>
 </DragDropContext>

);
};

export default DraggableCollegeSection;