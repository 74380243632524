import React, { Component } from 'react'
import Select from 'react-select';
import { components } from 'react-select'
import { Modal, Form, Col, Button } from 'react-bootstrap';
import BranchData from '../groups/BranchData'
import SectionData from '../groups/SectionData'
import ClassesData from '../groups/ClassesData'
import SelectDropDown from '../../selectdropdown/SelectDropDown';


import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as Cookies from "es-cookie";
import * as compose from 'lodash.flowright';
// import { withRouter } from "react-router-dom";

// Subjects
const Subjects = [
    { value: 1, label: 'Maths' },
    { value: 2, label: 'Physics' },
    { value: 3, label: 'Biology' },
    { value: 4, label: 'Chemistry' }
];

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
};


const FETCH_TEACHERS = gql`
  query($institution_id: Int!) {
    getTeachers(institution_id: $institution_id){
        id
        teacher_name
	    contact_no
	    subject_id
        branch_id
        class_ids
        section_ids
        }
    }
`;

const ADD_TEACHER = gql`
  mutation($params:TeacherInput) {
    addTeacher(params: $params)
  }
`;
class TeacherModal extends Component {
    constructor(props) {
        super(props);
        //console.log("constructor", this.props);
        this.state = {
            currentStep: 1,
            teacher_name: "",
            contact_no: "",
            subject: "",
            branch: "",
            section: "",
            class: "",
            submitError: "",
            formErrors: {
                teacher_name: "",
                contact_no: "",
                subject: "",
                branch: "",
                class: "",
                section: ""
            },
            teacher_nameValid: false,
            contact_noValid: false,
            subjectValid: false,
            branchValid: false,
            formValid: false
        };
    }

    getDefaultValuesSection(vals) {
        let sections = Array();
        // for (let i = 0; i < vals.length; i++) {
        //     if (i % 2 == 0) {
        //         const classval = vals[i];
        //         classes.push({ label: classval.class, value: classval.class, id: classval.id });
        //     }
        // }
        return sections;
    }

    getValues(vals) {
        // console.log("Sections",vals)
        let sections = Array();

        for (let i = 0; i < vals.length; i++) {
            if (this.state.branch == vals[i].branch_id) {
                const sectionval = vals[i];
                sections.push({ label: sectionval.section_name, value: sectionval.section_name, id: sectionval.id });
            }

        }
        return sections;
    }
    handleSelectInputChangeSection = e => {
        let sections = Array();
        if (e != undefined) {
            for (let i = 0; i < e.length; i++) {
                const sectionval = e[i];
                sections.push(sectionval.id);
            }
            this.setState({
                section: sections
            });
        }
    };
    getDefaultValuesClass(vals) {
        let classes = Array();
        // for (let i = 0; i < vals.length; i++) {
        //     if (i % 2 == 0) {
        //         const classval = vals[i];
        //         classes.push({ label: classval.class, value: classval.class, id: classval.id });
        //     }
        // }
        return classes;
    }
    getClassValues(vals) {
        // console.log("Classes",vals);
        let classes = Array();
        for (let i = 0; i < vals.length; i++) {
            const classval = vals[i];
            classes.push({ label: classval.class, value: classval.class, id: classval.id });
        }

        return classes;
    }
    handleSelectInputChangeClass = e => {
        let classes = Array();
        if (e != undefined) {
            for (let i = 0; i < e.length; i++) {
                const classval = e[i];
                classes.push(classval.id);
            }
            this.setState({
                class: classes
            });
        }
    };

    handleInputChange = e => {
        console.log("e.target.name", e.target.name);
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => {
            this.validateField(name, value);
        });
    };

    // handleSelectChange = (name, value) => {
    //     this.setState({ [name]: value }, () => {
    //       this.validateField(name, value);
    //     });
    //   };

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let teacher_nameValid = this.state.teacher_nameValid;
        let contact_noValid = this.state.contact_noValid;
        let subjectValid = this.state.subjectValid;
        let branchValid = this.state.branchValid;



        switch (fieldName) {
            case "teacher_name":
                if (value.length == "") {
                    teacher_nameValid = false;
                    fieldValidationErrors.teacher_name = "Teacher Name Cannot Be Empty";
                }
                else {
                    teacher_nameValid = true;
                    fieldValidationErrors.teacher_name = "";
                }

                break;

            //    case "contact_no":
            //     if (value.length > 12) {
            //         contact_noValid = false;
            //         fieldValidationErrors.contact_no = "Mobile No. Maximum 12 digits";
            //     }
            //     else if (value.length < 8) {
            //         contact_noValid = false;
            //         fieldValidationErrors.contact_no = "Mobile No. Minimum 8 digits";
            //     }

            //     else if (value.length == "") {
            //         contact_noValid = false;
            //         fieldValidationErrors.contact_no = "Mobile No. Cannot Be Empty";
            //     } else {
            //         contact_noValid = true;
            //         fieldValidationErrors.contact_no = "";
            //     }

            //     break;

            case "contact_no":
                var pattern = new RegExp("^[6-9][0-9]{9}$");

                if (value.length == "") {
                    contact_noValid = false;
                    fieldValidationErrors.contact_no = "contact No. Cannot Be Empty";
                } else if (!pattern.test(value)) {
                    contact_noValid = false;
                    fieldValidationErrors.contact_no = "Invalid contact No.";
                } else {
                    contact_noValid = true;
                    fieldValidationErrors.contact_no = "";
                }

                break;

            case "subject":
                if (value.length == "") {
                    subjectValid = false;
                    fieldValidationErrors.subject = "Exam Type cannot be empty";
                }
                else {
                    subjectValid = true;
                    fieldValidationErrors.subject = "";
                }

                break;

            case "branch":
                if (value.length == "") {
                    branchValid = false;
                    fieldValidationErrors.branch = "Branch id cannot be empty";
                }
                else {
                    branchValid = true;
                    fieldValidationErrors.branch = "";
                }
                break;
            default:
                break;
        }
        this.setState(
            {
                formErrors: fieldValidationErrors,
                teacher_nameValid: teacher_nameValid,
                contact_noValid: contact_noValid,
                subjectValid: subjectValid,
                branchValid: branchValid,
            },
            this.validateForm
        );
    }

    validateForm() {
        this.setState({
            formValid: this.state.teacher_nameValid &&
                this.state.contact_noValid &&
                this.state.subjectValid &&
                this.state.branchValid
        });
        if (this.state.formValid) {
            this.setState({ submitError: "" }, () => { });
        }
    }
    handleFormSubmit = e => {
        e.preventDefault();
        console.log("Form submitted");
        console.log("Cookies2", Cookies.get("username"));
        console.log("institutionid2", Cookies.get("institutionid"));
        console.log("Data", this.state);

        if (this.state.formValid) {
            let addTeacher = {
                teacher_name: this.state.teacher_name,
                contact_no: this.state.contact_no,
                subject_id: parseInt(this.state.subject),
                branch_id: parseInt(this.state.branch),
                section_ids: this.state.section,
                class_ids: (this.state.class).toString(),
                institution_id: parseInt(Cookies.get("institutionid")),
                username: Cookies.get("username")

            }
            this.addTeacher(addTeacher).catch(error => {
                console.log("catch if error");
                console.log(error);
                this.setState({
                    submitError: error.graphQLErrors.map(x => x.message)
                });
                console.error("ERR =>", error.graphQLErrors.map(x => x.message));
            });
        } else {
            this.setState({ submitError: "Please fill all the values to proceed" });
        }
    };

    addTeacher = async params => {
        await this.props.addTeacher({
            variables: {
                params
            },
            update: (store, { data }) => {
                console.log("Datas", data)
                let data1 = store.readQuery({
                    query: FETCH_TEACHERS,
                    variables: {
                        institution_id: parseInt(Cookies.get("institutionid"))
                    }
                });

                const newTeacher = {
                    id: data.addTeacher,
                    teacher_name: this.state.teacher_name,
                    contact_no: this.state.contact_no,
                    subject_id: this.state.subject,
                    branch_id: this.state.branch,
                    section_ids: this.state.section,
                    class_ids: this.state.class,
                    __typename: "Teachers"
                };

                data1.getTeachers.push(newTeacher);
                console.log("data2", data1);
                try {
                    store.writeQuery({
                        query: FETCH_TEACHERS,
                        variables: {
                            institution_id: parseInt(Cookies.get("institutionid"))
                        },
                        data: data1
                    });

                }
                catch (e) {
                    console.log("Exception", e);
                }

                if (data.addTeacher) {
                    this.setState({
                        currentStep: 5,
                        teacher_name: "",
                        contact_no: "",
                        subject: "",
                        branch: "",
                        section: "",
                        class: "",
                        submitError: "",
                        formErrors: {
                            teacher_name: "",
                            contact_no: "",
                            subject: "",
                            branch: "",
                            section: "",
                            class: ""
                        },
                        teacher_nameValid: false,
                        contact_noValid: false,
                        subjectValid: false,
                        branchValid: false,
                        // sectionValid:false,
                        // classnameValid:false,
                        formValid: false
                    });

                    setTimeout(() => { this.SetpageLoad() }, 1500);
                }
            }
        });
    };

    SetpageLoad = () => {
        console.log("setTimeout");
        this.setState({ currentStep: 1 });
        this.props.onHide();
    }





    //  commaSeparated = () => {
    //  console.log("teachersData", this.props.getTeachers.getTeachers);
    // let newTeacherArray = [];
    // for (let i = 0; i < this.props.getTeachers.getTeachers.length; i++) {
    // const edata = this.props.getTeachers.getTeachers[i];

    // const newTeacher = {
    //         id: edata.id,
    //         teacher_name: edata.teacher_name,
    //         contact_no: edata.contact_no,
    //         subject_id: edata.sunject_id,
    //         branch_id: edata.branch_id,
    //         section_ids: edata.section_ids,
    //         class_ids: edata.class_ids
    //         };
    //         newTeacherArray.push(newTeacher);
    //         }
    //         return newTeacherArray;
    //         }

    // getDefaultValuesSection(vals) {
    //     let sections = Array();
    //     // for (let i = 0; i < vals.length; i++) {
    //     //     if (i % 2 == 0) {
    //     //         const sectionval = vals[i];
    //     //         sections.push({ label: sectionval.section_name, value: sectionval.section_name, id: sectionval.id });
    //     //     }
    //     // }
    //     return sections;
    // }

    render() {
        return (
            <Modal {...this.props}
                size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Teacher</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    {this.state.currentStep == 5 ? (
                        <Form.Text className="form-text text-danger">
                            Teacher Data Saved successfully
                        </Form.Text>
                    ) : (
                            <Form.Text className="form-text text-danger">
                                {this.state.submitError}
                            </Form.Text>
                        )}
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} lg={6} md={12} sm={12} controlId="SelectTeacherName">
                                <Form.Label className="text-uppercase"> Teacher Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    name="teacher_name"
                                    onChange={this.handleInputChange}
                                    autoComplete="off"
                                    value={this.state.teacher_name}
                                />
                                <Form.Text className="form-text text-danger">
                                    {this.state.formErrors.teacher_name}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group as={Col} lg={6} md={12} sm={12} controlId="SelectContact">
                                <Form.Label className="text-uppercase"> Contact No</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Mobile No"
                                    name="contact_no"
                                    onChange={this.handleInputChange}
                                    autoComplete="off"
                                    value={this.state.contact_no}
                                />
                                <Form.Text className="form-text text-danger">
                                    {this.state.formErrors.contact_no}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group as={Col} lg={6} md={12} sm={12} controlId="SelectSubjects">
                                <Form.Label className="text-uppercase"> Subjects</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="subject"
                                    onChange={this.handleInputChange}
                                    value={this.state.subject}>
                                    <option>--Select Subject--</option>
                                    {this.props.globals.subjects.map(subjectdata => (
                                        <option value={subjectdata.id}>{subjectdata.subject}</option>))}

                                </Form.Control>
                                <Form.Text className="form-text text-danger">
                                    {this.state.formErrors.subject}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group as={Col} lg={6} md={12} sm={12} controlId="SelectBranch">
                                <Form.Label className="text-uppercase">Branch</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="branch"
                                    onChange={this.handleInputChange}
                                    value={this.state.branch}>
                                    <option>--Select Branch--</option>
                                    {this.props.globals.globalBranches.map(branchesdata => (
                                        <option value={branchesdata.id}>{branchesdata.branch_name}</option>))}

                                </Form.Control>

                                <Form.Text className="form-text text-danger">
                                    {this.state.formErrors.branch}
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} lg={6} md={12} sm={12} controlId="SelectSection">
                                <Form.Label className="text-uppercase">Section</Form.Label>
                                <Select maxMenuHeight={150}
                                    defaultValue={this.getDefaultValuesSection(this.props.globals.globalSections)}

                                    isMulti
                                    name="section"
                                    options={this.getValues(this.props.globals.globalSections)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.handleSelectInputChangeSection}
                                />
                                <Form.Text className="form-text text-danger">
                                    {this.state.formErrors.section}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group as={Col} lg={6} md={12} sm={12} controlId="SelectClass">
                                <Form.Label className="text-uppercase">Class</Form.Label>
                                <Select maxMenuHeight={150}
                                    defaultValue={this.getDefaultValuesClass(this.props.globals.classes)}

                                    isMulti
                                    name="class"
                                    options={this.getClassValues(this.props.globals.classes)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.handleSelectInputChangeClass}
                                />
                                <Form.Text className="form-text text-danger">
                                    {this.state.formErrors.class}
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer variant="white" className="px-4">
                    <Button onClick={this.handleFormSubmit} className="btn btn-success text-uppercase" >
                        {/* onClick={() => { this.props.onHide() }} */}
                        Save</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default
    compose(graphql(ADD_TEACHER, {
        name: "addTeacher"
    }))(TeacherModal);