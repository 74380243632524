import { thisExpression } from '@babel/types';
import React, { Component } from 'react';
import { Row, Tabs, Tab, Card, Image, Button, Col, Table } from 'react-bootstrap';
import './shortnotes.scss';
import SyllabusPdfTable from './syllabusPdfTable';
import UserRestrictionAlertInstitute from '../home/UserRestrictionAlertInstitute';
import * as Cookies from "es-cookie";
import LockSimple from './LockSimple.png'

class ChemistryShortNotes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPdf: false,
            pdfUrl: "",
            userRestionModalShow: false
        }
        this.data = [
            {
                name: 'CHEMICAL BONDING AND MOLECULAR STRUTCTURE',
                url: "https://rizee.in/econtent/short_notes/CH1_2894_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'STOICHIOMETRY',
                url: "https://rizee.in/econtent/short_notes/CH2_8012_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'EQUILIBRIUM',
                url: "https://rizee.in/econtent/short_notes/CH3_1531_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'ORGANIC CHEMISTRY SOME BASIC PRINCIPLES AND TECHNIQUES ',
                url: "https://rizee.in/econtent/short_notes/CH4_2018_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'STRUCTURE OF ATOM',
                url: "https://rizee.in/econtent/short_notes/CH5_9001_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'THE P-BLOCK ELEMENTS(XI)',
                url: "https://rizee.in/econtent/short_notes/CH6_9979_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'THERMODYNAMICS',
                url: "https://rizee.in/econtent/short_notes/CH7_1929_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'STATES OF MATTER',
                url: "https://rizee.in/econtent/short_notes/CH8_5586_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'CLASSIFICATION OF ELEMENTS AND PERIODICITY IN PROPERTIES',
                url: "https://rizee.in/econtent/short_notes/CH9_7912_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'HYDROCARBONS',
                url: "https://rizee.in/econtent/short_notes/CH10_4865_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'S-BLOCK ELEMENTS',
                url: "https://rizee.in/econtent/short_notes/CH11_9819_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'HYDROGEN',
                url: "https://rizee.in/econtent/short_notes/CH12_9032_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'ENVIRONMENTAL CHEMISTRY',
                url: "https://rizee.in/econtent/short_notes/CH13_7789_SHRT.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'CO-ORDINATION COMPOUNDS',
                url: "https://rizee.in/econtent/short_notes/CH14_9858_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'ALCOHOL, PHENOL AND ETHERS',
                url: "https://rizee.in/econtent/short_notes/CH15_2568_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'ALDEHYDES AND KETONES',
                url: "https://rizee.in/econtent/short_notes/CH16_3715_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'CARBOXYLIC ACIDS',
                url: "https://rizee.in/econtent/short_notes/CH17_6595_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'THE P-BLOCK ELEMENTS(XII)',
                url: "https://rizee.in/econtent/short_notes/CH18_2187_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'SOLUTIONS',
                url: "https://rizee.in/econtent/short_notes/CH19_5392_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'CHEMICAL KINETICS',
                url: "https://rizee.in/econtent/short_notes/CH20_1395_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'ORGANIC COMPOUNDS CONTAINING HALOGENS',
                url: "https://rizee.in/econtent/short_notes/CH21_5229_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'SOLID STATE',
                url: "https://rizee.in/econtent/short_notes/CH22_5226_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'ELECTROCHEMISTRY',
                url: "https://rizee.in/econtent/short_notes/CH23_7899_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'D AND F BLOCK ELEMENTS',
                url: "https://rizee.in/econtent/short_notes/CH24_4699_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'SURFACE CHEMISTRY',
                url: "https://rizee.in/econtent/short_notes/CH25_7356_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'ORGANIC CHEMISTRY CONTAINING NITROGEN',
                url: "https://rizee.in/econtent/short_notes/CH26_5537_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },
            {
                name: 'BIOMOLECULES',
                url: "https://rizee.in/econtent/short_notes/CH27_6199_SHRT.pdf",
                value: "View Notes",
                Year: "Second Year"
            },

        ]
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }
    handlePdfView = (url) => {
        console.log("chemistry")
        console.log(url)
        this.setState({
            showPdf: !this.state.showPdf,
            pdfUrl: url
        });
    }
    modalaFun = (info, index) => {
        console.log(info)
        let isStudentUserValid = ""
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        console.log(isStudentUserValid)
        console.log(isStudentUserValid.current_plan_id)
        console.log(Cookies.get("institution_id"))
        return (
            this.handlePdfView(info.url)
        )

    }
    render() {
        return (


            <Row>
                {
                    !this.state.showPdf && this.data.map((cardInfo, index) => {
                        return (
                            <Col sm={6} className="mt-4">
                                <div class="bg_white">
                                    <Row>
                                        <Col sm={8}>
                                            <h6 class="title mt-2">{cardInfo.name} </h6>

                                        </Col>
                                        <Col sm={4}>
                                            {
                                                cardInfo.value === "Coming Soon"
                                                    ? <h6 className="float-right viewNotesComingSoonCls" onClick={() => this.modalaFun(cardInfo, index)}>{cardInfo.value}</h6> :
                                                    <h6 className="float-right viewNotesCls" onClick={() => this.modalaFun(cardInfo, index)}>{cardInfo.value}</h6>
                                            }

                                        </Col>
                                    </Row>
                                </div>
                            </Col>


                        )
                    })
                }
                <SyllabusPdfTable
                    showPdf={this.state.showPdf}
                    pdfUrl={this.state.pdfUrl}
                />
                <UserRestrictionAlertInstitute
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />
            </Row>
        )

        /*return (
            <div class="container">
                <Row>
                    {
                        !this.state.showPdf && this.data.map((cardInfo) => {
                            return (
                                <Col>
                                    <Card className="card_main mt-8">
                                        <Card.Body className="d-flex justify-content-between">
                                            <Card.Title className="card_title">{cardInfo.name}</Card.Title>
                                            <Button variant="info" size="md" className="che content-style" onClick={()=> this.handlePdfView(cardInfo.url)}>{cardInfo.value}</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
                <SyllabusPdfTable
                         showPdf={this.state.showPdf}
                         pdfUrl={this.state.pdfUrl}
                />
            </div>
        )*/
    }
}
export default ChemistryShortNotes;
