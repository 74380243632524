import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import { Row, Tabs, Tab, Card, Image, Button, Col, Table, Media, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import videosbg from '../../../images/bookstore_videos.png';
import './_bookstoremodule.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import rizeelogo from '../../../images/rizee_bs.png';
import ModuleTourModal from './moduletourModal'
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import * as compose from 'lodash.flowright';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import thumbnail from '../../../images/course.jpeg'
import WatchNowVideosModal from '../Eamcet_Crash_Course/eamcet_liveClasses/WatchNowVideosModal'
import PLAYICON from '../../../images/video-play.png';

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const responsiveImages = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6,
        slidesToSlide: 6 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    }
};

class BookstoreModuleSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            tourPopup: false,
            moduleTourData: [],
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }

    startExam = (data) => {

        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        localStorage.setItem("resumeExam", false);
        localStorage.setItem("examtype_pattern", "partner_exam");

        if (isMobile == true && Cookies.get("institution_id") == 30) {
            window.location.replace("/student/subject/exam");
        } else {
            window.open("/student/subject/exam", "_blank")
        }
    }

    videosmodalaFun = (row) => {
        this.addStudentAnalytics()

        this.setState({
            liveModalShow: true,
            dataLiveUrl: row
        });
    }


    resumeExam = (data) => {
        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        localStorage.setItem("examtype_pattern", "partner_exam");
        localStorage.setItem("resumeExam", true);


        if (isMobile == true && Cookies.get("institution_id") == 30) {
            window.location.replace("/student/subject/exam");
        } else {
            window.open("/student/subject/exam", "_blank")
        }
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 236,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    handleResultFunction = (id) => {
        this.props.history.push({
            pathname: "/student/subject/exam-result",
            state: {
                sessionid: id,
                examtype: "partner_exam",
            }
        })
    }

    modalaFun = () => {
        this.setState({
            tourPopup: true,
        });
    }

    componentDidMount() {
        this.getModuleTourData()
    }

    getModuleTourData = async () => {
        this.setState({ moduleTourData: [] })
        const result = await this.props.client.query({
            query: gql`
            query($exam_id:Int,$id:Int,$module_name:String){
                  getRizeeTour(exam_id:$exam_id,id:$id, module_name:$module_name){
                    web_path
                    mobile_path
                    navigation_url
                    title
                  }
            }
            `,
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                id: 54,
                module_name: "Start_Tour"
            },
            fetchPolicy: 'network-only'
        })

        if (result.data.getRizeeTour.length > 0) {
            this.setState({ moduleTourData: result.data.getRizeeTour })
        }
    }

    render() {

        const ModulesData = this.props.getModuleNavigation

        const examsList = this.props.getGroupBasedExams
        let currentTime_currentSec = Math.round((new Date()).getTime() / 1000);

        let videourls = [];

        if (this.props.getServiceData[0].url != null && this.props.getServiceData[0].url != undefined) {
            videourls = this.props.getServiceData[0].url.split(",")
        }

        return (
            <Container className='Book_store'>
                <Row className='mt-3'>
                    <Col sm={12}>
                        <h3 className='bs_header text-center'>Get a glimpse of what you can achieve with Rizee</h3>
                    </Col>
                </Row>
                <div className='mt-2 bg_videos'>
                    <Row>
                        <Col sm={10} >
                            <h6 className='videos_header'>Free Foundation Videos</h6>
                        </Col>
                    </Row>

                    <Carousel
                        swipeable={true}
                        draggable={true}
                        responsive={responsive}
                        keyBoardControl={true}
                        customTransition="transform 1s"
                        transitionDuration={100}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {
                            videourls.map(item => {
                                let embdUrl = item.split('#');
                                return (
                                    <span onClick={() => this.videosmodalaFun(embdUrl[0])} className="play-icon-video"><img className="video_block" src={embdUrl[1]} alt="video" /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /></span>
                                )
                            })
                        }

                    </Carousel>
                </div>

                <Row className='mt-5'>
                    <Col sm={8}>
                        <h3 className='bs_header'>Modules that will help you prepare better</h3>
                    </Col>

                    <Col sm={4}>
                        <button className="tour_button float-right" onClick={() => this.modalaFun()}>Start Tour</button>
                    </Col>
                </Row>

                <div className='mt-3 tour_cont'>
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        responsive={responsiveImages}
                        keyBoardControl={true}
                        customTransition="transform 1s"
                        transitionDuration={100}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {
                            ModulesData.length > 0 && ModulesData.map((item) => {
                                let link = item.navigation_url.replace('https://rizee.in/student/action/', '');


                                return (
                                    <Link to={`/student/action/${link}`} style={{ textDecoration: 'none' }}>
                                        <img src={item.icon_path} className="mod_img" alt="module" />
                                    </Link>
                                )
                            })
                        }
                    </Carousel>
                </div>


                <Row className='mt-5'>
                    <Col sm={10} >
                        <h6 className='videos_header'>Free Test Series</h6>
                    </Col>
                </Row>

                <Row className="mb-5">
                    {
                        examsList.length > 0 ?
                            (
                                examsList.map((item) => {
                                    return (
                                        <Col sm={6} className="mt-2">
                                            <Card className="card-border-exams">
                                                <Card.Body>
                                                    <Media>
                                                        <div className="date_block mr-3 text-center">
                                                            <p className="mt-1 f-22 border-bootom2"> {moment.unix(item.start_time).format("MMM")}</p>
                                                            <p className="mt-2 f-22">{moment.unix(item.start_time).format("DD")}</p>
                                                        </div>
                                                        <Media.Body>
                                                            <Card.Title><h6 className="f-14">{item.exam_name}</h6></Card.Title>

                                                            <Row>
                                                                <Col sm={12}>
                                                                    <p className="f-14">Time : {moment.unix(item.start_time).format("LT")}</p>
                                                                    <p className="f-14 pos_top"> Duration : {item.exam_duration}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={6}>
                                                                </Col>
                                                                <Col sm={6} className=''>

                                                                    {
                                                                        item.exam_expired == true ?
                                                                            ("")
                                                                            :
                                                                            (
                                                                                <>
                                                                                    {
                                                                                        item.is_completed == true ?
                                                                                            (
                                                                                                <Button type="button" className="btn btn-custom-blue float-right-resp" onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results </Button>
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                item.is_started == true && currentTime_currentSec > item.start_time ?
                                                                                                    (<Button type="button" className="btn btn-custom-blue float-right-resp" onClick={() => this.resumeExam(item)}>Resume Test</Button>) :

                                                                                                    (
                                                                                                        item.exam_started == true ?
                                                                                                            (<Button type="button" className="btn btn-custom-blue float-right-resp" onClick={() => this.startExam(item)}>Start Now</Button>)
                                                                                                            :
                                                                                                            (<Button type="button" className="btn btn-custom-blue float-right-resp disabledCursor" disabled>Start Now</Button>)
                                                                                                    )
                                                                                            )
                                                                                    }
                                                                                </>
                                                                            )
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Media.Body>
                                                    </Media>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })
                            )
                            :
                            (
                                <Col>
                                    <h5 className='text-center exams_text'>Tests will be available soon</h5>
                                </Col>
                            )
                    }

                </Row>
                <ModuleTourModal
                    show={this.state.tourPopup}
                    onHide={() => this.setState({ tourPopup: false })}
                    data={this.state.moduleTourData}
                />

                <WatchNowVideosModal data={this.state.dataLiveUrl}
                    show={this.state.liveModalShow}
                    onHide={() => this.setState({ liveModalShow: false })}
                />
            </Container>


        )
    }
}

export default withApollo(withRouter(
    compose(
        graphql(UPDATE_STUDENT_ANALYTICS, {
            name: "submitpt"
        }),
    )(BookstoreModuleSection)
));
