import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { Row, Col, Card, Image, Button, Form } from 'react-bootstrap';
import * as Cookies from "es-cookie";
import PdfModal from '../subjects/PdfModal';
import { withRouter } from "react-router-dom";
import '../../category_wise_videos/_categorywisevideos.scss';
import PLAYICON from  '../../../../images/video-play.png';

class WatchingChapterWiseVideoSection extends Component {

    constructor(props) {
        super(props)
        this.state = {
            getPracticeVideos: props?.getPracticeVideos,
        }
    }
   
   
    render() {
      console.log("videos",this.state.getPracticeVideos)
        return (
            <section class="customCategoryVideos">
                <Row>
                    {
                        this.state.getPracticeVideos.map((videosUrls,index) => (
                                <Col  sm={4} className="mt-2">
                                   
                                    <Link to={{
                                        pathname: "/student/subject/start-video-watching",
                                        state: {
                                            index: index,
                                            videosList: this.state.getPracticeVideos,
                                            type: "iv",
                                            mobile: Cookies.get("mobile"),
                                            urltype:"categoryWiseVideo"

                                        }
                                    }} className="">
                                        {videosUrls.thumbnail != "" ? (<>
                                            <Card className="single_card" style={{ textDecoration: 'none', color: "#000" }}>
                                                <Card.Header className="border-0 py-2 bg-light d-flex justify-content-between align-items-center">
                                                    <div className="header-title">
                                                        <Card.Title className="h6 mb-0">
                                                            {videosUrls.title}
                                                        </Card.Title>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body>
                                                     <span className="play-icon-video"><img className="image-style-cls w-100"  src={videosUrls.thumbnail} alt={videosUrls.title} /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /></span>
                                                </Card.Body>
                                                </Card>
                                            
                                        </>) : (
                                            <span className="play-icon-video"><img  className="image-style-cls w-100" src={"https://source.unsplash.com/M185_qYH8vg/1780x1040"} alt="img" /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /> </span>
                                        )}

                                    </Link>
                                    
                                </Col>
                            
                            ))
                        }
                </Row>
            </section>
        )
    }
}


export default (withRouter(WatchingChapterWiseVideoSection));
