import React, { Component } from "react";
import SideNavbar from "../components/navbars/SideNavbar";
import NavbarOne from "../components/navbars/NavbarOne";
import OwnQuestionPaperSection from "../components/questioners/create_question_paper/own_question_paper/OwnQuestionPaperSection";
import Footer from "../components/footer/Footer";
import * as Cookies from "es-cookie";
class OwnQuestionPaper extends Component {
  render() {
    if (Cookies.get("token") == undefined) this.props.history.push("/login");
    console.log("OwnQuestionPaper", this.props.history.location.state);
    return (
      <React.Fragment>
        <div className="left-side-menu">
          <SideNavbar onClick={() => this.props.changeToggle()} />
        </div>
        <div className="content-page">
          <NavbarOne onClick={() => this.props.changeToggle()} />
          <div className="overlay" onClick={() => this.props.changeToggle()} />
          <div className="main-content">
            <OwnQuestionPaperSection
              locationData={this.props}
              getGroupData={this.props.history.location.state}
            />
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default (OwnQuestionPaper);
