import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import { Row, Tabs, Tab, Card, Image, Button, Col, Table, Media, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Arrow from "../../../images/eamcet_arrow.png"
import Chemistry from '../../../images/chemistry_icon.png';
import Zoology from '../../../images/zoology_icon.png';
import Physics from '../../../images/physics_icon.png';
import Maths from '../../../images/maths_icon.png';
import Botany from '../../../images/botany_icon.png';
import UserRestrictionAlertInstitute from "../home/UserRestrictionAlertInstitute";
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';
import moment from 'moment';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import * as compose from 'lodash.flowright';
import './_chapterWiseLearning.scss'
import SyllabusPdfTable from './syllabusPdfTable';
import gql from "graphql-tag";
import parse, { domToReact } from 'html-react-parser';
import ExplanationModal from '../ChapterWiseQuestions/ExplanationModal.jsx'
import EamcetRestrictionAlert from "../home/EamcetRestrictionAlert";
import Restrictionpage from './restrictionPage';
import PLAYICON from '../../../images/video-play.png';




class ChapterWiseLearningSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            module: this.props.history.location.state.clickType,
            shortNotesstudyMaterial: "",
            revisionMaterialStudyMaterial: "",
            showPdf: true,
            pdfUrl: "",
            eamcetPracticeTests: [],
            apeamcetCheckbox: Cookies.get("examid") == 3 || Cookies.get("examid") == 7 ? true : false,
            tseamcetCheckbox: Cookies.get("examid") == 6 || Cookies.get("examid") == 8 ? true : false,
            showShortNotes: false,
            showRevisionMaterial: false,
            exam_type_value: parseInt(Cookies.get("examid")),
            solutionModal: false,
            solution: [],
            shortNotesEnabled: false,
            revisionMaterialEnabled: false,
            restrictionPageView: false,
            userRestionModalShow: false,
            restrictionPageView: false,
            getChapterWiseVideos: [],
            IpeQuestionsArray: [],
            twoMarksCheckbox: false,
            fourMarksCheckbox: false,
            sevenMarksCheckbox: false,
            eightMarksCheckbox: false,
            allCheckBox: true,
            marksValue: 0,
            videoEnabled: false,
            restrictionPageViewForVideos:false,
            restrictionPageViewForShortnotes:false
        }
    }


    componentDidMount() {
        this.displayStudyMaterial()
        this.displayVideos()
    }

    selectHandleModuleChange = async (module_type) => {
        const value = module_type
        this.setState({
            module: value
        })

        if (value == "short_notes") {
            this.handlePdfViewShortNotes()
        } else if (value == "revision_material") {
            this.handlePdfViewRevisionMaterial()
        } else if (value == "videos") {
            if (this.state.videoEnabled == false) {
                this.setState({ restrictionPageViewForVideos: false })
            } else {
                this.setState({ restrictionPageViewForVideos: true })
            }
        }
    }

    displayStudyMaterial = async () => {
        const result = await this.props.client.query({
            query: gql`
            query($chapter_id:Int,$mobile:String){
                getEamcetStudyMaterial(chapter_id:$chapter_id,mobile:$mobile){
                    shortNotes
                    revisionMaterial
                    shortNotesEnabled
                    revisionMaterialEnabled
                  }
            }
            `,
            variables: {
                chapter_id: this.props.history.location.state.exam_type_value == 12 || this.props.history.location.state.exam_type_value == 13 ? parseInt(this?.props?.history?.location?.state?.Ipe_chapter_id) : parseInt(this?.props?.history?.location?.state?.chapterData?.id),
                mobile: Cookies.get("mobile")
            },
            fetchPolicy: "network-only"
        })
        if (result.data.getEamcetStudyMaterial.length > 0) {
            this.setState({
                shortNotesstudyMaterial: result.data.getEamcetStudyMaterial[0].shortNotes,
                revisionMaterialStudyMaterial: result.data.getEamcetStudyMaterial[0].revisionMaterial,
                shortNotesEnabled: result.data.getEamcetStudyMaterial[0].shortNotesEnabled,
                revisionMaterialEnabled: result.data.getEamcetStudyMaterial[0].revisionMaterialEnabled
            })
        }

        if (this.state.module == "short_notes") {
            this.handlePdfViewShortNotes()
        } else if (this.state.module == "revision_material") {
            this.handlePdfViewRevisionMaterial()
        }
    }

    displayVideos = async () => {
        const result = await this.props.client.query({
            query: gql`
            query($chapter_id:Int, $mobile:String){
                getChapterWiseVideos(chapter_id:$chapter_id,mobile:$mobile){
                    id
                    generic
                    paid_video
                    class
                    subject
                    chapter
                    topic
                    exam
                    exam_type
                    title
                    description
                    video_id
                    video_url
                    thumbnail
                    publish
                    vimeo_url
                    institute_id
                    likes
                    dislikes
                    is_purchased
                    video_source
                    views
                    pdf_file    
                    videoAccess         
                  }
            }
            `,
            variables: {
                // chapter_id: parseInt(this.props.history.location.state.chapterData.id),
                chapter_id: this.props.history.location.state.exam_type_value == 12 || this.props.history.location.state.exam_type_value == 13 ? parseInt(this?.props?.history?.location?.state?.Ipe_chapter_id) : parseInt(this.props.history.location.state.chapterData.id),
                mobile: Cookies.get("mobile")
            },
            fetchPolicy: "network-only"
        })

        if (result.data.getChapterWiseVideos.length > 0) {
            this.setState({ getChapterWiseVideos: result.data.getChapterWiseVideos, videoEnabled: result.data.getChapterWiseVideos[0].videoAccess })
        } else {
            this.setState({ getChapterWiseVideos: [] })
        }
    }

    handlePdfViewShortNotes = () => {
        if (this.state.shortNotesEnabled == false) {
            this.setState({
                showShortNotes: true,
                pdfUrl: this.state.shortNotesstudyMaterial
            });
        } else {
            this.setState({
                restrictionPageViewForShortnotes: true
            })
        }
    }

    handlePdfViewRevisionMaterial = () => {
        if (this.state.revisionMaterialEnabled == false) {
            this.setState({
                showRevisionMaterial: true,
                pdfUrl: this.state.revisionMaterialStudyMaterial
            });
        } else {
            this.setState({
                restrictionPageView: true
            })
        }
    }

    handleResultFunction = (id) => {

        this.props.history.push({
            pathname: "/student/subject/exam-result",
            state: {
                sessionid: id,
                examtype: "eamcet_practice",
                //mocktype: "history"
            }
        })
    }

    modalaFun = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }

    startExam = (data) => {

        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        localStorage.setItem("resumeExam", false);
        localStorage.setItem("subexamtype", this.state.subtype);
        localStorage.setItem("examtype_pattern", "eamcet_practice");

        if (isMobile == true && Cookies.get("institution_id") == 30) {
            this.props.history.push({
                pathname: "/student/subject/exam",
            })
        } else {
            window.open("/student/subject/exam", "_blank");
        }

    }

    resumeExam = (data) => {
        console.log("this.state.subtype", data.exam_name, data.exam_name);

        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        localStorage.setItem("subexamtype", this.state.subtype);
        localStorage.setItem("examtype_pattern", "eamcet_practice");
        localStorage.setItem("resumeExam", true);

        if (isMobile == true && Cookies.get("institution_id") == 30) {
            this.props.history.push({
                pathname: "/student/subject/exam",
            })
        } else {
            window.open("/student/subject/exam", "_blank");
        }
    }

    handleQuestionsType = async (e) => {
        const value = e.target.value;

        if (value == "AP_EAPCET") {
            const delay = (ms) => new Promise((r) => setTimeout(r, ms));
            await delay(200);

            this.setState({
                exam_type: "AP EAPCET",
                exam_type_value: Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? 3 : 7,
                apeamcetCheckbox: true,
                tseamcetCheckbox: false,
                apIPEcheckbox: false,
                tsIPEcheckbox: false,
            })
        }

        else if (value == "TS_EAMCET") {
            const delay = (ms) => new Promise((r) => setTimeout(r, ms));
            await delay(200);

            this.setState({
                exam_type: "TS EAMCET",
                exam_type_value: Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? 6 : 8,
                apeamcetCheckbox: false,
                tseamcetCheckbox: true,
                apIPEcheckbox: false,
                tsIPEcheckbox: false,
            })
        }

        else if (value == "AP_IPE") {
            const delay = (ms) => new Promise((r) => setTimeout(r, ms));
            await delay(200);

            this.setState({
                exam_type: "AP IPE",
                exam_type_value: 12,
                apeamcetCheckbox: false,
                tseamcetCheckbox: false,
                apIPEcheckbox: true,
                tsIPEcheckbox: false,
            })

        }

        else if (value == "TS_IPE") {
            const delay = (ms) => new Promise((r) => setTimeout(r, ms));
            await delay(200);

            this.setState({
                exam_type: "TS IPE",
                exam_type_value: 13,
                apeamcetCheckbox: false,
                tseamcetCheckbox: false,
                apIPEcheckbox: false,
                tsIPEcheckbox: true,
            })

        }
    }

    showSolution = (key) => {
        console.log("keykeykey", key)
        this.setState({
            solutionModal: true,
            solution: key
        })
    }

    handleChangeMarksType = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        console.log("qqqqqqqqqq", name, value)

        if (value == "two_marks") {
            this.setState({
                marksValue: 2,
                twoMarksCheckbox: true,
                fourMarksCheckbox: false,
                sevenMarksCheckbox: false,
                eightMarksCheckbox: false,
                allCheckBox: false
            })
        } else if (value == "four_marks") {
            this.setState({
                marksValue: 4,
                twoMarksCheckbox: false,
                fourMarksCheckbox: true,
                sevenMarksCheckbox: false,
                eightMarksCheckbox: false,
                allCheckBox: false

            })
        } else if (value == "seven_marks") {
            this.setState({
                marksValue: 7,
                twoMarksCheckbox: false,
                fourMarksCheckbox: false,
                sevenMarksCheckbox: true,
                eightMarksCheckbox: false,
                allCheckBox: false

            })
        } else if (value == "eight_marks") {
            this.setState({
                marksValue: 8,
                twoMarksCheckbox: false,
                fourMarksCheckbox: false,
                sevenMarksCheckbox: false,
                eightMarksCheckbox: true,
                allCheckBox: false

            })
        } else if (value == "All") {
            this.setState({
                marksValue: 0,
                twoMarksCheckbox: false,
                fourMarksCheckbox: false,
                sevenMarksCheckbox: false,
                eightMarksCheckbox: false,
                allCheckBox: true
            })
        }
    }

    render() {



        let isStudentUserValid1 = "";
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid1 = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }

        let isuserValid = JSON.parse(
            isStudentUserValid1.user_access_restictions
        );

        const examsArray = this.props.getChapterWisePracticeTests
        let currentTime_currentSec = Math.round((new Date()).getTime() / 1000);

        let previousYearQuestions = [];

        let IPEPreviousQuestions = [];

        if (this.state.module == "previous_questions" && this.props.history.location.state.isPreviousQuestions == true && this.props.history.location.state != undefined) {
            previousYearQuestions = this.props.getChapterWiseGroupedQuestionsNew.filter((item) => item.exam == this.props.history.location.state.exam_type_value)
        }
        else if (this.state.exam_type_value == 12 || this.state.exam_type_value == 13 && this.state.module == "previous_questions" && this.state.marksValue == 0) {
            previousYearQuestions = this.props.getIPEChapterWiseQuestions.filter((item) => item.exam == this.state.exam_type_value)
        }
        else if (this.state.exam_type_value == 12 || this.state.exam_type_value == 13 && this.state.module == "previous_questions") {
            previousYearQuestions = this.props.getIPEChapterWiseQuestions.filter((item) => item.exam == this.state.exam_type_value && item.marks == this.state.marksValue)
        }
        else {
            previousYearQuestions = this.props.getChapterWiseGroupedQuestionsNew.filter((item) => item.exam == this.state.exam_type_value)
        }


        if (this.state.module == "previous_questions" && this.state.marksValue == 0 && this.props.history.location.state.isPreviousQuestions == true && this.props.history.location.state != undefined && (this.props.history.location.state.exam_type_value == 12 || this.props.history.location.state.exam_type_value == 13)) {
            IPEPreviousQuestions = this.props.getIPEChapterWiseQuestions.filter((item) => item.exam == this.props.history.location.state.exam_type_value)
        }
        else if (this.state.module == "previous_questions" && this.props.history.location.state.isPreviousQuestions == true && this.props.history.location.state != undefined && (this.props.history.location.state.exam_type_value == 12 || this.props.history.location.state.exam_type_value == 13)) {
            IPEPreviousQuestions = this.props.getIPEChapterWiseQuestions.filter((item) => item.exam == this.props.history.location.state.exam_type_value && item.marks == this.state.marksValue)
        }
        else {
            IPEPreviousQuestions = this.props.getIPEChapterWiseQuestions.filter((item) => item.exam == this.state.exam_type_value)
        }

        return (
            <Container className='chapter_learning'>
                <Row className='resp_mt_5'>
                    <Col sm={12}>
                        <div class="topnav">
                            {isuserValid.Chapterpreviousquestions == true ? ("") : (<a className={this.state.module == "previous_questions" ? "active font-weight-bold" : "font-weight-bold"} onClick={(e) => this.selectHandleModuleChange("previous_questions")}>Previous Paper Questions</a>)}
                            {isuserValid.ST_ShortNotes == true ? ("") : (<a className={this.state.module == "short_notes" ? "active font-weight-bold" : "font-weight-bold"} onClick={(e) => this.selectHandleModuleChange("short_notes")}>Short Notes</a>)}
                            {isuserValid.ST_RevisionMaterial == true ? ("") : (<a className={this.state.module == "revision_material" ? "active font-weight-bold" : "font-weight-bold"} onClick={(e) => this.selectHandleModuleChange("revision_material")}>Revision material</a>)}
                            {isuserValid.ST_Practice == true ? ("") : (<a className={this.state.module == "practise_sessions" ? "active font-weight-bold" : "font-weight-bold"} onClick={(e) => this.selectHandleModuleChange("practise_sessions")}>Practise sessions</a>)}
                            {isuserValid.ChapterVideos == true ? ("") : (<a className={this.state.module == "videos" ? "active font-weight-bold" : "font-weight-bold"} onClick={(e) => this.selectHandleModuleChange("videos")}>Video Classes</a>)}
                        </div>
                        <div className='line'></div>
                    </Col>
                </Row>
                <Row>

                    {
                        this.state.module == "videos" && this.state.videoEnabled == false ?
                            (
                                this.state.getChapterWiseVideos.length > 0 ?
                                    (
                                        this.state.getChapterWiseVideos.map((item, index) => {
                                            let thumbnail = `https://admin.rizee.in/createvideos/files/${item.thumbnail}`
                                            console.log("thum", thumbnail)

                                            return (
                                                <Col sm={4} className="mt-3">
                                                    <Link to={{
                                                        pathname: "/student/subject/start-video-watching",
                                                        state: {
                                                            index: index,
                                                            videosList: this.state.getChapterWiseVideos,
                                                            type: "iv",
                                                            mobile: Cookies.get("mobile"),
                                                            urltype: "categoryWiseVideo"
                                                        }
                                                    }}>
                                                        {
                                                            item.thumbnail == null ?
                                                                (
                                                                    <>
                                                                        <span className="play-icon-video"><img className="image-style-cls w-100" src={"https://rizee.in/static_content_images/Rizee_Video_Thumnail.jpeg"} alt="img" /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /> </span>
                                                                    </>
                                                                )
                                                                :
                                                                (<><span className="play-icon-video"><img className="image-style-cls w-100" src={thumbnail} alt={item.title} /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /></span></>)
                                                        }
                                                    </Link>
                                                    <h6 className="f-16 mt-2">{item.title}</h6>
                                                </Col>
                                            )
                                        })
                                    )
                                    :
                                    (
                                        <p className='font-weight-bold text-center mt-3 ml_35'>Videos will be available soon</p>
                                    )
                            )
                            :
                            (
                                this.state.module == "videos" && this.state.restrictionPageViewForVideos == true ?
                                    (<Restrictionpage />)
                                    :
                                    ("")
                            )

                    }
                </Row>

                {
                    this.state.module == "short_notes" && this.state.shortNotesEnabled == false ?
                        (
                            <SyllabusPdfTable
                                showPdf={this.state.showShortNotes}
                                pdfUrl={this.state.shortNotesstudyMaterial}
                                handlePdfView={this.handlePdfViewShortNotes}
                            />
                        )
                        :
                        (
                            this.state.module == "short_notes" && this.state.restrictionPageViewForShortnotes == true ?
                                (<Restrictionpage />)
                                :
                                ("")
                        )
                }

                {
                    this.state.module == "revision_material" && this.state.revisionMaterialEnabled == false ?
                        (
                            <SyllabusPdfTable
                                showPdf={this.state.showRevisionMaterial}
                                pdfUrl={this.state.revisionMaterialStudyMaterial}
                                handlePdfView={this.handlePdfViewRevisionMaterial}
                            />
                        )
                        :
                        (
                            this.state.module == "revision_material" && this.state.restrictionPageView == true ?
                                (<Restrictionpage />)
                                :
                                ("")
                        )
                }

                {
                    this.state.module == "practise_sessions" ?
                        (
                            <div className='chap_block_view margin_top_learning'>
                                <Row>
                                    <Col sm={12}>
                                        <h5>{examsArray[0].chapter_name}:-</h5>
                                    </Col>
                                </Row>
                                <Row className='margin_top_learning'>
                                    {
                                        examsArray[0].exam_paper_one.length > 0 ?
                                            (
                                                examsArray[0].exam_paper_one.map(item =>
                                                    <Col sm={4} className='mt-2'>
                                                        <Card className='border_pad_card'>
                                                            <Card.Body className='p-2'>
                                                                <h6 className='mt-2 mb-3'>{item.exam_name}
                                                                    {
                                                                        item.complexity_id == 1 ?
                                                                            (<span className='easy_cls float-right'>Level-1</span>)
                                                                            :
                                                                            (
                                                                                <>
                                                                                    {
                                                                                        item.complexity_id == 2 ?
                                                                                            (<span className='modarate_cls float-right'>Level-2</span>)
                                                                                            :
                                                                                            (
                                                                                                <>
                                                                                                    {
                                                                                                        item.complexity_id == 3 ?
                                                                                                            (<span className='difficult_cls float-right'>Level-3</span>)
                                                                                                            :
                                                                                                            ("")
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                    }
                                                                                </>
                                                                            )
                                                                    }
                                                                </h6>
                                                                <p className='mt-2 mb-1'>Duration : <b>{item.exam_duration_min + " Min"}</b>
                                                                    {
                                                                        item.is_completed == true ? (
                                                                            <Button type="button" className='btn-custom-blue float-right f-14' onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results</Button>

                                                                        ) : (
                                                                            item.enabled == false ? (
                                                                                item.is_started == true && currentTime_currentSec > item.start_time ? (
                                                                                    <Button type="button" className='btn-custom-blue float-right f-14' onClick={() => this.resumeExam(item)}>Resume Practice</Button>
                                                                                ) : (
                                                                                    <Button type="button" className='btn-custom-blue float-right f-14' onClick={() => this.startExam(item)}>Start Practice</Button>
                                                                                )
                                                                            ) : (
                                                                                <Button type="button" className='btn-custom-blue float-right f-14' onClick={() => this.modalaFun()}>Start Test</Button>

                                                                            )
                                                                        )
                                                                    }
                                                                </p>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                )
                                            )
                                            :
                                            (
                                                <>
                                                    <Col sm={12}>
                                                        <h6 className='text-center font-weight-bold mt-5'>Coming Soon...</h6>
                                                    </Col>
                                                </>
                                            )
                                    }
                                </Row>
                            </div>
                        )
                        :
                        ("")
                }

                {
                    this.state.module == "previous_questions" && this.props.history.location.state.isPreviousQuestions == true ?
                        (
                            <div className="bg-light my-2">
                                {
                                    previousYearQuestions.length > 0 ?
                                        (
                                            previousYearQuestions.map((item, index) => {
                                                console.log("iiiiiiiii", item)
                                                if (item.qtype == 3 || item.qtype == 9) {
                                                    let question1 = item.question_name.replace(/src="/g, 'src=\\"');
                                                    let question2 = question1.replace(/" \/>/g, '\\" />');
                                                    console.log("question2question2", question2)
                                                    let question = [];

                                                    try {
                                                        question = JSON.parse(question2);
                                                    }
                                                    catch (err) {
                                                        console.log("MATRIX ERROR:", err.message);
                                                    }
                                                    return (
                                                        <>
                                                            <Card as={Card.Body} className="bg-light my-2 mob_view">
                                                                <Row>
                                                                    <Col xl={12} lg={12} md={12} sm={12}>
                                                                        {parse(item.mat_question)}

                                                                        <Row>
                                                                            <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                <span className='font-weight-bold'>{index + 1 + '.'}</span>

                                                                                <ol className="matrixlistoptions" type={
                                                                                    item.list1type == "alphabets" ? ("A")
                                                                                        : (item.list1type == "numbers") ? ("1")
                                                                                            : ("roman")}
                                                                                >
                                                                                    {question.map((item) => (
                                                                                        <li className='font-weight-bold'>{parse(item.qlist1)}</li>
                                                                                    ))}
                                                                                </ol>
                                                                            </Col>
                                                                            <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                <ol className="matrixlistoptions" type={
                                                                                    item.list2type == "alphabets" ? ("A")
                                                                                        : (item.list2type == "numbers") ? ("1")
                                                                                            : ("roman")}>
                                                                                    {question.map((item) => (
                                                                                        <li className='font-weight-bold'>{parse(item.qlist2)}</li>
                                                                                    ))}
                                                                                </ol>
                                                                            </Col>
                                                                            <p className='float-right'>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className='f-16'>A.</span>
                                                                                    <div className="ml-2">{parse(item.option1)}</div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className='f-16'>B.</span>
                                                                                    <div className="ml-2">{parse(item.option2)}</div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className='f-16'>C.</span>
                                                                                    <div className="ml-2">{parse(item.option3)}</div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className='f-16'>D.</span>
                                                                                    <div className="ml-2">{parse(item.option4)}</div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xl={12} lg={12} md={12} sm={12} className="my-2">
                                                                                <button className='solution_btn_new' onClick={() => this.showSolution(item)}>View Solution</button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </>
                                                    )

                                                }
                                                else if (item.qtype == "8") {
                                                    return (
                                                        <>
                                                            <Card as={Card.Body} className="bg-light my-2 mob_view">
                                                                <span className='font-weight-bold'>{index + 1 + '.'}</span>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        {parse(item.question_name)}
                                                                    </Col>
                                                                </Row>
                                                                <p className='float-right '>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>

                                                                <Row>
                                                                    <Col xl={12} lg={12} md={12} sm={12} className="my-2">
                                                                        <button className='solution_btn_new' onClick={() => this.showSolution(item)}>View Solution</button>

                                                                    </Col>
                                                                </Row>

                                                            </Card>
                                                        </>
                                                    )

                                                }
                                                else if (item.qtype == "5") {
                                                    //console.log("questionsData.questionnn", question);
                                                    return (
                                                        <>
                                                            <Card as={Card.Body} className="bg-light my-2 mob_view">
                                                                <span className='font-weight-bold'>{index + 1 + '.'}</span>
                                                                {parse(item.compquestion)}

                                                                {parse(item.question_name)}
                                                                <p className='float-right '>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>

                                                                <Row>
                                                                    <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                        <div className="d-flex align-items-center">
                                                                            <span className='f-16'>A.</span>
                                                                            <div className="ml-2">{parse(item.option1)}</div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                        <div className="d-flex align-items-center">
                                                                            <span className='f-16'>B.</span>
                                                                            <div className="ml-2">{parse(item.option2)}</div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                        <div className="d-flex align-items-center">
                                                                            <span className='f-16'>C.</span>
                                                                            <div className="ml-2">{parse(item.option3)}</div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                        <div className="d-flex align-items-center">
                                                                            <span className='f-16'>D.</span>
                                                                            <div className="ml-2">{parse(item.option4)}</div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl={12} lg={12} md={12} sm={12} className="my-2 ">
                                                                        <button className='solution_btn_new' onClick={() => this.showSolution(item)}>View Solution</button>

                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </>
                                                    )
                                                }

                                                else {

                                                    return (
                                                        <div className='d-flex mob_view'>
                                                            <Card as={Card.Body} className="bg-light my-2">
                                                                <Row>
                                                                    <Col xl={12} lg={12} md={12} sm={12}>
                                                                        <Row>
                                                                            <Col xl={12} lg={12} md={12} sm={12} className="my-2">
                                                                                <div className="d-flex align-items-center" >
                                                                                    <span className='font-weight-bold'>{index + 1 + '.'}</span>
                                                                                    <div className="ml-2 font-weight-bold ">{parse(item.question_name)}</div>
                                                                                </div>
                                                                                <p className='float-right '>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>

                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className='f-16'>A.</span>
                                                                                    <div className="ml-2">{parse(item.option1)}</div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className='f-16'>B.</span>
                                                                                    <div className="ml-2">{parse(item.option2)}</div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className='f-16'>C.</span>
                                                                                    <div className="ml-2">{parse(item.option3)}</div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className='f-16'>D.</span>
                                                                                    <div className="ml-2">{parse(item.option4)}</div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xl={12} lg={12} md={12} sm={12} className="">
                                                                                <button className='solution_btn' type="button" onClick={() => this.showSolution(item)}>View Solution</button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </div>
                                                    )
                                                }

                                            })

                                        )
                                        :
                                        (
                                            this.state.module == "previous_questions" && this.props.history.location.state.isPreviousQuestions == true && (this.props.history.location.state.exam_type_value == 12 || this.props.history.location.state.exam_type_value == 13) ?
                                                ("")
                                                :
                                                (
                                                    <Row>
                                                        <Col sm={12}>
                                                            <p className='text-center font-weight-bold ver_align'>Coming Soon...</p>
                                                        </Col>
                                                    </Row>
                                                )
                                        )
                                }
                            </div>
                        )
                        :
                        (
                            this.state.module == "previous_questions" && this.props.history.location.state.isPreviousQuestions == undefined ?
                                (
                                    <>
                                        <Row className='mt-3'>
                                            <Col sm={12}>
                                                <div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="AP_EAPCET" value="AP_EAPCET" name="input_exam_type" className="custom-control-input" checked={this.state.apeamcetCheckbox} onChange={(e) => this.handleQuestionsType(e)} />
                                                        <label className="custom-control-label inp_text" htmlFor="AP_EAPCET" >AP EAPCET</label>
                                                    </div>

                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="TS_EAMCET" value="TS_EAMCET" name="input_exam_type" className="custom-control-input" checked={this.state.tseamcetCheckbox} onChange={(e) => this.handleQuestionsType(e)} />
                                                        <label className="custom-control-label inp_text" htmlFor="TS_EAMCET" >TS EAMCET </label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="AP_IPE" value="AP_IPE" name="input_exam_type" className="custom-control-input" checked={this.state.apIPEcheckbox} onChange={(e) => this.handleQuestionsType(e)} />
                                                        <label className="custom-control-label inp_text" htmlFor="AP_IPE" >AP IPE</label>
                                                    </div>

                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="TS_IPE" value="TS_IPE" name="input_exam_type" className="custom-control-input" checked={this.state.tsIPEcheckbox} onChange={(e) => this.handleQuestionsType(e)} />
                                                        <label className="custom-control-label inp_text" htmlFor="TS_IPE" >TS IPE </label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* {
                                            this.state.exam_type_value == 12 || this.state.exam_type_value == 13 ?
                                            (
                                                <Row>
                                            <Col sm={12}>

                                                <div className='float-right'>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="All" value="All" name="marks" className="custom-control-input" checked={this.state.allCheckBox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                        <label className="custom-control-label inp_text" htmlFor="All" >All</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="two_marks" value="two_marks" name="marks" className="custom-control-input" checked={this.state.twoMarksCheckbox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                        <label className="custom-control-label inp_text" htmlFor="two_marks" >2 Marks</label>
                                                    </div>

                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="four_marks" value="four_marks" name="marks" className="custom-control-input" checked={this.state.fourMarksCheckbox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                        <label className="custom-control-label inp_text" htmlFor="four_marks" >4 Marks</label>
                                                    </div>
                                                    {
                                                        this.props.history.location.state.subject_id == 4 ?
                                                            (
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" id="seven_marks" value="seven_marks" name="marks" className="custom-control-input" checked={this.state.sevenMarksCheckbox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                                    <label className="custom-control-label inp_text" htmlFor="seven_marks" >7 Marks</label>
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" id="eight_marks" value="eight_marks" name="marks" className="custom-control-input" checked={this.state.eightMarksCheckbox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                                    <label className="custom-control-label inp_text" htmlFor="eight_marks" >8 Marks</label>
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                            </Col>

                                        </Row>
                                            )
                                            :
                                            ("")
                                        } */}

                                        <Row>
                                            <Col>
                                                <Card as={Card.Body} className="bg-light my-2">
                                                    {
                                                        previousYearQuestions.length > 0 ?
                                                            (
                                                                previousYearQuestions.map((item, index) => {
                                                                    if (item.qtype == 3 || item.qtype == 9) {
                                                                        let question1 = item.question_name.replace(/src="/g, 'src=\\"');
                                                                        let question2 = question1.replace(/" \/>/g, '\\" />');
                                                                        console.log("question2question2", question2)
                                                                        let question = [];

                                                                        try {
                                                                            question = JSON.parse(question2);
                                                                        }
                                                                        catch (err) {
                                                                            console.log("MATRIX ERROR:", err.message);
                                                                        }
                                                                        return (
                                                                            <>
                                                                                <Card as={Card.Body} className="bg-light my-2 mob_view">
                                                                                    <Row>
                                                                                        <Col xl={12} lg={12} md={12} sm={12}>
                                                                                            <Row>
                                                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                                    <span className='font-weight-bold'>{index + 1 + '.'}</span>

                                                                                                    <ol className="matrixlistoptions" type={
                                                                                                        item.list1type == "alphabets" ? ("A")
                                                                                                            : (item.list1type == "numbers") ? ("1")
                                                                                                                : ("roman")}
                                                                                                    >
                                                                                                        {question.map((item) => (
                                                                                                            <li className='font-weight-bold'>{parse(item.qlist1)}</li>
                                                                                                        ))}
                                                                                                    </ol>
                                                                                                </Col>
                                                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                                    <ol className="matrixlistoptions" type={
                                                                                                        item.list2type == "alphabets" ? ("A")
                                                                                                            : (item.list2type == "numbers") ? ("1")
                                                                                                                : ("roman")}>
                                                                                                        {question.map((item) => (
                                                                                                            <li className='font-weight-bold'>{parse(item.qlist2)}</li>
                                                                                                        ))}
                                                                                                    </ol>
                                                                                                </Col>
                                                                                                <p className='float-right'>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <span className='f-16'>A.</span>
                                                                                                        <div className="ml-2">{parse(item.option1)}</div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <span className='f-16'>B.</span>
                                                                                                        <div className="ml-2">{parse(item.option2)}</div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <span className='f-16'>C.</span>
                                                                                                        <div className="ml-2">{parse(item.option3)}</div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <span className='f-16'>D.</span>
                                                                                                        <div className="ml-2">{parse(item.option4)}</div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col xl={12} lg={12} md={12} sm={12} className="my-2">
                                                                                                    <button className='solution_btn_new' onClick={() => this.showSolution(item)}>View Solution</button>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Card>
                                                                            </>
                                                                        )
                                                                    }
                                                                    else if (item.qtype == "8") {
                                                                        return (
                                                                            <>
                                                                                <Card as={Card.Body} className="bg-light my-2 mob_view">
                                                                                    <span className='font-weight-bold'>{index + 1 + '.'}</span>
                                                                                    {parse(item.question_name)}
                                                                                    <p className='float-right '>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>

                                                                                    <Row>
                                                                                        <Col xl={12} lg={12} md={12} sm={12} className="my-2">
                                                                                            <button className='solution_btn_new' onClick={() => this.showSolution(item)}>View Solution</button>

                                                                                        </Col>
                                                                                    </Row>

                                                                                </Card>
                                                                            </>
                                                                        )

                                                                    }
                                                                    else if (item.qtype == "5") {
                                                                        return (
                                                                            <>
                                                                                <Card as={Card.Body} className="bg-light my-2 mob_view">
                                                                                    <span className='font-weight-bold'>{index + 1 + '.'}</span>
                                                                                    {parse(item.question_name)}
                                                                                    <p className='float-right '>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>

                                                                                    <Row>
                                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <span className='f-16'>A.</span>
                                                                                                <div className="ml-2">{parse(item.option1)}</div>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <span className='f-16'>B.</span>
                                                                                                <div className="ml-2">{parse(item.option2)}</div>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <span className='f-16'>C.</span>
                                                                                                <div className="ml-2">{parse(item.option3)}</div>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <span className='f-16'>D.</span>
                                                                                                <div className="ml-2">{parse(item.option4)}</div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col xl={12} lg={12} md={12} sm={12} className="my-2 ">
                                                                                            <button className='solution_btn_new' onClick={() => this.showSolution(item)}>View Solution</button>

                                                                                        </Col>
                                                                                    </Row>
                                                                                </Card>
                                                                            </>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <div className='d-flex mob_view'>
                                                                                <Card as={Card.Body} className="bg-light my-2">
                                                                                    <Row>
                                                                                        <Col xl={12} lg={12} md={12} sm={12}>
                                                                                            <Row>
                                                                                                <Col xl={12} lg={12} md={12} sm={12} className="my-2">
                                                                                                    <div className="d-flex align-items-center" >
                                                                                                        <span className='font-weight-bold'>{index + 1 + '.'}</span>
                                                                                                        <div className="ml-2 font-weight-bold ">{parse(item.question_name)}</div>
                                                                                                    </div>
                                                                                                    <p className='float-right '>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>
                                                                                                    {
                                                                                                        this.state.exam_type_value == 12 || this.state.exam_type_value == 13 ?
                                                                                                            (<p className='mt-2'><span className=''>({item.marks}</span> Marks question)</p>)
                                                                                                            :
                                                                                                            ("")
                                                                                                    }
                                                                                                </Col>
                                                                                            </Row>
                                                                                            {
                                                                                                this.state.exam_type_value == 12 || this.state.exam_type_value == 13 ?
                                                                                                    (
                                                                                                        <Row>
                                                                                                            <Col xl={12} lg={12} md={12} sm={12} className="">
                                                                                                                <button className='solution_btn' type="button" onClick={() => this.showSolution(item)}>View Solution</button>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    )
                                                                                                    :
                                                                                                    (
                                                                                                        <>
                                                                                                            <Row>
                                                                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                                                    <div className="d-flex align-items-center">
                                                                                                                        <span className='f-16'>A.</span>
                                                                                                                        <div className="ml-2">{parse(item.option1)}</div>
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                                                    <div className="d-flex align-items-center">
                                                                                                                        <span className='f-16'>B.</span>
                                                                                                                        <div className="ml-2">{parse(item.option2)}</div>
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                                                    <div className="d-flex align-items-center">
                                                                                                                        <span className='f-16'>C.</span>
                                                                                                                        <div className="ml-2">{parse(item.option3)}</div>
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                                                                    <div className="d-flex align-items-center">
                                                                                                                        <span className='f-16'>D.</span>
                                                                                                                        <div className="ml-2">{parse(item.option4)}</div>
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                            <Row>
                                                                                                                <Col xl={12} lg={12} md={12} sm={12} className="">
                                                                                                                    <button className='solution_btn' type="button" onClick={() => this.showSolution(item)}>View Solution</button>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </>
                                                                                                    )
                                                                                            }
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Card>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                            :
                                                            (
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        <p className='text-center font-weight-bold ver_align'>Questions not Available for selected Chapter</p>
                                                                    </Col>
                                                                </Row>)
                                                    }
                                                </Card>
                                            </Col>
                                        </Row>
                                    </>
                                )
                                :
                                ("")
                        )
                }

                {
                    this.state.module == "previous_questions" && this.props.history.location.state.isPreviousQuestions == true && (this.props.history.location.state.exam_type_value == 12 || this.props.history.location.state.exam_type_value == 13) ?
                        (
                            <>

                                <Row>
                                    <Col sm={12}>
                                        <div className='float-right'>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="All" value="All" name="marks" className="custom-control-input" checked={this.state.allCheckBox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                <label className="custom-control-label inp_text" htmlFor="All" >All</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="two_marks" value="two_marks" name="marks" className="custom-control-input" checked={this.state.twoMarksCheckbox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                <label className="custom-control-label inp_text" htmlFor="two_marks" >2 Marks</label>
                                            </div>

                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="four_marks" value="four_marks" name="marks" className="custom-control-input" checked={this.state.fourMarksCheckbox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                <label className="custom-control-label inp_text" htmlFor="four_marks" >4 Marks</label>
                                            </div>
                                            {
                                                this.props.history.location.state.subject_id == 4 ?
                                                    (
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="seven_marks" value="seven_marks" name="marks" className="custom-control-input" checked={this.state.sevenMarksCheckbox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                            <label className="custom-control-label inp_text" htmlFor="seven_marks" >7 Marks</label>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="eight_marks" value="eight_marks" name="marks" className="custom-control-input" checked={this.state.eightMarksCheckbox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                            <label className="custom-control-label inp_text" htmlFor="eight_marks" >8 Marks</label>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </Col>
                                </Row>

                                <Card className='p-3'>
                                    {
                                        IPEPreviousQuestions.length > 0 ?
                                            (
                                                IPEPreviousQuestions.map((item, index) => {
                                                    return (
                                                        <Card as={Card.Body} className="bg-light my-2">
                                                            <Row>
                                                                <Col xl={12} lg={12} md={12} sm={12} className="my-2">
                                                                    <div className="d-flex align-items-center" >
                                                                        <span className='font-weight-bold'>{index + 1 + '.'}</span>
                                                                        <div className="ml-2 font-weight-bold ">{parse(item.question_name)}</div>
                                                                    </div>
                                                                    <p className='float-right '>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl={12} lg={12} md={12} sm={12} className="">
                                                                    <button className='solution_btn' type="button" onClick={() => this.showSolution(item)}>View Solution</button>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    )
                                                })
                                            )
                                            :
                                            (
                                                <>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <p className='text-center font-weight-bold'>Questions Not available for selected criteria</p>

                                                        </Col>

                                                    </Row>
                                                </>
                                            )
                                    }
                                </Card>
                            </>
                        )
                        :
                        ("")
                }
                <ExplanationModal
                    show={this.state.solutionModal}
                    onHide={() => this.setState({ solutionModal: false })}
                    solution={this.state.solution}
                />
                <EamcetRestrictionAlert
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />


            </Container >
        )
    }
}
export default withRouter(withApollo(compose(
)(ChapterWiseLearningSection)));