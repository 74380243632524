import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import * as Cookies from "es-cookie"
import EamcetLongTermCourseMainBlock from '../components/Eamcet_LongTerm_Course/EamcetLongTermCourseMainBlock';

import { Container, Row, Col, Image } from 'react-bootstrap'

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";


import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import EamcetRestrictionAlert from "../components/home/EamcetRestrictionAlert";


const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

const FETCH_SERVICE_DATA_FOR_EAMCET_CLASSES = gql` 
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String,
    $mobile:String
    ){
        getServiceData(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type,
            mobile:$mobile){
            data
            enabled
            }
        }
    `;


class EamcetCrashCourse extends Component {
    constructor(props) {
        super(props)

        this.state = {
            headerBottomImg: {
                type: "eamcet_view",
                title: "LONG TERM COURSE"
            },
            toggled: Cookies.get("toggle"),
            submitError: "",
            userRestionModalShow: false,
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }
    componentDidMount = () => {

        this.addStudentAnalytics();

        if (this.isStudentUserValid.current_plan_id != 65) {
            this.setState({ userRestionModalShow: true });
        }

        const title = GoogleAnalyticsArray[0].Eamcet_Crash_Course;
        ReactGA.pageview('/student/eamcet-crashcourse/home', ["rizee"], title);
    }

    closeModal = () => {
        this.setState({
            userRestionModalShow: false,
        });

    }
    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 215,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    render() {

        const getServiceData = this.props.getServiceData
        const loading = getServiceData.loading
        const error = getServiceData.error



        if (error !== undefined) {
            alert("Server Error. " + error.message);
            return null;
        }


        let enabled = ""

        if (loading != true) {
            enabled = getServiceData.getServiceData.length > 0 && getServiceData.getServiceData[0].enabled
        }


        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }

        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars
                        headerBottom={this.state.headerBottomImg}
                        onClick={() => this.menuToggler()} pageType={""} />
                </div>
                <div className="main-wrapper">

                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0" style={{ minHeight: "50vh" }}>
                        <EamcetLongTermCourseMainBlock />
                        {
                            enabled == true ?
                                (
                                    <EamcetRestrictionAlert
                                        show={this.state.userRestionModalShow}
                                        onHide={() => this.closeModal()}
                                        buildType="lt_course"
                                    />
                                )
                                :
                                ("")
                        }
                    </div>
                </div>
            </div>

        )
    }
}

export default withRouter(compose(

    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
    graphql(FETCH_SERVICE_DATA_FOR_EAMCET_CLASSES, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                service_type: "Eamcet_Long_Term_Course",
                service_sub_type: "ALL",
                mobile: Cookies.get("mobile")
            },
            fetchPolicy: 'network-only'

        }),
        name: "getServiceData"
    }),   

)(EamcetCrashCourse));
