import React, { Component } from 'react'
import SideNavbar from '../components/navbars/SideNavbar'
import NavbarOne from '../components/navbars/NavbarOne';
import Preloader from '../components/preloader/Preloader';
import StudentOrganizationProfileSection from '../components/home/student_organization_profile/StudentOrganizationProfileSection';
import Footer from '../components/footer/Footer';

class StudentOrganizationProfile extends Component {

    render() {
        console.log("StudentOrganizationProfile8", this.props.history.location.state);
        return (
            <React.Fragment>
                <div className="left-side-menu">
                    <SideNavbar onClick={() => this.props.changeToggle()} />
                </div>
                <div className="content-page">
                    {/* <Preloader /> */}
                    <NavbarOne onClick={() => this.props.changeToggle()} />
                    <div className="overlay" onClick={() => this.props.changeToggle()} />
                    <div className="main-content">
                        <StudentOrganizationProfileSection
                            hislocationData={this.props.history.location.state} />
                    </div>
                    <Footer />
                </div>
            </React.Fragment>
        )
    }
}

export default StudentOrganizationProfile
