import React, { Component } from 'react'
import VideoNavbar from '../components/learn_practice/videos/singlevideo/VideoNavbar'
import '../components/learn_practice/videos/singlevideo/_singlevideo.scss'
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from "lodash.flowright";
import SingleVideoWatchingSection from "../components/learn_practice/videos/SingleVideoWatchingSection";
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import { withRouter } from 'react-router-dom';

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import * as Cookies from "es-cookie";


const FETCH_GLOBALS = gql`
  query($mobile: String) {
    studentGlobals(mobile: $mobile) {
      reports {
        id
        report
      }
      tags {
        id
        tag
        type
      }
      contentTypes {
        id
        customcontent
      }
    }
  }
`;
const FETCH_VIDEOS = gql`
  query($params: VideosInput) {
    getVideos(
      params: $params
    ) {
      videoDetails{
        totalVideos
        videosList{
          id
          paid_video
          title
          class
          description
          video_url
          vimeo_url
          subjectName
          ChapterName
          topicName
          likes
          dislikes
          views
          is_purchased
          thumbnail
          video_id
          created_timestamp
          pdf_file
          video_source
        }
      }
      }
  }
`;

class SingleVideoWatchingAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            views: "",
            urltype:"notification",
            getChapterId:{
                type:"iv"
            }
        };

    }
    componentDidMount() {
        const title = GoogleAnalyticsArray[0].Videos_Playing;
        ReactGA.pageview('/student/subject/start-video-watching', ["rizee"], title);

        window.addEventListener('contextmenu',
            event => event.preventDefault());
        window.addEventListener("selectstart", e => {
            e.preventDefault();
            e.returnValue = "false";

        });
    }

    componentWillUnmount() {
        window.removeEventListener('contextmenu', () => { });
        window.removeEventListener("selectstart", () => { });

    }

    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");
        const studentGlobals = this.props.studentGlobals;
        const loading3 = studentGlobals.loading;
        const error3 = studentGlobals.error;
        const getVideos = this.props?.getVideos;
        const loading2 = getVideos.loading;
        const error2 = getVideos.error;

        console.log("getVideos",this.props)
        if (loading3 || loading2 ) {
            return (<PreloaderTwo />);
        }
        if (error3 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }
        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }

        return (
            <section className="single_video">
                 <VideoNavbar
                    urltype={this.state.urltype}
                    getChapterId={this.state.getChapterId}
                    />
                <SingleVideoWatchingSection

                    urltype={this.state.urltype}
                    stateData={this.state}
                    studentGlobals={studentGlobals.studentGlobals}
                    getData={getVideos?.getVideos?.videoDetails}
                     />
            </section>
        )
    }
}
export default
    withRouter(
        compose(

            graphql(FETCH_GLOBALS,
                {
                    options: props => ({
                        variables: {
                            mobile: Cookies.get("mobile")
                        }
                    }), name: "studentGlobals"
                }),
                graphql(FETCH_VIDEOS, {
                    options: (props) => ({
                      variables: {
                        params: {
                          mobile: Cookies.get("mobile"),
                          exam: parseInt(Cookies.get("examid")),
                          class1: 0,
                          institute_id: 0,
                          page: 1,
                          id:parseInt(props?.history?.location?.search?.slice(4))
                        }
                
                
                      },
                      fetchPolicy: 'no-cache'
                    }),
                    name: "getVideos",
                  })


        )(SingleVideoWatchingAction));
