import React, { Component } from 'react'
import Select from 'react-select';
import { components } from 'react-select'
import { Modal, Form, Col, Button } from 'react-bootstrap';
import BranchData from '../groups/BranchData'
import SectionData from '../groups/SectionData'
import ClassesData from '../groups/ClassesData'
import SelectDropDown from '../../selectdropdown/SelectDropDown';


import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as Cookies from "es-cookie";
import * as compose from 'lodash.flowright';

const Subjects = [
    { value: 1, label: 'Maths' },
    { value: 2, label: 'Physics' },
    { value: 3, label: 'Biology' },
    { value: 4, label: 'Chemistry' }
];



const FETCH_TEACHERS = gql`
  query($institution_id: Int!) {
    getTeachers(institution_id: $institution_id){
        id
        teacher_name
	    contact_no
	    subject_id
        branch_id
        class_ids
        section_ids
        }
    }
`;

class TeacherModalEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
            teacher_name: "",
            contact_no: "",
            subject: "",
            branch: "",
            section: "",
            class: "",
            id: 0,
            submitError: "",
            formErrors: {
                teacher_name: "",
                contact_no: "",
                subject: "",
                branch: "",
                class: "",
                section: ""
            },
            teacher_nameValid: false,
            contact_noValid: false,
            subjectValid: false,
            branchValid: false,
            formValid: false
        };
    }



    render() {
        return (
            <Modal {...this.props}
                size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Teacher</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    {this.props.stateData.currentStep == 5 ? (
                        <Form.Text className="form-text text-danger">
                            Teacher Data Updated successfully
                        </Form.Text>
                    ) : (
                            <Form.Text className="form-text text-danger">
                                {this.props.stateData.submitError}
                            </Form.Text>
                        )}
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} lg={6} md={12} sm={12} controlId="SelectTeacherName">
                                <Form.Label className="text-uppercase"> Teacher Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    name="teacher_name"
                                    onChange={this.props.parenthandleInputChange}
                                    autoComplete="off"
                                    value={this.props.stateData.teacher_name}
                                />
                                <Form.Text className="form-text text-danger">
                                    {this.props.stateData.formErrors.teacher_name}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group as={Col} lg={6} md={12} sm={12} controlId="SelectContact">
                                <Form.Label className="text-uppercase"> Contact No</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Mobile No"
                                    name="contact_no"
                                    onChange={this.props.parenthandleInputChange}
                                    autoComplete="off"
                                    value={this.props.stateData.contact_no}
                                />
                                <Form.Text className="form-text text-danger">
                                    {this.props.stateData.formErrors.contact_no}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group as={Col} lg={6} md={12} sm={12} controlId="SelectSubjects">
                                <Form.Label className="text-uppercase"> Subjects</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="subject"
                                    onChange={this.props.parenthandleInputChange}
                                    value={this.props.stateData.subject}>
                                    <option>--Select Subject--</option>
                                    {this.props.globals.subjects.map(subjectdata => (
                                        <option value={subjectdata.id}>{subjectdata.subject}</option>))}

                                </Form.Control>

                            </Form.Group>
                            <Form.Group as={Col} lg={6} md={12} sm={12} controlId="SelectBranch">
                                <Form.Label className="text-uppercase">Branch</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="branch"
                                    onChange={this.props.parenthandleInputChange}
                                    value={this.props.stateData.branch}>
                                    <option>--Select Branch--</option>
                                    {this.props.globals.globalBranches.map(branchesdata => (
                                        <option value={branchesdata.id}>{branchesdata.branch_name}</option>))}

                                </Form.Control>

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} lg={6} md={12} sm={12} controlId="SelectSection">
                                <Form.Label className="text-uppercase">Section</Form.Label>
                                <Select maxMenuHeight={150}
                                    defaultValue={this.props.getDefaultValuesSection(this.props.stateData.section, this.props.globals)}

                                    isMulti
                                    name="section"
                                    options={this.props.getValues(this.props.globals.globalSections)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.props.handleSelectInputChangeSection}
                                />

                            </Form.Group>
                            <Form.Group as={Col} lg={6} md={12} sm={12} controlId="SelectClass">
                                <Form.Label className="text-uppercase">Class</Form.Label>
                                <Select maxMenuHeight={150}
                                    defaultValue={this.props.getDefaultValues(this.props.stateData.class, this.props.globals)}

                                    isMulti
                                    name="class"
                                    options={this.props.getClassValues(this.props.globals.classes)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.props.handleSelectInputChange}
                                />

                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer variant="white" className="px-4">
                    <Button onClick={this.props.handleFormSubmit} className="btn btn-success text-uppercase" >

                        Save</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default (TeacherModalEdit);