import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_liveClassess.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import LiveClassesModal from '../liveClasses/LiveClassesModal';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';

import FileText from "../../../images/images1/FileText.png";
import FileArrowDown from "../../../images/images1/FileArrowDown.png";
//import Group3172 from "../../../images/images1/Group3172.png";

import UserRestrictionAlertInstitute from '../home/UserRestrictionAlertInstitute';
import LockSimple from "../../../images/LockSimple.png";
import * as Cookies from "es-cookie";
import moment from 'moment';

let QuestionData = [
    {
        DATE: "02-07-2021",
        ClassTimings: "4 PM To 5 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Morphology of Flowering Plants",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("cUNdkc0cCCo"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "4 PM To 5 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Introduction to  Botany  & Type of Questions",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("bNZri57P_HA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "4 PM To 5 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Botany- Importance of Flow Charts & Diagrams",
        // ShortNotes:"",
        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("IAGeMt0bQhU"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "4 PM To 5 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Basics in botany branches",
        // ShortNotes:"",
        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("A-SIpfEt3PI"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "THE LIVING WORLD",
        // ShortNotes: "",
        Assignments: ("https://rizee.in/econtent/LiveClass/D1_5639_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_305_Sol.pdf"),
        VideoLecture: ("ATmH9EUJAXg"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "08-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "BIOLOGICAL CLASSIFICATION-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_2338_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_2639_Sol.pdf"),
        VideoLecture: ("H_FtjAdRS-A"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "09-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "BIOLOGICAL CLASSIFICATION-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_7049_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_5747_Sol.pdf"),
        VideoLecture: ("LLzRd0qNV7M"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "10-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "SCIENCE OF PLANTS-BOTANY",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_8489_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_7426_Sol.pdf"),
        VideoLecture: ("WXX9cnskHJs"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "12-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "PLANT KINGDOM-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_8757_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_8923_Sol.pdf"),
        VideoLecture: ("Cd-zOAEMlRA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "13-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "PLANT KINGDOM-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_9571_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_9622_Sol.pdf"),
        VideoLecture: ("_4l6HLe0xGw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "MORPHOLOGY OF FLOWERING PLANTS-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_2586_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_5726_Sol.pdf"),
        VideoLecture: ("l77SVSF6UEU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "MORPHOLOGY OF FLOWERING PLANTS-2",
        ShortNotes: "",
        Assignments: ("https://rizee.in/econtent/LiveClass/D8_4527_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_8396_Sol.pdf"),
        VideoLecture: ("zu-LlguZkzo"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "MODES OF REPRODUCTION",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_1812_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_5965_Sol.pdf"),
        VideoLecture: ("XMo2mX6mlxw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "SEXUAL REPRODUCTION IN FLOWERING PLANTS-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_781_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_6349_Sol.pdf"),
        VideoLecture: ("YN6BC_xSCLI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "SEXUAL REPRODUCTION IN FLOWERING PLANTS-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_9819_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_8279_Sol.pdf"),
        VideoLecture: ("30-QsKnU5hI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "TAXONOMY OF ANGIOSPERM",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_7064_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_9943_Sol.pdf"),
        VideoLecture: ("Sl5Qyiliy68"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "THE UNIT OF LIFE",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_3608_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_5125_Sol.pdf"),
        VideoLecture: ("u13NlKLpITk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "BIOMOLECULES",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_2118_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_2843_Sol.pdf"),
        VideoLecture: ("eG0jZYOdzkE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "CELL CYCLE AND CELL DIVISION",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_2507_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_4225_Sol.pdf"),
        VideoLecture: ("Y9PiGi4Bztg"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "HISTOLOGY AND ANOTOMY OF FLOWERING PLANTS",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_2457_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_9706_Sol.pdf"),
        VideoLecture: ("x4y-F1WNwbM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "ECOLOGICAL ADAPTATION,SUCCESSION AND ECOLOGICAL SERVICES",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_5391_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_7401_Sol.pdf"),
        VideoLecture: ("m0VrRzkkZd0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "ENZYMES",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_804_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_3753_Sol.pdf"),
        VideoLecture: ("lozGtuHX_Bk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "PHOTOSYNTHESIS IN HIGHER PLANTS",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_7052_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_3401_Sol.pdf"),
        VideoLecture: ("mktArZwcRMM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "RESPIRATION IN PLANTS",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_2151_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_1013_Sol.pdf"),
        VideoLecture: ("AOad89DPyMI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "PLANT GROWTH AND DEVELOPMENT",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_2483_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_1353_Sol.pdf"),
        VideoLecture: ("cXl2NHjzW-Q"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "MICRO BIOLOGY (BACTERIA AND VIRUSES)",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_2658_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_7432_Sol.pdf"),
        VideoLecture: ("AqL1MrMz7w4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "PRINCIPLES OF INHERITANCE AND VARIATION",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_9198_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_9723_Sol.pdf"),
        VideoLecture: ("EONZ5ivK0D4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "MOLECULAR BASIS OF INHERITANCE",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_8811_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_2066_Sol.pdf"),
        VideoLecture: ("vDXgoiNQ-xY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "BIOTECHNOLOGY PRINCIPLES AND PROCESSES",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_8237_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_5449_Sol.pdf"),
        VideoLecture: ("R4ZhipODSr0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "BIOTECHNOLOGY AND ITS APPLICATIONS",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_593_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_5602_Sol.pdf"),
        VideoLecture: ("wuQ210k0Q-o"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "MICROBES IN HUMEN WELFARE,STRATEGIES FOR ENHANCEMENT IN FOOD PRODUCTION",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_2607_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_5871_Sol.pdf"),
        VideoLecture: ("q3jVauob7qE"),
        Live: (""),
        Zoom: (""),
    },
];


let LiveClassesDataBotany = []

for (var i in QuestionData) {
    let currentDate = moment().format("DD/MM/YYYY");
    if ((QuestionData[i]["VideoLecture"] || QuestionData[i]["Live"] || QuestionData[i]["Zoom"]) && (currentDate == moment(QuestionData[i].DATE, "DD-MM-YYYY").format("DD/MM/YYYY"))) {
        LiveClassesDataBotany.push(QuestionData[i]);
    }
}

var time;
var Zoom;
var Livevideo;


class BotanyDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            dataLiveUrl: "",
            userRestionModalShow: false,
            allLiveClasses: true

        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }
    modalaFun = (row) => {
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });

    }
    modalFunSubscribed = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7 && Cookies.get("institution_id") == 30 && this.isStudentUserValid.current_plan_id == "23") {
            this.setState({
                userRestionModalShow: true,
            });
        } else {
            this.setState({
                liveModalShow: true,
                dataLiveUrl: row.Live

            });
        }



    }
    checkForDate = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7) {
            return true
        }
        return false
    }
    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }
    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    sortFuncDate(a, b, order, dataField, rowA, rowB) {
        if (order == 'desc') {

            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }
    actionFormatterAssignments(cell, row, rowIndex) {
        return (
            <>
                {
                    row.Assignments != "" ?
                        <div className="Assignments">
                            <img src={FileText} alt="file-text-img" ></img>
                            <a href={row.Assignments} target="_blank"> Assignment Download</a>
                        </div> : <h6 className="text-center">-</h6>
                }

                {
                    row.Solutions != "" ?
                        <div className="Solutions">
                            <img src={FileArrowDown} alt="File-Arrowdown-img"></img>
                            <a href={row.Solutions} target="_blank" > Solutions</a>
                        </div> : ""
                }
            </>

        )
    }

    actionFormatterVideoLecture(cell, row, rowIndex) {

        return (
            <>
                <div className="allsubjects">
                    {
                        row.Live == "" ?

                            row.VideoLecture != "" ?
                                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                : "-"


                            :
                            <>

                                {
                                    <a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-success zoom_effect effect-1">Zoom</button></a>
                                }
                                {
                                    <button type="button" className="btn btn-warning live live_effect effect-1" onClick={() => this.modalaFun(row.Live)}>Live</button>
                                }



                            </>
                    }

                </div>

            </>
        )
    }

    colFunction() {

        let columns = [
            // {
            //     dataField: "DATE",
            //     text: "Date",
            //     sort: true,
            //     headerStyle: {
            //         width: "13%"
            //     },
            //     style: {
            //         fontWeight: "bold !important"
            //     },
            //     sortFunc: this.sortFuncDate.bind(this)
            // },
            // {
            //     dataField: "ClassTimings",
            //     text: "Class Timings",
            //     sort: true,
            //     headerStyle: {
            //         width: "23%"
            //     },
            //     sortFunc: this.sortFuncMulti.bind(this)
            // },

            {
                dataField: "Subject",
                text: "Subject",
                style: (row, rowIndex) => {
                    return { fontWeight: "bold !important" };
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                    width: "35%"
                }
            },
            {
                dataField: "Assignments/Solution",
                text: "Assignment/Solution",
                headerStyle: {
                    width: "55%"
                },

                formatter: this.actionFormatterAssignments.bind(this)
            },
            // {
            //     dataField: "Solutions",
            //     text: "Solutions",
            //     formatter: this.actionFormatterSolutions
            // },
            {
                dataField: "Videos/Recorded/Live",
                text: "Videos(Recorded/Live)",
                formatter: this.actionFormatterVideoLecture.bind(this)
            },
            // {
            //     dataField: "Live/Zoom",
            //     text: "Live/Zoom",
            //     formatter: this.actionFormatterLive
            // },
        ];
        return columns;
    }

    render() {
        return (
            <Row>
                <Col align="right" sm={12}>
                    <h6 className="switch_button_cls mt-res-4">
                        <p className="allClassesAlignment">All Classes</p>
                        <label class="switch">
                            <input type="checkbox"
                                onChange={() => {

                                    this.setState((state) => {

                                        return {
                                            allLiveClasses: !state.allLiveClasses
                                        }
                                    })
                                }}
                                checked={!this.state.allLiveClasses} />
                            <span class="slider round"></span>
                        </label>
                        <p className="liveClassesAlignment">Live Classes</p>
                    </h6>
                </Col>
                <Col sm={12}>
                    {
                        this.state.allLiveClasses ?
                            <CardLessDataTableWithSearch
                                parentData={QuestionData}
                                particlecolumns={this.colFunction()}
                                defaultSorted={[{
                                    dataField: "ClassTimings",
                                    order: "asc"
                                }]}
                            />
                            :
                            <CardLessDataTableWithSearch
                                parentData={LiveClassesDataBotany}
                                particlecolumns={this.colFunction()}
                                defaultSorted={[{
                                    dataField: "ClassTimings",
                                    order: "asc"
                                }]}
                            />
                    }
                    {/* <Card>
                        <BootstrapTable
                            keyField="id"
                            columns={this.colFunction()}
                            data={QuestionData}
                            defaultSorted={[{
                                dataField: "ClassTimings",
                                order: "asc"
                            }]}
                            wrapperClasses="table-responsive"
                            bootstrap4

                        />
                    </Card> */}

                    <LiveClassesModal data={this.state.dataLiveUrl} show={this.state.liveModalShow} onHide={() => this.setState({ liveModalShow: false })} />
                    <UserRestrictionAlertInstitute
                        show={this.state.userRestionModalShow}
                        onHide={() => this.setState({ userRestionModalShow: false })} />
                </Col>
            </Row>

        )
    }

}
export default BotanyDataSection;