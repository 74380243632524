import React, { Component } from 'react'
import { Row, Col, Table, Image, Button, Container, Accordion, Card, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import { components } from 'react-select'
import * as Cookies from "es-cookie";
import moment from 'moment';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import './_collegeRankPredictor.scss';
import { withApollo } from "react-apollo";
import CardLessDataTableWithOutSearch from "../../../neetjee_guru/components/datatables/CardLessDataTableWithOutSearch"
import collegeImage from '../../../images/university-college-school-building-vector.png'

class CollegeDataModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branchWiseDataCounts: [],
            showSpinnerForCounts: "hide",
        }
    }

    getDetails = async (row) => {
        this.setState({ showSpinnerForCounts: "show", branchWiseDataCounts: [] })

        const result = await this.props.client.query({
            query: gql`
            query($college:Int ,$branch:Int,$state:Int){
                getBranchwiseSeatData(college:$college,branch:$branch,state:$state){
                    branch_name
                    no_of_seats
                    Minimum_Rank
                    Maximum_Rank
                    caste
                    sub_caste
                  }
            }
            `,
            variables: {
                college: this.props.collegeId,
                branch: row,
                state:this.props.state
            },
            fetchPolicy: 'network-only'
        })
        if (result.data.getBranchwiseSeatData.length > 0) {
            this.setState({
                branchWiseDataCounts: result.data.getBranchwiseSeatData,
                showSpinnerForCounts: "hide",
            })
        }
    }

    colFunction() {
        let columns = [
            {
                dataField: "no_of_seats",
                text: "Allocated Seats",
                sort: true
            },
            {
                dataField: "caste",
                text: "Reservation Category",
                sort: true
            },
            {
                dataField: "sub_caste",
                text: "Reservation Sub Category",
                sort: true
            },

            {
                dataField: "Minimum_Rank",
                text: "Min Rank Joined",
                sort: true
            },
            {
                dataField: "Maximum_Rank",
                text: "Max Rank Joined",
                sort: true
            }
        ];
        return columns;
    }

    clearfiled = () => {
        this.setState({
            branchWiseDataCounts: [],
            showSpinnerForCounts: "hide"
        })
    }

    render() {
        console.log("dddddddddddddddddd",this.props)
        let branchWiseData = this.props.BranchWiseData

        let collegeName = ""
        let collegeUrl = "";
        let collegeLocation = ""

        if (branchWiseData != "" && branchWiseData != undefined) {
            collegeName = branchWiseData[0].college_name
            collegeUrl = branchWiseData[0].college_website
            collegeLocation = branchWiseData[0].location
        }

        let nirfrank = ""
        let image = ''

        if (this.props.getNirfRanking != undefined && this.props.getNirfRanking != "") {
            nirfrank = this.props.getNirfRanking[0].rank
            image = this.props.getNirfRanking[0].image_path
        }

        return (
            <>
                <Modal {...this.props} className='BranchesData p-3'
                    size="xl" aria-labelledby="example-modal-sizes-title-lg max_width_cls">
                    <Modal.Header>
                        <Button variant="link p-0 close f-14" onClick={() => { this.props.onHide(); this.clearfiled() }}> <i className="fas fa-times" /> </Button>
                        <Row>
                            <Col sm={12}>
                                <h3 className='college_name'>{collegeName}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <img src={collegeImage} className="college_img" />
                            </Col>
                        </Row>

                        <Row className='web_view'>
                            <Col sm={6}>
                                <h3 className='college_rank'><span>{image == "" ? ("") : (<img src={image} className="logo-nirf" />)}</span>{nirfrank}</h3>
                            </Col>

                            <Col sm={6}>
                                <h6 className='float-right'><span className='location mr-2'><i class="far fa-map-marker-alt fa_colour fa-xs mr-2"></i> {collegeLocation}</span> <span ><a href={collegeUrl} className='coll_url' target="_blank"><i class="far fa-globe fa_colour fa-xs mr-2 "></i> {collegeUrl}</a></span></h6>
                            </Col>
                        </Row>

                        <Row className='mob_view'>
                            <Col sm={6}>
                                <h3 className='college_name_mob'><span>{image == "" ? ("") : (<img src={image} className="logo-nirf" />)}</span>{nirfrank}</h3>
                            </Col>
                            <Col sm={6}>
                                <h3 className='college_name_mob'><i class="far fa-map-marker-alt fa_colour fa-xs mr-2"></i> {collegeLocation}</h3>
                            </Col>

                            <Col sm={6}>
                                <a href={collegeUrl} className='college_name_mob' target="_blank"><i class="far fa-globe fa_colour fa-xs mr-2"></i> {collegeUrl}</a>
                            </Col>
                        </Row>
                    </Modal.Header>

                    <Modal.Body className='bg-popup' >
                        {
                            this.props.stateData == "show" ?
                                (<Row>
                                    <Col sm={12} className={this.props.stateData == "show" ? "showSpinnerCls text-center" : "hideSpinnerCls text-center"}>
                                        <div class="spinner-border text-primary text-center loading-img-center"></div>
                                    </Col>
                                </Row>)
                                :
                                ("")
                        }

                        {
                            branchWiseData != "" && branchWiseData != undefined ?
                                (
                                    <div className='p-3'>
                                    
                                        <Row className='mt-2'>
                                            <h1 className='coll_branches'>Available Branches</h1>
                                        </Row>
                                        <Row>
                                            {
                                                this.props.state == 2?
                                                (
                                                    <p className='text-danger'>* Seats Filled in 2021</p>
                                                )
                                                :
                                                (
                                                    <p className='text-danger'>* Seats Filled in 2022</p>
                                                )
                                            }
                                        </Row>
                                        <Row>
                                            {
                                                branchWiseData[0].branchDetails.map((item) => {
                                                    return (
                                                        <Col sm={3}>
                                                            <Card className='pred_card mt-1'>
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col sm={12}>
                                                                            <Card.Img src={item.image_path} className="branch_img pos_align" alt="image" />
                                                                        </Col>

                                                                        <Col sm={12}>
                                                                            <Card.Text className='branch_name text-center'>{item.branch_name}</Card.Text>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col sm={12}>
                                                                            <Card.Text className='seats_text text-center mt-2' >No.of Seats <span className='text-danger'>*</span> : <span className="seats_count font-weight-bold" onClick={(e) => this.getDetails(item.branch_id)}>{item.no_of_seats}</span></Card.Text>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </div>
                                )
                                :
                                ("")
                        }   

                        {
                            this.state.branchWiseDataCounts.length > 0 ?
                                (
                                    <>
                                        <h5 className='table_branch_name mt-2'> {this.state.branchWiseDataCounts[0].branch_name} Data</h5>
                                        <CardLessDataTableWithOutSearch
                                            parentData={this.state.branchWiseDataCounts}
                                            particlecolumns={this.colFunction()}

                                        />
                                    </>
                                )
                                :
                                (
                                    <Row>
                                        <Col sm={12} className={this.state.showSpinnerForCounts == "show" ? "showSpinnerCls text-center" : "hideSpinnerCls text-center"}>

                                            <div className={this.state.showSpinnerForCounts == "show" ? "spinner-border text-primary text-center loading-img-center" : ""} >
                                                {
                                                    this.state.showSpinnerForCounts == "hide" ? "No Data Available" : ""
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                )
                        }
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default withRouter(withApollo(compose(

)(CollegeDataModal)));