import React, { Component } from 'react';
import { Row, Col, Form, Button, Container, Card } from 'react-bootstrap';
import SelectDropDown from '../../../neetjee_guru/components/selectdropdown/SelectDropDown';
import { components } from 'react-select';
import moment from "moment";
import './_previousHistory.scss';
import { withRouter } from "react-router-dom";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
};

class PreviousExamHistorySection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: "All",
            yearVal: { value: 0, label: 'All' }
        }
    }

    handleSelectYear = (name, value, label) => {
        this.setState({ year: label.label, yearVal: label });
    }

    handleViewQuestionanswer = (rowIndex) => {
        console.log("hello", rowIndex.type)
        this.props.history.push({
            pathname: "/student/subject/exam-result",
            state: {
                htype: "history",
                sessionid: rowIndex.id,
                examtype: rowIndex.type
            }
        })
    }

    render() {
        let examsHistoryData = this.props.getStudentExamSessions.filter(item => item.type == 'previous_exam' && item.sub_type != 'custom');
        console.log("this.props", this.props);
        let yearsdata = [{ value: 0, label: 'All' }];
        if (examsHistoryData.length > 0) {
            let years = [... new Set(examsHistoryData.map(data => data.sub_type))];
            years.map((item, index) => {
                yearsdata.push({ value: index + 1, label: item })
            });
        }
        let filterHistoryData = [];
        if (this.state.year == 'All') {
            filterHistoryData = examsHistoryData
        } else {
            filterHistoryData = examsHistoryData.filter(item => item.sub_type == this.state.year);
        }
        return (
            <Container className='previousHistory'>
                {
                    examsHistoryData.length > 0 ? (
                        <>
                            <Row>
                                <Col>
                                    <h2 className='mt-2 text-center'>Exam History</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="d-flex  justify-content-left align-items-center px-2 mt-3">
                                        <Form.Label className="mb-0 mt-1 classSelect">Select Year :</Form.Label>
                                        <div className="ml-2" style={{ width: 90 }}>
                                            <SelectDropDown
                                                stateData={this.state.yearVal}
                                                handleChange={this.handleSelectYear}
                                                name="year"
                                                options={yearsdata}
                                                placeholderName={'ALL'}
                                                dropdownIndicator={{ DropdownIndicator }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    filterHistoryData.map(item => (
                                        <Col sm={6}>
                                            <Card className='mt-3 examCards'>
                                                <Card.Body>
                                                    <Row>
                                                        <Col>
                                                            <h6 className='text-center'>{item.exam_name} - {item.sub_type}</h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={7}>
                                                            <p className='mt-1'><span className='font-weight-bold'>Date:</span> {moment.unix(item.timestamp).format("DD-MM-YYYY")}</p>
                                                            <p className='mt-1'><span className='font-weight-bold'>Time: </span>{moment.unix(item.timestamp).format("hh:mm a")}</p>
                                                            <h5 className='mt-1'>{parseInt(item.correct_marks) - parseInt(item.negative_marks)} / {parseInt(item.total_marks)}</h5>
                                                        </Col>
                                                        <Col sm={5}>
                                                            <p className='mt-1'><span className='font-weight-bold'>Accuracy:</span> {item.accuracy} %</p>
                                                            <p className='mt-1'><span className='font-weight-bold'>Speed:</span> {item.speed} Sec/Per Q</p>
                                                            <Button className="mock-btn mt-1 float-right" onClick={() => this.handleViewQuestionanswer(item)} block>View Result</Button>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row>
                                <Col>
                                    <h2 className='mt-4 text-center'>Exam History</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <h6 className='text-center mt-4'>You have not attempted any exam</h6>
                                </Col>
                            </Row>
                        </>
                    )

                }

            </Container>
        )
    }
}
export default withRouter(PreviousExamHistorySection);
