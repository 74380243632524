import React, { Component } from 'react'
import { Row, Col, Table, Image, Button, Container, Accordion, Card, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import { components } from 'react-select'
import * as Cookies from "es-cookie";
import moment from 'moment';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter, Link } from "react-router-dom";
import '../studentTargeting/_studenttargetting.scss'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

class StudentTargettingPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {

        let link = this.props.targetPopupData.navigation_url.replace('https://rizee.in/student/action/', '');

        return (
            <Modal {...this.props} className='target_popup'
                size="md" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row>
                        <Link to={{
                            pathname: `/student/action/${link}`,
                        }} >
                            <img src={this.props.targetPopupData.web_image_path} className="target_pop_width" />
                        </Link>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(compose(
)(StudentTargettingPopup));