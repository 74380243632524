import React, { Component } from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import CrashCourseExamsSections from '../components/Neet_Crash_Course/exams/CrashCourseExamsSections';
import * as Cookies from "es-cookie";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import { ValuesOfCorrectType } from 'graphql/validation/rules/ValuesOfCorrectType';

const FETCH_ISSTUDENTUSERVALID = gql` 
query($mobile: String) {
    isStudentUserValid(mobile: $mobile){
        estatus
        current_plan_id
        expiry_date
        
        user_access_restictions
        module_restrictions
        chapter_ids
        previous_sets
     }
}

`;
const FETCH_NEET_CRASH_COURSE = gql`
    query($id : Int!){
        getGlobalType(id : $id){
            global_name
            comments
            global_data{
                global_type_id
                date_time
                value
            }
        }
    }
`;

const FETCH_COURSE_MODULE = gql`
    query($id : Int!){
        getCourseModuleAccess(id : $id){
            id
            course_name
            description
            exam_id
            courseModuleAccess {
              id
              student_plan_id
              course_id
              subject_ids
              subject_names
              course_module_ids
              courseModules {
                id
                course_id
                module_name
              }
            
            }
        }
    }
`;

const FETCH_GETSERIES = gql` 
query($mobile: String,$exam_group_id: Int) {
    getGroupWiseExams(mobile: $mobile,exam_group_id: $exam_group_id){

        freeExams{
            id
            exam_name
            exam_type
            exam_session_id
            sub_exam_type
            is_completed
            is_started
            title
            short_name
            amount
            speed
            accuracy
            correct_marks
            negative_marks
            total_marks
            start_time
            end_time
            is_purchased
            exam_started
            is_scheduled
            is_registered
            exam_expired
            exam_duration
            syllabus
            syllabus_desc
        }
        paidExams{
            id
            exam_name
            exam_type
            exam_session_id
            sub_exam_type
            is_completed
            is_started
            title
            short_name
            amount
            speed
            accuracy
            correct_marks
            negative_marks
            total_marks
            start_time
            end_time
            is_purchased
            exam_started
            is_scheduled
            is_registered
            exam_expired
            exam_duration
            syllabus
            syllabus_desc
        }

       
    }
}
`;

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

class NeetCrashCourseExams extends Component {
    constructor(props) {
        super(props)

        this.state = {
            headerBottomImg: {
                type:"Exam_pattern",
                title:"Live Test Series"
            },
            toggled: isMobile == true && Cookies.get("institution_id") == 30 ? "wrapper" : Cookies.get("toggle")
        }
    }
    componentDidMount = () => {
        this.addStudentAnalytics();
        const title = GoogleAnalyticsArray[0].Exams;
        ReactGA.pageview('/student/exams',["rizee"], title);
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 153,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
    
    
    menuToggler = () => {
        const toggled = this.state.toggled;
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    render() {
        const isStudentUserValid = this.props.isStudentUserValid;
        const loading2 = isStudentUserValid.loading;
        const error2 = isStudentUserValid.error;

        const getGroupWiseExams = this.props.getGroupWiseExams;
        const loading1 = getGroupWiseExams.loading;
        const error1 = getGroupWiseExams.error;

        const getCourseModuleAccess = this.props.getCourseModuleAccess;
        const loading3 = getCourseModuleAccess.loading;
        const error3 = getCourseModuleAccess.error;

        const getGlobalType = this.props.getGlobalType;
        const loading4 = getGlobalType.loading;
        const error4 = getGlobalType.error;
        console.log("getsss",this.props.getGlobalType.getGlobalType);

        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        if (error3 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }
        if (error4 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }

        console.log("getUserExplored", isStudentUserValid.isStudentUserValid);
        if (isStudentUserValid.isStudentUserValid != undefined) {
            if (isStudentUserValid.isStudentUserValid.estatus == 0) {
                Cookies.remove("token");
                Cookies.remove("username");
                Cookies.remove("refreshtoken");
                Cookies.remove("email");
                Cookies.remove("id");
                Cookies.remove("institutionid");
                Cookies.remove("userlevel");
                Cookies.remove("name");
                this.props.history.push("/student/login");
            }
            localStorage.removeItem('isStudentUserValid');
            localStorage.setItem(
                "isStudentUserValid",
                JSON.stringify(isStudentUserValid.isStudentUserValid)
            );
        }
        let getFreeExamsData =[],getPaidExamsData =[];
        if(getGroupWiseExams.getGroupWiseExams !=undefined){
            getGroupWiseExams.getGroupWiseExams.map(item => {
                item.freeExams.map(exams => {
                    getFreeExamsData.push(exams);
                })
            
            });

            getGroupWiseExams.getGroupWiseExams.map(item => {
                item.paidExams.map(exams => {
                    getPaidExamsData.push(exams);
                })
            
            });
        }
        
        return (
            <div className={this.state.toggled}>
                <div className="student main-wrapper">
                    <div className="student header-area exam_pattern_topnavbar">
                        <ScrollTopNavbar 
                        headerBottom={this.state.headerBottomImg}
                        onClick={() => this.menuToggler()} />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper-pad0">
                        {
                        loading1 == true  || loading3 == true || loading4 == true ?
                        (
                            <Container><Row className="my-3">
                                <Col>
                                    <div class="spinner-border text-primary text-center loading-img-center"></div>
                                </Col>
                            </Row></Container>
                        ): (
                            <CrashCourseExamsSections
                                isStudentUserValid={isStudentUserValid.isStudentUserValid}
                                getFreeExamsData={getFreeExamsData} 
                                getPaidExamsData={getPaidExamsData}
                                getCourseModuleAccess={this.props.getCourseModuleAccess}
                                getGlobalType={getGlobalType.getGlobalType}
                            />
                        )
                    }

                    </div>

                </div>
            </div>
        )
    }
}


export default withRouter(compose(
    graphql(FETCH_ISSTUDENTUSERVALID,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: 'network-only'
            }), name: "isStudentUserValid"
        }),
        graphql(FETCH_GETSERIES,
            {
                options: props => ({
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_group_id: 7
                    }
                    ,
                    fetchPolicy: 'network-only'
                }), name: "getGroupWiseExams"
            }),
            graphql(FETCH_COURSE_MODULE,
                {
                    options: props => ({
                        variables: {
                            id: 3
                        }
                        ,
                    }), name: "getCourseModuleAccess"
                }),
            graphql(FETCH_NEET_CRASH_COURSE,
                {
                    options:props => ({
                        variables:{
                            id:1
                        }
                        ,
                    }), name: "getGlobalType"
                }),
                graphql(UPDATE_STUDENT_ANALYTICS, {
                    name: "submitpt"
                }),
)(NeetCrashCourseExams));

