import React, { Component } from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import CrashCourseLiveClassesSection from '../components/Neet_Crash_Course/liveClass_sessions/CrashCourseLiveClassesSection';
import HomeNavbar from '../components/home/HomeNavbar';

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';

const FETCH_SERVICE_DATA_FOR_REPEATERS_CLASSES = gql` 
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String
    ){
        getServiceData(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type){
            data
            }
        }
    `;
    const VIDEO_DESCRIPTION = gql`
    query(
        $id : Int
        $video_code : String,
        $video_url : String
    ){
        getVideoDescription(
            id :$id
            video_code : $video_code
            video_url : $video_url
        ){
            video_code
            video_url
            description
        }
    }
    
    `;
    const FETCH_COURSE_MODULE = gql`
    query($id : Int!){
        getCourseModuleAccess(id : $id){
            id
            course_name
            description
            exam_id
            courseModuleAccess {
              id
              student_plan_id
              course_id
              subject_ids
              subject_names
              course_module_ids
              courseModules {
                id
                course_id
                module_name
              }
            
            }
        }
    }
`;
class LiveSessionsCrashCourse extends Component {
    constructor(props) {
        super(props)

        this.state = {
            toggled: Cookies.get("toggle")
        }
    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }

        const repeatersClassesDetails = this.props.repeatersClassesDetails;
        const getCourseModuleAccess = this.props.getCourseModuleAccess;
        const videoDescription = this.props.videoDescription;

        const loading1 = repeatersClassesDetails.loading;
        const error1 = repeatersClassesDetails.error;

        const loading2 = getCourseModuleAccess.loading;
        const error2 = getCourseModuleAccess.error;

        const loading3 = videoDescription.loading;
        const error3 = videoDescription.error;

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} pageType={"LiveSessionsCrashCourse"}/>
                </div>
                <div className="main-wrapper">
                
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0" style={{ minHeight: "50vh" }}>

                    {
                        loading1 == true || loading2 == true || loading3 == true ?
                        (
                            <Container><Row className="my-3">
                                <Col>
                                    <div class="spinner-border text-primary text-center loading-img-center"></div>
                                </Col>
                            </Row></Container>
                        ): (
                            <CrashCourseLiveClassesSection
                            repeatersClassesDetails={this.props.repeatersClassesDetails}
                            videoDescription={this.props.videoDescription}
                            getCourseModuleAccess={this.props.getCourseModuleAccess}
                        />)
                    }

                      
                    </div>
                </div>
            </div>

        )
        
    }
}

export default withRouter(compose(
    graphql(FETCH_SERVICE_DATA_FOR_REPEATERS_CLASSES  , {
            options: props => ({
                variables: {
                    exam_id : parseInt(Cookies.get("examid")),
                    service_type : Cookies.get("examid") == 1 ? "NEET_Crash_Course_Feb2022" : "JEE_2022_LIVE_CLASSES",
                    service_sub_type: "ALL"
                    
                },
                fetchPolicy: 'network-only'
            }),name: "repeatersClassesDetails"
        }),graphql(VIDEO_DESCRIPTION,{
            options: props => ({
                variables : {
                    id : 0
                }
    
            }), name: "videoDescription"
        }),
        graphql(FETCH_COURSE_MODULE,
            {
                options: props => ({
                    variables: {
                        id: 3
                    }
                    ,
                }), name: "getCourseModuleAccess"
            })
       
        )(LiveSessionsCrashCourse));

