import React, { Component } from 'react'
import { Modal, Card, Button, Row, Col, Container } from "react-bootstrap";
import "./_subscribe-plans.scss";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter, Link } from "react-router-dom";
import * as Cookies from "es-cookie";
import courseRestriction from "../../../images/course_restriction.png";
import ntaPredictor from '../../../images/images1/collegepredpopup.png'

const FETCH_PLANS = gql` 
query($exam_id: String) {
    getStudentPlans(exam_id: $exam_id){
        id
        plan_name
        plan_title
        amount
        original_amount
        image
        additional_text
        plan_features
     
    }
}
`;

const FETCH_DEFINED_PLANS = gql`
query($mobile:String){
        getSubscriptionPlans(mobile:$mobile) {
            id
            name
            exam_id
            student_plan_id
            order_by
            image_path
            description
            studentPlans{
                id
                plan_name
                plan_title
                amount
                exam_id
                original_amount
                valid_days
                image
                additional_text
                plan_features
                popup_plan_desc
                color
                sort_order
                coupon_display
                url
                expiry_date
                upgrade_access
                upgrade_amount
                upgrade_description
          }
        }
}
`

class UserRestrictionAlert extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subscribeTrending: false,
            selectedPlanDetailsShow: '',
            course_currentPlanId: 0

        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));

        }
    }
    paymentFun = (activePlan) => {

        //data= {...data, exam_name:this.props.exam_name}
        // this.props.history.push({
        //     pathname: "/student/subscribe-plans-order-summary",
        //     state: {
        //         data: data

        //     }
        // })
        this.props.history.push({
            pathname: "/student/package",
            state: {
                type: "subscribe",
                //oneweekplan:this.props.oneweekplan
                activePlan: activePlan
            }
        })
    }

    paymentOrderFun = (data) => {
        this.props.history.push({
            pathname: "/student/subscribe-plans-order-summary",
            state: {
                data: data
            }
        })
    }

    rankPredictorFunc = () => {
        this.props.history.push({
            pathname: "/student/subscribe-plans-order-summary",
            state: {
                data: { additional_text: "upto JEE 2023 exam", amount: 99, color: "#006c81", coupon_display: true, exam_id: "2", expiry_date: "", id: "79", image: "", original_amount: 99, plan_features: "Find the likelihood of acceptance into your dream college#Know your college admission chances based on your category, sub category and exam result#Get your EAMCET personalized report with Top Predicted Colleges.", plan_name: "College & Branch Predictor", plan_title: "1 Year", popup_plan_desc: "Your one stop for predicting the best colleges and branches for you", sort_order: 18, upgrade_access: false, upgrade_amount: 99, upgrade_description: "", url: "https://youtu.be/qRTjqv97gDs", valid_days: 90 }
            }
        })
    }

    getCrouseName = (getAllNewPlans1) => {
        let planName = "";
        console.log("planDetails", getAllNewPlans1)
        if (getAllNewPlans1 != undefined) {
            getAllNewPlans1.filter(checkPlanTypeName => checkPlanTypeName.id == this.state.selectedPlanDetailsShow).map(mainPlan => (
                planName = mainPlan.name
            ))
        }
        return planName;
    }

    render() {

        let subplan = [];

        const getStudentPlans = this.props.getStudentPlans;
        const loading1 = getStudentPlans.loading;
        const error1 = getStudentPlans.error;

        const getSubscriptionPlans = this.props.getSubscriptionPlans;
        const loading6 = getSubscriptionPlans.loading;
        const error6 = getSubscriptionPlans.error;

        if (error6 !== undefined) {
            alert("Server Error. " + error6.message);
            return null;
        }

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        if (this.props.planDetailsShow == "2") {
            this.state.selectedPlanDetailsShow = '2';
            this.state.course_currentPlanId = '25';
        } else if (this.props.planDetailsShow == "5") {
            this.state.selectedPlanDetailsShow = '5';
            this.state.course_currentPlanId = '36';
        } else {
            this.state.selectedPlanDetailsShow = '1';
        }

        const getPlansdata = getStudentPlans.getStudentPlans;

        return (
            this.props.buildType == "ntaRankPredictor" ?
                (
                    <Modal {...this.props} size="md" aria-labelledby="example-modal-sizes-title-lg" className="nta_subscribe_popup">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            {
                                // loading6 == true ?
                                //     (
                                //         <Container>
                                //             <Row className="my-3">
                                //                 <Col>
                                //                     <div class="spinner-border text-primary text-center loading-img-center"></div>
                                //                 </Col>
                                //             </Row>
                                //         </Container>
                                //     )
                                //     :
                                //     (
                                //         getSubscriptionPlans != undefined && getSubscriptionPlans.getSubscriptionPlans.map(plans => (
                                //             plans.studentPlans.filter(item => item.id == 79).map(plandDetails => {
                                //                 return (
                                <>
                                    <Row>
                                        <Col sm={12}>
                                            <h6 className='nta_header'>JEE Mains & Advanced College and Branch Predictor</h6>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col sm={12}>
                                            <p className='nta_des'>Your one stop for predicting and targeting the best colleges and branches for you</p>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col sm={12}>
                                            <p className='nta_amount'><i class="fas fa-rupee-sign"></i>    99</p>

                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col sm={12}>
                                            <Button onClick={() => this.rankPredictorFunc()} className='nta_btn'>Subscribe Now</Button>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col sm={12}>
                                            <img src={ntaPredictor} className="nta_img" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p className='mt-2 nta_info'>Do you Need More Information on Plans</p>
                                            <p className='mt-1 nta_contact'> Contact us at <span className='color_purple'>+91 7075448813</span></p>
                                        </Col>
                                    </Row>

                                </>
                                //             )
                                //         })

                                //     ))
                                // )
                            }
                        </Modal.Body>
                    </Modal>
                )
                :
                this.props.getAccessSubjectNames != undefined && this.props.getAccessSubjectNames.length > 0 ?
                    (
                        <Modal {...this.props}
                            size="md" aria-labelledby="example-modal-sizes-title-lg" className="scribePlan-modal-courses">
                            <Button variant="link p-0 close" onClick={() => { this.props.onHide() }}> <i className="fas fa-times" /> </Button>
                            <Modal.Body className="">

                                <Card>
                                    <Card.Img variant="top" src={courseRestriction} />
                                    <div className='caret'></div>
                                    <Card.Body>
                                        <Card.Text>
                                            Hi, You haven't subscribed to this subject classes. Please upgrade your current subscription plans to join these classes. Contact us now at <b>+91 7075448813</b> to upgrade your subscription
                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                            </Modal.Body>
                        </Modal>
                    )
                    :
                    (
                        <Modal {...this.props}
                            size={this.props.planDetailsShow == "2" || this.props.planDetailsShow == "5" ? "max_width_cls" : "md"} aria-labelledby="example-modal-sizes-title-lg" className="scribePlan-modal">
                            <Button variant="link p-0 close" onClick={() => { this.props.onHide() }}> <i className="fas fa-times" /> </Button>

                            <Modal.Body className={this.props.planDetailsShow == "2" || this.props.planDetailsShow == "5" ? "modal_body_bg " : ""}>
                                <div className="text-center mb-4">

                                    <h4 className={this.props.planDetailsShow == "2" || this.props.planDetailsShow == "5" ? "text-yash font-weight-bold text-uppercase mb-1" : "mb-1 text-blue text-uppercase font-weight-bold"}>Subscribe - {this.props.planDetailsShow == "Neet_Jee_foundation" ? ("Foundation Course") : (this.getCrouseName(getSubscriptionPlans.getSubscriptionPlans))}</h4>
                                    <h6 className={this.props.planDetailsShow == "2" || this.props.planDetailsShow == "5" ? "text-yash text-uppercase font-weight-normal" : "font-weight-normal text-blue text-uppercase"}>Unlock &amp; Get Ready for exam</h6>

                                </div>
                                {loading1 ? (
                                    <Card>
                                        <div className="d-flex justify-content-center">
                                            <div class="spinner-border text-primary"></div>
                                        </div>
                                    </Card>
                                ) : (<React.Fragment>
                                    {
                                        getSubscriptionPlans.getSubscriptionPlans != undefined ?
                                            (
                                                this.props.planDetailsShow != "2" && this.props.planDetailsShow != "5" ?
                                                    (
                                                        this.props.planDetailsShow == "Neet_Jee_foundation" ?
                                                            (
                                                                getSubscriptionPlans.getSubscriptionPlans.sort((a, b) => b.id - a.id).map(mainPlan => (
                                                                    mainPlan.studentPlans.filter(item => item.id == "63" || item.id == "66").map((subplan, index) => {

                                                                        let colorname = "";
                                                                        if (subplan.id == "3 MONTHS") {
                                                                            colorname = "three-months";
                                                                        }
                                                                        else if (subplan.id == "6 MONTHS") {
                                                                            colorname = "six-months";
                                                                        }
                                                                        else if (subplan.id == "1 YEAR") {
                                                                            colorname = "one-year";
                                                                        }
                                                                        else if (subplan.plan_title == "12 MONTHS") {
                                                                            colorname = "one-year";
                                                                        }
                                                                        else if (subplan.plan_title == "1 WEEK") {
                                                                            colorname = "one-week";
                                                                        } else if (subplan.id == 27) {
                                                                            colorname = "one-week";
                                                                        } else if (subplan.id == 25) {
                                                                            colorname = "three-months";
                                                                        } else if (subplan.id == 36) {
                                                                            colorname = "one-week";
                                                                        } else if (subplan.id == 37) {
                                                                            colorname = "three-months";
                                                                        } else if (subplan.id == 63) {
                                                                            colorname = "foundation_1";
                                                                        } else if (subplan.id == 66) {
                                                                            colorname = "foundation_2";
                                                                        }
                                                                        else {
                                                                            colorname = "one-week";
                                                                        }

                                                                        let features = subplan.popup_plan_desc.split("#");
                                                                        return (
                                                                            <Card style={{ cursor: "pointer" }} onClick={() => this.paymentFun(mainPlan.id)} key={index} as={Card.Body} className={`subscribe-plans pl-4 py-2 ${colorname}`}>
                                                                                {subplan.plan_name == "3 MONTHS" ? (<div className="subscribe-lable text-uppercase">Trending</div>) : ("")}

                                                                                <div className="subscribe-content d-flex justify-content-between align-items-start">
                                                                                    <div className="content-left">
                                                                                        {
                                                                                            subplan.id == 27 || subplan.id == 25 || subplan.id == 36 || subplan.id == 37 ?
                                                                                                ("") :
                                                                                                (<p className="text-muted text-capitalize">{subplan.plan_title}</p>)
                                                                                        }

                                                                                        <h6 className="text-dark text-capitalize mb-0">{subplan.plan_name}</h6>
                                                                                        <small className="text-muted plan-price">{subplan.amount} only</small>
                                                                                        {subplan.popup_plan_desc != "" ? (
                                                                                            <React.Fragment>
                                                                                                {features.map((imap, index) => {

                                                                                                    if (index == 0) {
                                                                                                        return (<p className="text-uppercase plan-description"><i className="fas fa-circle" /> {imap}</p>)
                                                                                                    }

                                                                                                })}
                                                                                            </React.Fragment>

                                                                                        ) : ("")}

                                                                                    </div>
                                                                                    <div className="content-right">
                                                                                        <div className="price-block shadow-sm p-2">
                                                                                            <h6 className="mb-0"><i className="fas fa-rupee-sign text-white"></i> </h6>
                                                                                            <div className="text-right">
                                                                                                <div className="plan-price text-white">{subplan.amount}</div>
                                                                                                <small className="text-white-50">only</small>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Card>

                                                                        )
                                                                    })
                                                                ))
                                                            )
                                                            :
                                                            (
                                                                getSubscriptionPlans.getSubscriptionPlans.sort((a, b) => b.id - a.id).map(mainPlan => (
                                                                    mainPlan.studentPlans.filter(item => item.id != "63" && item.id != "66").map((subplan, index) => {
                                                                        console.log("subplae", subplan);

                                                                        let colorname = "";
                                                                        if (subplan.id == 3) {
                                                                            colorname = "three-months";
                                                                        }
                                                                        else if (subplan.id == 6) {
                                                                            colorname = "six-months";
                                                                        }
                                                                        else if (subplan.plan_title == "1 YEAR") {
                                                                            colorname = "one-year";
                                                                        }
                                                                        else if (subplan.id == 2) {
                                                                            colorname = "one-year";
                                                                        }
                                                                        else if (subplan.plan_title == "1 WEEK") {
                                                                            colorname = "one-week";
                                                                        } else if (subplan.id == 27) {
                                                                            colorname = "one-week";
                                                                        } else if (subplan.id == 25) {
                                                                            colorname = "three-months";
                                                                        } else if (subplan.id == 36) {
                                                                            colorname = "one-week";
                                                                        } else if (subplan.id == 37) {
                                                                            colorname = "three-months";
                                                                        } else if (subplan.id == 63) {
                                                                            colorname = "foundation_1";
                                                                        } else if (subplan.id == 66) {
                                                                            colorname = "foundation_2";
                                                                        }
                                                                        else {
                                                                            colorname = "one-week";
                                                                        }

                                                                        let features = subplan.popup_plan_desc.split("#");
                                                                        return (
                                                                            <Card style={{ cursor: "pointer" }} onClick={() => this.paymentFun(mainPlan.id)} key={index} as={Card.Body} className={`subscribe-plans pl-4 py-2 ${colorname}`}>
                                                                                {subplan.plan_title == "3 MONTHS" ? (<div className="subscribe-lable text-uppercase">Trending</div>) : ("")}

                                                                                <div className="subscribe-content d-flex justify-content-between align-items-start">
                                                                                    <div className="content-left">
                                                                                        {
                                                                                            subplan.id == 27 || subplan.id == 25 || subplan.id == 36 || subplan.id == 37 ?
                                                                                                ("") :
                                                                                                (<p className="text-muted text-capitalize">{subplan.plan_title}</p>)
                                                                                        }

                                                                                        <h6 className="text-dark text-capitalize mb-0">{subplan.plan_name}</h6>
                                                                                        <small className="text-muted plan-price">{subplan.amount} only</small>
                                                                                        {subplan.popup_plan_desc != "" ? (
                                                                                            <React.Fragment>
                                                                                                {features.map((imap, index) => {
                                                                                                    if (index == 0) {
                                                                                                        return (<p className="text-uppercase plan-description"><i className="fas fa-circle" /> {imap}</p>)
                                                                                                    }

                                                                                                })}
                                                                                            </React.Fragment>

                                                                                        ) : ("")}

                                                                                    </div>
                                                                                    <div className="content-right">
                                                                                        <div className="price-block shadow-sm p-2">
                                                                                            <h6 className="mb-0"><i className="fas fa-rupee-sign text-white"></i> </h6>
                                                                                            <div className="text-right">
                                                                                                <div className="plan-price text-white">{subplan.amount}</div>
                                                                                                <small className="text-white-50">only</small>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    subplan.upgrade_access == true && subplan.upgrade_amount != subplan.amount ?
                                                                                        (
                                                                                            <p className='text-center font-weight-bold blink_me'> Upgrade to this plan with {subplan.upgrade_amount + " /-" + "only"}</p>
                                                                                        )
                                                                                        :
                                                                                        ("")
                                                                                }
                                                                            </Card>

                                                                        )
                                                                    })
                                                                ))
                                                            )


                                                    )
                                                    :
                                                    (

                                                        <Row>
                                                            <Col sm={12}>
                                                                <div class="white_bg">
                                                                    {
                                                                        getSubscriptionPlans.getSubscriptionPlans.filter(checkPlanTypeName => checkPlanTypeName.id == this.state.selectedPlanDetailsShow).map(mainPlan => (
                                                                            mainPlan.studentPlans.filter(subplanId => subplanId.id == this.state.course_currentPlanId).map((subplan, index) => {
                                                                                let features = subplan.popup_plan_desc.split("#");
                                                                                return (
                                                                                    // let features = subplan.plan_features.split("#");
                                                                                    <>
                                                                                        <h6 className="color_blue text-center-res">{subplan.plan_name}
                                                                                            <br className="res_show" />
                                                                                            <span className="float-right-res">
                                                                                                <span class="color_strike_rest font-weight-normal"><i class="fas fa-rupee-sign f-13"></i> {subplan.original_amount}</span>
                                                                                                <span className="ml-2 color_blue f-20"><i class="fas fa-rupee-sign"></i> {subplan.amount}/-</span>
                                                                                            </span>
                                                                                        </h6>
                                                                                        <hr className="border_top_color" />
                                                                                        {subplan.popup_plan_desc != "" ? (

                                                                                            <React.Fragment>
                                                                                                <Row>
                                                                                                    {features.map((imap, index) => {
                                                                                                        return (
                                                                                                            <Col sm={6}>
                                                                                                                <h6 className="color_yash font-weight-normal mt-2"><i class="fas fa-check-circle "></i> {imap}</h6>
                                                                                                            </Col>
                                                                                                        )
                                                                                                    })}
                                                                                                </Row>
                                                                                            </React.Fragment>

                                                                                        ) : ("")}

                                                                                        <Button className={`button-blue center-auto mt-3`} onClick={() => this.paymentOrderFun(subplan)} ><i class="fas fa-chevron-right ml-3"></i> Subscribe Now</Button>
                                                                                        {<div className="coupons mt-2 text-center">
                                                                                            {subplan.additional_text != "" ? (<p> {subplan.additional_text}</p>) : ("")}

                                                                                        </div>}

                                                                                    </>
                                                                                )
                                                                            })))
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )

                                            ) : ("")
                                    }
                                </React.Fragment>)}
                            </Modal.Body>
                        </Modal>
                    )

        )
    }
}
export default withRouter(compose(
    graphql(FETCH_PLANS,
        {
            options: props => ({
                variables: {
                    exam_id: Cookies.get("examid")
                }
                ,
                fetchPolicy: 'network-only'
            }), name: "getStudentPlans"
        }),
    // graphql(FETCH_NEW_PLANS,
    //     {
    //         options: props => ({
    //             variables: {
    //                 id: 0
    //             }
    //             ,
    //             fetchPolicy: 'network-only'
    //         }), name: "getAllNewPlans_New"
    //     }),
    graphql(FETCH_DEFINED_PLANS,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: 'network-only'
            }), name: "getSubscriptionPlans"
        })
)(UserRestrictionAlert));
