import React, { Component } from 'react'
import { Row, Col, Tabs,Tab,Card,Table} from 'react-bootstrap';
import './_bluePrintexamanalysis.scss';
import BootstrapTable from 'react-bootstrap-table-next';

import * as Cookies from "es-cookie";

//// ApBipcData

let ApBipcData = [
    {
        CHAPTERNAME:"UNIT I - DIVERSITY IN THE LIVING WORLD",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "THE LIVING WORLD",
        NOOFQUESTIONS: "1",
      
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "BIOLOGICAL ClASSIFICATION",
        NOOFQUESTIONS: "2",
    },
    {
       
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "SCIENCE OF PLANTS - BOTANY",
        NOOFQUESTIONS: "1",
    },
    {
       
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PLANT KINGDOM",
        NOOFQUESTIONS: "2",
    },
    {
        CHAPTERNAME:"UNIT - II STRUCTURAL ORGANISATION IN PLANTS - MORPHOLOGY",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "MORPHOLOGY OF FLOWERING PLANTS",
        NOOFQUESTIONS: "3",
    },
    {
        CHAPTERNAME:"UNIT - III REPRODUCTION IN PLANTS",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "MODES OF REPRODUCTION",
        NOOFQUESTIONS: "1",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "SEXUAL REPRODUCTION IN FLOWERING PLANTS",
        NOOFQUESTIONS: "3",
    },
    {
        CHAPTERNAME:"UNIT - IV PLANT SYSTEMATICS",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "TAXONOMY OF ANGIOSPERMS",
        NOOFQUESTIONS: "1",
    },
    {
        CHAPTERNAME:"UNIT - V CELL : STRUCTURE AND FUNCTIONS",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "THE UNIT OF LIFE",
        NOOFQUESTIONS: "2",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "BIOMOLECULES",
        NOOFQUESTIONS: "2",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "CELL CYCLE AND CELL DIVISION",
        NOOFQUESTIONS: "1",
    },
    {
        CHAPTERNAME:"UNIT - VI INTERNAL ORGANISATION OF PLANTS",
    },

    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "HISTOLOGY AND ANATOMY OF FLOWERING PLANTS",
        NOOFQUESTIONS: "1",
    },

 
    {
        
        CHAPTERNAME: "PLANT ECOLOGY",

    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "ECOLOGICAL ADAPTATION, SUCCESSION AND ECOLOGICAL SERVICES",
        NOOFQUESTIONS: "2",
    },
    {
        CHAPTERNAME:"UNIT - I PLANT PHYSIOLOGY",
    },
    {
        CHAPTERNAME:"UNIT-1 PLANT PHYSIOLOGY",
    },
    {
        
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "TRANSPORT IN PLANTS",
        NOOFQUESTIONS: "1",
    },
    {
        
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "ENZYMES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "PHOTOSYNTHESIS IN HIGHER PLANTS",
        NOOFQUESTIONS: "1",
    },
    
    {
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "RESPIRATION IN PLANTS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "PLANT GROWTH AND DEVELOPMENT",
        NOOFQUESTIONS: "1",
    },
    {
        CHAPTERNAME:"UNIT - II MICROBIOLOGY"
    },
    {
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "BACTERIA",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "VIRUSES",
        NOOFQUESTIONS: "1",
    },
    {
        CHAPTERNAME:"UNIT - III GENETICS",
    },
    {
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "PRINCIPLES OF INHERITANCE AND VARIATION",
        NOOFQUESTIONS: "3",
    },
    {
        CHAPTERNAME:"UNIT - IV MOLECULAR BIOLOGY",
    },
    {
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "MOLECULAR BASIS OF INHERITANCE",
        NOOFQUESTIONS: "3",
    },
    {
        CHAPTERNAME:"UNIT - V BIOTECHNOLOGY",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"BIOTECHNOLOGY PRINCIPLES AND PROCESSES",
        NOOFQUESTIONS:"2",
    },
    
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"BIOTECHNOLOGY AND ITS APPLICATIONS",
        NOOFQUESTIONS:"1",
    },
    {
        CHAPTERNAME:"UNIT - VI PLANTS, MICROBES IN HUMAN WELFARE",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"STRATEGIES AND ENHANCEMENT IN FOOD PRODUCTION",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"MICROBES IN HUMAN WELFARE",
        NOOFQUESTIONS:"1",
    }
    

  ];
 ///  TsBipcData

  let TsBipcData = [
    {
        CHAPTERNAME:"UNIT I - DIVERSITY IN THE LIVING WORLD",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "THE LIVING WORLD",
        NOOFQUESTIONS: "1",
      
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "BIOLOGICAL ClASSIFICATION",
        NOOFQUESTIONS: "2",
    },
    {
       
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "SCIENCE OF PLANTS - BOTANY",
        NOOFQUESTIONS: "1",
    },
    {
       
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PLANT KINGDOM",
        NOOFQUESTIONS: "2",
    },
    {
        CHAPTERNAME:"UNIT - II STRUCTURAL ORGANISATION IN PLANTS - MORPHOLOGY",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "MORPHOLOGY OF FLOWERING PLANTS",
        NOOFQUESTIONS: "3",
    },
    {
        CHAPTERNAME:"UNIT - III REPRODUCTION IN PLANTS",
    },
  
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "MODES OF REPRODUCTION",
        NOOFQUESTIONS: "1",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "SEXUAL REPRODUCTION IN FLOWERING PLANTS",
        NOOFQUESTIONS: "3",
    },
    {
        CHAPTERNAME:"UNIT - IV PLANT SYSTEMATICS",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "TAXONOMY OF ANGIOSPERMS",
        NOOFQUESTIONS: "1",
    },
    {
        CHAPTERNAME:"UNIT - V CELL : STRUCTURE AND FUNCTIONS",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "THE UNIT OF LIFE",
        NOOFQUESTIONS: "2",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "BIOMOLECULES",
        NOOFQUESTIONS: "2",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "CELL CYCLE AND CELL DIVISION",
        NOOFQUESTIONS: "1",
    },
    {
        CHAPTERNAME:"UNIT - VI INTERNAL ORGANISATION OF PLANTS",
    },

    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "HISTOLOGY AND ANATOMY OF FLOWERING PLANTS",
        NOOFQUESTIONS: "1",
    },

 
    {
        
        
        CHAPTERNAME: "UNIT - VII PLANT ECOLOGY",
        
    },
    {
        CHAPTERNAME:"UNIT - I PLANT PHYSIOLOGY",
    },
    {
        
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "PHOTOSYNTHESIS IN HIGHER PLANTS",
        NOOFQUESTIONS: "2",
    },
    {
        
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "ENZYMES",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "RESPIRATION IN PLANTS",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "PLANT GROWTH AND DEVELOPMENT",
        NOOFQUESTIONS: "2",
    },
    {
        
        CHAPTERNAME: "UNIT - II MICROBIOLOGY",
        
    },
    {
        
        CHAPTERNAME: "UNIT - III GENETICS",
    },
    {
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "PRINCIPLES OF INHERITANCE AND VARIATION",
        NOOFQUESTIONS: "3",
    },
    {
        
        CHAPTERNAME: "UNIT - IV MOLECULAR BIOLOGY",
    
    },
    
    {
        YEAR: "SECOND YEAR",
        CHAPTERNAME: "MOLECULAR BASIS OF INHERITANCE",
        NOOFQUESTIONS: "3",
    },
    {
        CHAPTERNAME:"UNIT - V BIOTECHNOLOGY",
    },

    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"BIOTECHNOLOGY PRINCIPLES AND PROCESSES",
        NOOFQUESTIONS:"2",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"BIOTECHNOLOGY AND ITS APPLICATIONS",
        NOOFQUESTIONS:"1",
    },
    {
        
        CHAPTERNAME:"UNIT - VI PLANTS, MICROBES IN HUMAN WELFARE",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"MICROBES IN HUMAN WELFARE",
        NOOFQUESTIONS:"1",
    }
    
  ];
  class BotanyDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    
    colFunction(){

        let columns = [
            {
                dataField:'YEAR',
                text:'Year',
                sort: true
            },
            {
               dataField:'CHAPTERNAME',
               text:'CHAPTERNAME',
               sort: true
           },
           {
               dataField:'NOOFQUESTIONS',
               text:'NO OF QUESTIONS',
               sort: false
           },
       
        ];
        return columns;
       }
           
           render() {
               return (
               <Row>
                   <Col>
                   {
                        Cookies.get("examid") == 7 ?(
                            <Card>
                                <BootstrapTable
                                    keyField="id"
                                    columns={this.colFunction()}
                                    data={ApBipcData}
                                    defaultSorted={true}
                                    wrapperClasses="table-responsive"
                                    bootstrap4
                                />
                            </Card>

                        ) : null
                    }

                    {
                        Cookies.get("examid") == 8 ? (
                            <Card>
                                <BootstrapTable
                                    keyField="id"
                                    columns={this.colFunction()}
                                    data={TsBipcData}
                                    defaultSorted={true}
                                    wrapperClasses="table-responsive"
                                    bootstrap4
                                />
                            </Card>

                        ) : null
                    }
                   </Col>
               </Row>
               
               )
           }

}
export default BotanyDataSection