import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import * as Cookies from "es-cookie"
import { Container, Row, Col, Image } from 'react-bootstrap'
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import FoundationExams from '../components/FoundationCourse/foundation_exams/foundationExamsSection'
import { withApollo } from "react-apollo";


// const FETCH_GETSERIES = gql` 
// query($mobile: String,
//     $exam_group_id: Int) {
//         getGroupWiseExams(
//             mobile: $mobile,
//             exam_group_id: $exam_group_id)
//             {
//                 freeExams{
//                     id
//                     exam_name
//                     exam_type
//                     exam_session_id
//                     sub_exam_type
//                     is_completed
//                     is_started
//                     title
//                     short_name
//                     amount
//                     speed
//                     accuracy
//                     correct_marks
//                     negative_marks
//                     total_marks
//                     start_time
//                     end_time
//                     is_purchased
//                     exam_started
//                     is_scheduled
//                     is_registered
//                     exam_expired
//                     exam_duration
//                     syllabus
//                     class_id
//                     exam_class_id
//                     syllabus_desc
//                     subject_id
//                 }
//                 paidExams{
//                     id
//                     exam_name
//                     exam_type
//                     exam_session_id
//                     sub_exam_type
//                     is_completed
//                     is_started
//                     title
//                     short_name
//                     amount
//                     speed
//                     accuracy
//                     correct_marks
//                     negative_marks
//                     total_marks
//                     start_time
//                     end_time
//                     is_purchased
//                     exam_started
//                     is_scheduled
//                     is_registered
//                     exam_expired
//                     exam_duration
//                     syllabus
//                     class_id
//                     exam_class_id
//                     syllabus_desc
//                     subject_id
//                 }

//     }
// }

// `;

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

let globalsubjectsStatic = []

class FoundationCourseExams extends Component {
    constructor(props) {
        super(props)

        if (Cookies.get("examid") == 3 || Cookies.get("examid") == 6) {
            globalsubjectsStatic = [
                { id: 0, subject: 'All', active: "" },
                { id: 4, subject: 'Maths', active: "" },
                { id: 2, subject: 'Physics', active: "" },
                { id: 3, subject: 'Chemistry', active: "" },
            ]

        } else {
            globalsubjectsStatic = [
                { id: 0, subject: 'All', active: "" },
                { id: 1, subject: 'Botany', active: "" },
                { id: 5, subject: 'Zoology', active: "" },
                { id: 2, subject: 'Physics', active: "" },
                { id: 3, subject: 'Chemistry', active: "" },
            ]
        }
        this.state = {
            headerBottomImg: {
                type: "foundation_course_exams",
                title: "FOUNDATION COURSE EXAMS"
            },
            toggled: Cookies.get("toggle"),
            getSubjectsArr: globalsubjectsStatic,
        }
    }
    componentDidMount = () => {

        this.addStudentAnalytics();

    }
    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 211,
            source: 2
        }

        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    render() {
   
        // const getGroupWiseExams = this.props.getGroupWiseExams
        // const loading = getGroupWiseExams.loading
        // const error = getGroupWiseExams.error

        // if (error !== undefined) {
        //     alert("Server Error. " + error.message);
        //     return null;
        // }

        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }

        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars
                        headerBottom={this.state.headerBottomImg}
                        onClick={() => this.menuToggler()} pageType={"foundation_exams"} />
                </div>
                <div className="main-wrapper">

                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0" style={{ minHeight: "50vh" }}>
                        {/* {
                            loading == true ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <FoundationExams
                                        stateData={this.state}
                                        // getGroupWiseExams={getGroupWiseExams.getGroupWiseExams}
                                    />
                                )
                        } */}
                        <FoundationExams
                            stateData={this.state}
                        />

                    </div>
                </div>
            </div>

        )
    }
}

export default withRouter(compose(
    // graphql(FETCH_GETSERIES, {
    //     options: props => ({
    //         variables: {
    //             mobile: Cookies.get("mobile"),
    //             exam_group_id: Cookies.get("examid") == "3" ? 8 : Cookies.get("examid") == "6" ? 9 : Cookies.get("examid") == "7" ? 10 : 11

    //         },
    //         fetchPolicy: "network-only"
    //     }), name: "getGroupWiseExams"
    // })
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    })


)(FoundationCourseExams));
