import React, { Component } from 'react'
import { Modal, Form, Button, Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom';

import Pdf from '../ChapterWiseLearningEamcet/pdf'

class PDFModel extends Component {
    render() {
        console.log("this.props.Data", this.props.data);
        return (
            <Modal {...this.props}
                size="lg" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="p-4" style={{ height: 522, overflowY: 'auto' }}>
                <Pdf file={this.props.file_path} className='w-100' />
                </Modal.Body>
            </Modal >
        )
    }
}

export default PDFModel
