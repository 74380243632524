import React, { Component } from "react";
import { Row, Form, Tab, Card, Image, Button, Col, Table, Media, Container } from 'react-bootstrap';
import './_errorHome.scss'
import SelectDropDown from '../../../neetjee_guru/components/selectdropdown/SelectDropDown';
import { components } from 'react-select';
import * as Cookies from "es-cookie";
import { Scrollbars } from 'react-custom-scrollbars'
import { withRouter, Link } from "react-router-dom";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import EamcetRestrictionAlert from "../home/EamcetRestrictionAlert";


const getClasses = [
    { value: 0, label: 'ALL' },
    { value: 1, label: 'XI' },
    { value: 2, label: 'XII' }
];
const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
};

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        width: '3px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
};


const ERROR_EXAM = gql`
  mutation(
    $params:StudentErrorExam  
    ) {
        studentErrorExam(
        params: $params
     )
  }
`;

class ErrorExamHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainSelVal: '1',
            class: '0',
            subjectArray: [],
            subjectArraycu: [],
            unanswered1: '0',
            errorquestions1: '0',
            pquestions1: '0',
            examquestions1: '0',
            unanswered2: '0',
            errorquestions2: '0',
            pquestions2: '0',
            examquestions2: '0',
            pquestionscheck1: "0",
            examquestionscheck1: "0",
            unansweredcheck1: "0",
            errorquestionscheck1: "0",
            pquestionscheck2: "0",
            examquestionscheck2: "0",
            unansweredcheck2: "0",
            errorquestionscheck2: "0",
            loadbutton: '0',
            formValid: true,
            submitError: "",
            onMouseId: '0',
            classes: this.props.studentGlobals.classes,
            class_label: { value: "0", label: "ALL" },
            userRestionModalShow: false

        }
    }

    componentDidMount = () => {
        if (Cookies.get("examid") == "5") {

            //console.log("this.props", this.props.globals.globals.subjects);
            let examsData = this.props.studentGlobals.exams.find((a) => a.id == this.state.exam);
            let examsubjectData = examsData.exam_subjects;
            let sampleArray1 = [];
            for (let i = 0; i <= examsubjectData.length; i++) {
                let idata = examsubjectData[i];
                //console.log("idata", idata);
                if (idata != undefined) {
                    let someData = this.props.getSubjects.find((a) => a.id == idata.subject_id);
                    const newarr1 = {
                        ...someData,
                        classActive1: "",
                        classActive2: "",
                        classActive3: "",
                        checkedall: false
                    }
                    sampleArray1.push(newarr1);

                    someData.studentChapters.map((item) => {
                        item.active = ""
                        item.checked = false
                    });

                }

            }
            this.setState({
                subjectArray: sampleArray1,
                subjectArraycu: sampleArray1,

            });
        }
        else {
            let sampleArray = [];
            for (let i = 0; i < this.props.getSubjects.length; i++) {
                let someData = this.props.getSubjects[i];
                const newarr1 = {
                    ...someData,
                    classActive1: "",
                    classActive2: "",
                    classActive3: "",
                    checkedall: false
                }
                sampleArray.push(newarr1);

                someData.studentChapters.map((item) => {
                    item.active = ""
                    item.checked = false
                });
            }
            this.setState({
                subjectArray: sampleArray,
                subjectArraycu: sampleArray,

            });
        }


    }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            loadbutton: 1
        });
        if (this.state.formValid) {

            let syllabus = [];
            let sub_type = "";
            let un_answered = "";
            let error_questions = "";
            let practice_questions = "";
            let test_questions = "";
            let exam_type = "";
            if (Cookies.get("examid") != "5") {
                if (Cookies.get("examid") == "2" || Cookies.get("examid") == "1" ) {
                    exam_type = "1";
                }
                else {
                    exam_type = Cookies.get("examid")
                }
            }
            else {
                if (this.state.exam == "2") {
                    exam_type = "1";
                }
                else {
                    exam_type = "0";
                }

            }


            if (this.state.mainSelVal == "1") {
                console.log("handleFormSubmit");
                sub_type = "chapter";
                //exam_type = this.state.examtype1

                if (this.state.unansweredcheck1 != "") {
                    un_answered = 1;
                }
                else {
                    un_answered = 0;
                }
                if (this.state.errorquestionscheck1 != "") {
                    error_questions = 1;
                }
                else {
                    error_questions = 0;
                }
                if (this.state.pquestionscheck1 != "") {
                    practice_questions = 1;

                }
                else {
                    practice_questions = 0;
                }

                if (this.state.examquestionscheck1 != "") {
                    test_questions = 1;

                }
                else {
                    test_questions = 0;
                }
                let array = this.state.subjectArray;
                for (let i = 0; i <= array.length; i++) {
                    let idata = array[i];
                    let chArray = [];

                    if (idata != undefined) {
                        let chapters = idata.studentChapters;

                        for (let c = 0; c <= chapters.length; c++) {
                            let cdata = chapters[c];
                            if (cdata != undefined) {
                                if (cdata.checked == true) {
                                    const id = cdata.id;
                                    chArray.push(id);
                                }
                            }

                        }
                        const newobj = {
                            subject_id: parseInt(idata.id),
                            chapters: chArray
                        }
                        syllabus.push(newobj);
                    }



                }
            }
            else {
                //exam_type = this.state.examtype2
                sub_type = "cumulative";
                if (this.state.unansweredcheck2 != "") {
                    un_answered = 1;
                }
                else {
                    un_answered = 0;
                }
                if (this.state.errorquestionscheck2 != "") {
                    error_questions = 1;
                }
                else {
                    error_questions = 0;
                }
                if (this.state.pquestionscheck2 != "") {
                    practice_questions = 1;

                }
                else {
                    practice_questions = 0;
                }

                if (this.state.examquestionscheck2 != "") {
                    test_questions = 1;

                }
                else {
                    test_questions = 0;
                }

                let array = this.state.subjectArraycu;
                for (let i = 0; i <= array.length; i++) {
                    let idata = array[i];
                    let chArray = [];

                    if (idata != undefined) {
                        let chapters = idata.studentChapters;

                        for (let c = 0; c <= chapters.length; c++) {
                            let cdata = chapters[c];
                            if (cdata != undefined) {
                                if (cdata.checked == true) {
                                    const id = cdata.id;
                                    chArray.push(id);
                                }
                            }

                        }
                        const newobj = {
                            subject_id: parseInt(idata.id),
                            chapters: chArray
                        }
                        syllabus.push(newobj);
                    }
                }
            }
            console.log("syllabus", syllabus);
            let status = false;
            syllabus.map((data) => {
                if (data.chapters.length > 0) {
                    status = true;
                }

            })
            if (status) {
                const params = {
                    mobile: Cookies.get("mobile"),
                    sub_type: sub_type,
                    source: 0,
                    exam_type: parseInt(exam_type),
                    practice_questions: practice_questions,
                    test_questions: test_questions,
                    un_answered: un_answered,
                    error_questions: error_questions,
                    syllabus: syllabus,
                    question_types: [],
                    complexity: [],
                    question_theory: []
                };
                console.log("errorparams", params);
                this.customfunction(
                    params
                ).catch(error => {
                    console.log("catch if error");
                    console.log(error);
                    this.setState({
                        submitError: error.graphQLErrors.map(x => x.message), loadbutton: 0
                    });
                    console.error("ERR =>", error.graphQLErrors.map(x => x.message));
                });
            }
            else if (!status) {
                this.setState({ submitError: "Please select chapter at least one subject", loadbutton: 0 });
            }

        } else {
            this.setState({ submitError: "Please fill all the values to proceed", loadbutton: 0 });
        }
    };

    customfunction = async (
        params) => {
        await this.props.customfunction({
            variables: {
                params
            },
            update: (store, { data }) => {
                console.log("updatedata", data);
                if (data.studentErrorExam) {
                    console.log("updatedatadataID", data.studentErrorExam)
                    // this.props.history.push({
                    //     pathname: "/student/subject/custom-instructions",
                    //     state: {
                    //         sessionid: data.studentErrorExam,
                    //         type: "Error Exam",
                    //         etype: "error"
                    //     }
                    // }
                    // );
                    this.setState({
                        submitError: "", loadbutton: 0
                    });
                    localStorage.setItem("sessionid", data.studentErrorExam);
                    localStorage.setItem("type", "Error Exam");
                    localStorage.setItem("stype", "");
                    localStorage.setItem("exam_paper_id", "0");
                    localStorage.setItem("etype", "error");
                    localStorage.setItem("examtype_pattern","state_error_exam")

                    if (isMobile == true && Cookies.get("institution_id") == 30) {
                        window.location.replace("/student/subject/exam");
                    } else {
                        window.open("/student/subject/exam", "_blank")
                    }

                }
            }
        });
    };


    handleSelect = (id) => {
        let sampleArray = [];
        for (let i = 0; i < this.props.getSubjects.length; i++) {
            let someData = this.props.getSubjects[i];
            const newarr1 = {
                ...someData,
                classActive1: "",
                classActive2: "",
                classActive3: "",
                checkedall: false
            }
            sampleArray.push(newarr1);

            someData.studentChapters.map((item) => {
                item.active = ""
                item.checked = false
            });
        }
        this.setState({
            mainSelVal: id, unanswered1: 0,
            errorquestions1: 0,
            pquestions1: 0,
            examquestions1: 0,
            unanswered2: 0,
            errorquestions2: 0,
            pquestions2: 0,
            examquestions2: 0,
            pquestionscheck1: "0",
            examquestionscheck1: "0",
            unansweredcheck1: "0",
            errorquestionscheck1: "0",
            pquestionscheck2: "0",
            examquestionscheck2: "0",
            unansweredcheck2: "0",
            errorquestionscheck2: "0",
            subjectArray: sampleArray,
            subjectArraycu: sampleArray,
            class: '0',
            class_label:{ value: "0", label: "ALL" }
        })
    }
    onMouse = (id) => {
        this.setState({ onMouseId: id })
    }
    classGetDataFunction(data) {
        let sarray = [];
        const obj1 = {
            value: 0,
            label: "ALL",
        }
        for (let i = 0; i < data.length; i++) {
            let idata = data[i];
            const obj = {
                value: idata.id,
                label: idata.class,
            }
            sarray.push(obj);
        }
        sarray.unshift(obj1);
        return sarray;
    }

    checkeOnchange = (e) => {
        console.log("checkeOnchange", e.target.name);
        if (e.target.checked == true) {
            const name = e.target.name;
            const value = e.target.value;
            this.setState({ [name]: value });
        }
        else {
            const name = e.target.name;
            const value = "";
            this.setState({ [name]: value });
        }
        if (e.target.name == "pquestionscheck2") {
            if (this.state.mainSelVal == "2") {
                if (e.target.checked == true) {
                    let arr = this.state.subjectArraycu;
                    let unansA = [];
                    let erroqA = [];
                    let oldcount2 = 0;
                    let oldcount3 = 0;
                    for (let i = 0; i < arr.length; i++) {
                        let idata = arr[i];
                        let chapter = idata.studentChapters;
                        for (let c = 0; c < chapter.length; c++) {
                            let cData = chapter[c];
                            if (cData.checked == true) {
                                if (this.state.examquestionscheck2 != "") {
                                    const unans = parseInt(cData.exam_unanswered);
                                    unansA.push(parseInt(unans));

                                    const erroq = parseInt(cData.exam_wrong_answered) + parseInt(cData.practice_wrong_answered);;
                                    erroqA.push(parseInt(erroq));
                                }
                                else {
                                    const unans = 0;
                                    unansA.push(parseInt(unans));

                                    const erroq = parseInt(cData.practice_wrong_answered);;
                                    erroqA.push(parseInt(erroq));
                                }
                            }
                        }
                    }
                    for (let num of unansA) {
                        oldcount2 = oldcount2 + num
                    }
                    for (let num of erroqA) {
                        oldcount3 = oldcount3 + num
                    }
                    this.setState({
                        unanswered2: oldcount2,
                        errorquestions2: oldcount3,
                    });

                } else {
                    let arr = this.state.subjectArraycu;
                    let unansA = [];
                    let erroqA = [];
                    let oldcount2 = 0;
                    let oldcount3 = 0;
                    for (let i = 0; i < arr.length; i++) {
                        let idata = arr[i];
                        let chapter = idata.studentChapters;
                        for (let c = 0; c < chapter.length; c++) {
                            let cData = chapter[c];
                            if (cData.checked == true) {
                                if (this.state.examquestionscheck2 != "") {


                                    const unans = parseInt(cData.exam_unanswered);
                                    unansA.push(parseInt(unans));

                                    const erroq = parseInt(cData.exam_wrong_answered);
                                    erroqA.push(parseInt(erroq));
                                }
                                else {
                                    // erroq = 0;
                                    // unans = 0;

                                    const unans = 0;
                                    unansA.push(parseInt(unans));

                                    const erroq = 0;
                                    erroqA.push(parseInt(erroq));
                                }
                            }
                        }
                    }
                    for (let num of unansA) {
                        oldcount2 = oldcount2 + num
                    }
                    for (let num of erroqA) {
                        oldcount3 = oldcount3 + num
                    }
                    this.setState({
                        unanswered2: oldcount2,
                        errorquestions2: oldcount3,
                    });

                }
            }

        }
        if (e.target.name == "pquestionscheck1") {
            if (this.state.mainSelVal == "1") {
                if (e.target.checked == true) {
                    let arr = this.state.subjectArray;
                    let unans = "";
                    let erroq = "";
                    for (let i = 0; i < arr.length; i++) {
                        let idata = arr[i];
                        let chapter = idata.studentChapters;
                        for (let c = 0; c < chapter.length; c++) {
                            let cData = chapter[c];
                            if (cData.checked == true) {
                                //unans = parseInt(cData.practice_unanswered);

                                //unans = 0;
                                if (this.state.examquestionscheck1 != "") {
                                    unans = parseInt(cData.exam_unanswered);
                                    erroq = parseInt(cData.exam_wrong_answered) + parseInt(cData.practice_wrong_answered);
                                }
                                else {
                                    erroq = parseInt(cData.practice_wrong_answered);
                                    unans = 0;
                                }
                            }
                        }
                    }
                    this.setState({
                        unanswered1: unans,
                        errorquestions1: erroq,
                    });

                }
                else {
                    let arr = this.state.subjectArray;
                    let unans = "";
                    let erroq = "";
                    for (let i = 0; i < arr.length; i++) {
                        let idata = arr[i];
                        let chapter = idata.studentChapters;
                        for (let c = 0; c < chapter.length; c++) {
                            let cData = chapter[c];
                            if (cData.checked == true) {
                                // unans = parseInt(cData.practice_unanswered);


                                // erroq = parseInt(cData.practice_wrong_answered);
                                //unans = parseInt(cData.practice_unanswered) + parseInt(cData.exam_unanswered);

                                // erroq = parseInt(cData.practice_wrong_answered) + parseInt(cData.exam_wrong_answered);

                                if (this.state.examquestionscheck1 != "") {
                                    erroq = parseInt(cData.exam_wrong_answered);
                                    unans = parseInt(cData.exam_unanswered);
                                }
                                else {
                                    erroq = 0;
                                    unans = 0;
                                }
                            }
                        }
                    }
                    this.setState({
                        unanswered1: unans,
                        errorquestions1: erroq,
                    });

                }
            }

        }
        if (e.target.name == "examquestionscheck2") {
            if (this.state.mainSelVal == "2") {
                if (e.target.checked == true) {
                    let arr = this.state.subjectArraycu;
                    let unansA = [];
                    let erroqA = [];
                    let oldcount2 = 0;
                    let oldcount3 = 0;
                    for (let i = 0; i < arr.length; i++) {
                        let idata = arr[i];
                        let chapter = idata.studentChapters;
                        for (let c = 0; c < chapter.length; c++) {
                            let cData = chapter[c];
                            if (cData.checked == true) {

                                // const unans = parseInt(cData.practice_unanswered) + parseInt(cData.exam_unanswered);
                                // unansA.push(parseInt(unans));

                                // const erroq = parseInt(cData.practice_wrong_answered) + parseInt(cData.exam_wrong_answered);
                                // erroqA.push(parseInt(erroq));

                                if (this.state.pquestionscheck2 != "") {
                                    const unans = parseInt(cData.exam_unanswered);
                                    unansA.push(parseInt(unans));

                                    const erroq = parseInt(cData.practice_wrong_answered) + parseInt(cData.exam_wrong_answered);
                                    erroqA.push(parseInt(erroq));
                                }
                                else {
                                    const unans = parseInt(cData.exam_unanswered);
                                    unansA.push(parseInt(unans));

                                    const erroq = parseInt(cData.exam_wrong_answered);
                                    erroqA.push(parseInt(erroq));
                                }
                            }
                        }
                    }
                    for (let num of unansA) {
                        oldcount2 = oldcount2 + num
                    }
                    for (let num of erroqA) {
                        oldcount3 = oldcount3 + num
                    }
                    this.setState({
                        unanswered2: oldcount2,
                        errorquestions2: oldcount3,
                    });
                }
                else {
                    let arr = this.state.subjectArraycu;
                    let unansA = [];
                    let erroqA = [];
                    let oldcount2 = 0;
                    let oldcount3 = 0;
                    for (let i = 0; i < arr.length; i++) {
                        let idata = arr[i];
                        let chapter = idata.studentChapters;
                        for (let c = 0; c < chapter.length; c++) {
                            let cData = chapter[c];
                            if (cData.checked == true) {
                                // const unans = parseInt(cData.exam_unanswered);
                                // unansA.push(parseInt(unans));

                                // const erroq = parseInt(cData.exam_wrong_answered);
                                // erroqA.push(parseInt(erroq));

                                if (this.state.pquestionscheck2 != "") {

                                    const unans = 0;
                                    unansA.push(parseInt(unans));

                                    const erroq = parseInt(cData.practice_wrong_answered);
                                    erroqA.push(parseInt(erroq));
                                } else {

                                    const unans = 0;
                                    unansA.push(parseInt(unans));

                                    const erroq = 0;
                                    erroqA.push(parseInt(erroq));
                                }
                            }
                        }
                    }
                    for (let num of unansA) {
                        oldcount2 = oldcount2 + num
                    }
                    for (let num of erroqA) {
                        oldcount3 = oldcount3 + num
                    }
                    this.setState({
                        unanswered2: oldcount2,
                        errorquestions2: oldcount3,
                    });
                }

            }

        }
        if (e.target.name == "examquestionscheck1") {
            console.log("examquestionscheck1");
            if (this.state.mainSelVal == "1") {

                if (e.target.checked == true) {
                    let arr = this.state.subjectArray;
                    let unans = "";
                    let erroq = "";

                    for (let i = 0; i < arr.length; i++) {
                        let idata = arr[i];
                        let chapter = idata.studentChapters;
                        for (let c = 0; c < chapter.length; c++) {
                            let cData = chapter[c];
                            if (cData.checked == true) {

                                if (this.state.pquestionscheck1 != "") {
                                    unans = parseInt(cData.exam_unanswered);
                                    erroq = parseInt(cData.practice_wrong_answered) + parseInt(cData.exam_wrong_answered);
                                }
                                else {
                                    unans = parseInt(cData.exam_unanswered);
                                    erroq = parseInt(cData.exam_wrong_answered);
                                }
                            }
                        }
                    }
                    this.setState({
                        unanswered1: unans,
                        errorquestions1: erroq,
                    });

                }
                else {
                    let arr = this.state.subjectArray;
                    let unans = "";
                    let erroq = "";
                    for (let i = 0; i < arr.length; i++) {
                        let idata = arr[i];
                        let chapter = idata.studentChapters;
                        for (let c = 0; c < chapter.length; c++) {
                            let cData = chapter[c];
                            if (cData.checked == true) {
                                // unans = parseInt(cData.exam_unanswered);
                                // erroq = parseInt(cData.exam_wrong_answered);
                                console.log("this.state.pquestionscheck1345", this.state.pquestionscheck1);
                                if (this.state.pquestionscheck1 != "") {
                                    console.log("123");
                                    unans = 0;
                                    erroq = parseInt(cData.practice_wrong_answered);
                                } else {
                                    console.log("345");
                                    unans = 0;
                                    erroq = 0;
                                }

                            }
                        }
                    }
                    this.setState({
                        unanswered1: unans,
                        errorquestions1: erroq,
                    });

                }
            }

        }
    }


    handleSelectInputChange = (name, value) => {
        console.log("name", name, value);
        const ename = name;
        const evalue = value;
        if (name == "exam") {
            let examsData = this.props.studentGlobals.exams.find((a) => a.id == value);

            //subjets Data
            //console.log("this.props", this.props.globals.globals.subjects);

            let examsubjectData = examsData.exam_subjects;
            let sampleArray1 = [];
            for (let i = 0; i <= examsubjectData.length; i++) {
                let idata = examsubjectData[i];
                //console.log("idata", idata);
                if (idata != undefined) {
                    let someData = this.props.getSubjects.find((a) => a.id == idata.subject_id);
                    const newarr1 = {
                        ...someData,
                        classActive1: "",
                        classActive2: "",
                        classActive3: "",
                        checkedall: false
                    }
                    sampleArray1.push(newarr1);

                    someData.studentChapters.map((item) => {
                        item.active = ""
                        item.checked = false
                    });

                }

            }
            this.setState({
                subjectArray: sampleArray1,
                subjectArraycu: sampleArray1,
                examvalue: {
                    value: value,
                    label: examsData.exam

                }

            });
            //end subects Data
        } else if (name == "class") {
            let selected_class = getClasses.find((a) => a.value == evalue);
            this.setState({
                class_label: {
                    value: selected_class.value,
                    label: selected_class.label
                }
            });
        }
        this.setState({ [ename]: evalue });
    }

    chaptersFunction = (e, subid, qtyid) => {
        let arr = this.state.subjectArray.map(item => {

            if (item.id == subid) {
                const qtype = item.studentChapters.map(qitem => {
                    if (qitem.id == qtyid) {
                        console.log("qitem.checked", qitem.checked);
                        if (qitem.checked == false) {
                            return { ...qitem, checked: true, active: "active" }
                        } else {
                            return { ...qitem, checked: false, active: "active" }
                        }
                    }
                    return { ...qitem, checked: false, active: "active" }

                })
                return { ...item, studentChapters: qtype };
            }
            return item;
        }

        )
        this.setState({ subjectArray: arr, submitError: '' }, () => this.updateFields("single"));
    }

    questionFunction = (e, subid, qtyid) => {
        let foundData = this.state.subjectArraycu.find((a) => a.id == subid);
        let overAllQuestion = foundData.studentChapters;

        let findactive = overAllQuestion.find((a) => a.active == "active");
        if (findactive != undefined) {
            let findex = overAllQuestion.indexOf(findactive);
            overAllQuestion[findex].active = "";
        }

        let arr = this.state.subjectArraycu.map(item => {

            if (item.id == subid) {
                console.log("satisfiedsub", item.id, subid);
                const qtype = item.studentChapters.map(qitem => {
                    console.log("satisfiedchap", qitem.id, qtyid);

                    if (qitem.id == qtyid) {
                        console.log("satisfiedsub", qitem.checked);
                        if (qitem.checked == false) {
                            return { ...qitem, checked: true, active: "active" }
                        } else {
                            return { ...qitem, checked: false, active: "active" }
                        }
                    }
                    return qitem;
                })
                return { ...item, studentChapters: qtype };


            }
            return item;
        }

        )
        this.setState({ subjectArraycu: arr }, () => this.updateFields("multiple"));
    }

    updateFields = (type) => {
        console.log("updateFields", type);
        if (type == "multiple" || type == "all") {
            console.log("multiple");
            let practiceQuestions = 0;
            let examQuestions = 0;
            let unansweredQuestions = 0;
            let errorQuestions = 0;
            console.log("this.state.subjectArraycu", this.state.subjectArraycu);

            this.state.subjectArraycu.map(item => {
                item.studentChapters.map(qitem => {
                    if (qitem.checked == true) {

                        practiceQuestions += parseInt(qitem.practice_unanswered) + parseInt(qitem.practice_wrong_answered);
                        examQuestions += parseInt(qitem.exam_unanswered) + parseInt(qitem.exam_wrong_answered);
                        unansweredQuestions += parseInt(qitem.practice_unanswered) + parseInt(qitem.exam_unanswered);
                        errorQuestions += parseInt(qitem.practice_wrong_answered) + parseInt(qitem.exam_wrong_answered);
                    }
                })
            })
            console.log("unansweredQuestions", unansweredQuestions);
            this.setState({
                unanswered2: unansweredQuestions,
                errorquestions2: errorQuestions,
                pquestions2: practiceQuestions,
                examquestions2: examQuestions
            })
        }
        else if (type == "single") {
            let practiceQuestions = 0;
            let examQuestions = 0;
            let unansweredQuestions = 0;
            let errorQuestions = 0;

            this.state.subjectArray.map(item => {
                item.studentChapters.map(qitem => {
                    if (qitem.checked == true) {

                        practiceQuestions += parseInt(qitem.practice_unanswered) + parseInt(qitem.practice_wrong_answered);
                        examQuestions += parseInt(qitem.exam_unanswered) + parseInt(qitem.exam_wrong_answered);
                        unansweredQuestions += parseInt(qitem.practice_unanswered) + parseInt(qitem.exam_unanswered);
                        errorQuestions += parseInt(qitem.practice_wrong_answered) + parseInt(qitem.exam_wrong_answered);

                        console.log("llll", qitem);
                    }
                })
            })
            this.setState({
                unanswered1: unansweredQuestions,
                errorquestions1: errorQuestions,
                pquestions1: practiceQuestions,
                examquestions1: examQuestions
            })
        }



    }

    CheckAllFun = (e, subjectid, chid) => {
        let isStudentUserValid = "";
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        else {
            this.props.history.push("/student/login");
        }
        console.log("CheckAllFun", subjectid);
        let arr = this.state.subjectArraycu;
        let findsubData = arr.find((a) => a.id == subjectid);
        let idindex = arr.indexOf(findsubData);
        // arr[idindex].checkedall = true;

        console.log("e.target.checked", e.target.checked);
        if (e.target.checked == true) {
            findsubData.studentChapters.map((t) => {
                if ((t.errorExamsEnabled == false) || isStudentUserValid.chapter_ids.split(",").includes(t.id.toString())) {
                    if (t.error_questions > 0) {
                        t.checked = true;
                    }
                }


            });

            arr[idindex].checkedall = true;
        }
        else {
            findsubData.studentChapters.map((t) => {
                if ((t.errorExamsEnabled == false) || isStudentUserValid.chapter_ids.split(",").includes(t.id.toString())) {
                    if (t.error_questions > 0) {
                        t.checked = "";
                    }
                }

            });
            arr[idindex].checkedall = "";
        }
        this.setState({ subjectArraycu: arr }, () => this.updateFields("all"));
        //this.setState({ subjectArraycu: arr });
        //this.pQuestionFun2(arr, e.target.checked);
    }



    render() {
        console.log("state", this.state);

        let locsubjects = "";
        if (JSON.parse(localStorage.getItem("globalsubjects")) != "") {
            locsubjects = JSON.parse(localStorage.getItem("globalsubjects"));
        }
        else {
            this.props.history.push("/student/login");
        }

        let isStudentUserValid = "";
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        else {
            this.props.history.push("/student/login");
        }

        const isuserValid = JSON.parse(isStudentUserValid.user_access_restictions);
        // const moduleValid = JSON.parse(isStudentUserValid.module_restrictions);


        return (
            <Container className="errorHome">
                <Row>
                    <Col sm={5} className='main-blocks'>
                        <Card className={this.state.mainSelVal == '1' ? 'error-main-bg active' : 'error-main-bg'} onClick={() => this.handleSelect('1')} onMouseOver={() => this.onMouse('1')} onMouseLeave={() => this.onMouse('0')}>
                            <Card.Body>
                                <Row>
                                    <Col sm={4}>
                                        <Image src={this.state.mainSelVal == '1' || this.state.onMouseId == '1' ? require('../../../images/single-chapter-color.png') : require('../../../images/single-chapter-without-color.png')} alt="img" rounded fluid className="img-width" />
                                    </Col>
                                    <Col sm={8}>
                                        <h5 className="main-title">Single Chapter Exam</h5>
                                        <p className="main-sub-title">Give error exam for every individual chapter</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={5} className='main-blocks-second'>
                        <Card className={this.state.mainSelVal == 2 ? 'error-main-bg active' : 'error-main-bg'} onClick={() => this.handleSelect('2')} onMouseOver={() => this.onMouse('2')} onMouseLeave={() => this.onMouse('0')}>
                            <Card.Body>
                                <Row>
                                    <Col sm={4}>
                                        <Image src={this.state.mainSelVal == '2' || this.state.onMouseId == '2' ? require('../../../images/cumulative-color.png') : require('../../../images/cumulative-without-color.png')} alt="img" rounded fluid className="img-width" />
                                    </Col>
                                    <Col sm={8}>
                                        <h5 className="main-title">Cumulative exam</h5>
                                        <p className="main-sub-title">Give error exam for multiple chapters in multiple subjects</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className='mt-3'>
                        {
                            this.state.mainSelVal == '1' ? (
                                <Row>
                                    <Col>
                                        <h5>Single Chapter Exam</h5>
                                        <p className="main-sub-title">Select chapter for which you want to give error exam</p>
                                    </Col>
                                </Row>
                            ) : this.state.mainSelVal == '2' ? (
                                <Row>
                                    <Col>
                                        <h5>Cumulative exam</h5>
                                        <p className="main-sub-title">Select multiple chapters for which you want to give error exam</p>
                                    </Col>
                                </Row>
                            ) : ('')
                        }
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <h5 className="mt-3">SUBJECTS</h5>
                    </Col>
                    <Col sm={8} className='mt-3'>
                        {this.state.mainSelVal != '3' ? (
                            <Row>
                                <Col >
                                    <div className="d-flex  justify-content-right align-items-center px-2 float-right">
                                        <Form.Label className="mb-0 mt-1 classSelect">Select Class :</Form.Label>
                                        <div className="ml-2" style={{ width: 90 }}>
                                            <SelectDropDown
                                                stateData={this.state.class_label}
                                                handleChange={this.handleSelectInputChange}
                                                name="class"
                                                options={getClasses}
                                                placeholderName={'ALL'}
                                                dropdownIndicator={{ DropdownIndicator }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        ) : ("")
                        }
                    </Col>
                </Row>
                <Row>
                    {
                        this.state.subjectArray.map((getsub, index) => {
                            const getsinglesub = locsubjects.find((a) => a.id == getsub.id);
                            console.log("");
                            let subIcon = '';
                            if (getsinglesub.subject == 'Physics') {
                                subIcon = require('../../../images/physics_icon.png')
                            } else if (getsinglesub.subject == 'Chemistry') {
                                subIcon = require('../../../images/chemistry_icon.png')
                            } else if (getsinglesub.subject == 'Mathematics') {
                                subIcon = require('../../../images/maths_icon.png')
                            } else if (getsinglesub.subject == 'Zoology') {
                                subIcon = require('../../../images/zoology_icon.png')
                            } else if (getsinglesub.subject == 'Botany') {
                                subIcon = require('../../../images/botany_icon.png')
                            }else if (getsinglesub.subject == 'Biology') {
                                subIcon = require('../../../images/botany_icon.png')
                            }
                            return (
                                <Col sm={this.state.subjectArray.length == 3 ? 4 : 3}>
                                    <Card className="main-card mt-3">
                                        <Card.Body className="p-3">
                                            <Row>
                                                <Col sm={4} className="img-width-main" >
                                                    <Image src={subIcon} alt="img" rounded fluid className="img-width" />
                                                </Col>
                                                <Col sm={8}>
                                                    <h5 className="main-title text-center mt-2">{getsinglesub.subject.toUpperCase()}</h5>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>
                                                    <p className="inside-card bg-pink">Not Answered <br /> <span className="font-weight-bold">{getsub.subjectNotAnswered}</span></p>
                                                </Col>
                                                <Col sm={6}>
                                                    <p className="inside-card bg-dark-pink">Wrong Answered <br /> <span className="font-weight-bold">{getsub.subjectWrongAnswered}</span></p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>
                                                    <p className="inside-card bg-light-green">Error Corrected <br /> <span className="font-weight-bold">{getsub.subjectErrorCorrected}</span></p>
                                                </Col>
                                                <Col sm={6}>
                                                    <p className="inside-card bg-light-red">Pending Questions <br /> <span className="font-weight-bold">{getsub.subjectPendingQuestions}</span></p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} className='mt-3'>
                                                    <h6>CHAPTERS</h6>
                                                </Col>
                                            </Row>
                                            {
                                                this.state.mainSelVal == '1' ? (

                                                    <Row>
                                                        <Col>
                                                            <Scrollbars style={{ height: 130 }}
                                                                {...this.props}
                                                                renderThumbVertical={renderThumb}
                                                                autoHide
                                                                autoHideTimeout={500}
                                                                autoHideDuration={200}>
                                                                <ul className="list-unstyled topic-list m-0 pl-1">
                                                                    {
                                                                        this.state.subjectArray.filter((item) => item.id == getsinglesub.id).map((chData, index1) => {
                                                                            let filterData = chData.studentChapters
                                                                            if (this.state.class != '0') {
                                                                                filterData = chData.studentChapters.filter((a) => a.class == this.state.class);
                                                                            }
                                                                            let erroquestionscount = filterData.filter((a) => a.error_questions > 0);
                                                                            return (
                                                                                <div>
                                                                                    {erroquestionscount.length > 0 ? (
                                                                                        <React.Fragment>
                                                                                            <React.Fragment>
                                                                                                {filterData.map((cData, index) => {
                                                                                                    console.log("index", cData.id);
                                                                                                    const getsinglesubfilter = locsubjects.find((a) => a.id == chData.id);
                                                                                                    const getsinglechap = getsinglesubfilter.studentChapters.find((b) => b.id == cData.id);
                                                                                                    if (cData.enabled == false) {

                                                                                                        if (cData.error_questions != 0) {
                                                                                                            return (
                                                                                                                <div>
                                                                                                                    <li style={{ cursor: "pointer" }} className={cData.active}>
                                                                                                                        <Form.Check type="checkbox" id={"chapcheckboxOne" + "_" + cData.id} custom>
                                                                                                                            <Form.Check.Input
                                                                                                                                checked={cData.checked}
                                                                                                                                type="checkbox"
                                                                                                                                onClick={(e) => this.chaptersFunction(e, chData.id, cData.id)}
                                                                                                                            />
                                                                                                                            <Form.Check.Label
                                                                                                                                htmlFor={"chapcheckboxOne" + "_" + cData.id} className='chapter-text'
                                                                                                                            >{getsinglechap.chapter} - {cData.error_questions}</Form.Check.Label>
                                                                                                                        </Form.Check>
                                                                                                                    </li>
                                                                                                                </div>

                                                                                                            )
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        return (
                                                                                                            <div>
                                                                                                                <li style={{ cursor: "pointer" }} onClick={() => this.setState({ userRestionModalShow: true })} className={cData.active}>
                                                                                                                    <Image src={require('../../../images/locked.png')} className="mr-2" width="13" alt="locked image" />
                                                                                                                    {getsinglechap.chapter}
                                                                                                                </li>

                                                                                                            </div>

                                                                                                        )
                                                                                                    }
                                                                                                })}
                                                                                            </React.Fragment>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                        <div className="text-danger text-center mt-5">
                                                                                            {index1 == 0 ? ("No error chapters are available") : ("")}

                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                            )
                                                                        }
                                                                        )}
                                                                </ul>
                                                            </Scrollbars>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col>
                                                            <Scrollbars style={{ height: 130 }}
                                                                {...this.props}
                                                                renderThumbVertical={renderThumb}
                                                                autoHide
                                                                autoHideTimeout={500}
                                                                autoHideDuration={200}>
                                                                <ul className="list-unstyled topic-list m-0 pl-1">
                                                                    {this.state.subjectArraycu.filter((item) => item.id == getsinglesub.id).map((chData, index1) => {
                                                                        let filterData = chData.studentChapters;
                                                                        if (this.state.class != '0') {
                                                                            filterData = chData.studentChapters.filter((a) => a.class == this.state.class);
                                                                        }
                                                                        let erroquestionscount = filterData.filter((a) => a.error_questions > 0);
                                                                        return (
                                                                            <div>
                                                                                {erroquestionscount.length > 0 ? (<React.Fragment>
                                                                                    <React.Fragment>
                                                                                        {filterData.map((cData, index) => {
                                                                                            console.log("index", index,);
                                                                                            const getsinglesub = locsubjects.find((a) => a.id == chData.id);
                                                                                            const getsinglechap = getsinglesub.studentChapters.find((b) => b.id == cData.id);
                                                                                            if (cData.error_questions != 0) {
                                                                                                return (

                                                                                                    <div>
                                                                                                        {
                                                                                                            index == 0 ? (
                                                                                                                <li style={{ cursor: "pointer" }}>
                                                                                                                    <Form.Check type="checkbox" id={"checkboxAll" + "_" + chData.id} custom>
                                                                                                                        <Form.Check.Input
                                                                                                                            type="checkbox"
                                                                                                                            onClick={(e) => this.CheckAllFun(e, chData.id, cData.id)}
                                                                                                                            checked={chData.checkedall}
                                                                                                                        />
                                                                                                                        <Form.Check.Label htmlFor={"checkboxAll" + "_" + chData.id}>Select All</Form.Check.Label>
                                                                                                                    </Form.Check>
                                                                                                                </li>
                                                                                                            ) : ("")
                                                                                                        }
                                                                                                        {(cData.enabled == false) ? (
                                                                                                            <li style={{ cursor: "pointer" }} className={cData.active}>
                                                                                                                <Form.Check type="checkbox" id={"chapcheckboxOneTwo" + "_" + cData.id} custom>
                                                                                                                    <Form.Check.Input checked={cData.checked} type="checkbox" onClick={(e) => this.questionFunction(e, chData.id, cData.id)} />
                                                                                                                    <Form.Check.Label
                                                                                                                        htmlFor={"chapcheckboxOneTwo" + "_" + cData.id} className='chapter-text'
                                                                                                                    >{getsinglechap.chapter} - {cData.error_questions}</Form.Check.Label>
                                                                                                                </Form.Check>
                                                                                                            </li>
                                                                                                        ) : (
                                                                                                            <li style={{ cursor: "pointer" }} onClick={() => this.setState({ userRestionModalShow: true })} className={cData.active}>
                                                                                                                <Image src={require('../../../images/locked.png')} className="mr-2" width="13" alt="locked image" />
                                                                                                                {getsinglechap.chapter}
                                                                                                            </li>
                                                                                                        )}
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        })}
                                                                                    </React.Fragment>
                                                                                </React.Fragment>) : (
                                                                                    <div className="text-danger text-center mt-5">
                                                                                        {index1 == 0 ? ("No error chapters are available") : ("")}
                                                                                    </div>)}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </Scrollbars>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
                {
                    this.state.mainSelVal == '1' ? (
                        <Row>
                            <Col xl={6} lg={6} md={12} className='mt-3 bottom-card '>
                                <h6 className="mb-4">Source</h6>
                                {/* <Card as={Card.Body} className="p-2 mb-2 mb-2 bg-light-red">
                                    <div className="d-flex justify-content-between pl-3 bg-light-red">
                                        <Form.Label>Practise Questions - <span className="font-weight-bold">
                                            {isNaN(this.state.pquestions1) ? ("0") : (this.state.pquestions1)}
                                        </span></Form.Label>
                                        <Form.Check
                                            custom
                                            type="checkbox"
                                            id="pqchapcheckbox01"
                                            label=""
                                            name="pquestionscheck1"
                                            checked={this.state.pquestionscheck1 != "" ? ('checked') : ("")}
                                            onChange={this.checkeOnchange}
                                        //value={this.state.pquestions1}
                                        />
                                    </div>
                                </Card> */}
                                <Card as={Card.Body} className="p-2 mb-2 bg-dark-pink">
                                    <div className="d-flex justify-content-between pl-3 bg-dark-pink">
                                        <Form.Label>Test Questions - <span className="font-weight-bold">
                                            {isNaN(this.state.examquestions1) ? ("0") : (this.state.examquestions1)}
                                        </span></Form.Label>
                                        <Form.Check
                                            custom
                                            type="checkbox"
                                            id="eqchapcheckbox01"
                                            label=""
                                            name="examquestionscheck1"
                                            onChange={this.checkeOnchange}
                                            //value={this.state.examquestions1}
                                            checked={this.state.examquestionscheck1 != "" ? ('checked') : ("")}
                                        />
                                    </div>
                                </Card>
                            </Col>

                            <Col xl={6} lg={6} md={12} className='mt-3 bottom-card'>
                                <h6 className="mb-4">Question Type</h6>
                                <Card as={Card.Body} className="p-2 mb-2 bg-light-green">
                                    <div className="d-flex justify-content-between pl-3 bg-light-green">
                                        <Form.Label>Un-Answered - <span className="font-weight-bold">
                                            {isNaN(this.state.unanswered1) ? ("0") : (this.state.unanswered1)}
                                        </span></Form.Label>
                                        <Form.Check custom type="checkbox" id="unchapcheckbox03" label=""
                                            name="unansweredcheck1"
                                            onChange={this.checkeOnchange}
                                            //value={this.state.unanswered1} 
                                            checked={this.state.unansweredcheck1 != "" ? ('checked') : ("")} />
                                    </div>
                                </Card>
                                <Card as={Card.Body} className="p-2 mb-2 bg-pink">
                                    <div className="d-flex justify-content-between pl-3 bg-pink">
                                        <Form.Label>Error Questions - <span className="font-weight-bold">
                                            {isNaN(this.state.errorquestions1) ? ("0") : (this.state.errorquestions1)}
                                        </span></Form.Label>
                                        <Form.Check custom type="checkbox" id="erchapcheckbox04" label=""
                                            name="errorquestionscheck1"
                                            onChange={this.checkeOnchange}
                                            //value={this.state.errorquestions1} 
                                            checked={this.state.errorquestionscheck1 != "" ? ('checked') : ("")} />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col xl={6} lg={6} md={12} className='mt-3 bottom-card '>
                                <h6 className="mb-4">Source</h6>
                                {/* <Card as={Card.Body} className="p-2 mb-2 mb-2 bg-light-red">
                                    <div className="d-flex justify-content-between pl-3 bg-light-red">
                                        <Form.Label>Practise Questions - <span className="font-weight-bold">
                                            {isNaN(this.state.pquestions2) ? ("0") : (this.state.pquestions2)}
                                        </span></Form.Label>
                                        <Form.Check
                                            custom
                                            type="checkbox"
                                            id="pqchapcheckbox02"
                                            label=""
                                            name="pquestionscheck2"
                                            checked={this.state.pquestionscheck2 != "" ? ('checked') : ("")}
                                            onChange={this.checkeOnchange}
                                        //value={this.state.pquestions1}
                                        />
                                    </div>
                                </Card> */}
                                <Card as={Card.Body} className="p-2 mb-2 bg-dark-pink">
                                    <div className="d-flex justify-content-between pl-3 bg-dark-pink">
                                        <Form.Label>Test Questions - <span className="font-weight-bold">
                                            {isNaN(this.state.examquestions2) ? ("0") : (this.state.examquestions2)}
                                        </span></Form.Label>
                                        <Form.Check
                                            custom
                                            type="checkbox"
                                            id="eqchapcheckbox02"
                                            label=""
                                            name="examquestionscheck2"
                                            onChange={this.checkeOnchange}
                                            //value={this.state.examquestions1}
                                            checked={this.state.examquestionscheck2 != "" ? ('checked') : ("")}
                                        />
                                    </div>
                                </Card>
                            </Col>

                            <Col xl={6} lg={6} md={12} className='mt-3 bottom-card'>
                                <h6 className="mb-4">Question Type</h6>
                                <Card as={Card.Body} className="p-2 mb-2 bg-light-green">
                                    <div className="d-flex justify-content-between pl-3 bg-light-green">
                                        <Form.Label>Un-Answered - <span className="font-weight-bold">
                                            {isNaN(this.state.unanswered2) ? ("0") : (this.state.unanswered2)}
                                        </span></Form.Label>
                                        <Form.Check custom type="checkbox" id="unchapcheckbox03" label=""
                                            name="unansweredcheck2"
                                            onChange={this.checkeOnchange}
                                            //value={this.state.unanswered1} 
                                            checked={this.state.unansweredcheck2 != "" ? ('checked') : ("")} />
                                    </div>
                                </Card>
                                <Card as={Card.Body} className="p-2 mb-2 bg-pink">
                                    <div className="d-flex justify-content-between pl-3 bg-pink">
                                        <Form.Label>Error Questions - <span className="font-weight-bold">
                                            {isNaN(this.state.errorquestions2) ? ("0") : (this.state.errorquestions2)}
                                        </span></Form.Label>
                                        <Form.Check custom type="checkbox" id="erchapcheckbox04" label=""
                                            name="errorquestionscheck2"
                                            onChange={this.checkeOnchange}
                                            //value={this.state.errorquestions1} 
                                            checked={this.state.errorquestionscheck2 != "" ? ('checked') : ("")} />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    )

                }

                <Row>
                    <Col>
                        <p className="form-text text-danger text-center">{this.state.submitError}</p>
                    </Col>
                </Row>

                <Row className="float-right">
                    <Col>
                        {this.state.mainSelVal == "1" ? (
                            <React.Fragment>
                                {(this.state.unansweredcheck1 == "" && this.state.errorquestionscheck1 == "") || (this.state.pquestionscheck1 == "" && this.state.examquestionscheck1 == "") ? ("") : (
                                    <React.Fragment>
                                        {this.state.loadbutton == 0 ? (<Button onClick={this.handleFormSubmit} className="px-5 btn btn-blue start-btn mt-3">START TEST</Button>) : (
                                            <Link className="px-5 btn btn-blue start-btn mt-3"><span className="spinner-border spinner-border-sm"></span>loading..</Link>
                                        )}
                                    </React.Fragment>

                                )}

                            </React.Fragment>) : (<React.Fragment>
                                {(this.state.unansweredcheck2 == "" && this.state.errorquestionscheck2 == "") || (this.state.pquestionscheck2 == "" && this.state.examquestionscheck2 == "") ? ("") : (
                                    <React.Fragment>
                                        {this.state.loadbutton == 0 ? (<Button onClick={this.handleFormSubmit} className="px-5 btn btn-blue start-btn mt-3">START TEST</Button>) : (<Link className="px-5 btn btn-blue start-btn mt-3"><span className="spinner-border spinner-border-sm"></span>loading..</Link>)}
                                    </React.Fragment>

                                )}

                            </React.Fragment>)}
                    </Col>
                </Row>
                <EamcetRestrictionAlert
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />
            </Container>
        )
    }
};

export default withRouter(compose(graphql(ERROR_EXAM, {
    name: "customfunction"
})
)(ErrorExamHome));