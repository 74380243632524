import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Image, Navbar, Dropdown, Form, FormControl, Button, Container, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Cookies from "es-cookie";
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';
import moment from 'moment';
import WatchNowVideosModalIpe from './WatchNowVideosModalIpe';
import EamcetRestrictionAlert from "../home/EamcetRestrictionAlert";
import './_ipeVideosnew.scss';
import SelectDropDown from '../../../neetjee_guru/components/selectdropdown/SelectDropDown';
import { components } from 'react-select';

import Chemistry from '../../../images/chemistry_icon.png';
import Zoology from '../../../images/zoology_icon.png';
import Physics from '../../../images/physics_icon.png';
import Maths from '../../../images/maths_icon.png';
import Botany from '../../../images/botany_icon.png';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";


const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;


const classes = [
    { value: "1", label: 'Class-XI' },
    { value: "2", label: 'Class-XII' }
];

let subArray = []
{
    (Cookies.get("examid") == 7) || (Cookies.get("examid") == 8) ? (

        subArray = [{ id: '1', subject: 'Botany' }, { id: '5', subject: 'Zoology' }, { id: '2', subject: 'Physics' }, { id: '3', subject: 'Chemistry' }]
    ) : (
        subArray = [{ id: '4', subject: 'Mathematics' }, { id: '2', subject: 'Physics' }, { id: '3', subject: 'Chemistry' }]

    )
}

class IpeVideosSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            dataLiveUrl: "",
            getSubjectsArr: [],
            classValue: "XI",
            selectedSubjectVal: "",
            IPEClassesDataArr: [],
            userRestionModalShow: false,
            subData: subArray,
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));

        }
    }


    componentDidMount = () => {
        const title = GoogleAnalyticsArray[0].Eamcet_IPE_Lectures;
        ReactGA.pageview('/student/IpeClasses', ["rizee"], title);


        if (this.props.globalsubjects != undefined || this.props.globalsubjects != null) {
            let selectedSubject = "";
            const getSubjects = this.state.subData.map((gmap, index) => {
                if (index == 0) {
                    selectedSubject = gmap.id
                    return { ...gmap, active: "active" }

                }
                else {
                    return { ...gmap, active: "" }
                }

            })
            this.setState({
                getSubjectsArr: getSubjects,
                selectedSubjectVal: selectedSubject
            });
        }

    }

    modalaFun = (row) => {

        this.addStudentAnalytics()
        console.log(row)
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });
    }

    handleSubscribeModal = (data) => {
        this.setState({
            userRestionModalShow: true,
        });
    }


    subjectClassName(data) {
        let classname = "";
        if (data.subject == "Botany") {
            classname = `block_white botany mt-res-2 ${data.active}`
        }
        else if (data.subject == "Physics") {
            classname = `block_white physics mt-res-2 ${data.active}`
        }
        else if (data.subject == "Chemistry") {
            classname = `block_white chemistry mt-res-2 ${data.active}`
        }
        else if (data.subject == "Zoology") {
            classname = `block_white zoology mt-res-2 ${data.active}`
        }
        else if (data.subject == "Mathematics") {
            classname = `block_white maths mt-res-2 ${data.active}`
        }

        return classname;

    }

    handleSelect = (subid) => {
        const getSubjects = this.state.getSubjectsArr.map((gmap, index) => {
            if (gmap.id == subid) {
                return { ...gmap, active: "active" }
            }
            else {
                return { ...gmap, active: "" }
            }
        });
        this.setState({
            getSubjectsArr: getSubjects,
            selectedSubjectVal: subid,
        });
    }

    handleSelectClassVal(val) {
        this.setState({
            classValue: val
        });
    }

    getSubjectName = (subid) => {
        let subjectName = "";
        if (subid == "1") {
            subjectName = "Botany";
        } else if (subid == "2") {
            subjectName = "Physics";
        } else if (subid == "3") {
            subjectName = "Chemistry";
        } else if (subid == "4") {
            subjectName = "Mathematics";
        } else if (subid == "5") {
            subjectName = "Zoology";
        }

        return subjectName;
    }


    getClassName = (classid) => {
        let className = "";
        if (classid == "XI") {
            className = "XI";
        } else if (classid == "XII") {
            className = "XII";
        }

        return className;
    }

    getAccessPlans = (planId) => {
        const epriryDateInunixTime = this.isStudentUserValid.expiry_date;
        const currentDateInUnixTime = parseInt((new Date().getTime() / 1000).toFixed(0))

        console.log("expire", epriryDateInunixTime)
        console.log("current", currentDateInUnixTime)
        console.log("planId", planId)

        if (planId == 44 || planId == 45) {
            if (currentDateInUnixTime > epriryDateInunixTime) {
                return true;
            }
        } else {
            return true;
        }

        return false
    }

    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }

    actionFormatterPpt(cell, row, rowIndex) {
        let currentDate = moment().format("DD-MM-YYYY");
        let currentMonth = moment().format('MM');
        let currentRowMonth = moment(row.Date, "DD-MM-YYYY").format("MM");

        console.log("bag", row)
        return (
            <>
                {
                    this.getAccessPlans(this.isStudentUserValid.current_plan_id) && row.freeAccess != "true" ?
                        (
                            (
                                <a className="eamcet_ppt" onClick={() => this.handleSubscribeModal()}><i class="fal fa-file-alt"></i> {row.new}</a>
                            )
                        )
                        :
                        (
                            currentMonth == currentRowMonth ? (
                                row.Date <= currentDate ? (
                                    row.Ppt != "" ? (
                                        <div className="text-center">
                                            <a href={row.Ppt} target="_blank"><i class="fal fa-file-alt"></i> {row.new}</a>
                                        </div>
                                    ) : (
                                        <div className="text-center">
                                            <h6 className="text-center">-</h6>
                                        </div>
                                    )

                                ) : (
                                    <div className="text-center">
                                        <h6 className="text-center">-</h6>
                                    </div>
                                )
                            ) : (

                                currentRowMonth < currentMonth ? (
                                    <div className="text-center">
                                        <a href={row.Ppt} target="_blank"><i class="fal fa-file-alt"></i> {row.new}</a>
                                    </div>

                                ) : (<div className="text-center">
                                    <h6 className="text-center">-</h6>
                                </div>)

                            )
                        )
                }
            </>
        )
    }

    // actionFormatterVideoLecture(cell, row, rowIndex) {

    //     console.log("chaitu",row.Date)


    //     let currentDate = moment().format("DD-MM-YYYY");

    //     let currentMonth = moment().format('MM');
    //     let currentRowMonth = moment(row.Date, "DD-MM-YYYY").format("MM");
    //     console.log(currentMonth, currentRowMonth, "welcome");

    //     return (

    //         <>
    //             {
    //                 this.getAccessPlans(this.isStudentUserValid.current_plan_id) && row.freeAccess != "true" ?
    //                     (
    //                         <div className="text-center">
    //                             <button type="button" className="btn btn btn-custom-blue" onClick={() => this.handleSubscribeModal()}>Watch Now</button>
    //                         </div>
    //                     )
    //                     :
    //                     (
    //                         currentMonth == currentRowMonth ? (
    //                             row.Date <= currentDate ? (
    //                                 row.Videourl != "" ? (
    //                                     <div className="text-center">
    //                                         <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.Videourl)}>Watch Now </button>
    //                                     </div>

    //                                 ) : (
    //                                     <div className="text-center">
    //                                         <button type="button" className="btn btn-custom-blue">Watch Now </button>
    //                                     </div>
    //                                 )
    //                             ) : (
    //                                 <div className="text-center">
    //                                     <button type="button" className="btn btn-custom-blue" >{row.Date}</button>
    //                                 </div>
    //                             )
    //                         ) : (

    //                             currentRowMonth > currentMonth ? (
    //                                 row.Videourl != "" ? (
    //                                     <div className="text-center">
    //                                         <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.Videourl)}>Watch Now </button>
    //                                     </div>
    //                                 ) : (
    //                                     <div className="text-center">
    //                                         <button type="button" className="btn btn-custom-blue">Watch Now </button>
    //                                     </div>
    //                                 )

    //                             ) : (<div className="text-center">
    //                                 <button type="button" className="btn btn-custom-blue" >{row.Date}</button>
    //                             </div>)

    //                         )

    //                     )
    //             }

    //         </>

    //     )
    // }

    actionFormatterVideoLecture(cell, row, rowIndex) {
        return (
            <>
                {
                    this.getAccessPlans(this.isStudentUserValid.current_plan_id) && row.freeAccess != "true" ?
                        (
                            <div className="text-center">
                                <button type="button" className="btn btn btn-custom-blue" onClick={() => this.handleSubscribeModal()}>Watch Now</button>
                            </div>
                        )
                        :
                        (
                            row.Videourl != "" ?
                                (
                                    <div className="text-center">
                                        <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.Videourl)}>Watch Now </button>
                                    </div>
                                )
                                :
                                (
                                    <div className="text-center">
                                        <button type="button" className="btn btn-custom-blue">Watch Now </button>
                                    </div>
                                )
                        )
                }
            </>
        )
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 194,
            source: 2
        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    actionFormatterSNO(cell, row, rowIndex) {
        return (

            <>
                <p className='text-center'>{rowIndex + 1}</p>
            </>

        )
    }

    colFunction() {

        let columns = [
            {
                dataField: "S_No",
                text: "S No",
                // sort: true,
                headerStyle: {
                    width: "10%",
                    background: "#E9ECF1",
                    color: "#0B3573",
                },
                formatter: this.actionFormatterSNO.bind(this),
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                sort: true,
                headerStyle: {
                    width: "30%", background: "#E9ECF1", color: "#0B3573",

                },
                sortFunc: this.sortFuncMulti.bind(this)
            },

            {
                dataField: "Marks",
                text: "IPE Weightage (in marks)",
                headerStyle: {
                    width: "15%", background: "#E9ECF1", color: "#0B3573",
                },
                sortFunc: this.sortFuncMulti.bind(this)


            },
            {
                dataField: "Solution",
                text: "Class Notes",
                headerStyle: {
                    width: "15%", background: "#E9ECF1", color: "#0B3573",
                },
                headerbody: {
                    color: "#000000",
                },
                formatter: this.actionFormatterPpt.bind(this)

            },
            {
                dataField: "Video Lecture",
                text: "Videos (Recorded/Live)",
                headerStyle: {
                    width: "20%", background: "#E9ECF1", color: "#0B3573",
                },
                formatter: this.actionFormatterVideoLecture.bind(this),
            },

        ];
        return columns;
    }

    render() {

        console.log("bbbbb", this.isStudentUserValid.expiry_date)

        if (this.props.ipeClassesData.getServiceData111 != undefined) {
            this.props.ipeClassesData.getServiceData111.map((mainData, index) =>
                this.state.IPEClassesDataArr = mainData.data.filter(item => item.Class == this.state.classValue && item.Subject == this.state.selectedSubjectVal)
            )
        }
        this.state.IPEClassesDataArr.sort(function (x, y) {
            // true values first
            return (x.freeAccess === y.freeAccess) ? 0 : x.freeAccess ? -1 : 1;


        });

        console.log("this.state.IPEClassesDataArr", this.state.IPEClassesDataArr)

        return (
            <Container className='eamcet-ipe-classes mt-4'>
                <Row>
                    <Col sm={12}>
                        <div className='subject-blocks-view'>
                            {
                                this.state.getSubjectsArr.map(sub =>
                                    <Media className={this.subjectClassName(sub)} onClick={() => this.handleSelect(sub.id)}>
                                        <Media.Body>
                                            <h5 className='mt-2 text-center'>{sub.subject}</h5>

                                        </Media.Body>
                                        {
                                            sub.id == "1" ?
                                                (<Image src={Botany} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "2" ?
                                                (<Image src={Physics} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "3" ?
                                                (<Image src={Chemistry} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "5" ?
                                                (<Image src={Zoology} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "4" ?
                                                (<Image src={Maths} className="ml-4" alt="subject" />) : ("")
                                        }
                                    </Media>
                                )
                            }

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <ul className='list-inline class_type_li mt-4'>
                            <li className={this.state.classValue == "XI" ? "active" : ""} onClick={() => this.handleSelectClassVal("XI")}>Class XI</li>
                            <li className={this.state.classValue == "XII" ? "active ml-4" : "ml-4"} onClick={() => this.handleSelectClassVal("XII")}>Class XII</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className='mt-4'>
                        <h5 className='f-res-12'>IP Important Questions & Answers Explained - ( Subject : {this.getSubjectName(this.state.selectedSubjectVal)},Class : {this.getClassName(this.state.classValue)} )</h5>
                        <hr />
                    </Col>
                </Row>
                {
                    this.state.IPEClassesDataArr.length == 0 ?
                        (
                            <Row>
                                <Col sm={12}>
                                    <div className='no_data_text'>Coming Soon...</div>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            <Row>
                                <Col sm={12}>
                                    <CardLessDataTableWithSearch
                                        parentData={this.state.IPEClassesDataArr}
                                        particlecolumns={this.colFunction()}
                                        defaultSorted={[{
                                            dataField: "Chapter",
                                            order: "asc"
                                        }]}
                                    />
                                </Col>
                            </Row>
                        )
                }

                <WatchNowVideosModalIpe data={this.state.dataLiveUrl} show={this.state.liveModalShow} onHide={() => this.setState({ liveModalShow: false })} />

                <EamcetRestrictionAlert
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />
            </Container>

        )

    }

}
export default withRouter(compose(

    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
)(IpeVideosSection));
