export const SubjectCardOne = [{
        id: '01',
        percentage: '0%',
        title: 'The living world',
        tabTitleOne:'Dashboard',
        tabTitleTwo:'Learn',
        tabTitleThree:'Practice',
        tabTitleFour: 'Question',
        tabbottomTitleOne:'Go To Dashboard',
        tabbottomTitleThree:'Start Practice',
        tabbottomTitleFour:'Start Error Exam',
        topics: '06',
        practiceCount: '58%',
        errorCount: '15',
        availableVideos: '3 Videos Available',
        availableMaterial: '20 Formulas And Many More'
    },
    {
        id: '02',
        percentage: '0%',
        title: 'Biological Classification',
        tabTitleOne:'Dashboard',
        tabTitleTwo:'Learn',
        tabTitleThree:'Practice',
        tabTitleFour: 'Question',
        tabbottomTitleOne:'Go To Dashboard',
        tabbottomTitleThree:'Start Practice',
        tabbottomTitleFour:'Start Error Exam',
        topics: '06',
        practiceCount: '58%',
        errorCount: '15',
        availableVideos: '3 Videos Available',
        availableMaterial: '20 Formulas And Many More'
    },
    {
        id: '03',
        percentage: '0%',
        title: 'Science of plants',
        tabTitleOne:'Dashboard',
        tabTitleTwo:'Learn',
        tabTitleThree:'Practice',
        tabTitleFour: 'Question',
        tabbottomTitleOne:'Go To Dashboard',
        tabbottomTitleThree:'Start Practice',
        tabbottomTitleFour:'Start Error Exam',
        topics: '06',
        practiceCount: '58%',
        errorCount: '15',
        availableVideos: '3 Videos Available',
        availableMaterial: '20 Formulas And Many More'
    },
    {
        id: '04',
        percentage: '0%',
        title: 'Plant Kingdom',
        tabTitleOne:'Dashboard',
        tabTitleTwo:'Learn',
        tabTitleThree:'Practice',
        tabTitleFour: 'Question',
        tabbottomTitleOne:'Go To Dashboard',
        tabbottomTitleThree:'Start Practice',
        tabbottomTitleFour:'Start Error Exam',
        topics: '06',
        practiceCount: '58%',
        errorCount: '15',
        availableVideos: '3 Videos Available',
        availableMaterial: '20 Formulas And Many More'
    }
];
export const SubjectCardTwo = [
    {
        id: '05',
        percentage: '0%',
        title: 'Morphology of flowering plants Vegetative',
        description: 'Parts of a typical Angiospermic plant; Vegetative morphology and modifications- Root, Stem and Leaf- types; Venation, Phyllotaxy.',
        topics: '06',
        weightage: '4 Marks',
        totalQuestions: '4586'
    }
];
export const SubjectCardThree = [{
        id: '06',
        percentage: '0%',
        title: 'Modes of Reproduction',
        description: 'Asexual reproduction, binary fission, Sporulation, budding, fragmentation, vegetative propagation in plants, Sexual reproduction in brief, Overview of angiosperm life cycle.',
        topics: '06',
        weightage: '4 Marks',
        totalQuestions: '4586'
    },
    {
        id: '07',
        percentage: '0%',
        title: 'Sexual reproduction in flowering plants',
        description: 'Stamen, microsporangium, pollen grain. Pistil, megasporangium (ovule) and embryo sac; Development of male and female gametophytes. Pollination - Types, agents ,....',
        topics: '06',
        weightage: '4 Marks',
        totalQuestions: '4586'
    }
];