import React, { Component } from 'react'
import { Container, Nav, Navbar, NavDropdown, Badge, Row, Col, Image, Button, Media } from 'react-bootstrap'
import { withRouter, Link } from 'react-router-dom'
import * as Cookies from "es-cookie";
import ChangePassword from './ChangePassword';
import HelpImg from './HelpImg';
import './_scrolltopnavbar.scss';
import Notification from './Notification';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import GITHAMCOLLEGELOGO from '../../../images/gitam_college_logo.png';
import MEGHACOLLEGELOGO from '../../../images/megha.png';
import SEGACOLLEGELOGO from '../../../images/sega_college_logo.jpeg';
import PRAGNACOLLEGELOGO from '../../../images/pragna_college.png';
import OMEGHACOLLEGELOGO from '../../../images/omega.png';
import PrevImages from '../../../images/newpreviouspaper.png';
import PredictImages from '../../../images/predictorImg.png';
import Perforamceanalysis from '../../../images/perfAnalTitleImg.png';
import previouspapersHeader from '../../../images/previousHeader.png'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";


const USERLOGOUT = gql`
  mutation(
    $mobile: String!,
    $device_id: String) {
    userLogout(mobile: $mobile,device_id: $device_id)
  }
`;

class ScrollTopNavbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modalShow: false,
            loader: 0
        }
    }

    logout = e => {
        e.preventDefault();
        this.setState({
            loader: 1
        })

        this.userLogout(Cookies.get("mobile"), "").catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
            console.error(
                "ERR =>",
                error.graphQLErrors.map(x => x.message)
            );
        });

    };
    userLogout = async (mobile,
        device_id) => {
        await this.props.userLogout({
            variables: {
                mobile,
                device_id
            },
            update: (store, { data }) => {
                console.log("data.userLogout", data.userLogout);
                if (data.userLogout) {
                    Cookies.remove("studenttoken");
                    Cookies.remove("studentrefreshtoken");
                    Cookies.remove("studentusername");
                    Cookies.remove("studentemail");
                    Cookies.remove("mobile");
                    Cookies.remove("classid");
                    Cookies.remove("examid");
                    Cookies.remove("exam_name");
                    Cookies.remove("mobileverified");
                    Cookies.remove("targetyear");
                    Cookies.remove("videos");
                    Cookies.remove("branch_name");
                    Cookies.remove("role", "");
                    Cookies.remove("profile_pic");
                    Cookies.remove("student_userlevel");
                    Cookies.remove("stulogintype", "");
                    localStorage.removeItem('profile_pic');
                    localStorage.removeItem('studentglobals');
                    localStorage.removeItem('globalsubjects');
                    localStorage.removeItem("packageplan");
                    Cookies.remove("toggle");
                    this.setState({
                        loader: 0
                    })
                    console.log("window.ReactNativeWebView")
                    if (window.ReactNativeWebView) {
                        this.props.history.push("/student/logineamcet");
                        window.ReactNativeWebView.postMessage("LOGOUT");
                        return;
                    }
                    this.props.history.push("/student/login");
                }
            }
        });
    };

    render() {

        let isStudentUserValid = "";
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        else {
            this.props.history.push("/student/login");
        }
        const isuserValid = JSON.parse(
            isStudentUserValid.user_access_restictions
        );


        return (
            <div className="scroll-header">
                <Navbar className="header-top">
                    <Container>
                        <Button variant="light" className="px-2 py-1 mr-2" onClick={() => this.props.onClick()}>
                            <i className="fal fa-bars" />
                        </Button>
                        {
                            this.props.headerBottom.type == "Exam_pattern" ?
                                (
                                    <Button variant="mr-2 d-flex align-items-center" onClick={this.props.history.goBack}> <i className="fas fa-long-arrow-alt-left text-white font-weight-bold" /> <span className="font-weight-bold ml-2 d-none d-md-block text-white">Back</span></Button>
                                )
                                :
                                ("")
                        }
                        {
                            this.props.headerBottom.type == "Quiz_Exam" ?
                                (
                                    <Button variant="mr-2 d-flex align-items-center" onClick={this.props.history.goBack}> <i className="fas fa-long-arrow-alt-left text-white font-weight-bold" /> <span className="font-weight-bold ml-2 d-none d-md-block text-white">Back</span></Button>
                                )
                                :
                                ("")
                        }
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <div className="mr-auto d-flex align-items-center">
                                {
                                    isBrowser == true && (Cookies.get("institution_id") == 46 || Cookies.get("institution_id") == 50 || Cookies.get("institution_id") == 51 || Cookies.get("institution_id") == 54 || Cookies.get("institution_id") == 53) ?
                                        (

                                            <Media className="college_logo_syles" >
                                                <Image
                                                    className="mr-1"
                                                    src={
                                                        Cookies.get("institution_id") == 46 ?
                                                            (GITHAMCOLLEGELOGO) :
                                                            (
                                                                Cookies.get("institution_id") == 50 ?
                                                                    (MEGHACOLLEGELOGO) :
                                                                    (
                                                                        Cookies.get("institution_id") == 51 ?
                                                                            (SEGACOLLEGELOGO) :
                                                                            (
                                                                                Cookies.get("institution_id") == 54 ?
                                                                                    (PRAGNACOLLEGELOGO) :
                                                                                    (
                                                                                        Cookies.get("institution_id") == 53 ?
                                                                                            (OMEGHACOLLEGELOGO) : ("")
                                                                                    )
                                                                            )
                                                                    )
                                                            )
                                                    }
                                                    alt="logo"
                                                />
                                                <Media.Body>
                                                    {
                                                        Cookies.get("institution_id") == 46 ?
                                                            (
                                                                <div>
                                                                    <p class="mb-0">CODE:59425</p>
                                                                    <h5 className="mb-0">GITAM JUNIOR COLLEGE</h5>
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                Cookies.get("institution_id") == 50 ?

                                                                    (<h5 className="mb-0 mt-3 ml-2">MEGHA JUNIOR COLLEGE</h5>)
                                                                    :
                                                                    (
                                                                        Cookies.get("institution_id") == 51 ?
                                                                            (<h5 className="mb-0 mt-3 ml-2">SAGE ACADEMY</h5>) :
                                                                            (
                                                                                Cookies.get("institution_id") == 54 ?
                                                                                    (<h5 className="mb-0 mt-3 ml-2">PRAGNA ACADEMY</h5>) :
                                                                                    (
                                                                                        Cookies.get("institution_id") == 53 ?
                                                                                            (<h5 className="mb-0 mt-3 ml-2">OMEGA JUNIOR COLLEGE</h5>)
                                                                                            :
                                                                                            ("")
                                                                                    )
                                                                            )
                                                                    )
                                                            )
                                                    }
                                                </Media.Body>
                                            </Media>
                                        )
                                        :
                                        (
                                            <>


                                                {
                                                    Cookies.get("student_userlevel") != "1" && (Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8) ? ("") : (
                                                        <>
                                                            {Cookies.get("institute_logo") != "" ? (
                                                                <Image className="d-none d-xl-block d-lg-block" src={Cookies.get("institute_logo")} height="30" style={{ width: 'auto', minWidth: 60 }} />
                                                            ) : ("")}
                                                            <h6 className="ml-2 mb-0 d-none d-xl-block d-lg-block">{Cookies.get("institute_name")}</h6>

                                                        </>
                                                    )

                                                }

                                            </>
                                        )
                                }

                            </div>

                            {
                                isuserValid.referred_mobile == "gsl635" ? ("") : (

                                    <Nav className="ml-auto">
                                        <Notification />
                                        <NavDropdown id="basic-nav-dropdown2" className="no-arrow" alignRight title={
                                            <div className="profile-img d-flex align-items-center">
                                                {Cookies.get("profile_pic") != "" ? (

                                                    <Image src={`https://admin.rizee.in/files/${localStorage.getItem("profile_pic")}`} roundedCircle alt="profile-img" />
                                                ) : (
                                                    <Image src={require('../../../images/businessman.png')} roundedCircle alt="profile-img" />
                                                )}

                                                <div className="text mx-2 mt-2">
                                                    {this.state.loader == 0 ? (<React.Fragment>
                                                        <div className="profile-name">{Cookies.get("studentusername")}</div>
                                                        <small>Exam- {Cookies.get("exam_name")}, Class - {Cookies.get("classid") == "1" ? ("XI") : ("XII")}</small>
                                                    </React.Fragment>) : (<React.Fragment>
                                                        <div className="profile-name text-dark">{Cookies.get("studentusername")}</div>
                                                        <small className="text-muted">Logging Out...</small>

                                                    </React.Fragment>)}
                                                </div>
                                            </div>
                                        }>
                                            <NavDropdown.Item className="font-weight-bold">{Cookies.get("studentemail")}</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item as={Link} to="/student/profile"><i class="fas fa-key"></i> Activate Partner Offer</NavDropdown.Item>
                                            {isuserValid.ins_profile_tab == true ? ("") : (<NavDropdown.Item as={Link} to="/student/profile"><i className="far fa-user mr-2" /> My Profile</NavDropdown.Item>)}
                                            {isuserValid.ins_package_tab == true ? ("") : (<NavDropdown.Item as={Link} to={Cookies.get("examid") == 1 || Cookies.get("examid") == 2 ? "/student/package" : "/student/plans_section"}><i className="far fa-sack mr-2" /> Subscription Plans</NavDropdown.Item>)}

                                            {/* <NavDropdown.Item onClick={() => this.setState({ modalShow: true })}><i className="far fa-key mr-2" /> Change Password</NavDropdown.Item> */}
                                            {isuserValid.ins_feedback_tab == true ? ("") : (<NavDropdown.Item as={Link} to="/student/feedback"><i className="far fa-comment-alt-lines mr-2" /> Feedback</NavDropdown.Item>)}

                                            <NavDropdown.Divider />
                                            <NavDropdown.Item as={Link} onClick={this.logout}><i className="far fa-sign-out mr-2" /> Logout</NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav>
                                )
                            }
                        </Navbar.Collapse>
                    </Container>

                    <ChangePassword
                        show={this.state.modalShow}
                        onHide={() => this.setState({ modalShow: false })}
                    />
                </Navbar>
                {
                    this.props.headerBottom.type == "Exam_pattern" ?
                        (
                            <div className="text-center text-white">
                                <h3 className="">{this.props.headerBottom.title}</h3>
                            </div>
                        ) : this.props.headerBottom.type == "prev_exams" ? (
                            <>
                                {/* <div className='prev-main'>
                                    <Image src={PrevImages} className="prev-img" alt="profile-img" width="107" height="90" />
                                    <h3 className="prev-txt">{this.props.headerBottom.title}</h3>
                                </div> */}
                                <Container>
                                    <Row>
                                        <Col lg={2}>
                                            <Image src={previouspapersHeader} alt="profile-img" width="120" height="110" />
                                        </Col>
                                        <Col lg={6} className="col-txt mt-4">
                                            <h5 className='prev-main-txt'>{this.props.headerBottom.title}</h5>
                                            {
                                                Cookies.get("examid") == 2 ?
                                                    (<p className='prev_sub_txt'>Note: We are introducing JEE advance in same marks pattern similar to real time as NTA pattern</p>)
                                                    :
                                                    ("")
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                        ) : this.props.headerBottom.type == "col_pred" ? (
                            <>
                                <Container>
                                    <Row>
                                        <Col lg={6} className="col-txt mt-4">
                                            <h5 className='main-txt'>FIND YOUR COLLEGE & BRANCH</h5>
                                            <h6 className='sub-txt'>Giving your exam was just the first step. Finding the college that best suits you is the next step. Find out the likelihood of acceptance by using our {Cookies.get("examid") == 1 || Cookies.get("examid") == 2 ? "" : "EAMCET"} college predictor.</h6>
                                        </Col>
                                        <Col lg={6} className="col-img">
                                            <Image src={PredictImages} alt="profile-img" width="250" height="150" />
                                        </Col>

                                    </Row>
                                </Container>
                            </>
                        ) : this.props.headerBottom.type == "preformace_analysis" ? (
                            <>
                                <Container>
                                    <Row>
                                        <Col lg={2} >
                                            <Image src={Perforamceanalysis} alt="profile-img" width="150" height="150" />
                                        </Col>
                                        <Col lg={4} className="col-txt mt-4">
                                            <h5 className='main-txt'> Performance Analysis</h5>
                                            <p className='anal_sub_txt'>Practicing is just a part of preparation, but how you are  performing and analyze every area is a crucial part of your preparation</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                        ) : this.props.headerBottom.type == "bookstore_module" ? (
                            <>
                                <Container>
                                    <Row>
                                        {
                                            isuserValid.partnership_logo == "" ?
                                                (<Col sm={2}></Col>)
                                                :
                                                (
                                                    <Col sm={2}>
                                                        <Image src={isuserValid.partnership_logo} className="partnerCircle" width="120" height="100" alt="store" />
                                                    </Col>
                                                )

                                        }

                                        {
                                            isuserValid.partnership_name == "" ?
                                                (
                                                    <Col sm={8}>
                                                        <h5 className='text-center  welcome_text mb-5'>Welcome to Rizee Offers</h5>
                                                    </Col>
                                                )
                                                :
                                                (
                                                    <Col sm={8}>
                                                        <h4 className='text-center store_name'>{isuserValid.partnership_name}</h4>
                                                        <h5 className='text-center mt-3 welcome_text'>Welcome to Partner Offers</h5>
                                                    </Col>
                                                )
                                        }


                                    </Row>
                                </Container>
                            </>
                        ) :

                            ("")

                }
                {/* {
                    this.props.headerBottom.type == "Quiz_Exam" ?
                        (
                            <div className="text-center text-white">
                                <h3 className="quiz-text">Quiz</h3>
                            </div>
                        )
                        :
                        ("")
                } */}
                <Container className="header-bottom">
                    {
                        isMobile == true && (Cookies.get("institution_id") == 46 || Cookies.get("institution_id") == 50 || Cookies.get("institution_id") == 51 || Cookies.get("institution_id") == 54 || Cookies.get("institution_id") == 53) ?
                            (
                                <Media className="college_logo_syles" >
                                    <Image
                                        className="mr-1"
                                        src={
                                            Cookies.get("institution_id") == 46 ?
                                                (GITHAMCOLLEGELOGO) :
                                                (
                                                    Cookies.get("institution_id") == 50 ?
                                                        (MEGHACOLLEGELOGO) :
                                                        (
                                                            Cookies.get("institution_id") == 51 ?
                                                                (SEGACOLLEGELOGO) :
                                                                (
                                                                    Cookies.get("institution_id") == 54 ?
                                                                        (PRAGNACOLLEGELOGO) :
                                                                        (
                                                                            Cookies.get("institution_id") == 53 ?
                                                                                (OMEGHACOLLEGELOGO)
                                                                                : ("")
                                                                        )
                                                                )
                                                        )
                                                )
                                        }
                                        alt="logo"
                                    />
                                    <Media.Body>
                                        {
                                            Cookies.get("institution_id") == 46 ?
                                                (
                                                    <div>
                                                        <p class="mb-0">CODE:59425</p>
                                                        <h5 className="mb-0">GITAM JUNIOR COLLEGE</h5>
                                                    </div>
                                                )
                                                :
                                                (
                                                    Cookies.get("institution_id") == 50 ?

                                                        (<h5 className="mb-0 mt-3 ml-2">MEGHA JUNIOR COLLEGE</h5>)
                                                        :
                                                        (
                                                            Cookies.get("institution_id") == 51 ?
                                                                (<h5 className="mb-0 mt-3 ml-2">SAGE ACADEMY</h5>) :
                                                                (
                                                                    Cookies.get("institution_id") == 54 ?
                                                                        (<h5 className="mb-0 mt-3 ml-2">PRAGNA ACADEMY</h5>) :
                                                                        (
                                                                            Cookies.get("institution_id") == 53 ?
                                                                                (<h5 className="mb-0 mt-3 ml-2">OMEGA JUNIOR COLLEGE</h5>)
                                                                                :
                                                                                ("")
                                                                        )
                                                                )
                                                        )
                                                )
                                        }
                                    </Media.Body>
                                </Media>

                            ) : ("")
                    }

                </Container>
                {
                    this.props.headerBottom.type == "Exam_pattern" || this.props.headerBottom.type == "eamcet_view" || this.props.headerBottom.type == "prev_exams" || this.props.headerBottom.type == "col_pred" || this.props.headerBottom.type == "preformace_analysis" || this.props.headerBottom.type == "bookstore_module" ?
                        ("")
                        :
                        (<HelpImg headerBottom={this.props.headerBottom} />)
                }

            </div>
        )
    }
}


export default withRouter(
    graphql(USERLOGOUT, {
        name: "userLogout"
    })
        (ScrollTopNavbar));