import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import { Row, Tabs, Tab, Card, Image, Button, Col, Table, Media, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './_formulaeSheets.scss';
import Chemistry from '../../../images/chemistry_icon.png';
import Zoology from '../../../images/zoology_icon.png';
import Physics from '../../../images/physics_icon.png';
import Maths from '../../../images/maths_icon.png';
import Botany from '../../../images/botany_icon.png';
import Arrow from "../../../images/eamcet_arrow.png"
import SyllabusPdfTable from './syllabusPdfTable';
import UserRestrictionAlertInstitute from "../home/UserRestrictionAlertInstitute";
import EamcetRestrictionAlert from "../home/EamcetRestrictionAlert";

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';
import { withRouter } from "react-router-dom";
import * as compose from 'lodash.flowright';

let subArray = []


class FormulaeSheetsEamcet extends React.Component {

    constructor(props) {
        super(props)
        this.handleChapterChange = this.handleChapterChange.bind(this);
        this.state = {
            showPdf: false,
            pdfUrl: "",
            getSubjectsArr: [],
            selectedSubjectVal: "",
            getEamcetFormulaeSheetsArr: [],
            onloadSubjectWiseChapters: 0,
            expandval: "",
            chapterValue: "",
            classData: { value: 1, label: 'Class-XI' },
            userRestionModalShow: false,
            subscribeDetails: "",
            subData: subArray,

        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }

    }

    componentDidMount = () => {
        if (this.props.getSubjectOrder != undefined || this.props.getSubjectOrder != null) {
            let selectedSubject = "";
            const getSubjects = this.props.getSubjectOrder.map((gmap, index) => {
                if (index == 0) {
                    selectedSubject = gmap.id
                    return { ...gmap, active: "active" }

                }
                else {
                    return { ...gmap, active: "" }
                }

            })
            this.setState({
                getSubjectsArr: getSubjects,
                selectedSubjectVal: selectedSubject
            });
        }
    }

    subjectClassName(data) {
        let classname = "";
        if (data.subject == "Botany") {
            classname = `block_white botany mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Physics") {
            classname = `block_white physics mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Chemistry") {
            classname = `block_white chemistry mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Zoology") {
            classname = `block_white zoology mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Mathematics") {
            classname = `block_white maths mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Biology") {
            classname = `block_white botany mobile_card_eamcet ${data.active}`

        }

        return classname;

    }

    handleSelect = (subid) => {
        this.setState({ getEamcetFormulaeSheetsArr: [], onloadSubjectWiseChapters: 1 });
        const getSubjects = this.state.getSubjectsArr.map((gmap, index) => {
            if (gmap.id == subid) {
                return { ...gmap, active: "active" }

            }
            else {
                return { ...gmap, active: "" }
            }

        });

        let subjectWiseTestsArr = [];
        if (this.props.getEamcetFormulaeSheetsArr != undefined) {
            const getTestsArr = this.props.getEamcetFormulaeSheetsArr;
            getTestsArr.filter(item => item.class_id == parseInt(this.state.classValue) && item.subject_id == parseInt(subid))
                .map(item2 => {

                    subjectWiseTestsArr.push(item2);
                });
        }

        this.setState({
            getSubjectsArr: getSubjects,
            selectedSubjectVal: subid,
            getEamcetFormulaeSheetsArr: subjectWiseTestsArr,
            showPdf: false
        });
    }

    selectHandleInputChange = (evalue) => {
        console.log("ram", evalue)
        const value = evalue
        let labelval = ""

        if (value == "1") {
            labelval = "Class  XI"

        } else {
            labelval = "Class  XII"
        }
        this.setState({
            classData: {
                value: value,
                label: labelval
            },
            showPdf: false

        })
    }

    getSubjectName = (subid) => {
        let subjectName = "";
        if (subid == "1") {
            subjectName = "Botany";
        } else if (subid == "2") {
            subjectName = "Physics";
        } else if (subid == "3") {
            subjectName = "Chemistry";
        } else if (subid == "4") {
            subjectName = "Mathematics";
        } else if (subid == "5") {
            subjectName = "Zoology";
        } else if (subid == "6") {
            subjectName = "Biology"
        }


        return subjectName;
    }

    getClassName = (classid) => {
        let className = "";
        if (classid == "1") {
            className = "Class XI";
        } else if (classid == "2") {
            className = "Class XII";
        }

        return className;
    }

    getblocks = (indexVal) => {
        if (this.state.expandval != indexVal.toString()) {
            this.setState({ expandval: indexVal })
        }
        else {
            this.setState({ expandval: "" })
        }
    }

    handlePdfView = (ppt) => {
        console.log("maths")
        console.log(ppt)
        this.setState({
            showPdf: !this.state.showPdf,
            pdfUrl: ppt
        });
    }

    modalaFun = (info, index) => {
        console.log("info", info)
        let isStudentUserValid = ""
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        console.log(isStudentUserValid)
        console.log(isStudentUserValid.current_plan_id)
        console.log(Cookies.get("institution_id"))

        return (
            this.handlePdfView(info.ppt)
        )
    }

    handleChapterChange = (event) => {
        this.setState({
            chapterValue: event.target.value
        })
    }

    handleSubscribeModal = () => {
        this.setState({
            userRestionModalShow: true,
        })

    }

    getAccessPlans = (planId, class_id) => {
        const epriryDateInunixTime = this.isStudentUserValid.expiry_date;
        const currentDateInUnixTime = parseInt((new Date().getTime() / 1000).toFixed(0))


        if (this.props.getEamcetFormulaeSheetsArr[0].enabled == true) {
            return false
        } else if (this.props.getEamcetFormulaeSheetsArr[0].enabled == false) {
            return true
        }
    }

    startLearning=(data)=>{
        this.props.history.push({
            pathname: "/student/chapter-wise-learning",
            state: {
                chapterData:data,
                clickType:"revision_material",
                Ipe_chapter_id:data.chapter_id,
                pathname:"/student/FormulaeSheets"


            }
        })
    }

    render() {

        let indexVal = [];
        let formulaSheetsData = []
        let keyword = this.state.chapterValue;


        // if(this.props.getEamcetFormulaeSheetsArr != undefined && this.props.getEamcetFormulaeSheetsArr.length > 0){
        //     formulaSheetsData = this.props.getEamcetFormulaeSheetsArr[0].data.filter((sub) => sub.Subject_id == this.state.selectedSubjectVal)
        //     formulaSheetsData.sort(function (x, y) {
        //         // true values first
        //         return (x.freeAccess === y.freeAccess) ? 0 : x.freeAccess ? -1 : 1;


        //     });

        //     if (this.state.classData.value == "1") {
        //         formulaSheetsData = formulaSheetsData.filter((item) => item.class == "XI")

        //     } else {
        //         formulaSheetsData = formulaSheetsData.filter((item) => item.class == "XII")

        //     }


        //     if (keyword !== '') {
        //         formulaSheetsData = formulaSheetsData.filter((item) => {
        //             return item.chapter.toLowerCase().startsWith(keyword.toLowerCase());
        //         });
        //     }
        // }

        if (this.props.getEamcetFormulaeSheetsArr != undefined && this.props.getEamcetFormulaeSheetsArr.length > 0) {
            formulaSheetsData = this.props.getStateChapterNames.filter(item => item.id == this.state.selectedSubjectVal)
            console.log("formulaSheetsData",formulaSheetsData)


            if (this.state.classData.value == 1) {
                formulaSheetsData = formulaSheetsData[0]?.state_chapters.filter(item => item.class_id == 1)
            } else {
                formulaSheetsData = formulaSheetsData[0]?.state_chapters.filter(item => item.class_id == 2)
            }
            if (keyword !== '') {
                formulaSheetsData = formulaSheetsData.filter((item) => {
                    return item.chapter_name.toLowerCase().startsWith(keyword.toLowerCase());
                });
            }
        }



        return (
            <Container className='eamcet-formulae-sheets mt-5'>
                <Row>
                    <Col sm={12}>
                        <div className='subject-blocks-view'>
                            {
                                this.state.getSubjectsArr.map(sub =>
                                    <Media className={this.subjectClassName(sub)} onClick={() => this.handleSelect(sub.id)}>
                                        <Media.Body>
                                            <h5 className='mt-2 text-center'>{sub.subject}</h5>

                                        </Media.Body>
                                        {
                                            sub.id == "1" ?
                                                (<Image src={Botany} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "2" ?
                                                (<Image src={Physics} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "3" ?
                                                (<Image src={Chemistry} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "5" ?
                                                (<Image src={Zoology} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "4" ?
                                                (<Image src={Maths} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == 6 ?
                                                (<Card.Img variant="top" src={Botany} className="mobile_sub_img" alt="subject" />) : ("")
                                        }
                                    </Media>
                                )
                            }

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <ul className='list-inline class_type_li mt-4'>
                            <li className={this.state.classData.value == "1" ? "active" : ""} onClick={() => this.selectHandleInputChange("1")}>Class XI</li>
                            <li className={this.state.classData.value == "2" ? "active ml-4" : "ml-4"} onClick={() => this.selectHandleInputChange("2")}>Class XII</li>
                        </ul>
                    </Col>

                    <Col sm={6}>
                        <input
                            className='float-right search_bar mt-4'
                            type="search"
                            placeholder='  search Chapter'
                            value={this.state.chapterValue} onChange={this.handleChapterChange}
                        />
                        <i className='fa fa-search search-icon'></i>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className='mt-4'>
                        <h5 className='f-res-12'>Revision Material - ( Subject : {this.getSubjectName(this.state.selectedSubjectVal)},  Class : {this.state.classData.value == 1 ? "XI" : "XII"} )</h5>
                        <hr />
                    </Col>
                </Row>

                {/* <Row>
                    
                    {
                        formulaSheetsData.length == 0 ?
                            (
                                <Col sm={12}>
                                    <div className='no_data_text'>Coming Soon....</div>

                                </Col>
                            )
                            :
                            (
                                
                                    !this.state.showPdf && formulaSheetsData.map((item, index) => {
            
                                        return (
                                            <>
                                                {
                                                this.getAccessPlans(this.isStudentUserValid.current_plan_id, 0) || item.freeAccess == "true" ?
                                                    (
                                                        <Col sm={12} lg={6}>
                                                            <Card className='chapter_card mt-4 cursor_pointer' onClick={() => this.modalaFun(item, index)}>
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col sm={10}>
                                                                            <h6 className='chapter_name mt-2'><span className='mr-2'>{index + 1}.</span>{item.chapter}</h6>
                                                                        </Col>
                                                                        <Col sm={2}>
                                                                            <img src={Arrow} className='arrow pos_sn_aligntment' />
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    )
                                                    :
                                                    (
                                                        <Col sm={12} lg={6}>
                                                            <Card className='chapter_card mt-4 cursor_pointer' onClick={() => this.handleSubscribeModal()}>
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col sm={10}>
                                                                            <h6 className='chapter_name mt-2'><span className='mr-2'>{index + 1}.</span>{item.chapter}</h6>
                                                                        </Col>
                                                                        <Col sm={2}>
                                                                            <img src={Arrow} className='arrow pos_sn_aligntment' />
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    )
                                            }
                                            </>
                                        )
                                    })
                                
                            )
                    }

                    <SyllabusPdfTable
                        showPdf={this.state.showPdf}
                        pdfUrl={this.state.pdfUrl}
                        handlePdfView={this.handlePdfView}
                    />
                   
                   



                </Row> */}

                <Row>
                    {
                        formulaSheetsData != "" && formulaSheetsData != undefined && formulaSheetsData.map((item, index) => {
                            return (
                                item.revisionMaterialEnabled == false ?
                                    (
                                        <Col sm={12} lg={6}>
                                            <Card className='chapter_card mt-4 cursor_pointer' onClick={() => this.startLearning(item)}>
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={10}>
                                                            <h6 className='chapter_name mt-2'><span className='mr-2'>{index + 1}.</span>{item.chapter_name}</h6>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <img src={Arrow} className='arrow pos_sn_aligntment' />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                    :
                                    (
                                        <Col sm={12} lg={6}>
                                            <Card className='chapter_card mt-4 cursor_pointer' onClick={() => this.handleSubscribeModal()}>
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={10}>
                                                            <h6 className='chapter_name mt-2'><span className='mr-2'>{index + 1}.</span>{item.chapter_name}</h6>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <img src={Arrow} className='arrow pos_sn_aligntment' />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>

                                        </Col>
                                    )
                            )

                        })
                    }
                </Row>
                <EamcetRestrictionAlert
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />
            </Container>

        )

    }

}

export default withRouter(compose(
    )(FormulaeSheetsEamcet));
    