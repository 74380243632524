import { thisExpression } from '@babel/types';
import React, { Component } from 'react';
import { Row, Tabs, Tab, Card, Image, Button, Col, Table } from 'react-bootstrap';
import './detailednotes.scss';
import DetailedPdfTable from './detailedPdfTable';
import UserRestrictionAlertInstitute from '../home/UserRestrictionAlertInstitute';
import * as Cookies from "es-cookie";
import LockSimple from './LockSimple.png'

class BotonyDetailedNotes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPdf: false,
            pdfUrl: "",
            userRestionModalShow: false
        }
        this.data = [
            {
                name: 'THE LIVING WORLD',
                url: "https://rizee.in/econtent/detailed_notes/BOT1_6467_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'BIOLOGICAL CLASSIFICATION',
                url: "https://rizee.in/econtent/detailed_notes/BOT2_4977_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'SCIENCE OF PLANTS-BOTANY',
                url: "https://rizee.in/econtent/detailed_notes/BOT3_2439_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'PLANT KINGDOM',
                url: "https://rizee.in/econtent/detailed_notes/BOT4_4762_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'MORPHOLOGY OF FLOWERING PLANTS',
                url: "https://rizee.in/econtent/detailed_notes/BOT5_7875_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'MODES OF REPRODUCTION',
                url: "https://rizee.in/econtent/detailed_notes/BOT6_7432_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'SEXUAL REPRODUCTION IN FLOWERING PLANTS',
                url: "https://rizee.in/econtent/detailed_notes/BOT7_6293_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'TAXONOMY OF ANGIOSPERM',
                url: "https://rizee.in/econtent/detailed_notes/BOT8_1865_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'THE UNIT OF LIFE',
                url: "https://rizee.in/econtent/detailed_notes/BOT9_3554_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'BIOMOLECULES',
                url: "https://rizee.in/econtent/detailed_notes/BOT10_1819_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'CELL CYCLE AND CELL DIVISION',
                url: "https://rizee.in/econtent/detailed_notes/BOT11_2305_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'HISTOLOGY AND ANOTOMY OF FLOWERING PLANTS',
                url: "https://rizee.in/econtent/detailed_notes/BOT12_7373_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'PLANT ECOLOGY',
                url: "https://rizee.in/econtent/detailed_notes/BOT12a_7374_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'TRANSPORT IN PLANTS',
                url: "https://rizee.in/econtent/detailed_notes/BOT13_8089_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'MINERAL NUTRITION',
                url: "https://rizee.in/econtent/detailed_notes/BOT14_6242_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'ENZYMES',
                url: "https://rizee.in/econtent/detailed_notes/BOT15_5193_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'PHOTOSYNTHESIS IN HIGHER PLANTS',
                url: "https://rizee.in/econtent/detailed_notes/BOT16_5707_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'RESPIRATION IN PLANTS',
                url: "https://rizee.in/econtent/detailed_notes/BOT17_5891_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'PLANT GROWTH AND DEVELOPMENT',
                url: "https://rizee.in/econtent/detailed_notes/BOT18_2718_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'MICROBIOLOGY',
                url: "https://rizee.in/econtent/detailed_notes/BOT19_7556_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'PRINCIPLES OF INHERITANCE AND VARIATION',
                url: "https://rizee.in/econtent/detailed_notes/BOT21_4775_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'MOLECULAR BASIS OF INHERITANCE',
                url: "https://rizee.in/econtent/detailed_notes/BOT22_7906_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'BIOTECHNOLOGY PRINCIPLES AND PROCESSES',
                url: "https://rizee.in/econtent/detailed_notes/BOT23_4418_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'BIOTECHNOLOGY AND ITS APPLICATIONS',
                url: "https://rizee.in/econtent/detailed_notes/BOT24_4316_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'STRATEGIES AND ENHANCEMENT IN FOOD PRODUCTION',
                url: "https://rizee.in/econtent/detailed_notes/BOT25_1489_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'MICROBES IN HUMEN WELFARE',
                url: "https://rizee.in/econtent/detailed_notes/BOT26_3922_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            }

        ]
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }
    modalaFun = (info, index) => {
        console.log(info)
        let isStudentUserValid = ""
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        console.log(isStudentUserValid)
        console.log(isStudentUserValid.current_plan_id)
        console.log(Cookies.get("institution_id"))
        return (
            this.handlePdfView(info.url)
        )

    }

    handlePdfView = (url) => {
        console.log("botany")
        console.log(url)
        this.setState({
            showPdf: !this.state.showPdf,
            pdfUrl: url
        });
    }
    render() {
        return (


            <Row>
                {
                    !this.state.showPdf && this.data.map((cardInfo, index) => {
                        return (
                            <Col sm={6} className="mt-4">
                                <div class="bg_white">
                                    <Row>
                                        <Col sm={8}>
                                            <h6 class="title mt-2">{cardInfo.name} </h6>

                                        </Col>
                                        <Col sm={4}>
                                            {
                                                cardInfo.value === "Coming Soon"
                                                    ? <h6 className="float-right viewNotesComingSoonCls" onClick={() => this.modalaFun(cardInfo, index)}>{cardInfo.value}</h6> :
                                                    <h6 className="float-right viewNotesCls" onClick={() => this.modalaFun(cardInfo, index)}>{cardInfo.value}</h6>
                                            }

                                        </Col>
                                    </Row>
                                </div>
                            </Col>


                        )
                    })
                }
                <DetailedPdfTable
                    showPdf={this.state.showPdf}
                    pdfUrl={this.state.pdfUrl}
                />
                <UserRestrictionAlertInstitute
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />
            </Row>
        )

        /*return (
            <div class="container">
                <Row>
                    {
                        !this.state.showPdf && this.data.map((cardInfo) => {
                            return (
                                <Col>
                                    <Card className="card_main mt-8">
                                        <Card.Body className="d-flex justify-content-between">
                                            <Card.Title className="card_title">{cardInfo.name}</Card.Title>
                                            <Button variant="info" size="md" className="bot content-style" onClick={()=> this.handlePdfView(cardInfo.url)}>{cardInfo.value}</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
                <DetailedPdfTable
                         showPdf={this.state.showPdf}
                         pdfUrl={this.state.pdfUrl}
                />
            </div>
        )*/
    }
}
export default BotonyDetailedNotes;







