import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Image, Navbar, Dropdown, Form, FormControl, Button, Container, Media } from 'react-bootstrap';
import * as Cookies from "es-cookie";
import { components } from 'react-select';
import SelectDropDown from '../../../neetjee_guru/components/selectdropdown/SelectDropDown';
import MultiSelect from "react-multi-select-component";
import { withRouter, Link } from "react-router-dom";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withApollo } from "react-apollo";
import CardLessDataTableWithOutSearch from "../../../neetjee_guru/components/datatables/CardLessDataTableWithOutSearch"
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';
import './_Ntapredictor.scss';
import ClearModal from '../collegeRankPredictor/clearAllColleges'
import shortList from '../../../images/happy-students.png'
import { useTouchSensor } from 'react-beautiful-dnd';
import UserRestrictionAlert from "../home/UserRestrictionAlert";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
};

const COLLEGE_SHORTLIST = gql`
mutation(
    $params :NTAShortListedCollege
){
    addNTAShortListedCollege(
        params : $params
    )
}
`;

const DELETE_SHORTLISTED_COLLEGE = gql`
mutation(
    $params:DeletePrediction
    ){
    deleteCollegePredictor(
        params:$params
        )
}
`;

const removeALL_Shortlisted_colleges = gql`
mutation($params:AllShortlistedColleges){
    removeAllShortlistedColleges(params:$params)
}
`;

const advanceExam = [
    { value: 2, label: 'Indian Institute of Technology (IIT)' },
];

class NTAPredictorSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userRestionModalShow: false,

            equotaselData: [],
            equotaselValue: [],
            quotaFilterData: [],

            eseattypeselData: [],
            eseattypeselValue: [],
            seatTypeFilterData: [],

            estateselData: [],
            estateselValue: [],
            stateOnChangeData: "",
            stateFilterData: [],

            ecollegetypeselData: [],
            ecollegetypeselValue: [],
            collegeTypeOnChangeData: "",
            collegetypeFilterData: [],

            collegeFilterData: [],

            ecollegeselData: [],
            ecollegeselValue: [],

            egenderselData: [],
            egenderselValue: [],

            yearsArray: [],
            yearValue: { value: this.props.getNTAPredictorYears[0].year, label: this.props.getNTAPredictorYears[0].name },

            inputRank: 0,

            getNTACollegesPrediction: [],
            showSpinner: "hide",

            ebranchselData: [],
            ebranchselValue: [],
            branchOnchnageData: "",
            branchFilterData: [],

            eroundselData: [{ value: 6, label: 6 }],
            eroundselValue: [6],
            error_display: "",
            shortListStatus: 0,
            clearallModal: false,
            spinnerClear: '0',

            input_exam_type: "mains",
            input_exam_type_value: 1,
            b_arch_type_value: 0,
            degreeTypeValue: 1,

            examTypeValue: { value: 2, label: "Indian Institute of Technology" },
            enablearchSubtypes: false,

            shortListPage: false,
            shortlistPageLoading: "hide",

            fourdegreeCheckBox: true,
            dualdegreecheck: false,
            allDegreecheckbox: false,

            mainCheckBox: true,
            advanceCheckBox: false,
            bArchCheckbox: false
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }

    componentDidMount() {

        const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

        if (isuserValid.college_prediction_package_acess == true) {
            this.restrictedAcccesOnloaDdata()
        } else {
            this.getNTACollegesPredictiononload();
        }

        let yearsData = []
        this.props.getNTAPredictorYears.length > 0 && this.props.getNTAPredictorYears.map((item) => {
            if (item != undefined) {
                const obj = {
                    value: item.year,
                    label: item.name
                }
                yearsData.push(obj)
                this.setState({ yearsArray: yearsData })
            }
        })
    }

    restrictedAcccesOnloaDdata = async () => {
        this.setState({
            getNTACollegesPrediction: [],
            showSpinner: "show",
            error_display: ""
        })

        if (this.state.inputRank > 1000000) {
            this.setState({ error_display: "Please Enter Valid Rank", showSpinner: "hide" });
            return;
        }

        const result = await this.props.client.query({
            query: gql`
            query( $exam_id: Int,
                $ year: Int,
                $ college_Ids: String,
                $branch_Ids: String,
                $quota_Ids: String,
                $seat_type_Ids: String,
                $rank: Int,
                $round: String,
                $state_Ids: String,
                $college_Type_Ids: String
                $mobile:String
                $exam_type:Int
                $b_arch_type:Int
                $degree_type:Int
                $gender_type:String){
                    getNTACollegesPrediction(
                        exam_id: $exam_id,
                        year: $year,
                        college_Ids: $college_Ids,
                        branch_Ids: $branch_Ids,
                        quota_Ids: $quota_Ids,
                        seat_type_Ids: $seat_type_Ids,
                        rank: $rank,
                        round: $round,
                        state_Ids: $state_Ids,
                        college_Type_Ids: $college_Type_Ids,
                        mobile:$mobile,
                        exam_type:$exam_type
                        b_arch_type:$b_arch_type,
                        degree_type:$degree_type
                        gender_type:$gender_type

                      ) {
                        id
                        location
                        college_name
                        opening_rank
                        closing_rank
                        gender
                        branch_name
                        quota_name
                        seat_type
                        website
                        college_code
                        nta_college_id
                        nta_branch_id
                        round
                        is_shortlisted
                      }
                }
            `,
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                year: parseInt(this.state.yearValue.value),
                college_Ids: this.state.ecollegeselValue.toString(),
                branch_Ids: "55",
                quota_Ids: this.state.equotaselValue.toString(),
                seat_type_Ids: this.state.eseattypeselValue.toString(),
                rank: parseInt(this.state.inputRank),
                round: this.state.eroundselValue.toString(),
                state_Ids: this.state.estateselValue.toString(),
                college_Type_Ids: "1",
                mobile: Cookies.get("mobile"),
                exam_type: 1,
                b_arch_type: 0,
                degree_type: 1,
                gender_type: this.state.egenderselValue.toString()
            },
            fetchPolicy: 'network-only'
        })

        if (result.data.getNTACollegesPrediction.length > 0) {
            this.setState({
                getNTACollegesPrediction: result.data.getNTACollegesPrediction,
                showSpinner: "hide",

            })
        } else {
            this.setState({
                getNTACollegesPrediction: [],
                showSpinner: "hide"
            })
        }
    }

    getNTACollegesPredictiononload = async () => {
        this.setState({
            getNTACollegesPrediction: [],
            showSpinner: "show",
            error_display: ""
        })

        if (this.state.inputRank > 1000000) {
            this.setState({ error_display: "Please Enter Valid Rank", showSpinner: "hide" });
            return;
        }

        const result = await this.props.client.query({
            query: gql`
            query( $exam_id: Int,
                $ year: Int,
                $ college_Ids: String,
                $branch_Ids: String,
                $quota_Ids: String,
                $seat_type_Ids: String,
                $rank: Int,
                $round: String,
                $state_Ids: String,
                $college_Type_Ids: String
                $mobile:String
                $exam_type:Int
                $b_arch_type:Int
                $degree_type:Int
                $gender_type:String){
                    getNTACollegesPrediction(
                        exam_id: $exam_id,
                        year: $year,
                        college_Ids: $college_Ids,
                        branch_Ids: $branch_Ids,
                        quota_Ids: $quota_Ids,
                        seat_type_Ids: $seat_type_Ids,
                        rank: $rank,
                        round: $round,
                        state_Ids: $state_Ids,
                        college_Type_Ids: $college_Type_Ids,
                        mobile:$mobile,
                        exam_type:$exam_type
                        b_arch_type:$b_arch_type,
                        degree_type:$degree_type
                        gender_type:$gender_type

                      ) {
                        id
                        location
                        college_name
                        opening_rank
                        closing_rank
                        gender
                        branch_name
                        quota_name
                        seat_type
                        website
                        college_code
                        nta_college_id
                        nta_branch_id
                        round
                        is_shortlisted
                      }
                }
            `,
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                year: parseInt(this.state.yearValue.value),
                college_Ids: this.state.ecollegeselValue.toString(),
                branch_Ids: this.state.ebranchselValue.toString(),
                quota_Ids: this.state.equotaselValue.toString(),
                seat_type_Ids: this.state.eseattypeselValue.toString(),
                rank: parseInt(this.state.inputRank),
                round: this.state.eroundselValue.toString(),
                state_Ids: this.state.estateselValue.toString(),
                college_Type_Ids: this.state.ecollegetypeselValue.toString(),
                mobile: Cookies.get("mobile"),
                exam_type: 1,
                b_arch_type: 0,
                degree_type: 1,
                gender_type: this.state.egenderselValue.toString()
            },
            fetchPolicy: 'network-only'
        })

        if (result.data.getNTACollegesPrediction.length > 0) {
            this.setState({
                getNTACollegesPrediction: result.data.getNTACollegesPrediction,
                showSpinner: "hide",

            })
        } else {
            this.setState({
                getNTACollegesPrediction: [],
                showSpinner: "hide"
            })
        }
    }

    getNTACollegesPredictiononloadOnsubmit = async () => {
        this.setState({
            getNTACollegesPrediction: [],
            showSpinner: "show",
            error_display: ""
        })

        if (this.state.inputRank > 1000000) {
            this.setState({ error_display: "Please Enter Valid Rank", showSpinner: "hide" });
            return;
        }

        const result = await this.props.client.query({
            query: gql`
            query( $exam_id: Int,
                $ year: Int,
                $ college_Ids: String,
                $branch_Ids: String,
                $quota_Ids: String,
                $seat_type_Ids: String,
                $rank: Int,
                $round: String,
                $state_Ids: String,
                $college_Type_Ids: String
                $mobile:String
                $exam_type:Int
                $b_arch_type:Int
                $degree_type:Int
                $gender_type:String){
                    getNTACollegesPrediction(
                        exam_id: $exam_id,
                        year: $year,
                        college_Ids: $college_Ids,
                        branch_Ids: $branch_Ids,
                        quota_Ids: $quota_Ids,
                        seat_type_Ids: $seat_type_Ids,
                        rank: $rank,
                        round: $round,
                        state_Ids: $state_Ids,
                        college_Type_Ids: $college_Type_Ids,
                        mobile:$mobile,
                        exam_type:$exam_type
                        b_arch_type:$b_arch_type,
                        degree_type:$degree_type,
                        gender_type:$gender_type

                      ) {
                        id
                        location
                        college_name
                        opening_rank
                        closing_rank
                        gender
                        branch_name
                        quota_name
                        seat_type
                        website
                        college_code
                        nta_college_id
                        nta_branch_id
                        round
                        is_shortlisted
                      }
                }
            `,
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                year: parseInt(this.state.yearValue.value),
                college_Ids: this.state.ecollegeselValue.toString(),
                branch_Ids: this.state.ebranchselValue.toString(),
                quota_Ids: this.state.equotaselValue.toString(),
                seat_type_Ids: this.state.eseattypeselValue.toString(),
                rank: parseInt(this.state.inputRank),
                round: this.state.eroundselValue.toString(),
                state_Ids: this.state.estateselValue.toString(),
                college_Type_Ids: this.state.ecollegetypeselValue.toString(),
                mobile: Cookies.get("mobile"),
                exam_type: this.state.input_exam_type_value,
                b_arch_type: this.state.b_arch_type_value,
                degree_type: this.state.degreeTypeValue,
                gender_type: this.state.egenderselValue.toString(),
            },

            fetchPolicy: 'network-only'
        })

        if (result.data.getNTACollegesPrediction.length > 0) {
            this.setState({
                getNTACollegesPrediction: result.data.getNTACollegesPrediction,
                showSpinner: "hide",

            })
        } else {
            this.setState({
                getNTACollegesPrediction: [],
                showSpinner: "hide"
            })
        }
    }

    resultData(data) {
        const tableData = Array();
        for (let i = 0; i < data.length; i++) {
            tableData.push({
                index: i + 1,
                location: data[i].location,
                college_name: data[i].college_name,
                opening_rank: data[i].opening_rank,
                closing_rank: data[i].closing_rank,
                gender: data[i].gender,
                branch_name: data[i].branch_name,
                quota_name: data[i].quota_name,
                seat_type: data[i].seat_type,
                round: data[i].round,
                website: data[i].website,
                college_code: data[i].college_code,
                is_shortlisted: data[i].is_shortlisted,
                nta_branch_id: data[i].nta_branch_id,
                nta_college_id: data[i].nta_college_id
            })
            tableData.sort(function (a, b) {
                return parseFloat(a.opening_rank) - parseFloat(b.opening_rank);
            })
        }
        return tableData
    }

    selecthandleInputChange = async (ename, evalue) => {
        const name = ename
        const value = evalue

        if (name == "year") {
            let yearsData = this.state.yearsArray.find((a) => a.value == value)
            this.setState({
                yearValue: {
                    value: value,
                    label: yearsData.label,
                },
                equotaselData: [],
                equotaselValue: [],
                eseattypeselData: [],
                eseattypeselValue: [],
                estateselData: [],
                estateselValue: [],
                ecollegetypeselData: [],
                ecollegeselValue: [],
                ecollegeselData: [],
                eroundselData: [{ value: 6, label: 6 }],
                eroundselValue: [6],
                inputRank: 0,
                branchFilterData: [],
                quotaFilterData: [],
                seatTypeFilterData: [],
                stateFilterData: [],
                collegeFilterData: [],
                collegetypeFilterData: [],
            })

            const result3 = await this.props.client.query({
                query: gql`
                query($year:Int,$exam_id:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String){
                    getStates(year:$year,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids){
                        state_id
                        state_name
                      }
                }
                `,
                variables: {
                    year: parseInt(value),
                    exam_id: parseInt(Cookies.get("examid")),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                },
                fetchPolicy: "network-only"
            })

            if (result3.data.getStates.length > 0) {
                this.setState({
                    stateFilterData: result3.data.getStates
                })
            } else {
                this.setState({ stateFilterData: [] })
            }
        }
    }

    handleSelectChangeDegree = async (e) => {
        const value = e.target.value;

        if (value == "single_degree") {
            const delay = (ms) => new Promise((r) => setTimeout(r, ms));
            await delay(1000);

            this.setState({
                getNTACollegesPrediction: [],
                showSpinner: "show",
                ebranchselData: [],
                ebranchselValue: [],
                ecollegeselData: [],
                ecollegeselValue: [],
                estateselData: [],
                estateselValue: [],

                fourdegreeCheckBox: true,
                dualdegreecheck: false,
                allDegreecheckbox: false,
                degreeTypeValue: 1,

            })

            const result9 = await this.props.client.query({
                query: gql`
                query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        college_name
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    state_id: this.state.estateselValue.toString(),
                    college_type: this.state.ecollegetypeselValue.toString(),
                    branch_id: this.state.ebranchselValue.toString(),
                    year: parseInt(this.state.yearValue.value),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    degree_type: 1,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")

                },
                fetchPolicy: 'network-only'
            })

            if (result9.data.getNTAPredictorColleges.length > 0) {
                this.setState({
                    collegeFilterData: result9.data.getNTAPredictorColleges
                })
            } else {
                this.setState({
                    collegeFilterData: []
                })
            }


            const result3 = await this.props.client.query({
                query: gql`
                query($year:Int,$exam_id:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String){
                    getStates(year:$year,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids){
                        state_id
                        state_name
                      }
                }
                `,
                variables: {
                    year: parseInt(this.state.yearValue.value),
                    exam_id: parseInt(Cookies.get("examid")),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    degree_type: 1,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                },
                fetchPolicy: "network-only"
            })

            if (result3.data.getStates.length > 0) {
                this.setState({
                    stateFilterData: result3.data.getStates
                })
            } else {
                this.setState({ stateFilterData: [] })
            }

            const result = await this.props.client.query({
                query: gql`
                query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        branch_name
                        branch_logo
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    college_type: this.state.ecollegetypeselValue.toString(),
                    state_id: this.state.estateselValue.toString(),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    year: parseInt(this.state.yearValue.value),
                    degree_type: 1,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")


                },
                fetchPolicy: "network-only"
            })

            if (result.data.getNTAPredictorBranches.length > 0) {
                this.setState({
                    branchFilterData: result.data.getNTAPredictorBranches
                })
            } else {
                this.setState({
                    branchFilterData: []
                })
            }

            const result11 = await this.props.client.query({
                query: gql`
                query( $exam_id: Int,
                    $ year: Int,
                    $ college_Ids: String,
                    $branch_Ids: String,
                    $quota_Ids: String,
                    $seat_type_Ids: String,
                    $rank: Int,
                    $round: String,
                    $state_Ids: String,
                    $college_Type_Ids: String
                    $mobile:String
                    $exam_type:Int
                    $b_arch_type:Int
                    $degree_type:Int
                    $gender_type:String){
                        getNTACollegesPrediction(
                            exam_id: $exam_id,
                            year: $year,
                            college_Ids: $college_Ids,
                            branch_Ids: $branch_Ids,
                            quota_Ids: $quota_Ids,
                            seat_type_Ids: $seat_type_Ids,
                            rank: $rank,
                            round: $round,
                            state_Ids: $state_Ids,
                            college_Type_Ids: $college_Type_Ids,
                            mobile:$mobile,
                            exam_type:$exam_type
                            b_arch_type:$b_arch_type,
                            degree_type:$degree_type,
                            gender_type:$gender_type

                          ) {
                            id
                            location
                            college_name
                            opening_rank
                            closing_rank
                            gender
                            branch_name
                            quota_name
                            seat_type
                            website
                            college_code
                            nta_college_id
                            nta_branch_id
                            round
                            is_shortlisted
                          }
                    }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    year: parseInt(this.state.yearValue.value),
                    college_Ids: this.state.ecollegeselValue.toString(),
                    branch_Ids: this.state.ebranchselValue.toString(),
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    rank: parseInt(this.state.inputRank),
                    round: this.state.eroundselValue.toString(),
                    state_Ids: this.state.estateselValue.toString(),
                    college_Type_Ids: this.state.ecollegetypeselValue.toString(),
                    mobile: Cookies.get("mobile"),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    degree_type: 1,
                    gender_type: this.state.egenderselValue.toString()
                },

                fetchPolicy: 'network-only'
            })


            if (result11.data.getNTACollegesPrediction.length > 0) {
                this.setState({
                    getNTACollegesPrediction: result11.data.getNTACollegesPrediction,
                    showSpinner: "hide",

                })
            } else {
                this.setState({
                    getNTACollegesPrediction: [],
                    showSpinner: "hide"
                })
            }
        }

        else if (value == "dual_degree") {

            const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

            if (isuserValid.college_prediction_package_acess == true) {
                this.setState({ userRestionModalShow: true })
            } else {
                const delay = (ms) => new Promise((r) => setTimeout(r, ms));
                await delay(1000);

                this.setState({
                    getNTACollegesPrediction: [],
                    showSpinner: "show",
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                    ecollegeselValue: [],
                    estateselData: [],
                    estateselValue: [],

                    dualdegreecheck: true,
                    fourdegreeCheckBox: false,
                    allDegreecheckbox: false,
                    degreeTypeValue: 2,

                })

                const result3 = await this.props.client.query({
                    query: gql`
                    query($year:Int,$exam_id:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String){
                        getStates(year:$year,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids){
                            state_id
                            state_name
                          }
                    }
                    `,
                    variables: {
                        year: parseInt(this.state.yearValue.value),
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: this.state.input_exam_type_value,
                        b_arch_type: this.state.b_arch_type_value,
                        degree_type: 2,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                    },
                    fetchPolicy: "network-only"
                })

                if (result3.data.getStates.length > 0) {
                    this.setState({
                        stateFilterData: result3.data.getStates
                    })
                } else {
                    this.setState({ stateFilterData: [] })
                }

                const result = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                        getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                            id
                            branch_name
                            branch_logo
                            enabled
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        college_type: this.state.ecollegetypeselValue.toString(),
                        state_id: this.state.estateselValue.toString(),
                        exam_type: this.state.input_exam_type_value,
                        b_arch_type: this.state.b_arch_type_value,
                        year: parseInt(this.state.yearValue.value),
                        degree_type: 2,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        mobile: Cookies.get("mobile")

                    },
                    fetchPolicy: "network-only"
                })

                if (result.data.getNTAPredictorBranches.length > 0) {
                    this.setState({
                        branchFilterData: result.data.getNTAPredictorBranches
                    })
                } else {
                    this.setState({
                        branchFilterData: []
                    })
                }

                const result9 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                        getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                            id
                            college_name
                            enabled
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        state_id: this.state.estateselValue.toString(),
                        college_type: this.state.ecollegetypeselValue.toString(),
                        branch_id: this.state.ebranchselValue.toString(),
                        year: parseInt(this.state.yearValue.value),
                        exam_type: this.state.input_exam_type_value,
                        b_arch_type: this.state.b_arch_type_value,
                        degree_type: 2,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        mobile: Cookies.get("mobile")

                    },
                    fetchPolicy: 'network-only'
                })

                if (result9.data.getNTAPredictorColleges.length > 0) {
                    this.setState({
                        collegeFilterData: result9.data.getNTAPredictorColleges
                    })
                } else {
                    this.setState({
                        collegeFilterData: []
                    })
                }

                const result11 = await this.props.client.query({
                    query: gql`
                    query( $exam_id: Int,
                        $ year: Int,
                        $ college_Ids: String,
                        $branch_Ids: String,
                        $quota_Ids: String,
                        $seat_type_Ids: String,
                        $rank: Int,
                        $round: String,
                        $state_Ids: String,
                        $college_Type_Ids: String
                        $mobile:String
                        $exam_type:Int
                        $b_arch_type:Int
                        $degree_type:Int
                        $gender_type:String){
                            getNTACollegesPrediction(
                                exam_id: $exam_id,
                                year: $year,
                                college_Ids: $college_Ids,
                                branch_Ids: $branch_Ids,
                                quota_Ids: $quota_Ids,
                                seat_type_Ids: $seat_type_Ids,
                                rank: $rank,
                                round: $round,
                                state_Ids: $state_Ids,
                                college_Type_Ids: $college_Type_Ids,
                                mobile:$mobile,
                                exam_type:$exam_type
                                b_arch_type:$b_arch_type,
                                degree_type:$degree_type,
                                gender_type:$gender_type
    
                              ) {
                                id
                                location
                                college_name
                                opening_rank
                                closing_rank
                                gender
                                branch_name
                                quota_name
                                seat_type
                                website
                                college_code
                                nta_college_id
                                nta_branch_id
                                round
                                is_shortlisted
                              }
                        }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        year: parseInt(this.state.yearValue.value),
                        college_Ids: this.state.ecollegeselValue.toString(),
                        branch_Ids: this.state.ebranchselValue.toString(),
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        rank: parseInt(this.state.inputRank),
                        round: this.state.eroundselValue.toString(),
                        state_Ids: this.state.estateselValue.toString(),
                        college_Type_Ids: this.state.ecollegetypeselValue.toString(),
                        mobile: Cookies.get("mobile"),
                        exam_type: this.state.input_exam_type_value,
                        b_arch_type: this.state.b_arch_type_value,
                        degree_type: 2,
                        gender_type: this.state.egenderselValue.toString()


                    },
                    fetchPolicy: 'network-only'
                })

                if (result11.data.getNTACollegesPrediction.length > 0) {
                    this.setState({
                        getNTACollegesPrediction: result11.data.getNTACollegesPrediction,
                        showSpinner: "hide",

                    })
                } else {
                    this.setState({
                        getNTACollegesPrediction: [],
                        showSpinner: "hide"
                    })
                }
            }



        }

        else if (value == "all") {

            const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

            if (isuserValid.college_prediction_package_acess == true) {
                this.setState({ userRestionModalShow: true })
            } else {
                const delay = (ms) => new Promise((r) => setTimeout(r, ms));
                await delay(1000);

                this.setState({
                    getNTACollegesPrediction: [],
                    showSpinner: "show",
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                    ecollegeselValue: [],
                    estateselData: [],
                    estateselValue: [],

                    degreeTypeValue: 0,
                    allDegreecheckbox: true,
                    dualdegreecheck: false,
                    fourdegreeCheckBox: false
                })


                const result3 = await this.props.client.query({
                    query: gql`
                    query($year:Int,$exam_id:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String){
                        getStates(year:$year,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids){
                            state_id
                            state_name
                          }
                    }
                    `,
                    variables: {
                        year: parseInt(this.state.yearValue.value),
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: this.state.input_exam_type_value,
                        b_arch_type: this.state.b_arch_type_value,
                        degree_type: 0,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                    },
                    fetchPolicy: "network-only"
                })

                if (result3.data.getStates.length > 0) {
                    this.setState({
                        stateFilterData: result3.data.getStates
                    })
                } else {
                    this.setState({ stateFilterData: [] })
                }


                const result = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                        getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                            id
                            branch_name
                            branch_logo
                            enabled
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        college_type: this.state.ecollegetypeselValue.toString(),
                        state_id: this.state.estateselValue.toString(),
                        exam_type: this.state.input_exam_type_value,
                        b_arch_type: this.state.b_arch_type_value,
                        year: parseInt(this.state.yearValue.value),
                        degree_type: 0,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        mobile: Cookies.get("mobile")

                    },
                    fetchPolicy: "network-only"
                })

                if (result.data.getNTAPredictorBranches.length > 0) {
                    this.setState({
                        branchFilterData: result.data.getNTAPredictorBranches
                    })
                } else {
                    this.setState({
                        branchFilterData: []
                    })
                }

                const result9 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                        getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                            id
                            college_name
                            enabled
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        state_id: this.state.estateselValue.toString(),
                        college_type: this.state.ecollegetypeselValue.toString(),
                        branch_id: this.state.ebranchselValue.toString(),
                        year: parseInt(this.state.yearValue.value),
                        exam_type: this.state.input_exam_type_value,
                        b_arch_type: this.state.b_arch_type_value,
                        degree_type: 0,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        mobile: Cookies.get("mobile")

                    },
                    fetchPolicy: 'network-only'
                })

                if (result9.data.getNTAPredictorColleges.length > 0) {
                    this.setState({
                        collegeFilterData: result9.data.getNTAPredictorColleges
                    })
                } else {
                    this.setState({
                        collegeFilterData: []
                    })
                }

                const result11 = await this.props.client.query({
                    query: gql`
                    query( $exam_id: Int,
                        $ year: Int,
                        $ college_Ids: String,
                        $branch_Ids: String,
                        $quota_Ids: String,
                        $seat_type_Ids: String,
                        $rank: Int,
                        $round: String,
                        $state_Ids: String,
                        $college_Type_Ids: String
                        $mobile:String
                        $exam_type:Int
                        $b_arch_type:Int
                        $degree_type:Int
                        $gender_type:String){
                            getNTACollegesPrediction(
                                exam_id: $exam_id,
                                year: $year,
                                college_Ids: $college_Ids,
                                branch_Ids: $branch_Ids,
                                quota_Ids: $quota_Ids,
                                seat_type_Ids: $seat_type_Ids,
                                rank: $rank,
                                round: $round,
                                state_Ids: $state_Ids,
                                college_Type_Ids: $college_Type_Ids,
                                mobile:$mobile,
                                exam_type:$exam_type
                                b_arch_type:$b_arch_type,
                                degree_type:$degree_type,
                                gender_type:$gender_type
    
                              ) {
                                id
                                location
                                college_name
                                opening_rank
                                closing_rank
                                gender
                                branch_name
                                quota_name
                                seat_type
                                website
                                college_code
                                nta_college_id
                                nta_branch_id
                                round
                                is_shortlisted
                              }
                        }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        year: parseInt(this.state.yearValue.value),
                        college_Ids: this.state.ecollegeselValue.toString(),
                        branch_Ids: this.state.ebranchselValue.toString(),
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        rank: parseInt(this.state.inputRank),
                        round: this.state.eroundselValue.toString(),
                        state_Ids: this.state.estateselValue.toString(),
                        college_Type_Ids: this.state.ecollegetypeselValue.toString(),
                        mobile: Cookies.get("mobile"),
                        exam_type: this.state.input_exam_type_value,
                        b_arch_type: this.state.b_arch_type_value,
                        degree_type: 0,
                        gender_type: this.state.egenderselValue.toString()
                    },
                    fetchPolicy: 'network-only'
                })

                if (result11.data.getNTACollegesPrediction.length > 0) {
                    this.setState({
                        getNTACollegesPrediction: result11.data.getNTACollegesPrediction,
                        showSpinner: "hide",

                    })
                } else {
                    this.setState({
                        getNTACollegesPrediction: [],
                        showSpinner: "hide"
                    })
                }

            }



        }
    }

    handleRank = (e) => {
        const value = e.target.value
        this.setState({ inputRank: value })
    }

    collegeDataFunc(cell, row, rowIndex, formatExtraData) {
        return (
            <>
                <a href={row.website} target="_blank">
                    <p style={{ color: "black", textDecoration: "underline" }} className="under_dec">{row["website"]}</p>
                </a>
            </>
        )
    }

    handleMultipleSelectInputChange = async (e, name) => {

        if (name == "quota") {
            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0 && this.state.input_exam_type_value != 1) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }
                this.setState({
                    equotaselData: etesttypevalue,
                    equotaselValue: etesttype,
                    eseattypeselData: [],
                    eseattypeselValue: [],
                    estateselData: [],
                    estateselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                })
            }
            else if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }
                this.setState({
                    equotaselData: etesttypevalue,
                    equotaselValue: etesttype,
                    eseattypeselData: [],
                    eseattypeselValue: [],
                    estateselData: [],
                    estateselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                    ecollegetypeselData: [],
                    ecollegetypeselValue: [],
                })
            }

            else {
                this.setState({
                    equotaselData: [],
                    equotaselValue: [],
                    eseattypeselData: [],
                    eseattypeselValue: [],
                    estateselData: [],
                    estateselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                })
            }

            let quota_ids_array = etesttypevalue.map(item => item.value);
            quota_ids_array.join(", ");


            const result6 = await this.props.client.query({
                query: gql`
                query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int,$quota_Ids:String){
                    getNTAPredictorSeatTypes(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,quota_Ids:$quota_Ids) {
                        id
                        seat_type
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    year: parseInt(this.state.yearValue.value),
                    quota_Ids: quota_ids_array.toString()

                },
                fetchPolicy: "network-only"
            })

            if (result6.data.getNTAPredictorSeatTypes.length > 0) {
                this.setState({
                    seatTypeFilterData: result6.data.getNTAPredictorSeatTypes
                })
            } else {
                this.setState({
                    seatTypeFilterData: []
                })
            }

            const result3 = await this.props.client.query({
                query: gql`
                query($year:Int,$exam_id:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String){
                    getStates(year:$year,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids){
                        state_id
                        state_name
                      }
                }
                `,
                variables: {
                    year: parseInt(this.state.yearValue.value),
                    exam_id: parseInt(Cookies.get("examid")),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: quota_ids_array.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),

                },
                fetchPolicy: "network-only"
            })


            if (result3.data.getStates.length > 0) {
                this.setState({
                    stateFilterData: result3.data.getStates
                })
            } else {
                this.setState({ stateFilterData: [] })
            }


            const result = await this.props.client.query({
                query: gql`
                query($exam_id:Int,$state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorCollegeTypes(exam_id:$exam_id,state_id:$state_id,exam_type:$exam_type,b_arch_type:$b_arch_type,year:$year,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        college_type
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    state_id: this.state.estateselValue.toString(),
                    year: parseInt(this.state.yearValue.value),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    quota_Ids: quota_ids_array.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")
                },
                fetchPolicy: 'network-only'
            })

            if (result.data.getNTAPredictorCollegeTypes.length > 0) {
                this.setState({
                    collegetypeFilterData: result.data.getNTAPredictorCollegeTypes
                })
            } else {
                this.setState({
                    collegetypeFilterData: []
                })
            }

            const result9 = await this.props.client.query({
                query: gql`
                query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        college_name
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    state_id: this.state.estateselValue.toString(),
                    college_type: this.state.ecollegetypeselValue.toString(),
                    branch_id: this.state.ebranchselValue.toString(),
                    year: parseInt(this.state.yearValue.value),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: quota_ids_array.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")

                },
                fetchPolicy: 'network-only'

            })

            if (result9.data.getNTAPredictorColleges.length > 0) {
                this.setState({
                    collegeFilterData: result9.data.getNTAPredictorColleges
                })
            } else {
                this.setState({
                    collegeFilterData: []
                })
            }

            const result2 = await this.props.client.query({
                query: gql`
                query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        branch_name
                        branch_logo
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    college_type: this.state.ecollegetypeselValue.toString(),
                    state_id: this.state.estateselValue.toString(),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    year: parseInt(this.state.yearValue.value),
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: quota_ids_array.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")


                },
                fetchPolicy: 'network-only'
            })

            if (result2.data.getNTAPredictorBranches.length > 0) {
                this.setState({
                    branchFilterData: result2.data.getNTAPredictorBranches
                })
            } else {
                this.setState({
                    branchFilterData: []
                })
            }


        }

        else if (name == "seattype") {
            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0 && this.state.input_exam_type_value != 1) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }
                this.setState({
                    eseattypeselData: etesttypevalue,
                    eseattypeselValue: etesttype,
                    estateselData: [],
                    estateselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                    ecollegeselValue: [],
                })
            } else if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }
                this.setState({
                    eseattypeselData: etesttypevalue,
                    eseattypeselValue: etesttype,
                    estateselData: [],
                    estateselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                    ecollegeselValue: [],
                    ecollegetypeselData: [],
                    ecollegetypeselValue: [],

                })
            }

            else {
                this.setState({
                    eseattypeselData: [],
                    eseattypeselValue: [],
                    estateselData: [],
                    estateselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                    ecollegeselValue: [],

                })
            }

            let seattype_ids_array = etesttypevalue.map(item => item.value);
            seattype_ids_array.join(", ");

            const result3 = await this.props.client.query({
                query: gql`
                query($year:Int,$exam_id:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String){
                    getStates(year:$year,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids){
                        state_id
                        state_name
                      }
                }
                `,
                variables: {
                    year: parseInt(this.state.yearValue.value),
                    exam_id: parseInt(Cookies.get("examid")),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: seattype_ids_array.toString(),

                },
                fetchPolicy: "network-only"
            })


            if (result3.data.getStates.length > 0) {
                this.setState({
                    stateFilterData: result3.data.getStates
                })
            } else {
                this.setState({ stateFilterData: [] })
            }

            const result = await this.props.client.query({
                query: gql`
                query($exam_id:Int,$state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorCollegeTypes(exam_id:$exam_id,state_id:$state_id,exam_type:$exam_type,b_arch_type:$b_arch_type,year:$year,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        college_type
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    state_id: this.state.estateselValue.toString(),
                    year: parseInt(this.state.yearValue.value),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: seattype_ids_array.toString(),
                    mobile: Cookies.get("mobile")

                },
                fetchPolicy: 'network-only'
            })

            if (result.data.getNTAPredictorCollegeTypes.length > 0) {
                this.setState({
                    collegetypeFilterData: result.data.getNTAPredictorCollegeTypes
                })
            } else {
                this.setState({
                    collegetypeFilterData: []
                })
            }

            const result9 = await this.props.client.query({
                query: gql`
                query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        college_name
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    state_id: this.state.estateselValue.toString(),
                    college_type: this.state.ecollegetypeselValue.toString(),
                    branch_id: this.state.ebranchselValue.toString(),
                    year: parseInt(this.state.yearValue.value),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: seattype_ids_array.toString(),
                    mobile: Cookies.get("mobile")

                },
                fetchPolicy: 'network-only'

            })

            if (result9.data.getNTAPredictorColleges.length > 0) {
                this.setState({
                    collegeFilterData: result9.data.getNTAPredictorColleges
                })
            } else {
                this.setState({
                    collegeFilterData: []
                })
            }

            const result2 = await this.props.client.query({
                query: gql`
                query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        branch_name
                        branch_logo
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    college_type: this.state.ecollegetypeselValue.toString(),
                    state_id: this.state.estateselValue.toString(),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    year: parseInt(this.state.yearValue.value),
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: seattype_ids_array.toString(),
                    mobile: Cookies.get("mobile")


                },
                fetchPolicy: 'network-only'
            })

            if (result2.data.getNTAPredictorBranches.length > 0) {
                this.setState({
                    branchFilterData: result2.data.getNTAPredictorBranches
                })
            } else {
                this.setState({
                    branchFilterData: []
                })
            }
        }

        else if (name == "state") {

            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0 && this.state.input_exam_type_value != 1) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }

                this.setState({
                    estateselData: etesttypevalue,
                    estateselValue: etesttype,
                    ecollegeselData: [],
                    ecollegeselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                })

            } else if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }

                this.setState({
                    estateselData: etesttypevalue,
                    estateselValue: etesttype,
                    ecollegeselData: [],
                    ecollegeselValue: [],
                    ecollegetypeselData: [],
                    ecollegetypeselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                })
            }

            else {
                this.setState({
                    estateselData: [],
                    estateselValue: [],
                    ecollegeselData: [],
                    ecollegeselValue: [],
                    // ecollegetypeselData: [],
                    // ecollegetypeselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    // collegeTypeOnChangeData:"",
                })
            }

            let state_ids_array = etesttypevalue.map(item => item.value);
            state_ids_array.join(", ");

            this.setState({ stateOnChangeData: state_ids_array.toString() })

            const result3 = await this.props.client.query({
                query: gql`
                query($exam_id:Int,$state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorCollegeTypes(exam_id:$exam_id,state_id:$state_id,exam_type:$exam_type,b_arch_type:$b_arch_type,year:$year,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        college_type
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    state_id: state_ids_array.toString(),
                    year: parseInt(this.state.yearValue.value),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")

                },
                fetchPolicy: 'network-only'
            })

            if (result3.data.getNTAPredictorCollegeTypes.length > 0) {
                this.setState({
                    collegetypeFilterData: result3.data.getNTAPredictorCollegeTypes
                })
            } else {
                this.setState({
                    collegetypeFilterData: []
                })
            }

            const result2 = await this.props.client.query({
                query: gql`
                query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        branch_name
                        branch_logo
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    college_type: this.state.ecollegetypeselValue.toString(),
                    state_id: this.state.estateselValue.toString(),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    year: parseInt(this.state.yearValue.value),
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")

                },
                fetchPolicy: 'network-only'
            })

            if (result2.data.getNTAPredictorBranches.length > 0) {
                this.setState({
                    branchFilterData: result2.data.getNTAPredictorBranches
                })
            } else {
                this.setState({
                    branchFilterData: []
                })
            }

            const result = await this.props.client.query({
                query: gql`
                query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        college_name
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    state_id: state_ids_array.toString(),
                    college_type: this.state.ecollegetypeselValue.toString(),
                    branch_id: this.state.ebranchselValue.toString(),
                    year: parseInt(this.state.yearValue.value),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")

                },
                fetchPolicy: 'network-only'
            })

            if (result.data.getNTAPredictorColleges.length > 0) {
                this.setState({
                    collegeFilterData: result.data.getNTAPredictorColleges
                })
            } else {
                this.setState({
                    collegeFilterData: []
                })
            }
        }

        else if (name == "collegetype") {

            // const delay = (ms) => new Promise((r) => setTimeout(r, ms));
            // await delay(1000);

            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    console.log("eeeeeeeeeeeee", e.length)
                    if (etesttypeval.checked == false) {
                        if (this.props.getNTAPredictorCollegeTypes.length != e.length) {
                            this.setState({
                                userRestionModalShow: true
                            })
                        }

                    } else {
                        const newObj = {
                            label: etesttypeval.label,
                            value: etesttypeval.value
                        }
                        etesttypevalue.push(newObj);
                        etesttype.push(etesttypeval.value);
                    }

                }
                this.setState({
                    ecollegetypeselData: etesttypevalue,
                    ecollegetypeselValue: etesttype,
                    ecollegeselData: [],
                    ecollegeselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    // branchOnchnageData: "",
                })
            } else {
                this.setState({
                    ecollegetypeselData: [],
                    ecollegetypeselValue: [],
                    ecollegeselData: [],
                    ecollegeselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    // branchOnchnageData: "",

                })
            }

            let collegetype_ids_Array = etesttypevalue.map(item => item.value);
            collegetype_ids_Array.join(", ");

            this.setState({
                collegeTypeOnChangeData: collegetype_ids_Array.toString(),
            })


            const result = await this.props.client.query({
                query: gql`
                query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        college_name
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    state_id: this.state.estateselValue.toString(),
                    college_type: collegetype_ids_Array.toString(),
                    branch_id: this.state.ebranchselValue.toString(),
                    year: parseInt(this.state.yearValue.value),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")

                },
                fetchPolicy: 'network-only'
            })

            if (result.data.getNTAPredictorColleges.length > 0) {
                this.setState({
                    collegeFilterData: result.data.getNTAPredictorColleges
                })
            } else {
                this.setState({
                    collegeFilterData: [],
                })
            }


            const result2 = await this.props.client.query({
                query: gql`
                query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        branch_name
                        branch_logo
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    college_type: this.state.ecollegetypeselValue.toString(),
                    state_id: this.state.estateselValue.toString(),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    year: parseInt(this.state.yearValue.value),
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")

                },
                fetchPolicy: 'network-only'
            })

            if (result2.data.getNTAPredictorBranches.length > 0) {
                this.setState({
                    branchFilterData: result2.data.getNTAPredictorBranches
                })
            } else {
                this.setState({
                    branchFilterData: []
                })
            }
        }

        else if (name == "branch") {
            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    if (etesttypeval.checked == false) {
                        if (this.props.getNTAPredictorBranches.length != e.length) {
                            this.setState({ userRestionModalShow: true })

                        }
                    } else {
                        const newObj = {
                            label: etesttypeval.label,
                            value: etesttypeval.value
                        }
                        etesttypevalue.push(newObj);
                        etesttype.push(etesttypeval.value);
                    }
                }
                this.setState({
                    ebranchselData: etesttypevalue,
                    ebranchselValue: etesttype,
                    ecollegeselData: [],
                    ecollegeselValue: [],
                })
            } else {
                this.setState({
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                    ecollegeselValue: [],
                })
            }

            let branch_ids_array = etesttypevalue.map(item => item.value);
            branch_ids_array.join(", ");


            this.setState({
                branchOnchnageData: branch_ids_array.toString(),

            })

            const result = await this.props.client.query({
                query: gql`
                query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        college_name
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    state_id: this.state.estateselValue.toString(),
                    college_type: this.state.ecollegetypeselValue.toString(),
                    branch_id: branch_ids_array.toString(),
                    year: parseInt(this.state.yearValue.value),
                    exam_type: this.state.input_exam_type_value,
                    b_arch_type: this.state.b_arch_type_value,
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")


                },
                fetchPolicy: 'network-only'
            })


            if (result.data.getNTAPredictorColleges.length > 0) {
                this.setState({
                    collegeFilterData: result.data.getNTAPredictorColleges
                })
            } else {
                this.setState({
                    collegeFilterData: [],
                })
            }
        }

        else if (name == "colleges") {
            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    if (etesttypeval.checked == false) {
                        if (this.props.getNTAPredictorColleges.length != e.length) {
                            this.setState({ userRestionModalShow: true })
                        }
                    } else {
                        const newObj = {
                            label: etesttypeval.label,
                            value: etesttypeval.value
                        }
                        etesttypevalue.push(newObj);
                        etesttype.push(etesttypeval.value);
                    }

                }
                this.setState({
                    ecollegeselData: etesttypevalue,
                    ecollegeselValue: etesttype

                })
            } else {
                this.setState({
                    ecollegeselData: [],
                    ecollegeselValue: []
                })
            }
        }

        else if (name == "round") {
            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }
                this.setState({
                    eroundselData: etesttypevalue,
                    eroundselValue: etesttype
                })
            } else {
                this.setState({
                    eroundselData: [],
                    eroundselValue: []
                })
            }
        }

        else if (name == "gender") {
            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }
                this.setState({
                    egenderselData: etesttypevalue,
                    egenderselValue: etesttype
                })
            } else {
                this.setState({
                    egenderselData: [],
                    egenderselValue: []
                })
            }
        }
    }

    sortFunc(a, b, order, dataField, rowA, rowB) {
        if (order === 'asc') return parseInt(a) - parseInt(b);
        else return parseInt(b) - parseInt(a);
    }

    shortListFunc(cell, row, rowIndex, formatExtraData) {
        return (
            <>
                {
                    row.is_shortlisted == true ?
                        (
                            <button className='shortListed_btn_nta mt-3' onClick={() => this.removeCollegeShortlist(row)}> <i class="far fa-times cross "></i>  Remove from Shortlist</button>)
                        :
                        (
                            <button className='short_list_btn_nta mt-3' onClick={() => this.addCollegeShortlist(row)}> <i class="fal fa-plus"></i> Add to Shortlist</button>
                        )
                }
            </>
        )
    }

    addCollegeShortlist = (row) => {
        this.setState({ spinnerShortlist: "show" })

        let addCollege = ""
        addCollege = {
            mobile: Cookies.get("mobile"),
            nta_college_id: row.nta_college_id,
            nta_branch_id: row.nta_branch_id,
            comments: "",
            exam_id: parseInt(Cookies.get("examid")),
            exam_type: this.state.input_exam_type_value,
            b_arch_type: this.state.b_arch_type_value
        }

        this.submitCollege(addCollege, row).catch(error => {
            console.log("catch if error");
            console.log(error);
        })
    }

    SetpageLoad = () => {
        this.setState({ shortListStatus: 0, spinnerShortlist: "hide" });
    };

    submitCollege = async (params, row) => {
        await this.props.submitCollege({
            variables: {
                params
            },
            update: (store, { data }) => {
                const getData = this.state.getNTACollegesPrediction.map((item) => {
                    if (item.nta_college_id == row.nta_college_id && item.nta_branch_id == row.nta_branch_id) {
                        return { ...item, is_shortlisted: true }
                    }
                    return { ...item }
                })

                this.state.getNTACollegesPrediction = getData;

                if (data.addNTAShortListedCollege) {
                    this.setState({ shortListStatus: 1 })
                    setTimeout(() => {
                        this.SetpageLoad();
                    }, 1000);
                }
            }
        });
    }

    removeCollegeShortlist = (row) => {
        let removeCollege = ""
        removeCollege = {
            mobile: Cookies.get("mobile"),
            eamcet_college_id: row.nta_college_id,
            eamcet_branch_id: row.nta_branch_id,
            exam_type: this.state.input_exam_type_value,
            b_arch_type: this.state.b_arch_type_value

        }
        this.removeCollegeData(removeCollege, row).catch(error => {
            console.log("catch if error");
            console.log(error);
        })
    }

    removeCollegeData = async (params, row) => {
        await this.props.removeCollegeData({
            variables: {
                params
            },
            update: (store, { data }) => {
                const getData = this.state.getNTACollegesPrediction.map((item) => {
                    if (item.nta_college_id == row.nta_college_id && item.nta_branch_id == row.nta_branch_id) {
                        return { ...item, is_shortlisted: false }
                    }
                    return { ...item }
                })

                this.state.getNTACollegesPrediction = getData;

                if (data.deleteCollegePredictor) {
                    this.setState({ shortListStatus: 2 })
                    setTimeout(() => {
                        this.SetpageLoad();
                    }, 1000);
                }
            }
        })
    }

    clearAll = () => {
        this.setState({ spinnerClear: '1' })
        let removeAllData = ""
        removeAllData = {
            mobile: Cookies.get("mobile"),
            exam_id: parseInt(Cookies.get("examid")),
            exam_type: this.state.input_exam_type_value,
            b_arch_type: this.state.b_arch_type_value
        }
        this.removeAllData(removeAllData).catch(error => {
            console.log("catch if error");
            console.log(error);
        })

    }

    removeAllData = async (params, row) => {

        const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

        await this.props.removeAllData({
            variables: {
                params
            },
            update: (store, { data }) => {
                const getData = this.state.getNTACollegesPrediction.map((item) => {

                    return { ...item, is_shortlisted: false }
                })

                this.state.getNTACollegesPrediction = getData;

                if (data.removeAllShortlistedColleges == true) {
                    this.setState({ spinnerClear: '0', clearallModal: false })

                    if (isuserValid.college_prediction_package_acess == false) {
                        this.getNTACollegesPredictiononloadOnsubmit()
                    } else {
                        this.restrictedAcccesOnloaDdata()
                    }
                }
            }
        })

    }

    colFunction() {

        let columns = [
            {
                dataField: "college_name",
                text: "ShortList",
                formatter: this.shortListFunc.bind(this),
                headerStyle: {
                    width: "10%", background: "#E9ECF1", color: "#0B3573"
                },
            },
            // {
            //     dataField: "location",
            //     text: "Location",
            // },
            {
                dataField: "college_name",
                text: "College Name",
                sort: true,
                headerStyle: {
                    width: "25%", background: "#E9ECF1", color: "#0B3573"
                },

            },
            {
                dataField: "opening_rank",
                text: "Opening Rank",
                sort: true,
                sortFunc: this.sortFunc.bind(this),
                headerStyle: {
                    width: "5%", background: "#E9ECF1", color: "#0B3573"
                },
            },


            {
                dataField: "closing_rank",
                text: "Closing Rank",
                sort: true,
                sortFunc: this.sortFunc.bind(this),
                headerStyle: {
                    width: "5%", background: "#E9ECF1", color: "#0B3573"
                },
            },
            {
                dataField: "gender",
                text: "Gender",
                headerStyle: {
                    width: "5%", background: "#E9ECF1", color: "#0B3573"
                },

            },
            {
                dataField: "branch_name",
                text: "Branch Name",
                headerStyle: {
                    width: "20%", background: "#E9ECF1", color: "#0B3573"
                },

            },
            {
                dataField: "quota_name",
                text: "Quota Name",
                headerStyle: {
                    width: "5%", background: "#E9ECF1", color: "#0B3573"
                },

            },
            {
                dataField: "seat_type",
                text: "Seat Type",
                headerStyle: {
                    width: "5%", background: "#E9ECF1", color: "#0B3573"
                },
            },
            {
                dataField: "round",
                text: "Round",
                sort: true,
                headerStyle: {
                    width: "3%", background: "#E9ECF1", color: "#0B3573"
                },

            },
            {
                dataField: "website",
                text: "Website",
                formatter: this.collegeDataFunc.bind(this),
                headerStyle: {
                    width: "15%", background: "#E9ECF1", color: "#0B3573"
                },
            },
            // {
            //     dataField: "college_code",
            //     text: "College Code",
            //     sort: true
            // },
        ];
        return columns;
    }

    handleSelectChange = async (e) => {
        const value = e.target.value;
        if (value == "mains") {

            const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

            const delay = (ms) => new Promise((r) => setTimeout(r, ms));
            await delay(1000);

            this.setState({
                mainCheckBox: true,
                advanceCheckBox: false,
                bArchCheckbox: false,
                showSpinner: "show",
                getNTACollegesPrediction: [],
                enablearchSubtypes: false,
                input_exam_type: value,
                input_exam_type_value: 1,
                ecollegetypeselValue: [],
                ecollegetypeselData: [],
                equotaselData: [],
                equotaselValue: [],
                eseattypeselData: [],
                eseattypeselValue: [],
                estateselData: [],
                estateselValue: [],
                ebranchselData: [],
                ebranchselValue: [],
                ecollegeselData: [],
                ecollegeselValue: [],
                eroundselData: [{ value: 6, label: 6 }],
                eroundselValue: [6],
                inputRank: 0,
                b_arch_type_value: 0,

                degreeTypeValue: 1,
                fourdegreeCheckBox: true,
                dualdegreecheck: false,
                allDegreecheckbox: false


            });

            const result6 = await this.props.client.query({
                query: gql`
                query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int,$quota_Ids:String){
                    getNTAPredictorSeatTypes(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,quota_Ids:$quota_Ids) {
                        id
                        seat_type
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    exam_type: 1,
                    b_arch_type: 0,
                    year: parseInt(this.state.yearValue.value),
                    quota_Ids: this.state.equotaselValue.toString()

                },
                fetchPolicy: "network-only"
            })

            if (result6.data.getNTAPredictorSeatTypes.length > 0) {
                this.setState({
                    seatTypeFilterData: result6.data.getNTAPredictorSeatTypes
                })
            } else {
                this.setState({
                    seatTypeFilterData: []
                })
            }

            const result4 = await this.props.client.query({
                query: gql`
                query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int){
                    getNTAPredictorQuota(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type) {
                        id
                        quota_name
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    exam_type: 1,
                    b_arch_type: 0,
                    year: parseInt(this.state.yearValue.value),
                },
                fetchPolicy: 'network-only'
            })

            if (result4.data.getNTAPredictorQuota.length > 0) {
                this.setState({
                    quotaFilterData: result4.data.getNTAPredictorQuota
                })
            } else {
                this.setState({
                    quotaFilterData: []
                })
            }

            const result2 = await this.props.client.query({
                query: gql`
                query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        branch_name
                        branch_logo
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    college_type: this.state.ecollegetypeselValue.toString(),
                    state_id: this.state.estateselValue.toString(),
                    exam_type: 1,
                    b_arch_type: 0,
                    year: parseInt(this.state.yearValue.value),
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")


                },
                fetchPolicy: 'network-only'
            })


            if (result2.data.getNTAPredictorBranches.length > 0) {
                this.setState({
                    branchFilterData: result2.data.getNTAPredictorBranches
                })
            } else {
                this.setState({
                    branchFilterData: []
                })
            }

            const result3 = await this.props.client.query({
                query: gql`
                query($year:Int,$exam_id:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String){
                    getStates(year:$year,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids){
                        state_id
                        state_name
                      }
                }
                `,
                variables: {
                    year: parseInt(this.state.yearValue.value),
                    exam_id: parseInt(Cookies.get("examid")),
                    exam_type: 1,
                    b_arch_type: 0,
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),

                },
                fetchPolicy: "network-only"
            })

            if (result3.data.getStates.length > 0) {
                this.setState({
                    stateFilterData: result3.data.getStates
                })
            } else {
                this.setState({ stateFilterData: [] })
            }

            const result = await this.props.client.query({
                query: gql`
                query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                    getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                        id
                        college_name
                        enabled
                      }
                }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    state_id: this.state.estateselValue.toString(),
                    college_type: this.state.ecollegetypeselValue.toString(),
                    branch_id: this.state.ebranchselValue.toString(),
                    year: parseInt(this.state.yearValue.value),
                    exam_type: 1,
                    b_arch_type: 0,
                    degree_type: this.state.degreeTypeValue,
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    mobile: Cookies.get("mobile")


                },
                fetchPolicy: 'network-only'

            })
            if (result.data.getNTAPredictorColleges.length > 0) {
                this.setState({
                    collegeFilterData: result.data.getNTAPredictorColleges
                })
            } else {
                this.setState({
                    collegeFilterData: []
                })
            }

            const result7 = await this.props.client.query({
                query: gql`
                query( $exam_id: Int,
                    $ year: Int,
                    $ college_Ids: String,
                    $branch_Ids: String,
                    $quota_Ids: String,
                    $seat_type_Ids: String,
                    $rank: Int,
                    $round: String,
                    $state_Ids: String,
                    $college_Type_Ids: String
                    $mobile:String
                    $exam_type:Int
                    $b_arch_type:Int
                    $degree_type:Int
                    $gender_type:String){
                        getNTACollegesPrediction(
                            exam_id: $exam_id,
                            year: $year,
                            college_Ids: $college_Ids,
                            branch_Ids: $branch_Ids,
                            quota_Ids: $quota_Ids,
                            seat_type_Ids: $seat_type_Ids,
                            rank: $rank,
                            round: $round,
                            state_Ids: $state_Ids,
                            college_Type_Ids: $college_Type_Ids,
                            mobile:$mobile,
                            exam_type:$exam_type
                            b_arch_type:$b_arch_type
                            degree_type:$degree_type
                            gender_type:$gender_type

                          ) {
                            id
                            location
                            college_name
                            opening_rank
                            closing_rank
                            gender
                            branch_name
                            quota_name
                            seat_type
                            website
                            college_code
                            nta_college_id
                            nta_branch_id
                            round
                            is_shortlisted
                          }
                    }
                `,
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    year: parseInt(this.state.yearValue.value),
                    college_Ids: this.state.ecollegeselValue.toString(),
                    branch_Ids: this.state.ebranchselValue.toString(),
                    quota_Ids: this.state.equotaselValue.toString(),
                    seat_type_Ids: this.state.eseattypeselValue.toString(),
                    rank: parseInt(this.state.inputRank),
                    round: this.state.eroundselValue.toString(),
                    state_Ids: this.state.estateselValue.toString(),
                    college_Type_Ids: this.state.ecollegetypeselValue.toString(),
                    mobile: Cookies.get("mobile"),
                    exam_type: 1,
                    b_arch_type: 0,
                    degree_type: this.state.degreeTypeValue,
                    gender_type: this.state.egenderselValue.toString()


                },
                fetchPolicy: 'network-only'
            })

            if (result7.data.getNTACollegesPrediction.length > 0) {
                this.setState({
                    getNTACollegesPrediction: result7.data.getNTACollegesPrediction,
                    showSpinner: "hide",
                })
            } else {
                this.setState({
                    getNTACollegesPrediction: [],
                    showSpinner: "hide"
                })
            }
        }

        else if (value == "advance") {

            const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);


            const delay = (ms) => new Promise((r) => setTimeout(r, ms));
            await delay(1000);

            if (isuserValid.college_prediction_package_acess == true) {
                this.setState({ userRestionModalShow: true })

            } else {
                this.setState({
                    showSpinner: "show",
                    advanceCheckBox: true,
                    mainCheckBox: false,
                    bArchCheckbox: false,

                    getNTACollegesPrediction: [],
                    enablearchSubtypes: false,
                    input_exam_type: value,
                    input_exam_type_value: 0,
                    ecollegetypeselValue: 2,
                    ecollegetypeselData: [],
                    equotaselData: [],
                    equotaselValue: [],
                    eseattypeselData: [],
                    eseattypeselValue: [],
                    estateselData: [],
                    estateselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                    ecollegeselValue: [],
                    eroundselData: [{ value: 6, label: 6 }],
                    eroundselValue: [6],
                    inputRank: 0,
                    b_arch_type_value: 0,

                    degreeTypeValue: 1,
                    fourdegreeCheckBox: true,
                    dualdegreecheck: false,
                    allDegreecheckbox: false
                });

                const result6 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int,$quota_Ids:String){
                        getNTAPredictorSeatTypes(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,quota_Ids:$quota_Ids) {
                            id
                            seat_type
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: 0,
                        b_arch_type: 0,
                        year: parseInt(this.state.yearValue.value),
                        quota_Ids: this.state.equotaselValue.toString()

                    },
                    fetchPolicy: "network-only"
                })

                if (result6.data.getNTAPredictorSeatTypes.length > 0) {
                    this.setState({
                        seatTypeFilterData: result6.data.getNTAPredictorSeatTypes
                    })
                } else {
                    this.setState({
                        seatTypeFilterData: []
                    })
                }

                const result4 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int){
                        getNTAPredictorQuota(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type) {
                            id
                            quota_name
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: 0,
                        b_arch_type: 0,
                        year: parseInt(this.state.yearValue.value),
                    },
                    fetchPolicy: 'network-only'
                })

                if (result4.data.getNTAPredictorQuota.length > 0) {
                    this.setState({
                        quotaFilterData: result4.data.getNTAPredictorQuota
                    })
                } else {
                    this.setState({
                        quotaFilterData: []
                    })
                }

                const result2 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                        getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                            id
                            branch_name
                            branch_logo
                            enabled
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        college_type: "2",
                        state_id: this.state.estateselValue.toString(),
                        exam_type: 0,
                        b_arch_type: 0,
                        year: parseInt(this.state.yearValue.value),
                        degree_type: this.state.degreeTypeValue,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        mobile: Cookies.get("mobile")


                    },
                    fetchPolicy: 'network-only'
                })

                if (result2.data.getNTAPredictorBranches.length > 0) {
                    this.setState({
                        branchFilterData: result2.data.getNTAPredictorBranches
                    })
                } else {
                    this.setState({
                        branchFilterData: []
                    })
                }

                const result3 = await this.props.client.query({
                    query: gql`
                    query($year:Int,$exam_id:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String){
                        getStates(year:$year,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids){
                            state_id
                            state_name
                          }
                    }
                    `,
                    variables: {
                        year: parseInt(this.state.yearValue.value),
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: 0,
                        b_arch_type: 0,
                        degree_type: this.state.degreeTypeValue,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),

                    },
                    fetchPolicy: "network-only"
                })

                if (result3.data.getStates.length > 0) {
                    this.setState({
                        stateFilterData: result3.data.getStates
                    })
                } else {
                    this.setState({ stateFilterData: [] })
                }

                const result = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                        getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                            id
                            college_name
                            enabled
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        state_id: this.state.estateselValue.toString(),
                        college_type: this.state.ecollegetypeselValue.toString(),
                        branch_id: this.state.ebranchselValue.toString(),
                        year: parseInt(this.state.yearValue.value),
                        exam_type: 0,
                        b_arch_type: 0,
                        degree_type: this.state.degreeTypeValue,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        mobile: Cookies.get("mobile")


                    },
                    fetchPolicy: 'network-only'
                })

                if (result.data.getNTAPredictorColleges.length > 0) {
                    this.setState({
                        collegeFilterData: result.data.getNTAPredictorColleges
                    })
                } else {
                    this.setState({
                        collegeFilterData: []
                    })
                }

                const result7 = await this.props.client.query({
                    query: gql`
                    query( $exam_id: Int,
                        $ year: Int,
                        $ college_Ids: String,
                        $branch_Ids: String,
                        $quota_Ids: String,
                        $seat_type_Ids: String,
                        $rank: Int,
                        $round: String,
                        $state_Ids: String,
                        $college_Type_Ids: String
                        $mobile:String
                        $exam_type:Int
                        $b_arch_type:Int
                        $degree_type:Int
                        $gender_type:String){
                            getNTACollegesPrediction(
                                exam_id: $exam_id,
                                year: $year,
                                college_Ids: $college_Ids,
                                branch_Ids: $branch_Ids,
                                quota_Ids: $quota_Ids,
                                seat_type_Ids: $seat_type_Ids,
                                rank: $rank,
                                round: $round,
                                state_Ids: $state_Ids,
                                college_Type_Ids: $college_Type_Ids,
                                mobile:$mobile,
                                exam_type:$exam_type
                                b_arch_type:$b_arch_type
                                degree_type:$degree_type
                                gender_type:$gender_type
    
                              ) {
                                id
                                location
                                college_name
                                opening_rank
                                closing_rank
                                gender
                                branch_name
                                quota_name
                                seat_type
                                website
                                college_code
                                nta_college_id
                                nta_branch_id
                                round
                                is_shortlisted
                              }
                        }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        year: parseInt(this.state.yearValue.value),
                        college_Ids: this.state.ecollegeselValue.toString(),
                        branch_Ids: this.state.ebranchselValue.toString(),
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        rank: parseInt(this.state.inputRank),
                        round: this.state.eroundselValue.toString(),
                        state_Ids: this.state.estateselValue.toString(),
                        college_Type_Ids: this.state.ecollegetypeselValue.toString(),
                        mobile: Cookies.get("mobile"),
                        exam_type: 0,
                        b_arch_type: 0,
                        degree_type: this.state.degreeTypeValue,
                        gender_type: this.state.egenderselValue.toString()


                    },
                    fetchPolicy: 'network-only'
                })

                if (result7.data.getNTACollegesPrediction.length > 0) {
                    this.setState({
                        getNTACollegesPrediction: result7.data.getNTACollegesPrediction,
                        showSpinner: "hide",

                    })
                } else {
                    this.setState({
                        getNTACollegesPrediction: [],
                        showSpinner: "hide"
                    })
                }

            }


        }

        else if (value == "architecture") {

            const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

            if (isuserValid.college_prediction_package_acess == true) {
                this.setState({ userRestionModalShow: true })
            } else {
                const delay = (ms) => new Promise((r) => setTimeout(r, ms));
                await delay(1000);

                this.setState({
                    showSpinner: "show",

                    getNTACollegesPrediction: [],
                    mainCheckBox: false,
                    advanceCheckBox: false,
                    bArchCheckbox: true,

                    enablearchSubtypes: true,
                    input_exam_type: value,
                    b_arch_type_value: 2,
                    input_exam_type_value: 1,
                    ecollegetypeselValue: [],
                    ecollegetypeselData: [],
                    equotaselData: [],
                    equotaselValue: [],
                    eseattypeselData: [],
                    eseattypeselValue: [],
                    estateselData: [],
                    estateselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                    ecollegeselValue: [],
                    eroundselData: [{ value: 6, label: 6 }],
                    eroundselValue: [6],
                    inputRank: 0,

                    degreeTypeValue: 1,
                    fourdegreeCheckBox: true,
                    dualdegreecheck: false,
                    allDegreecheckbox: false
                });

                const result6 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int,$quota_Ids:String){
                        getNTAPredictorSeatTypes(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,quota_Ids:$quota_Ids) {
                            id
                            seat_type
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: 1,
                        b_arch_type: 2,
                        year: parseInt(this.state.yearValue.value),
                        quota_Ids: this.state.equotaselValue.toString()

                    },
                    fetchPolicy: "network-only"
                })

                if (result6.data.getNTAPredictorSeatTypes.length > 0) {
                    this.setState({
                        seatTypeFilterData: result6.data.getNTAPredictorSeatTypes
                    })
                } else {
                    this.setState({
                        seatTypeFilterData: []
                    })
                }


                const result4 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int){
                        getNTAPredictorQuota(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type) {
                            id
                            quota_name
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: 1,
                        b_arch_type: 2,
                        year: parseInt(this.state.yearValue.value),
                    },
                    fetchPolicy: 'network-only'
                })

                if (result4.data.getNTAPredictorQuota.length > 0) {
                    this.setState({
                        quotaFilterData: result4.data.getNTAPredictorQuota
                    })
                } else {
                    this.setState({
                        quotaFilterData: []
                    })
                }



                const result2 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                        getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                            id
                            branch_name
                            branch_logo
                            enabled
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        college_type: this.state.ecollegetypeselValue.toString(),
                        state_id: this.state.estateselValue.toString(),
                        exam_type: 1,
                        b_arch_type: 2,
                        year: parseInt(this.state.yearValue.value),
                        degree_type: this.state.degreeTypeValue,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        mobile: Cookies.get("mobile")


                    },
                    fetchPolicy: 'network-only'
                })

                if (result2.data.getNTAPredictorBranches.length > 0) {
                    this.setState({
                        branchFilterData: result2.data.getNTAPredictorBranches
                    })
                } else {
                    this.setState({
                        branchFilterData: []
                    })
                }

                const result3 = await this.props.client.query({
                    query: gql`
                    query($year:Int,$exam_id:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String){
                        getStates(year:$year,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids){
                            state_id
                            state_name
                          }
                    }
                    `,
                    variables: {
                        year: parseInt(this.state.yearValue.value),
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: 1,
                        b_arch_type: 2,
                        degree_type: this.state.degreeTypeValue,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),

                    },
                    fetchPolicy: "network-only"
                })

                if (result3.data.getStates.length > 0) {
                    this.setState({
                        stateFilterData: result3.data.getStates
                    })
                } else {
                    this.setState({ stateFilterData: [] })
                }

                const result = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                        getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                            id
                            college_name
                            enabled
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        state_id: this.state.estateselValue.toString(),
                        college_type: this.state.ecollegetypeselValue.toString(),
                        branch_id: this.state.ebranchselValue.toString(),
                        year: parseInt(this.state.yearValue.value),
                        exam_type: 1,
                        b_arch_type: 2,
                        degree_type: this.state.degreeTypeValue,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        mobile: Cookies.get("mobile")


                    },
                    fetchPolicy: 'network-only'

                })
                if (result.data.getNTAPredictorColleges.length > 0) {
                    this.setState({
                        collegeFilterData: result.data.getNTAPredictorColleges
                    })
                } else {
                    this.setState({
                        collegeFilterData: []
                    })
                }

                const result7 = await this.props.client.query({
                    query: gql`
                    query( $exam_id: Int,
                        $ year: Int,
                        $ college_Ids: String,
                        $branch_Ids: String,
                        $quota_Ids: String,
                        $seat_type_Ids: String,
                        $rank: Int,
                        $round: String,
                        $state_Ids: String,
                        $college_Type_Ids: String
                        $mobile:String
                        $exam_type:Int
                        $b_arch_type:Int
                        $degree_type:Int
                        $gender_type:String){
                            getNTACollegesPrediction(
                                exam_id: $exam_id,
                                year: $year,
                                college_Ids: $college_Ids,
                                branch_Ids: $branch_Ids,
                                quota_Ids: $quota_Ids,
                                seat_type_Ids: $seat_type_Ids,
                                rank: $rank,
                                round: $round,
                                state_Ids: $state_Ids,
                                college_Type_Ids: $college_Type_Ids,
                                mobile:$mobile,
                                exam_type:$exam_type
                                b_arch_type:$b_arch_type
                                degree_type:$degree_type
                                gender_type:$gender_type
    
                              ) {
                                id
                                location
                                college_name
                                opening_rank
                                closing_rank
                                gender
                                branch_name
                                quota_name
                                seat_type
                                website
                                college_code
                                nta_college_id
                                nta_branch_id
                                round
                                is_shortlisted
                              }
                        }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        year: parseInt(this.state.yearValue.value),
                        college_Ids: this.state.ecollegeselValue.toString(),
                        branch_Ids: this.state.ebranchselValue.toString(),
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        rank: parseInt(this.state.inputRank),
                        round: this.state.eroundselValue.toString(),
                        state_Ids: this.state.estateselValue.toString(),
                        college_Type_Ids: this.state.ecollegetypeselValue.toString(),
                        mobile: Cookies.get("mobile"),
                        exam_type: 1,
                        b_arch_type: 2,
                        degree_type: this.state.degreeTypeValue,
                        gender_type: this.state.egenderselValue.toString()


                    },
                    fetchPolicy: 'network-only'
                })

                if (result7.data.getNTACollegesPrediction.length > 0) {
                    this.setState({
                        getNTACollegesPrediction: result7.data.getNTACollegesPrediction,
                        showSpinner: "hide",

                    })
                } else {
                    this.setState({
                        getNTACollegesPrediction: [],
                        showSpinner: "hide"
                    })
                }

            }



        }

        else if (value == "architecture_mains") {

            const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

            if (isuserValid.college_prediction_package_acess == true) {
                this.setState({ userRestionModalShow: true })
            } else {
                const delay = (ms) => new Promise((r) => setTimeout(r, ms));
                await delay(1000);

                this.setState({
                    showSpinner: "show",
                    mainCheckBox: false,
                    advanceCheckBox: false,
                    bArchCheckbox: true,

                    getNTACollegesPrediction: [],

                    input_exam_type: value,
                    input_exam_type_value: 1,
                    ecollegetypeselValue: [],
                    equotaselData: [],
                    equotaselValue: [],
                    eseattypeselData: [],
                    eseattypeselValue: [],
                    estateselData: [],
                    estateselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                    ecollegetypeselData: [],
                    ecollegeselValue: [],
                    eroundselData: [{ value: 6, label: 6 }],
                    eroundselValue: [6],
                    inputRank: 0,

                    degreeTypeValue: 1,
                    fourdegreeCheckBox: true,
                    dualdegreecheck: false,
                    allDegreecheckbox: false
                });

                const result6 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int,$quota_Ids:String){
                        getNTAPredictorSeatTypes(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,quota_Ids:$quota_Ids) {
                            id
                            seat_type
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: 1,
                        b_arch_type: 2,
                        year: parseInt(this.state.yearValue.value),
                        quota_Ids: this.state.equotaselValue.toString()

                    },
                    fetchPolicy: "network-only"
                })

                if (result6.data.getNTAPredictorSeatTypes.length > 0) {
                    this.setState({
                        seatTypeFilterData: result6.data.getNTAPredictorSeatTypes
                    })
                } else {
                    this.setState({
                        seatTypeFilterData: []
                    })
                }

                const result4 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int){
                        getNTAPredictorQuota(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type) {
                            id
                            quota_name
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: 1,
                        b_arch_type: 2,
                        year: parseInt(this.state.yearValue.value),
                    },
                    fetchPolicy: 'network-only'
                })

                if (result4.data.getNTAPredictorQuota.length > 0) {
                    this.setState({
                        quotaFilterData: result4.data.getNTAPredictorQuota
                    })
                } else {
                    this.setState({
                        quotaFilterData: []
                    })
                }



                const result2 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                        getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                            id
                            branch_name
                            branch_logo
                            enabled
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        college_type: this.state.ecollegetypeselValue.toString(),
                        state_id: this.state.estateselValue.toString(),
                        exam_type: 1,
                        b_arch_type: 2,
                        year: parseInt(this.state.yearValue.value),
                        degree_type: this.state.degreeTypeValue,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        mobile: Cookies.get("mobile")


                    },
                    fetchPolicy: 'network-only'
                })

                if (result2.data.getNTAPredictorBranches.length > 0) {
                    this.setState({
                        branchFilterData: result2.data.getNTAPredictorBranches
                    })
                } else {
                    this.setState({
                        branchFilterData: []
                    })
                }

                const result3 = await this.props.client.query({
                    query: gql`
                    query($year:Int,$exam_id:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String){
                        getStates(year:$year,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids){
                            state_id
                            state_name
                          }
                    }
                    `,
                    variables: {
                        year: parseInt(this.state.yearValue.value),
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: 1,
                        b_arch_type: 2,
                        degree_type: this.state.degreeTypeValue,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),

                    },
                    fetchPolicy: "network-only"
                })

                if (result3.data.getStates.length > 0) {
                    this.setState({
                        stateFilterData: result3.data.getStates
                    })
                } else {
                    this.setState({ stateFilterData: [] })
                }

                const result = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                        getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                            id
                            college_name
                            enabled
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        state_id: this.state.estateselValue.toString(),
                        college_type: this.state.ecollegetypeselValue.toString(),
                        branch_id: this.state.ebranchselValue.toString(),
                        year: parseInt(this.state.yearValue.value),
                        exam_type: 1,
                        b_arch_type: 2,
                        degree_type: this.state.degreeTypeValue,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        mobile: Cookies.get("mobile")


                    },
                    fetchPolicy: 'network-only'

                })
                if (result.data.getNTAPredictorColleges.length > 0) {
                    this.setState({
                        collegeFilterData: result.data.getNTAPredictorColleges
                    })
                } else {
                    this.setState({
                        collegeFilterData: []
                    })
                }

                const result7 = await this.props.client.query({
                    query: gql`
                    query( $exam_id: Int,
                        $ year: Int,
                        $ college_Ids: String,
                        $branch_Ids: String,
                        $quota_Ids: String,
                        $seat_type_Ids: String,
                        $rank: Int,
                        $round: String,
                        $state_Ids: String,
                        $college_Type_Ids: String
                        $mobile:String
                        $exam_type:Int
                        $b_arch_type:Int
                        $degree_type:Int
                        $gender_type:String){
                            getNTACollegesPrediction(
                                exam_id: $exam_id,
                                year: $year,
                                college_Ids: $college_Ids,
                                branch_Ids: $branch_Ids,
                                quota_Ids: $quota_Ids,
                                seat_type_Ids: $seat_type_Ids,
                                rank: $rank,
                                round: $round,
                                state_Ids: $state_Ids,
                                college_Type_Ids: $college_Type_Ids,
                                mobile:$mobile,
                                exam_type:$exam_type
                                b_arch_type:$b_arch_type
                                degree_type:$degree_type
                                gender_type:$gender_type
    
                              ) {
                                id
                                location
                                college_name
                                opening_rank
                                closing_rank
                                gender
                                branch_name
                                quota_name
                                seat_type
                                website
                                college_code
                                nta_college_id
                                nta_branch_id
                                round
                                is_shortlisted
                              }
                        }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        year: parseInt(this.state.yearValue.value),
                        college_Ids: this.state.ecollegeselValue.toString(),
                        branch_Ids: this.state.ebranchselValue.toString(),
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        rank: parseInt(this.state.inputRank),
                        round: this.state.eroundselValue.toString(),
                        state_Ids: this.state.estateselValue.toString(),
                        college_Type_Ids: this.state.ecollegetypeselValue.toString(),
                        mobile: Cookies.get("mobile"),
                        exam_type: 1,
                        b_arch_type: 2,
                        degree_type: this.state.degreeTypeValue,
                        gender_type: this.state.egenderselValue.toString()


                    },
                    fetchPolicy: 'network-only'
                })

                if (result7.data.getNTACollegesPrediction.length > 0) {
                    this.setState({
                        getNTACollegesPrediction: result7.data.getNTACollegesPrediction,
                        showSpinner: "hide",

                    })
                } else {
                    this.setState({
                        getNTACollegesPrediction: [],
                        showSpinner: "hide"
                    })
                }
            }



        }

        else if (value == "architecture_advance") {

            const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

            if (isuserValid.college_prediction_package_acess == true) {
                this.setState({ userRestionModalShow: true })
            } else {
                const delay = (ms) => new Promise((r) => setTimeout(r, ms));
                await delay(1000);

                this.setState({
                    mainCheckBox: false,
                    advanceCheckBox: false,
                    bArchCheckbox: true,
                    showSpinner: "show",
                    getNTACollegesPrediction: [],
                    input_exam_type: value,
                    input_exam_type_value: 0,
                    ecollegetypeselValue: 2,
                    equotaselData: [],
                    equotaselValue: [],
                    eseattypeselData: [],
                    eseattypeselValue: [],
                    estateselData: [],
                    estateselValue: [],
                    ebranchselData: [],
                    ebranchselValue: [],
                    ecollegeselData: [],
                    ecollegeselValue: [],
                    eroundselData: [{ value: 6, label: 6 }],
                    eroundselValue: [6],
                    inputRank: 0,

                    degreeTypeValue: 2,
                    fourdegreeCheckBox: false,
                    dualdegreecheck: true,
                    allDegreecheckbox: false
                });

                const result6 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int,$quota_Ids:String){
                        getNTAPredictorSeatTypes(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,quota_Ids:$quota_Ids) {
                            id
                            seat_type
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: 0,
                        b_arch_type: 2,
                        year: parseInt(this.state.yearValue.value),
                        quota_Ids: this.state.equotaselValue.toString()

                    },
                    fetchPolicy: "network-only"
                })

                if (result6.data.getNTAPredictorSeatTypes.length > 0) {
                    this.setState({
                        seatTypeFilterData: result6.data.getNTAPredictorSeatTypes
                    })
                } else {
                    this.setState({
                        seatTypeFilterData: []
                    })
                }

                const result4 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int){
                        getNTAPredictorQuota(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type) {
                            id
                            quota_name
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: 0,
                        b_arch_type: 2,
                        year: parseInt(this.state.yearValue.value),
                    },
                    fetchPolicy: 'network-only'
                })

                if (result4.data.getNTAPredictorQuota.length > 0) {
                    this.setState({
                        quotaFilterData: result4.data.getNTAPredictorQuota
                    })
                } else {
                    this.setState({
                        quotaFilterData: []
                    })
                }

                const result2 = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                        getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                            id
                            branch_name
                            branch_logo
                            enabled
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        college_type: this.state.ecollegetypeselValue.toString(),
                        state_id: this.state.estateselValue.toString(),
                        exam_type: 0,
                        b_arch_type: 2,
                        year: parseInt(this.state.yearValue.value),
                        degree_type: 2,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        mobile: Cookies.get("mobile")


                    },
                    fetchPolicy: 'network-only'
                })

                if (result2.data.getNTAPredictorBranches.length > 0) {
                    this.setState({
                        branchFilterData: result2.data.getNTAPredictorBranches
                    })
                } else {
                    this.setState({
                        branchFilterData: []
                    })
                }

                const result3 = await this.props.client.query({
                    query: gql`
                    query($year:Int,$exam_id:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String){
                        getStates(year:$year,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids){
                            state_id
                            state_name
                          }
                    }
                    `,
                    variables: {
                        year: parseInt(this.state.yearValue.value),
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: 0,
                        b_arch_type: 2,
                        degree_type: 2,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),

                    },
                    fetchPolicy: "network-only"
                })


                if (result3.data.getStates.length > 0) {
                    this.setState({
                        stateFilterData: result3.data.getStates
                    })
                } else {
                    this.setState({ stateFilterData: [] })
                }

                const result = await this.props.client.query({
                    query: gql`
                    query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
                        getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
                            id
                            college_name
                            enabled
                          }
                    }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        state_id: this.state.estateselValue.toString(),
                        college_type: this.state.ecollegetypeselValue.toString(),
                        branch_id: this.state.ebranchselValue.toString(),
                        year: parseInt(this.state.yearValue.value),
                        exam_type: 0,
                        b_arch_type: 2,
                        degree_type: 2,
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        mobile: Cookies.get("mobile")


                    },
                    fetchPolicy: 'network-only'

                })
                if (result.data.getNTAPredictorColleges.length > 0) {
                    this.setState({
                        collegeFilterData: result.data.getNTAPredictorColleges
                    })
                } else {
                    this.setState({
                        collegeFilterData: []
                    })
                }

                const result7 = await this.props.client.query({
                    query: gql`
                    query( $exam_id: Int,
                        $ year: Int,
                        $ college_Ids: String,
                        $branch_Ids: String,
                        $quota_Ids: String,
                        $seat_type_Ids: String,
                        $rank: Int,
                        $round: String,
                        $state_Ids: String,
                        $college_Type_Ids: String
                        $mobile:String
                        $exam_type:Int
                        $b_arch_type:Int
                        $degree_type:Int
                        $gender_type:String){
                            getNTACollegesPrediction(
                                exam_id: $exam_id,
                                year: $year,
                                college_Ids: $college_Ids,
                                branch_Ids: $branch_Ids,
                                quota_Ids: $quota_Ids,
                                seat_type_Ids: $seat_type_Ids,
                                rank: $rank,
                                round: $round,
                                state_Ids: $state_Ids,
                                college_Type_Ids: $college_Type_Ids,
                                mobile:$mobile,
                                exam_type:$exam_type
                                b_arch_type:$b_arch_type
                                degree_type:$degree_type
                                gender_type:$gender_type
    
                              ) {
                                id
                                location
                                college_name
                                opening_rank
                                closing_rank
                                gender
                                branch_name
                                quota_name
                                seat_type
                                website
                                college_code
                                nta_college_id
                                nta_branch_id
                                round
                                is_shortlisted
                              }
                        }
                    `,
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        year: parseInt(this.state.yearValue.value),
                        college_Ids: this.state.ecollegeselValue.toString(),
                        branch_Ids: this.state.ebranchselValue.toString(),
                        quota_Ids: this.state.equotaselValue.toString(),
                        seat_type_Ids: this.state.eseattypeselValue.toString(),
                        rank: parseInt(this.state.inputRank),
                        round: this.state.eroundselValue.toString(),
                        state_Ids: this.state.estateselValue.toString(),
                        college_Type_Ids: this.state.ecollegetypeselValue.toString(),
                        mobile: Cookies.get("mobile"),
                        exam_type: 0,
                        b_arch_type: 2,
                        degree_type: 2,
                        gender_type: this.state.egenderselValue.toString()


                    },
                    fetchPolicy: 'network-only'
                })

                if (result7.data.getNTACollegesPrediction.length > 0) {
                    this.setState({
                        getNTACollegesPrediction: result7.data.getNTACollegesPrediction,
                        showSpinner: "hide",

                    })
                } else {
                    this.setState({
                        getNTACollegesPrediction: [],
                        showSpinner: "hide"
                    })
                }
            }
        }
    }

    closeModal = () => {
        this.setState({
            userRestionModalShow: false,
            mainCheckBox: true,
            advanceCheckBox: false,
            bArchCheckbox: false,
            fourdegreeCheckBox: true,
            dualdegreecheck: false,
            allDegreecheckbox: false,
            input_exam_type_value: 1,
            b_arch_type_value: 0,
            degreeTypeValue: 1,
        });
    }

    render() {
        const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);


        //quota data

        let quotaArray = [];

        if (this.state.input_exam_type == "advance" || this.state.input_exam_type == "architecture_advance" || this.state.input_exam_type == "architecture_mains" || this.state.input_exam_type == "architecture") {
            this.state.quotaFilterData.map((item) => {
                if (item != undefined) {
                    const obj = {
                        value: item.id,
                        label: item.quota_name,
                    }
                    quotaArray.push(obj)
                }
            })
        } else {
            this.props.getNTAPredictorQuota.length > 0 && this.props.getNTAPredictorQuota.map((item) => {
                if (item != undefined) {
                    const obj = {
                        value: item.id,
                        label: item.quota_name,
                    }
                    quotaArray.push(obj);
                }
            })
        }

        let quotaselectall = true;
        let quotalabelledBY = "ALL";
        let quotadisablesearch = false;
        if (quotaArray.length > 0) {
            quotaselectall = true;
            quotadisablesearch = false;
        }
        else {
            quotadisablesearch = true;
            quotaselectall = false;
            quotalabelledBY = "No Options"
        }

        //seat type data 

        let seatTypeArray = [];

        if (this.state.input_exam_type == "advance" || this.state.input_exam_type == "architecture_advance" || this.state.input_exam_type == "architecture_mains" || this.state.input_exam_type == "architecture" || this.state.equotaselValue.length > 0) {
            this.state.seatTypeFilterData.map((item) => {
                if (item != undefined) {
                    const obj = {
                        value: item.id,
                        label: item.seat_type,
                    }
                    seatTypeArray.push(obj);
                }
            })
        } else {
            this.props.getNTAPredictorSeatTypes.length > 0 && this.props.getNTAPredictorSeatTypes.map((item) => {
                if (item != undefined) {
                    const obj = {
                        value: item.id,
                        label: item.seat_type,
                    }
                    seatTypeArray.push(obj);
                }
            })
        }

        let seattypeselectall = true;
        let seattypelabelledBY = "ALL";
        let seattypedisablesearch = false;
        if (seatTypeArray.length > 0) {
            seattypeselectall = true;
            seattypedisablesearch = false;
        }
        else {
            seattypedisablesearch = true;
            seattypeselectall = false;
            seattypelabelledBY = "No Options"
        }

        // state data 
        let stateArray = [];

        if (this.state.input_exam_type == "advance" || this.state.input_exam_type == "architecture_advance" || this.state.input_exam_type == "architecture_mains" || this.state.input_exam_type == "architecture" || this.state.yearValue.value != this.props.getNTAPredictorYears[0].year || this.state.degreeTypeValue == 0 || this.state.degreeTypeValue == 2 || this.state.equotaselValue.length > 0 || this.state.eseattypeselValue.length > 0) {
            this.state.stateFilterData.map((item) => {
                if (item != undefined) {
                    const obj = {
                        value: item.state_id,
                        label: item.state_name
                    }
                    stateArray.push(obj)
                }
            })
        } else {
            this.props.getStates.length > 0 && this.props.getStates.map((item) => {
                if (item != undefined) {
                    const obj = {
                        value: item.state_id,
                        label: item.state_name
                    }
                    stateArray.push(obj)
                }
            })
        }

        let stateselectall = true;
        let statelabelledBY = "ALL";
        let statedisablesearch = false;
        if (stateArray.length > 0) {
            stateselectall = true;
            statedisablesearch = false;
        }
        else {
            statedisablesearch = true;
            stateselectall = false;
            statelabelledBY = "No Options"
        }

        // college type data 

        let collegeTypeArray = [];

        if (this.state.estateselValue.length > 0 || this.state.equotaselValue.length > 0 || this.state.eseattypeselValue.length > 0) {
            this.state.collegetypeFilterData.map((item) => {

                if (item != undefined) {
                    if (item.enabled == false) {
                        const obj = {
                            value: item.id,
                            label: item.college_type,
                            checked: true
                        }
                        collegeTypeArray.push(obj)
                    } else {
                        const newObj = {
                            value: item.id,
                            label: <p><Image src={require('../../../images/locked.png')} width="13" alt="Lock" /> {item.college_type}</p>,
                            checked: false
                        }
                        collegeTypeArray.push(newObj)
                    }
                }
                let combined = [];
                combined = collegeTypeArray.filter((a) => a.checked == true);
                combined.push(...collegeTypeArray.filter((a) => a.checked == false));
                collegeTypeArray = combined

            })
        } else {
            this.props.getNTAPredictorCollegeTypes.length > 0 && this.props.getNTAPredictorCollegeTypes.map((item) => {
                if (item != undefined) {
                    if (item.enabled == false) {
                        const obj = {
                            value: item.id,
                            label: item.college_type,
                            checked: true
                        }
                        collegeTypeArray.push(obj)
                    } else {
                        const newObj = {
                            value: item.id,
                            label: <p><Image src={require('../../../images/locked.png')} width="13" alt="Lock" /> {item.college_type}</p>,
                            checked: false
                        }
                        collegeTypeArray.push(newObj)
                    }
                }
            })
            let combined = [];
            combined = collegeTypeArray.filter((a) => a.checked == true);
            combined.push(...collegeTypeArray.filter((a) => a.checked == false));
            collegeTypeArray = combined
        }

        let collegetypeselectall = true;
        let collegetypelabelledBY = "ALL";
        let collegetypedisablesearch = false;
        if (collegeTypeArray.length > 0) {
            collegetypeselectall = true;
            collegetypedisablesearch = false;
        }
        else {
            collegetypedisablesearch = true;
            collegetypeselectall = false;
            collegetypelabelledBY = "No Options"
        }

        // college data

        let collegesArray = []
        if (this.state.ecollegetypeselValue.length > 0 || this.state.equotaselValue.length > 0 || this.state.eseattypeselValue.length > 0 || this.state.estateselValue.length > 0 || this.state.ebranchselValue.length > 0 || this.state.input_exam_type == "advance" || this.state.input_exam_type == "architecture_advance" || this.state.input_exam_type == "architecture_mains" || this.state.input_exam_type == "architecture" || this.state.degreeTypeValue == 0 || this.state.degreeTypeValue == 2) {
            this.state.collegeFilterData.map((item) => {
                if (item.enabled == false) {
                    const obj = {
                        value: item.id,
                        label: item.college_name,
                        checked: true
                    }
                    collegesArray.push(obj)
                } else {
                    const newobj = {
                        value: item.id,
                        label: <p><Image src={require('../../../images/locked.png')} width="13" alt="Lock" /> {item.college_name}</p>,
                        checked: false
                    }
                    collegesArray.push(newobj)
                }
            })
            let combined = [];
            combined = collegesArray.filter((a) => a.checked == true);
            combined.push(...collegesArray.filter((a) => a.checked == false));
            collegesArray = combined

        } else {
            this.props.getNTAPredictorColleges.length > 0 && this.props.getNTAPredictorColleges.map((item) => {

                if (item.enabled == false) {
                    const obj = {
                        value: item.id,
                        label: item.college_name,
                        checked: true
                    }
                    collegesArray.push(obj)
                } else {
                    const newobj = {
                        value: item.id,
                        label: <p><Image src={require('../../../images/locked.png')} width="13" alt="Lock" /> {item.college_name}</p>,
                        checked: false
                    }
                    collegesArray.push(newobj)
                }
            })
            let combined = [];
            combined = collegesArray.filter((a) => a.checked == true);
            combined.push(...collegesArray.filter((a) => a.checked == false));
            collegesArray = combined
        }

        let collegeselectall = true;
        let collegelabelledBY = "ALL";
        let collegedisablesearch = false;
        if (collegesArray.length > 0) {
            collegeselectall = true;
            collegedisablesearch = false;
        }
        else {
            collegedisablesearch = true;
            collegeselectall = false;
            collegelabelledBY = "No Options"
        }

        // branches data 

        let branchesArray = [];

        if (this.state.ecollegetypeselValue.length > 0 || this.state.equotaselValue.length > 0 || this.state.eseattypeselValue.length > 0 || this.state.estateselValue.length > 0 || this.state.input_exam_type == "advance" || this.state.input_exam_type == "architecture_advance" || this.state.input_exam_type == "architecture_mains" || this.state.input_exam_type == "architecture" || this.state.degreeTypeValue == 0 || this.state.degreeTypeValue == 2) {
            this.state.branchFilterData.map((item) => {
                if (item.enabled == false) {
                    const obj = {
                        value: item.id,
                        label: item.branch_name,
                        checked: true,
                    }
                    branchesArray.push(obj)
                }
                else {
                    const newObj = {
                        value: item.id,
                        label: <p><Image src={require('../../../images/locked.png')} width="13" alt="Lock" /> {item.branch_name}</p>,
                        checked: false,
                    }
                    branchesArray.push(newObj)
                }
            })
            let combined = [];
            combined = branchesArray.filter((a) => a.checked == true);
            combined.push(...branchesArray.filter((a) => a.checked == false));
            branchesArray = combined

            console.log("branchesArraybranchesArray", branchesArray)
        } else {
            this.props.getNTAPredictorBranches.length > 0 && this.props.getNTAPredictorBranches.map((item) => {
                if (item != undefined) {
                    if (item.enabled == false) {
                        const obj = {
                            value: item.id,
                            label: item.branch_name,
                            checked: true,
                        }
                        branchesArray.push(obj)
                    }
                    else {
                        const newObj = {
                            value: item.id,
                            label: <p><Image src={require('../../../images/locked.png')} width="13" alt="Lock" /> {item.branch_name}</p>,
                            checked: false,
                        }
                        branchesArray.push(newObj)
                    }
                }
            })
            let combined = [];
            combined = branchesArray.filter((a) => a.checked == true);
            combined.push(...branchesArray.filter((a) => a.checked == false));
            branchesArray = combined
        }

        let branchselectall = true;
        let branchlabelledBY = "ALL";
        let branchdisablesearch = false;
        if (branchesArray.length > 0) {
            branchselectall = true;
            branchdisablesearch = false;
        }
        else {
            branchdisablesearch = true;
            branchselectall = false;
            branchlabelledBY = "No Options"
        }

        //round data

        let roundsArray = [];
        this.props.getNTAPredictorRounds.length > 0 && this.props.getNTAPredictorRounds.map((item) => {
            if (item != undefined) {
                const obj = {
                    value: item.id,
                    label: item.round
                }
                roundsArray.push(obj)
            }
        })

        let roundselectall = true;
        let roundlabelledBY = "ALL";
        let rounddisablesearch = false;
        if (roundsArray.length > 0) {
            branchselectall = true;
            branchdisablesearch = false;
        }
        else {
            branchdisablesearch = true;
            branchselectall = false;
            branchlabelledBY = "No Options"
        }

        // genders Data 

        let gendersArray = [];
        this.props.getNtaGenderTypes.length > 0 && this.props.getNtaGenderTypes.map((item) => {
            if (item != undefined) {
                const obj = {
                    value: item.id,
                    label: item.gender_type
                }
                gendersArray.push(obj)
            }
        })

        let genderselectall = true;
        let genderlabelledBY = "ALL";
        let genderdisablesearch = false;
        if (gendersArray.length > 0) {
            genderselectall = true;
            genderdisablesearch = false;
        }
        else {
            genderdisablesearch = true;
            genderselectall = false;
            genderlabelledBY = "No Options"
        }

        return (
            <Container className='nta-predictor'>
                <>
                    <Row className='mt-5'>
                        <Col sm={6}>
                            <Row>
                                <h6 className='mr-3'>Exam Name:</h6>
                                <div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="mains" value="mains" name="input_exam_type" className="custom-control-input" checked={this.state.mainCheckBox} onChange={(e) => this.handleSelectChange(e)} />
                                        <label className="custom-control-label inp_text" htmlFor="mains" > JEE Mains</label>
                                    </div>

                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="advance" value="advance" name="input_exam_type" className="custom-control-input" checked={this.state.advanceCheckBox} onChange={(e) => this.handleSelectChange(e)} />
                                        <label className="custom-control-label inp_text" htmlFor="advance" >JEE Advanced (IIT) </label>
                                    </div>

                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="architecture" value="architecture" name="input_exam_type" className="custom-control-input" checked={this.state.bArchCheckbox} onChange={(e) => this.handleSelectChange(e)} />
                                        <label className="custom-control-label inp_text" htmlFor="architecture" > B.Arch./B.Planning </label>
                                    </div>
                                </div>
                            </Row>
                            <Row className='mt-3'>
                                <h6 className='mr-3'>Degree Type:</h6>
                                <div>
                                    {
                                        this.state.b_arch_type_value == 2 && this.state.input_exam_type_value == 0 ?
                                            (
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="dual_degree" value="dual_degree" name="degree" className="custom-control-input" checked={this.state.dualdegreecheck} onChange={(e) => this.handleSelectChangeDegree(e)} />
                                                    <label className="custom-control-label inp_text" htmlFor="dual_degree" >Dual Degree </label>
                                                </div>
                                            )
                                            :
                                            (
                                                <>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="single_degree" value="single_degree" name="degree" className="custom-control-input" checked={this.state.fourdegreeCheckBox} onChange={(e) => this.handleSelectChangeDegree(e)} />
                                                        <label className="custom-control-label inp_text" htmlFor="single_degree" >4 Years Degree</label>
                                                    </div>

                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="dual_degree" value="dual_degree" name="degree" className="custom-control-input" checked={this.state.dualdegreecheck} onChange={(e) => this.handleSelectChangeDegree(e)} />
                                                        <label className="custom-control-label inp_text" htmlFor="dual_degree" >Dual Degree </label>
                                                    </div>

                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="all" value="all" name="degree" className="custom-control-input" checked={this.state.allDegreecheckbox} onChange={(e) => this.handleSelectChangeDegree(e)} />
                                                        <label className="custom-control-label inp_text" htmlFor="all" > All </label>
                                                    </div>
                                                </>
                                            )
                                    }
                                </div>
                            </Row>
                        </Col>

                        <Col sm={6} className="mt_resp_1">
                            <Row>
                                {
                                    this.state.enablearchSubtypes == true ?
                                        (
                                            <>
                                                <h6 className='mr-3'>Exam Type:</h6>
                                                <div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="architecture_mains" value="architecture_mains" name="input_exam_type2" className="custom-control-input" defaultChecked={true} onChange={(e) => this.handleSelectChange(e)} />
                                                        <label className="custom-control-label " htmlFor="architecture_mains" > Mains </label>
                                                    </div>

                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="architecture_advance" value="architecture_advance" name="input_exam_type2" className="custom-control-input" onChange={(e) => this.handleSelectChange(e)} />
                                                        <label className="custom-control-label " htmlFor="architecture_advance" > Advance </label>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        :
                                        ("")
                                }
                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-5'>
                        <Col sm={12}>
                            <Form>
                                <Row>
                                    <Form.Group as={Col} xl={2} lg={2} md={2} sm={3} controlId="years">
                                        <Form.Label className="text-uppercase font-weight-bold input_title">Select Year</Form.Label>
                                        <SelectDropDown
                                            handleChange={this.selecthandleInputChange}
                                            name="year"
                                            options={this.state.yearsArray}
                                            placeholderName={'Year'}
                                            dropdownIndicator={{ DropdownIndicator }}
                                            stateData={this.state.yearValue}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} xl={2} lg={2} md={2} sm={3} controlId="quota">
                                        <Form.Label className="text-uppercase font-weight-bold input_title">Quota</Form.Label>
                                        <MultiSelect
                                            overrideStrings={{
                                                "allItemsAreSelected": "All",
                                                "selectSomeItems": quotalabelledBY
                                            }}
                                            hasSelectAll={quotaselectall}
                                            options={quotaArray}
                                            disableSearch={quotadisablesearch}
                                            onChange={(e) => this.handleMultipleSelectInputChange(e, "quota")}
                                            labelledBy={"Select"}
                                            value={this.state.equotaselData}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} xl={2} lg={2} md={2} sm={3} controlId="seattype">
                                        <Form.Label className="text-uppercase font-weight-bold input_title">Seat Type</Form.Label>
                                        <MultiSelect
                                            overrideStrings={{
                                                "allItemsAreSelected": "All",
                                                "selectSomeItems": seattypelabelledBY
                                            }}
                                            hasSelectAll={seattypeselectall}
                                            options={seatTypeArray}
                                            disableSearch={seattypedisablesearch}
                                            onChange={(e) => this.handleMultipleSelectInputChange(e, "seattype")}
                                            labelledBy={"Select"}
                                            value={this.state.eseattypeselData}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="gender">
                                        <Form.Label className="text-uppercase font-weight-bold input_title">Gender</Form.Label>
                                        <MultiSelect
                                            overrideStrings={{
                                                "allItemsAreSelected": "All",
                                                "selectSomeItems": genderlabelledBY
                                            }}
                                            hasSelectAll={genderselectall}
                                            options={gendersArray}
                                            disableSearch={genderdisablesearch}
                                            onChange={(e) => this.handleMultipleSelectInputChange(e, "gender")}
                                            labelledBy={"Select"}
                                            value={this.state.egenderselData}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="state">
                                        <Form.Label className="text-uppercase font-weight-bold input_title">State</Form.Label>
                                        <MultiSelect
                                            overrideStrings={{
                                                "allItemsAreSelected": "All",
                                                "selectSomeItems": statelabelledBY
                                            }}
                                            hasSelectAll={stateselectall}
                                            options={stateArray}
                                            disableSearch={statedisablesearch}
                                            onChange={(e) => this.handleMultipleSelectInputChange(e, "state")}
                                            labelledBy={"Select"}
                                            value={this.state.estateselData}
                                        />
                                    </Form.Group>


                                </Row>

                                <Row>
                                    <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="collegetype">
                                        <Form.Label className="text-uppercase font-weight-bold input_title">College Type</Form.Label>
                                        {
                                            this.state.input_exam_type == "advance" || this.state.input_exam_type == "architecture_advance" ?
                                                (
                                                    <SelectDropDown
                                                        // handleChange={this.selecthandleInputChange}
                                                        name="exam"
                                                        options={advanceExam}
                                                        placeholderName={'Exam'}
                                                        dropdownIndicator={{ DropdownIndicator }}
                                                        stateData={this.state.examTypeValue}
                                                    />
                                                )
                                                :
                                                (
                                                    <MultiSelect
                                                        overrideStrings={{
                                                            "allItemsAreSelected": "All",
                                                            "selectSomeItems": collegetypelabelledBY
                                                        }}
                                                        hasSelectAll={collegetypeselectall}
                                                        options={collegeTypeArray}
                                                        disableSearch={collegetypedisablesearch}
                                                        onChange={(e) => this.handleMultipleSelectInputChange(e, "collegetype")}
                                                        labelledBy={"Select"}
                                                        value={this.state.ecollegetypeselData}
                                                    />
                                                )
                                        }

                                    </Form.Group>

                                    <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="branch">
                                        <Form.Label className="text-uppercase font-weight-bold input_title">Branches</Form.Label>
                                        <MultiSelect
                                            overrideStrings={{
                                                "allItemsAreSelected": "All",
                                                "selectSomeItems": branchlabelledBY
                                            }}
                                            hasSelectAll={branchselectall}
                                            options={branchesArray}
                                            disableSearch={branchdisablesearch}
                                            onChange={(e) => this.handleMultipleSelectInputChange(e, "branch")}
                                            labelledBy={"Select"}
                                            value={this.state.ebranchselData}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="colleges">
                                        <Form.Label className="text-uppercase font-weight-bold input_title">Colleges</Form.Label>
                                        <MultiSelect
                                            overrideStrings={{
                                                "allItemsAreSelected": "All",
                                                "selectSomeItems": collegelabelledBY
                                            }}
                                            hasSelectAll={collegeselectall}
                                            options={collegesArray}
                                            disableSearch={collegedisablesearch}
                                            onChange={(e) => this.handleMultipleSelectInputChange(e, "colleges")}
                                            labelledBy={"Select"}
                                            value={this.state.ecollegeselData}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} xl={2} lg={2} md={2} sm={3} controlId="round">
                                        <Form.Label className="text-uppercase font-weight-bold input_title">Round</Form.Label>
                                        <MultiSelect
                                            overrideStrings={{
                                                "allItemsAreSelected": "All",
                                                "selectSomeItems": roundlabelledBY
                                            }}
                                            hasSelectAll={roundselectall}
                                            options={roundsArray}
                                            disableSearch={rounddisablesearch}
                                            onChange={(e) => this.handleMultipleSelectInputChange(e, "round")}
                                            labelledBy={"Select"}
                                            value={this.state.eroundselData}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} xl={1} lg={1} md={1} sm={3} controlId="rank">
                                        <Form.Label className="text-uppercase font-weight-bold input_title">Rank</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            name="marks"
                                            autoComplete="off"
                                            className='custom_selection'
                                            onChange={(e) => this.handleRank(e)}
                                            value={this.state.inputRank}
                                        />
                                        <h6 className='font-12 errorCls mt-2'>{this.state.error_display}</h6>
                                    </Form.Group>

                                    <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="colleges" className="">
                                        {
                                            isuserValid.college_prediction_package_acess == false ?
                                                (<Button type="button" className='btn custom_button' onClick={(e) => this.getNTACollegesPredictiononloadOnsubmit()}>PREDICT</Button>)
                                                :
                                                (<Button type="button" className='btn custom_button' onClick={(e) => this.restrictedAcccesOnloaDdata()}>PREDICT</Button>)
                                        }
                                    </Form.Group>
                                </Row>
                            </Form>
                        </Col>
                    </Row>

                    {
                        this.state.getNTACollegesPrediction.length > 0 ?
                            (
                                <Row className='mt-3'>
                                    <Col sm={12}>
                                        <p className='disclaimer'>If the Closing/Opening rank has a suffix 'P', it indicates that the corresponding rank is from Preparatory Rank List.</p>
                                    </Col>
                                    <Col sm={12}>
                                        <CardLessDataTableWithSearch
                                            parentData={this.resultData(this.state.getNTACollegesPrediction)}
                                            particlecolumns={this.colFunction()}
                                            searchBar={false}
                                            pageType="college_predictor"
                                        />
                                    </Col>
                                </Row>
                            )
                            :
                            (
                                <Row>
                                    <Col sm={12} className={this.state.showSpinner == "show" ? "showSpinnerCls text-center" : "hideSpinnerCls text-center"}>
                                        <div className={this.state.showSpinner == "show" ? "spinner-border text-primary text-center loading-img-center" : ""} >

                                        </div>
                                    </Col>

                                    <Col>
                                        {
                                            this.state.showSpinner == "hide" ?
                                                (<h6 className='text-center mt-3'>Seats were not filled/allocated for your selected criteria </h6>)
                                                :
                                                ("")
                                        }
                                    </Col>
                                </Row>
                            )
                    }

                    {
                        this.state.getNTACollegesPrediction.length > 0 ?

                            (<button className='goto_short_btn mb-2' onClick={(e) => this.setState({ clearallModal: true })}>Clear all shortlisted colleges</button>

                            )
                            :
                            ("")
                    }

                    <ClearModal
                        show={this.state.clearallModal}
                        onHide={() => this.setState({ clearallModal: false })}
                        clearAll={this.clearAll}
                        spinnerClear={this.state.spinnerClear}
                    />

                    {
                        this.state.getNTACollegesPrediction.length > 0 ?
                            (
                                <Card className='goto_card p-4 mb-4'>
                                    <Row>
                                        <Col sm={3}>
                                            <img src={shortList} className="sl_image" alt="image" />
                                        </Col>
                                        <Col sm={9}>
                                            <h4 className='goto_text text-center'>You have shortlisted colleges based on your information. Now from your shortlisted colleges priotirize your branches and get a full list of branch, colleges and get ready for the web option</h4>
                                            <Link to={{
                                                pathname: "/student/NTA-College_predictor-shortlist",



                                            }} >
                                                <button className='goto_short_btn center-sl-auto'>GO TO SHORLISTED PAGE</button>
                                            </Link>
                                        </Col>
                                    </Row>

                                </Card>
                            )
                            :
                            ("")
                    }
                    <UserRestrictionAlert
                        show={this.state.userRestionModalShow}
                        onHide={() => this.closeModal()}
                        buildType="ntaRankPredictor"
                    />
                </>
            </Container>
        )
    }
}

export default withRouter(withApollo(compose(
    graphql(COLLEGE_SHORTLIST, {
        name: "submitCollege"
    }),
    graphql(DELETE_SHORTLISTED_COLLEGE, {
        name: "removeCollegeData"
    }),
    graphql(removeALL_Shortlisted_colleges, {
        name: "removeAllData"
    }),
)(NTAPredictorSection)));
