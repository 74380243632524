import React, { Component } from 'react'
import { Modal, Card, Button, Row, Col,Image } from "react-bootstrap";
import "./_subscribe-plans.scss";

class PaymentFailureAlert extends Component {
    render() {
        return (

            <Modal {...this.props}
                size={"md"} aria-labelledby="example-modal-sizes-title-lg" className="eamcet-scribePlan-modal-courses">
                <Button variant="link p-0 close" onClick={() => { this.props.onHide() }}> <i className="fa fa-times" /> </Button>
                <Modal.Body className="p-3">
                    <Row >
                        <Col >
                            <Card  className='text-center'>
                                <Card.Body>
                                    <Card.Text>
                                        <Image src={require('../../../images/razar_failure.png')} className="my-2 failure_img" alt="img" />
                                        <h5 className="text-danger mb-4 mt-4">Payment Failure</h5>
                                        <h6 className="mb-4">Please try again</h6>
                                        <Button type="button"  variant="success" className='btn btn-custom-green font-weight-bold' onClick={this.props.retry_Payment}>Pay Again</Button>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}
export default PaymentFailureAlert;
