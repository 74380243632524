import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_rapidRevision.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import LiveClassesModal from './LiveClassesModal';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';

import FileText from "../../../images/images1/FileText.png";
import FileArrowDown from "../../../images/images1/FileArrowDown.png";
//import Group3172 from "../../../images/images1/Group3172.png";

import UserRestrictionAlert from "../home/UserRestrictionAlert";
import LockSimple from "../../../images/LockSimple.png";
import * as Cookies from "es-cookie";
import moment from 'moment';
import { withRouter } from "react-router-dom";

let QuestionDataBotany = [
    {
        DATE: "09-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Molecular Basis of Inheritance - I",

        Learn: ("1,123,0"),
        Practice: ("1,123,0"),
        VideoLecture: ("erTJt-Pj3VU"),
        Zoom: ("https://zoom.us/j/91425239257?pwd=ODVYbEUxNFY2cFlCNlMwVllqSWRlUT09"),
    },
    {
        DATE: "10-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Molecular Basis of Inheritance - II",

        Learn: ("1,123,0"),
        Practice: ("1,123,0"),
        VideoLecture: ("oKSqQDrfcdI"),
        Zoom: ("https://zoom.us/j/97543168806?pwd=RzlkOHljZlVPSkIyd29qaW5zcHR6UT09"),
    },
    {
        DATE: "11-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Principles of Inheritance and variation - I",

        Learn: ("1,122,0"),
        Practice: ("1,122,0"),
        VideoLecture: ("JotU2Kn0xRc"),
        Zoom: ("https://zoom.us/j/92219409876?pwd=a2p3dE9RWFllZGVtWERxQmNMdkw1QT09"),
    },
    {
        DATE: "12-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Principles of Inheritance and variation - II",

        Learn: ("1,122,0"),
        Practice: ("1,122,0"),
        VideoLecture: ("JotU2Kn0xRc"),
        Zoom: ("https://zoom.us/j/94369706242?pwd=OUFzT2NpL29OaEpxQWxZazdsNnNHZz09"),
    },
    {
        DATE: "13-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Biotechnology - Principles and Processes",

        Learn: ("1,126,0"),
        Practice: ("1,126,0"),
        VideoLecture: ("a1XfHcXN98o"),
        Zoom: ("https://zoom.us/j/99227711166?pwd=Rm8xamdXVFVablV3YkxUdmZHRUszZz09"),
    },
    {
        DATE: "14-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Biotechnology and Its Applications",

        Learn: ("1,127,0"),
        Practice: ("1,127,0"),
        VideoLecture: ("pUHhgou2pF8"),
        Zoom: ("https://zoom.us/j/94335448062?pwd=WG4vdHhvTXNCakdtSFhsYy91Ny9MQT09"),
    },
    {
        DATE: "16-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Biomolecules",

        Learn: ("1,7,0"),
        Practice: ("1,7,0"),
        VideoLecture: ("ydiPigjntnc"),
        Zoom: ("https://zoom.us/j/96755071637?pwd=SHM2ditHWGNURmZUUG9wWExIckUvQT09"),
    },
    {
        DATE: "17-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "The living world",

        Learn: ("1,1,0"),
        Practice: ("1,1,0"),
        VideoLecture: ("fgYztolKyoU"),
        Zoom: ("https://zoom.us/j/95933385051?pwd=VTdqalFpMlJRRFdRMGtFR29nMnNQZz09"),
    },
    {
        DATE: "18-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Cell Cycle and Cell Division",

        Learn: ("1,8,0"),
        Practice: ("1,8,0"),
        VideoLecture: ("BKmFEjd-khA"),
        Zoom: ("https://zoom.us/j/94356738588?pwd=dXhOUVFRdUZKSVlLMDZpVHZzMzh6UT09"),
    },
    {
        DATE: "19-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Biological Classification",

        Learn: ("1,2,0"),
        Practice: ("1,2,0"),
        VideoLecture: ("cjO2KpJjwVM"),
        Zoom: ("https://zoom.us/j/95895017162?pwd=Uy9nSXYwYitPTzRxZXNjWVpxazNWUT09"),
    },
    {
        DATE: "20-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Plant Growth and Development",

        Learn: ("1,13,0"),
        Practice: ("1,13,0"),
        VideoLecture: ("2hw_94XhvuY"),
        Zoom: ("https://zoom.us/j/91589214812?pwd=LzhFczhXanpCZHZLaEdBekNNaGNaZz09"),
    },
    {
        DATE: "21-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Photosynthesis in Higher Plants",

        Learn: ("1,11,0"),
        Practice: ("1,11,0"),
        VideoLecture: ("RIslRHRAyKo"),
        Zoom: ("https://zoom.us/j/96642455350?pwd=WWlPL2dMVXRZK0hRMVAwcFc3aUhKdz09"),
    },
    {
        DATE: "23-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Respiration in Plants",

        Learn: ("1,12,0"),
        Practice: ("1,12,0"),
        VideoLecture: ("-q1iFVkaG2A"),
        Zoom: ("https://zoom.us/j/97181290863?pwd=UVUrUzc3NWhTcWNFaTg1MFJYdG0vQT09"),
    },
    {
        DATE: "24-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Sexual Reproduction in Flowering Plants",

        Learn: ("1,121,0"),
        Practice: ("1,121,0"),
        VideoLecture: ("q6KP4qYn5Bo"),
        Zoom: ("https://zoom.us/j/94881931311?pwd=N0dSLzlzaWs0eXBHckM3UGdmdnNyQT09"),
    },
    {
        DATE: "24-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Reproduction in Organism",

        Learn: ("1,120,0"),
        Practice: ("1,120,0"),
        VideoLecture: ("q6KP4qYn5Bo"),
        Zoom: ("https://zoom.us/j/94881931311?pwd=N0dSLzlzaWs0eXBHckM3UGdmdnNyQT09"),
    },
    {
        DATE: "25-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Morphology of flowering plants ",

        Learn: ("1,4,0"),
        Practice: ("1,4,0"),
        VideoLecture: ("uHVj3gDAsEA"),
        Zoom: ("https://zoom.us/j/99315333161?pwd=aXFOVmpZUnNxT1MxV0pZemtxbVI0QT09"),
    },
    {
        DATE: "26-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Microbes in Human Welfare",

        Learn: ("1,125,0"),
        Practice: ("1,125,0"),
        VideoLecture: ("YazUdBfLj3g"),
        Zoom: ("https://zoom.us/j/99636451382?pwd=cnFNcjFDTWRaWGNSaFprcWZTZjFpQT09"),
    },
    {
        DATE: "27-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Anatomy of Flowering plants",

        Learn: ("1,5,0"),
        Practice: ("1,5,0"),
        VideoLecture: ("CP6L04kT23Q"),
        Zoom: ("https://zoom.us/j/96670394469?pwd=d3ZmWUo3MDZMMG53ci9kcy9ISkYrdz09"),
    },
    {
        DATE: "28-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Plant Breeding",

        Learn: ("1,124,0"),
        Practice: ("1,124,0"),
        VideoLecture: ("galLAF_i3cQ"),
        Zoom: ("https://zoom.us/j/94843119757?pwd=Vk5VNXU2cUV4Z3lKbWJWalluNkl4UT09"),
    },
    {
        DATE: "30-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Mineral Nutrition",

        Learn: ("1,10,0"),
        Practice: ("1,10,0"),
        VideoLecture: ("XkuJtRN71ZA"),
        Zoom: ("https://zoom.us/j/94377525883?pwd=TkRVNEI3RUdFb05uaGpXaDdnM1ZhZz09"),
    },
    {
        DATE: "31-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "The Unit of life - I",

        Learn: ("1,6,0"),
        Practice: ("1,6,0"),
        VideoLecture: ("DHJ7glLEzX4"),
        Zoom: ("https://zoom.us/j/95561851238?pwd=b2VyUWQ5MlVsMnlOUmZWdHc0STQyUT09"),
    },
    {
        DATE: "01-09-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "The Unit of life -II",

        Learn: ("1,6,0"),
        Practice: ("1,6,0"),
        VideoLecture: "RC2syCCJ3UM",
        Zoom: ("https://zoom.us/j/98448345173?pwd=Yzk2UFBkTUdlQjZlc1lzSmYyZnlhQT09"),
    }, 
    {
        DATE: "02-09-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Plant Kingdom",

        Learn: ("1,3,0"),
        Practice: ("1,3,0"),
        VideoLecture: "QCrK-JpvzPU",
        Zoom: ("https://zoom.us/j/94682208901?pwd=UStWby9acGh3c3VWTnljMFlJQkF2dz09"),
    },
    {
        DATE: "03-09-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Biomolecules",

        Learn: ("1,7,0"),
        Practice: ("1,7,0"),
        VideoLecture: "SlF3gJHGGds",
        Zoom: ("https://zoom.us/j/94523859690?pwd=RVFwcXZnK2VyN240YjQvZUlLK2NiQT09"),
    },
    {
        DATE: "04-09-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Transport in plants",

        Learn: ("1,9,0"),
        Practice: ("1,9,0"),
        VideoLecture: "_0-7jiAC5HY",
        Zoom: ("https://zoom.us/j/94060615873?pwd=OHlvSjhlQ3IzaCthYVVRcE5TNWhUZz09"),
    },   
    
];

 const sortedActivities = QuestionDataBotany.sort ( function (a, b){
    let date1 = moment(a.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
    let date2 = moment(b.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
    
   if (a.DATE === b.DATE) {
    return a.time - b.time;
}
   return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
});

let count = 0;
var QuestionData = sortedActivities.map((el,index) => {
    var o = Object.assign({}, el);
    if(el.Assignments !="" || el.Solutions !="" ){
        count++;
         o.indexvalue = count
         
    }
    return o;
  });
  
let LiveClassesDataBotany = []

    for(var i in QuestionData){
        let currentDate = moment().format("DD/MM/YYYY");
        if ((QuestionData[i]["VideoLecture"]  || QuestionData[i]["Zoom"]) && (currentDate ==  moment(QuestionData[i].DATE,"DD-MM-YYYY").format("DD/MM/YYYY"))) {
            LiveClassesDataBotany.push(QuestionData[i]);
          }
    }
	
var time;
var Zoom;
var Livevideo;


class BotanyDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            dataLiveUrl: "",
            userRestionModalShow: false,
			allLiveClasses:true

        }

        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));

        }
        
    }
    modalaFun = (row) => {
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });

    }
    
    
    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }
    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    sortFuncDate(a, b, order, dataField, rowA, rowB)  { 
        if (order == 'desc') {
            
            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY")  > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY")  > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }
    
    getAccessPlans = (planId)  => {
        const epriryDateInunixTime = this.isStudentUserValid.expiry_date;
        const currentDateInUnixTime = parseInt((new Date().getTime() / 1000).toFixed(0))

        // console.log("exU",epriryDateInunixTime)
        // console.log("cU",currentDateInUnixTime)
        // console.log("eeeeee",this.isStudentUserValid)
        if(planId == 5){
            return true; //restrict
        }else{

            if(currentDateInUnixTime > epriryDateInunixTime){
                return true; 
            }
        }

        return false
    }
   

    learnFormateAction = (row) => {
        
        let practiceIds = row.split(',');
        //console.log("practiceIds",practiceIds)
        this.props.history.push({
            pathname: "/student/subject/start-learning",
            state: {
              subjectid: practiceIds[0],
              ocid: practiceIds[1],
              otid: practiceIds[2]
            }
          }
        )
    }
    actionFormatterLearn(cell, row, rowIndex){
        return (
            <>
            {
                row.Learn != "" ?  (row.indexvalue > 3 && this.getAccessPlans(this.isStudentUserValid.current_plan_id) ) ? 
                
                    (<div className="learnTextLock">
                        <a onClick={() => this.modalFunAssignments()}> <span>Learn Chapter</span></a>
                    </div>) :
                    (<div className="learnText">
                        <a onClick={() => this.learnFormateAction(row.Learn)} target="_blank"> Learn Chapter</a>
                    </div>) : <h6 className="text-center">-</h6>
            }
            </>
        )
    }
    practiceFormateAction = (row) => {
        
        let practiceIds = row.split(',');
        //console.log("practiceIds",practiceIds)
        localStorage.setItem("subjectid", practiceIds[0]);
        localStorage.setItem("type", "practice");
        //localStorage.setItem("ocid", "0");
        localStorage.setItem("ocid", practiceIds[1]);
        localStorage.setItem("otid", practiceIds[2]);
        window.open("/student/subject/practice-test", "_blank") //to open new page
    }
    actionFormatterPractice(cell, row, rowIndex){
        return (
            <>
            {
                row.Practice != "" ?  (row.indexvalue > 3 && this.getAccessPlans(this.isStudentUserValid.current_plan_id) ) ? 
                
                    (<div className="practiceTextLock">
                        <a onClick={() => this.modalFunAssignments()}> <span>Practice Here</span></a>
                    </div>) :
                    (<div className="practiceText">
                        <a   onClick={() => this.practiceFormateAction(row.Practice)}> Practice Here </a>
                    </div>) : <h6 className="text-center">-</h6>
            }
            </>
        )
    }
    actionFormatterVideoLecture(cell, row, rowIndex) {
        let currentDate = moment().format("DD-MM-YYYY");
        let currentMonth = moment().format('MM')
        let currentRowMonth = moment(row.DATE,"DD-MM-YYYY").format("MM") 
        console.log("currentMonth",currentMonth)
        console.log("rowMonth",currentRowMonth)
        
        return (

            <>
                <div className="text-center">
                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                    {/* {
                        currentMonth == currentRowMonth ? 
                        (
                            currentDate == row.DATE ? 
                        (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) :
                        (
                            row.DATE < currentDate  ? 
                            (<button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>) 
                            :
                            
                            (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                        )
                        ) 
                        :
                        (
                            currentDate == row.DATE ? 
                            (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) :
                            (
                                row.DATE > currentDate  ? 
                                :
                                
                                (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                            )
                        )

                        
                    } */}
                </div>
            </>
            // <>
            //     <div className="text-center">
            //         {
            //             row.Zoom == "" ?

            //                 row.VideoLecture != "" ?
            //                     (<button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>)
                            
            //                     : "-"


            //                 :
            //                 <>
                            
            //                     {
            //                         currentDate == row.DATE ? 
            //                         (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) 
            //                         :
            //                         (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                                    
            //                     }
                                



            //                 </>
            //         }

            //     </div>

            // </>
        )
    }

    colFunction() {

        let columns = [
            {
                dataField: "DATE",
                text: "Date",
                sort: true,
                headerStyle: {
                    width: "12%"
                },
                style: {
                    fontWeight: "bold !important"
                },
                sortFunc: this.sortFuncDate.bind(this)
            },
            {
                dataField: "ClassTimings",
                text: "Class Timings",
                sort: true,
                headerStyle: {
                    width: "22%"
                },
                sortFunc: this.sortFuncMulti.bind(this)
            },

            {
                dataField: "Subject",
                text: "Subject",
                style: (row, rowIndex) => {
                    return { fontWeight: "bold !important" };
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                width: "25%"
                }
            },
            {
                dataField: "Learn",
                text: "Learn",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterLearn.bind(this)
            },
            {
                dataField: "Practice",
                text: "Practice",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterPractice.bind(this)
            },
           
            {
                dataField: "VideoLecture",
                text: "Video Lecture",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterVideoLecture.bind(this),
                
            },
            
        ];
        return columns;
    }

    render() {
         return (
            <Row>
                <Col align="right" sm={12}>
                    <h6 className="switch_button_cls mt-res-4">
                        <p className="allClassesAlignment">All Classes</p>
                        <label class="switch">
                            <input type="checkbox" 
                                onChange={() => {
                                    
                                    this.setState((state) => {
                                        
                                        return {
                                            allLiveClasses: !state.allLiveClasses
                                        }
                                    })
                                }}
                                checked={!this.state.allLiveClasses} />
                            <span class="slider round"></span>
                        </label>
                        <p className="liveClassesAlignment">Live</p>
                    </h6>
                </Col>
                <Col sm={12}>
                {
                    this.state.allLiveClasses ?
                    <CardLessDataTableWithSearch 
                    parentData={QuestionData}
                    particlecolumns={this.colFunction()}
                    defaultSorted={[{
                        dataField: "ClassTimings",
                        order: "asc"
                    }]}
                    />
                    :
                    <CardLessDataTableWithSearch 
                    parentData={LiveClassesDataBotany}
                    particlecolumns={this.colFunction()}
                    defaultSorted={[{
                        dataField: "ClassTimings",
                        order: "asc"
                    }]}
                    />
                }
                    {/* <Card>
                        <BootstrapTable
                            keyField="id"
                            columns={this.colFunction()}
                            data={QuestionData}
                            defaultSorted={[{
                                dataField: "ClassTimings",
                                order: "asc"
                            }]}
                            wrapperClasses="table-responsive"
                            bootstrap4

                        />
                    </Card> */}

                    <LiveClassesModal data={this.state.dataLiveUrl} show={this.state.liveModalShow} onHide={() => this.setState({ liveModalShow: false })} />
                    <UserRestrictionAlert
                        show={this.state.userRestionModalShow}
                        onHide={() => this.setState({ userRestionModalShow: false })}
                    />
                </Col>
            </Row>

        )
    }

}
export default withRouter (BotanyDataSection);