import React, { Component } from 'react'
import { Modal, Card, Button } from "react-bootstrap";
import "../home/_subscribe-plans.scss";
import { withRouter, Link } from "react-router-dom";
import * as Cookies from "es-cookie";
import moment from 'moment';

class QuestionAnswerViewModal extends Component {
    constructor(props) {
        super(props)
       
        this.state = {
           
        }
    }
    

    render() {
        
        console.log("aaa",this.props.questionAnswerViewTime)

        return (
            <Modal {...this.props}
                size="md" aria-labelledby="example-modal-sizes-title-lg" className="scribePlan-modal">
                <Button variant="link p-0 close" onClick={() => { this.props.onHide() }}> <i className="fas fa-times" /> </Button>

                <Modal.Body>
                    <div className="text-center mb-4">
                        <h5 className="text-uppercase text-blue font-weight-normal mb-1">Solutions for this Test will be available after</h5>
                        <h3 className='mt-4'>{moment.unix(this.props.questionAnswerViewTime).format("LTS")}</h3>
                    </div>
                    
                </Modal.Body>
            </Modal>
        )
    }
}
export default withRouter(QuestionAnswerViewModal);

