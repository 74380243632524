import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Nav, Navbar, Button } from 'react-bootstrap'
import './_navbars.scss'
import moment from 'moment'
import * as Cookies from "es-cookie";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import "hacktimer";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";


let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}
const AUTO_SUBMIT_TIME = gql` 
query($mobile: String!,$exam_paper_id:Int!) {
    getAutoSubmitTime(mobile: $mobile,exam_paper_id:$exam_paper_id){
        auto_submit_time
    }
}
`;
class MockExamNavbar extends Component {

    constructor(props) {
        super(props);

        console.log("eeeeeeeeeeeee", this.props.examInstructions)
        let totalExamCount = "";
        let splitHeadingName = this.props.getData.type.split("(");
        let local = localStorage.getItem("resumeExam");

        if (this.props.examInstructions.exam_pattern_id > 0) {
            if ((local == true && !!props.getAutoSubmitTime.getAutoSubmitTime && props.getAutoSubmitTime.getAutoSubmitTime.length > 0) || (!!props.resumeExam && props.resumeExam && !!props.getAutoSubmitTime.getAutoSubmitTime && props.getAutoSubmitTime.getAutoSubmitTime.length > 0)) {
                if (parseInt(props.getAutoSubmitTime.getAutoSubmitTime[0].auto_submit_time) == 0) {
                    props.totTimeFunction();
                } else {
                    totalExamCount = parseInt(props.getAutoSubmitTime.getAutoSubmitTime[0].auto_submit_time);
                }
            } else if (!!props.getAutoSubmitTime.getAutoSubmitTime && props.getAutoSubmitTime.getAutoSubmitTime.length == 0 && !!props.getAutoSaveExam && props.getAutoSaveExam.length > 0) {
                totalExamCount = parseInt(props.getAutoSaveExam[0].examtimer);
            } else if (!!props.getAutoSubmitTime.getAutoSubmitTime && props.getAutoSubmitTime.getAutoSubmitTime.length > 0 && props.getData.type != 'Custom Exam') {
                if (parseInt(props.getAutoSubmitTime.getAutoSubmitTime[0].auto_submit_time) == 0) {
                    props.totTimeFunction();
                } else {
                    totalExamCount = parseInt(props.getAutoSubmitTime.getAutoSubmitTime[0].auto_submit_time);
                }
            } else {
                totalExamCount = parseInt(props.examInstructions.exam_duration_min * 60);
            }
        } else {
            if ((local == true && !!props.getAutoSubmitTime.getAutoSubmitTime && props.getAutoSubmitTime.getAutoSubmitTime.length > 0) || (!!props.resumeExam && props.resumeExam && !!props.getAutoSubmitTime.getAutoSubmitTime && props.getAutoSubmitTime.getAutoSubmitTime.length > 0)) {
                if (parseInt(props.getAutoSubmitTime.getAutoSubmitTime[0].auto_submit_time) == 0) {
                    props.totTimeFunction();
                } else {
                    totalExamCount = parseInt(props.getAutoSubmitTime.getAutoSubmitTime[0].auto_submit_time);
                }
            } else if (!!props.getAutoSubmitTime.getAutoSubmitTime && props.getAutoSubmitTime.getAutoSubmitTime.length == 0 && !!props.getAutoSaveExam && props.getAutoSaveExam.length > 0) {
                totalExamCount = parseInt(props.getAutoSaveExam[0].examtimer);
            } else if (!!props.getAutoSubmitTime.getAutoSubmitTime && props.getAutoSubmitTime.getAutoSubmitTime.length > 0 && props.getData.type != 'Custom Exam') {
                if (parseInt(props.getAutoSubmitTime.getAutoSubmitTime[0].auto_submit_time) == 0) {
                    props.totTimeFunction();
                } else {
                    totalExamCount = parseInt(props.getAutoSubmitTime.getAutoSubmitTime[0].auto_submit_time);
                }
            }
            else if (props.getPracticeQuestions[0].mains_2021 == "1") {
                totalExamCount = parseInt(props.exam_time) * parseInt(props.getPracticeQuestions.length - 15);
            }
            else if (props.getPracticeQuestions[0].mains_2021 == "0" && this.props.examInstructions != undefined && this.props.examInstructions.exam_model_id == "2") {
                totalExamCount = parseInt(props.exam_time) * parseInt(props.getPracticeQuestions.length - 20);
            }
            else if (props.examInstructions != undefined && props.examInstructions.is_partexam == 1 || props.examInstructions != undefined && props.examInstructions.exam_model_type_id == 4) {
                totalExamCount = parseInt(props.examInstructions.time_duration * 60);
            }
            else {
                totalExamCount = parseInt(props.exam_time) * parseInt(props.getPracticeQuestions.length);
            }
        }



        this.state = {
            timerStatus: false,
            totalExamCount: totalExamCount,
            oldCount: totalExamCount,
        }
        this.handleChangeinTime = this.handleChangeinTime.bind(this);
    }


    timeFunction = () => {
        console.log("tottime", this.state.timerStatus);
        if (this.state.timerStatus == false) {
            let totalExamCount = parseInt(this.state.totalExamCount) - 1;
            console.log("tottimestatus", totalExamCount);
            if (totalExamCount == 0) {
                this.setState({
                    timerStatus: true,
                    totalExamCount: totalExamCount
                });
                this.props.totTimeFunction();
            }
            else {
                this.setState({
                    totalExamCount: totalExamCount
                });
                if (this.props.totalExamCount) {
                    this.props.totalExamCount(totalExamCount)
                }
            }
        }
    }
    timeFunctionNew = () => {
        console.log("tottime", this.state.timerStatus);
        if (this.state.timerStatus == false) {
            let totalExamCount = parseInt(this.state.totalExamCount) - 1;
            console.log("tottimestatus", totalExamCount);
            if (totalExamCount == 0) {
                this.setState({
                    timerStatus: true,
                    totalExamCount: totalExamCount
                });
                this.props.totTimeFunction();
            }
            else {
                this.setState({
                    totalExamCount: totalExamCount
                });
                if (this.props.totalExamCount) {
                    this.props.totalExamCount(totalExamCount)
                }
            }
        }
    }

    componentDidMount = () => {
        if (this.state.totalExamCount == 0) {
            this.props.ParenthandleFormSubmit();
        }
        this.timer = setInterval(() => {
            this.timeFunction();
        }, 1000);
        //document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    }

    handleVisibilityChange = () => {
        if (document[hidden]) {
            this.timer1 = setInterval(() => {
                this.timeFunctionNew();
            }, 1000);
        } else {
            clearInterval(this.timer1);
        }
    }
    componentWillUnmount() {
        document.removeEventListener(visibilityChange, this.handleVisibilityChange);
    }

    minutesTimer = (data) => {
        var date = new Date(data * 1000);
        var hh = date.getUTCHours();
        var mm = date.getUTCMinutes();
        var ss = date.getSeconds();
        if (hh < 10) { hh = "0" + hh; }
        if (mm < 10) { mm = "0" + mm; }
        if (ss < 10) { ss = "0" + ss; }
        var t = hh + ":" + mm + ":" + ss;

        return t;
    }
    handleChangeinTime = (getAutoSubmitTime) => {
        if (getAutoSubmitTime.getAutoSubmitTime && getAutoSubmitTime.getAutoSubmitTime.length > 0) {
            let totalExamCount = parseInt(getAutoSubmitTime.getAutoSubmitTime[0].auto_submit_time);
            if (this.state.oldCount == this.state.totalExamCount && totalExamCount != this.state.totalExamCount && this.props.getData.type != 'Custom Exam') {
                this.setState({
                    totalExamCount: totalExamCount,
                });
            }
        }
    }


    render() {
        const getStudentTestSeriesNewNTAPattern = this.props.getStudentTestSeriesNewNTAPattern;
        console.log("resul", getStudentTestSeriesNewNTAPattern);

        const getAutoSubmitTime = this.props.getAutoSubmitTime;
        const loading6 = getAutoSubmitTime.loading;
        const error6 = getAutoSubmitTime.error;

        this.handleChangeinTime(getAutoSubmitTime);

        return (
            <Navbar bg="white" className="examtop-header header-top shadow-sm" expand="lg">

                <Container>
                    <div className="nav-brand">
                        <Button variant="light" className="px-2 py-1 mr-2" onClick={() => this.props.onClick()}>
                            <i className="fal fa-bars" />
                        </Button>
                    </div>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <div className="timecounter">
                                <i className="fas fa-clock mr-2" /> Time Left : {this.minutesTimer(this.state.totalExamCount)}
                            </div>
                        </Nav>
                        <Nav className="mx-auto">
                            <h6 className="title text-muted">{this.props.getData.type}</h6>
                        </Nav>
                        <Nav className="ml-auto">
                            {this.props.getPracticeQuestions != "" ? (
                                <React.Fragment>
                                    {/* <Button variant="light" style={{ width: 180, borderRadius: 25 }} onClick={this.props.ParenthandleFormSubmit}>
                                         <i className="fas fa-pause text-danger mr-2" /> Complete Exam
                                </Button> */}
                                    <Button
                                        variant="outline-success"
                                        style={{ fontweight: "bold", width: 180, borderRadius: 25 }}
                                        onClick={this.props.ParenthandleFormSubmit}>
                                        Submit Exam
                                    </Button>
                                </React.Fragment>

                            ) : ("")}
                        </Nav>
                    </Navbar.Collapse>
                    <Nav className="mx-auto d-block d-xl-none d-lg-none text-right">
                        <div className="text-center"> <h6 className="title text-muted">{this.props.getData.type}</h6></div>
                        <div className="d-flex align-items-center">
                            <div className="timecounter mr-3">
                                <i className="fas fa-clock mr-2" /> Time Left : {this.minutesTimer(this.state.totalExamCount)}
                            </div>
                            {this.props.getPracticeQuestions != "" ? (
                                <React.Fragment>
                                    {/* <Button variant="light" style={{ width: 180, borderRadius: 25 }} onClick={this.props.ParenthandleFormSubmit}>
                                         <i className="fas fa-pause text-danger mr-2" /> Complete Exam
                                </Button> */}
                                    <Button
                                        variant="outline-success"
                                        style={{ fontweight: "bold", width: 180, borderRadius: 25 }}
                                        onClick={this.props.ParenthandleFormSubmit}>
                                        Submit Exam
                                    </Button>
                                </React.Fragment>

                            ) : ("")}
                        </div>
                    </Nav>
                    <Nav className="mx-auto">
                        <h6 className="font-weight-bold ml-5"><span className={this.props.stateData.fontSizeIncreaseA == "active" ? "round-circle-FS active font-AA" : "round-circle-FS font-AA"} onClick={(e) => this.props.pfontSizeIncrease(e, "A")}>A</span> <span className={this.props.stateData.fontSizeIncreaseAA == "active" ? "round-circle-FS active font-AAA" : "round-circle-FS font-AAA"} onClick={(e) => this.props.pfontSizeIncrease(e, "AA")}>A<sup>+</sup></span></h6>
                    </Nav>

                </Container>
            </Navbar>
        )
    }
}

export default withRouter(
    compose(graphql(AUTO_SUBMIT_TIME, {
        options: props => ({
            variables: {
                mobile: Cookies.get("mobile"),
                exam_paper_id: parseInt(localStorage.getItem('exam_paper_id'))
            }
            ,
            fetchPolicy: "cache-and-network"
        }),
        name: "getAutoSubmitTime"
    }))(MockExamNavbar));