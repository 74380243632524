import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import ChapterSection from '../components/learn_practice/chapters/ChapterSection'
import ChepterHeaderSection from '../components/learn_practice/top_header/ChepterHeaderSection';
import * as Cookies from "es-cookie";
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";

const FETCH_ISSTUDENTUSERVALID = gql` 
query($mobile: String) {
    isStudentUserValid(mobile: $mobile){
        estatus
        current_plan_id
        expiry_date
        app_version
        user_access_restictions
     }
}

`;

class Chapter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: "wrapper sidebar-enable"

        }
    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
         if (toggled === "wrapper") {
             this.setState({toggled:"wrapper sidebar-enable"});
             Cookies.set("toggle", "wrapper sidebar-enable");
         } else {
             this.setState({toggled:"wrapper"});
             Cookies.set("toggle", "wrapper");
         }
     };
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");
        console.log("Chapter", this.props.history.location.state);

        const subjects = this.props.subjects;
        const loading1 = subjects.loading;
        const error1 = subjects.error;

        const isStudentUserValid = this.props.isStudentUserValid;
        const loading5 = isStudentUserValid.loading;
        const error5 = isStudentUserValid.error;

        // if (loading1) return null;
        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        if (error5 !== undefined) {
            alert("Server Error. " + error5.message);
            return null;
        }
        //console.log("getUserExplored", isStudentUserValid.isStudentUserValid);
        if (isStudentUserValid.isStudentUserValid != undefined) {
            if (isStudentUserValid.isStudentUserValid.estatus == 0) {
                Cookies.remove("token");
                Cookies.remove("username");
                Cookies.remove("refreshtoken");
                Cookies.remove("email");
                Cookies.remove("id");
                Cookies.remove("institutionid");
                Cookies.remove("userlevel");
                Cookies.remove("name");
                this.props.history.push("/student/login");
            }
        }
        let subjectsData = "";
        if (subjects.getSubjects != undefined) {
            subjectsData = subjects.getSubjects.find((a) => a.id == this.props.history.location.state.subjectid);

        }
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper">
                    {(loading1 == true || loading5 == true) && (<PreloaderTwo />)}
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper">
                        {
                            !loading1 && !loading5 && (
                                <React.Fragment>
                                    <ChepterHeaderSection
                                        subjectsData={subjectsData}
                                        getData={this.props.history.location.state != undefined ? this.props.history.location.state : ""} />
                                    <Container fluid={true}>
                                        <ChapterSection
                                            isStudentUserValid={isStudentUserValid.isStudentUserValid}
                                            getData={this.props.history.location.state != undefined ? this.props.history.location.state : ""}
                                        />
                                    </Container>
                                </React.Fragment>
                            )
                        }

                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(compose(
    graphql(gql` 
    query($mobile: String,
        $exam_id: Int,
        $class_id: Int) {
        getSubjects(mobile: $mobile,
        exam_id: $exam_id,
        class_id: $class_id){
            id
            subject
            studentChapters{
                id
                chapter
            }
            practice_percentage
            last_attempted_chapter
            last_timestamp
            accuracy
        }
    }
    `,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_id: parseInt(Cookies.get("examid")),
                    class_id: parseInt(Cookies.get("classid"))
                },
                fetchPolicy: 'cache-and-network'
            }), name: "subjects"
        }),
    graphql(FETCH_ISSTUDENTUSERVALID,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: 'cache-and-network'
            }), name: "isStudentUserValid"
        }))(Chapter));
