import React, { Component } from 'react';
import { Row, Col, Container, Image, Media, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';
import * as Cookies from "es-cookie";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import * as compose from 'lodash.flowright';
import './_eamcetLongTermCourseHome.scss';
import Carousel from 'react-bootstrap/Carousel';

const getURL = gql`
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String
    ){
        getServiceData111(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type){
            url
            title
            url
            course_content
            }
        }
    `;


class EamcetCrashCourseBlockSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blockType: ""
        }
    }

    componentDidMount = () => {
        const title = GoogleAnalyticsArray[0].Eamcet_Crash_Course;
        ReactGA.pageview('/student/eamcet-longterm-course/home', ["rizee"], title);
    }

    stopVideoPlay = () => {
        var videos = document.querySelectorAll('iframe, video');
        Array.prototype.forEach.call(videos, function (video) {
            if (video.tagName.toLowerCase() == 'video') {
                video.pause();
            } else {
                var src = video.src;
                video.src = src;
            }
        });

    }


    render() {

        const getServiceData111 = this.props.getServiceData111;
        const loading = getServiceData111.loading;
        const error = getServiceData111.error;

        if (error !== undefined) {
            alert("Server Error. " + error.message);
            return null;
        }

        let videoUrls = [];
        let title = '';
        let course_content = [];
        let nav_course_content = [];

        if (loading != true) {
            title = getServiceData111.getServiceData111.length > 0 && getServiceData111.getServiceData111[0].title;
            videoUrls = getServiceData111.getServiceData111.length > 0 && getServiceData111.getServiceData111[0].url != null && getServiceData111.getServiceData111[0].url.split(',');
            course_content = getServiceData111.getServiceData111.length > 0 && getServiceData111.getServiceData111[0].course_content;
            nav_course_content = getServiceData111.getServiceData111.length > 0 && getServiceData111.getServiceData111[0].course_content.filter((item, index) => index == 2);
        };

        return (
            <section className="longtermcourse_home">
                {
                    loading == true ?
                        (
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <Image src={require('../../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                    </Col>
                                </Row>
                            </Container>
                        )
                        :
                        (
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className='white_pad25 mt-4'>
                                            <Row >
                                                <Col sm={6}>
                                                    <h5 className='over_view'>OVERVIEW</h5>
                                                    <p className='course_des'>{title}</p>
                                                </Col>
                                                <Col sm={6}>
                                                    <Carousel interval={false} nextIcon={<span aria-hidden="true" className="fas fa-chevron-right f-22 color_black" onClick={() => this.stopVideoPlay()} />} prevIcon={<span aria-hidden="true" className="fas fa-chevron-left f-22 color_black m_let_30" onClick={() => this.stopVideoPlay()} />}>
                                                        {
                                                            videoUrls.length > 0 ?
                                                                (
                                                                    videoUrls.map(item => {
                                                                        let embdUrl = item.split('/');
                                                                        return (
                                                                            <Carousel.Item >
                                                                                <iframe className='video_block'
                                                                                    src={`https://www.youtube.com/embed/${embdUrl[embdUrl.length - 1]}`}
                                                                                    allowFullScreen
                                                                                >
                                                                                </iframe>

                                                                            </Carousel.Item>
                                                                        )
                                                                    })
                                                                )
                                                                :
                                                                ("")
                                                        }
                                                    </Carousel>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h5 className='mt-3 ml-2'>Course Details</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        {
                                            course_content.filter((item, index) => index != 2).map((item1, index1) => {
                                                let description = item1.Description.split('#');
                                                let navurl = index1 == 0 ? '/student/eamcet-longterm-course/liveclasses' : '/student/eamcet-longterm-course/exams';
                                                return (
                                                    <div className='white_pad25 second-module mt-3'>
                                                        <Row>
                                                            <Col sm={8}>
                                                                {
                                                                    description.map((item2) => {
                                                                        return (
                                                                            <ul className='custom_li_alignment'>
                                                                                <li className='course_des'>{item2}</li>
                                                                            </ul>
                                                                        )
                                                                    })
                                                                }
                                                                <Link to={navurl}>
                                                                    <Button type="button" className='found_btn mt-4 center-btn'>{item1.button_name}</Button>
                                                                </Link>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <Image className="found_img" src={item1.image_path} alt="images" />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                            nav_course_content.length > 0 ? (
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <h5 className='nav-link-title mt-3 ml-2'>{nav_course_content[0].Title}</h5>
                                                        </Col>
                                                    </Row>
                                                    <div className=' white_pad_img  mt-3'>
                                                        {
                                                                nav_course_content[0].modules.map((item) => {
                                                                    let link = item.link.replace('https://rizee.in/student/action/', '');
                                                                    return (
                                                                            <Link to={{
                                                                                pathname: `/student/action/${link}`,
                                                                            }} >
                                                                                <Image src={item.image} alt="Images"  className='nav-link-image'/>
                                                                            </Link>

                                                                    )
                                                                })
                                                            }
                                                    </div>
                                                </>
                                            ) : ("")
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        )
                }
            </section >
        )
    }
}
export default withRouter(withApollo(compose(
    graphql(getURL, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                service_type: "Eamcet_Long_Term_Course",
                service_sub_type: "ALL"
            },
            fetchPolicy: "network-only",
        }), name: "getServiceData111"
    })
)(EamcetCrashCourseBlockSection)));