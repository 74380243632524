import React, { Component } from 'react'
import * as Cookies from "es-cookie";
import AsideNavbar from '../components/navbars/AsideNavbar'
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import VideosHomeSection from '../components/category_wise_videos/VideosHomeSection'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'
import Navbars from '../components/navbars/Navbars';
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import * as compose from 'lodash.flowright';

const UPDATE_STUDENT_ANALYTICS = gql`
  mutation(
    $params:AnalyticsStudents1  
    ) {
        studentAnalytics(
        params: $params
     )
  }
`;

class CategoryWiseVideos extends Component {
    constructor(props) {
        super(props)

        this.state = {
            headerBottomImg: {
                Img: require('../../images/Vieos Image.png'),
                Title: "Watch & Learn",
                width: 100,
                helpImg: require('../../images/Ask_me_for_Help.gif'),
                pageType: "CategoryVideos"

            }
        }
    }

    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    componentDidMount = () => {

        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Watch_And_learn_Home;
        ReactGA.pageview('/student/category_wise_videos', ["rizee"], title);
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 159,
            source :2

        }
        console.log("addStudentObj",addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} pageType={"categoryVideos"} />
                </div>
                <div className="main-wrapper">

                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0 customCategoryVideos" style={{ minHeight: "50vh" }}>
                        <VideosHomeSection />
                    </div>
                </div>
            </div>

        )

    }


}

export default withRouter(compose(
  
        graphql(UPDATE_STUDENT_ANALYTICS, {
            name: "submitpt"
        }),
        
        )(CategoryWiseVideos));
