import React, { Component } from 'react'
import { Container, Row, Col, Card, Button, Badge } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import * as Cookies from "es-cookie";
import PLAYICON from  '../../../../images/video-play.png';

import '../_categorywisevideos.scss';

class AllVideosSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            subjectId: '',
            subjectWiseCount: []
        }
    }

    handleSelect = (subject) => {


        this.setState({
            subjectId: subject
        });

    }

    subjectClass = (subject) => {
        let classname = "";
        if (subject == this.state.subjectId) {
            classname = "active"
        }

        return classname;
    }
    checkforSubjects = () => {
        const videosData = this.props.subCategoryWiseData.subCategoryNameType.CustomVideosList;
        let filtered = videosData.filter(item => item.subject == 0)
        console.log("videosData1", filtered)
        return this.props.subCategoryWiseData.subCategoryNameType.CustomVideosList.length == filtered.length;
    }
    render() {

        const subCategoryWiseData = this.props.subCategoryWiseData;
        console.log("sub", subCategoryWiseData);
        const loading1 = subCategoryWiseData.loading;
        const error1 = subCategoryWiseData.error;


        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        const videosData = subCategoryWiseData.subCategoryNameType.CustomVideosList;
        console.log("videos123", subCategoryWiseData.mainCategoryName)
        this.state.subjectWiseCount = [];

        videosData.filter((item, indexVal) => item.subject == this.state.subjectId)
            .map((subjectsCount, index) => {
                this.state.subjectWiseCount.push(subjectsCount);
            })


        if (loading1) {
            return (<Container><Row className="my-3">
                <Col>
                    <Card as={Card.Body} className="free-mock justify-content-center flex-row">

                        <div class="spinner-border text-primary text-center"></div>

                    </Card>
                </Col>
            </Row></Container>)
        } else {
            return (
                <Container>
                    <Row>
                        <Col sm={6} className="mt-res-3rem">
                            <ul className="list-inline breadcrumb-nav ">
                                <li>
                                    <Link to={{ pathname: "/student/category_wise_videos" }}>
                                        <span>Watch & Learn</span> <i class="fas fa-chevron-right"></i></Link>
                                </li>
                                {
                                    subCategoryWiseData.mainCategoryName != "Mentorship" ? (
                                        subCategoryWiseData.mainCategoryName != "Live Classes"  ?
                                         (<li onClick={this.props.history.goBack}><span>{subCategoryWiseData.mainCategoryName}</span> <i class="fas fa-chevron-right"></i></li>)
                                        :
                                        ("")
                                        
                                    ):(
                                        subCategoryWiseData.mainCategoryName != "Live Classes"  ? 
                                        (<li style={{ cursor: "auto" }}><span>{subCategoryWiseData.mainCategoryName}</span> <i class="fas fa-chevron-right"></i></li>)
                                        :
                                        ("")
                                        
                                    )
                                }
                                {
                                    subCategoryWiseData.subCategoryNameType.category != "Mentorship" ?(
                                        subCategoryWiseData.mainCategoryName == "Live Classes"  ? 
                                        (<li onClick={this.props.history.goBack}><span>{subCategoryWiseData.subCategoryName}</span> <i class="fas fa-chevron-right"></i></li>) 
                                        : 
                                        (<li style={{ cursor: "auto" }}><span>{subCategoryWiseData.subCategoryName}</span> <i class="fas fa-chevron-right"></i></li>)
                                        
                                    ):("")
                                }
                            </ul>
                        </Col>
                    

                        <Col sm={6} className="mt-res-2rem">
                        {
                                !this.checkforSubjects() ? (<Col sm={12} className="">
                                    <ul className="list-inline subjects-custom-cls float-right">
                                        <li className={this.subjectClass('')} onClick={() => this.handleSelect('')}>All</li>
                                        {
                                            Cookies.get("examid") != 2 ? (
                                                <>
                                                    <li className={this.subjectClass(1)} onClick={() => this.handleSelect(1)}>Botany</li>
                                                </>
                                            )
                                                : ("")
                                        }
                                        <li className={this.subjectClass(2)} onClick={() => this.handleSelect(2)}>Physics</li>
                                        <li className={this.subjectClass(3)} onClick={() => this.handleSelect(3)}>Chemistry</li>
                                        {
                                            Cookies.get("examid") != 1 ? (<li className={this.subjectClass(4)} onClick={() => this.handleSelect(4)}>Maths</li>)
                                                : ("")
                                        }

                                        {
                                            Cookies.get("examid") != 2 ? (
                                                <>
                                                    <li className={this.subjectClass(5)} onClick={() => this.handleSelect(5)}>Zoology</li>
                                                </>
                                            )
                                                : ("")
                                        }

                                    </ul>

                                </Col>) : ''
                            }
                        </Col>



                    </Row>

                    {/* {
                        subCategoryWiseData.subCategoryNameType.CustomVideosList.length > 0 ? (
                            <>
                                <Row>
                                    <Col className="mt-4">
                                        {
                                            this.state.subjectId == '' ?
                                                (
                                                    <h5> <span className="total-videos-count font-18">{subCategoryWiseData.subCategoryNameType.CustomVideosList.length} Videos1 : </span>   </h5>
                                                ) : (
                                                    <h5> <span className="total-videos-count font-18">{this.state.subjectWiseCount.length} Videos : </span>  </h5>
                                                )
                                        }

                                    </Col>
                                </Row>
                            </>
                        )
                            : ("")
                    } */}

                    <Row className="mt-2">
                        {
                            this.state.subjectId == '' ?
                                (
                                    subCategoryWiseData.subCategoryNameType.CustomVideosList.length > 0 ? (
                                        subCategoryWiseData.subCategoryNameType.CustomVideosList.map((videosUrls, index) => (
                                            <Col sm={4} className="mt-4">
                                                    <Link to={{
                                                        pathname: "/student/subject/start-video-watching",
                                                        state: {
                                                            index: index,
                                                            videosList: subCategoryWiseData.subCategoryNameType.CustomVideosList,
                                                            type: "iv",
                                                            mobile: Cookies.get("mobile"),
                                                            urltype: "categoryWiseVideo"

                                                        }
                                                    }}>
                                                        {videosUrls.thumbnail != "" ? (<>
                                                            {/*
                                                                videosUrls.is_live == "1" ?
                                                                    (
                                                                        <span className="is_live_icon"><img src="https://student.rizee.in/static/media/pulse.1319bdcb.png" alt="New" class="ml-2" width="20" /></span>
                                                                    )
                                                                    :
                                                                    ("")

                                                                    */}

                                                            <span className="play-icon-video"><img className="image-style-cls w-100"  src={videosUrls.thumbnail} alt={videosUrls.title} /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /></span>        
                                                        </>) : (
                                                            <span className="play-icon-video"><img  className="image-style-cls w-100" src={"https://source.unsplash.com/M185_qYH8vg/1780x1040"} alt="img" /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /> </span>
                                                        )}

                                                    </Link>

                                                    <h6 className="f-16 mt-2">{videosUrls.title}</h6>
                                                </Col>
                                            

                                        ))
                                    ) : (<Col sm={12}><h6>No Videos Available</h6></Col>)
                                )
                                :
                                (

                                    this.state.subjectWiseCount.length > 0 ?
                                        (
                                            subCategoryWiseData.subCategoryNameType.CustomVideosList.length > 0 ? (
                                                subCategoryWiseData.subCategoryNameType.CustomVideosList.map((videosUrls, index) => {
                                                    if (videosUrls.subject == this.state.subjectId) {
                                                        return (
                                                            <Col sm={4} className="mt-4">
                                                                    <Link to={{
                                                                        pathname: "/student/subject/start-video-watching",
                                                                        state: {
                                                                            index: index,
                                                                            videosList: subCategoryWiseData.subCategoryNameType.CustomVideosList,
                                                                            type: "iv",
                                                                            mobile: Cookies.get("mobile"),
                                                                            urltype: "categoryWiseVideo"

                                                                        }
                                                                    }}>
                                                                        {videosUrls.thumbnail != "" ? (<>
                                                                            {/*
                                                                                videosUrls.is_live == "1" ?
                                                                                    (
                                                                                        <span className="is_live_icon"><img src="https://student.rizee.in/static/media/pulse.1319bdcb.png" alt="New" class="ml-2" width="20" /></span>
                                                                                    )
                                                                                    :
                                                                                    ("")

                                                                            */}


                                                                            <span className="play-icon-video"><img className="image-style-cls w-100"  src={videosUrls.thumbnail} alt={videosUrls.title} /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /></span>        
                                                                        </>) : (
                                                                            <span className="play-icon-video"><img  className="image-style-cls w-100" src={"https://source.unsplash.com/M185_qYH8vg/1780x1040"} alt="img" /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /> </span>
                                                                        )}

                                                                    </Link>

                                                                    <h6 className="f-16 mt-2">{videosUrls.title}</h6>
                                                                </Col>
                                                            
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                }
                                                )

                                            ) : (<Col sm={12}><h6>No Videos Available</h6></Col>)
                                        )
                                        :
                                        (<Col sm={12}><h6>Videos Not Available</h6></Col>)
                                )
                        }


                    </Row>

                </Container>
            )
        }
    }
}

export default withRouter((AllVideosSection));

