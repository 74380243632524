import React, { Component } from 'react'
import { Row, Col, Table, Image, Button, Container, Accordion, Card, Form, Tooltip, OverlayTrigger, Modal, ListGroup } from 'react-bootstrap';
import { components } from 'react-select'
import * as Cookies from "es-cookie";
import moment from 'moment';
import "./_referFriend.scss";

class RewardsHistoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return (
            <Modal {...this.props} className='p-3'
                size="lg" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton className='p-2'>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className='t-center'>
                    <Row>
                        <Row>  
                            <div className='table-responsive'>
                                <table id="rewards">
                                    <tr>
                                        <th>Name</th>
                                        <th>No.of days rewarded</th>
                                        <th>Validity</th>
                                    </tr>
                                    {
                                        this.props.redeemHistory.map(item => (
                                            <tr>
                                                {
                                                    item.name == "" || item.claimed_days == 0 || item.plan_expiry_time == "" ?
                                                        ("")
                                                        :

                                                        (
                                                            <>
                                                                <td>{item.name}</td>
                                                                <td>{item.claimed_days} {item.claimed_days > 1 ? "Days" : "Day"}</td>
                                                                <td>Your subscription plan has been extended upto <span className='font-weight-bold'>{item.plan_expiry_time != "" ? (moment.unix(item.plan_expiry_time).format('MMMM Do YYYY')) : ""}</span></td>
                                                            </>
                                                        )
                                                }
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div>

                            {/* <Col sm={12}>

                            </Col> */}
                        </Row>

                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}

export default RewardsHistoryModal
