import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_rapidRevision.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import LiveClassesModal from './LiveClassesModal';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';
import FileText from "../../../images/images1/FileText.png";
import FileArrowDown from "../../../images/images1/FileArrowDown.png";
import UserRestrictionAlert from "../home/UserRestrictionAlert";
// import LockSimple from "../../../images/LockSimple.png";
import * as Cookies from "es-cookie";
import moment from 'moment';
import { withRouter } from "react-router-dom";

let QuestionDataEngg = [
    {
        DATE: "09-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Current Electricity",

        Learn: ("2,101,0"),
        Practice: ("2,101,0"),
        VideoLecture: ("qI8AJOMSsYo"),
        Zoom: ("https://zoom.us/j/98980145720?pwd=akxQTHAyZ2lvSGZaMHJmL3BjMjBPQT09"),
        
    },
    {
        DATE: "10-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Electric Charges and Field",

        Learn: ("2,97,0"),
        Practice: ("2,97,0"),
        VideoLecture: ("d35nyRcinzk"),
        Zoom:("https://zoom.us/j/91613283361?pwd=alNoY1Q1NFBQd1E0TmtGb3F0aVp1dz09"),
    },
    {
        DATE: "11-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Electrostatic Potential and Capacitance",

        Learn: ("2,100,0"),
        Practice: ("2,100,0"),
        VideoLecture: "3ncSVRYW7v0",
        Zoom: ("https://zoom.us/j/96652889433?pwd=Z0E1Nko0azR5WElMOGVobk9TU0d5dz09"),
    },
    {
        DATE: "12-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Semi Conductor Electronics: Material Devices and Simple Circuit",

        Learn: ("2,113,0"),
        Practice: ("2,113,0"),
        VideoLecture: "AlGIP-d7rs8",
        Zoom: ("https://zoom.us/j/97867660522?pwd=cFl4M2pwZ3E2SUluMm1XSzlaYWdoUT09"),
    },
    {
        DATE: "13-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Laws of Motion",

        Learn: ("2,56,0"),
        Practice: ("2,56,0"),
        VideoLecture: "A5pQe0Lyu9I",
        Zoom: ("https://zoom.us/j/93900224728?pwd=cUJEeW1EcUpUWUdINWNMelAxd0VXQT09"),
    },
    {
        DATE: "14-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Work, Energy and Power",

        Learn: ("2,57,0"),
        Practice: ("2,57,0"),
        VideoLecture: "vr8-urU8N-A",
        Zoom: ("https://zoom.us/j/94004457451?pwd=d21KMVNvWWZ4NTNmREY0WEd3U0NCUT09"),
    },
    {
        DATE: "16-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Gravitation",

        Learn: ("2,59,0"),
        Practice: ("2,59,0"),
        VideoLecture: "HdReSsnL1LI",
        Zoom: ("https://zoom.us/j/98948894982?pwd=TkRYdTZLRk0xOE81MFhOZC9hUkI4dz09"),
    }, 
    {
        DATE: "17-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Oscillation",

        Learn: ("2,65,0"),
        Practice: ("2,65,0"),
        VideoLecture: "PV_y3f0BG4Q",
        Zoom: ("https://zoom.us/j/94219407330?pwd=MHp4SHNXNUsrOEdMUFRUc3owS014QT09"),
    },
    /*  {
         DATE: "18-08-2021",
         ClassTimings: "6:30 PM to 7:30 PM",
         time: "18",
         Subject: "Physics",
         Chapter: "System Of Particles and Rotational Motion",

         Learn: ("2,58,0"),
         Practice: ("2,58,0"),
         VideoLecture: "sbv8G9b_raE",
         Zoom: ("https://zoom.us/j/99820660957?pwd=aVFjMFV1cU1sZXhUeW9JRUQ1dVlDdz09"),
     },
    {
        DATE: "19-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Mechanical Properties Of Solids",

        Learn: ("2,80,0"),
        Practice: ("2,80,0"),
        VideoLecture: "mBcSpyDhseI",
        Zoom: ("https://zoom.us/j/94841356338?pwd=THFHTGdKd2JHeVN3Nng5aEI3ZCtXUT09"),
    },
    {
        DATE: "20-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Mechanical Properties Of Fluids",

        Learn: ("2,81,0"),
        Practice: ("2,81,0"),
        VideoLecture: "yb5mB70yZDg",
        Zoom: ("https://zoom.us/j/96652366346?pwd=SFhtR1BzWmp6RGpuMFVWMUV2WjRlZz09"),
    },
    {
        DATE: "21-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Thermal Properties of Matter",

        Learn: ("2,62,0"),
        Practice: ("2,62,0"),
        VideoLecture: "RIfW2BLjn8s",
        Zoom: ("https://zoom.us/j/94775099786?pwd=VGYwRnBVSnpBbVhRRUUzSnFlWHFPZz09"),
    }, */
    // {
    //     DATE: "23-08-2021",
    //     ClassTimings: "6:30 PM to 7:30 PM",
    //     time: "18",
    //     Subject: "Physics",
    //     Chapter: "Thermodynamics",

    //     Learn: ("2,63,0"),
    //     Practice: ("2,63,0"),
    //     VideoLecture: "rSGq-D4zyOw",
    //     Zoom: ("https://zoom.us/j/94035821098?pwd=azBxQ2lyQ29mOHRLY1ZtOStxc0lrdz09"),
    // }, 
    {
        DATE: "24-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Waves",

        Learn: ("2,66,0"),
        Practice: ("2,66,0"),
        VideoLecture: "Tzaw1fI1Jbw",
        Zoom: ("https://zoom.us/j/93297261486?pwd=MlpjbVFiMGhHVi9ZVUpSSFk5MWxEdz09"),
    }, 
    {
        DATE: "25-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Ray Optics and Optical Instruments",

        Learn: ("2,108,0"),
        Practice: ("2,108,0"),
        VideoLecture: "47yeclPF_80",
        Zoom: ("https://zoom.us/j/97011670665?pwd=ZzBWdTZLd0M3STZiamdOQ2VzaUxoZz09"),
    },
    {
        DATE: "26-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Wave Optics",

        Learn: ("2,109,0"),
        Practice: ("2,109,0"),
        VideoLecture: "gIZx8qf0cxc",
        Zoom: ("https://zoom.us/j/96111897569?pwd=L25FdVA3d0JVRlZDNkFqQWpyNTJ6dz09"),
    },
    {
        DATE: "27-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Motion In Straight Line",

        Learn: ("2,54,0"),
        Practice: ("2,54,0"),
        VideoLecture: "rGIcHvw13uI",
        Zoom: ("https://zoom.us/j/95259183982?pwd=Y0RFV0VQdVEyZXFTTmw2NDJKSGdKUT09"),
    },  
    {
        DATE: "28-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Motion In Plane",

        Learn: ("2,55,0"),
        Practice: ("2,55,0"),
        VideoLecture: "e7evjWC8Vrk",
        Zoom: ("https://zoom.us/j/97871439176?pwd=MlhKNHNEU2V1ME10MUNEc1JaekQwdz09"),
    },
    {
        DATE: "30-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Units and Measurement",

        Learn: ("2,53,0"),
        Practice: ("2,53,0"),
        VideoLecture: "y-m2A19ye1k",
        Zoom: ("https://zoom.us/j/92526619643?pwd=Y1Z1c1JNNDZTWVBDOE5LcEcyRjJrZz09"),
    }, 
    {
        DATE: "31-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Moving Charges and Magnetism & Magnetism and Matter",

        Learn: ("2,102,0"),
        Practice: ("2,102,0"),
        VideoLecture: "zdN_IyQKdsU",
        Zoom: ("https://zoom.us/j/94029527179?pwd=eCttOG5JSjhkdFN0MGo2V2YrK3FHdz09"),
    },
    {
        DATE: "01-09-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Dual Nature Of Matter and Radiation",

        Learn: ("2,110,0"),
        Practice: ("2,110,0"),
        VideoLecture: "qn5pKAN9vpk",
        Zoom: ("https://zoom.us/j/97720386367?pwd=NjJmZ2k2WElpQjhNdW9NYUJETTVjdz09"),
    }, 
    {
        DATE: "02-09-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Electro Magnetic Induction",

        Learn: ("2,104,0"),
        Practice: ("2,104,0"),
        VideoLecture: "1y811HsIEa4",
        Zoom: ("https://zoom.us/j/99956877986?pwd=K0t0cFljZ25RL3BhT1c2UDkrZjgxZz09"),
    },
    {
        DATE: "02-09-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Electro Magnetic Waves",

        Learn: ("2,107,0"),
        Practice: ("2,107,0"),
        VideoLecture: "1y811HsIEa4",
        Zoom: ("https://zoom.us/j/99956877986?pwd=K0t0cFljZ25RL3BhT1c2UDkrZjgxZz09"),
    },
    {
        DATE: "03-09-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Alternating Current",

        Learn: ("2,106,0"),
        Practice: ("2,106,0"),
        VideoLecture: "eXruZaEn-gc",
        Zoom: ("https://zoom.us/j/91652085703?pwd=UnNQenZXSkJGSGE4VFFMYTRsWjBWQT09"),
    },
    {
        DATE: "04-09-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Atoms",

        Learn: ("2,111,0"),
        Practice: ("2,111,0"),
        VideoLecture: "QGvgcRBEIjA",
        Zoom: ("https://zoom.us/j/93490944964?pwd=WDJNalNlUHlpd2ZoTFozckRrc0pmZz09"),
    }, 
    {
        DATE: "04-09-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Nucleii",

        Learn: ("2,112,0"),
        Practice: ("2,112,0"),
        VideoLecture: "QGvgcRBEIjA",
        Zoom: ("https://zoom.us/j/93490944964?pwd=WDJNalNlUHlpd2ZoTFozckRrc0pmZz09"),
    },     
];

let QuestionDataPhysics = []
// QuestionDataPhysics.push(...QuestionDataAgri)
QuestionDataPhysics.push(...QuestionDataEngg)


const sortedActivities = QuestionDataPhysics.sort ( function (a, b){
  let date1 = moment(a.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
  let date2 = moment(b.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
  
 if (a.DATE === b.DATE) {
  return a.time - b.time;
}
 return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
});

let count = 0;
var QuestionData = sortedActivities.map((el,index) => {
  var o = Object.assign({}, el);
  if(el.Assignments !="" || el.Solutions !="" ){
      count++;
       o.indexvalue = count
       
  }
  return o;
});

/*if (Cookies.get("examid") == 7 || Cookies.get("examid") == 8) {

    QuestionData.push(...QuestionDataAgri)

}else{
	 QuestionData.push(...QuestionDataEngg)
}*/
// {
// (Cookies.get("examid") == 1 ? (

// ):(""))
// }

let LiveClassesDataPhysics = []

    for(var i in QuestionData){
        let currentDate = moment().format("DD/MM/YYYY");
        if ((QuestionData[i]["VideoLecture"] || QuestionData[i]["Zoom"]) && (currentDate ==  moment(QuestionData[i].DATE,"DD-MM-YYYY").format("DD/MM/YYYY"))) {
            LiveClassesDataPhysics.push(QuestionData[i]);
          }
    }
	
var Zoom;
var Livevideo;


class PhysicsDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            dataLiveUrl: "",
            userRestionModalShow: false,
			      allLiveClasses:true
        }

        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
          this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));

      }
        
    }
    
    checkForDate = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7) {
            return true
        }
        return false
    }

    modalaFun = (row) => {
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });

    }
    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    sortFuncDate(a, b, order, dataField, rowA, rowB)  { 
        if (order == 'desc') {
            
            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY")  > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY")  > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }
    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }
    getAccessPlans = (planId)  => {
        const epriryDateInunixTime = this.isStudentUserValid.expiry_date;
        const currentDateInUnixTime = parseInt((new Date().getTime() / 1000).toFixed(0))

        // console.log("exU",epriryDateInunixTime)
        // console.log("cU",currentDateInUnixTime)
        // console.log("eeeeee",this.isStudentUserValid)
        if(planId == 5){
            return true; //restrict
        }else{

            if(currentDateInUnixTime > epriryDateInunixTime){
                return true; 
            }
        }

        return false
    }
    learnFormateAction = (row) => {
        
        let practiceIds = row.split(',');
        //console.log("practiceIds",practiceIds)
        this.props.history.push({
            pathname: "/student/subject/start-learning",
            state: {
              subjectid: practiceIds[0],
              ocid: practiceIds[1],
              otid: practiceIds[2]
            }
          }
        )
    }
    actionFormatterLearn(cell, row, rowIndex){
        return (
            <>
            {
                row.Learn != "" ?  (row.indexvalue > 3 && this.getAccessPlans(this.isStudentUserValid.current_plan_id) ) ? 
                
                    (<div className="learnTextLock">
                        <a onClick={() => this.modalFunAssignments()}> <span>Learn Chapter</span></a>
                    </div>) :
                    (<div className="learnText">
                        <a onClick={() => this.learnFormateAction(row.Learn)} target="_blank"> Learn Chapter</a>
                    </div>) : <h6 className="text-center">-</h6>
            }
            </>
        )
    }
    practiceFormateAction = (row) => {
        
        let practiceIds = row.split(',');
        //console.log("practiceIds",practiceIds)
        localStorage.setItem("subjectid", practiceIds[0]);
        localStorage.setItem("type", "practice");
        //localStorage.setItem("ocid", "0");
        localStorage.setItem("ocid", practiceIds[1]);
        localStorage.setItem("otid", practiceIds[2]);
        window.open("/student/subject/practice-test", "_blank") //to open new page
    }
    actionFormatterPractice(cell, row, rowIndex){
        return (
            <>
            {
                row.Practice != "" ?  (row.indexvalue > 3 && this.getAccessPlans(this.isStudentUserValid.current_plan_id) ) ? 
                
                    (<div className="practiceTextLock">
                        <a onClick={() => this.modalFunAssignments()}> <span>Practice Here</span></a>
                    </div>) :
                    (<div className="practiceText">
                        <a   onClick={() => this.practiceFormateAction(row.Practice)}> Practice Here </a>
                    </div>) : <h6 className="text-center">-</h6>
            }
            </>
        )
    }
    actionFormatterVideoLecture(cell, row, rowIndex) {
        let currentDate = moment().format("DD-MM-YYYY");
        let currentMonth = moment().format('MM')
        let currentRowMonth = moment(row.DATE,"DD-MM-YYYY").format("MM") 
        console.log("currentMonth",currentMonth)
        console.log("rowMonth",currentRowMonth)
        
        return (

            <>
                <div className="text-center">
                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
                

                    {/* {
                        currentMonth == currentRowMonth ? 
                        (
                            currentDate == row.DATE ? 
                        (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) :
                        (
                            row.DATE < currentDate  ? 
                            :
                            
                            (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                        )
                        ) 
                        :
                        (
                            currentDate == row.DATE ? 
                            (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) :
                            (
                                row.DATE > currentDate  ? 
                                (<button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>) 
                                :
                                
                                (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                            )
                        )

                        
                    } */}
                </div>
            </>

            
                    /*{
                        row.Zoom == "" ?

                            row.VideoLecture != "" ?
                                (<button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>)
                            
                                : "-"


                            :
                            <>
                            
                                {
                                    currentDate == row.DATE ? 
                                    (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) 
                                    :
                                    (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                                    // (<a href={row.VideoLecture} target="_blank" ><button type="button" className="btn btn-custom-disabled-cls" disabled>WatchNow</button></a>)
                               
                                }
                                



                            </>
                            }*/


            
        )
    }

    colFunction() {

        let columns = [
            {
                dataField: "DATE",
                text: "Date",
                sort: true,
                headerStyle: {
                    width: "12%"
                },
                style: {
                    fontWeight: "bold !important"
                },
                sortFunc: this.sortFuncDate.bind(this)
            },
            {
                dataField: "ClassTimings",
                text: "Class Timings",
                sort: true,
                headerStyle: {
                    width: "22%"
                },
                sortFunc: this.sortFuncMulti.bind(this)
            },

            {
                dataField: "Subject",
                text: "Subject",
                style: (row, rowIndex) => {
                    return { fontWeight: "bold !important" };
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                width: "25%"
                }
            },
            {
                dataField: "Learn",
                text: "Learn",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterLearn.bind(this)
            },
            {
                dataField: "Practice",
                text: "Practice",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterPractice.bind(this)
            },
           
            {
                dataField: "VideoLecture",
                text: "Video Lecture",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterVideoLecture.bind(this),
                
            },
            
        ];
        return columns;
    }

    render() {
        return (
            <Row>
                <Col align="right" sm={12}>
                    <h6 className="switch_button_cls mt-res-4">
                        <p className="allClassesAlignment">All Classes</p>
                        <label class="switch">
                            <input type="checkbox" 
                                onChange={() => {
                                    
                                    this.setState((state) => {
                                        
                                        return {
                                            allLiveClasses: !state.allLiveClasses
                                        }
                                    })
                                }}
                                checked={!this.state.allLiveClasses} />
                            <span class="slider round"></span>
                        </label>
                        <p className="liveClassesAlignment">Live</p>
                    </h6>
                </Col>
                <Col sm={12}>
                {
                    this.state.allLiveClasses ?
                    <CardLessDataTableWithSearch 
                    parentData={QuestionData}
                    particlecolumns={this.colFunction()}
                    defaultSorted={[{
                        dataField: "ClassTimings",
                        order: "asc"
                    }]}
                    />
                    :
                    <CardLessDataTableWithSearch 
                    parentData={LiveClassesDataPhysics}
                    particlecolumns={this.colFunction()}
                    defaultSorted={[{
                        dataField: "ClassTimings",
                        order: "asc"
                    }]}
                    />
                }
                    {/* <Card>
                        <BootstrapTable
                            keyField="id"
                            columns={this.colFunction()}
                            data={QuestionData}
                            defaultSorted={[{
                                dataField: "ClassTimings",
                                order: "asc"
                            }]}
                            wrapperClasses="table-responsive"
                            bootstrap4

                        />
                    </Card> */}

                    <LiveClassesModal data={this.state.dataLiveUrl} show={this.state.liveModalShow} onHide={() => this.setState({ liveModalShow: false })} />
                    <UserRestrictionAlert
                        show={this.state.userRestionModalShow}
                        onHide={() => this.setState({ userRestionModalShow: false })}
                    />
                </Col>
            </Row>

        )
    }

}
export default withRouter (PhysicsDataSection)