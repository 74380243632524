import React, { Component } from 'react'
import NewTopicsGroupCards from './NewTopicsGroupCards'
import '../_subjects.scss'

import { SubjectCardOne } from './SubjectData'
import * as compose from 'lodash.flowright';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as Cookies from "es-cookie";

const FETCH_VIDEOS_ENABLED = gql`
  query($module: String!, $feature: String!, $exam_id: Int!) {
    getFeatureEnabled(
      module: $module
      feature: $feature
      exam_id: $exam_id
    ) {
      module
      feature
      exam_id
      web_enable
      mobile_enable
    }
  }
`;

class NewTopicsSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            CardtitleOne: {
                title: 'Unit-I Diversity in the living world'
            }
        }
    }

    render() {
        const getFeatureEnabled = this.props.getFeatureEnabled;
        const loading2 = getFeatureEnabled.loading;
        const error2 = getFeatureEnabled.error;

        if (loading2) return "";
        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
        return null;
        }
        
        return (
            <section className="subject_section">

                <NewTopicsGroupCards
                getChapters={this.props.getChapters}
                getTopics={this.props.getTopics}
                    CardsData={SubjectCardOne}
                    cardTitle={this.state.CardtitleOne}
                    videoEnabled={getFeatureEnabled.getFeatureEnabled} />
            </section>
        )
    }
}


export default compose(
  graphql(FETCH_VIDEOS_ENABLED, {
    options: (props) => ({
      variables: {
        module: "Videos",
        feature: "topic_videos",
        exam_id: parseInt(Cookies.get("examid")),
      },
      fetchPolicy: "cache-and-network",
    }),
    name: "getFeatureEnabled",
  })
  )(NewTopicsSection);
