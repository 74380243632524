import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_rapidRevision.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import LiveClassesModal from './LiveClassesModal';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';

import FileText from "../../../images/images1/FileText.png";
import FileArrowDown from "../../../images/images1/FileArrowDown.png";
//import Group3172 from "../../../images/images1/Group3172.png";

import UserRestrictionAlert from "../home/UserRestrictionAlert";
// import LockSimple from "../../../images/LockSimple.png";
import * as Cookies from "es-cookie";
import moment from 'moment';
import { withRouter } from "react-router-dom";

let QuestionDataZoology = [
    {
        DATE: "09-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Animal kingdom - I",

        Learn: ("5,14,0"),
        Practice: ("5,14,0"),
        VideoLecture: ("xgRdzHo5Hno"),
        Zoom: ("https://zoom.us/j/95280968622?pwd=N0xlQ09PMjlMc0hQZFVXVStzd09Tdz09"),
    },
    {
        DATE: "10-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Structural organization in Animals",

        Learn: ("5,15,0"),
        Practice: ("5,15,0"),
        VideoLecture: ("qYzfUNU9Sxo"),
        Zoom: ("https://zoom.us/j/97813985497?pwd=T25lbWlpQVZwYUlnYkVKMnJ6NGg2dz09"),
        
    },
    {
        DATE: "11-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Human physiology  and digestion",

        Learn: ("5,16,0"),
        Practice: ("5,16,0"),
        VideoLecture: ("s1T7qtjp7E0"),
        Zoom: ("https://zoom.us/j/99457296726?pwd=VUlBNHRPWG90WTg1S1N2S2piSVZqUT09"),
    },
    {
        DATE: "12-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Breathing and exchange of gases",

        Learn: ("5,17,0"),
        Practice: ("5,17,0"),
        VideoLecture: ("QDNS9ggdSbA"),
        Zoom: ("https://zoom.us/j/98354928989?pwd=NHlldk44dHpWL3g1MGNKeFZvWHBIdz09"),
    },
    {
        DATE: "13-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Body fluids and circulation",

        Learn: ("5,18,0"),
        Practice: ("5,18,0"),
        VideoLecture: ("9khPtkqD0wY"),
        Zoom: ("https://zoom.us/j/93241778728?pwd=eWg0M0YxL2RIL2tUMmdaWE1uUEFpQT09"),
    },
    {
        DATE: "14-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Excretory products and their Elimination",

        Learn: ("5,19,0"),
        Practice: ("5,19,0"),
        VideoLecture: ("lI3nPriSDZM"),
        Zoom: ("https://zoom.us/j/96058403836?pwd=M1N0eFF0TWZrbzBZNWlGOERDMkJFZz09"),
    },
    {
        DATE: "16-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Locomotion and Movement",

        Learn: ("5,20,0"),
        Practice: ("5,20,0"),
        VideoLecture: ("BpO8GlGKPQo"),
        Zoom: ("https://zoom.us/j/94483566517?pwd=S2thODhLSFVnMTFxRkV6czlVMGMxZz09"),
    },
    {
        DATE: "17-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Neural Control and Co-ordination",

        Learn: ("5,21,0"),
        Practice: ("5,21.0"),
        VideoLecture: ("vl6tV5QH7RI"),
        Zoom: ("https://zoom.us/j/95530649279?pwd=VjBZdHNkTCtsVFM2YVdGZDk0QlRYdz09"),
    },
    {
        DATE: "18-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Chemical Coordination and Control",

        Learn: ("5,22,0"),
        Practice: ("5,22,0"),
        VideoLecture: ("wd3x0enn7io"),
        Zoom: ("https://zoom.us/j/97930140428?pwd=dS91dHc4VndWNDhMMXk1eGtGaVhYZz09"),
    },
    {
        DATE: "19-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Human Health and Disease ",

        Learn: ("5,128,0"),
        Practice: ("5,128,0"),
        VideoLecture: ("MH61uRZSz2o"),
        Zoom: ("https://zoom.us/j/99039475923?pwd=UGx4amRnQit5b0djNTBEUmplRy9YZz09"),
    },
    {
        DATE: "20-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Biotechnology and Its applications",

        Learn: ("5,131,0"),
        Practice: ("5,131,0"),
        VideoLecture: ("F_JrjgqWA30"),
        Zoom: ("https://zoom.us/j/97173447370?pwd=Zzh5bTFNUjVQMGZhYmYxUmZrSVFKQT09"),
    },
    {
        DATE: "21-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Animal Husbandry",

        Learn: ("5,130,0"),
        Practice: ("5,130,0"),
        VideoLecture: ("CCsujcR0S1M"),
        Zoom: ("https://zoom.us/j/96693845391?pwd=V09vLzUwT1UvY3Qvd0V0KzhPWWdidz09"),
    },
    {
        DATE: "23-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Human physiology - II",

        Learn: ("5,16,0"),
        Practice: ("5,16,0"),
        VideoLecture: ("r7mcvD8vC0A"),
        Zoom: ("https://zoom.us/j/93708017405?pwd=UHVGc090SmtobzdEMmRINTZBQU9nUT09"),
    },
    {
        DATE: "24-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Human Reproduction ",

        Learn: ("5,114,0"),
        Practice: ("5,114,0"),
        VideoLecture: ("ZN8L9vlVL5Q"),
        Zoom: ("https://zoom.us/j/97762102422?pwd=WVpUVUJ5OG5NUW9LL25MQ3Q3aWI1Zz09"),
    },
    {
        DATE: "25-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Reproductive Health",

        Learn: ("5,115,0"),
        Practice: ("5,115,0"),
        VideoLecture: ("1n9aNKnReGM"),
        Zoom: ("https://zoom.us/j/91661924326?pwd=OE4zVDNzOE5EM3Q5OEJ6ckJzM2NMZz09"),
    },
    {
        DATE: "26-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Principles of Inheritance and Variation",

        Learn: ("5,116,0"),
        Practice: ("5,116,0"),
        VideoLecture: ("GPoL9J9FJ7g"),
        Zoom: ("https://zoom.us/j/91974893563?pwd=VS9tTTRCL05VOWYvem02cGorOTRCdz09"),
    },
    {
        DATE: "27-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Evolution-I",

        Learn: ("5,119,0"),
        Practice: ("5,119,0"),
        VideoLecture: ("7hzF6Um_qro"),
        Zoom: ("https://zoom.us/j/92016421469?pwd=dmdjMzBNTTBKbVZtWHpCbXA4L0R4Zz09"),
    },
    {
        DATE: "28-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Evolution - II",

        Learn: ("5,119,0"),
        Practice: ("5,119,0"),
        VideoLecture: ("VKgpgM3nyBM"),
        Zoom: ("https://zoom.us/j/95062720883?pwd=cS9zKzZyTE5zbjdCNlZRVGx6cHRIUT09"),
    },
    {
        DATE: "30-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Eco system",

        Learn: ("5,133,0"),
        Practice: ("5,133,0"),
        VideoLecture: ("IuDJmUKc47A"),
        Zoom: ("https://zoom.us/j/95187768648?pwd=ek1CRDZidSt6OFJEa0cwakd4Y3Mxdz09"),
    },
    {
        DATE: "31-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Environmental issues",

        Learn: ("5,135,0"),
        Practice: ("5,135,0"),
        VideoLecture: ("XLxCnaJZDNM"),
        Zoom: ("https://zoom.us/j/99680175480?pwd=RWxENDdNOTI2L1FHM3YrODZ3SFlZQT09"),
    },
    {
        DATE: "01-09-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Biodiversity and conservation",

        Learn: ("5,134,0"),
        Practice: ("5,134,0"),
        VideoLecture: ("z6NOs3wxls4"),
        Zoom: ("https://zoom.us/j/99497866947?pwd=QXhRTU5DVVU5R0ZQeVpmK09aRHB2UT09"),
    },
    {
        DATE: "02-09-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Animal Kingdom ",

        Learn: ("5,14,0"),
        Practice: ("5,14,0"),
        VideoLecture: ("02Wwg4xLsR8"),
        Zoom: ("https://zoom.us/j/93746673978?pwd=bGx3UFQwVStNZzZXcFduclpENCt5UT09"),
    },
    {
        DATE: "03-09-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Organisms and Population",

        Learn: ("5,132,0"),
        Practice: ("5,132,0"),
        VideoLecture: ("NLhqVxV-Zcg"),
        Zoom: ("https://zoom.us/j/93549109464?pwd=ZEN6U2ZXRUlZZzJGaUlCSzhJemFrQT09"),
    },
    {
        DATE: "04-09-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Organisms and Population",

        Learn: ("5,132,0"),
        Practice: ("5,132,0"),
        VideoLecture: ("SYlVX_ghba4"),
        Zoom: ("https://zoom.us/j/97393584162?pwd=NC9XeEttNmNvTDUxVEI5Vk9IZFljdz09"),
    },
    
];

const sortedActivities = QuestionDataZoology.sort ( function (a, b){
  let date1 = moment(a.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
  let date2 = moment(b.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
  
 if (a.DATE === b.DATE) {
  return a.time - b.time;
}
 return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
});

let count = 0;
var QuestionData = sortedActivities.map((el,index) => {
  var o = Object.assign({}, el);
  if(el.Assignments !="" || el.Solutions !="" ){
      count++;
       o.indexvalue = count
       
  }
  return o;
});

let LiveClassesDataZoology = []

    for(var i in QuestionData){
        let currentDate = moment().format("DD/MM/YYYY");
        if ((QuestionData[i]["VideoLecture"] || QuestionData[i]["Zoom"]) && (currentDate ==  moment(QuestionData[i].DATE,"DD-MM-YYYY").format("DD/MM/YYYY"))) {
            LiveClassesDataZoology.push(QuestionData[i]);
          }
    }
	
var time;
var Zoom;
var Livevideo;
class ZoologyDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            dataLiveUrl: "",
            userRestionModalShow: false,
			      allLiveClasses:true
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
          this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));

      }
    }
    
    checkForDate = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7) {
            return true
        }
        return false
    }
    modalaFun = (row) => {
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });
    }
    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }
    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    sortFuncDate(a, b, order, dataField, rowA, rowB)  { 
        if (order == 'desc') {
            
            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY")  > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY")  > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }
    getAccessPlans = (planId)  => {
        const epriryDateInunixTime = this.isStudentUserValid.expiry_date;
        const currentDateInUnixTime = parseInt((new Date().getTime() / 1000).toFixed(0))

        // console.log("exU",epriryDateInunixTime)
        // console.log("cU",currentDateInUnixTime)
        // console.log("eeeeee",this.isStudentUserValid)
        if(planId == 5){
            return true; //restrict
        }else{

            if(currentDateInUnixTime > epriryDateInunixTime){
                return true; 
            }
        }

        return false
    }
    learnFormateAction = (row) => {
        
        let practiceIds = row.split(',');
        //console.log("practiceIds",practiceIds)
        this.props.history.push({
            pathname: "/student/subject/start-learning",
            state: {
              subjectid: practiceIds[0],
              ocid: practiceIds[1],
              otid: practiceIds[2]
            }
          }
        )
    }
    actionFormatterLearn(cell, row, rowIndex){
        return (
            <>
            {
                row.Learn != "" ?  (row.indexvalue > 3 && this.getAccessPlans(this.isStudentUserValid.current_plan_id) ) ? 
                
                    (<div className="learnTextLock">
                        <a onClick={() => this.modalFunAssignments()}> <span>Learn Chapter</span></a>
                    </div>) :
                    (<div className="learnText">
                        <a onClick={() => this.learnFormateAction(row.Learn)} target="_blank"> Learn Chapter</a>
                    </div>) : <h6 className="text-center">-</h6>
            }
            </>
        )
    }
    practiceFormateAction = (row) => {
        
        let practiceIds = row.split(',');
        //console.log("practiceIds",practiceIds)
        localStorage.setItem("subjectid", practiceIds[0]);
        localStorage.setItem("type", "practice");
        //localStorage.setItem("ocid", "0");
        localStorage.setItem("ocid", practiceIds[1]);
        localStorage.setItem("otid", practiceIds[2]);
        window.open("/student/subject/practice-test", "_blank") //to open new page
    }
    actionFormatterPractice(cell, row, rowIndex){
        return (
            <>
            {
                row.Practice != "" ?  (row.indexvalue > 3 && this.getAccessPlans(this.isStudentUserValid.current_plan_id) ) ? 
                
                    (<div className="practiceTextLock">
                        <a onClick={() => this.modalFunAssignments()}> <span>Practice Here</span></a>
                    </div>) :
                    (<div className="practiceText">
                        <a   onClick={() => this.practiceFormateAction(row.Practice)}> Practice Here </a>
                    </div>) : <h6 className="text-center">-</h6>
            }
            </>
        )
    }
    actionFormatterVideoLecture(cell, row, rowIndex) {
        let currentDate = moment().format("DD-MM-YYYY");
        let currentMonth = moment().format('MM')
        let currentRowMonth = moment(row.DATE,"DD-MM-YYYY").format("MM") 
        console.log("currentMonth",currentMonth)
        console.log("rowMonth",currentRowMonth)
        
        return (

            <>
                <div className="text-center">
                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
{/*                 

                    {
                        currentMonth == currentRowMonth ? 
                        (
                            currentDate == row.DATE ? 
                        (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) :
                        (
                            row.DATE < currentDate  ? 
                            :
                            
                            (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                        )
                        ) 
                        :
                        (
                            currentDate == row.DATE ? 
                            (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) :
                            (
                                row.DATE > currentDate  ? 
                                (<button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>) 
                                :
                                
                                (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                            )
                        )

                        
                    } */}
                </div>
            </>
        //     <>
        //         <div className="text-center">
        //             {
        //                 row.Zoom == "" ?

        //                     row.VideoLecture != "" ?
        //                         (<button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>)
                            
        //                         : "-"


        //                     :
        //                     <>
                            
        //                         {
        //                             currentDate == row.DATE ? 
        //                             (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) 
        //                             :
        //                             (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                                    
        //                         }
                                



        //                     </>
        //             }

        //         </div>

        //     </>
         )
    }

    colFunction() {

        let columns = [
            {
                dataField: "DATE",
                text: "Date",
                sort: true,
                headerStyle: {
                    width: "12%"
                },
                style: {
                    fontWeight: "bold !important"
                },
                sortFunc: this.sortFuncDate.bind(this)
            },
            {
                dataField: "ClassTimings",
                text: "Class Timings",
                sort: true,
                headerStyle: {
                    width: "22%"
                },
                sortFunc: this.sortFuncMulti.bind(this)
            },

            {
                dataField: "Subject",
                text: "Subject",
                style: (row, rowIndex) => {
                    return { fontWeight: "bold !important" };
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                width: "25%"
                }
            },
            {
                dataField: "Learn",
                text: "Learn",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterLearn.bind(this)
            },
            {
                dataField: "Practice",
                text: "Practice",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterPractice.bind(this)
            },
           
            {
                dataField: "VideoLecture",
                text: "Video Lecture",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterVideoLecture.bind(this),
                
            },
            
        ];
        return columns;
    }

    render() {
        return (
            <Row>
                <Col align="right" sm={12}>
                    <h6 className="switch_button_cls mt-res-4">
                        <p className="allClassesAlignment">All Classes</p>
                        <label class="switch">
                            <input type="checkbox" 
                                onChange={() => {
                                    
                                    this.setState((state) => {
                                        
                                        return {
                                            allLiveClasses: !state.allLiveClasses
                                        }
                                    })
                                }}
                                checked={!this.state.allLiveClasses} />
                            <span class="slider round"></span>
                        </label>
                        <p className="liveClassesAlignment">Live</p>
                    </h6>
                </Col>
                <Col sm={12}>
                {
                    this.state.allLiveClasses ?
                    <CardLessDataTableWithSearch 
                    parentData={QuestionData}
                    particlecolumns={this.colFunction()}
                    defaultSorted={[{
                        dataField: "ClassTimings",
                        order: "asc"
                    }]}
                    />
                    :
                    <CardLessDataTableWithSearch 
                    parentData={LiveClassesDataZoology}
                    particlecolumns={this.colFunction()}
                    defaultSorted={[{
                        dataField: "ClassTimings",
                        order: "asc"
                    }]}
                    />
                }
                    {/* <Card>
                        <BootstrapTable
                            keyField="id"
                            columns={this.colFunction()}
                            data={QuestionData}
                            defaultSorted={[{
                                dataField: "ClassTimings",
                                order: "asc"
                            }]}
                            wrapperClasses="table-responsive"
                            bootstrap4

                        />
                    </Card> */}

                    <LiveClassesModal data={this.state.dataLiveUrl} show={this.state.liveModalShow} onHide={() => this.setState({ liveModalShow: false })} />
                    <UserRestrictionAlert
                        show={this.state.userRestionModalShow}
                        onHide={() => this.setState({ userRestionModalShow: false })}
                    />
                </Col>
            </Row>

        )
    }
}
export default withRouter(ZoologyDataSection);