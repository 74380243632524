import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'

import * as Cookies from "es-cookie";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { Container, Row, Col, Image } from 'react-bootstrap'

import { withRouter } from "react-router-dom";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import InstituteRevisionMaterialsHome from '../components/institute_revision_materials/InstituteRevisonMaterialsHome';


const INSTITUTE_REVISION_MATERIALS = gql`
  query($student_mobile: String) {
    getInstituteRevisionMaterials(student_mobile: $student_mobile) {
        subject_id
        class_id
        file_name
        file_path
        chapter_id
        chapter_name
    }
  }
`;

const FETCH_SUBJECTS_NEETJEE = gql`
query($mobile:String ,$exam_id:Int){
    getSubjects(mobile:$mobile exam_id:$exam_id){
        id
        subject
        studentChapters{
          id
          chapter
          class
          enabled
          chapterWisePreviousQuestionsCount
        }
      }
}
`;

const GET_SUBJECTS = gql`
query($exam_id:Int){
    getSubjectOrder(exam_id:$exam_id){
        id
        subject
      }
}
`



class InstituteRevisionMaterials extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggled: isMobile == true && Cookies.get("institution_id") == 30 ? "wrapper" : Cookies.get("toggle")
        }
    }

    menuToggler = () => {
        const toggled = this.state.toggled;
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }


        const instituteRevisionMaterils = this.props.instituteRevisionMaterils;
        const loading = instituteRevisionMaterils.loading;
        const error = instituteRevisionMaterils.error;

        const getSubjects = this.props.getSubjects;
        const loading1 = getSubjects.loading;
        const error1 = getSubjects.error

        const getSubjectOrder = this.props.getSubjectOrder
        const loading2 = getSubjectOrder.loading2;
        const error2 = getSubjectOrder.error2;

        if (error !== undefined) {
            alert("Server Error. " + error.message);
            return null;
        }

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }

        return (
            <div className={this.state.toggled}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper">
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper">
                        <Container>
                            {
                                loading == true || loading1 == true || loading2 == true ?
                                    (
                                        <Container>
                                            <Row>
                                                <Col sm={12}>
                                                    <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                                </Col>
                                            </Row>
                                        </Container>
                                    )
                                    :
                                    (
                                        <InstituteRevisionMaterialsHome
                                            instituteRevisionMaterils={instituteRevisionMaterils.getInstituteRevisionMaterials}
                                            getSubjects={getSubjects.getSubjects}
                                            getSubjectOrder={getSubjectOrder.getSubjectOrder}
                                        />
                                    )
                            }
                        </Container>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(
    graphql(INSTITUTE_REVISION_MATERIALS, {
        options: props => ({
            variables: {
                student_mobile: Cookies.get("mobile"),
            },
            fetchPolicy: 'network-only'
        }), name: "instituteRevisionMaterils"
    }), graphql(FETCH_SUBJECTS_NEETJEE, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile")
            },
            fetchPolicy: 'network-only'
        }), name: "getSubjects"
    }),
    graphql(GET_SUBJECTS, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
            },
            fetchPolicy: 'network-only'
        }), name: "getSubjectOrder"
    }),
)(InstituteRevisionMaterials));