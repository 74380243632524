import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Image, Navbar, Dropdown, Form, FormControl, Button, Container, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Cookies from "es-cookie";
import moment from 'moment';

import { components } from 'react-select'
import './_eamcetMockTests.scss';
import { data } from 'jquery';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import ViewSyllabusEamcet from './ViewSyllabusEamcet';
import EamcetMockTestHistoryFree from './EamcetMockTestHistoryFree';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';
import EamcetRestrictionAlert from "../home/EamcetRestrictionAlert";

const MOCKTEST_REGISTER_EAMCET = gql`
  mutation($mobile: String!,
    $exam_id: Int!) {
    registerForExam(mobile: $mobile,exam_id: $exam_id)
  }
`;

class EamcetMockTestsSection extends Component {
    constructor(props) {
        super(props)
        this.state = {

            userRestionModalShow: false,
            regstatus: 1,
            viewSyllabusdetails: "",
            viewSyllabusModal: false,
            status: true,
            getStudentTestSeries: props.getStudentTestSeries,
            currentTime: moment().unix(),
            classData: { value: 0, label: 'ALL' },

        }
        localStorage.setItem("resumeExam", false);
        localStorage.setItem("uid", 0);

    }

    componentDidMount() {
        const title = GoogleAnalyticsArray[0].Eamcet_Mocktests;
        ReactGA.pageview('/student/Mocktests', ["rizee"], title);

        localStorage.setItem("resumeExam", false);
        let Schedule = this.state.getStudentTestSeries.filter((item) => item.is_scheduled == "1" && item.exam_expired == false && item.exam_session_id == 0);
        let islive = Schedule.find((item) => item.exam_started == true && item.is_completed == false);

        if (islive != undefined) {
            const end_time = islive.end_time;
            const currentTime = this.state.currentTime;
            //console.log("comislive", end_time, currentTime);
            this.timer = setInterval(() => {
                this.setState({ currentTime: moment().unix() });

                if (currentTime >= end_time) {
                    console.log("comislive", end_time, currentTime);
                    clearInterval(this.timer);
                }

            }, 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }


    startExam = (data, islive, type) => {
        console.log("startExam", data, islive, type);
        if (type == "free") {
            if (islive == undefined) {
                localStorage.setItem("mocklivestatus", "live");
            }
            else {
                localStorage.setItem("mocklivestatus", "");
            }
        }
        else if (type == "schedule") {
            if (islive != undefined) {
                localStorage.setItem("mocklivestatus", "live");
            }
            else {
                localStorage.setItem("mocklivestatus", "");
            }
        }

        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "eamcet_exam");
        localStorage.setItem("examname", examname);
        localStorage.setItem("mocktest", true);
        localStorage.setItem("examtype_pattern", "eamcet_exam");
        if (isMobile == true && Cookies.get("institution_id") == 30) {
            this.props.history.push({
                pathname: "/student/subject/series_test",
            })
        } else {
            window.open("/student/subject/series_test", "_blank");
        }

    }

    resumeExam = (data, islive, type) => {
        console.log("resumeExam", data, islive, type);
        if (type == "free") {
            if (islive == undefined) {
                localStorage.setItem("mocklivestatus", "live");
            }
            else {
                localStorage.setItem("mocklivestatus", "");
            }
        }
        else if (type == "schedule") {
            if (islive != undefined) {
                localStorage.setItem("mocklivestatus", "live");
            }
            else {
                localStorage.setItem("mocklivestatus", "");
            }
        }

        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "eamcet_exam");
        localStorage.setItem("examname", examname);
        localStorage.setItem("mocktest", true);
        localStorage.setItem("resumeExam", true);
        localStorage.setItem("examtype_pattern", "eamcet_exam")
        if (isMobile == true && Cookies.get("institution_id") == 30) {
            this.props.history.push({
                pathname: "/student/subject/series_test",
            })
        } else {
            window.open("/student/subject/series_test", "_blank");
        }
    }

    handleResultFunction = (id) => {
        this.props.history.push({
            pathname: "/student/subject/mock-exam-result",
            state: {
                sessionid: id,
                examtype: "eamcet_exam"
            }
        })
    }

    register = async (e, id) => {
        e.preventDefault();
        await this.props.register({
            variables: {
                mobile: Cookies.get("mobile"),
                exam_id: parseInt(id)
            },
            update: (store, { data }) => {
                console.log("datadata", data)
                const getdata = this.state.getStudentTestSeries.map((item) => {
                    if (item.id == id) {
                        console.log("true", item.id, id);
                        return { ...item, is_registered: true }
                    }
                    return { ...item }
                })
                console.log("store", getdata);
                this.state.getStudentTestSeries = getdata;

                if (data.registerForExam) {
                    this.setState({
                        regstatus: 2
                    })
                    setTimeout(() => {
                        this.RegisterSetpageLoad();
                    }, 1000);
                }
            }
        });
    };

    RegisterSetpageLoad = () => {
        this.setState({ regstatus: 1 });
    };

    modalaFun = (exam_name) => {
            this.setState({
                userRestionModalShow: true,
                exam_name: exam_name
            })
        
    }



    handleShowModal = (data) => {
        console.log("dataaa", data)
        this.setState({
            viewSyllabusModal: true,
            viewSyllabusDetails: data
        });
    }
    liveMockHistory = () => {
        this.setState({
            status: !this.state.status
        });
    }

    graphValue(getseries) {
        let data = "";
        if (getseries != undefined) {
            data = parseInt(getseries.correct_marks) - parseInt(getseries.negative_marks);
        }
        //console.log("graphValue123", data);
        return data;

    }

    selectHandleInputChange = (evalue) => {
        const classId = evalue
        let labelval = ""
        if (classId == "0") {
            labelval = "All"

        } else if (classId == "1") {
            labelval = "Class XI"

        } else if (classId == "2") {
            labelval = "Class XII"

        } else if (classId == "3") {
            labelval = "Cumulative"
        }
        this.setState({
            classData: {
                value: classId,
                label: labelval
            },
        })
    }


    render() {
        console.log("eprop", this.props)
        // console.log("this.state", this.state)
        let notification_exam_id = this.props?.data?.location?.search?.slice(4);
        let currentTime_currentSec = Math.round((new Date()).getTime() / 1000);
        let premiumMockTests = [];
        let freeSchedule = [];
        let Schedule = [];
        let islive = [];
        let classTests = [];
        let getStudentTestSeries = [];

        if (this.props.data.location.pathname != "/student/action/ST_MockTests" || (this.props.data.location.pathname == "/student/action/ST_MockTests" && !this.props.data.location.search)) {
            premiumMockTests = this.state.getStudentTestSeries.filter((item) => item.amount == 1)
            freeSchedule = this.state.getStudentTestSeries.filter((item) => item.is_scheduled == "1" && item.amount == "0" && currentTime_currentSec < item.end_time && item.exam_expired == false);
            Schedule = this.state.getStudentTestSeries.filter((item) => item.is_scheduled == "1" && item.exam_expired == false);
            islive = Schedule.find((item) => item.exam_started == true && item.is_completed == false);
            classTests = this.state.getStudentTestSeries.filter((item) => item.is_scheduled == "0" && item.amount == "0")
            getStudentTestSeries = this.state.getStudentTestSeries;
        } else {
            premiumMockTests = this.state.getStudentTestSeries.filter((item) => item.amount == 1 && item.id == notification_exam_id)
            freeSchedule = this.state.getStudentTestSeries.filter((item) => item.is_scheduled == "1" && item.amount == "0" && item.id == notification_exam_id);
            Schedule = this.state.getStudentTestSeries.filter((item) => item.is_scheduled == "1" && item.id == notification_exam_id);
            islive = Schedule.find((item) => item.exam_started == true && item.is_completed == false && item.id == notification_exam_id);
            classTests = this.state.getStudentTestSeries.filter((item) => item.is_scheduled == "0" && item.amount == "0" && item.id == notification_exam_id);
            getStudentTestSeries = this.state.getStudentTestSeries;
        }

        if (this.state.classData.value == 1) {
            premiumMockTests = premiumMockTests.filter((item) => item.class_id == 1 && item.exam_class_id == 1)
        } else if (this.state.classData.value == 2) {
            premiumMockTests = premiumMockTests.filter((item) => item.class_id == 2 && item.exam_class_id == 2)
        } else if (this.state.classData.value == 3) {
            premiumMockTests = premiumMockTests.filter((item) => (item.class_id == 1 || item.class_id == 2) && item.exam_class_id == 0)
        } else {
            premiumMockTests = premiumMockTests
        }

        let existingTime = '';
        if (islive != undefined) {
            const end_time = islive.end_time;
            const currentTime = this.state.currentTime;
            //console.log("existingTime1", end_time, currentTime);
            let diff = end_time - currentTime;


            if (diff > 0) {
                let hours = parseInt(diff / 3600);
                let min = parseInt((diff % 3600) / 60);
                let sec = parseInt((diff % 3600) % 60);

                hours = hours < 10 ? `0${hours}` : hours;
                min = min < 10 ? `0${min}` : min;
                sec = sec < 10 ? `0${sec}` : sec;

                existingTime = `${hours} : ${min} : ${sec}`;
            }

        }


        let isStudentUserValid = "";
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }

        console.log("isStudentUserValid", isStudentUserValid)

        return (
            <Container className={isMobile == true ? 'eamcet-mock-test mt-5' : 'eamcet-mock-test'} >
                <Row>
                    {this.props.data.location.pathname != "/student/action/ST_MockTests" ? (

                        <Col sm={12}>
                            {
                                this.state.status == true ?
                                    (<Button type="button" className='btn-custom-Red float-right f-14' onClick={() => this.liveMockHistory()}>View Mock Test History</Button>)
                                    :
                                    (<Button type="button" className='btn-custom-Red float-right f-14' onClick={() => this.liveMockHistory()}>Go To Mock Tests</Button>)
                            }

                        </Col>
                    ) : ("")}
                </Row>
                {
                    this.state.status == true ?
                        (
                            <>
                                <Row>
                                    {
                                        freeSchedule.length == 0 && this.props.data.location.pathname == "/student/action/ST_MockTests" ? ("") : (

                                            <Col sm={6} className='mt-2'>

                                                <h5 className='text-uppercase color_2b3b92'>Live Mock Tests

                                                </h5>
                                                <Card className='mock_test_bg mb-3'>
                                                    <Card.Body className='p-2'>
                                                        {this.state.regstatus == 2 ? (<span className="text-success f-12">
                                                            Mock Test Registered Successfully
                                                        </span>) : ("")}
                                                        {
                                                            freeSchedule.length > 0 ?
                                                                (<>
                                                                    {freeSchedule.map((item) => {
                                                                        console.log("item", item)
                                                                        return (
                                                                            <Card className="card-border-exams mb-3">
                                                                                <Card.Body>
                                                                                    <h6 className='float-right f-12'>
                                                                                        {
                                                                                            item.exam_started == false ?
                                                                                                (<span className="upcoming-class">Upcoming</span>)
                                                                                                :
                                                                                                (<span className="live-class">Live</span>)
                                                                                        }

                                                                                    </h6>
                                                                                    <Media>
                                                                                        <div className="date_block mr-3 text-center">
                                                                                            <p className="mt-1 f-22 border-bootom2"> {moment.unix(item.start_time).format("MMM")}</p>
                                                                                            <p className="mt-2 f-22">{moment.unix(item.start_time).format("DD")}</p>
                                                                                        </div>
                                                                                        <Media.Body>
                                                                                            <Card.Title>
                                                                                                <h6 className="f-14">{item.exam_name}</h6>
                                                                                            </Card.Title>

                                                                                            <Row>
                                                                                                <Col sm={12}>
                                                                                                    <p className="f-14">Time : {moment.unix(item.start_time).format("LT")} to {moment.unix(item.end_time).format("LT")}</p>
                                                                                                    <p className="f-14"> Duration : {item.exam_duration}</p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Media.Body>
                                                                                    </Media>
                                                                                    <Row>
                                                                                        <Col className="mt-2">
                                                                                            <Button type="button" className="btn btn-blue-exam" onClick={() => this.handleShowModal(item)}>View Syllabus</Button>
                                                                                        </Col>
                                                                                        <Col className="mt-2">

                                                                                            {
                                                                                                item.exam_expired == true ?
                                                                                                    (
                                                                                                        <h6 className="red_color float-right-resp" >Exam Expired </h6>
                                                                                                    )
                                                                                                    :
                                                                                                    (
                                                                                                        <>
                                                                                                            {
                                                                                                                item.is_completed == true ?
                                                                                                                    (
                                                                                                                        <Button type="button" className="btn btn-blue-exam float-right-resp" onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results </Button>
                                                                                                                    )
                                                                                                                    :
                                                                                                                    (
                                                                                                                        item.exam_started == false ?
                                                                                                                            (
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        item.is_registered == true ?
                                                                                                                                            (<Button type="button" className='btn-custom-Red float-right f-14 disabledCursor text-uppercase' disabled>Registered</Button>)
                                                                                                                                            :
                                                                                                                                            (<Button type="button" className='btn-custom-Red float-right f-14 text-uppercase' onClick={(e) => this.register(e, item.id)}>Register</Button>)
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                            )
                                                                                                                            :
                                                                                                                            (
                                                                                                                                item.exam_expired == true ?
                                                                                                                                    (<Button className='btn-custom-Red' disabled>Exam Expired</Button>)
                                                                                                                                    :
                                                                                                                                    (
                                                                                                                                        item.amount == 0 && item.is_purchased == true ?
                                                                                                                                            (
                                                                                                                                                <>
                                                                                                                                                    {
                                                                                                                                                        item.is_started == true ?
                                                                                                                                                            (<Button type="button" className='btn-custom-blue float-right f-14 text-uppercase' onClick={() => this.resumeExam(item, islive, "schedule")}>Resume Test</Button>)
                                                                                                                                                            :
                                                                                                                                                            (
                                                                                                                                                                moment.unix(item.start_time).format("DD-MMM-YYYY") == moment.unix(currentTime_currentSec).format("DD-MMM-YYYY") && parseInt(item.start_time - 600) < parseInt(currentTime_currentSec) ?

                                                                                                                                                                    (<Button type="button" className='btn-custom-blue float-right f-14 text-uppercase' onClick={() => this.startExam(item, islive, "schedule")}>Start Test</Button>)
                                                                                                                                                                    :
                                                                                                                                                                    (<Button type="button" className='btn-custom-blue float-right f-14 disabledCursor text-uppercase' onClick={() => this.startExam(item, islive, "schedule")} disabled>Start Test</Button>)
                                                                                                                                                            )
                                                                                                                                                    }
                                                                                                                                                </>
                                                                                                                                            )
                                                                                                                                            :
                                                                                                                                            ("")
                                                                                                                                    )
                                                                                                                            )
                                                                                                                    )
                                                                                                            }
                                                                                                        </>

                                                                                                    )


                                                                                            }

                                                                                        </Col>
                                                                                    </Row>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        )
                                                                    })
                                                                    }
                                                                </>)
                                                                :
                                                                (
                                                                    <Col sm={12}>
                                                                        <p className='text-center resp_view'>Coming Soon</p>
                                                                    </Col>
                                                                )
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )}
                                    {
                                        classTests.length == 0 && this.props.data.location.pathname == "/student/action/ST_MockTests" ? ("") : (

                                            <Col sm={6} className='mt-2'>
                                                <h5 className='text-uppercase color_2b3b92'>FREE MOCK TESTS</h5>
                                                <Card className='mock_test_bg'>
                                                    <Card.Body className='p-2'>
                                                        {
                                                            classTests.length > 0 ?
                                                                (<>
                                                                    {classTests.map((item) => {
                                                                        console.log("item", item)
                                                                        return (
                                                                            <Card className="card-border-exams mb-3">
                                                                                <Card.Body>
                                                                                    <Media>

                                                                                        <Media.Body>
                                                                                            <Card.Title>
                                                                                                <h6 className="f-14">{item.exam_name}
                                                                                                    <span className="float-right live-class">Free</span>

                                                                                                </h6>
                                                                                            </Card.Title>

                                                                                            <Row>
                                                                                                <Col sm={12}>
                                                                                                    <p className="f-14"> Duration : {item.exam_duration}</p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col className="mt_4_5">
                                                                                                    <Button type="button" className="btn btn-blue-exam" onClick={() => this.handleShowModal(item)}>View Syllabus</Button>
                                                                                                </Col>
                                                                                                <Col className="mt_4_5">

                                                                                                    {
                                                                                                        item.exam_expired == true ?
                                                                                                            (
                                                                                                                <h6 className="red_color float-right-resp" >Exam Expired </h6>
                                                                                                            )
                                                                                                            :
                                                                                                            (
                                                                                                                <>
                                                                                                                    {
                                                                                                                        item.is_completed == true ?
                                                                                                                            (
                                                                                                                                <Button type="button" className="btn btn-blue-exam float-right-resp text-uppercase" onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results </Button>
                                                                                                                            )
                                                                                                                            :
                                                                                                                            (
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        item.is_started == true ?
                                                                                                                                            (<Button type="button" className='btn-custom-blue float-right f-14 text-uppercase' onClick={() => this.resumeExam(item, islive, "free")}>Resume Test</Button>)
                                                                                                                                            :
                                                                                                                                            (
                                                                                                                                                <Button type="button" className='btn-custom-blue float-right f-14 text-uppercase' onClick={() => this.startExam(item, islive, "free")}>Start Test</Button>
                                                                                                                                            )

                                                                                                                                    }
                                                                                                                                </>

                                                                                                                            )
                                                                                                                    }
                                                                                                                </>

                                                                                                            )


                                                                                                    }

                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Media.Body>
                                                                                    </Media>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        )
                                                                    })
                                                                    }
                                                                </>)
                                                                :
                                                                (
                                                                    <Col sm={12}>
                                                                        <p className='text-center resp_view'>Coming Soon</p>
                                                                    </Col>
                                                                )
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    }
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <ul className='list-inline class_type_li mt-4'>
                                            <li className={this.state.classData.value == "0" ? "active " : ""} onClick={() => this.selectHandleInputChange("0")}>ALL</li>
                                            <li className={this.state.classData.value == "1" ? "active ml-4" : "ml-4"} onClick={() => this.selectHandleInputChange("1")}>Class XI</li>
                                            <li className={this.state.classData.value == "2" ? "active ml-4" : "ml-4"} onClick={() => this.selectHandleInputChange("2")}>Class XII</li>
                                            <li className={this.state.classData.value == "3" ? "active ml-4-res mob_view" : "ml-4-res mob_view"} onClick={() => this.selectHandleInputChange("3")}>Cumulative (XI + XII)</li>
                                        </ul>
                                    </Col>
                                </Row>
                                {/* {
                                    premiumMockTests.length == 0 && this.props.data.location.pathname == "/student/action/ST_MockTests" ? ("") : (

                                        <Row>
                                            <Col xs={12} className='mt-4'>
                                                <h5 className="color_2b3b92 h5 text-uppercase">Premium Mock Tests</h5>
                                            </Col>
                                            <Col>
                                                <Card as={Card.Body} className="mockpackage_list_card">
                                                    {premiumMockTests.length > 0 ? (
                                                        <Row>
                                                            {
                                                                premiumMockTests.map((item, index) => {
                                                                    return (
                                                                        <Col key={index} xl={2} lg={3} md={4} sm={6} xs={6}>
                                                                            <Card as={Card.Body} className="signle-mocktest p-3">
                                                                                {item.is_completed == true ? (
                                                                                    <div className="planTitle text-uppercase text-center text-white rounded mock-complated">Completed</div>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        {
                                                                                            item.amount == "0" ? (
                                                                                                <React.Fragment>
                                                                                                    {item.is_purchased == true ? (
                                                                                                        <React.Fragment>
                                                                                                            {item.amount == "0" ? (

                                                                                                                <div className="planTitle text-uppercase text-center text-white rounded">Free</div>
                                                                                                            ) : (

                                                                                                                <div className="planTitle text-uppercase text-center text-white rounded">{item.amount} /-</div>
                                                                                                            )}
                                                                                                        </React.Fragment>

                                                                                                    ) : (
                                                                                                        <div className="planTitle text-uppercase text-center text-white rounded">{item.amount} /-</div>
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            ) : (

                                                                                                <div className="planTitle text-uppercase text-center text-white rounded">Premium</div>
                                                                                            )
                                                                                        }
                                                                                    </React.Fragment>
                                                                                )}

                                                                                <div className="mocktest-content text-center pt-3">
                                                                                    <h5 className="test-series text-uppercase mock-text-color mb-0">{item.exam_name}</h5>
                                                                                    <p className="text-uppercase font-weight-normal mock-text-color">{item.exam_duration}</p>
                                                                                    {
                                                                                        item.exam_session_id != 0 ? (
                                                                                            <React.Fragment>
                                                                                                <Card as={Card.Body} className="my-3 mockavgtime-card border-0 bg-darkblue p-1 text-center">
                                                                                                    <ul className="list-inline dot-circle pr-1 mb-1">
                                                                                                        <li className="list-inline-item"><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /></li>
                                                                                                        <li className="list-inline-item"><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /></li>
                                                                                                        <li className="list-inline-item"><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /></li>
                                                                                                    </ul>
                                                                                                    <h6 className="status-subtitle mb-0">Score</h6>
                                                                                                    <h1 className="status-title mb-0">{this.graphValue(item)} <small>/ {item.total_marks}</small></h1>
                                                                                                </Card>
                                                                                                <Button className={`mt-3 btn-mock mock-teststart`} onClick={() => this.handleResultFunction(item.exam_session_id)}>View Result</Button>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                            :
                                                                                            item.is_purchased ?
                                                                                                (
                                                                                                    <>
                                                                                                        {
                                                                                                            item.exam_started ? (
                                                                                                                <React.Fragment>
                                                                                                                    <Image src={require('../../../images/mock-test-pad.png')} alt="img" className="my-3" width="100" />
                                                                                                                    {(item.is_started == false) ?
                                                                                                                        (<Button className={`mt-3 btn-mock mock-teststart`} onClick={() => this.startExam(item)}>Start Test</Button>) :
                                                                                                                        (<Button className={`mt-3 btn-mock mock-teststart`} onClick={() => this.resumeExam(item)}>Resume Test</Button>)}
                                                                                                                </React.Fragment>
                                                                                                            )
                                                                                                                :
                                                                                                                (
                                                                                                                    <React.Fragment>
                                                                                                                        <Card as={Card.Body} className="my-3 mockavailable-card border-0 bg-darkblue p-x-1 py-2 text-center text-white">
                                                                                                                            <h6 className="mockavailable-title">Available</h6>
                                                                                                                            <div className="date-block">
                                                                                                                                <div className="month">{moment.unix(item.start_time).format("MMM")}</div>
                                                                                                                                <div className="divider" />
                                                                                                                                <div className="date">{moment.unix(item.start_time).format("DD")}</div>
                                                                                                                            </div>
                                                                                                                        </Card>
                                                                                                                        <Button className={`mt-3 btn-mock mock-complated`}>Coming Soon</Button>
                                                                                                                    </React.Fragment>
                                                                                                                )
                                                                                                        }
                                                                                                    </>

                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div className="mackTestSubscribe my-3">
                                                                                                            <Image src={require('../../../images/mock-test-pad.png')} alt="img" width="100" />
                                                                                                        </div>
                                                                                                        <Button onClick={() => this.modalaFun(item.exam_name)} className={`mt-3 btn-mock mock-complated`}>Subscribe</Button>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                    }


                                                                                </div>
                                                                            </Card>
                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    ) : (

                                                        <div className="justify-content-center flex-row text-danger">
                                                            Coming Soon
                                                        </div>

                                                    )}


                                                </Card>
                                            </Col>
                                            
                                        </Row>
                                    )
                                } */}
                                {
                                    premiumMockTests.length == 0 && this.props.data.location.pathname == "/student/action/ST_MockTests" ? ("") :
                                        (
                                            <Row>
                                                <Col xs={12} className='mt-4'>
                                                    <h5 className="color_2b3b92 h5 text-uppercase">Premium Mock Tests</h5>
                                                    <Card className='mock_test_bg'>
                                                        <Card.Body className='p-2'>
                                                            {
                                                                premiumMockTests.length > 0 ?
                                                                    (
                                                                        <Row>
                                                                            {
                                                                                premiumMockTests.map((item, index) => {
                                                                                    return (
                                                                                        <Col sm={4}>
                                                                                            <Card className="card-border-exams mb-3">
                                                                                                <Card.Body>
                                                                                                    <Media>
                                                                                                        <Media.Body>
                                                                                                            <Card.Title>
                                                                                                                <h6 className="f-14">{item.exam_name}
                                                                                                                    <span className="float-right live-class-prem">Premium</span>
                                                                                                                </h6>
                                                                                                                <Row>
                                                                                                                    <Col sm={12}>
                                                                                                                        <p className="duration"> Duration : {item.exam_duration}</p>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                                <Row>
                                                                                                                    <Col className="mt_4_5">
                                                                                                                        <Button type="button" className="btn btn-blue-exam" onClick={() => this.handleShowModal(item)}>View Syllabus</Button>
                                                                                                                    </Col>
                                                                                                                    <Col className="mt_4_5">
                                                                                                                        {
                                                                                                                            item.exam_session_id != 0 ?
                                                                                                                                (
                                                                                                                                    <Button type="button" className="btn btn-custom-blue float-right-prem-resp text-uppercase" onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results </Button>
                                                                                                                                )
                                                                                                                                :
                                                                                                                                item.is_purchased == false ?
                                                                                                                                    (
                                                                                                                                        <>
                                                                                                                                            {
                                                                                                                                                item.exam_started == true ?
                                                                                                                                                    (
                                                                                                                                                        item.is_started == false ?
                                                                                                                                                            (<Button type="button" className='btn-custom-blue float-right-prem-resp f-14 text-uppercase' onClick={() => this.startExam(item)}>Start Test</Button>)
                                                                                                                                                            :
                                                                                                                                                            (<Button type="button" className='btn-custom-blue float-right-prem-resp f-14 text-uppercase' onClick={() => this.resumeExam(item)}>Resume Test</Button>)
                                                                                                                                                    )
                                                                                                                                                    :
                                                                                                                                                    (<Button className={`mt-3 btn-mock mock-complated`}>Coming Soon</Button>)

                                                                                                                                            }
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                    :
                                                                                                                                    (
                                                                                                                                        <Button onClick={() => this.modalaFun(item.exam_name)} className="btn btn-custom-Red float-right-prem-resp f-14 text-uppercase">Subscribe</Button>
                                                                                                                                    )
                                                                                                                        }


                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </Card.Title>
                                                                                                        </Media.Body>
                                                                                                    </Media>

                                                                                                </Card.Body>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Row>

                                                                    )
                                                                    :
                                                                    (<div className="justify-content-center flex-row text-danger">
                                                                    Coming Soon
                                                                </div>)
                                                            }

                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )
                                }
                            </>
                        )
                        :
                        (
                            <EamcetMockTestHistoryFree getStudentTestSeries={getStudentTestSeries} />


                        )
                }

                <ViewSyllabusEamcet show={this.state.viewSyllabusModal} syllabusDetails={this.state.viewSyllabusDetails} onHide={() => this.setState({ viewSyllabusModal: false })} />
                <EamcetRestrictionAlert
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />
            
            </Container>
        )
    }


}
export default withRouter(compose(
    graphql(MOCKTEST_REGISTER_EAMCET, {
        name: "register"
    })
)(EamcetMockTestsSection));
