import React, { Component } from 'react'
import { Row, Col, Button, Alert, Card, Form, Modal, Popover, OverlayTrigger } from 'react-bootstrap'
import { components } from 'react-select'
import SelectDropDown from '../../../../neetjee_guru/components/selectdropdown/SelectDropDown';
import parse, { domToReact } from 'html-react-parser';

import '../_preexam.scss';
import '../../learn_practice/start_error_exam/start_error_page/_errorexam.scss';
import SingleOption1 from '../../learn_practice/start_error_exam/start_error_page/SingleOption1';
import gql from "graphql-tag";
import { withRouter } from "react-router-dom";
import { withApollo } from 'react-apollo';
const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
};






class ViewQuestionAnswerSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            innerreloader: "0",

        }
    }
    idFunction(data) {
        let id = parseInt(data + 1);
        return id;
    }
    isValidJSON() {
        console.log("srefsgs12");
        let question1 = this.props.stateData.questions[this.props.stateData.index].question.replace(/src="/g, 'src=\\"');
        console.log("question1", question1);
        let question2 = question1.replace(/" \/>/g, '\\" />');
        console.log("question2", question2);


        try {
            let question = JSON.parse(question2);
            console.log("isValidJSONtrue");

            return (

                <ol className="ml-3 p-0" type={
                    this.props.stateData.questions[this.props.stateData.index].list1type == "alphabets" ? ("A")
                        : (this.props.stateData.questions[this.props.stateData.index].list1type == "numbers") ? ("1")
                            : ("roman")}

                >
                    {question.map((item) => (
                        <li>{this.parseFun(item.qlist1)}</li>
                    ))}


                </ol>
            );

        } catch (ex) {
            console.log("isValidJSONfalse");
            return false;
        }
    }
    isValidJSON2(str) {
        console.log("srefsgs123");
        let question1 = this.props.stateData.questions[this.props.stateData.index].question.replace(/src="/g, 'src=\\"');
        let question2 = question1.replace(/" \/>/g, '\\" />');
        try {

            let question = JSON.parse(question2);
            console.log("isValidJSONtrue", question);
            return (
                <ol className="ml-3 p-0" type={
                    this.props.stateData.questions[this.props.stateData.index].list2type == "alphabets" ? ("A")
                        : (this.props.stateData.questions[this.props.stateData.index].list2type == "numbers") ? ("1")
                            : ("roman")}>

                    {question.map((item) => (
                        <li>{this.parseFun(item.qlist2)}</li>
                    ))}

                </ol>
            );

        } catch (ex) {
            console.log("isValidJSONfalse");
            return false;
        }
    }
    parseFun(str) {
        console.log("str", str);
        if (str != undefined || str != "") {
            try {
                return parse(str);
            } catch (ex) {
                return false;
            }
        }
        else {
            return false;
        }

    }

    onScrollgetQuestionById = async (e) => {

        console.log("onScrollgetQuestionById");
        this.setState({
            innerreloader: "1"
        });
        const result = await this.props.client.query({
            query: gql` 
            query(
                $question_id: Int, 
                $selection_type: Int
                
                ) {
                    getQuestionById(
                    question_id: $question_id,
                    selection_type: $selection_type
                    )
                    {
                        id
                        question
                        option1
                        option2
                        option3
                        option4
                        compquestion
                        list1type
                        list2type
                        mat_question
                        inputquestion
                        explanation
                }
            }
        `,
            variables: {
                question_id: parseInt(this.props.stateData.questions[this.props.stateData.index].id),
                // selection_type:parseInt(this.props.stateData.questions[this.props.stateData.index].selection_type)
                selection_type: 2
            },
        })

        console.log("newstyledata1234", result.data.getQuestionById);

        this.setState({ innerreloader: "0" });
        if (result.data.getQuestionById.length > 0) {
            this.props.parentonScrollgetQuestionById(result.data.getQuestionById);
        }

    }

    handleReasonsChange = (e) => {
        console.log("ssssss", e);
        const name = e.target.name;
        const value = e.target.value;
        if (name == "errorReasons") {
            this.setState({
                errorReasons: value
            })
        }
        if (name == "rcomments") {
            this.setState({
                rcomments: value
            })
        }
    }

    reasonFun(data) {
        let sarray = [];

        let obj = { value: 0, label: "Select" };

        sarray.push(obj)
        for (let i = 0; i < data.length; i++) {
            let idata = data[i];
            const obj = {
                value: idata.id,
                label: idata.reason,
            }
            sarray.push(obj);
        }
        return sarray;
    }

    render() {
        console.log("dddd1232", this.props.stateData, this.state.rcomments);

        const lqlength = this.props.stateData.questions.length - 1;
        const SectionData = [
            { value: 'NEET 2020', label: 'NEET 2020', color: '#00B8D9', isFixed: true },
            { value: 'JEE 2020', label: 'JEE 2020', color: '#0052CC', isFixed: true },
            { value: 'EAMCET 2020', label: 'EAMCET 2020', color: '#5243AA' },
        ];


        return (
            <React.Fragment>

                {this.props.stateData.questions.length > 0 ? (<div className="error_exam_block py-3">
                    <Row onClick={this.props.onClick}>
                        <Col xl={2} lg={{ span: 2, order: 1 }} md={{ span: 6, order: 1 }} sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}>
                            <div className="time-spent my-2">

                            </div>
                        </Col>
                        {this.props.stateData.questions[this.props.stateData.index].qtype == "9" || this.props.stateData.questions[this.props.stateData.index].qtype == "3" ? (
                            <Col xl={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} md={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} xs={{ span: 12, order: 3 }}>

                                <div className="question_area my-2">
                                    <div className="q_Name">
                                        <span className="q_No">
                                            {this.idFunction(this.props.stateData.index)}.
                                        </span>
                                        {this.parseFun(this.props.stateData.questions[this.props.stateData.index].mat_question)}
                                        {
                                            " QID- " + this.props.stateData.questions[this.props.stateData.index].id
                                        }
                                        {this.props.stateData.questions.length > 0 ?
                                            (

                                                <Row>
                                                    <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                        <h5 className="list-title"> List I</h5>

                                                        {this.isValidJSON()}
                                                    </Col>
                                                    <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                        <h5 className="list-title"> List II</h5>
                                                        {this.isValidJSON2()}
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ("")}

                                    </div>

                                    {this.state.innerreloader == "1" || this.props.stateData.reloader == "2" ? (<div className="text-primary">Reloading</div>) : (<div style={{ color: "#ff0000" }}><a><i onClick={(e) => this.onScrollgetQuestionById(e)} className="fa fa-repeat"> <span >Reload</span></i></a>
                                        <small style={{ color: "#2a346c", fontWeight: "bold" }}>&nbsp;&nbsp;&nbsp;*Note: If you are unable to view the question properly,  click on Reload to refresh the Question</small></div>)}
                                    {this.props.stateData.reloader == "1" ? (
                                        <div className="text-success">Question reloaded</div>
                                    ) : ("")}

                                    <div className="q_options mt-4">
                                        <SingleOption1
                                            option="A"
                                            status={
                                                this.props.stateData.questions[this.props.stateData.index].answer.includes("A") ? true : this.props.stateData.questions[this.props.stateData.index].attempt_answer == "A" ? false : null}
                                            showMessage={true}
                                            optionText={this.props.stateData.questions.length > 0 ? (this.parseFun(this.props.stateData.questions[this.props.stateData.index].option1)) : ("")}
                                            controlId="formBasicCheckboxOne"
                                        />
                                        <SingleOption1
                                            option="B"
                                            status={
                                                this.props.stateData.questions[this.props.stateData.index].answer.includes("B") ? true : this.props.stateData.questions[this.props.stateData.index].attempt_answer == "B" ? false : null}
                                            showMessage={true}
                                            optionText={this.props.stateData.questions.length > 0 ? (this.parseFun(this.props.stateData.questions[this.props.stateData.index].option2)) : ("")}
                                            controlId="formBasicCheckboxTwo" />
                                        <SingleOption1
                                            option="C"
                                            status={
                                                this.props.stateData.questions[this.props.stateData.index].answer.includes("C") ? true : this.props.stateData.questions[this.props.stateData.index].attempt_answer == "C" ? false : null}
                                            optionText={this.props.stateData.questions.length > 0 ? (this.parseFun(this.props.stateData.questions[this.props.stateData.index].option3)) : ("")}
                                            showMessage={true}
                                            controlId="formBasicCheckboxThree"
                                        />
                                        <SingleOption1
                                            option="D"
                                            status={
                                                this.props.stateData.questions[this.props.stateData.index].answer.includes("D") ? true : this.props.stateData.questions[this.props.stateData.index].attempt_answer == "D" ? false : null}
                                            showMessage={true}
                                            optionText={this.props.stateData.questions.length > 0 ? (this.parseFun(this.props.stateData.questions[this.props.stateData.index].option4)) : ("")}
                                            controlId="formBasicCheckboxFour" />
                                    </div>

                                </div>
                            </Col>
                        ) : (this.props.stateData.questions[this.props.stateData.index].qtype == "8") ? (
                            <Col xl={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} md={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} xs={{ span: 12, order: 3 }}>
                                <div className="question_area my-2">

                                    <div className="q_Name">
                                        <span className="q_No">
                                            {this.idFunction(this.props.stateData.index)}
                                        </span>
                                        {this.props.stateData.questions.length > 0 ?
                                            (
                                                this.parseFun(this.props.stateData.questions[this.props.stateData.index].question)
                                            )
                                            :
                                            ("")}
                                        {
                                            " QID- " + this.props.stateData.questions[this.props.stateData.index].id
                                        }
                                    </div>

                                    <React.Fragment>
                                        {this.props.stateData.questions[this.props.stateData.index].status == "2" ?
                                            (<Card as={Card.Body} className="flex-row justify-content-between align-items-center">
                                                <div className="option_name">
                                                    {(this.props.stateData.questions[this.props.stateData.index].attempt_answer)}
                                                </div>
                                                <div className="option_selected text-success">
                                                    <span className="mr-2">Attempted Answer</span><i className="fal fa-check-circle" />
                                                </div>
                                            </Card>) : this.props.stateData.questions[this.props.stateData.index].status == "1" ? (
                                                <React.Fragment>
                                                    <Card as={Card.Body} className="flex-row justify-content-between align-items-center">
                                                        <div className="option_name">
                                                            {(this.props.stateData.questions[this.props.stateData.index].attempt_answer)}
                                                        </div>
                                                        <div className="option_selected text-danger">
                                                            <span className="mr-2">Attempted Answer</span><i className="fal fa-times-circle" />
                                                        </div>
                                                    </Card><br />
                                                    <Card as={Card.Body} className="flex-row justify-content-between align-items-center">
                                                        <div className="option_name">
                                                            {(this.props.stateData.questions[this.props.stateData.index].answer)}
                                                        </div>
                                                        <div className="option_selected text-success">
                                                            <span className="mr-2">Correct Answer</span><i className="fal fa-check-circle" />
                                                        </div>
                                                    </Card>
                                                </React.Fragment>
                                            ) : ("")}
                                    </React.Fragment>





                                </div>
                            </Col>) : (this.props.stateData.questions[this.props.stateData.index].qtype == "5") ? (
                                <Col xl={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} md={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} xs={{ span: 12, order: 3 }}>
                                    <div className="question_area my-2">
                                        <Card className="p-3 mb-2 Qparagraph ">
                                            <Card.Text className="text-justify">{this.parseFun(this.props.stateData.questions[this.props.stateData.index].compquestion)}</Card.Text>
                                        </Card>

                                        <div className="q_Name">
                                            <span className="q_No">
                                                {this.idFunction(this.props.stateData.index)}
                                            </span>
                                            {this.props.stateData.questions.length > 0 ?
                                                (
                                                    this.parseFun(this.props.stateData.questions[this.props.stateData.index].question)
                                                )
                                                :
                                                ("")}
                                            {
                                                " QID- " + this.props.stateData.questions[this.props.stateData.index].id
                                            }
                                        </div>

                                        {this.state.innerreloader == "1" || this.props.stateData.reloader == "2" ? (<div className="text-primary">Reloading</div>) : (<div style={{ color: "#ff0000" }}><a><i onClick={(e) => this.onScrollgetQuestionById(e)} className="fa fa-repeat"> <span >Reload</span></i></a>

                                            <small style={{ color: "#2a346c", fontWeight: "bold" }}>&nbsp;&nbsp;&nbsp;*Note: If you are unable to view the question properly,  click on Reload to refresh the Question</small></div>)}
                                        {this.props.stateData.reloader == "1" ? (
                                            <div className="text-success">Question reloaded</div>
                                        ) : ("")}


                                        <div className="q_options mt-4">
                                            <SingleOption1
                                                option="A"
                                                status={
                                                    this.props.stateData.questions[this.props.stateData.index].answer.includes("A") ? true : this.props.stateData.questions[this.props.stateData.index].attempt_answer == "A" ? false : null}
                                                showMessage={true}
                                                optionText={this.props.stateData.questions.length > 0 ? (this.parseFun(this.props.stateData.questions[this.props.stateData.index].option1)) : ("")}
                                                controlId="formBasicCheckboxOne"
                                            />
                                            <SingleOption1
                                                option="B"
                                                status={
                                                    this.props.stateData.questions[this.props.stateData.index].answer.includes("B") ? true : this.props.stateData.questions[this.props.stateData.index].attempt_answer == "B" ? false : null}
                                                showMessage={true}
                                                optionText={this.props.stateData.questions.length > 0 ? (this.parseFun(this.props.stateData.questions[this.props.stateData.index].option2)) : ("")}
                                                controlId="formBasicCheckboxTwo" />
                                            <SingleOption1
                                                option="C"
                                                status={
                                                    this.props.stateData.questions[this.props.stateData.index].answer.includes("C") ? true : this.props.stateData.questions[this.props.stateData.index].attempt_answer == "C" ? false : null}
                                                optionText={this.props.stateData.questions.length > 0 ? (this.parseFun(this.props.stateData.questions[this.props.stateData.index].option3)) : ("")}
                                                showMessage={true}
                                                controlId="formBasicCheckboxThree"
                                            />
                                            <SingleOption1
                                                option="D"
                                                status={
                                                    this.props.stateData.questions[this.props.stateData.index].answer.includes("D") ? true : this.props.stateData.questions[this.props.stateData.index].attempt_answer == "D" ? false : null}
                                                showMessage={true}
                                                optionText={this.props.stateData.questions.length > 0 ? (this.parseFun(this.props.stateData.questions[this.props.stateData.index].option4)) : ("")}
                                                controlId="formBasicCheckboxFour" />
                                        </div>
                                    </div>
                                </Col>
                            ) : (<Col xl={{ span: 8, order: 2 }} lg={{ span: 8, order: 2 }} md={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} xs={{ span: 12, order: 3 }}>
                                <div className="question_area my-2">
                                    <div className="q_Name">
                                        <span className="q_No">
                                            {this.idFunction(this.props.stateData.index)}
                                        </span>
                                        {this.props.stateData.questions.length > 0 ?
                                            (
                                                this.parseFun(this.props.stateData.questions[this.props.stateData.index].question)
                                            )
                                            :
                                            ("")}
                                        {
                                            " QID- " + this.props.stateData.questions[this.props.stateData.index].id
                                        }
                                    </div>

                                    {this.state.innerreloader == "1" || this.props.stateData.reloader == "2" ? (<div className="text-primary">Reloading</div>) : (<div style={{ color: "#ff0000" }}><a><i onClick={(e) => this.onScrollgetQuestionById(e)} className="fa fa-repeat"> <span >Reload</span></i></a>

                                        <small style={{ color: "#2a346c", fontWeight: "bold" }}>&nbsp;&nbsp;&nbsp;*Note: If you are unable to view the question properly,  click on Reload to refresh the Question</small></div>)}
                                    {this.props.stateData.reloader == "1" ? (
                                        <div className="text-success">Question reloaded</div>
                                    ) : ("")}


                                    <div className="q_options mt-4">
                                        <SingleOption1
                                            option="A"
                                            status={
                                                this.props.stateData.questions[this.props.stateData.index].answer.includes("A") ? true : this.props.stateData.questions[this.props.stateData.index].attempt_answer == "A" ? false : null}
                                            showMessage={true}
                                            optionText={this.props.stateData.questions.length > 0 ? (this.parseFun(this.props.stateData.questions[this.props.stateData.index].option1)) : ("")}
                                            controlId="formBasicCheckboxOne"
                                        />
                                        <SingleOption1
                                            option="B"
                                            status={
                                                this.props.stateData.questions[this.props.stateData.index].answer.includes("B") ? true : this.props.stateData.questions[this.props.stateData.index].attempt_answer == "B" ? false : null}
                                            showMessage={true}
                                            optionText={this.props.stateData.questions.length > 0 ? (this.parseFun(this.props.stateData.questions[this.props.stateData.index].option2)) : ("")}
                                            controlId="formBasicCheckboxTwo" />
                                        <SingleOption1
                                            option="C"
                                            status={
                                                this.props.stateData.questions[this.props.stateData.index].answer.includes("C") ? true : this.props.stateData.questions[this.props.stateData.index].attempt_answer == "C" ? false : null}
                                            optionText={this.props.stateData.questions.length > 0 ? (this.parseFun(this.props.stateData.questions[this.props.stateData.index].option3)) : ("")}
                                            showMessage={true}
                                            controlId="formBasicCheckboxThree"
                                        />
                                        <SingleOption1
                                            option="D"
                                            status={
                                                this.props.stateData.questions[this.props.stateData.index].answer.includes("D") ? true : this.props.stateData.questions[this.props.stateData.index].attempt_answer == "D" ? false : null}
                                            showMessage={true}
                                            optionText={this.props.stateData.questions.length > 0 ? (this.parseFun(this.props.stateData.questions[this.props.stateData.index].option4)) : ("")}
                                            controlId="formBasicCheckboxFour" />
                                    </div>
                                </div>
                            </Col>)}
                    </Row>


                    <React.Fragment>
                        <Row>
                            <Col xl={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} md={12} sm={12}>
                                <Alert variant="success" className="my-3 border-success">
                                    <h5>Solution</h5>
                                    <p>{this.parseFun(this.props.stateData.questions[this.props.stateData.index].explanation)}</p>
                                </Alert>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} md={12} sm={12}>
                                {this.props.stateData.questions != undefined ? (
                                    <React.Fragment>
                                        {this.props.stateData.questions[this.props.stateData.index].status == "1" ? (

                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <Card.Body>
                                                            <Form>
                                                                <Form.Row>
                                                                    <Form.Group as={Col} lg={6} md={6} sm={12} controlId="SelectReasons">
                                                                        <Form.Label>Reason For Wrong Attempt</Form.Label>
                                                                        <SelectDropDown
                                                                            name="reason"
                                                                            options={this.reasonFun(this.props.studentGlobals.errorReasons)}
                                                                            placeholderName={'Select Reasons'}
                                                                            dropdownIndicator={{ DropdownIndicator }}
                                                                            stateData={this.props.stateData.reason_for_wrong}
                                                                            handleChange={this.props.ParentSelecthandleChange} />
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} lg={6} md={6} sm={12} controlId="SelectTextarea">
                                                                        <Form.Label>Some Comments</Form.Label>
                                                                        <Form.Control
                                                                            name="rcomments"
                                                                            onChange={this.props.pCommentsFunction}
                                                                            disabled={this.props.stateData.reason_for_wrong.value == 0 ? true : false}
                                                                            value={this.props.stateData.comments}
                                                                            as="textarea" rows="3" />
                                                                    </Form.Group>
                                                                </Form.Row>
                                                            </Form>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>

                                        ) : (
                                            ""
                                        )}
                                    </React.Fragment>) : ("")}
                            </Col>
                        </Row>

                        <Row className="text-center mt-3">
                            <Col xl={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} md={12} sm={12} xs={12} className="paginations-btns">

                                {this.props.stateData.index > 0 ? (
                                    <Button variant="outline-primary" className="px-5 m-2" onClick={this.props.PreQuestionfunction}>Previous Question</Button>
                                ) : ("")}
                                {this.props.stateData.index != lqlength ? (<Button variant="outline-success" className="px-5 m-2" onClick={this.props.PnextQuestionfunction}>Next Question</Button>) : ("")}
                            </Col>
                        </Row>
                    </React.Fragment>

                </div>) : (
                    <div className="text-center pt-5">
                        <span className="text-danger">No Data Available</span>
                    </div>
                )
                }
            </React.Fragment>

        )
    }
}

export default withRouter(withApollo(ViewQuestionAnswerSection));


