import React, { Component } from 'react';
import TinyMCE from 'react-tinymce';
import { Form } from 'react-bootstrap';


class TextEditor extends Component {
    handleEditorChange(e) {
        console.log(e.target.getContent());
    }
    constructor(props){
        super(props);
        let plugins=[];
        let toolbar="";
        let height=300;
        let content="";
        if(this.props.type=="studentnote"){
            height=360;
            plugins= [
                'image',
                'searchreplace wordcount visualblocks code fullscreen',
                'insertdatetime media table contextmenu paste code'
            ]
            content=this.props.content
            
            toolbar= 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent| image | fullscreen'
        }
        else{
            plugins= [
                'advlist autolink lists link image charmap print preview anchor',
                // 'searchreplace wordcount visualblocks code fullscreen',
                'insertdatetime media table contextmenu paste code'
            ]
            //fullscreen
            toolbar= 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image'
        }
        this.state={
            plugins:plugins,
            toolbar:toolbar,
            content:content,
            height:height
        }
    }
    render() {
        console.log("textcurrentstate", this.state);
        return (
            <React.Fragment>
            <Form.Group controlId="my-file" style={{ display: "none" }}>
                <Form.Control type="file" name="my-file" onChange="" />
            </Form.Group>
            <TinyMCE
                content={this.state.content}

                config={{
                    selector: 'textarea',
                    theme: 'modern',
                    height: this.state.height,
                    menubar: false,
                    plugins: this.state.plugins,
                    toolbar: this.state.toolbar,
                   
                    content_css: '//www.tinymce.com/css/codepen.min.css',
                    
                    file_browser_callback_types: 'image',
                    
                    file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype == 'image') {
                            var input = document.getElementById('my-file');
                            input.click();
                            input.onchange = function () {
                                var file = input.files[0];
                                var reader = new FileReader();
                                reader.onload = function (e) {
                                    console.log('name', e.target.result);
                                    callback(e.target.result, {
                                        alt: file.name
                                    });
                                };
                                reader.readAsDataURL(file);
                            };
                        }
                    },
                    paste_data_images: true,

                }}

                onChange={this.props.handleEditorChange}
            />
        </React.Fragment>


        )
    }
}

export default TextEditor;
