import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import SeriesExamSectionMiddle from '../components/exams/test_series_test/SeriesExamSectionMiddle';
import * as Cookies from "es-cookie";
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import * as compose from 'lodash.flowright';
import PreloaderTwo from '../components/preloader/PreloaderTwo'
import { Beforeunload } from 'react-beforeunload';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
const FETCH_STUDENTEXAMQUESTION = gql`
  query(
          $mobile: String!, $exam_session_id: Int!,$type: String
$exam_paper_id: Int) {
    
            getStudentExamQuestions(mobile: $mobile, exam_session_id: $exam_session_id,
                type: $type,
                exam_paper_id: $exam_paper_id){
              id
              subject
              examType
              sub_exam_type
              question
              option1
              option2
              option3
              option4
              qtype
              compquestion
              list1type
              list2type
              mat_question
              explanation
              answer
              inputquestion
              bookmarked
              report{
                report_id
                comments
              }
              notes{
                tags
                comments
              }
              subject_name
              mains_2021
              selection_type
              section_name
              no_of_questions
              no_of_qus_answer
              exam_pattern_id
              no_of_marks
              no_of_neg_marks
              order_by
              indexvalue
              starting_no_val
              ending_no_val
          }
      }
`;
const AUTO_SAVE_TIME = gql` 
query($mobile: String!) {
    getAutoTypeExam(mobile: $mobile){
        type_name
        value
    }
}
`;
const AUTO_SUBMIT_TIME = gql` 
query($mobile: String!,$exam_paper_id:Int!) {
    getAutoSubmitTime(mobile: $mobile,exam_paper_id:$exam_paper_id){
        auto_submit_time
    }
}
`;
const GET_AUTO_SAVE_TIME = gql` 
query($mobile: String!,$exam_paper_id: Int!,$source:String!) {
    getAutoSaveExam(mobile: $mobile,exam_paper_id: $exam_paper_id,source:$source){
        qsno
        questions_data
        mobile
        exam_paper_id
        examtimer
        uid
    }
}
`;


const FETCH_EXAMINSTRUCTIONS = gql`
  query(
          $mobile: String!, $exam_session_id: Int!,$type: String
$exam_paper_id: Int) {
    
    getExamInstructions(mobile: $mobile, exam_session_id: $exam_session_id,
                type: $type,
                exam_paper_id: $exam_paper_id){
                is_exam
                exam_id
                exam_model_id
                exam_type
                instructions
                exam_status
                status_text
                is_partexam
                exam_pattern_id
                time_duration
                start_time
                exam_duration_min
          }
      }
`;

const STUDENT_GLOBALS = gql`
  query(
          $mobile: String!) {
            studentGlobals(mobile: $mobile){
                errorReasons{
                    id
                    reason
                }
                reports{
            id
            report
        }
        tags{
            id
            tag
            type
        }
        exam_time
       
              
          }
      }
`;

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

class SeriesExamTest extends Component {
    constructor(props) {
        super(props)
        this.state={
            submitError:""
        }
        let resumeExam = false;
        let local = localStorage.getItem("resumeExam");
        if (props.history.location.state && props.history.location.state.resumeExam && props.history.location.state.resumeExam == true) {
            resumeExam = true;
        } else if (!!local && local == "true") {
            resumeExam = true;
        }
        this.state = {
            modal: false,
            resumeExam: resumeExam
        }
    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    handleWindow = e => {
        //alert("hello: " + e.type);
    };
    componentDidMount() {
        this.addStudentAnalytics();

        if (Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8) {
            const title = GoogleAnalyticsArray[0].Eamcet_Mock_Instructions;
            ReactGA.pageview('/student/subject/series_test', title);
        }

        window.onpopstate = e => {
            this.props.history.replace("/student/home", "urlhistory");

        };
        window.addEventListener('contextmenu',
            event => event.preventDefault());

        window.addEventListener("selectstart", e => {
            e.preventDefault();
            e.returnValue = "false";

        });

        window.addEventListener("keypress", e => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        });
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""
        if(localStorage.getItem("examtype_pattern") == "series_test_NewNTA" || localStorage.getItem("examtype_pattern") == "series_test" ){
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 8,
                source: 2
            }
        } else if(localStorage.getItem("examtype_pattern") == "series_test_pattern"){
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 154,
                source: 2
            }
        }
        else {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 106,
                source: 2
            }
        }
        
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    componentWillUnmount() {
        //this.props.history.replace('', null);
        //this.props.history.replace("/student/home", "urlhistory");
        //console.log("componentWillUnmount", window, this.props);
        window.removeEventListener('contextmenu', this.handleWindow);
        window.removeEventListener("selectstart", this.handleWindow);
        window.removeEventListener("keypress", this.handleWindow);
    }

    render() {
        console.log("ttt",localStorage.getItem("examtype_pattern"))

        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");
        const localData = {
            sessionid: localStorage.getItem('sessionid'),
            type: localStorage.getItem('examname'),
            stype: localStorage.getItem('stype'),
            exam_paper_id: localStorage.getItem('exam_paper_id'),
            etype: localStorage.getItem('etype'),
            mocktest: localStorage.getItem('mocktest')
        }
        const getStudentExamQuestions = this.props.getStudentExamQuestions;
        const loading1 = getStudentExamQuestions.loading;
        const error1 = getStudentExamQuestions.error;
        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        const studentGlobals = this.props.studentGlobals;
        const loading2 = studentGlobals.loading;
        const error2 = studentGlobals.error;
        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }

        const getExamInstructions = this.props.getExamInstructions;
        const loading3 = getExamInstructions.loading;
        const error3 = getExamInstructions.error;

        const getAutoTypeExam = this.props.getAutoTypeExam;
        const loading4 = getAutoTypeExam.loading;
        const error4 = getAutoTypeExam.error;

        const getAutoSaveExam = this.props.getAutoSaveExam;
        const loading5 = getAutoSaveExam.loading;
        const error5 = getAutoSaveExam.error;

        const getAutoSubmitTime = this.props.getAutoSubmitTime;
        const loading6 = getAutoSubmitTime.loading;
        const error6 = getAutoSubmitTime.error;
        if (error3 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }
        //console.log("getExamInstructions", getExamInstructions.getExamInstructions);
        return (

            <Beforeunload onBeforeunload={() => "Hi"}>
                <div className={Cookies.get("toggle")}>
                    {(loading1 == true || loading3 == true || loading2 == true) && (<PreloaderTwo />)}
                    {
                        !loading1 && !loading3 && !loading2 && (

                            <SeriesExamSectionMiddle
                                getExamInstructions={getExamInstructions.getExamInstructions}
                                changeToggle={this.menuToggler}
                                studentGlobals={studentGlobals.studentGlobals}
                                getPracticeQuestions={getStudentExamQuestions.getStudentExamQuestions}
                                getData={localData}
                                getAutoTypeExam={getAutoTypeExam.getAutoTypeExam}
                                resumeExam={this.state.resumeExam}
                                getAutoSaveExam={getAutoSaveExam.getAutoSaveExam}
                                getAutoSubmitTime={getAutoSubmitTime.getAutoSubmitTime}
                            />
                        )
                    }
                </div>
            </Beforeunload >
        )
    }
}
export default
    compose(graphql(FETCH_STUDENTEXAMQUESTION,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_session_id: parseInt(localStorage.getItem('sessionid')),
                    type: localStorage.getItem('stype'),
                    exam_paper_id: parseInt(localStorage.getItem('exam_paper_id'))
                },
                fetchPolicy: "cache-and-network"
            }), name: "getStudentExamQuestions"
        }),
        graphql(FETCH_EXAMINSTRUCTIONS,
            {
                options: props => ({
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_session_id: parseInt(localStorage.getItem('sessionid')),
                        type: localStorage.getItem('stype'),
                        exam_paper_id: parseInt(localStorage.getItem('exam_paper_id'))
                    }
                    ,
                    fetchPolicy: "cache-and-network"
                }), name: "getExamInstructions"
            }),
        graphql(AUTO_SAVE_TIME,
            {
                options: props => ({
                    variables: {
                        mobile: Cookies.get("mobile"),
                    },
                    fetchPolicy: 'network-only'
                }),
                name: "getAutoTypeExam"
            }),
        graphql(GET_AUTO_SAVE_TIME,
            {
                options: props => ({
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_paper_id: parseInt(localStorage.getItem('exam_paper_id')),
                        source: "2"
                    },
                    fetchPolicy: 'network-only'
                }),
                name: "getAutoSaveExam"
            }),
        graphql(STUDENT_GLOBALS,
            {
                options: props => ({
                    variables: {
                        mobile: Cookies.get("mobile"),
                    }
                    ,
                    fetchPolicy: "cache-and-network"
                }), name: "studentGlobals"
            }), graphql(AUTO_SUBMIT_TIME, {
                options: props => ({
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_paper_id: parseInt(localStorage.getItem('exam_paper_id'))
                    }
                    ,
                    fetchPolicy: "cache-and-network"
                }),
                name: "getAutoSubmitTime"
            }),
            graphql(UPDATE_STUDENT_ANALYTICS, {
                name: "submitpt"
            }),
    )(SeriesExamTest);

