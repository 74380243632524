import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import RapidRevisionHome from '../components/rapidRevisionHome/liveHome'
import HomeNavbar from '../components/home/HomeNavbar';

let globalsubjectsStatic = [
    { id: 0, subject: 'AllSubjects',active: "active" },
    { id: 1, subject: 'Botany',active: "" },
    { id: 2, subject: 'Physics',active: "" },
    { id: 3, subject: 'Chemistry',active: "" },
    { id: 5, subject: 'Zoology',active: "" }
]

class RapidRevision extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mainindex: 0,
            getSubjectsArr:globalsubjectsStatic,
            toggled: Cookies.get("toggle")
        }
    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    componentDidMount = () => {


        if (globalsubjectsStatic != undefined || globalsubjectsStatic != null) {
            const getSubjects = globalsubjectsStatic.map((gmap, index) => {
                if (index == 0) {
                    return { ...gmap, active: "active" }

                }
                else {
                    return { ...gmap, active: "" }
                }

            })
            this.setState({
                getSubjectsArr: getSubjects
            });
        }

    }
    handleSelect = (subid) => {
        const getSubjects = this.state.getSubjectsArr.map((gmap, index) => {
            if (index == subid) {
                return { ...gmap, active: "active" }

            }
            else {
                return { ...gmap, active: "" }
            }

        })
        this.setState({
            getSubjectsArr: getSubjects,
            mainindex: subid
        });
    }
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }
        

        return (
            <div className={Cookies.get("toggle")}>
                <div className="student main-wrapper">
                    <div className="student header-area">
                    <HomeNavbar
                                stateData={this.state}
                                getSubjects={this.state.getSubjectsArr}
                                onClick={() => this.menuToggler()}
                                logout={this.props.logout} 
                                pageType ="RapidRevision"
                                />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper">
                    <RapidRevisionHome
                        stateData={this.state}
                        getSubjects={this.state.getSubjectsArr}
                        handleSelect={this.handleSelect} />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter((RapidRevision));
