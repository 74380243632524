import React, { Component } from 'react'
import { Container, Col, Row, Image } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import * as Cookies from "es-cookie";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import BluePrintEamcet from '../components/EamcetBluePrint/BluePrintEamcet';


import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';



const FETCH_SERVICE_DATA_FOR_EAMCET_BLUEPRINT = gql` 
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String
    ){
        getServiceData(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type){
            data
            }
        }
    `;
const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

const GET_SUBJECTS = gql`

query($exam_id:Int){
getSubjectOrder(exam_id:$exam_id){
    id
    subject
}
}

`


class EamcetBluePrint extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerBottomImg: {
                type: "eamcet_view",
                title: "BLUE PRINT"
            },
            toggled: "wrapper sidebar-enable",
            submitError: ""

        }

    }
    componentDidMount = () => {
        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Eamcet_BluePrint;
        ReactGA.pageview('/student/StateBluePrint', ["rizee"], title);
    }
    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 124,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");


        const getEamcetBlueprintArr = this.props.getServiceData;
        const loading1 = getEamcetBlueprintArr.loading;
        const error1 = getEamcetBlueprintArr.error;

        const getSubjectOrder = this.props.getSubjectOrder
        const loading2 = getSubjectOrder.loading;
        const error2 = getSubjectOrder.error;

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }

        let globalsubjects = "";
        if (JSON.parse(localStorage.getItem("globalsubjects")) != "") {
            globalsubjects = JSON.parse(localStorage.getItem("globalsubjects"));
        }
        else {
            this.props.history.push("/student/login");
        }


        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }
        
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars
                        headerBottom={this.state.headerBottomImg}
                        onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper">
                    {/* {(loading1 == true) && (<PreloaderTwo />)} */}
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper" style={{ paddingTop: 10, minHeight: "80vh" }}>
                        {
                            loading1 || loading2 ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <BluePrintEamcet
                                        globalsubjects={globalsubjects}
                                        getEamcetBlueprintArr={this.props.getServiceData.getServiceData}
                                        getSubjectOrder={getSubjectOrder.getSubjectOrder}

                                    />
                                )
                        }
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(compose(

    graphql(FETCH_SERVICE_DATA_FOR_EAMCET_BLUEPRINT, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                service_type: Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ? "Eamcet_Blue_Print" : "Kcet_Blue_Print",
                service_sub_type: "All"
            },
            fetchPolicy: 'network-only'
        }), name: "getServiceData"
    }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
    graphql(GET_SUBJECTS, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
            },
            fetchPolicy: 'network-only'
        }), name: "getSubjectOrder"
    }),
)(EamcetBluePrint));
