import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Image, Navbar, Dropdown, Form, FormControl, Button,Container,Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './_bluePrintexamanalysis.scss';
import * as Cookies from "es-cookie";

import MathsDataSection from './MathsDataSection';
import PhysicsDataSection from './PhysicsDataSection';
import ChemistryDataSection from './ChemistryDataSection';

import ZoologyDataSection from './ZoologyDataSection';
import BotanyDataSection from './BotanyDataSection';

import Vector from "../../../images/images1/Vector.png";
// import Vector1 from "../../../images/images1/Vector1.png";
import physicslatest from "../../../images/images1/Physics_icon.png"
import Chemistryicon from "../../../images/images1/Chemistry_icon.png";
import Maths from "../../../images/images1/Maths_icon.png";
import botony from "../../../images/images1/Botony_icon.png";
class BluePrintAnalysisSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            key: "AllSubjects"
        }
    }

   
    handleSelect = (val) => {
        console.log(val)
        this.setState({
            key: val
        });
    }


    render() {
        return (
            
            <Container fluid>
                <Row>
                    <Col>
                        <div className="flex-blocks activeClsApply">
                            <Media className={this.state.key === "AllSubjects" ? "active allSubCls" : "allSubCls"}  as={Link} onClick={() => this.handleSelect("AllSubjects")} >
                                <Image
                                    className="mr-4"
                                    src={Vector}
                                    alt="All Subjects"
                                />
                                <Media.Body>
                                    <h5 className="mb-0">All <br/>Subjects</h5>
                                    
                                </Media.Body>
                            </Media>
                            {
                                Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? (
                                    <Media className={this.state.key === "Maths" ? "active remingSubCls" : "remingSubCls"} as={Link} onClick={() => this.handleSelect("Maths")}>
                                            <Image
                                                className="mr-4"
                                                src={Maths}
                                                alt="Maths"
                                            />
                                            <Media.Body>
                                                <h5 className="mb-0 mt-4">Maths</h5>
                                                
                                            </Media.Body>
                                    </Media>
                                ) : null
                             }
                            <Media className={this.state.key === "Physics" ? "active remingSubCls" : "remingSubCls"}  as={Link} onClick={() => this.handleSelect("Physics")}>
                                    <Image
                                        className="mr-4"
                                        src={physicslatest}
                                        alt="Physics"
                                    />
                                    <Media.Body>
                                        <h5 className="mb-0 mt-4">Physics</h5>
                                        
                                    </Media.Body>
                            </Media>
                            <Media className={this.state.key === "Chemistry" ? "active remingSubCls widthalignChCls" : "remingSubCls widthalignChCls"}  as={Link} onClick={() => this.handleSelect("Chemistry")}>
                                    <Image
                                        className="mr-4"
                                        src={Chemistryicon}
                                        alt="Chemistry"
                                    />
                                    <Media.Body>
                                        <h5 className="mb-0 mt-4">Chemistry</h5>
                                        
                                    </Media.Body>
                            </Media>
                            
                             {
                                    Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? ('') : (
                                        <Media className={this.state.key === "Botony" ? "active remingSubCls" : "remingSubCls"} as={Link} onClick={() => this.handleSelect("Botony")}>
                                            <Image
                                                className="mr-4"
                                                src={botony}
                                                alt="Botony"
                                            />
                                            <Media.Body>
                                                <h5 className="mb-0 mt-4">Botony</h5>
                                                
                                            </Media.Body>
                                        </Media>
                                    )
                             }
                             {
                                    Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? ('') : (
                                        <Media className={this.state.key === "Zoology" ? "active remingSubCls" : "remingSubCls"} as={Link} onClick={() => this.handleSelect("Zoology")}>
                                            <Image
                                                className="mr-4"
                                                src={botony}
                                                alt="Zoology"
                                            />
                                            <Media.Body>
                                                <h5 className="mb-0 mt-4">Zoology</h5>
                                                
                                            </Media.Body>
                                        </Media>
                                    )
                             }
                        </div>
                    </Col>

                </Row>
                <Container>
                    <Row>
                        <Col className="mt-4">
                            <h4>Blue Print - {this.state.key}</h4>
                            <Form>
                                {/* <Row>
                                    <Col md={6} className="mt-3">
                                        <Form.Control size="lg" type="text" placeholder="Search Chapter name" />
                                    </Col>
                                    <Col md={3} className="mt-3">
                                     <select className="form-control">
                                        <option>Select Year</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                        </select>
                                    </Col>
                                </Row> */}
                             </Form>
                             {/* <Navbar bg="light" expand="lg">
                                

                                <Navbar.Toggle aria-controls="navbarScroll" />
                                <Navbar.Collapse id="navbarScroll">

                                    <Form className="d-flex search">
                                        <FormControl
                                            type="search"
                                            placeholder="Search Chapter name"
                                            className="mr-2"
                                            aria-label="Search"
                                        />

                                    </Form>
                                    <Dropdown className="ml-4">
                                        <Dropdown.Toggle id="dropdown-basic">
                                            First Year
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="./FirstYear">First Year</Dropdown.Item>
                                            <Dropdown.Item href="./Second Year">Second Year</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Navbar.Collapse>
                            </Navbar> */}

                            <div className="mt-4">

                            
                            {
                                this.state.key === "Maths"
                                    ? <MathsDataSection />
                                    : this.state.key === "Physics"
                                        ? < PhysicsDataSection />
                                        : this.state.key === "Chemistry"
                                            ? <ChemistryDataSection />

                                            : this.state.key === "Botony"
                                                ? <BotanyDataSection />
                                                : this.state.key === "Zoology"
                                                    ? <ZoologyDataSection />
                                                    : this.state.key === "AllSubjects"
                                                        ?
                                                        <>
                                                            {
                                                                Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? (
                                                                    <MathsDataSection />
                                                                ) : null
                                                            }

                                                            <PhysicsDataSection />
                                                            <ChemistryDataSection />
                                                            {
                                                                Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? null : (
                                                                    <BotanyDataSection/>
                                                                )

                                                            }
                                                            {
                                                                Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? null : (
                                                                    <ZoologyDataSection />
                                                                )
                                                            }
                                                        </>
                                                        : null
                                }
                                
                                </div>
                               
                                {/* <Tabs activeKey={this.state.key} onSelect={this.handleSelect}>

                                {
                                    Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? (
                                        <Tab eventKey="Maths" title="Maths">
                                            <MathsDataSection />
                                        </Tab>
                                    ) : ('')
                                }
                                <Tab eventKey="Physics" title="Physics">
                                    <PhysicsDataSection />
                                </Tab>
                                <Tab eventKey="Chemistry" title="Chemistry">
                                    <ChemistryDataSection />
                                </Tab>
                                {
                                    Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? ('') : (
                                        <Tab eventKey="Zoology" title="Zoology">
                                            <ZoologyDataSection />

                                        </Tab>
                                    )
                                }
                                {
                                    Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? ('') : (
                                        <Tab eventKey="Botany" title="Botany">
                                            <BotanyDataSection />
                                        </Tab>
                                    )
                                }
                            </Tabs>
                        */}
                        </Col>
                       
                    </Row>
                    
                </Container>

            </Container>
            
           
        )
    }

}
export default BluePrintAnalysisSection;