import React, { Component } from 'react'
import { Row, Col, Card, Image, Container,Button } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import * as Cookies from "es-cookie";
import * as compose from 'lodash.flowright';
import NavbarTop from '../components/login_register/NavbarTop';
import Footer from '../components/login_register/Footer';
import StudentOrganizationNavbar from '../components/student_organization_profile/StudentOrganizationNavbar';

const LOGIN_USER = gql`
  mutation($username: String!, $token: String) {
    studentLogin(username: $username, token: $token) {
        token
        refreshToken
        user{
            name
            email
            mobile
            valid
            class_id
            exam_id
            current_plan_id
            mobile_verified
            target_year
            videos
            branch_name
            profile_pic
            userlevel
            
            
        }
    }
  }
`;
class RazorPaySuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 0
        }
    }
    loggedhandleFormSubmit = e => {
        console.log("packageplan", localStorage.getItem("packageplan"));
        if (localStorage.getItem("packageplan") == "home" || localStorage.getItem("packageplan") == "null") {
            this.props.history.push("/student/home");
        }
        if (localStorage.getItem("packageplan") == "mocktest") {
            this.props.history.push("/student/exams/test-series");
        }
        else {
            this.props.history.push("/student/home");
        }

    };

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            loader: 1
        });
        console.log("Form submitted");
        console.log(this.state);
        console.log("autologin", this.props.match.params.mobile, this.props.match.params.token);
        if (this.props.match.params.mobile && this.props.match.params.token) {
            this.login(this.props.match.params.mobile, this.props.match.params.token).catch(error => {
                console.log("catch if error");
                console.log(error);
                this.setState({
                    submitError: error.graphQLErrors.map(x => x.message), loader: 0
                });
                console.error("ERR =>", error.graphQLErrors.map(x => x.message));
            });
        } else {
            this.setState({ submitError: "Please fill all the values to proceed", loader: 0 });
        }
    };
    login = async (username, token) => {
        await this.props.login({
            variables: {
                username,
                token
            },
            update: (store, { data }) => {
                console.log("data.studentLogin.user.branch_name", data.studentLogin.user);
                Cookies.set("studenttoken", data.studentLogin.token);
                Cookies.set("studentrefreshtoken", data.studentLogin.refreshToken);
                Cookies.set("studentusername", data.studentLogin.user.name);
                Cookies.set("studentemail", data.studentLogin.user.email);

                Cookies.set("mobile", data.studentLogin.user.mobile);
                Cookies.set("classid", data.studentLogin.user.class_id);
                Cookies.set("examid", data.studentLogin.user.exam_id);
                Cookies.set("mobileverified", data.studentLogin.user.mobile_verified);
                Cookies.set("targetyear", data.studentLogin.user.target_year);
                Cookies.set("videos", data.studentLogin.user.videos);
                Cookies.set("branch_name", data.studentLogin.user.branch_name);
                Cookies.set("role", "student");
                Cookies.set("profile_pic", data.studentLogin.user.profile_pic);
                Cookies.set("student_userlevel", data.studentLogin.user.userlevel);
                localStorage.removeItem("packageplan");
                localStorage.setItem("profile_pic", data.studentLogin.user.profile_pic);


                if (data.studentLogin) {

                    this.setState({ loader: 0 });
                    this.props.history.push("/student/loading");
                    //this.props.history.push("/student/home");

                }
            }
        });
    };
    render() {
        console.log('props',this.props)
        let trasactionID = this.props?.history?.location?.state;
        console.log("Cookies.getstudenttoken", Cookies.get("studenttoken"));
        return (
            <div >
                <StudentOrganizationNavbar name={Cookies.get("studentusername")} />
                <div className="register-form">
                    <section className="register-form-block">
                        <Container>
                            <div className="mb-5">
                                <Row className="mt-5 justify-content-center">
                                    <Col sm={6}>
                                        <Card as={Card.Body} className="border-0 text-center block_white_card">
                                             <Image src={require('../../images/success_img.png')} className="my-2 succes_img" alt="img" />
                                                <h5 className="text-success mb-4 mt-4">Transaction Successful</h5>
                                                <h6>Your Transaction ID : {trasactionID.transactionId}</h6>
                                                <h6 className="mb-4">Thank you for Purchasing</h6>
                                                <Button type="button"  variant="success" className='btn btn-custom-green font-weight-bold mb-5' onClick={this.loggedhandleFormSubmit}>Continue</Button>
                                            
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </section>
                </div>
            </div>
        )

    }
}
export default withRouter(compose(
    graphql(LOGIN_USER, {
        name: "login"
    })
)(RazorPaySuccess)
);
