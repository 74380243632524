import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_liveClassess.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import LiveClassesModal from '../liveClasses/LiveClassesModal';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';
import UserRestrictionAlertInstitute from '../home/UserRestrictionAlertInstitute';
import moment from 'moment';

import * as Cookies from "es-cookie";

import FileText from "../../../images/images1/FileText.png";
import FileArrowDown from "../../../images/images1/FileArrowDown.png";
import Live from "../../../images/images1/Live.png"
import LockSimple from "../../../images/LockSimple.png"

//import Group3172 from "../../../images/images1/Group3172.png";



let mathsQuestionData = [
    {
        DATE: "02-07-2021",
        ClassTimings: "2 PM to 3 PM",
        time: "14",
        Subject: "Mathematics",
        Chapter: "Trigonometry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("C7Ov9OxYimw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "2 PM to 3 PM",
        time: "14",
        Subject: "Mathematics",
        Chapter: "Introduction to Fundamental Mathematics",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("gXsLQuq-s7w"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "2 PM to 3 PM",
        time: "14",
        Subject: "Mathematics",
        Chapter: "First Year Topics - Briefing",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("JbUYOyPeuQs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "2 PM to 3 PM",
        time: "14",
        Subject: "Mathematics",
        Chapter: "Second Year Topics - Briefing",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("NUGUCLMWew"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Co-ordinate Geometry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_5022_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_8232_Sol.pdf"),
        VideoLecture: ("kmVxXYILmrc"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Trigonometric Ratios",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_6516_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_2283_Sol.pdf"),
        VideoLecture: ("Q0Jsy5pz24M"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "08-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Locus,Change of Axis",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_5325_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_3048_Sol.pdf"),
        VideoLecture: ("f35RKYDyCtg"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "08-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Compound Angels,Multiple and Sub-Multiple angels",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_5476_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_7453_Sol.pdf"),
        VideoLecture: ("Mqf2aS_g0uw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "09-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Straight Lines-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_5661_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_1347_Sol.pdf"),
        VideoLecture: ("qdGvpTbOGxM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "09-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Transformations and Trigonometric Identies",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_9328_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_84_Sol.pdf"),
        VideoLecture: ("SDabqaBGTww"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "10-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Straight Lines 2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_7994_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_7941_Sol.pdf"),
        VideoLecture: ("9Ok_kxt-tvE"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "10-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Periodicity and Extreme Values,Hyperbolic Functions",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_4655_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_9043_Sol.pdf"),
        VideoLecture: ("P0hPiit6hCg"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "12-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Pair of Straight Lines-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_3091_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_1923_Sol.pdf"),
        VideoLecture: ("wDVkGHmLgC4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "12-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Trigonometric Equations",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_49_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_3367_Sol.pdf"),
        VideoLecture: ("nhe8LDN0LkU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "13-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Pair of Straight Lines-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_56_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_1833_Sol.pdf"),
        VideoLecture: ("RI-mkpxXWiM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "13-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Inverse Trigonometry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_5973_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_7318_Sol.pdf"),
        VideoLecture: ("NuDtzRN48f0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Three Dimensional Geometry,Direction Ratios and Direction Cosines",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_5807_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_4606_Sol.pdf"),
        VideoLecture: ("75-2CYdhFyA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Properties of Triangle-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_5414_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_8203_Sol.pdf"),
        VideoLecture: ("0aXTXLu0_uI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "3D-Lines,3D-Planes",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_2028_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_3178_Sol.pdf"),
        VideoLecture: ("Wf89btf3RS4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Properties of Triangle-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_8231_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_9353_Sol.pdf"),
        VideoLecture: ("PZy5hS0HyfE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Limits and Continuity-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_6492_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_3711_Sol.pdf"),
        VideoLecture: ("iN6i624UBHA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Functions-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_9661_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_8891_Sol.pdf"),
        VideoLecture: ("B6doHRL_mn8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Limits and Continuity-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_8944_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_7003_Sol.pdf"),
        VideoLecture: ("-6uXjCZwAXk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Functions-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_8831_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_9317_Sol.pdf"),
        VideoLecture: ("r3dB0RnvSCc"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Differentiation-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_951_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_9748_Sol.pdf"),
        VideoLecture: ("G9KdV61HJmU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Mathematical Induction",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_4305_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_4483_Sol.pdf"),
        VideoLecture: ("_iRnOmzcuMM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Differentiation-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_4636_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_1786_Sol.pdf"),
        VideoLecture: ("XIkbRskJTPs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "4:30 PM to 6.00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Matrices-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_8322_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_618_Sol.pdf"),
        VideoLecture: ("yXe4gJx7c7Q"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Errors and Approximation,Rate of Change",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_496_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_2568_Sol.pdf"),
        VideoLecture: ("EnTJl4-V3S0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Matrices-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_3735_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_256_Sol.pdf"),
        VideoLecture: ("lGA3oN0_aec"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Tangent and Normals",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_1723_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_7158_Sol.pdf"),
        VideoLecture: ("HDaRUaKJugU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Addition of Vectors",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_3297_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_8162_Sol.pdf"),
        VideoLecture: ("4fSMV4UFPo0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Maxima and Minima,Mean value Theorem",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_9777_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_2047_Sol.pdf"),
        VideoLecture: ("Zwgjlsoongc"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Product of Vectors-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_1904_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_1304_Sol.pdf"),
        VideoLecture: ("I38lj4VJ1Ic"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Circles-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_1541_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_6834_Sol.pdf"),
        VideoLecture: ("a9w8MC2Ekqw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Product of Vectors-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_4201_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_6495_Sol.pdf"),
        VideoLecture: ("_-MuXrH4icI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Circles-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_7465_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_7768_Sol.pdf"),
        VideoLecture: ("A7DXsu3-MaU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Complex Numbers",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_8342_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_5199_Sol.pdf"),
        VideoLecture: ("0eEp_P0qZNA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "System of Circles",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_9761_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_8312_Sol.pdf"),
        VideoLecture: ("MrRlTpT3Yvk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "De Moiver's Theorem",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_1748_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_47_Sol.pdf"),
        VideoLecture: ("6JasQfKGSk8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Parabola",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_9822_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_1262_Sol.pdf"),
        VideoLecture: ("GVn_SOxdFWk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Quadratic Euations",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_1959_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_4673_Sol.pdf"),
        VideoLecture: ("wr4HDu0l1Bo"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Ellipse",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_162_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_4689_Sol.pdf"),
        VideoLecture: ("y9DSineLLT4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Theorey of Equations",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_9384_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_5265_Sol.pdf"),
        VideoLecture: ("uK98wWS7dX4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Hyperbola",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_6606_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_7416_Sol.pdf"),
        VideoLecture: ("X2Cung2oliM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Permutations and Combinations",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_5142_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_6445_Sol.pdf"),
        VideoLecture: ("BKGoy4K0mDA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Integration-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_647_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_2089_Sol.pdf"),
        VideoLecture: ("2DK65zLVyxA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Probability-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_2113_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_6375_Sol.pdf"),
        VideoLecture: ("x4_Bb2fqoS4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Integration-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_7408_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_8671_Sol.pdf"),
        VideoLecture: ("SkIwkA_9pLY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Probability-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_7089_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_6512_Sol.pdf"),
        VideoLecture: ("GOqksAY-KaA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Definite Integration-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_2823_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_9798_Sol.pdf"),
        VideoLecture: ("C95geHme_PI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Random Variables",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_5782_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_896_Sol.pdf"),
        VideoLecture: ("RxGT2jbb-YQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Definte Integration-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_8342_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_8106_Sol.pdf"),
        VideoLecture: ("mjjiaZZOxS4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Partial Fractions",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_267_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_7926_Sol.pdf"),
        VideoLecture: ("_g_3hQxSUWE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Differential Equations-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_6967_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_7024_Sol.pdf"),
        VideoLecture: ("KextXf8FdFg"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Binomial Theorem",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_325_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_4417_Sol.pdf"),
        VideoLecture: ("sTyJETXCV_o"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Differential Equations-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_354_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_5134_Sol.pdf"),
        VideoLecture: ("ZtilXbBldkM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Measuers of Dispersion",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_4508_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_264_Sol.pdf"),
        VideoLecture: ("tWixiA7U5QY"),
        Live: (""),
        Zoom: (""),
    },

];
/// Engg physics
let physicsQuestionData = [
    {
        DATE: "02-07-2021",
        ClassTimings: "3 PM to 4 PM",
        time: "15",
        Subject: "Physics",
        Chapter: "Gravitation",

        Assignments: "",
        Solutions: "",
        VideoLecture: "d94Gqco3xIw",
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "3 PM to 4 PM",
        time: "15",
        Subject: "Physics",
        Chapter: "General Physics - Briefing",

        Assignments: "",
        Solutions: "",
        VideoLecture: ("BwxXbySoCxU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "3 PM to 4 PM",
        time: "15",
        Subject: "Physics",
        Chapter: "Introduction to Mathematical physics ",

        Assignments: "",
        Solutions: "",
        VideoLecture: ("mGS3_K_ulXk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "3 PM to 4 PM",
        time: "15",
        Subject: "Physics",
        Chapter: "Application of physics",

        Assignments: "",
        Solutions: "",
        VideoLecture: ("-wrvGzsX6sc"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Motion in straight line",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_5062_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_1071_Sol.pdf"),
        VideoLecture: ("TZtL4BKRbf8"),
        Live: (""),
        Zoom: (""),
    },

    {
        DATE: "08-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Motion in plane",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_1517_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_7134_Sol.pdf"),
        VideoLecture: ("CdOLviEiGTQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "09-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Units and Measurement",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_9761_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_7444_Sol.pdf"),
        VideoLecture: ("5qsbgFx0F60"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "10-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Laws of motion",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_7803_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_9966_Sol.pdf"),
        VideoLecture: ("g9KbwnXTpeQ"),
        Live: (""),
        Zoom: ("")
    },

    {
        DATE: "12-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Work ,energy and power",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_92_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_405_Sol.pdf"),
        VideoLecture: ("I7yqOkE0umI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "13-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Gravitation",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_7925_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_3001_Sol.pdf"),
        VideoLecture: ("QxF1nubi42g"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Oscillation",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_549_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_5042_Sol.pdf"),
        VideoLecture: ("gvNS7pAym9Q"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "System of particle and rotational motion",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_3117_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_151_Sol.pdf"),
        VideoLecture: ("Ke-hg7ZVgcs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Mechnical properties of solids",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_8184_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_1519_Sol.pdf"),
        VideoLecture: ("AyUhXSedki8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Mechnical properties of fluids",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_3515_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_8288_Sol.pdf"),
        VideoLecture: ("32bMPRNRnaA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Thermal properties of matter",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_3355_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_4048_Sol.pdf"),
        VideoLecture: ("2PQKEtTOxlI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Thermodynamics",
        Assignments: ("https://rizee.in/econtent/LiveClass/D12_5953_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_7404_Sol.pdf"),
        VideoLecture: ("5FkQfAvjjxs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Kinetic theory",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_3045_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_2146_Sol.pdf"),
        VideoLecture: ("wBp04FDjQmY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Waves",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_6192_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_6478_Sol.pdf"),
        VideoLecture: ("MraOSPOJcuQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Ray optics and optical instruments",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_5652_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_1295_Sol.pdf"),
        VideoLecture: ("2HKLOKGpDIQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Wave optics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_7009_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_1576_Sol.pdf"),
        VideoLecture: ("o7Ll71wQZ74"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Electric charges and field",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_5848_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_3898_Sol.pdf"),
        VideoLecture: ("ETolepm3MzM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Electro static potential and capacitance",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_2424_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_1321_Sol.pdf"),
        VideoLecture: ("2_NJ2dyyWDQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Current electricity-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_2013_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_2412_Sol.pdf"),
        VideoLecture: ("QOo-V06AcDQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Current electricity-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_6403_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_1111_Sol.pdf"),
        VideoLecture: ("QcHOOsVWJKQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Moving charges and magnetism,magnetism and matter",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_771_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_6301_Sol.pdf"),
        VideoLecture: ("R-rH6HQi_lI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Semiconductor electronics :materials,devices and simple circuit-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_2207_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_3557_Sol.pdf"),
        VideoLecture: ("ziEBjM4h0Gw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Semiconductor electronics:materials,devices and simple circuits-II,Communication system-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_4874_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_3719_Sol.pdf"),
        VideoLecture: ("HI0su0QKNSE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Dual nature of matter and radiation",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_8261_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_1362_Sol.pdf"),
        VideoLecture: ("_xpGSTgz_pA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Electromagnetic induction, E.M waves",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_9369_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_6048_Sol.pdf"),
        VideoLecture: ("tgMEuiSJ4rA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Alternating current",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_6562_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_7738_Sol.pdf"),
        VideoLecture: ("60pEof3Scuw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Atoms - Nuclie",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_5001_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_2787_Sol.pdf"),
        VideoLecture: ("FEeLhFWozxE"),
        Live: (""),
        Zoom: (""),
    },
];
//// physics agricultue
let QuestionDataAgri = [{
    DATE: "02-07-2021",
    ClassTimings: "3 PM to 4 PM",
    time: "15",
    Subject: "Physics",
    Chapter: "Gravitation",
    Assignments: "",
    Solutions: "",
    VideoLecture: "d94Gqco3xIw",
    Live: (""),
    Zoom: (""),
},
{
    DATE: "03-07-2021",
    ClassTimings: "3 PM to 4 PM",
    time: "15",
    Subject: "Physics",
    Chapter: "General Physics - Briefing",

    Assignments: "",
    Solutions: "",
    VideoLecture: ("BwxXbySoCxU"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "05-07-2021",
    ClassTimings: "3 PM to 4 PM",
    time: "15",
    Subject: "Physics",
    Chapter: "Introduction to Mathematical physics ",

    Assignments: "",
    Solutions: "",
    VideoLecture: ("mGS3_K_ulXk"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "06-07-2021",
    ClassTimings: "3 PM to 4 PM",
    time: "15",
    Subject: "Physics",
    Chapter: "Application of physics",

    Assignments: "",
    Solutions: "",
    VideoLecture: ("-wrvGzsX6sc"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "07-07-2021",
    ClassTimings: "2:30 PM to 4.00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Motion in straight line",

    Assignments: ("https://rizee.in/econtent/LiveClass/D1_5062_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D1_6195_Sol.pdf"),
    VideoLecture: ("gMknL-7boz0"),
    Live: (""),
    Zoom: (""),

},
{
    DATE: "08-07-2021",
    ClassTimings: "2:30 PM to 4.00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Motion in plane",

    Assignments: ("https://rizee.in/econtent/LiveClass/D2_4804_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D2_6954_Sol.pdf"),
    VideoLecture: ("j8jIFhtC7uU"),
    Live: (""),
    Zoom: (""),

},
{
    DATE: "09-07-2021",
    ClassTimings: "2:30 PM to 4.00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Units and Measurement",

    Assignments: ("https://rizee.in/econtent/LiveClass/D3_7913_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D3_6575_Sol.pdf"),
    VideoLecture: ("N6QDAQ_kL5Q"),
    Live: (""),
    Zoom: (""),

},
{
    DATE: "10-07-2021",
    ClassTimings: "2:30 PM to 4.00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Laws of motion",

    Assignments: ("https://rizee.in/econtent/LiveClass/D4_8324_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D4_5082_Sol.pdf"),
    VideoLecture: ("nOsxE5VOR9Q"),
    Live: (""),
    Zoom: (""),

},
{
    DATE: "12-07-2021",
    ClassTimings: "2:30 PM to 4.00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Work ,energy and power",

    Assignments: ("https://rizee.in/econtent/LiveClass/D5_6383_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D5_1581_Sol.pdf"),
    VideoLecture: ("c_RTp-QdQa0"),
    Live: (""),
    Zoom: (""),

},
{
    DATE: "13-07-2021",
    ClassTimings: "2:30 PM to 4.00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Gravitation",

    Assignments: ("https://rizee.in/econtent/LiveClass/D6_8173_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D6_6386_Sol.pdf"),
    VideoLecture: ("nwdbtUkfnGw"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "14-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Oscillation",

    Assignments: ("https://rizee.in/econtent/LiveClass/D7_5625_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D7_6812_Sol.pdf"),
    VideoLecture: ("sDRgsonecYw"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "15-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "System of particle and rotational motion",

    Assignments: ("https://rizee.in/econtent/LiveClass/D8_258_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D8_169_Sol.pdf"),
    VideoLecture: ("itez-dp2J30"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "16-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Mechnical properties of solids",

    Assignments: ("https://rizee.in/econtent/LiveClass/D9_7269_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D9_6638_Sol.pdf"),
    VideoLecture: ("2o5aBiErFfY"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "17-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Mechnical properties of fluids",

    Assignments: ("https://rizee.in/econtent/LiveClass/D10_2556_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D10_1242_Sol.pdf"),
    VideoLecture: ("YoWml-PH-7c"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "19-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Thermal properties of matter",

    Assignments: ("https://rizee.in/econtent/LiveClass/D11_281_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D11_7232_Sol.pdf"),
    VideoLecture: ("NSUQjt3oUiU"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "20-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Thermodynamics",

    Assignments: ("https://rizee.in/econtent/LiveClass/D12_809_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D12_3401_Sol.pdf"),
    VideoLecture: ("KPGAoXSRGUI"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "21-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Kinetic theory",

    Assignments: ("https://rizee.in/econtent/LiveClass/D13_3915_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D13_7628_Sol.pdf"),
    VideoLecture: ("3X1g7azB4LM"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "22-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Waves",

    Assignments: ("https://rizee.in/econtent/LiveClass/D14_5331_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D14_5169_Sol.pdf"),
    VideoLecture: ("pDZpz6aWBdQ"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "23-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Ray optics and optical instruments",

    Assignments: ("https://rizee.in/econtent/LiveClass/D15_1573_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D15_3516_Sol.pdf"),
    VideoLecture: ("eiRzqMh_UA0"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "24-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Wave optics",

    Assignments: ("https://rizee.in/econtent/LiveClass/D16_6862_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D16_3031_Sol.pdf"),
    VideoLecture: ("pX1HwqwPWNE"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "26-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Electric charges and field",

    Assignments: ("https://rizee.in/econtent/LiveClass/D17_1958_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D17_5048_Sol.pdf"),
    VideoLecture: ("yF17fScceYo"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "27-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Electro static potential and capacitance",

    Assignments: ("https://rizee.in/econtent/LiveClass/D18_9599_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D18_1447_Sol.pdf"),
    VideoLecture: ("TwIiKT7SAvs"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "28-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Current electricity-1",

    Assignments: ("https://rizee.in/econtent/LiveClass/D19_6228_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D19_3468_Sol.pdf"),
    VideoLecture: ("84Hr55y6PWI"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "29-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Current electricity-2",

    Assignments: ("https://rizee.in/econtent/LiveClass/D20_5453_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D20_7619_Sol.pdf"),
    VideoLecture: ("pU3MpNuVXvY"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "30-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Moving charges and magnetism,magnetism and matter",

    Assignments: ("https://rizee.in/econtent/LiveClass/D21_5531_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D21_4516_Sol.pdf"),
    VideoLecture: ("-1cVeu3lpmM"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "31-07-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Semiconductor electronics :materials,devices and simple circuit-1",

    Assignments: ("https://rizee.in/econtent/LiveClass/D22_5817_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D22_8876_Sol.pdf"),
    VideoLecture: ("7QVESRzUiXY"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "02-08-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Semiconductor electronics:materials,devices and simple circuits-II,Communication system-2",

    Assignments: ("https://rizee.in/econtent/LiveClass/D23_685_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D23_398_Sol.pdf"),
    VideoLecture: ("WbDwqm7NMVM"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "03-08-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Dual nature of matter and radiation",

    Assignments: ("https://rizee.in/econtent/LiveClass/D24_3684_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D24_4222_Sol.pdf"),
    VideoLecture: ("Mup3k4puxZU"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "04-08-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Electromagnetic induction, E.M waves",

    Assignments: ("https://rizee.in/econtent/LiveClass/D25_9566_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D25_3481_Sol.pdf"),
    VideoLecture: ("MDYRILnN5hs"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "05-08-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Alternating current",

    Assignments: ("https://rizee.in/econtent/LiveClass/D26_5679_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D26_5019_Sol.pdf"),
    VideoLecture: ("IEjgcYhfyzA"),
    Live: (""),
    Zoom: (""),
},
{
    DATE: "06-08-2021",
    ClassTimings: "2:30 PM to 4:00 PM",
    time: "14",
    Subject: "Physics",
    Chapter: "Atoms - Nuclie",

    Assignments: ("https://rizee.in/econtent/LiveClass/D27_5358_Ass.pdf"),
    Solutions: ("https://rizee.in/econtent/LiveClass/D27_2736_Sol.pdf"),
    VideoLecture: ("vN_vMtSHJgc"),
    Live: (""),
    Zoom: (""),
},
]



//Chemistry ENGINEERING
let chemistryQuestionDataEngg = [
    {
        DATE: "02-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Chemical Bonding",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("fZLk5oYI6HE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Introdution to Organic Chemistry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("TaNfEbiXRDw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Introduction to Physical Chemistry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("S1xcozxK59A"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Introdution to In-organic Chemistry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("oUNfpoSbWZ4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Chemical Bonding And Molecular Structure ",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_5444_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_9313_Sol.pdf"),
        VideoLecture: ("UqzP4S1OQtQ"),
        Live: (""),
        Zoom: ("")
    },

    {
        DATE: "08-07-2021",
        ClassTimings: "2:30 PM to 4.00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Stoichiometry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_4747_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_7914_Sol.pdf"),
        VideoLecture: ("AROYrpTGVyw"),
        Live: (""),
        Zoom: (""),
    },

    {
        DATE: "09-07-2021",
        ClassTimings: "2:30 PM to 4.00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Equilibrium",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_6043_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_1632_Sol.pdf"),
        VideoLecture: ("g0If4LYHAEU"),
        Live: (""),
        Zoom: (""),
    },

    {
        DATE: "10-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Organic Chemistry Some Basic Principles and Techniques",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_9558_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_9995_Sol.pdf"),
        VideoLecture: ("pZJfVaN0IuI"),
        Live: (""),
        Zoom: (""),
    },

    {
        DATE: "12-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Structure of Atom",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_3438_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_7856_Sol.pdf"),
        VideoLecture: ("LzhUwbaxP_8"),
        Live: (""),
        Zoom: (""),
    },

    {
        DATE: "13-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "The P-Block Elements(XI)",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_1158_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_4455_Sol.pdf"),
        VideoLecture: ("Tpa6jV1imtQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Thermodynamics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_8527_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_2229_Sol.pdf"),
        VideoLecture: ("jb1b5nHkdRs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "States of Matter",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_6469_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_2401_Sol.pdf"),
        VideoLecture: ("Bz61Moge3sc"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Classification of Elements and Periodicity in Properties",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_945_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_9015_Sol.pdf"),
        VideoLecture: ("mFtgmIBSHVs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Hydrocarbons",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_5993_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_4467_Sol.pdf"),
        VideoLecture: ("Wtnl_RPkffQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "S-Block Elements",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_9772_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_6874_Sol.pdf"),
        VideoLecture: ("6UNLma0CTmY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Hydrogen",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_273_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_9222_Sol.pdf"),
        VideoLecture: ("X1GgazNYml4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Environmental Chemistry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_5249_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_9819_Sol.pdf"),
        VideoLecture: ("QJaFyN-Epno"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Co-Ordination Compounds",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_975_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_157_Sol.pdf"),
        VideoLecture: ("ihqd4algaXk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Alcohol, Phenol and Ethers",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_8908_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_3604_Sol.pdf"),
        VideoLecture: ("SUhCiMsDNe4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Aldehydes and Ketones",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_6694_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_9124_Sol.pdf"),
        VideoLecture: ("aDuoo_DgEB8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Carboxylic Acids",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_3971_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_3775_Sol.pdf"),
        VideoLecture: ("7QLsO94vg2Q"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "The P-Block Elements(XII)",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_6517_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_8181_Sol.pdf"),
        VideoLecture: ("d5pjxGMxkxM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Solutions",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_3818_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_5297_Sol.pdf"),
        VideoLecture: ("NhC1YI85stU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Chemical Kinetics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_8139_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_5851_Sol.pdf"),
        VideoLecture: ("GMDQqavWKas"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Organic Compounds Containing Halogens",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_9401_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_1267_Sol.pdf"),
        VideoLecture: ("yBlFtAcuBuE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Solid State",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_148_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_4348_Sol.pdf"),
        VideoLecture: ("q-MuRrqYync"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Electrochemistry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_5399_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_7871_Sol.pdf"),
        VideoLecture: ("ceYlBOTe6bQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "D and F Block Elements",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_9423_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_3065_Sol.pdf"),
        VideoLecture: ("i8EWncpuA-I"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Surface Chemistry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_5988_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_5707_Sol.pdf"),
        VideoLecture: ("diSSe2e-wrU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Organic Chemistry Containing Nitrogen",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_732_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_7857_Sol.pdf"),
        VideoLecture: ("QU9yoF5tMGs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Biomolecules",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_5478_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_1364_Sol.pdf"),
        VideoLecture: ("IcFQKb_-Drk"),
        Live: (""),
        Zoom: (""),
    },
];
//AGRICULTURE CHEMISTRY
let ChemistryQuestionDataAgri = [
    {
        DATE: "02-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Chemical Bonding",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("fZLk5oYI6HE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Introdution to Organic Chemistry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("TaNfEbiXRDw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Introduction to Physical Chemistry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("S1xcozxK59A"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Introdution to In-organic Chemistry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("oUNfpoSbWZ4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Chemical Bonding And Molecular Structure",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_1622_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_8364_Sol.pdf"),
        VideoLecture: ("Dzf8MaBO6qc"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "08-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Stoichiometry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_6014_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_5108_Sol.pdf"),
        VideoLecture: ("Elym3AzmVbE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "09-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Equilibrium",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_2852_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_45_Sol.pdf"),
        VideoLecture: ("3SJVxAfCZVA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "10-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Organic Chemistry Some Basic Principles and Techniques",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_1147_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_3091_Sol.pdf"),
        VideoLecture: ("lkCZ5N3PsKw"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "12-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Structure of Atom",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_2469_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_6174_Sol.pdf"),
        VideoLecture: ("xqeWPbvcAxU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "13-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "The P-Block Elements(XI)",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_3893_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_6415_Sol.pdf"),
        VideoLecture: ("Ricns0ke6-A"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Thermodynamics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_2701_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_9865_Sol.pdf"),
        VideoLecture: ("GFso2Jc8vlk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "States of Matter",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_1684_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_7275_Sol.pdf"),
        VideoLecture: ("Lvn2LlojGy0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Classification of Elements and Periodicity in Properties",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_6537_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_7393_Sol.pdf"),
        VideoLecture: ("e7IC6txXpoI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Hydrocarbons",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_9706_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_6971_Sol.pdf"),
        VideoLecture: ("Psf9a1yT1ZU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "S-Block Elements",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_5669_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_8041_Sol.pdf"),
        VideoLecture: ("Mn3s4ZYWtV8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Hydrogen",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_5766_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_6324_Sol.pdf"),
        VideoLecture: ("6maE2QFiPn8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Environmental Chemistry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_4881_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_3499_Sol.pdf"),
        VideoLecture: ("OgAZQBBvbhs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Co-Ordination Compounds",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_1225_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_4266_Sol.pdf"),
        VideoLecture: ("Nb2abN3LgVk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Alcohol, Phenol and Ethers",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_7334_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_2953_Sol.pdf"),
        VideoLecture: ("yit7VAVWRig"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Aldehydes and Ketones",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_313_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_726_Sol.pdf"),
        VideoLecture: ("bvP7k5iH7Ts"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Carboxylic Acids",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_3062_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_3829_Sol.pdf"),
        VideoLecture: ("225zTrnAKWk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "The P-Block Elements(XII)",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_9156_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_5406_Sol.pdf"),
        VideoLecture: ("LB4_m2obTvY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Solutions",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_7323_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_8796_Sol.pdf"),
        VideoLecture: ("0rizH0qSv3o"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Chemical Kinetics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_3235_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_5538_Sol.pdf"),
        VideoLecture: ("0kWZEeLyVxs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Organic Compounds Containing Halogens",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_1278_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_1443_Sol.pdf"),
        VideoLecture: ("_X5s9cg5N-I"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Solid State",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_5481_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_9106_Sol.pdf"),
        VideoLecture: ("EYHguXi_OB4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Electrochemistry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_4259_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_5979_Sol.pdf"),
        VideoLecture: ("fJhG0HmeN4k"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "D and F Block Elements",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_8159_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_4797_Sol.pdf"),
        VideoLecture: ("5iI6tMTOMm4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Surface Chemistry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_3393_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_3946_Sol.pdf"),
        VideoLecture: ("B6CClFfQPJE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Organic Chemistry Containing Nitrogen",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_5933_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_3857_Sol.pdf"),
        VideoLecture: ("hczyCMjDKvw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Biomolecules",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_3612_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_6314_Sol.pdf"),
        VideoLecture: ("hIj571VWDc4"),
        Live: (""),
        Zoom: (""),
    },
];

let botonyQuestionData = [
    {
        DATE: "02-07-2021",
        ClassTimings: "4 PM To 5 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Morphology of Flowering Plants",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("cUNdkc0cCCo"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "4 PM To 5 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Introduction to  Botany  & Type of Questions",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("bNZri57P_HA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "4 PM To 5 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Botany- Importance of Flow Charts & Diagrams",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("IAGeMt0bQhU"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "4 PM To 5 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Basics in botany branches ",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("A-SIpfEt3PI"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "THE LIVING WORLD",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_5639_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_305_Sol.pdf"),
        VideoLecture: ("ATmH9EUJAXg"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "08-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "BIOLOGICAL CLASSIFICATION-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_2338_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_2639_Sol.pdf"),
        VideoLecture: ("H_FtjAdRS-A"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "09-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "BIOLOGICAL CLASSIFICATION-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_7049_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_5747_Sol.pdf"),
        VideoLecture: ("LLzRd0qNV7M"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "10-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "SCIENCE OF PLANTS -BOTANY",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_8489_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_7426_Sol.pdf"),
        VideoLecture: ("WXX9cnskHJs"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "12-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "PLANT KINGDOM-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_8757_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_8923_Sol.pdf"),
        VideoLecture: ("Cd-zOAEMlRA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "13-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "PLANT KINGDOM-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_9571_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_9622_Sol.pdf"),
        VideoLecture: ("_4l6HLe0xGw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "MORPHOLOGY OF FLOWERING PLANTS-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_2586_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_5726_Sol.pdf"),
        VideoLecture: ("l77SVSF6UEU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "MORPHOLOGY OF FLOWERING PLANTS-2",
        ShortNotes: "",
        Assignments: ("https://rizee.in/econtent/LiveClass/D8_4527_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_8396_Sol.pdf"),
        VideoLecture: ("zu-LlguZkzo"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "MODES OF REPRODUCTION",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_1812_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_5965_Sol.pdf"),
        VideoLecture: ("XMo2mX6mlxw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "SEXUAL REPRODUCTION IN FLOWERING PLANTS-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_781_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_6349_Sol.pdf"),
        VideoLecture: ("YN6BC_xSCLI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "SEXUAL REPRODUCTION IN FLOWERING PLANTS-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_9819_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_8279_Sol.pdf"),
        VideoLecture: ("30-QsKnU5hI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "TAXONOMY OF ANGIOSPERM",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_7064_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_9943_Sol.pdf"),
        VideoLecture: ("Sl5Qyiliy68"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "THE UNIT OF LIFE",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_3608_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_5125_Sol.pdf"),
        VideoLecture: ("u13NlKLpITk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "BIOMOLECULES",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_2118_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_2843_Sol.pdf"),
        VideoLecture: ("eG0jZYOdzkE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "CELL CYCLE AND CELL DIVISION",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_2507_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_4225_Sol.pdf"),
        VideoLecture: ("Y9PiGi4Bztg"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "HISTOLOGY AND ANOTOMY OF FLOWERING PLANTS",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_2457_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_9706_Sol.pdf"),
        VideoLecture: ("x4y-F1WNwbM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "ECOLOGICAL ADAPTATION,SUCCESSION AND ECOLOGICAL SERVICES",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_5391_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_7401_Sol.pdf"),
        VideoLecture: ("m0VrRzkkZd0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "ENZYMES",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_804_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_3753_Sol.pdf"),
        VideoLecture: ("lozGtuHX_Bk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "PHOTOSYNTHESIS IN HIGHER PLANTS",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_7052_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_3401_Sol.pdf"),
        VideoLecture: ("mktArZwcRMM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "RESPIRATION IN PLANTS",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_2151_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_1013_Sol.pdf"),
        VideoLecture: ("AOad89DPyMI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "PLANT GROWTH AND DEVELOPMENT",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_2483_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_1353_Sol.pdf"),
        VideoLecture: ("cXl2NHjzW-Q"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "MICRO BIOLOGY (BACTERIA AND VIRUSES)",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_2658_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_7432_Sol.pdf"),
        VideoLecture: ("AqL1MrMz7w4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "PRINCIPLES OF INHERITANCE AND VARIATION",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_9198_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_9723_Sol.pdf"),
        VideoLecture: ("EONZ5ivK0D4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "MOLECULAR BASIS OF INHERITANCE",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_8811_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_2066_Sol.pdf"),
        VideoLecture: ("vDXgoiNQ-xY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "BIOTECHNOLOGY PRINCIPLES AND PROCESSES",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_8237_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_5449_Sol.pdf"),
        VideoLecture: ("R4ZhipODSr0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "BIOTECHNOLOGY AND ITS APPLICATIONS",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_593_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_5602_Sol.pdf"),
        VideoLecture: ("wuQ210k0Q-o"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "MICROBES IN HUMEN WELFARE,STRATEGIES FOR ENHANCEMENT IN FOOD PRODUCTION",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_2607_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_5871_Sol.pdf"),
        VideoLecture: ("q3jVauob7qE"),
        Live: (""),
        Zoom: (""),
    },
];
let zoologyQuestionData = [
    {
        DATE: "02-07-2021",
        ClassTimings: "12 PM to 1 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Animal Tissues",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("s4Sc4KXOxY8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "12 PM to 1 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Introduction to Zoology-Branches and Tips and Tricks to Score above 100Marks",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("Ooyg1f10CA8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "12 PM to 1 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Animal kingdom classification - First year  units Briefing",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("o3vqnA1D7dM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "12 PM to 1 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Second Year Topics - Briefing | EAMCET | Zoology | Agriculture |",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("5FZ-UFyVXnE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Diversity of Living World-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_4485_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_2757_Sol.pdf"),
        VideoLecture: ("Jmu8WdPBHbk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "08-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Diversity of Living World-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_3849_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_7663_Sol.pdf"),
        VideoLecture: ("P6hYc0CVLEc"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "09-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Structural organization in animals-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_8876_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_7369_Sol.pdf"),
        VideoLecture: ("1lcGSieveZ4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "10-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Structural organization in animals-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_6153_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_6039_Sol.pdf"),
        VideoLecture: ("vYQ2T4qbMh4"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "12-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Structural organization in animals-3",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_7902_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_1844_Sol.pdf"),
        VideoLecture: ("QNY0EPIe5Mk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "13-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Animal Diversity-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_5548_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_9508_Sol.pdf"),
        VideoLecture: ("y6W9_gXrbc4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Animal Diversity-2-I ",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_3746_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_7919_Sol.pdf"),
        VideoLecture: ("xYhtW8vV4uU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Animal Diversity-2-II",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_5743_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_9115_Sol.pdf"),
        VideoLecture: ("R_2ufMCx3g8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Locomotion and Reproduction",
        ShortNotes: "",
        Assignments: ("https://rizee.in/econtent/LiveClass/D9_7343_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_7371_Sol.pdf"),
        VideoLecture: ("GoRg3b3hyok"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Biology in Human Welfare-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_8507_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_9873_Sol.pdf"),
        VideoLecture: ("In691UWxE84"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Biology in Human Welfare-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_317_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_3633_Sol.pdf"),
        VideoLecture: ("p7CU8D6CnoA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Periplaneta americana(Cockroach)",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_1528_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_7718_Sol.pdf"),
        VideoLecture: ("X1EBuUOQ82c"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Ecology and Environment-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_5272_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_4856_Sol.pdf"),
        VideoLecture: ("0I1kAbYgOo0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Ecology and Environment-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_3481_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_9115_Sol.pdf"),
        VideoLecture: ("ykGz5QdALUM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Human Anatomy and Physiology-1B",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_4191_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_8567_Sol.pdf"),
        VideoLecture: ("Bu4s-BLBuvg"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: " Body Fluids and Circulation",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_5389_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_5384_Sol.pdf"),
        VideoLecture: ("oz0oRQ97mBQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Excretory products and their Elimination",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_584_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_2349_Sol.pdf"),
        VideoLecture: ("yt9-GfrfXHk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Musculoskeletal system",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_8633_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_169_Sol.pdf"),
        VideoLecture: ("JV7VgdBzt7M"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Neural control and coordination",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_6403_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_2947_Sol.pdf"),
        VideoLecture: ("ZMvA99-r_Ks"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Endocrine system and Chemical coordination",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_4746_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_1674_Sol.pdf"),
        VideoLecture: ("cS2YE5yFIrU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Immune System",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_1704_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_7083_Sol.pdf"),
        VideoLecture: ("43ixKreKBPA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Human Reproduction-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_8927_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_7509_Sol.pdf"),
        VideoLecture: ("Ywtot2GAYxU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Human Reproduction-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_6989_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_9086_Sol.pdf"),
        VideoLecture: ("AxoGYdO_dug"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Reproductive Health",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_6815_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_288_Sol.pdf"),
        VideoLecture: ("0F304ZdLie4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Genetics-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_5709_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_9855_Sol.pdf"),
        VideoLecture: ("hQP5ljbmPI0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Genetics-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_65_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_5652_Sol.pdf"),
        VideoLecture: ("Y1zNF9uqnrI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Applied Biology",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_4427_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_8831_Sol.pdf"),
        VideoLecture: ("0ePGrGer8Bs"),
        Live: (""),
        Zoom: (""),
    },
];
let QuestionData = []
if (Cookies.get("examid") == 3 || Cookies.get("examid") == 6) {
    QuestionData.push(...mathsQuestionData)
}
if (Cookies.get("examid") == 7 || Cookies.get("examid") == 8) {

    QuestionData.push(...QuestionDataAgri)

}

if (Cookies.get("examid") == 3 || Cookies.get("examid") == 6) {
    QuestionData.push(...physicsQuestionData)
}

if (Cookies.get("examid") == 7 || Cookies.get("examid") == 8) {
    QuestionData.push(...botonyQuestionData)
    QuestionData.push(...zoologyQuestionData)

}
if (Cookies.get("examid") == 7 || Cookies.get("examid") == 8) {
    QuestionData.push(...ChemistryQuestionDataAgri)
}
if (Cookies.get("examid") == 3 || Cookies.get("examid") == 6) {
    QuestionData.push(...chemistryQuestionDataEngg)
}

let LiveClassesData = []

for (var i in QuestionData) {
    let currentDate = moment().format("DD/MM/YYYY");
    if ((QuestionData[i]["VideoLecture"] || QuestionData[i]["Live"] || QuestionData[i]["Zoom"]) && (currentDate == moment(QuestionData[i].DATE, "DD-MM-YYYY").format("DD/MM/YYYY"))) {
        LiveClassesData.push(QuestionData[i]);
    }
}

var Zoom;
var Livevideo;


class AllSubjects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            userRestionModalShow: false,
            dataLiveUrl: "",
            allLiveClasses: true
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }


    modalaFun = (row) => {

        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });

    }
    modalFunSubscribed = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7 && Cookies.get("institution_id") == 30 && this.isStudentUserValid.current_plan_id == "23") {
            this.setState({
                userRestionModalShow: true,
            });
        } else {
            this.setState({
                liveModalShow: true,
                dataLiveUrl: row.Live

            });
        }



    }
    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }
    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    sortFuncDate(a, b, order, dataField, rowA, rowB) {
        if (order == 'desc') {

            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }
    actionFormatterAssignments(cell, row, rowIndex) {
        return (
            <>
                {
                    row.Assignments != "" ?
                        <div className="Assignments">
                            <img src={FileText} alt="file-text-img" ></img>
                            <a href={row.Assignments} target="_blank"> Assignment Download</a>
                        </div> : <h6 className="text-center">-</h6>
                }

                {
                    row.Solutions != "" ?
                        <div className="Solutions">
                            <img src={FileArrowDown} alt="File-Arrowdown-img"></img>
                            <a href={row.Solutions} target="_blank" > Solutions</a>
                        </div> : ""
                }
            </>

        )
    }
    checkForDate = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7) {
            return true
        }
        return false
    }
    actionFormatterVideoLecture(cell, row, rowIndex) {

        return (
            <>
                <div className="allsubjects">
                    {
                        row.Live == "" ?

                            row.VideoLecture != "" ?
                                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                : "-"


                            :
                            <>

                                {
                                    <a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-success zoom_effect effect-1">Zoom</button></a>
                                }
                                {
                                    <button type="button" className="btn btn-warning live live_effect effect-1" onClick={() => this.modalaFun(row.Live)}>Live</button>
                                }



                            </>
                    }

                </div>

            </>
        )
    }

    colFunction() {

        let columns = [
            // {
            //     dataField: "DATE",
            //     text: "Date",
            //     sort: true,
            //     headerStyle: {
            //         width: "15%"
            //     },
            //     style: {
            //         fontWeight: "bold !important"
            //     },
            //     sortFunc: this.sortFuncDate.bind(this)
            // },
            // {
            //     dataField: "ClassTimings",
            //     text: "Class Timings",
            //     sort: true,
            //     headerStyle: {
            //         width: "23%"
            //     },
            //     sortFunc: this.sortFuncMulti.bind(this)
            // },

            {
                dataField: "Subject",
                text: "Subject",
                style: (row, rowIndex) => {
                    return { fontWeight: "bold !important" };
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                    width: "33%"
                }
            },
            {
                dataField: "Assignments/Solution",
                text: "Assignment/Solution",
                headerStyle: {
                    width: "55%"
                },

                formatter: this.actionFormatterAssignments.bind(this)
            },
            // {
            //     dataField: "Solutions",
            //     text: "Solutions",
            //     formatter: this.actionFormatterSolutions
            // },
            {
                dataField: "Videos/Recorded/Live",
                text: "Videos(Recorded/Live)",
                formatter: this.actionFormatterVideoLecture.bind(this)
            },
            // {
            //     dataField: "Live/Zoom",
            //     text: "Live/Zoom",
            //     formatter: this.actionFormatterLive
            // },
        ];
        return columns;
    }

    render() {
        return (
            <Row>
                <Col align="right" sm={12}>
                    <h6 className="switch_button_cls mt-res-4">
                        <p className="allClassesAlignment">All Classes</p>
                        <label class="switch">
                            <input type="checkbox"
                                onChange={() => {

                                    this.setState((state) => {

                                        return {
                                            allLiveClasses: !state.allLiveClasses
                                        }
                                    })
                                }}
                                checked={!this.state.allLiveClasses} />
                            <span class="slider round"></span>
                        </label>
                        <p className="liveClassesAlignment">Live Classes</p>
                    </h6>
                </Col>
                <Col sm={12}>
                    {
                        this.state.allLiveClasses ?
                            <CardLessDataTableWithSearch
                                parentData={QuestionData}
                                particlecolumns={this.colFunction()}
                                defaultSorted={[{
                                    dataField: "ClassTimings",
                                    order: "asc"
                                }]}
                            />
                            :
                            <CardLessDataTableWithSearch
                                parentData={LiveClassesData}
                                particlecolumns={this.colFunction()}
                                defaultSorted={[{
                                    dataField: "ClassTimings",
                                    order: "asc"
                                }]}
                            />
                    }
                    {/* <Card>
                        <BootstrapTable
                            keyField="id"
                            columns={this.colFunction()}
                            data={QuestionData}
                            defaultSorted={[{
                                dataField: "ClassTimings",
                                order: "asc"
                            }]}
                            wrapperClasses="table-responsive"
                            bootstrap4

                        />
                    </Card> */}

                    <LiveClassesModal data={this.state.dataLiveUrl} show={this.state.liveModalShow} onHide={() => this.setState({ liveModalShow: false })} />
                    <UserRestrictionAlertInstitute
                        show={this.state.userRestionModalShow}
                        onHide={() => this.setState({ userRestionModalShow: false })} />
                </Col>
            </Row>

        )
    }

}
export default AllSubjects;