import React, { Component } from 'react'
import { Row, Col, Container, Image, Media, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EXAM_LOGO from '../../../images/exams_eamcet.png'
import LIVE_SESSIONS_LOGO from '../../../images/live_classes_eamcet.png'
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';

import './_eamcetCrashCourseHome.scss';

class EamcetCrashCourseBlockSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blockType: ""
        }
    }

    componentDidMount = () => {
        const title = GoogleAnalyticsArray[0].Eamcet_Crash_Course;
	ReactGA.pageview('/student/eamcet-crashcourse/home',["rizee"], title);
    }

    render() {

        return (
            <section className="eamcetCrashCourse-home">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='white_pad25 mt-4'>
                                <Row>
                                    <Col sm={3}>
                                        <Link to="/student/eamcet-crashcourse/liveclasses">
                                            <Image
                                                className="mr-4 w-100"
                                                src={LIVE_SESSIONS_LOGO}
                                                alt="Live sessions"
                                            />

                                            <Button type="button" className='btn btn-custom-blue mt-3 center-auto'>Live Classes <i class="fas fa-chevron-right ml-2"></i></Button>
                                        </Link>
                                    </Col>
                                    <Col sm={9}>
                                        <ul className='list-inline custom_li_alignment mt-resp-2'>
                                            <li> Daily live sessions by our experienced faculty</li>
                                            <li>Tricks to solve complicated problems by experts.</li>
                                            <li>Special focus on difficult concepts and chapters</li>
                                            <li>Get benefitted with Live Quizzes and Assignments</li>
                                            <li>Additional Doubt clearing sessions for every individual</li>
                                        </ul>
                                    </Col>

                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <div className='white_pad25 mt-4'>
                                <Row>
                                    <Col sm={3}>
                                        <Link to="/student/eamcet-crashcourse/exams">
                                            <Image
                                                className="mr-4 w-100"
                                                src={EXAM_LOGO}
                                                alt="Live sessions"
                                            />

                                            <Button type="button" className='btn btn-custom-blue-pad mt-3 center-auto'>Exams <i class="fas fa-chevron-right ml-2"></i></Button>
                                        </Link>
                                    </Col>
                                    <Col sm={9}>
                                        <ul className='list-inline custom_li_alignment1 mt-resp-2'>
                                            <li>Revising the Week-long learnings with Mock tests</li>
                                            <li>Error rectifications on weekly assessments</li>
                                            <li>Real-time quizzes and Surprise tests to make you exam ready</li>
                                            <li>Integrated Exams on finished syllabus</li>
                                            <li>Assessments based on the weightage analysis of previous years papers</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        )


    }

}
export default EamcetCrashCourseBlockSection;