import React, { Component } from 'react'
import { Row, Col,Container,Image,Media,Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Cookies from "es-cookie";
import moment from 'moment';
import { withRouter } from "react-router-dom";
import EXAM_LOGO from '../../../images/exams_logo.png'
import LIVE_SESSIONS_LOGO from '../../../images/live_sessions_logo.png'

import './_repeatersRevision.scss';

class RepeatersRevisionSectionBlock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blockType:""
        }

       
    }

    render() {
        
        return (
            <section className="repeters-liveclasses">
                <Container>
                    <Row>
                        <Col sm={12} className='mt-4'>
                            <h3>NEET Rise Course</h3>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <div className='white_pad25 mt-4'>
                                <Row>
                                    <Col sm={3}>
                                        <Link to="/student/Neet_Rise_Course/liveClass_sessions">
                                            <Image
                                                className="mr-4 w-100"
                                                src={LIVE_SESSIONS_LOGO}
                                                alt="Live sessions"
                                            />
                                        
                                            <Button type="button" className='btn btn-custom-blue mt-3 center-auto'>Live Classes <i class="fas fa-chevron-right ml-2"></i></Button>
                                        </Link>
                                    </Col>
                                    <Col sm={9}>
                                        <ul className='list-inline custom_li_alignment mt-resp-2'>
                                            <li>Live Classes by Expert faculty</li>
                                            <li>Live Doubt clarification in the class </li>
                                            <li>Chapterwise Assignments on important Questions</li>
                                            <li>Strategies by Experts to improve scores </li>
                                            <li>Counselling at every step by our friendly Mentors</li>
                                        </ul>
                                    </Col>
                               
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <div className='white_pad25 mt-4'>
                                <Row>
                                    <Col sm={3}>
                                        <Link to="/student/Neet_Rise_Course/exams">
                                            <Image
                                                className="mr-4 w-100"
                                                src={EXAM_LOGO}
                                                alt="Live sessions"
                                            />
                                        
                                            <Button type="button" className='btn btn-custom-blue-pad mt-3 center-auto'>Exams <i class="fas fa-chevron-right ml-2"></i></Button>
                                        </Link>
                                    </Col>
                                    <Col sm={9}>
                                        <ul className='list-inline custom_li_alignment1 mt-resp-2'>
                                            <li>Weekly Exams in the latest NTA pattern</li>
                                            <li>Real-time exam environment to make you exam ready</li>
                                            <li>Integrated Exams on finished syllabus</li>
                                            <li>Focused Exams with previous year papers weightage</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        )

        
    }

}
export default RepeatersRevisionSectionBlock;