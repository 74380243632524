import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import OverAllChapterStrengthSection from '../components/learn_practice/goto_dashboard/OverAllChapterStrengthSection'
import ChepterHeaderSection from '../components/learn_practice/top_header/ChepterHeaderSection';

import * as Cookies from "es-cookie";
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";

const FETCH_DATA = gql` 
query($mobile: String!,
    $chapter_id: Int,$topic_id:Int) {
        getChapterDashboard(mobile: $mobile,
    chapter_id: $chapter_id,topic_id:$topic_id){
        total_exams
        total_questions
        answered
        skipped
        wrong_answered
        wrong_corrected
        correct_answered
        in_time
        less_time
        over_time
        accuracy
        total_time
        type
        error_wrong_corrected
        error_report_web{
            error
            error_name
            count
        }

        
        
        last_practiced_time
    }
}
`;

class InnerOverAllChapterStrength extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggled: "wrapper sidebar-enable"
        }

    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
         if (toggled === "wrapper") {
             this.setState({toggled:"wrapper sidebar-enable"});
             Cookies.set("toggle", "wrapper sidebar-enable");
         } else {
             this.setState({toggled:"wrapper"});
             Cookies.set("toggle", "wrapper");
         }
     };

    render() {
        
        
        const getChapterDashboard = this.props.getChapterDashboard;
        const loading1 = getChapterDashboard.loading;
        const error1 = getChapterDashboard.error;

       if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        return (
             <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper">
                    {(loading1 == true) && (<PreloaderTwo />)}
                    <AsideNavbar onClick={() => this.menuToggler()}/>
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    {
                        !loading1 &&  (
                            <div className="content-wrapper pt-0">
                                <ChepterHeaderSection
                                    last_practiced_time={getChapterDashboard.getChapterDashboard[0].last_practiced_time}
                                    headertype="chapterdashboard"
                                    getData={this.props.getData} />
                                <Container fluid={true}>
                                    <OverAllChapterStrengthSection
                                        getData={this.props.getData}
                                        getChapterDashboard={getChapterDashboard.getChapterDashboard}
                                    />

                                </Container>
                            </div>)
                    }
                </div>
            </div>
        )
    }
}



export default withRouter(compose(
    graphql(FETCH_DATA,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    chapter_id: parseInt(props.getData.ocid),
                    topic_id: parseInt(props.getData.otid)

                },
                fetchPolicy: 'cache-and-network'
            }), name: "getChapterDashboard"
        })
)(InnerOverAllChapterStrength));
