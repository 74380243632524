import React, { Component } from 'react'
import { Row, Col, Table, Image, Button, Container, Accordion, Card, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import { components } from 'react-select'
import * as Cookies from "es-cookie";
import moment from 'moment';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import './_foundationcourse.scss';
import foundationPopup from '../../../images/Foundation_popup.png';
import foundationNewText from '../../../images/foundation_popup2.png';
import foundationLoginOpoup from '../../../images/foundation_popupImg2.png'
import LongTermLoginPopupImage from '../../../images/longTerm_popupImage.png';
import jee1 from '../../../images/images1/jeepred_1.png';
import jee2 from '../../../images/images1/jeepred_2.png';
import jee3 from '../../../images/images1/jeepred_3.png';
import jee4 from '../../../images/images1/jeepred_4.png';
import jee5 from '../../../images/images1/jeepred_5.png';
import jee6 from '../../../images/images1/jeepred_6.png';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import JEEQuestions from '../../../images/images1/previousquestionsjee.jpeg';
import eamcetPrevious from '../../../images/images1/previousquestionseamcet.jpeg'

class LoginPageFoundationPopup extends Component {
    constructor(props) {
        super(props);

    }

    handlePageRedirect = () => {
        localStorage.setItem("loginfoundationModal", false)
        if(Cookies.get("examid") == 1 || Cookies.get("examid") == 2){
            this.props.history.push({
                pathname: "/student/learn-practice"
            })
        }
        else{
            this.props.history.push({
                pathname: "/student/Chapter-wise-questions"
            })
        }
    }


    render() {
        return (
            <Modal {...this.props} className='login_popup_fc'
                size="md" aria-labelledby="example-modal-sizes-title-lg">
                <div className='jee_pred_bg'>

                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    {/* <Modal.Body className='p-3'>
                    <Row>
                        <Col sm={12}>
                            <img src={foundationLoginOpoup} className="fc_popup w-50" alt="foundation" />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Row>
                                <Col sm={12}>
                                    <h5 className='fc_popup_header'><span><img src={foundationNewText} className="new_img" /></span>Rizee is Introducing Foundation Course</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <p className='fc_text_popup'>Foundation courses act as a bridge between your current education (Class XI or XII) and your previous education (Class IX & X). Our comprehensive foundation course is designed to plug any academic gaps, improve your basics in every subject and strengthen your foundation</p>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col sm={12}>
                                    <Button type="" className='fc_popup_btn' onClick={()=>this.handlePageRedirect()}>Explore More</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body> */}
                    <Modal.Body className='p-3'>
                    <Row>   
                        <Col>
                        {
                            Cookies.get("examid") == 1 || Cookies.get("examid") == 2 ?
                            (
                                <img src={JEEQuestions} onClick={()=>this.handlePageRedirect()} className="longtermpopupimage"alt="previousQuestions" />
                            )
                            :
                            (
                                <img src={eamcetPrevious} onClick={()=>this.handlePageRedirect()} className="longtermpopupimage"alt="previousQuestions" />
                            )
                        }
                        </Col>
                    </Row>
                </Modal.Body>


                    {/* <Modal.Body>
                        <Row>
                            <Col sm={12}>
                                <h4 className='jee_pop_header'>JEE MAINS & ADVANCED COLLEGE AND BRANCH PREDICTOR IS HERE</h4>
                            </Col>
                        </Row>
                        <Row className='mt-5'>
                            <Col sm={2}>
                                <div>
                                    <div>
                                        <img src={jee1} className="jeeimg" />
                                    </div>
                                    <p className='jee_pop_des'>Last year cutoffs</p>
                                </div>
                            </Col>

                            <Col sm={2}>
                                <div>
                                    <div>
                                        <img src={jee2} className="jeeimg" />
                                    </div>
                                    <p className='jee_pop_des'>College lists</p>
                                </div>

                            </Col>
                            <Col sm={2}>
                                <div>
                                    <div>
                                        <img src={jee3} className="jeeimg" />
                                    </div>
                                    <p className='jee_pop_des'>Branch lists</p>
                                </div>
                            </Col>

                            <Col sm={2}>
                                <div>
                                    <div>
                                        <img src={jee4} className="jeeimg" />
                                    </div>
                                    <p className='jee_pop_des'>Shortlisting</p>
                                </div>
                            </Col>

                            <Col sm={2}>
                                <div>
                                    <div>
                                        <img src={jee5} className="jeeimg" />
                                    </div>
                                    <p className='jee_pop_des'>Priority order</p>
                                </div>
                            </Col>

                            <Col sm={2}>
                                <div>
                                    <div>
                                        <img src={jee6} className="jeeimg" />
                                    </div>
                                    <p className='jee_pop_des'>Download Web options</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Button type="" className='fc_popup_btn' onClick={() => this.handlePageRedirect()}>PREDICT NOW</Button>
                            </Col>
                        </Row>
                    </Modal.Body> */}
                </div >

            </Modal>
        )
    }
}

export default withRouter(compose(
)(LoginPageFoundationPopup));