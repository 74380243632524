import React, { Component } from 'react'
import MockTestSeriesNTAPattern from '../components/mock_series_newNTAPattern/MockTestSeriesSection'
import AsideNavbar from '../components/navbars/AsideNavbar'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'
import { withRouter } from 'react-router-dom';
import * as Cookies from "es-cookie";

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import NEETPrepBundlePopup from '../components/mock_series_newNTAPattern/NEETPrepBundlepopup'


const UPDATE_STUDENT_ANALYTICS = gql`
  mutation(
    $params:AnalyticsStudents1  
    ) {
        studentAnalytics(
        params: $params
     )
  }
`;


class MockTestSeriesNewNTAPattern extends Component {
    constructor(props) {
        super(props)

        this.state = {
            headerBottomImg: {
                Img: require('../../images/mocktext-img.png'),
                Title: "Mock Test",
                width: 150,
                helpImg: require('../../images/Ask_me_for_Help.gif')
            },
            submitError: "",
            planpopup: true


        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }

        localStorage.setItem("resumeExam", false);
        localStorage.setItem("uid", 0);
    }
    onHide = () => {
        this.setState({
            planpopup: false
        })
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 9,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
            update: (store, { data }) => {
                console.log("data", data)
                if (data.studentAnalytics) {
                    this.setState({
                        submitError: ""
                    });
                }
            }
        });
    };

    componentDidMount = () => {

        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Test_Series_Home;
        ReactGA.pageview('/student/exams/test-series', ["rizee"], title);

    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    render() {
        const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

        return (
            <div className={Cookies.get("toggle")}>
                <div className="student main-wrapper">
                    <div className="student header-area mock-tests-eries-topnavbar">
                        <ScrollTopNavbar headerBottom={this.state.headerBottomImg} onClick={() => this.menuToggler()} />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper">
                        <MockTestSeriesNTAPattern data={this.props} />
                        {/* {
                            isuserValid.jeePrepBundleAcess == true ?
                                (
                                    <NEETPrepBundlePopup
                                        show={this.state.planpopup}
                                        onHide={this.onHide}
                                    />
                                )
                                :
                                ("")
                        } */}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),

)(MockTestSeriesNewNTAPattern));