import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Image, Navbar, Dropdown, Form, FormControl, Button, Container, Media } from 'react-bootstrap';
import * as Cookies from "es-cookie";
import { components } from 'react-select';
import SelectDropDown from '../../../neetjee_guru/components/selectdropdown/SelectDropDown';
import './_collegeRankPredictor.scss';
import MultiSelect from "react-multi-select-component";
import { withRouter, Link } from "react-router-dom";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withApollo } from "react-apollo";
import CardLessDataTableWithOutSearch from "../../../neetjee_guru/components/datatables/CardLessDataTableWithOutSearch"
import CollegeDataModal from './collegeDataModal';
import CollegeAllocatedSeatsModal from './collegeAllocatedSeatsModal';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';
import shortList from '../../../images/happy-students.png'
import ShortListPage from './shortListedPage'
import ClearModal from './clearAllColleges'


const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
};

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

const FETCH_SUB_CASTES = gql` 
query($eamcet_caste_id: Int!,$state_id:Int) {
    getEamcetCasteSubCategories(eamcet_caste_id: $eamcet_caste_id,state_id:$state_id){
        id
        name
     }
}
`;

const FETCH_COLLEGES = gql` 
query($state: Int!,$branch_id:String,$eamcet_college_type_id:String,$district:String) {
    getCollegeName(state: $state,branch_id:$branch_id,eamcet_college_type_id:$eamcet_college_type_id,district:$district ){
        college_id
        college_name
     }
}
`;

const FETCH_BRANCHES = gql` 
query($id: Int ,$state_id :Int) {
    getEamcetBranchesPrediction(id: $id state_id:$state_id){
        id
        branch_name
     }
}`;

const COLLEGE_SHORTLIST = gql`
mutation(
    $params :CollegePrediction1
){
    collegePrediction(
        params : $params
    )
}
`;

const GET_SHORTLISTED_COLLEGES = gql`
query($mobile:String){
    getBranchDraftData(mobile:$mobile){
        mobile
        college_name
        branch_name
      }
}
`

const DELETE_SHORTLISTED_COLLEGE = gql`
mutation(
    $params:DeletePrediction
    ){
    deleteCollegePredictor(
        params:$params
        )
}
`;


const GET_DISTRICTS = gql`
query($state_id:Int){
    getDistrictNames(state_id:$state_id){
        district_id
        district_name
      }
}
`

const removeALL_Shortlisted_colleges = gql`
mutation($params:AllShortlistedColleges){
    removeAllShortlistedColleges(params:$params)
}
`

class CollegeRankPredictorHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subCasteValue: [],
            predictData: [],
            showSpinner: "hide",
            collegeDataModal: false,
            collegeData: [],
            rowIndexVal: 0,
            getCollegeBranchWiseData: [],
            dataAvailable: 0,
            error_display: "",
            showSpinnerBranch: "hide",
            collegeId: 0,
            allocatedDataModal: false,
            allocatedData: [],
            branchWiseDataCounts: [],
            shortListModal: false,
            shortListedCollege: [],
            shortListStatus: 0,
            spinnerShortlist: "hide",
            getBranchDraftData: [],
            shortListPage: false,
            shortlistPageLoading: "hide",
            collegetypes: [],
            clearallModal:false,
            spinnerClear:'0',
            getNirfRanking:[]

        }
    }

    componentDidMount() {
        this.onScrollgetQuestionById();
    }



    getMainCastes() {
        let newArray = [];
        const newObj1 = {
            value: "0",
            label: "ALL"
        }
        newArray.push(newObj1);
        this.props.getMainCastes.map((item) => {
            if (item != undefined) {
                const newObj = {
                    value: item.id,
                    label: item.main_caste
                }
                newArray.push(newObj);
            }
        }
        )
        return newArray;
    };

    getSubCastes() {
        let newArray = [];
        const newObj1 = {
            value: "0",
            label: "ALL"
        }
        newArray.push(newObj1);
        this.props.stateData.subCasteData.map((item) => {
            if (item != undefined) {
                const newObj = {
                    value: item.id,
                    label: item.name
                }
                newArray.push(newObj);
            }
        })
        return newArray;
    };

    getcollegetypes() {
        let newArray = [];
        this.props.getCollegeType.map((item) => {
            if (item != undefined) {
                const newobj = {
                    value: item.college_type_id,
                    label: item.college_type
                }
                newArray.push(newobj)
            }
        })
        return newArray
    }


    collegeDataFunc (cell, row, rowIndex, formatExtraData) {
        return (
            <>
                <a onClick={() => this.singleCollegeData(row, rowIndex)}>
                    <p style={{ color: "black", textDecoration: "underline" }} className="under_dec">{row["college_name"]}</p>
                </a>
            </>
        )
    }

    shortListFunc(cell, row, rowIndex, formatExtraData) {
        return (
            <>
                {
                    row.is_shortlisted == true ?
                        (
                            <button className='shortListed_btn mt-3' onClick={() => this.removeCollegeShortlist(row)}> <i class="far fa-times cross "></i>  Remove from Shortlist</button>)
                        :
                        (
                            <button className='short_list_btn mt-3' onClick={() => this.addCollegeShortlist(row)}> <i class="fal fa-plus"></i> Add to Shortlist</button>
                        )
                }
            </>
        )
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""
        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 206,
            source: 2
        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(addStudentObj).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }
    addStudentAnalyticsShortlist = e => {
        let addStudentObj = ""
        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 208,
            source: 2
        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(addStudentObj).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    addStudentAnalyticsCol = e => {
        let addStudentObj = ""
        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 207,
            source: 2
        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(addStudentObj).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    addCollegeShortlist = (row) => {
        this.setState({ spinnerShortlist: "show" })

        let addCollege = ""
        addCollege = {
            mobile: Cookies.get("mobile"),
            eamcet_college_id: row.college_id,
            eamcet_branch_id: row.branch_id,
            comments: "",
            exam_id: parseInt(this.props.stateData.exam_type.value)
        }

        this.submitCollege(addCollege, row).catch(error => {
            console.log("catch if error");
            console.log(error);
        })
    }

    submitCollege = async (params, row) => {
        await this.props.submitCollege({
            variables: {
                params
            },
            update: (store, { data }) => {
                const getData = this.state.predictData.map((item) => {
                    if (item.college_id == row.college_id && item.branch_id == row.branch_id) {
                        return { ...item, is_shortlisted: true }
                    }
                    return { ...item }
                })

                this.state.predictData = getData;

                if (data.collegePrediction) {
                    this.setState({ shortListStatus: 1 })
                    setTimeout(() => {
                        this.SetpageLoad();
                    }, 1000);
                }
            }
        });
    }

    SetpageLoad = () => {
        this.setState({ shortListStatus: 0, spinnerShortlist: "hide" });
    };


    removeCollegeShortlist = (row) => {
        let removeCollege = ""
        removeCollege = {
            mobile: Cookies.get("mobile"),
            eamcet_college_id: row.college_id,
            eamcet_branch_id: row.branch_id,
        }
        this.removeCollegeData(removeCollege, row).catch(error => {
            console.log("catch if error");
            console.log(error);
        })

    }

    removeCollegeData = async (params, row) => {
        await this.props.removeCollegeData({
            variables: {
                params
            },
            update: (store, { data }) => {
                const getData = this.state.predictData.map((item) => {
                    if (item.college_id == row.college_id && item.branch_id == row.branch_id) {
                        return { ...item, is_shortlisted: false }
                    }
                    return { ...item }
                })

                this.state.predictData = getData;

                if (data.deleteCollegePredictor) {
                    this.setState({ shortListStatus: 2 })
                    setTimeout(() => {
                        this.SetpageLoad();
                    }, 1000);
                }
            }
        })

    }

    clearAll = () => {
        this.setState({spinnerClear:'1'})
        let removeAllData = ""
        removeAllData = {
            mobile: Cookies.get("mobile"),
            exam_id: parseInt(this.props.stateData.exam_type.value) == 24 ? 6 : 3
        }
        this.removeAllData(removeAllData).catch(error => {
            console.log("catch if error");
            console.log(error);
        })

    }
    removeAllData = async (params, row) => {
        await this.props.removeAllData({
            variables: {
                params
            },
            update: (store, { data }) => {
                const getData = this.state.predictData.map((item) => {
                 
                        return { ...item, is_shortlisted: false }
            
                    // return { ...item }
                })

                this.state.predictData = getData;

                if (data.removeAllShortlistedColleges) {
                  this.onScrollgetQuestionById()
                  this.setState({spinnerClear:'0',clearallModal:false})
                }
            }
        })

    }

    singleCollegeData = async (row, rowIndex) => {

        this.addStudentAnalyticsCol()

        this.setState({
            collegeDataModal: true,
            collegeData: row,
            rowIndexVal: rowIndex,
            showSpinnerBranch: "show",
            collegeId: row.college_id,
            getCollegeBranchWiseData: [],
            branchWiseDataCounts: [],
            getNirfRanking:[]
        })


        const result = await this.props.client.query({
            query: gql`
            query($college:Int,$state:Int){
                getCollegeBranchWiseData(college:$college,state:$state){
                    college_name
                    location
                    college_website
                    branchDetails{
                    branch_id
                    branch_name
                    no_of_seats
                    image_path
                    }
                }
            }
            `,
            variables: {
                state:parseInt(this.props.stateData.exam_type.value),
                college: row.college_id
            },
            fetchPolicy: 'network-only'
        })

        if (result.data.getCollegeBranchWiseData != undefined) {
            this.setState({
                getCollegeBranchWiseData: result.data.getCollegeBranchWiseData,
                showSpinnerBranch: "hide",

            })
        }

        const result2 = await this.props.client.query({
            query:gql`
            query($college:Int){
                getNirfRanking(college:$college){
                    rank
                    image_path
                }
            }
            `,
            variables:{
                college:row.college_id
            }
        })


        if (result2.data.getNirfRanking != undefined) {
            this.setState({
                getNirfRanking: result2.data.getNirfRanking,

            })
        }

    }

    allocatedDataFunc(cell, row, rowIndex, formatExtraData) {
        return (<>
            <a onClick={() => this.allocatedRowData(row, rowIndex)}>
                <p style={{ color: "black", textDecoration: "underline" }} className="under_dec">{row["no_of_seats"]}</p>
            </a>
        </>)
    }

    allocatedRowData = async (row, rowIndex) => {
        this.setState({
            allocatedDataModal: true,
        });
        const result = await this.props.client.query({
            query: gql`
            query($college_id:Int,$sub_cat_id:Int,$branch_id:Int,$state:Int,$caste_id:Int){
                getAllocatedSeatsData(college_id:$college_id,sub_cat_id:$sub_cat_id,branch_id:$branch_id,state:$state,caste_id:$caste_id){
                    gender
                    region
                    rank
                }
            }
            `,
            variables: {
                college_id: row.college_id,
                sub_cat_id: row.sub_cat_id,
                branch_id: row.branch_id,
                state: parseInt(this.props.stateData.exam_type.value),
                caste_id:row.eamcet_caste_id
            },
            fetchPolicy: 'network-only'
        })

        if (result.data.getAllocatedSeatsData != undefined) {
            this.setState({
                allocatedData: result.data.getAllocatedSeatsData,
            })
        }
    }

    showShortListedData = async () => {
        this.addStudentAnalyticsShortlist()

        this.setState({ shortListPage: !this.state.shortListPage, shortlistPageLoading: "show" })

        const result = await this.props.client.query({
            query: gql`
            query($mobile:String){
                getBranchDraftData(mobile:$mobile){
                    mobile
                    college_name
                    branch_name
                    branch_id
                    college_code
                    branch_code
                    exam_id
                  }
            }
            `,
            variables: {
                mobile: Cookies.get("mobile")
            },
            fetchPolicy: 'network-only'
        })


        if (result.data.getBranchDraftData != undefined) {
            this.setState({
                getBranchDraftData: result.data.getBranchDraftData,
                shortlistPageLoading: "hide"
            })
        }
    }

    colFunction() {

        let columns = [

            {
                dataField: "college_name",
                text: "ShortList",
                formatter: this.shortListFunc.bind(this),
            },
            {
                dataField: "college_name",
                text: "College Name",
                sort: true,
                formatter: this.collegeDataFunc.bind(this),

            },
            {
                dataField: "college_code",
                text: "College code",
                sort: true,

            },
            {
                dataField: "location",
                text: "Location",
                sort: true

            },
            {
                dataField: "branch_name",
                text: "Branch",
                sort: true

            },
            {
                dataField: "no_of_seats",
                text: "Allocated Seats",
                formatter: this.allocatedDataFunc.bind(this),
                sort: true

            },
            {
                dataField: "main_caste",
                text: "Reservation Category",
                sort: true

            },
            {
                dataField: "name",
                text: "Reservation Sub Category",
                sort: true,
            },
            {
                dataField: "Minimum_Rank",
                text: "Minimum Rank",
                sort: true
            },
            {
                dataField: "Maximum_Rank",
                text: "Maximum Rank",
                sort: true
            },

        ];
        return columns;

    }
    onScrollgetQuestionById = async () => {

        this.addStudentAnalytics()

        this.setState({ error_display: "" });
        let getPredictNowWithRank = [];
        let rank_val = 0, marrks_val = 0;
        if (this.props.stateData.input_pattern_type == "rank") {
            rank_val = this.props.stateData.inputRank_Marks_count;
        } else {
            marrks_val = this.props.stateData.inputRank_Marks_count;
        }
        this.setState({ showSpinner: "show", predictData: [], dataAvailable: 0 });

        if (this.props.stateData.input_pattern_type == "marks" && this.props.stateData.inputRank_Marks_count > 160) {
            this.setState({ error_display: "Please Enter Valid Marks", dataAvailable: 0, showSpinner: "hide" });
            return;
        }

        const result = await this.props.client.query({
            query: gql` 
                query($state: Int,$branch:String,$seat_category:String,$college:String,$caste:Int,$my_rank:Int,$my_marks:Int, $mobile:String,$college_type_id:String,$district:String) {
                        getPredictNow(state: $state,branch:$branch,seat_category:$seat_category,college:$college,caste:$caste,my_rank:$my_rank,my_marks:$my_marks,mobile:$mobile,college_type_id:$college_type_id,district:$district)
                        {
                            college_id
                            college_name
                            college_code
                            location
                            state_name
                            main_caste
                            name
                            no_of_seats
                            Minimum_Rank
                            Maximum_Rank
                            branch_name
                            sub_cat_id
                            branch_id
                            is_shortlisted
                            eamcet_caste_id
                        }
                }
            `,
            variables: {
                state: parseInt(this.props.stateData.exam_type.value),
                branch: this.props.stateData.ebranchselvalue.toString(),
                caste: parseInt(this.props.stateData.caste.value),
                seat_category: this.props.stateData.escasteselvalue.toString(),
                college: this.props.stateData.collegeselvalue.toString(),
                my_rank: parseInt(rank_val),
                my_marks: parseInt(marrks_val),
                mobile: Cookies.get("mobile"),
                college_type_id: this.props.stateData.collegetypeselvalue.toString(),
                district: this.props.stateData.districtselvalue.toString()

            },
            fetchPolicy: 'network-only'
        });


        if (result.data.getPredictNow.length > 0) {
            /* if(parseInt(this.state.rank) > 0){
                 console.log("eeeeeeeeee",parseInt(this.state.rank));
                 getPredictNowWithRank =   result.data.getPredictNow.filter((item)=> parseInt(this.state.rank) >= item.Minimum_Rank )
             }else{
                 console.log("wwwwwwwwwwww",parseInt(this.state.rank));
                 getPredictNowWithRank = result.data.getPredictNow
             }*/
            getPredictNowWithRank = result.data.getPredictNow

            this.setState({
                predictData: getPredictNowWithRank,
                showSpinner: "hide",
                dataAvailable: 1
            });
        } else {
            this.setState({
                predictData: [],
                showSpinner: "hide",
                dataAvailable: 0
            });
        }
    }

    viewShortListPage = () => {
        this.setState({ shortListPage: false })
    }

    render() {

        const getSubCastes = this.props.getSubCastes
        const loading = getSubCastes.loading
        const error = getSubCastes.error

        const getCollegeName = this.props.getCollegeName
        const loading1 = getCollegeName.loading
        const error1 = getCollegeName.error

        const getBranches = this.props.getBranches
        const loading2 = getBranches.loading
        const error2 = getBranches.error

        const getBranchDraftData = this.props.getBranchDraftData
        const loading5 = getBranchDraftData.loading
        const error5 = getBranchDraftData.error

        const getDistrictNames = this.props.getDistrictNames
        const loading3 = getDistrictNames.loading
        const error3 = getDistrictNames.error



        if (error !== undefined) {
            alert("Server Error1. " + error.message);
            return null;
        }

        if (error1 !== undefined) {
            alert("Server Error2. " + error1.message);
            return null;
        }
        if (error2 !== undefined) {
            alert("Server Error3. " + error2.message);
            return null;
        }

        if (error5 !== undefined) {
            alert("Server Error4. " + error5.message);
            return null;
        }
        if (error3 !== undefined) {
            alert("Server Error5. " + error3.message);
            return null;
        }

        if (!loading) {
            this.props.stateData.subCasteData = getSubCastes.getEamcetCasteSubCategories
        }

        if (!loading1) {
            this.props.stateData.collegeData = getCollegeName.getCollegeName
        }

        if (!loading2) {
            this.props.stateData.branch = getBranches.getEamcetBranchesPrediction
        }

        if (!loading) {
            this.props.stateData.districtData = getDistrictNames.getDistrictNames
        }

        let branchnewArray = [];
        this.props.stateData.branch.map((item) => {
            if (item != undefined) {
                const newObj = {
                    value: item.id,
                    label: item.branch_name,
                }
                branchnewArray.push(newObj);
            }
        })
        let branchselectall = true;
        let branchlabelledBY = "ALL";
        let branchdisablesearch = false;
        if (branchnewArray.length > 0) {
            branchselectall = true;
            branchdisablesearch = false;
        }
        else {
            branchdisablesearch = true;
            branchselectall = false;
            branchlabelledBY = "No Options"
        }

        // districts 
        let districtNewArray = [];
        if (this.props.stateData.districtData != undefined) {
            this.props.stateData.districtData.map((item) => {
                if (item != undefined) {
                    const newObj = {
                        value: item.district_id,
                        label: item.district_name
                    }
                    districtNewArray.push(newObj)
                }
            })

        }

        let districtselectall = true;
        let districtlabelledBy = "ALL";
        let districtdisabledsearch = false;
        if (districtNewArray.length > 0) {
            districtselectall = true;
            districtdisabledsearch = false;
        }
        else {
            districtdisabledsearch = true;
            districtselectall = false;
            branchlabelledBY = "No Options"
        }

        //adding select all to sub caste

        let scastenewArray = [];
        this.props.stateData.subCasteData.map((item) => {
            if (item != undefined) {
                const newObj = {
                    value: item.id,
                    label: item.name,
                }
                scastenewArray.push(newObj);
            }
        })
        let scasteselectall = true;
        let scastelabelledBY = "ALL";
        let scastedisablesearch = false;
        if (scastenewArray.length > 0) {
            scasteselectall = true;
            scastedisablesearch = false;
        }
        else {
            scastedisablesearch = true;
            scasteselectall = false;
            scastelabelledBY = "No Options"
        }

        // college types

        let collegetypenewArray = [];
        this.props.getCollegeType.map((item) => {
            if (item != undefined) {
                const newobj = {
                    value: item.college_type_id,
                    label: item.college_type
                }
                collegetypenewArray.push(newobj)
            }
        })

        let collegetypeselectall = true;
        let collegetypelabelledby = "ALL";
        let collegetypedisabledsearch = false;
        if (collegetypenewArray.length > 0) {
            collegetypeselectall = true;
            collegetypedisabledsearch = false;
        }
        else {
            scastedisablesearch = true;
            scasteselectall = false;
            collegetypelabelledby = "No Options"
        }

        // districts



        //adding colleges

        let collegenewArray = [];
        if (this.props.stateData.ebranchseldata.length > 0 || this.props.stateData.districtselvalue.length > 0 || this.props.stateData.collegetypeselvalue.length > 0) {
            this.props.stateData.collegeFilterData.map((item, index) => {
                const newObj = {
                    value: item.college_id,
                    label: item.college_name

                }
                collegenewArray.push(newObj);
            });
        } else {
            this.props.stateData.collegeData.map((item, index) => {
                const newObj = {
                    value: item.college_id,
                    label: item.college_name
                }
                collegenewArray.push(newObj);
            });
        }

        let collegeselectall = true;
        let collegelabelledBY = "ALL";
        let collegedisablesearch = false;
        if (collegenewArray.length > 0) {
            collegeselectall = true;
            collegedisablesearch = false;
        }
        else {
            collegedisablesearch = true;
            collegeselectall = false;
            collegelabelledBY = "No Options"
        }

        return (
            <Container className="college-rank-predictor">
                <Row className='mt-2'>
                    {
                        this.state.shortListPage == true ?
                            (
                                <a style={{ color: "blue", textDecoration: "underline", fontSize: "16px" }} onClick={() => this.viewShortListPage()}> <i class="far fa-long-arrow-left"></i>Back</a>
                            )
                            :
                            ("")
                    }
                </Row>

                {
                    this.state.shortListPage == false ?
                        (
                            <>
                                <Form>
                                    <Row className='first-row'>
                                        <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="examType">
                                            <Form.Label className="text-uppercase font-weight-bold input_title">Exam Type</Form.Label>
                                            <SelectDropDown
                                                stateData={this.props.stateData.exam_type}
                                                handleChange={this.props.selecthandleInputChange}
                                                name="examType"
                                                options={this.props.stateData.examTypes}
                                                placeholderName={'Exam Types'}
                                                dropdownIndicator={{ DropdownIndicator }}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="branch">
                                            <Form.Label className="text-uppercase font-weight-bold input_title">Branch</Form.Label>
                                            <MultiSelect
                                                overrideStrings={{
                                                    "allItemsAreSelected": "All",
                                                    "selectSomeItems": branchlabelledBY
                                                }}
                                                hasSelectAll={branchselectall}
                                                options={branchnewArray}
                                                disableSearch={branchdisablesearch}
                                                value={this.props.stateData.ebranchseldata}
                                                onChange={(e) => this.props.handleMultipleSelectInputChange(e, "branch")}
                                                labelledBy={"Select"}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="caste">
                                            <Form.Label className="text-uppercase font-weight-bold input_title">Reservation Category</Form.Label>
                                            <SelectDropDown
                                                stateData={this.props.stateData.caste}
                                                handleChange={this.props.selecthandleInputChange}
                                                name="caste"
                                                options={this.getMainCastes()}
                                                placeholderName={'Caste'}
                                                dropdownIndicator={{ DropdownIndicator }}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="subCaste">
                                            <Form.Label className="text-uppercase font-weight-bold input_title">Reservation Sub Category</Form.Label>
                                            <MultiSelect
                                                overrideStrings={{
                                                    "allItemsAreSelected": "All",
                                                    "selectSomeItems": scastelabelledBY
                                                }}
                                                hasSelectAll={scasteselectall}
                                                options={scastenewArray}
                                                disableSearch={scastedisablesearch}
                                                value={this.props.stateData.escasteseldata}
                                                onChange={(e) => this.props.handleMultipleSelectInputChange(e, "scaste")}
                                                labelledBy={"Select"}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>


                                        <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="colleges">
                                            <Form.Label className="text-uppercase font-weight-bold input_title">District</Form.Label>
                                            <MultiSelect
                                                overrideStrings={{
                                                    "allItemsAreSelected": "All",
                                                    "selectSomeItems": districtlabelledBy
                                                }}
                                                hasSelectAll={districtselectall}
                                                options={districtNewArray}
                                                disableSearch={districtdisabledsearch}
                                                value={this.props.stateData.districtseldata}
                                                onChange={(e) => this.props.handleMultipleSelectInputChange(e, "district")}
                                                labelledBy={"Select"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="colleges">
                                            <Form.Label className="text-uppercase font-weight-bold input_title">College Type</Form.Label>
                                            <MultiSelect
                                                overrideStrings={{
                                                    "allItemsAreSelected": "All",
                                                    "selectSomeItems": collegetypelabelledby
                                                }}
                                                hasSelectAll={collegetypeselectall}
                                                options={this.getcollegetypes()}
                                                disableSearch={collegetypedisabledsearch}
                                                value={this.props.stateData.collegetypeseldata}
                                                onChange={(e) => this.props.handleMultipleSelectInputChange(e, "collegeType")}
                                                labelledBy={"Select"}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="colleges">
                                            <Form.Label className="text-uppercase font-weight-bold input_title">College Name</Form.Label>
                                            <MultiSelect
                                                overrideStrings={{
                                                    "allItemsAreSelected": "All",
                                                    "selectSomeItems": collegelabelledBY
                                                }}
                                                hasSelectAll={collegeselectall}
                                                options={collegenewArray}
                                                disableSearch={collegedisablesearch}
                                                value={this.props.stateData.collegeseldata}
                                                onChange={(e) => this.props.handleMultipleSelectInputChange(e, "college")}
                                                labelledBy={"Select"}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="colleges">
                                            <div className="custom-control custom-radio custom-control-inline margin_right0">
                                                <input type="radio" id="rankId" value="rank" name="input_pattern_type" className="custom-control-input" defaultChecked={true} onChange={(e) => this.props.handleSelectChange(e)} />
                                                <label className="custom-control-label font-weight-normal" htmlFor="rankId" >  My Rank </label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline margin_right0 right_pos">
                                                <input type="radio" id="marksId" value="marks" name="input_pattern_type" className="custom-control-input" onChange={(e) => this.props.handleSelectChange(e)} />
                                                <label className="custom-control-label font-weight-normal" htmlFor="marksId" >  My Marks</label>
                                            </div>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                name="marks"
                                                autoComplete="off"
                                                className='custom_selection mt-2'
                                                value={this.props.stateData.inputRank_Marks_count}
                                                onChange={(e) => this.props.handleInputChange(e)}
                                            />
                                            <h6 className='font-12 errorCls mt-2'>{this.state.error_display}</h6>
                                        </Form.Group>
                                        <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="colleges" className="">
                                            <Button type="button" className='btn btn-light-blue' onClick={(e) => this.onScrollgetQuestionById()}>SUBMIT</Button>
                                        </Form.Group>
                                    </Row>
                                </Form>
                                {
                                    this.state.predictData.length > 0 ?
                                        (
                                            <Row>
                                                <Col sm={12}>
                                                    <CardLessDataTableWithSearch
                                                        parentData={this.state.predictData}
                                                        particlecolumns={this.colFunction()}
                                                        searchBar={false}
                                                        defaultSorted={[{
                                                            dataField: "Minimum_Rank",
                                                            order: "asc"
                                                        }]}
                                                        pageType="college_predictor"
                                                    />
                                                </Col>
                                            </Row>


                                        )
                                        :
                                        (
                                            <Row>
                                                <Col sm={12} className={this.state.showSpinner == "show" ? "showSpinnerCls text-center" : "hideSpinnerCls text-center"}>

                                                    <div className={this.state.showSpinner == "show" ? "spinner-border text-primary text-center loading-img-center" : ""} >
                                                        {
                                                            this.state.showSpinner == "hide" ? "No Data Available" : ""
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>


                                        )
                                }
                                <CollegeDataModal
                                    show={this.state.collegeDataModal}
                                    onHide={() => this.setState({ collegeDataModal: false, getCollegeBranchWiseData: [], branchWiseDataCounts: [] })}
                                    rowIndex={this.state.rowIndexVal}
                                    BranchWiseData={this.state.getCollegeBranchWiseData}
                                    stateData={this.state.showSpinnerBranch}
                                    collegeId={this.state.collegeId}
                                    PbranchWiseDataCounts={this.state.branchWiseDataCounts}
                                    getNirfRanking={this.state.getNirfRanking}
                                    state={parseInt(this.props.stateData.exam_type.value)}
                                />
                                <CollegeAllocatedSeatsModal
                                    show={this.state.allocatedDataModal}
                                    onHide={() => this.setState({ allocatedDataModal: false, allocatedData: [] })}
                                    stateData={this.state.allocatedData}
                                />

                                <ClearModal
                                    show={this.state.clearallModal}
                                    onHide={() => this.setState({ clearallModal: false })}
                                    clearAll={this.clearAll}
                                    spinnerClear={this.state.spinnerClear}

                                />

                                {
                                    this.state.predictData.length > 0 ?

                                        (<button className='goto_short_btn mb-2' onClick={(e) => this.setState({ clearallModal: true })}>Clear all shortlisted colleges</button>

                                        )
                                        :
                                        ("")
                                }

                                {
                                    this.state.predictData.length > 0 ?
                                        (
                                            <Card className='goto_card p-4 mb-4'>
                                                <Row>
                                                    <Col sm={3}>
                                                        <img src={shortList} className="sl_image" alt="image" />
                                                    </Col>
                                                    <Col sm={9}>
                                                        <h4 className='goto_text text-center'>You have shortlisted colleges based on your information. Now from your shortlisted colleges priotirize your branches and get a full list of branch, colleges and get ready for the web option</h4>
                                                        {/*<button className='goto_short_btn center-sl-auto' onClick={(e) => this.showShortListedData()}>GO TO SHORLISTED PAGE</button>*/}
                                                        <Link to={{
                                                            pathname: "/student/college-rank-predictor/short-list-page",
                                                            state: {
                                                                exam_type_value: this.props.stateData.exam_type.value == 2 ? 3 : 6,
                                                            }

                                                        }} >
                                                            <button className='goto_short_btn center-sl-auto'>GO TO SHORLISTED PAGE</button>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                    {
                                                        this.props.stateData.exam_type.value == 2 ?
                                                        (
                                                            <p className='text-center mt-2 disclaimer'>*Disclaimer: Predictions made are based on historical seat allocation data from 2023.</p>
                                                        )
                                                        :
                                                        (
                                                            <p className='text-center mt-2 disclaimer'>*Disclaimer: Predictions made are based on historical seat allocation data from 2023.</p>
                                                        )
                                                    }
                                                    </Col>
                                                </Row>
                                            </Card>
                                        )
                                        :
                                        ("")
                                }
                            </>
                        )
                        :
                        (
                            <>
                                <ShortListPage
                                    getBranchDraftData={this.state.getBranchDraftData}
                                    stateData={this.state}
                                    getMainCastes={this.props.getMainCastes}
                                    stateId={this.props.stateData.exam_type.value}
                                />
                            </>
                        )
                }



            </Container>
        )
    }
}

export default withRouter(withApollo(compose(
    graphql(FETCH_SUB_CASTES,
        {
            options: props => ({
                variables: {
                    eamcet_caste_id: parseInt(props.stateData.caste.value),
                    state_id: parseInt(props.stateData.exam_type.value)
                },
                fetchPolicy: "network-only"
            }), name: "getSubCastes"
        }),
    graphql(FETCH_COLLEGES,
        {
            options: props => ({
                variables: {
                    state: parseInt(props.stateData.exam_type.value),
                    branch_id: '',
                    // eamcet_college_type_id:props?.statedata?.collegetypeselvalue.toString()== undefined ? "" : props?.statedata?.collegetypeselvalue.toString(),
                    eamcet_college_type_id: '',
                    district: '',
                },
                fetchPolicy: "network-only"
            }), name: "getCollegeName"
        }),
    graphql(FETCH_BRANCHES,
        {
            options: props => ({
                variables: {
                    id: 0,
                    state_id: parseInt(props.stateData.exam_type.value)
                },
                fetchPolicy: "network-only"
            }), name: "getBranches"
        }),
    graphql(GET_SHORTLISTED_COLLEGES,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                },
                fetchPolicy: "network-only"
            }), name: "getBranchDraftData"
        }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
    graphql(COLLEGE_SHORTLIST, {
        name: "submitCollege"
    }),
    graphql(DELETE_SHORTLISTED_COLLEGE, {
        name: "removeCollegeData"
    }),
    graphql(GET_DISTRICTS,
        {
            options: props => ({
                variables: {
                    state_id: parseInt(props.stateData.exam_type.value) == 24 ? 24 : 1
                },
                fetchPolicy: "network-only"
            }), name: "getDistrictNames"
        }),
    graphql(removeALL_Shortlisted_colleges, {
        name: "removeAllData"
    }),


)(CollegeRankPredictorHome)));