import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import { Row, Tabs, Tab, Card, Image, Button, Col, Table, Media, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './_chapterwisequestions.scss'
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import * as compose from 'lodash.flowright';
import parse, { domToReact } from 'html-react-parser';
import ExplanationModal from './ExplanationModal'

class NeetJeePreviousQuestionsSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            solutionModal: false,
            solution: [],
            classData: { value: 1, label: 'Mains' },
        }
    }

    showSolution = (key) => {
        console.log("keykeykey", key)
        this.setState({
            solutionModal: true,
            solution: key
        })
    }

    selectHandleInputChange = (evalue) => {
        const value = evalue
        let labelval = ""

        if (value == "1") {
            labelval = "Mains"
        } else {
            labelval = "Advanced"
        }
        this.setState({
            classData: {
                value: value,
                label: labelval
            },
        })
    }

    render() {
        let questionsDisplayData = []

        if (this.props.questionsData.length > 0 && this.state.classData.value == "1" && Cookies.get("examid") == 2) {
            questionsDisplayData = this.props.questionsData.filter((item) => item.pexamtype == 1)
        } else if (this.props.questionsData.length > 0 && this.state.classData.value == "2" && Cookies.get("examid") == 2) {
            questionsDisplayData = this.props.questionsData.filter((item) => item.pexamtype == 2)
        } else {
            questionsDisplayData = this.props.questionsData.filter((item) => item.pexamtype == 0)
        }

        return (
            <Container>
                {
                    Cookies.get("examid") == 2 ?
                        (
                            <Row>
                                <Col sm={6}>
                                    <ul className='list-inline exam_type_li mt-4'>
                                        <li className={this.state.classData.value == "1" ? "active" : ""} onClick={() => this.selectHandleInputChange("1")}>Mains</li>
                                        <li className={this.state.classData.value == "2" ? "active ml-4" : "ml-4"} onClick={() => this.selectHandleInputChange("2")}>Advanced</li>
                                    </ul>
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                }
                <Row>
                    <Col sm={12}>
                        <Tab.Container>
                            {
                                questionsDisplayData.length > 0 ?
                                    (
                                        questionsDisplayData.map((item, index) => {
                                            if (item.qtype == 3 || item.qtype == 9) {
                                                let question1 = item.question_name.replace(/src="/g, 'src=\\"');
                                                let question2 = question1.replace(/" \/>/g, '\\" />');
                                                console.log("question2question2", question2)
                                                let question = [];

                                                try {
                                                    question = JSON.parse(question2);
                                                }
                                                catch (err) {
                                                    console.log("MATRIX ERROR:", err.message);
                                                }

                                                return (
                                                    <>
                                                        <Card as={Card.Body} className="bg-light my-2 mob_view">
                                                            <Row>
                                                                <Col xl={12} lg={12} md={12} sm={12}>

                                                                    {parse(item.mat_question)}

                                                                    <Row>
                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                            <span className='font-weight-bold'>{index + 1 + '.'}</span>

                                                                            <ol className="matrixlistoptions" type={
                                                                                item.list1type == "alphabets" ? ("A")
                                                                                    : (item.list1type == "numbers") ? ("1")
                                                                                        : ("roman")}
                                                                            >
                                                                                {question.map((item) => (
                                                                                    <li className='font-weight-bold'>{parse(item.qlist1)}</li>
                                                                                ))}
                                                                            </ol>
                                                                        </Col>
                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                            <ol className="matrixlistoptions" type={
                                                                                item.list2type == "alphabets" ? ("A")
                                                                                    : (item.list2type == "numbers") ? ("1")
                                                                                        : ("roman")}>
                                                                                {question.map((item) => (
                                                                                    <li className='font-weight-bold'>{parse(item.qlist2)}</li>
                                                                                ))}
                                                                            </ol>
                                                                        </Col>
                                                                        <p className='year_pos'>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                            <div className="d-flex align-items-center">
                                                                                <span className='f-16'>A.</span>
                                                                                <div className="ml-2">{parse(item.option1)}</div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                            <div className="d-flex align-items-center">
                                                                                <span className='f-16'>B.</span>
                                                                                <div className="ml-2">{parse(item.option2)}</div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                            <div className="d-flex align-items-center">
                                                                                <span className='f-16'>C.</span>
                                                                                <div className="ml-2">{parse(item.option3)}</div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                            <div className="d-flex align-items-center">
                                                                                <span className='f-16'>D.</span>
                                                                                <div className="ml-2">{parse(item.option4)}</div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl={12} lg={12} md={12} sm={12} className="my-2">
                                                                            <button className='solution_btn_new' onClick={() => this.showSolution(item)}>View Solution</button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </>
                                                )
                                            }
                                            else if (item.qtype == "8") {
                                                return (
                                                    <>
                                                        <Card as={Card.Body} className="bg-light my-2 mob_view">
                                                            <span className='font-weight-bold'>{index + 1 + '.'}</span>
                                                            {parse(item.question_name)}
                                                            <p className='year_pos'>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>

                                                            <Row>
                                                                <Col xl={12} lg={12} md={12} sm={12} className="my-2">
                                                                    <button className='solution_btn_new' onClick={() => this.showSolution(item)}>View Solution</button>

                                                                </Col>
                                                            </Row>

                                                        </Card>
                                                    </>
                                                )

                                            }
                                            else if (item.qtype == "5") {
                                                //console.log("questionsData.questionnn", question);
                                                return (
                                                    <>
                                                        <Card as={Card.Body} className="bg-light my-2 mob_view">
                                                            <span className='font-weight-bold'>{index + 1 + '.'}</span>
                                                            {parse(item.compquestion)}

                                                            {parse(item.question_name)}
                                                            <p className='year_pos'>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>

                                                            <Row>
                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className='f-16'>A.</span>
                                                                        <div className="ml-2">{parse(item.option1)}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className='f-16'>B.</span>
                                                                        <div className="ml-2">{parse(item.option2)}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className='f-16'>C.</span>
                                                                        <div className="ml-2">{parse(item.option3)}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className='f-16'>D.</span>
                                                                        <div className="ml-2">{parse(item.option4)}</div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl={12} lg={12} md={12} sm={12} className="my-2 ">
                                                                    <button className='solution_btn_new' onClick={() => this.showSolution(item)}>View Solution</button>

                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </>
                                                )
                                            }

                                            else {
                                                return (
                                                    <div className='d-flex mob_view'>
                                                        <Card as={Card.Body} className="bg-light my-2">
                                                            <Row>
                                                                <Col xl={12} lg={12} md={12} sm={12}>
                                                                    <Row>
                                                                        <Col xl={12} lg={12} md={12} sm={12} className="my-2">
                                                                            <div className="d-flex align-items-center" >
                                                                                <span className='font-weight-bold'>{index + 1 + '.'}</span>
                                                                                <div className="ml-2 font-weight-bold ">{parse(item.question_name)}</div>
                                                                            </div>
                                                                            <p className='year_pos'>( YEAR:  <span className='font-weight-bold'>{item.year} )</span></p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                            <div className="d-flex align-items-center">
                                                                                <span className='f-16'>A.</span>
                                                                                <div className="ml-2">{parse(item.option1)}</div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                            <div className="d-flex align-items-center">
                                                                                <span className='f-16'>B.</span>
                                                                                <div className="ml-2">{parse(item.option2)}</div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                            <div className="d-flex align-items-center">
                                                                                <span className='f-16'>C.</span>
                                                                                <div className="ml-2">{parse(item.option3)}</div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xl={6} lg={6} md={6} sm={12} className="my-2">
                                                                            <div className="d-flex align-items-center">
                                                                                <span className='f-16'>D.</span>
                                                                                <div className="ml-2">{parse(item.option4)}</div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl={12} lg={12} md={12} sm={12} className="">
                                                                            <button className='solution_btn_new' type="button" onClick={() => this.showSolution(item)}>View Solution</button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </div>
                                                )
                                            }

                                        })
                                    )
                                    :
                                    (
                                        <Row>
                                            <Col sm={12}>
                                                <p className='text-center font-weight-bold ver_align'>Questions not Available for selected Chapter/Topic</p>
                                            </Col>
                                        </Row>
                                    )
                            }
                        </Tab.Container>
                    </Col>
                </Row>
                <ExplanationModal
                    show={this.state.solutionModal}
                    onHide={() => this.setState({ solutionModal: false })}
                    solution={this.state.solution}
                />
            </Container>
        )
    }
}
export default withRouter(withApollo(compose(

)(NeetJeePreviousQuestionsSection)));
