import { Modal, Card } from 'react-bootstrap'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import parse, { domToReact } from 'html-react-parser';
import './_bookstoremodule.scss'
import Carousel from 'react-bootstrap/Carousel';
import { Row, Col, Container, Image, Media, Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

class ModuleTourModal extends Component {
    render() {
        const data = this.props.data
        return (
            <Modal {...this.props}
                size="lg" aria-labelledby="example-modal-sizes-title-lg" className="bookstore_popup">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Carousel interval={null} renderBottomCenterControls={false}>
                        {
                            data.length > 0 ?
                                (
                                    data.map((item) => {
                                        let link = item.navigation_url.replace('https://rizee.in/student/action/', '');
                                        return (
                                            <Carousel.Item>
                                                <Row>
                                                    <Col sm={12}>
                                                        <Image src={item.web_path} className="popup_image" alt="module" />
                                                    </Col>
                                                </Row>
                                                <Row className='mt-2'>
                                                    <Col sm={12}>

                                                        <Link to={`/student/action/${link}`} style={{ textDecoration: 'none' }}>
                                                            <button className='btn exp_btn btn-primary'>Explore Now</button>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </Carousel.Item>
                                        )
                                    })
                                )
                                :
                                (
                                    <Row>
                                        <Col>
                                            <h1 className='text-center'>No Data Available</h1>
                                        </Col>
                                    </Row>
                                )
                        }
                    </Carousel>
                </Modal.Body>
            </Modal>
        )
    }
}

export default ModuleTourModal
