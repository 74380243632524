/* eslint-disable import/first */
import React, { Component } from 'react'
import { Container, Row, Col, Card, Image, Form, Button, Media, Carousel } from 'react-bootstrap'
import { Link } from "react-router-dom";
import unmountComponentAtNode from 'react-dom'
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import * as Cookies from "es-cookie";
import * as compose from 'lodash.flowright';
import NavbarTop from '../components/login_register/NavbarTop';
import Footer from '../components/login_register/Footer';
import "../components/login_register/_studentlogin.scss";
// import Somescripturl from "https://rizee.in/";
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import { tokenChecking } from '../pages/GlobalFunctions';

import selfstudyplan from '../../images/registrations/self_study_reg.png';
import mentorship from '../../images/registrations/mentor_ship_plan.png';
import rapidRevision from '../../images/registrations/rapid_revision_reg.png';
import collegebranchpredictor from '../../images/collegebranchLoginpage.png'
import foundationLogin from '../../images/foundation_loginpage.png'


import previousPaper from '../../images/registrations/Newspaper.png';
import revision from '../../images/registrations/Books.png';
import linkage from '../../images/registrations/BookBookmark.png';
import mockPaper from '../../images/registrations/ClipboardText.png';


import plan from '../../images/registrations/plan.png';
import execute from '../../images/registrations/execute.png';
import measure from '../../images/registrations/measure.png';
import warmup from '../../images/registrations/warmup.png';

import logoBlue from '../../images/registrations/logo-blue.png';
import logoWhite from '../../images/registrations/logo-white.png';
import dineshPublications from '../../images/registrations/dinesh_publications.gif';

const queryParams = new URLSearchParams(window.location.search);
let url_redirect_type = queryParams.get('src');

const LOGIN_USER = gql`
  mutation($username: String!, $password: String) {
    studentLogin(username: $username, password: $password) {
        token
        refreshToken
        user{
            name
            email
            mobile
            valid
            class_id
            exam_id
            exam_name
            current_plan_id
            mobile_verified
            target_year
            videos
            branch_name
            profile_pic
            userlevel
            forum
            institution_id
            institute_name
            institute_logo
            
         }
    }
  }
`;

class DineshPubStudentLogin extends Component {
    constructor(props) {
        super(props);
        var today = new Date();
        var dd = today.getDate();
        var mmm = today.getMonth();
        var month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var yyyy = today.getFullYear();



        var date = new Date();
        var hh = date.getHours();
        var mm = date.getMinutes();
        hh = hh < 10 ? '0' + hh : hh;
        mm = mm < 10 ? '0' + mm : mm;

        let curr_time = hh + ':' + mm;
        today = dd + ' ' + month[mmm] + ' ' + yyyy + ' ' + curr_time;
        this.state = {
            date: today,
            currentStep: 1,
            username: "",
            password: "",
            submitError: "",
            formErrors: {
                username: "",
                password: ""
            },
            usernameValid: false,
            passwordValid: false,
            formValid: false,
            loader: 0,
        };
    }

    componentDidMount = () => {
        /*const scriptTag = document.createElement("script");
        scriptTag.src = "//js.hs-scripts.com/8456667.js";
        scriptTag.async = true;
        document.getElementById("scriptid").appendChild(scriptTag)*/
        const title = GoogleAnalyticsArray[0].Login;
        ReactGA.pageview('/student/login', ["rizee"], title);
        let url = new URL(window.location.href);
        const username = url.searchParams.get('username');
        const password = url.searchParams.get('password');
        if (username && password && username.length > 0 && password.length > 0) {
            this.login(username, password).catch(error => {
                console.log("catch if error");
                console.log(error);
                this.setState({
                    submitError: error.graphQLErrors.map(x => x.message)
                });
                console.error(
                    "ERR =>",
                    error.graphQLErrors.map(x => x.message)
                );
            });
        }

        if (Cookies.get("student_userlevel") == 1 && Cookies.get("institution_id") != 0 && Cookies.get("user_valid") == 0) {
            this.props.history.push("/student/login");
        } else if (Cookies.get("studenttoken") && tokenChecking(Cookies.get("studenttoken"))) {
            this.props.history.push("/student/loading");
            window.location.reload();
        }

    }
    // unmountComponentAtNode = () => {
    //     document.getElementById("scriptid").removeChild("script")
    // }
    datefunction() {

        console.log("date");
    }
    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            loader: 1
        });
        console.log("Form submitted");
        console.log(this.state);

        if (this.state.formValid) {
            this.login(this.state.username, this.state.password).catch(error => {
                console.log("catch if error");
                console.log(error);
                this.setState({
                    submitError: error.graphQLErrors.map(x => x.message), loader: 0
                });
                console.error("ERR =>", error.graphQLErrors.map(x => x.message));
            });
        } else {
            this.setState({ submitError: "Please fill all the values to proceed", loader: 0 });
        }
    };
    login = async (username, password) => {
        await this.props.login({
            variables: {
                username,
                password
            },
            update: (store, { data }) => {
                console.log("data.studentLogin.user.branch_name", data.studentLogin.token, data.studentLogin.user);
                //localStorage.clear();
                Cookies.set("studenttoken", data.studentLogin.token);
                Cookies.set("studentrefreshtoken", data.studentLogin.refreshToken);
                Cookies.set("studentusername", data.studentLogin.user.name);
                Cookies.set("studentemail", data.studentLogin.user.email);
                Cookies.set("institute_name", data.studentLogin.user.institute_name);
                Cookies.set("institute_logo", data.studentLogin.user.institute_logo);

                Cookies.set("mobile", data.studentLogin.user.mobile);
                Cookies.set("classid", data.studentLogin.user.class_id);
                Cookies.set("examid", data.studentLogin.user.exam_id);
                Cookies.set("exam_name", data.studentLogin.user.exam_name);
                Cookies.set("mobileverified", data.studentLogin.user.mobile_verified);
                Cookies.set("targetyear", data.studentLogin.user.target_year);
                Cookies.set("videos", data.studentLogin.user.videos);
                Cookies.set("branch_name", data.studentLogin.user.branch_name);
                Cookies.set("role", "student");
                Cookies.set("profile_pic", data.studentLogin.user.profile_pic);
                Cookies.set("student_userlevel", data.studentLogin.user.userlevel);
                Cookies.set("stulogintype", "normal");
                Cookies.set("forumlink", data.studentLogin.user.forum);
                Cookies.set("institution_id", data.studentLogin.user.institution_id);
                Cookies.set("user_valid", data.studentLogin.user.valid);
                localStorage.removeItem('profile_pic');
                localStorage.removeItem("packageplan");
                localStorage.setItem("packageplan", null);
                localStorage.setItem("profile_pic", data.studentLogin.user.profile_pic);
                localStorage.removeItem("homemodal");
                localStorage.setItem("homemodal", true);
                localStorage.setItem("loginrefermodal", true);
                localStorage.setItem("loginfoundationModal", true)
                Cookies.set("toggle", "wrapper sidebar-enable");
                Cookies.set("url_redirect_type", url_redirect_type);


                if (data.studentLogin) {
                    if (data.studentLogin.user.valid == "0" && data.studentLogin.user.institution_id == "0") {
                        if (data.studentLogin.user.mobile_verified == "0") {
                            this.props.history.push({
                                pathname: "/student/register",
                                state: {
                                    type: "mobileverify",
                                    fullname: data.studentLogin.user.name,
                                    mobile: data.studentLogin.user.mobile,
                                    email: data.studentLogin.user.email

                                }
                            });
                        }
                        else if (data.studentLogin.user.class_id == "0" ||
                            data.studentLogin.user.exam_id == "0") {
                            this.props.history.push({
                                pathname: "/student/register",
                                state: {
                                    type: "syllabusverify",
                                    fullname: data.studentLogin.user.name,
                                    mobile: data.studentLogin.user.mobile,
                                    email: data.studentLogin.user.email

                                }
                            });
                        }
                        else if (data.studentLogin.user.current_plan_id == "0") {
                            this.props.history.push({
                                pathname: "/student/register",
                                state: {
                                    type: "planverify",
                                    fullname: data.studentLogin.user.name,
                                    mobile: data.studentLogin.user.mobile,
                                    email: data.studentLogin.user.email,
                                    class_id: data.studentLogin.user.class_id,
                                    exam_id: data.studentLogin.user.exam_id,
                                    target_year: data.studentLogin.user.target_year
                                }
                            });
                        }

                        this.setState({ loader: 0 });

                    } else if (data.studentLogin.user.institution_id != "0" && data.studentLogin.user.userlevel == 1 && data.studentLogin.user.valid == 0) {
                        this.props.history.push({
                            pathname: "/student/register",
                            state: {
                                type: "studentBlock",
                            }
                        });
                        this.setState({ loader: 0 });
                    }/*else if (data.studentLogin.user.institution_id == 30){
                        console.log("institution id ", data.studentLogin.user.institution_id)
                        this.props.history.push("exams/test-series-institute");
                        //window.location.reload();
                    }*/
                    else {
                        //this.props.history.push("/student/home");

                        this.props.history.push("/student/loading");
                        window.location.reload();
                    }
                }
            }
        });
    };
    handleInputChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => {
            this.validateField(name, value);
        });
    };
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let usernameValid = this.state.usernameValid;
        let passwordValid = this.state.passwordValid;

        switch (fieldName) {
            // case "username":
            //     var pattern = new RegExp(
            //         /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            //     );

            //     if (value.length < 4) {
            //         usernameValid = false;
            //         fieldValidationErrors.username =
            //             "Username cannot be less than 5 chars";
            //     } else if (!pattern.test(value)) {
            //         usernameValid = false;
            //         fieldValidationErrors.username = "Invalid Username";
            //     } else {
            //         usernameValid = true;
            //         fieldValidationErrors.username = "";
            //     }
            //     break;

            case "username":
                var pattern = new RegExp("^[7-9][0-9]{9}$");

                if (value.length == "") {
                    usernameValid = false;
                    fieldValidationErrors.username = "username Cannot Be Empty";
                }
                // else if (!pattern.test(value)) {
                //     usernameValid = false;
                //     fieldValidationErrors.username = "Invalid username";
                // } 
                else {
                    usernameValid = true;
                    fieldValidationErrors.username = "";
                }

                break;
            case "password":
                if (value.length == "") {
                    passwordValid = false;
                    fieldValidationErrors.password = "Password cannot be Empty";
                } else {
                    passwordValid = true;
                    fieldValidationErrors.password = "";
                }

                break;

            default:
                break;
        }
        this.setState(
            {
                formErrors: fieldValidationErrors,
                usernameValid: usernameValid,
                passwordValid: passwordValid
            },
            this.validateForm
        );
    }

    validateForm() {
        this.setState({
            formValid: this.state.passwordValid && this.state.usernameValid
        });
        if (this.state.formValid) {
            this.setState({ submitError: "" }, () => { });
        }
    }
    handleKeypress = e => {
        if (e.keyCode === 13) {
            this.handleFormSubmit();
        }
    };
    render() {

        // if (Cookies.get("studenttoken") != undefined)
        //     this.props.history.push("/student/home");

        // if (loading2 || loading3) {
        //     return (<div className="d-flex justify-content-center align-items-center">
        //         <div class="spinner-border text-primary"></div>
        //     </div>);

        // }
        return (

            <div className="student_new_login" id="scriptid">
                <Container fluid className="mobile_view_bg_color">
                    <Row>
                        <Col sm={6} className="order bg_blue_registration">
                            <div className="">
                                <div className="res_hide">
                                    <h1 className="color_white font-weight-500">Welcome to Dinesh Publications</h1>
                                    {/* <p className="color_white">
                                        The Best competitive exam preparation <br />platform in India
                                    </p> */}
                                </div>
                                <hr className="border_bottom_white" />
                                <Carousel controls={false}>
                                    <Carousel.Item interval={1000} >
                                        <h4 className="color_white">From Foundation To Admission...</h4>
                                        <Media className="mt-4-respon" >
                                            <Image className="mr-3 media_img_reg_align" src={foundationLogin} alt="foundation" />
                                            <Media.Body className="mt-1">
                                                <h5 class="font-weight-bold color_white f-r-h6">Foundation Course</h5>
                                                <p class="color_white font-weight-normal f-14 f-r-12">Bridge gap between your current education (11th or 12th) and previous education (9th & 10th) with our Foundation Course.
                                                </p>
                                            </Media.Body>
                                        </Media>
                                        <Media className="mt-4-respon">
                                            <Image className="mr-3 media_img_reg_align" src={selfstudyplan} alt="Self Study" />
                                            <Media.Body className="mt-2">
                                                <h5 class="font-weight-bold color_white f-r-h6">Self Study Plan</h5>
                                                <p class="color_white font-weight-normal f-14 f-r-12">A methodical study plan designed for students with long term study
                                                    goals.
                                                </p>
                                            </Media.Body>
                                        </Media>
                                        <Media className="mt-4-respon" >
                                            <Image className="mr-3 media_img_reg_align" src={collegebranchpredictor} alt="predictor" />
                                            <Media.Body className="mt-1">
                                                <h5 class="font-weight-bold color_white f-r-h6">College and Branch Predictor</h5>
                                                <p class="color_white font-weight-normal f-14 f-r-12">Know the colleges/branches that you can get into with our college & branch predictor and be web option ready.
                                                </p>
                                            </Media.Body>
                                        </Media>

                                        {/* <Media className="mt-4-respon" >
                                            <Image className="mr-3 media_img_reg_align" src={mentorship} alt="Self Study"/>
                                            <Media.Body className="mt-2">
                                                <h5 class="font-weight-bold color_white f-r-h6">Mentorship Plan</h5>
                                                <p class="color_white font-weight-normal f-14 f-r-12">Learn the Best Strategies and Get Your Questions Answered by Experts in Mentorship Rooms.
                                                </p>
                                            </Media.Body>
                                        </Media> */}

                                        {/* <Media className="mt-4-respon" >
                                            <Image className="mr-3 media_img_reg_align" src={rapidRevision} alt="Self Study"/>
                                            <Media.Body className="mt-2">
                                                <h5 class="font-weight-bold color_white f-r-h6">Courses</h5>
                                                <p class="color_white font-weight-normal f-14 f-r-12">Learn, Revise and Crack NEET with our Courses..
                                                </p>
                                            </Media.Body>
                                        </Media> */}
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <h4 class="color_white mt-4">We offer...</h4>
                                        <ul class="list-inline offers_li_block mt-4reg">
                                            <li><Image src={previousPaper} alt="Previous Paper" /> Previous Paper Analysis</li>
                                            <li><Image src={revision} alt="revision" /> Personalized Revision Material</li>
                                            <li><Image src={linkage} alt="linkage" /> Linkage Chapter Analysis</li>
                                            <li><Image src={mockPaper} alt="mockPaper" /> Mock Test Paper</li>
                                        </ul>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <h4 class="color_white mt-4">We Help With...</h4>
                                        <Row>
                                            <Col className="help_block_cls mt-4reg">
                                                <h6><Image src={plan} alt="Plan" /> Plan</h6>
                                                <h6 className="mt-r-4"><Image src={execute} alt="Execute" /> Execute</h6>
                                            </Col>
                                            <Col className="help_block_cls mt-4reg">
                                                <h6><Image src={measure} alt="Measure" /> Measure</h6>
                                                <h6 className="mt-r-4"><Image src={warmup} alt="Warm Up" /> Warm Up</h6>
                                            </Col>
                                        </Row>

                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </Col>
                        <Col sm={6} className="order">
                            <Row className="justify-content-md-center">
                                <Col sm={9}>
                                    <div className="mt-4-res">
                                        <a className="brand-college-logo" href="#">
                                            <Image src={dineshPublications} alt="LOGO" className="res_hide" style={{marginLeft:"55px"}} />
                                            <Image src={dineshPublications} alt="LOGO" className="res_show" />
                                        </a>
                                    </div>
                                    <div className="res_show mt-5">
                                        <h2 className="color_white font-weight-500">Welcome to Dinesh Publications</h2>
                                        {/* <p className="color_white f-14">
                                            The Best competitive exam preparation <br />platform in India
                                        </p> */}
                                    </div>
                                    <Row>
                                        <Col sm={7} className="col7_custom">
                                            <h5 className="mt-5"><span className="color_yellow">Login</span> <span className="color_yash_white">to your account</span></h5>
                                            {this.state.currentStep == 5 ? (
                                                <Form.Text className="form-text text-danger">
                                                    logged In successfully
                                                </Form.Text>
                                            ) : (

                                                <h6 className="error_reg_Cls"> {this.state.submitError}</h6>
                                            )}

                                            <form onKeyPress={this.handleKeypress}>

                                                <div class="inputWithIcon">
                                                    <input type="text" placeholder="Email or Mobile Number" name="username" class="input-style-cls" onChange={this.handleInputChange} />
                                                    <i class="far fa-user-circle"></i>

                                                    <h6 className="error_reg_Cls"> {this.state.formErrors.username}</h6>
                                                </div>

                                                <div class="inputWithIcon  mt-2">
                                                    <input type="password" placeholder="Password" name="password" class="input-style-cls" onChange={this.handleInputChange} />
                                                    <i class="fas fa-key"></i>

                                                    <h6 className="error_reg_Cls"> {this.state.formErrors.password}</h6>
                                                </div>
                                                {/* <h6 className="forget_style float-right f-14 color_yellow"><Link to="/student/ForgotPassword">Forgot Password?</Link></h6> */}

                                                {this.state.loader == 1 ? (
                                                    <Button className="btn btn-custom-reg-blue mt-5" type="submit" block disabled style={{ width: "" }}>
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                        logging..
                                                    </Button>
                                                ) : (
                                                    <Button onClick={this.handleFormSubmit} className="mt-5 btn btn-custom-reg-blue" type="submit" block>
                                                        Login
                                                    </Button>
                                                )}
                                            </form>
                                            {/* <h6 className="text-center mt-5 f-14"><span className="color_yash_white">Not Registerd yet?</span> <span className="forget_style"><a href="https://rizee.in/Registration/?src=website">Create Account</a></span></h6> */}
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default withRouter(compose(
    graphql(LOGIN_USER, {
        name: "login"
    })
)(DineshPubStudentLogin)
);