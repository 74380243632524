import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_bluePrintexamanalysis.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import * as Cookies from "es-cookie";
//import QuestionData from './jsondata.js'
// import './firsyear';


/// //// ApPhyData //////////

let ApPhyData = [

    {
        YEAR: "FIRST YEAR",
        CHAPTERNAME: "PHYSICAL WORLD",
        NOOFQUESTIONS: '1',

    },
    {
        YEAR: "FIRST YEAR",
        CHAPTERNAME: "UNITS AND MEASUREMENTS",
        NOOFQUESTIONS: "1",
    },
    // {
    //     YEAR: "FIRST YEAR",
    //     CHAPTERNAME: "VECTORS",
    //     NOOFQUESTIONS: "1",
    // },
    {
        YEAR: "FIRST YEAR",
        CHAPTERNAME: "MOTION IN A STRAIGHT LINE",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "MOTION IN A PLANE",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "LAWS OF MOTION",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "WORK,ENERGY & POWER",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        NOOFQUESTIONS: "2",
    },
    // {
    //     YEAR: "FIRSTYEAR",
    //     CHAPTERNAME: "ROTATIONAL MOTION",
    //     NOOFQUESTIONS: "1",
    // },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "OSCILLATIONS",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "GRAVITATION",
        NOOFQUESTIONS: "2",
    },

    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "MECHANICAL PROPERTIES OF SOLIDS",
        NOOFQUESTIONS: "1",
    },

    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "MECHANICAL PROPERTIES OF FLUIDS",
        NOOFQUESTIONS: "1",
    },

    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "THERMAL PROPERTIES OF MATTER",
        NOOFQUESTIONS: "2",
    },

    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "THERMODYNAMICS",
        NOOFQUESTIONS: "2",
    },

    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "KINETIC THEORY",
        NOOFQUESTIONS: "1",
    },
    // {
    //     YEAR: "FIRSTYEAR",
    //     CHAPTERNAME: "HEAT TRANSFER",
    //     NOOFQUESTIONS: "1",
    // },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "WAVES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "RAY OPTICS & OPTICAL INSTRUMENTS",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "WAVE OPTICS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "ELECTRIC CHARGE & FIELDS",
        NOOFQUESTIONS: "1",
        
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "ELECTROSTATIC POTENTIAL & CAPACITANCE",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "CURRENT ELECTRICITY",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "MOVING CHARGES AND MAGNETISM",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"MAGNETISM & MATTER",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "ELECTROMAGNETIC INDUCTION",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "ALTERNATING CURRENT",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "ELECTROMAGNETIC WAVE",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "DUAL NATURE OF RADIATION & MATTER",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "ATOMS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "NUCLEI",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "SEMICONDUTOR ELECTRONICS :MATERIALS,DEVICES AND SIMPLE CIRCUITS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "COMMUNICATION SYSTEMS",
        NOOFQUESTIONS: "1",

    }

];



////    TsPhyData

let TsPhyData = [

    {
        YEAR: "FIRST YEAR",
        CHAPTERNAME: "PHYSICAL WORLD",
        NOOFQUESTIONS: '1',

    },
    {
        YEAR: "FIRST YEAR",
        CHAPTERNAME: "UNITS AND MEASUREMENTS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR",
        CHAPTERNAME: "VECTORS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR",
        CHAPTERNAME: "MOTION IN A STRAIGHT LINE",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "MOTION IN A PLANE",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "LAWS OF MOTION",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "WORK, ENERGY & POWER",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        NOOFQUESTIONS: "2",
    },
    // {
    //     YEAR: "FIRSTYEAR",
    //     CHAPTERNAME: "ROTATIONAL MOTION",
    //     NOOFQUESTIONS: "1",
    // },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "OSCILLATIONS",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "GRAVITATION",
        NOOFQUESTIONS: "2",
    },

    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "MECHANICAL PROPERTIES OF SOLIDS",
        NOOFQUESTIONS: "1",
    },

    // {
    //     YEAR: "FIRSTYEAR",
    //     CHAPTERNAME: "MECHANICAL PROPERTIES OF FLUIDS",
    //     NOOFQUESTIONS: "1",
    // },

    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "THERMAL PROPERTIES OF MATTER",
        NOOFQUESTIONS: "1",
    },

    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "THERMODYNAMICS",
        NOOFQUESTIONS: "2",
    },

    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "KINETIC ENERGY",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "HEAT TRANSFER",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "WAVES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "RAY OPTICS & OPTICAL INSTRUMENTS",
        NOOFQUESTIONS: "2",
        
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "WAVE OPTICS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "ELECTRIC CHARGE & FIELDS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "ELECTROSTATIC POTENTIAL & CAPACITANCE",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "CURRENT ELECTRICITY",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "MOVING CHARGES AND MAGNETISM",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "ELECTROMAGNETIC INDUCTION",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "ALTERNATING CURRENT",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "ELECTROMAGNETIC WAVE",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "DUAL NATURE OF RADIATION & MATTER",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "ATOMS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "NUCLEI",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "SEMICONDUCTOR ELECTRONICS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECONDYEAR",
        CHAPTERNAME: "COMMUNICATION SYSTEMS",
        NOOFQUESTIONS: "1",

    }

];
class PhysicsDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    colFunction() {

        let columns = [
            {
                dataField: 'YEAR',
                text: 'Year',
                sort: true
            },
            {
                dataField: 'CHAPTERNAME',
                text: 'CHAPTERNAME',
                sort: true
            },
            {
                dataField: 'NOOFQUESTIONS',
                text: 'NO OF QUESTIONS',
                sort: false
            },

        ];
        return columns;
    }

    render() {
        return (
            <Row>
                <Col>

                    {
                        Cookies.get("examid") == 3 || Cookies.get("examid") ==7 ?(
                            <Card>
                                <BootstrapTable
                                    keyField="id"
                                    columns={this.colFunction()}
                                    data={ApPhyData}
                                    defaultSorted={true}
                                    wrapperClasses="table-responsive"
                                    bootstrap4
                                />
                            </Card>

                        ) : null
                    }

                    {
                        Cookies.get("examid") == 6  || Cookies.get("examid") ==8 ?(
                            <Card>
                                <BootstrapTable
                                    keyField="id"
                                    columns={this.colFunction()}
                                    data={TsPhyData}
                                    defaultSorted={true}
                                    wrapperClasses="table-responsive"
                                    bootstrap4
                                />
                            </Card>

                        ) : null
                    }
                </Col>
            </Row>

        )
    }

}
export default PhysicsDataSection