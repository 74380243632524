import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import { Container, Row, Col, Image } from 'react-bootstrap'
import * as Cookies from "es-cookie";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import * as compose from 'lodash.flowright';
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import ReactGA from 'react-ga';
import StudentTargettingSection from '../components/studentTargeting/StudentTargettingSection';

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

const FETCH_PLANS = gql`
query($mobile:String){
        getSubscriptionPlans(mobile:$mobile) {
            id
            name 
            exam_id
            student_plan_id
            order_by
            image_path
            description
            studentPlans{
                id
                plan_name
                plan_title
                amount
                exam_id
                original_amount
                valid_days
                image
                additional_text
                plan_features
                color
                coupon_display
                upgrade_access
                upgrade_amount
                upgrade_description
                expiry_date
          }
        }
}
`;

const SINGLEDAYPRICE = gql`
query($id:Int){
    getPopupPlan(id:$id){
        id
        plan_name
        popup_plan_desc
        amount
        additional_text
      }
}
`

class StudentTargettingPayment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submitError:""
        }
    }

    componentDidMount(){
        this.addStudentAnalytics()
    }

    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 243,
            source: 2
        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    render() {
        const getSubscriptionPlans = this.props.getSubscriptionPlans;
        const loading4 = getSubscriptionPlans.loading;
        const error4 = getSubscriptionPlans.error

        const getPopupPlan = this.props.getPopupPlan;
        const loading5 = getPopupPlan.loading;
        const error5 = getPopupPlan.error;

        if (error4 !== undefined) {
            alert("Server Error. " + error4.message);
            return null;
        }

        if (error5 !== undefined) {
            alert("Server Error. " + error5.message);
            return null;
        }

        return (
            <Container>
                <div className={Cookies.get("toggle")}>
                    <div className="header-area">
                        <Navbars headerBottom={this.state.headerBottomImg} onClick={() => this.menuToggler()} />
                    </div>
                    <div className="main-wrapper bg-white scrolling">
                        <AsideNavbar onClick={() => this.menuToggler()} />
                        <div className="student-overlay" onClick={() => this.menuToggler()} />
                        <div className="content-wrapper pt-0">
                            {
                                loading4 == true || loading5 == true ?
                                    (
                                        <Container>
                                            <Row>
                                                <Col sm={12}>
                                                    <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                                </Col>
                                            </Row>
                                        </Container>
                                    ) : (
                                        <StudentTargettingSection
                                            getSubscriptionPlans={getSubscriptionPlans.getSubscriptionPlans}
                                            getPopupPlan={getPopupPlan.getPopupPlan}
                                        />
                                    )
                            }
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}

export default withRouter(compose(
    graphql(FETCH_PLANS,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: "network-only"
            }), name: "getSubscriptionPlans"
        }),
    graphql(SINGLEDAYPRICE,
        {
            options: props => ({
                variables: {
                    id: 80
                }
                ,
                fetchPolicy: "network-only"
            }), name: "getPopupPlan"
        }),
        graphql(UPDATE_STUDENT_ANALYTICS, {
            name: "submitpt"
        }),
)(StudentTargettingPayment));