import { thisExpression } from '@babel/types';
import React, { Component } from 'react';
import { Row, Tabs, Tab, Card, Image, Button, Col, Table } from 'react-bootstrap';
import './detailednotes.scss';
import DetailedPdfTable from './detailedPdfTable';
import UserRestrictionAlertInstitute from '../home/UserRestrictionAlertInstitute';
import * as Cookies from "es-cookie";
import LockSimple from './LockSimple.png';

class ZoologyDetailedNotes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPdf: false,
            pdfUrl: "",
            userRestionModalShow: false
        }
        this.data = [
            {
                name: 'DIVERSITY OF LIVING WORLD',
                url: "https://rizee.in/econtent/detailed_notes/ZOL1_5368_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'STRUCTURAL ORGANIZATION IN ANIMALS',
                url: "https://rizee.in/econtent/detailed_notes/ZOL3_6943_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'ANIMAL DIVERSITY I INVERTEBRATE PHYLUM',
                url: "https://rizee.in/econtent/detailed_notes/ZOL6_8109_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'ANIMAL DIVERSITY II  CHORDATE PHYLUM-1',
                url: "https://rizee.in/econtent/detailed_notes/ZOL7_1425_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'LOCOMOTION  AND REPRODUCTION',
                url: "https://rizee.in/econtent/detailed_notes/ZOL9_4519_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'BIOLOGY AND HUMAN WELFARE',
                url: "https://rizee.in/econtent/detailed_notes/ZOL10_6596_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'PERIPLANETA(COCKROACH)',
                url: "https://rizee.in/econtent/detailed_notes/ZOL12_3661_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'ECOLOGY AND ENVIRONMENT',
                url: "https://rizee.in/econtent/detailed_notes/ZOL13_8111_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'HUMAN ANATOMY AND PHYSIOLOGY-1',
                url: "https://rizee.in/econtent/detailed_notes/ZOL15_2382_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'HUMAN ANATOMY AND PHYSIOLOGY-2',
                url: "https://rizee.in/econtent/detailed_notes/ZOL16_5729_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'HUMAN ANATOMY AND PHYSIOLOGY-3',
                url: "https://rizee.in/econtent/detailed_notes/ZOL17_6289_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'HUMAN ANATOMY AND PHYSIOLOGY-4',
                url: "https://rizee.in/econtent/detailed_notes/ZOL18_4146_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'HUMAN ANATOMY AND PHYSIOLOGY-5',
                url: "https://rizee.in/econtent/detailed_notes/ZOL19_1374_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'HUMAN ANATOMY AND PHYSIOLOGY-6',
                url: "https://rizee.in/econtent/detailed_notes/ZOL20_7372_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'HUMAN ANATOMY AND PHYSIOLOGY-7',
                url: "https://rizee.in/econtent/detailed_notes/ZOL21_6205_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'HUMAN REPRODUCTIVE SYSTEM',
                url: "https://rizee.in/econtent/detailed_notes/ZOL22_1532_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'REPRODUCTIVE HEALTH',
                url: "https://rizee.in/econtent/detailed_notes/ZOL24_1552_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'GENETICS',
                url: "https://rizee.in/econtent/detailed_notes/ZOL25_5396_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'APPLIED BIOLOGY',
                url: "https://rizee.in/econtent/detailed_notes/ZOL27_8853_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            }

        ]
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }
    modalaFun = (info, index) => {
        console.log(info)
        let isStudentUserValid = ""
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        console.log(isStudentUserValid)
        console.log(isStudentUserValid.current_plan_id)
        console.log(Cookies.get("institution_id"))
        return (
            this.handlePdfView(info.url)
        )

    }

    handlePdfView = (url) => {
        console.log("zoology")
        console.log(url)
        this.setState({
            showPdf: !this.state.showPdf,
            pdfUrl: url
        });
    }
    render() {
        return (


            <Row>
                {
                    !this.state.showPdf && this.data.map((cardInfo, index) => {
                        return (
                            <Col sm={6} className="mt-4">
                                <div class="bg_white">
                                    <Row>
                                        <Col sm={8}>
                                            <h6 class="title mt-2">{cardInfo.name} </h6>

                                        </Col>
                                        <Col sm={4}>
                                            {
                                                cardInfo.value === "Coming Soon"
                                                    ? <h6 className="float-right viewNotesComingSoonCls" onClick={() => this.modalaFun(cardInfo, index)}>{cardInfo.value}</h6> :
                                                    <h6 className="float-right viewNotesCls" onClick={() => this.modalaFun(cardInfo, index)}>{cardInfo.value}</h6>
                                            }

                                        </Col>
                                    </Row>
                                </div>
                            </Col>


                        )
                    })
                }
                <DetailedPdfTable
                    showPdf={this.state.showPdf}
                    pdfUrl={this.state.pdfUrl}
                />
                <UserRestrictionAlertInstitute
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />
            </Row>
        )

        /*return (
            <div class="container">
                <Row>
                    {
                        !this.state.showPdf && this.data.map((cardInfo) => {
                            return (
                                <Col>
                                    <Card className="card_main mt-8">
                                        <Card.Body className="d-flex justify-content-between">
                                            <Card.Title className="card_title">{cardInfo.name}</Card.Title>
                                            <Button variant="info" size="md" className="zoo content-style" onClick={()=> this.handlePdfView(cardInfo.url)}>{cardInfo.value}</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
                <DetailedPdfTable
                         showPdf={this.state.showPdf}
                         pdfUrl={this.state.pdfUrl}
                />
            </div>
        )*/
    }
}
export default ZoologyDetailedNotes;














