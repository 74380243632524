import React, { Component } from 'react'
import { Row, Col, Table, Image, Button, Container, Accordion, Card, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import "./_mock-test-series.scss"
import FreeMockTestSection from "./FreeMockTestSection";
import PremiumMockTestSection from "./PremiumMockTestSection";
import * as Cookies from "es-cookie";
import ReactGA from 'react-ga';
import { extend } from 'jquery';
import moment from 'moment';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import listItem from '../../../images/images1/jeeplanlistitem.png'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isFirefox
} from "react-device-detect";

const FETCH_DEFINED_PLANS = gql`
query($mobile:String){
        getSubscriptionPlans(mobile:$mobile) {
            id
            name
            exam_id
            student_plan_id
            order_by
            image_path
            description
            studentPlans{
                id
                plan_name
                plan_title
                amount
                exam_id
                original_amount
                valid_days
                image
                additional_text
                plan_features
                popup_plan_desc
                color
                sort_order
                coupon_display
                url
                expiry_date
                upgrade_access
                upgrade_amount
                upgrade_description
          }
        }
}`


class NEETPrepBundlePopup extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    paymentOrderFun = (data) => {
        this.props.history.push({
            pathname: "/student/subscribe-plans-order-summary",
            state: {
                data:  {
                    additional_text: "", amount: 999, color: "", coupon_display: true, exam_id: "1", expiry_date: "", id: "57", image: "", original_amount: 1999, plan_features: "All Years Previous Papers#10 Grand Mock Tests# In Depth Analysis Report", plan_name: "Prep Bundle", plan_title: "Valid till NEET 2023", upgrade_access: false, upgrade_amount: 999, upgrade_description: "", valid_days: 120
                }

            }
        })
    }



    render() {
        const getSubscriptionPlans = this.props.getSubscriptionPlans;
        const loading6 = getSubscriptionPlans.loading;
        const error6 = getSubscriptionPlans.error;

        return (
            <Modal {...this.props} className='popup_jee' size="md" aria-labelledby="example-modal-sizes-title-lg">
                <div className='jee_bg_plan'>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            loading6 == true ?
                                (
                                    <Container>
                                        <Row className="my-3">
                                            <Col>
                                                <div class="spinner-border text-primary text-center loading-img-center"></div>
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    getSubscriptionPlans.loading == false && getSubscriptionPlans != undefined && getSubscriptionPlans.getSubscriptionPlans.map(plans_data => (plans_data.studentPlans.filter(item => item.id == 57).map(plans => {
                                        console.log("plansssssssssssss", plans)
                                        return (
                                            <>
                                                <Row>
                                                    <Col sm={12}>
                                                    <h6 className='heading_jee'>This Republic Day Achieve Your Dream Of Cracking <span className='jee_republic'>NEET Exam</span></h6>
                                                    </Col>
                                                </Row>
                                                <Row className='mt-2'>
                                                    <Col sm={12}>
                                                        <p className='counts_des'><img src={listItem} className="img_item mr-2" />All Years Previous Papers</p>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <p className='counts_des'><img src={listItem} className="img_item mr-2" />10 Grand Mock Tests with In Depth Analysis Report</p>
                                                    </Col>
  			 <Col sm={12}>
                                                        <p className='counts_des'><img src={listItem} className="img_item mr-2" />Performance Analysis</p>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <p className='counts_des'><img src={listItem} className="img_item mr-2" />Error Exam</p>
                                                    </Col>
                                                </Row>
                                                <Row className='mt-2'>
                                                    <Col sm={12}>
                                                        <p className='header_2_jee'>Presenting the NEET test prep bundle</p>

                                                    </Col>
                                                </Row>
                                                <Row className='mt-2'>
                                                    <p className='offer_des'>Flat 50% off exclusively for this week</p>
                                                </Row>
                                                {
                                                    isBrowser == true ?
                                                        (
                                                            <Row className='mt-2'>
                                                                <Col sm={3}>
                                                                    <div className='btn_jee_mock_2'><p className='btn_text'><span className='strike_amount'>{plans.original_amount}/-</span>{plans.amount}/-</p></div>
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <Button className='btn_jee_mock btn_text' onClick={this.paymentOrderFun}>JOIN TODAY</Button>
                                                                </Col>
                                                            </Row>
                                                        )
                                                        :
                                                        (
                                                            <div className='d-flex flex-row'>
                                                                <div className='btn_jee_mock_2'><p className='btn_text'><span className='strike_amount'>{plans.original_amount}/-</span>{plans.amount}/-</p></div>
                                                                <div className='ml-3'>
                                                                    <Button className='btn_jee_mock btn_text' onClick={this.paymentOrderFun}>JOIN TODAY</Button>
                                                                </div>
                                                            </div>
                                                        )
                                                }
                                            </>
                                        )
                                    })))
                                )
                        }
                    </Modal.Body>
                </div>
            </Modal>
        )
    }
}
export default withRouter(compose(
    graphql(FETCH_DEFINED_PLANS,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: 'network-only'
            }), name: "getSubscriptionPlans"
        })

)(NEETPrepBundlePopup));