import React, { Component } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar';
import Navbars from '../components/navbars/Navbars';
import PackageOrderSummary from '../components/register_steps/PackageOrderSummary';
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import * as Cookies from "es-cookie";
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';

const COUPN_VALIDATION = gql`
  mutation($mobile: String, $plan_id: Int, $referral_code: String) {
    validateReferralCode(
      mobile: $mobile
      plan_id: $plan_id
      referral_code: $referral_code
    ) {
      referral_id
      applied_amount
      status
      desc
      type
      value
    }
  }
`;


const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

class SubscribeOrderSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            couponob: "",
            submitError4: "",
            headerBottomImg: {
                Img: require('../../images/mocktext-img.png'),
                Title: "Mock Test Order Summary",
                width: 150,
                helpImg: require('../../images/Ask_me_for_Help.gif')
            }
        }
    }

    componentDidMount = () => {

        this.addStudentAnalytics();

        if(Cookies.get("examid") == 1 || Cookies.get("examid") == 2){
            const title = GoogleAnalyticsArray[0].Plan_Summary;
            ReactGA.pageview('/student/subscribe-plans-order-summary',["rizee"], title);
        } 
        if(Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8){
            const title = GoogleAnalyticsArray[0].Eamcet_Plan_Summary;
            ReactGA.pageview('/student/subscribe-plans-order-summary',["rizee"], title);
        } 
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        if(Cookies.get("examid") == 3 || Cookies.get("examid") == 6|| Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ){
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 195,
                source: 2
    
            }
        }else{
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 191,
                source: 2
            }
        }
        
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
   
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
         if (toggled === "wrapper") {
             this.setState({toggled:"wrapper sidebar-enable"});
             Cookies.set("toggle", "wrapper sidebar-enable");
         } else {
             this.setState({toggled:"wrapper"});
             Cookies.set("toggle", "wrapper");
         }
     };




    coupnValidation = (e, data) => {
        e.preventDefault();
        if (data != "") {

            this.coupnval(
                Cookies.get("mobile"),
                parseInt(this.props.history.location.state.data.id),
                data
            ).catch(error => {
                console.log("catch if error");
                console.log(error);
                this.setState({
                    submitError4: error.graphQLErrors.map(x => x.message)
                });
                console.error("ERR =>", error.graphQLErrors.map(x => x.message));
            });

        } else {
            this.setState({ submitError4: "Please fill COUPON ID" });
        }
    }

    coupnval = async (mobile,
        plan_id,
        referral_code
    ) => {
        await this.props.coupnval({
            variables: {
                mobile,
                plan_id,
                referral_code

            },
            update: (store, { data }) => {
                //console.log("coupnvaldata", data);
                if (data.validateReferralCode) {
                    //Cookies.set("coupnvalmob", data.validateReferralCode);
                    this.setState({
                        couponob: data.validateReferralCode,
                        submitError4: ""
                    });

                }
            }
        });
    };
    render() {
        console.log("kk",Cookies.get("examid"))
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper">

                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper">

                        <section className="student-package">
                            <Container>

                                <PackageOrderSummary
                                    type=""
                                    coupnValidation={this.coupnValidation}
                                    stateData={{
                                        planObj: this.props.history.location.state.data,
                                        couponob: this.state.couponob
                                    }}
                                    locgetData={this.props.history.location.state != undefined ? this.props.history.location.state : ""}
                                />


                            </Container>
                        </section>


                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(
    graphql(COUPN_VALIDATION, {
        name: "coupnval"
    },  
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
    )(SubscribeOrderSummary)); 

