export const getReadyExamData = [
    {
        id: 1,
        Title: 'NeetExam',
        textDescription: 'Checks here for all short notes and revision materials',
        dateView: '02-02-2020'
    },
    {
        id: 2,
        Title: 'Neet 11th Semi Grand',
        textDescription: 'Checks here for all short notes and revision materials',
        dateView: '03-03-2020'
    },
    {
        id: 3,
        Title: 'Neet 12th Semi Grand',
        textDescription: 'Checks here for all short notes and revision materials',
        dateView: '02-04-2020'
    }
];

export const createOwnExamData = [
    {
        id: 1,
        icon: 'fad fa-file-alt fa-4x text-warning',
        pageLink: '/student/get-ready-for-exam/chapter-exam',
        Title: 'Chapter Exam',
        textDescription: 'Only One Chapter selection from any one subject',
        dateView: '02-02-2020'
    },
    {
        id: 2,
        icon: 'fad fa-file-spreadsheet fa-4x text-success',
        pageLink: '/student/get-ready-for-exam/cumulative-exam',
        Title: 'Cumulative Exam',
        textDescription: 'Select Multi chapter from multiple Subjects',
        dateView: '03-03-2020'
    },
    {
        id: 3,
        icon: 'fad fa-book fa-4x text-warning',
        pageLink: '/student/get-ready-for-exam/semigrand-exam',
        Title: 'Semi Grand',
        textDescription: 'Complete One years Syllabus includes all subjects and chapters',
        dateView: '02-04-2020'
    },
    {
        id: 4,
        icon: 'fad fa-books fa-4x text-success',
        pageLink: '/student/get-ready-for-exam/grand-exam',
        Title: 'Grand Test',
        textDescription: 'Complete Two years Syllabus includes all subjects and chapters',
        dateView: '02-04-2020'
    }
];
