import React, { Component } from 'react'
import {Container, Row, Col,Card,Button,Badge,DropdownButton,Dropdown } from 'react-bootstrap';
import { Link,withRouter } from 'react-router-dom';
import * as Cookies from "es-cookie";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import PLAYICON from  '../../../../images/video-play.png';

import '../_categorywisevideos.scss';

const FETCH_SERVICE_DATA_FOR_LIVE_CLASSES_SCHEDULED = gql` 
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String
    ){
        getServiceData(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type){
            data
            }
        }
    `;

class LevelWiseVideoSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isCardView: false,
            subjectId: 0,
            subjectWiseCount: [],
            subjectIdsArr:[0]
        }
    }

    handleSelect = (subject) => {

        let subjectIds=[];
        let subjectIdsArrr=[];

        if(subject == ''){
            subjectIds=0;
        }else{
            subjectIds = subject;
        }

        if(subject == 99){
            subjectIdsArrr=[1,5];
        }else if(subject == ''){
            subjectIdsArrr=[0];
        }else{
            subjectIdsArrr.push(subject);
        }

        

        this.setState({
            subjectId: subjectIds,
            subjectIdsArr:subjectIdsArrr
        });

    }

    subjectClass = (subject) => {
        let classname = "",allsubjects=0;
        if(subject == ''){
            allsubjects = subject
        }else{
            allsubjects = subject
        }
        if(this.state.subjectId == allsubjects){
            classname = "active";
        }
        
        return classname;
    }
    checkforSubjects = () => {
        let videosData11=[];
        const categoryWiseData = this.props.categoryWiseData;
        categoryWiseData.categoryNameType.CustomVideoSubCategory.map((subCategoryName, index) => {
            videosData11 = subCategoryName.CustomVideosList

        })
       

                        
        //const videosData = videosData11.CustomVideosList;
        let filtered = videosData11.filter(item => item.subject == 0)
        return videosData11.length == filtered.length;
    }

    render() {

        const categoryWiseData = this.props.categoryWiseData;
        console.log("categroty",categoryWiseData)
        const loading1 = categoryWiseData.loading;
        const error1 = categoryWiseData.error;

        
        const getServiceData = this.props.getServiceData;
        console.log("videocate", getServiceData)
        const loading2 = getServiceData.loading;

       
        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }


        this.state.subjectWiseCount=[];
        categoryWiseData.categoryNameType.CustomVideoSubCategory.map((subCategoryName, index) => {
           const videosData22 = subCategoryName.CustomVideosList;
            videosData22 !=null && videosData22 != undefined && videosData22.map((subjectsCount, index) => {
                if(this.state.subjectId == 99){
                    if(subjectsCount.subject == 1 || subjectsCount.subject == 5){
                        this.state.subjectWiseCount.push(subjectsCount);
                    }
                }else if(this.state.subjectId == 0){
                    this.state.subjectWiseCount.push("all");
                }else{
                    if(subjectsCount.subject == this.state.subjectId){
                        this.state.subjectWiseCount.push(subjectsCount);
                    }
                }
                
            })
        })

        console.log("this.state.subjectWiseCount",this.state.subjectWiseCount)

        if (loading1) {
            return (<Container><Row className="my-3">
                 <Col>
                     <div class="spinner-border text-primary text-center"></div>
                </Col>
            </Row></Container>)
        }else {
            return (
                <Container>
                    
                     <Row>
                         <Col sm={6} className="mt-res-3rem">
                             <ul className="list-inline breadcrumb-nav">
                                 {
                                      categoryWiseData.pageType == "home" ?
                                      (
                                        <Link to="/student/category_wise_videos">
                                            <li onClick={this.props.history.goBack}><span>Watch & Learn</span> <i class="fas fa-chevron-right"></i></li>   
                                        </Link>
                                      )  
                                      :
                                      (<li onClick={this.props.history.goBack}><span>Watch & Learn</span> <i class="fas fa-chevron-right"></i></li>   )
                                 }  
                                 {
                                    categoryWiseData.mainCategoryName != "Live Classes" ? (
                                        <li style={{cursor:"auto"}}><span>{categoryWiseData.mainCategoryName}</span> <i class="fas fa-chevron-right"></i></li>   
                                    ) : ("")
                                 }
                                 
                             </ul>
                             
                         </Col>
                         {
                            categoryWiseData.categoryNameType.CustomVideoSubCategory.length == 1 ?
                            (
                                Cookies.get("examid") == 1 ?
                                (
                                    <Col sm={6} className="mt-4">
                                        {
                                            !this.checkforSubjects() ? (<Col sm={12} className="">
                                                <ul className="list-inline subjects-custom-cls float-right-res1">
                                                    <li className={this.subjectClass('')} onClick={() => this.handleSelect('')}>All</li>
                                                    <li className={this.subjectClass(2)} onClick={() => this.handleSelect(2)}>Physics</li>
                                                    <li className={this.subjectClass(3)} onClick={() => this.handleSelect(3)}>Chemistry</li>

                                                    {
                                                        categoryWiseData.mainCategoryName == "CBSE 2021" ?
                                                        (
                                                            <>
                                                                <li className={this.subjectClass(4)} onClick={() => this.handleSelect(4)}>Maths</li>
                                                                <li className={this.subjectClass(99)} onClick={() => this.handleSelect(99)}>Biology</li>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <li className={this.subjectClass(4)} onClick={() => this.handleSelect(5)}>Zoology</li>
                                                                <li className={this.subjectClass(1)} onClick={() => this.handleSelect(1)}>Botany</li>
                                                            </>
                                                        )
                                                    }
                                                </ul>

                                            </Col>) : ''
                                        }
                                    </Col>
                                )
                                :
                                (
                                    <Col sm={6} className="mt-4">
                                        {
                                           !this.checkforSubjects() ? (<Col sm={12} className="">
                                                <ul className="list-inline subjects-custom-cls float-right-res1">
                                                    <li className={this.subjectClass('')} onClick={() => this.handleSelect('')}>All</li>
                                                    
                                                    <li className={this.subjectClass(2)} onClick={() => this.handleSelect(2)}>Physics</li>
                                                    <li className={this.subjectClass(3)} onClick={() => this.handleSelect(3)}>Chemistry</li>
                                                    <li className={this.subjectClass(4)} onClick={() => this.handleSelect(4)}>Maths</li>
                                                    {
                                                        categoryWiseData.mainCategoryName == "CBSE 2021" ?
                                                        (
                                                            <>
                                                                <li className={this.subjectClass(99)} onClick={() => this.handleSelect(99)}>Biology</li>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            ""
                                                        )
                                                    }
                                                </ul>

                                            </Col>) : ''
                                        }
                                    </Col>
                                )
                            )
                            :
                            ("")
                         }
                         
                        {
                            categoryWiseData.mainCategoryName == "Live Classes" ? 
                            (
                                <>
                                {
                                    getServiceData.getServiceData != undefined ? (
                                        getServiceData.getServiceData.map((mainData,index) => 
                                        mainData.data != null && mainData.data.length >0 ? 
                                        (
                                            <Col sm={6} className="mt-res-2rem dropdowm-scheduled">
                                                <a onClick={()=>this.setState({ isCardView: !this.state.isCardView })}>
                                                <DropdownButton id="dropdown-basic-button" title="View Schedule" className={this.state.isCardView ? "dropdown-icon-reverse float-right-res" : "dropdown-icon float-right-res"}>
                                                    {
                                                        mainData.data.map((scheduledUrls, subindex) => 
                                                        <Row>
                                                            <Col>
                                                                <a href={scheduledUrls.url} target="_blank" className="mt-2 color-blue"><h6>{scheduledUrls.short_description}<i class="fas fa-download float-right"></i></h6></a>
                                                            </Col>
                
                                                        </Row>
                                                        ) 
                                                    }
                                                        
                                                </DropdownButton>
                                            </a>
                                            </Col>
                                        )
                                        :
                                        ("")
                                        )
                                    ) : ("")
                                        
                                    }
                                    </>
                                )
                                :
                                ("")
                            }       
                    </Row>
                    <Row>

                      {
                          categoryWiseData.categoryNameType.CustomVideoSubCategory.length >0 ? 
                          (
                              
                            categoryWiseData.categoryNameType.CustomVideoSubCategory.length == 1 ?
                            (
                                categoryWiseData.categoryNameType.CustomVideoSubCategory.map((subCategoryName, index) => {
                                    return (
                                        subCategoryName.CustomVideosList !=null && subCategoryName.CustomVideosList.length >0 ? 
                                        (

                                            this.state.subjectWiseCount.length > 0 ?
                                            (
                                                subCategoryName.CustomVideosList.map((videosUrls,index) => (
                                                    this.state.subjectIdsArr.includes(videosUrls.subject)?
                                                    (
                                                        <Col  sm={4} className="mt-4">
                                                            <Link to={{
                                                                pathname: "/student/subject/start-video-watching",
                                                                state: {
                                                                    index: index,
                                                                    videosList: subCategoryName.CustomVideosList,
                                                                    type: "iv",
                                                                    mobile: Cookies.get("mobile"),
                                                                    urltype:"categoryWiseVideo"
    
                                                                }
                                                            }} className="">
                                                                {videosUrls.thumbnail != "" ? (<>
                                                                
                                                                    <span className="play-icon-video"><img className="image-style-cls w-100"  src={videosUrls.thumbnail} alt={videosUrls.title} /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /></span>
                                                                </>) : (
                                                                    <span className="play-icon-video"><img  className="image-style-cls w-100" src={"https://source.unsplash.com/M185_qYH8vg/1780x1040"} alt="img" /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /> </span>
                                                                )}
    
                                                            </Link>
                                                            
                                                                <h6 className="f-16 mt-2">{videosUrls.title}</h6>
                                                        </Col>
                                                    )
                                                    :
                                                    (
                                                        this.state.subjectIdsArr.includes(0)?
                                                        (
                                                        <Col  sm={4} className="mt-4">
                                                            <Link to={{
                                                                pathname: "/student/subject/start-video-watching",
                                                                state: {
                                                                    index: index,
                                                                    videosList: subCategoryName.CustomVideosList,
                                                                    type: "iv",
                                                                    mobile: Cookies.get("mobile"),
                                                                    urltype:"categoryWiseVideo"
    
                                                                }
                                                            }} className="">
                                                                {videosUrls.thumbnail != "" ? (<>
                                                                
                                                                    <span className="play-icon-video"><img className="image-style-cls w-100"  src={videosUrls.thumbnail} alt={videosUrls.title} /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /></span>
                                                                </>) : (
                                                                    <span className="play-icon-video"><img  className="image-style-cls w-100" src={"https://source.unsplash.com/M185_qYH8vg/1780x1040"} alt="img" /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /> </span>
                                                                )}
    
                                                            </Link>
                                                            
                                                                <h6 className="f-16 mt-2">{videosUrls.title}</h6>
                                                        </Col>
                                                        ):("")
                                                    )
                                                    
                                                    
                                                        
                                                ))
                                            )
                                            :
                                            (<Col sm={12}> <h5 className='font-weight-bold text-center mt-4'>Available soon....</h5> </Col>)
                                            
                                            

                                           
                                        )
                                        :
                                        ("")
                                    )
                                  

                                })
                                           
                            )
                            :
                            (
                                
                                categoryWiseData.categoryNameType.CustomVideoSubCategory.sort((a,b) => a.order_by - b.order_by).map((subCategoryName, index) => {
                                    if(subCategoryName.CustomVideosList !=null && subCategoryName.CustomVideosList.length >0){
                                        return (
                                            <>
                                            {
                                                <Col sm={12} className="mt-4">
                                                    <h5 className="font-res-15">{subCategoryName.category}  
                                                    <span> - </span> <span className="total-videos-count">{subCategoryName.CustomVideosList.length} Videos</span> <span>:</span>
                                                        <Link to={{
                                                            pathname: "/student/category_wise_videos/all-videos",
                                                            state: {
                                                                subCategoryNameType:subCategoryName,
                                                                mainCategoryName:categoryWiseData.mainCategoryName,
                                                                subCategoryName :subCategoryName.category,
                                                                examType:categoryWiseData.scheduledType,
                                                            }
                                                        }} className="view_more_cls">View More</Link>
                                                    </h5>
                                               
                                                </Col>

                                                 }
                                                 {
                                                    subCategoryName.CustomVideosList.slice(0, 3).map((videosUrls,index) => (
                                                        videosUrls.publish == 1 ? 
                                                        (
                                                            <Col  sm={4} className="mt-2">
                                                                <Link to={{
                                                                    pathname: "/student/subject/start-video-watching",
                                                                    state: {
                                                                        index: index,
                                                                        videosList: subCategoryName.CustomVideosList,
                                                                        type: "iv",
                                                                        mobile: Cookies.get("mobile"),
                                                                        urltype:"categoryWiseVideo"
        
                                                                    }
                                                                }} className="">
                                                                    {videosUrls.thumbnail != "" ? (<>
                                                                    
                                                                        <span className="play-icon-video"><img className="image-style-cls w-100"  src={videosUrls.thumbnail} alt={videosUrls.title} /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /></span>
                                                                    </>) : (
                                                                        <span className="play-icon-video"><img  className="image-style-cls w-100" src={"https://source.unsplash.com/M185_qYH8vg/1780x1040"} alt="img" /> <img src={PLAYICON} alt="play-icon" className="play-icon-pos" /> </span>
                                                                    )}
        
                                                                </Link>
                                                                
                                                                    <h6 className="f-16 mt-2">{videosUrls.title}</h6>
                                                            </Col>
                                                        )
                                                        :
                                                        ("")
                                                        
                                                        ))
                                                 }

                                                </>
                                            )
                                    }else{
                                        return ("")
                                    }
                                    

                                })
                            )
                          )
                          :
                          ("")
                      }
                      </Row>
                     
                </Container>
              )
        }
    }
}

export default withRouter(compose(
    
    graphql(FETCH_SERVICE_DATA_FOR_LIVE_CLASSES_SCHEDULED  , {
        options: props => ({
            variables: {
                exam_id : parseInt(Cookies.get("examid")),
                service_type : "schedule_data",
                service_sub_type: props.categoryWiseData.scheduledType
                
            },
            fetchPolicy: 'network-only'
        }),name: "getServiceData"
    }
    )
    )(LevelWiseVideoSection));


