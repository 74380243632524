import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Image, Navbar, Dropdown, Form, FormControl, Button, Container, Media, Accordion } from 'react-bootstrap';
import * as Cookies from "es-cookie";
import { components } from 'react-select';
import SelectDropDown from '../../../../neetjee_guru/components/selectdropdown/SelectDropDown';
import MultiSelect from "react-multi-select-component";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withApollo } from "react-apollo";
import DraggableBranchSection from './DraggableBranchSection';
import DraggableCollegeSection from './DraggableCollegeSection';
import DraggablePriorityOrderSection from './DraggablePriorityOrderSection';
import './_shortlist.scss'
import DeleteRecordModal from './deleteRecordModal';
import ExcelContainer from './excelContainer.jsx';

// import ReferFriendImage from '../../../../images/refer_friend_sht.png'
import DownloadWOImage from '../../../../images/download_web_option.png';

const PRIMARY_SAVE = gql`
mutation(
    $params : StudentOptions
){
    saveStudentWebOptions(
        params : $params
    )
}
`;

const ORDER_WISE_SHORT_LIST = gql`
    mutation(
        $params :OrderwiseShortlistedData
    ){
        saveOrderwiseShortlistedData(
            params : $params
        ){
            collegeBranchDetails {
                id
                college_name
                branch_name
                default_order
                user_order
                college_id
                branch_id
                college_code
                rank
                last_year_cutoffs{
                    eamcet_seat_category_name
                    start_rank
                    end_rank
                  }
            }   
        }
    }
    `;

const ORDER_UPDATE_WISE_SHORT_LIST = gql`
    mutation(
        $params :OrderwiseUpdateShortlistedData
    ){
        updateOrderwiseShortlistedData(
            params : $params
        ){
            collegeBranchDetails {
                id
                college_name
                branch_name
                default_order
                user_order
                college_id
                branch_id
                college_code
                rank
                last_year_cutoffs{
                    eamcet_seat_category_name
                    start_rank
                    end_rank
                  }
            }
        }
    }
    `;

const DELETE_BRANCH_COLLEGE_DETATILS = gql`
    mutation(
        $params:CollegePrediction1
    ){
        deleteStudentBranchCollegeDetails(
            params:$params
        )
    }
    `;

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class NTAShortlistsection extends Component {
    constructor(props) {
        super(props)
        this.state = {

            uniqueBranchList: [],
            uniqueCollegesList: [],
            new_Branches_colleges: [],
            getShortlistedCollegesBranches: [],
            inputRank: 0,

            error_rank_display: "",
            error_display_caste: "",

            spinnerPrimaryBlockShow: "hide",
            disabled_button: false,

            caste: "",
            getStudentWebOptions_examType: [],

            orderPriorityDetails: [],
            savingOrderBranchList: [],
            savingOrderCollegeList: [],
            onloadWebOptions: "onload",
            deleteRecordSpinnerShow: 'hide',
            deleteModal: false,
            deleteItem: "",
            deleteMessage: "",
            orderspinnerBlockShow: "hide",
            primarySpinner: "hide",
            spinnerBlockShow: "hide",

            getDifficultyLevelCollegesData: [],
            iconChangeprimary: "up",
            iconChangesecondary: "down",

            examActiveType: "mains",

            textDisplay: false,

            exam_type_id: 1,
            b_arch_type_id: 0

        }
    }


    onDragEndData = (result) => {

        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            this.state.uniqueBranchList,
            result.source.index,
            result.destination.index
        );

        const reorderedData = reorderedItems;
        this.setState({
            uniqueBranchList: reorderedData
        });
    }

    onDragEndCollegeData = (result) => {

        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            this.state.uniqueCollegesList,
            result.source.index,
            result.destination.index
        );
        const reorderedData1 = reorderedItems;
        this.setState({
            uniqueCollegesList: reorderedData1
        });
    };

    onDragEndPriortyOrder = (result) => {

        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            this.state.orderPriorityDetails,
            result.source.index,
            result.destination.index
        );


        //setItems(reorderedItems);
        const reorderedData2 = reorderedItems;
        this.setState({
            orderPriorityDetails: reorderedData2
        });

    };

    componentDidMount = async () => {
        this.getShortlistedData()

        if (this.props.getStudentWebOptions != undefined && this.props.getStudentWebOptions.length > 0) {
            this.getOnloadPriorityOrder();
            this.getBranchWiseData();
        }
    }

    getShortlistedData = async () => {
        this.setState({ primarySpinner: "show" })
        const result = await this.props.client.query({
            query: gql`
            query($mobile: String,$exam_id:Int,$exam_type:Int,$b_arch_type:Int) {
                getShortlistedCollegesBranches(mobile: $mobile,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type)
                    {
                        mobile
                        branches{
                            id
                            branch_name
                            order_no
                            index_val
                        }
                        colleges{
                            id
                            college_name
                            order_no
                            index_val
                        }
                    }
            }
            `,
            variables: {
                mobile: Cookies.get("mobile"),
                exam_id: parseInt(Cookies.get("examid")),
                exam_type: this.state.exam_type_id,
                b_arch_type: this.state.b_arch_type_id
            },
            fetchPolicy: 'network-only'
        });

        if (result.data.getShortlistedCollegesBranches.length > 0) {
            this.setState({
                uniqueBranchList: result.data.getShortlistedCollegesBranches[0].branches,
                uniqueCollegesList: result.data.getShortlistedCollegesBranches[0].colleges,
                getShortlistedCollegesBranches: result.data.getShortlistedCollegesBranches,
                primarySpinner: "hide",
                textDisplay: false

            })
        } else {
            this.setState({
                primarySpinner: "hide",
                textDisplay: true


            })
        }
    }

    handleRank = (e) => {
        this.setState({ disabled_button: false, onloadWebOptions: "change" })

        const value = e.target.value
        this.setState({ inputRank: value, error_rank_display: '' })
    }

    savePrimaryDetails = () => {
        this.setState({ disabled_button: true });

        let getPrimarySelDetails = "";

        if (this.state.inputRank == 0) {
            this.setState({ error_rank_display: "Please Enter Rank" })
            return;
        }

        if (this.state.caste == '' || this.state.caste == 0) {
            this.setState({ error_display_caste: "Please Select Caste" });
            return;

        }
        this.setState({ spinnerPrimaryBlockShow: "show" });

        getPrimarySelDetails = {
            mobile: Cookies.get("mobile"),
            exam_id: parseInt(Cookies.get("examid")),
            rank: parseInt(this.state.inputRank),
            marks: 0,
            eamcet_caste_id: parseInt(this.state.caste.value),
            eamcet_seat_category_id: "",
            exam_type: this.state.exam_type_id,
            b_arch_type: this.state.b_arch_type_id

        }

        this.saveStudentWebOptions(getPrimarySelDetails).catch(error => {
            console.log("catch if error");
            console.log(error);
        })
    }
    saveStudentWebOptions = async (params) => {
        await this.props.saveStudentWebOptions({
            variables: {
                params
            },
            update: (store, { data }) => {
                this.setState({ spinnerPrimaryBlockShow: "hide" });
                if (data.saveStudentWebOptions == true) {
                    this.setState({ successMessage: "Updated" });
                    this.setState({ disabled_button: false })
                }
            }
        });
    }

    getMainCastes() {
        let newArray = [];

        this.props.getNTAPredictorCastes.map((item) => {
            if (item != undefined) {
                const newObj = {
                    value: item.id,
                    label: item.seat_type
                }
                newArray.push(newObj);
            }
        }
        )
        return newArray;
    };

    selecthandleInputChange = async (ename, evalue) => {
        const value = evalue;
        const name = ename;

        if (name == "caste") {
            this.setState({ onloadWebOptions: "change" })
            let ecasteData = this.props.getNTAPredictorCastes.find((a) => a.id == value);
            this.setState({
                caste: {
                    value: ecasteData.id,
                    label: ecasteData.seat_type
                },
                error_display_caste: ''
            })
        }
    }

    getOnloadPriorityOrder = async () => {
        const result = await this.props.client.query({
            query: gql`
            query($mobile: String,$exam_id:Int,$selection_type:Int,$exam_type:Int,$b_arch_type:Int) {
                getStudentBranchCollegeDetails(mobile: $mobile,exam_id:$exam_id,selection_type:$selection_type,exam_type:$exam_type,b_arch_type:$b_arch_type)
                    {
                        collegeBranchDetails {
                            id
                            college_name
                            branch_id
                            default_order
                            user_order
                            college_id
                            branch_name
                            college_code
                            rank
                            last_year_cutoffs{
                                eamcet_seat_category_name
                                start_rank
                                end_rank
                              }
                        }
                        
                    }
            }
            `,
            variables: {
                mobile: Cookies.get("mobile"),
                exam_id: parseInt(Cookies.get("examid")),
                selection_type: 2,
                exam_type: this.state.exam_type_id,
                b_arch_type: this.state.b_arch_type_id

            },
            fetchPolicy: 'network-only'
        })

        if (result.data.getStudentBranchCollegeDetails.length > 0) {
            this.setState({
                orderPriorityDetails: result.data.getStudentBranchCollegeDetails[0].collegeBranchDetails

            })
        } else {
            this.setState({ orderPriorityDetails: [] })
        }
    }

    saveAndGetDetails = () => {
        this.setState({ disabled_button: true, onloadWebOptions: "change" });
        let getSelDetails = "";

        this.setState({ error_display_caste: "", error_rank_display: "" })

        if (this.state.inputRank == 0) {
            this.setState({ error_rank_display: "Please enter Rank" })
            return;
        }

        if (this.state.caste == "" || this.state.caste == 0) {
            this.setState({ error_display_caste: "Please select caste" })
            return;
        }

        this.setState({ orderPriorityDetails: [] });
        this.setState({ spinnerBlockShow: "show" });

        this.state.savingOrderBranchList = this.state.uniqueBranchList.map((item, index) => {
            return { eamcet_branch_id: parseInt(item.id), order_no: parseInt(index + 1) }
        });

        this.state.savingOrderCollegeList = this.state.uniqueCollegesList.map((item, index) => {
            return { eamcet_college_id: parseInt(item.id), order_no: parseInt(index + 1) }
        });

        getSelDetails = {
            mobile: Cookies.get("mobile"),
            exam_id: parseInt(Cookies.get("examid")),
            rank: parseInt(this.state.inputRank),
            marks: 0,
            eamcet_caste_id: parseInt(this.state.caste.value),
            eamcet_seat_category_id: "",
            selectionType: 2,
            branchShortlist: this.state.savingOrderBranchList,
            collegeShortlist: this.state.savingOrderCollegeList,
            exam_type: this.state.exam_type_id,
            b_arch_type: this.state.b_arch_type_id

        }

        this.saveOrderwiseShortlistedData(getSelDetails).catch(error => {
            console.log("catch if error");
            console.log(error);
        })
    }

    saveOrderwiseShortlistedData = async (params) => {

        await this.props.saveOrderwiseShortlistedData({
            variables: {
                params
            },
            update: async (store, { data }) => {
                var collegeBranchDetails = [];
                data.saveOrderwiseShortlistedData.map(item => {
                    item.collegeBranchDetails.map(details => {
                        collegeBranchDetails.push(details);
                    })

                });

                this.setState({ orderPriorityDetails: collegeBranchDetails });
                this.setState({ spinnerBlockShow: "hide", disabled_button: false });
                this.getBranchWiseData();

                const result = await this.props.client.query({
                    query: gql` 
                            query($mobile: String,$exam_id:Int,$exam_type:Int,$b_arch_type:Int) {
                                getShortlistedCollegesBranches(mobile: $mobile,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type)
                                    {
                                        mobile
                                        branches{
                                            id
                                            branch_name
                                            order_no
                                            index_val
                                        }
                                        colleges{
                                            id
                                            college_name
                                            order_no
                                            index_val
                                        }
                                    }
                            }
                        `,
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: this.state.exam_type_id,
                        b_arch_type: this.state.b_arch_type_id
                    },
                    fetchPolicy: 'network-only'
                });


                if (result.data.getShortlistedCollegesBranches.length > 0) {

                    const delay = (ms) => new Promise((r) => setTimeout(r, ms));
                    await delay(1000);

                    this.setState({
                        uniqueBranchList: result.data.getShortlistedCollegesBranches[0].branches,
                        uniqueCollegesList: result.data.getShortlistedCollegesBranches[0].colleges,
                        new_Branches_colleges: []

                    });

                    const newBranches = this.state.uniqueBranchList.filter(item2 => item2.index_val == 0).map(item => item.index_val)
                    const newColleges = this.state.uniqueCollegesList.filter(item2 => item2.index_val == 0).map(item => item.index_val)

                }
            }
        });
    }

    updateAndGetDetails = (e, itemType) => {
        if (itemType == "update") {
            this.setState({ onloadWebOptions: "change", orderspinnerBlockShow: 'show' })
        }

        let getPriorityOrderDetails = "";

        const user_order_change_details = this.state.orderPriorityDetails.map((item, index) => {
            return { eamcet_college_id: item.college_id, eamcet_branch_id: item.branch_id, user_change_order_no: parseInt(index + 1) }
        });

        const branch_ids = this.state.orderPriorityDetails.map(item => item.branch_id);
        const unique_branch_ids = [...new Set(branch_ids)]

        const college_ids = this.state.orderPriorityDetails.map(item => item.college_id);
        const unique_college_ids = [...new Set(college_ids)]

        getPriorityOrderDetails = {
            mobile: Cookies.get("mobile"),
            exam_id: parseInt(Cookies.get("examid")),
            selection_type: 2,
            priorityOrderDetails: user_order_change_details,
            unique_branch_ids: unique_branch_ids.toString(),
            unique_college_ids: unique_college_ids.toString(),
            exam_type: this.state.exam_type_id,
            b_arch_type: this.state.b_arch_type_id,
        }

        this.updateOrderwiseShortlistedData(getPriorityOrderDetails, itemType).catch(error => {
            console.log("catch if error");
            console.log(error);
        });
    }

    updateOrderwiseShortlistedData = async (params, itemType) => {

        await this.props.updateOrderwiseShortlistedData({
            variables: {
                params
            },
            update: async (store, { data }) => {
                var collegeBranchDetails = [];

                data.updateOrderwiseShortlistedData != null && data.updateOrderwiseShortlistedData.map(item => {
                    item.collegeBranchDetails.map(details => {
                        collegeBranchDetails.push(details);
                    })
                });

                this.setState({ orderPriorityDetails: collegeBranchDetails });
                if (itemType == "update") {
                    this.setState({ orderspinnerBlockShow: "hide" });
                }

                this.getBranchWiseData();


                const result = await this.props.client.query({
                    query: gql` 
                    query($mobile: String,$exam_id:Int,$exam_type:Int,$b_arch_type:Int) {
                        getShortlistedCollegesBranches(mobile: $mobile,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type)
                            {
                                mobile
                                branches{
                                    id
                                    branch_name
                                    order_no
                                    index_val
                                }
                                colleges{
                                    id
                                    college_name
                                    order_no
                                    index_val
                                }
                            }
                    }
                        `,
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_id: parseInt(Cookies.get("examid")),
                        exam_type: this.state.exam_type_id,
                        b_arch_type: this.state.b_arch_type_id
                    },
                    fetchPolicy: 'network-only'
                });

                if (result.data.getShortlistedCollegesBranches.length > 0) {
                    if (result.data.getShortlistedCollegesBranches.length > 0) {
                        this.setState({
                            uniqueBranchList: result.data.getShortlistedCollegesBranches[0].branches,
                            uniqueCollegesList: result.data.getShortlistedCollegesBranches[0].colleges

                        });

                        const newBranches = this.state.uniqueBranchList.filter(item2 => item2.index_val == 0).map(item => item.index_val)
                        const newColleges = this.state.uniqueCollegesList.filter(item2 => item2.index_val == 0).map(item => item.index_val)


                        if (newBranches.length > 0 || newColleges.length > 0) {
                            this.setState({ new_Branches_colleges: [] });
                        }
                    }
                }
            }
        });
    }

    deleteBranchCollegeRecordData = async (item) => {
        this.setState({ deleteRecordSpinnerShow: 'show' })
        await this.props.deleteStudentBranchCollegeDetails({

            variables: {
                params: {
                    mobile: Cookies.get("mobile"),
                    eamcet_college_id: parseInt(item.college_id),
                    eamcet_branch_id: parseInt(item.branch_id),
                    exam_id: parseInt(Cookies.get("examid")),
                    exam_type: this.state.exam_type_id,
                    b_arch_type: this.state.b_arch_type_id

                }
            },
            update: async (store, { data }) => {
                if (data.deleteStudentBranchCollegeDetails) {
                    const result2 = await this.props.client.query({
                        query: gql` 
                                    query($mobile: String,$exam_id:Int,$selection_type:Int,$exam_type:Int,$b_arch_type:Int) {
                                        getStudentBranchCollegeDetails(mobile: $mobile,exam_id:$exam_id,selection_type:$selection_type,exam_type:$exam_type,b_arch_type:$b_arch_type)
                                            {
                                                collegeBranchDetails {
                                                    id
                                                    college_name
                                                    branch_id
                                                    default_order
                                                    user_order
                                                    college_id
                                                    branch_name
                                                    college_code
                                                    rank
                                                    last_year_cutoffs{
                                                        eamcet_seat_category_name
                                                        start_rank
                                                        end_rank
                                                      }
                                                }
                                                
                                            }
                                    }
                                `,
                        variables: {
                            mobile: Cookies.get("mobile"),
                            exam_id: parseInt(Cookies.get("examid")),
                            selection_type: 2,
                            exam_type: this.state.exam_type_id,
                            b_arch_type: this.state.b_arch_type_id

                        },
                        fetchPolicy: 'network-only'
                    });


                    if (result2.data.getStudentBranchCollegeDetails.length > 0) {
                        this.setState({ orderPriorityDetails: result2.data.getStudentBranchCollegeDetails[0].collegeBranchDetails, deleteRecordSpinnerShow: "hide" })
                        this.setState({ deleteMessage: "Deleted SuccessFully..." })
                        setTimeout(() => { this.setState({ deleteModal: false, deleteItem: "", deleteMessage: "" }) }, 1000);
                    } else {
                        this.setState({ orderPriorityDetails: [], getDifficultyLevelCollegesData: [], deleteRecordSpinnerShow: "hide" })
                        this.setState({ deleteMessage: "SomeThing went wrong,Please check once." })
                        //setTimeout(() => { this.setState({ deleteModal: false,deleteItem:"" }) }, 1000);
                    }


                    this.getBranchWiseData();
                    this.updateAndGetDetails("", "delete");
                }
            }
        });
    }

    onHideDeleteModal = () => {
        this.setState({ deleteModal: false, deleteItem: "" })
    }

    deleteRecordModal = (item) => {
        this.setState({ deleteModal: true, deleteItem: item })
    }

    getBranchWiseData = async (e) => {
        this.setState({ error_display_caste: '', error_rank_display: '' });
        const result = await this.props.client.query({
            query: gql` 
                query($mobile:String,$exam_id:Int,$exam_type:Int,$b_arch_type:Int) {
                    getDifficultyLevelColleges(mobile:$mobile,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type){
                        branch_name
                        branch_id
                        values{
                            easy
                            moda
                            diff
                          }
                    }
                }
                `,
            variables: {
                mobile: Cookies.get("mobile"),
                exam_id: parseInt(Cookies.get("examid")),
                exam_type: this.state.exam_type_id,
                b_arch_type: this.state.b_arch_type_id
            },
            fetchPolicy: 'network-only'
        });

        if (result.data.getDifficultyLevelColleges.length > 0) {
            this.setState({
                getDifficultyLevelCollegesData: result.data.getDifficultyLevelColleges
            });
        }
    }

    getAccordianIconChange = () => {
        if (this.state.iconChangeprimary == "up") {
            this.setState({ iconChangeprimary: "down" })
        } else {
            this.setState({ iconChangeprimary: "up" })
        }
    }

    getAccordianIconChange2 = () => {
        if (this.state.iconChangesecondary == "up") {
            this.setState({ iconChangesecondary: "down" })
        } else {
            this.setState({ iconChangesecondary: "up" })
        }
    }

    changeExamType = async (e, exam_type_val) => {
        this.setState({
            primarySpinner: "show",
            onloadWebOptions: "change"
        })

        let getCasteNamesData = [];

        this.setState({ examActiveType: '', textDisplay: false });

        if (exam_type_val == 1) {
            this.setState({
                examActiveType: "mains",
                exam_type_id: 1,
                b_arch_type_id: 0,
            })
        } else if (exam_type_val == 2) {
            this.setState({
                examActiveType: "advanced",
                exam_type_id: 0,
                b_arch_type_id: 0,
            })
        } else if (exam_type_val == 3) {
            this.setState({
                examActiveType: "b_mains",
                exam_type_id: 1,
                b_arch_type_id: 2,
            })
        } else if (exam_type_val == 4) {
            this.setState({
                examActiveType: "b_advanced",
                exam_type_id: 0,
                b_arch_type_id: 2,
            })
        }
        this.setState({
            caste: '',
            error_rank_display: "",
            error_display_caste: "",
            orderPriorityDetails: [],
            uniqueBranchList: [],
            uniqueCollegesList: [],
            savingOrderBranchList: [],
            savingOrderCollegeList: [],
            getDifficultyLevelCollegesData: [],
            getStudentWebOptions_examType: [],
            orderspinnerBlockShow: "hide",
            spinnerBlockShow: "hide",
            new_Branches_colleges: [],
            deleteItem: "",
            deleteMessage: "",
            inputRank: 0
        })

        const result_caste = await this.props.client.query({
            query: gql`
            query($id:Int){
                getNTAPredictorCastes(id: $id) {
                    id
                    seat_type
                  }
            }
            `,
            variables: {
                id: 0
            },
            fetchPolicy: 'network-only'
        });

        if (result_caste.data.getNTAPredictorCastes.length > 0) {
            getCasteNamesData = result_caste.data.getNTAPredictorCastes;
        }

        const result = await this.props.client.query({
            query: gql`
            query($mobile: String,$exam_id:Int,$b_arch_type:Int,$exam_type:Int) {
                getStudentWebOptions(mobile: $mobile,exam_id:$exam_id,b_arch_type:$b_arch_type,exam_type:$exam_type){
                    rank
                    marks
                    eamcet_caste_id
                    eamcet_seat_category_id
                    selection_type
                 }
            }
            `,
            variables: {
                mobile: Cookies.get("mobile"),
                exam_id: parseInt(Cookies.get("examid")),
                exam_type: this.state.exam_type_id,
                b_arch_type: this.state.b_arch_type_id
            },
            fetchPolicy: 'network-only'
        })

        if (result.data.getStudentWebOptions.length > 0) {
            this.setState({
                getStudentWebOptions_examType: result.data.getStudentWebOptions
            });

            if (getCasteNamesData.length > 0) {
                getCasteNamesData.map((item) => {
                    if (item != undefined) {
                        if (this.state.getStudentWebOptions_examType[0].eamcet_caste_id == item.id) {
                            const obj = {
                                value: item.id,
                                label: item.seat_type,
                            }
                            this.setState({
                                caste: obj
                            })
                        }
                    }
                })
            }

            if (this.state.getStudentWebOptions_examType[0].rank != 0) {
                this.state.inputRank = this.state.getStudentWebOptions_examType[0].rank
            }
        }

        const result_collegeAndBranches = await this.props.client.query({
            query: gql`
            query($mobile: String,$exam_id:Int,$exam_type:Int,$b_arch_type:Int) {
                getShortlistedCollegesBranches(mobile: $mobile,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type)
                    {
                        mobile
                        branches{
                            id
                            branch_name
                            order_no
                            index_val
                        }
                        colleges{
                            id
                            college_name
                            order_no
                            index_val
                        }
                    }
            }
            `,
            variables: {
                mobile: Cookies.get("mobile"),
                exam_id: parseInt(Cookies.get("examid")),
                exam_type: this.state.exam_type_id,
                b_arch_type: this.state.b_arch_type_id
            },
            fetchPolicy: 'network-only'
        });

        console.log("aaaaaaaaaaaaaaaa", result_collegeAndBranches)

        if (result_collegeAndBranches.data.getShortlistedCollegesBranches.length > 0) {
            this.setState({
                uniqueBranchList: result_collegeAndBranches.data.getShortlistedCollegesBranches[0].branches,
                uniqueCollegesList: result_collegeAndBranches.data.getShortlistedCollegesBranches[0].colleges,
                getShortlistedCollegesBranches: result_collegeAndBranches.data.getShortlistedCollegesBranches,
                primarySpinner: "hide",
                textDisplay: false,

            })
        } else {
            this.setState({
                primarySpinner: "hide",
                textDisplay: true,


            })
        }



        if (this.state.getStudentWebOptions_examType.length > 0) {

            const result2 = await this.props.client.query({
                query: gql` 
                query($mobile: String,$exam_id:Int,$selection_type:Int,$exam_type:Int,$b_arch_type:Int) {
                    getStudentBranchCollegeDetails(mobile: $mobile,exam_id:$exam_id,selection_type:$selection_type,exam_type:$exam_type,b_arch_type:$b_arch_type)
                        {
                            collegeBranchDetails {
                                id
                                college_name
                                branch_id
                                default_order
                                user_order
                                college_id
                                branch_name
                                college_code
                                rank
                                last_year_cutoffs{
                                    eamcet_seat_category_name
                                    start_rank
                                    end_rank
                                  }
                            }
                            
                        }
                }
                        `,
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_id: parseInt(Cookies.get("examid")),
                    selection_type: 2,
                    exam_type: this.state.exam_type_id,
                    b_arch_type: this.state.b_arch_type_id
                },
                fetchPolicy: 'network-only'
            });


            if (result2.data.getStudentBranchCollegeDetails.length > 0) {
                this.setState({ orderPriorityDetails: result2.data.getStudentBranchCollegeDetails[0].collegeBranchDetails })
            }
        }

        setTimeout(() => { this.getBranchWiseData(); }, 2000);
    }


    render() {
        

        if (this.state.getShortlistedCollegesBranches.length > 0) {

            const newBranches = this.state.getShortlistedCollegesBranches[0].branches.filter(item => item.index_val == 0).map(item2 => item2.index_val)
            const newColleges = this.state.getShortlistedCollegesBranches[0].colleges.filter(item => item.index_val == 0).map(item2 => item2.index_val)


            if (newBranches.length > 0 || newColleges.length > 0) {
                this.state.new_Branches_colleges = [...newBranches, ...newColleges]
            }
        }

        if (this.state.onloadWebOptions == "onload") {
            this.state.getStudentWebOptions_examType = this.props.getStudentWebOptions

            if (this.state.getStudentWebOptions_examType != undefined && this.state.getStudentWebOptions_examType.length > 0) {
                this.state.inputRank = this.state.getStudentWebOptions_examType[0].rank
            }

            if (this.props.getNTAPredictorCastes != undefined && this.props.getNTAPredictorCastes.length > 0 && this.state.getStudentWebOptions_examType != undefined && this.state.getStudentWebOptions_examType.length > 0) {
                this.props.getNTAPredictorCastes.map((item) => {
                    if (item != undefined) {
                        if (this.state.getStudentWebOptions_examType[0].eamcet_caste_id == item.id) {
                            const obj = {
                                value: item.id,
                                label: item.seat_type,
                            }
                            this.state.caste = obj
                        }
                    }
                })
            }
        }

        return (
            <Container className='shortlist_nta'>
                <Row className='mt-5 d-flex justify-content-center'>
                    <Col sm={6} lg={2} xl={2}>
                        <ul className='list-inline class_type_li'>
                            <li className={this.state.examActiveType == "mains" ? "active" : ""} onClick={(e) => this.changeExamType(e, 1)}>JEE MAINS</li>
                        </ul>
                    </Col>
                    <Col sm={6} lg={2} xl={2}>
                        <ul className='list-inline class_type_li'>
                            <li className={this.state.examActiveType == "advanced" ? "active" : ""} onClick={(e) => this.changeExamType(e, 2)}>JEE ADVANCED</li>
                        </ul>
                    </Col>
                    <Col sm={6} lg={2} xl={2}>
                        <ul className='list-inline class_type_li'>
                            <li className={this.state.examActiveType == "b_mains" ? "active" : ""} onClick={(e) => this.changeExamType(e, 3)}>B.Arch (Mains)</li>
                        </ul>
                    </Col>
                    <Col sm={6} lg={3} xl={3}>
                        <ul className='list-inline class_type_li'>
                            <li className={this.state.examActiveType == "b_advanced" ? "active" : ""} onClick={(e) => this.changeExamType(e, 4)}>B.Arch (Advanced)</li>
                        </ul>
                    </Col>
                </Row>

                <Form>
                    <Row className='mt-3 justify-content-center'>
                        <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="rank">
                            <Form.Label className="text-uppercase font-weight-bold input_title">Rank</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder=""
                                name="marks"
                                autoComplete="off"
                                className='custom_selection'
                                onChange={(e) => this.handleRank(e)}
                                value={this.state.inputRank}
                            />
                            <h6 className='font-12 errorCls mt-2'>{this.state.error_rank_display}</h6>
                        </Form.Group>

                        <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="caste">
                            <Form.Label className="text-uppercase font-weight-bold input_title">Reservation Category</Form.Label>
                            <SelectDropDown
                                stateData={this.state.caste}
                                handleChange={this.selecthandleInputChange}
                                name="caste"
                                options={this.getMainCastes()}
                                placeholderName={'Caste'}
                                dropdownIndicator={{ DropdownIndicator }}
                            />
                            <h6 className='font-12 errorCls mt-2'>{this.state.error_display_caste}</h6>
                        </Form.Group>

                        <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} >
                            <Button type="button" className='btn btn-blue-color mt-3 mb-4 center-auto text-uppercase mt-2' onClick={(e) => { this.savePrimaryDetails(e) }}>SAVE DETAILS
                                <span className={this.state.spinnerPrimaryBlockShow == "show" ? "spinner-border text-primary text-center loading-img-center width_align ml-3" : ""}></span>
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>

                {
                    this.state.primarySpinner == "show" ?
                        (
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className='spinner-border text-primary text-center loading-img-center'></div>
                                    </Col>
                                </Row>
                            </Container>
                        )
                        :
                        (
                            <>
                                <Row>
                                    <Col sm={12}>
                                        <div className='flex-items mt-3'>
                                            <DraggableBranchSection onDragEndP={this.onDragEndData} data={this.state.uniqueBranchList} />
                                            <DraggableCollegeSection onDragEndC={this.onDragEndCollegeData} data={this.state.uniqueCollegesList} />
                                        </div>
                                    </Col>
                                </Row>


                                {
                                    this.state.uniqueBranchList.length == 0 && this.state.uniqueCollegesList.length == 0 ?
                                        (
                                            <Row>
                                                <Col sm={12}>
                                                    {
                                                        this.state.textDisplay == true && this.state.primarySpinner == "hide" ?
                                                            (
                                                                <h5 className='text-center font-weight-bold mt-4'>You have not  shortlisted  any Branches & Colleges...</h5>

                                                            )
                                                            :
                                                            (
                                                                ""

                                                            )
                                                    }
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        (
                                            this.state.new_Branches_colleges.length > 0 ?
                                                (
                                                    <>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <h6 className='mt-4 errorCls text-center'>Note : New Colleges & Branches Added,Please Generate priority order</h6>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <Button type="button" className={this.state.disabled_button == false ? "btn btn-blue-color mt-3 mb-4 center-auto text-uppercase" : "btn btn-blue-color mt-3 mb-4 center-auto text-uppercase disabled_button_cursor"} disabled={this.state.disabled_button} onClick={(e) => { this.saveAndGetDetails(e) }}>Generate Priority Order
                                                                    <span className={this.state.spinnerBlockShow == "show" ? "spinner-border text-primary text-center loading-img-center width_align ml-3" : ""}></span>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )
                                                :
                                                (
                                                    this.state.uniqueBranchList.length > 0 && this.state.uniqueCollegesList.length > 0 ?
                                                        (
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Button type="button" className={this.state.disabled_button == false ? "btn btn-blue-color mt-3 mb-4 center-auto text-uppercase" : "btn btn-blue-color mt-3 mb-4 center-auto text-uppercase disabled_button_cursor"} disabled={this.state.disabled_button} onClick={(e) => { this.saveAndGetDetails(e) }} >Generate Priority Order
                                                                        <span className={this.state.spinnerBlockShow == "show" ? "spinner-border text-primary text-center loading-img-center width_align ml-3" : ""}></span>
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        )
                                                        :
                                                        ("")
                                                )
                                        )
                                }

                            </>
                        )
                }

                {
                    this.state.getDifficultyLevelCollegesData != undefined && this.state.getDifficultyLevelCollegesData.length > 0 ?
                        (
                            <Row>
                                <Col sm={12} className='mt-2'>
                                    <React.Fragment>
                                        <Accordion defaultActiveKey="0">
                                            <Card className='block_shadow_css p-0'>
                                                <Accordion.Toggle className="bg-white" as={Card.Header} eventKey="0" onClick={this.getAccordianIconChange}>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="mt-2 ml-4 color_blue text-uppercase cursor_pointer">Your Shortlisted Branches Analysis <i className={this.state.iconChangeprimary == "up" ? "fas fa-chevron-up float-right" : "fas fa-chevron-down  float-right"}></i></h6>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Toggle>

                                                <Accordion.Collapse eventKey='0'>
                                                    <Card.Body className='p-4'>
                                                        <React.Fragment>
                                                            {
                                                                this.state.getDifficultyLevelCollegesData.map((item, index) => {
                                                                    return (
                                                                        <Accordion eventKey="0">
                                                                            <Card className='mt-2 cursor_pointer'>
                                                                                <Accordion.Toggle className="bg-white" as={Card.Header} eventKey="0" onClick={this.getAccordianIconChange2}>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <h6 className="mt-2 color_sky_blue">{item.branch_name} <i className={this.state.iconChangesecondary == "up" ? "fas fa-chevron-up float-right" : "fas fa-chevron-down  float-right"}></i></h6>
                                                                                        </Col>

                                                                                    </Row>
                                                                                </Accordion.Toggle>
                                                                                <Accordion.Collapse eventKey="0">
                                                                                    <Card.Body className="p-1">
                                                                                        <table className='table table-bordered table_custom'>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th className=''>Difficult</th>
                                                                                                    <th className=''>Moderate</th>
                                                                                                    <th className=''>Easy</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <>
                                                                                                    {

                                                                                                        item.values.length > 0 ?
                                                                                                            (
                                                                                                                item.values.map(item2 => {
                                                                                                                    return (
                                                                                                                        <tr>
                                                                                                                            <td className='color_dark_red'>
                                                                                                                                {
                                                                                                                                    item2.diff == "" ?
                                                                                                                                        ("-")
                                                                                                                                        :
                                                                                                                                        (item2.diff)
                                                                                                                                }
                                                                                                                            </td>

                                                                                                                            <td className='color_yash'>
                                                                                                                                {
                                                                                                                                    item2.moda == "" ?
                                                                                                                                        ("-")
                                                                                                                                        :
                                                                                                                                        (item2.moda)
                                                                                                                                }
                                                                                                                            </td>
                                                                                                                            <td className='color_light_green'>
                                                                                                                                {
                                                                                                                                    item2.easy == "" ?
                                                                                                                                        ("-")
                                                                                                                                        :
                                                                                                                                        (item2.easy)
                                                                                                                                }
                                                                                                                            </td>

                                                                                                                        </tr>
                                                                                                                    )
                                                                                                                })
                                                                                                            )
                                                                                                            :
                                                                                                            (<tr><td colspan="3">No Data Available</td></tr>)
                                                                                                    }
                                                                                                </>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </Card.Body>
                                                                                </Accordion.Collapse>
                                                                            </Card>
                                                                        </Accordion>
                                                                    )
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                        </Accordion>
                                    </React.Fragment>

                                </Col>
                            </Row>
                        )
                        :
                        ("")
                }

                {
                    this.state.orderPriorityDetails.length > 0 ?
                        (
                            <>
                                <Row>
                                    <Col sm={12} className='mt-2'>
                                        <DraggablePriorityOrderSection deleteRecordModal={this.deleteRecordModal} onDragEndPriortyOrder={this.onDragEndPriortyOrder} data={this.state.orderPriorityDetails} />

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Button type="button" className={this.state.disabled_button == false ? "btn btn-blue-color mt-3 mb-4 center-auto text-uppercase" : "btn btn-blue-color mt-3 mb-4 center-auto text-uppercase disabled_button_cursor"} disabled={this.state.disabled_button} onClick={(e) => { this.updateAndGetDetails(e, "update") }}>Update Priority Order
                                            <span className={this.state.orderspinnerBlockShow == "show" && this.state.deleteRecordSpinnerShow != "show" ? "spinner-border text-primary text-center loading-img-center width_align ml-3" : ""}></span>
                                        </Button>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <div className='mt-2 mb-4 block_refer_cls'>
                                            <Row>
                                                <Col sm={4}>
                                                    <Image src={DownloadWOImage} alt="PDF Image" className='center-auto'></Image>
                                                </Col>
                                                <Col sm={8}>
                                                    <p className='refer_text'>
                                                        Congratulations! You have completed the hard yard.Now download this list for your WEB OPTION
                                                    </p>
                                                    <ExcelContainer stateData={this.state.orderPriorityDetails} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )
                        :
                        ("")
                }

                <DeleteRecordModal
                    deleteBranchCollegeRecord={this.deleteBranchCollegeRecordData}
                    show={this.state.deleteModal}
                    stateData={this.state}
                    onHide={this.onHideDeleteModal}
                />
            </Container>
        )
    }
}

export default withRouter(withApollo(compose(
    graphql(PRIMARY_SAVE, {
        name: "saveStudentWebOptions"
    }),
    graphql(ORDER_WISE_SHORT_LIST, {
        name: "saveOrderwiseShortlistedData"
    }),
    graphql(ORDER_UPDATE_WISE_SHORT_LIST, {
        name: "updateOrderwiseShortlistedData"
    }),
    graphql(DELETE_BRANCH_COLLEGE_DETATILS, {
        name: "deleteStudentBranchCollegeDetails"
    })


)(NTAShortlistsection)));