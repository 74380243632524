export const subjectCardData = [
    {
        id: 1,
        subjectName: 'botany',
        title: 'Botany',
        Active: 'active',
        percentage: '60'
    },
    {
        id: 2,
        subjectName: 'zoology',
        title: 'Zoology',
        percentage: '80'
    },
    {
        id: 3,
        subjectName: 'physics',
        title: 'Physics',
        percentage: '65'
    },
    {
        id: 4,
        subjectName: 'chemistry',
        title: 'Chemistry',
        percentage: '70'
    }
];

export const learnpracticeData = [
    {
        id: '01',
        contentTitle: 'Laws of Motion',
        contentDescription: 'Start Practice Now',
        status: 'Yet to Start',
    },
    {
        id: '02',
        contentTitle: 'System of particles and rotational motion',
        contentDescription: 'Start Practice Now',
        accurancyPercentage: '32%',
        accurancy: 'accurancy'
    },
    {
        id: '03',
        contentTitle: 'Electrostatic Potential & Capacities',
        contentDescription: 'Start Practice Now',
        accurancyPercentage: '39%',
        accurancy: 'accurancy'
    },
    {
        id: '04',
        contentTitle: 'Motion in a plane',
        contentDescription: 'Start Practice Now',
        accurancyPercentage: '45%',
        accurancy: 'accurancy'
    },
    {
        id: '05',
        contentTitle: 'Work, Energy & Power',
        contentDescription: 'Start Practice Now',
        accurancyPercentage: '50%',
        accurancy: 'accurancy'
    }
];

export const currentExamData = [
    {
        id: 1,
        ExamName: 'CurrentExam',
        currentExamImg: require('../../../images/preparationLifecircle/current_exam.svg'),
        currentExamName: 'Current Exam',
        currentExamEdgeImg: require('../../../images/preparationLifecircle/Non_Medical_Insurance.png'),
        currentExamText: 'None Assigned',
        currentExamSchedule: 'Scheduled today'
    },
    {
        id: 2,
        ExamName: 'UpcommingExam',
        currentExamImg: require('../../../images/preparationLifecircle/upcoming_exam.svg'),
        currentExamName: 'Upcoming Exam',
        currentExamEdgeImg: require('../../../images/preparationLifecircle/Non_Medical_Insurance.png'),
        currentExamText: 'None Assigned',
        currentExamSchedule: 'Scheduled'
    }
];