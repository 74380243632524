import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import * as Cookies from "es-cookie";
import { withApollo } from "react-apollo";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import NeetJeePreviousQuestionsSection from '../components/ChapterWiseQuestions/NeetJeePreviousQuestionsSection'

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

class NeetJeePreviousQuestions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            questionsData: [],
            showLoading: false
        }
    }

    componentDidMount() {
        this.showQuestions()
        this.addStudentAnalytics()
    }

    showQuestions = async () => {
        this.setState({ showLoading: true })
        const result = await this.props.client.query({
            query: gql`
            query($exam_id:Int,$chapter_id:Int ,$topic_id:Int){
                getChapterWiseGroupedQuestions(exam_id: $exam_id, chapter_id: $chapter_id,topic_id:$topic_id) {
                    id
                    slno
                    question_name
                    option1
                    option2
                    option3
                    option4
                    explanation
                    answer
                    qtype
                    list2type
                    list1type
                    year
                    pexamtype
	compquestion
                    mat_question
                  }
            }
            `,
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                chapter_id: parseInt(this.props.history.location.state.chapterId),
                topic_id: this.props.history.location.state.topic_id != 0 && this.props.history.location.state.topic_id != undefined ? parseInt(this.props.history.location.state.topic_id) : 0
            },
            fetchPolicy: "network-only"
        })

        if (result.data.getChapterWiseGroupedQuestions.length > 0) {
            this.setState({
                questionsData: result.data.getChapterWiseGroupedQuestions,
                showLoading: false
            })
        } else {
            this.setState({
                questionsData: [],
                showLoading: false

            })
        }
    }
    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 245,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    render() {


        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars
                        headerBottom={this.state.headerBottomImg}
                        onClick={() => this.menuToggler()}
                    />                 </div>
                <div className="main-wrapper">
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper" style={{ paddingTop: 10, minHeight: "80vh" }}>
                        {
                            this.state.showLoading == true ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <NeetJeePreviousQuestionsSection
                                        questionsData={this.state.questionsData}
                                    />
                                )
                        }
                    </div>
                </div>
            </div>

        )
    }
}
export default withRouter(withApollo(compose(

    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),

)(NeetJeePreviousQuestions)));