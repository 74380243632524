import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import NewTopicsSection from '../components/learn_practice/subjects/NewTopicsSection'
import ChepterHeaderSection from '../components/learn_practice/top_header/ChepterHeaderSection';
import * as Cookies from "es-cookie";
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';


const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

class NewTopics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submitError: "",
        }
    }
    componentDidMount = () => {
        this.addStudentAnalytics();
        const title = GoogleAnalyticsArray[0].Topics_View;
        ReactGA.pageview('/student/subject/topics', ["rizee"], title);
    }
    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 27,
            source: 2
        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");
        const getTopics = this.props.getTopics;
        const loading2 = getTopics.loading;
        const error2 = getTopics.error;

        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper">
                    {(loading2 == true) && (<PreloaderTwo />)}
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0">
                        {
                            !loading2 && (
                                <React.Fragment>
                                    <ChepterHeaderSection
                                        type="topic"
                                        getTopics={getTopics.getTopics}
                                        getData={this.props.history.location.state != undefined ? this.props.history.location.state : ""}
                                    />
                                    <Container fluid={true}>

                                        <NewTopicsSection
                                            getTopics={getTopics.getTopics}
                                            getChapters={this.props.history.location.state != undefined ? this.props.history.location.state : ""} />

                                    </Container>
                                </React.Fragment>


                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(compose(
    graphql(gql` 
    query($mobile: String!,
        $chapter: Int!) {
            getTopics(mobile: $mobile,
        chapter: $chapter){
            id
            subject
            subject_name
            topicWisePreviousQuestionsCount
            topic
            chapter
            chapter_name
            practice_percentage
            last_timestamp
            accuracy
            weightage
            total_questions
            attempted_questions
            error_questions
            enabled
            practice_unanswered
            practice_wrong_answered
            practice_correct
            exam_unanswered
            exam_wrong_answered
            exam_correct
            topicGraph{
                 value
                 correct
                 wrong
                 timestamp              
           }
        }
    }
    `,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    chapter: parseInt(props.history.location.state.ocid)

                },
                fetchPolicy: 'cache-and-network'
            }),
            name: "getTopics"
        }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
)(NewTopics));
