import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import RepeatersRevisionSectionBlock from '../components/Neet_Rise_Course/RepeatersRevisionSectionBlock';
import HomeNavbar from '../components/home/HomeNavbar';

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';

const FETCH_SERVICE_DATA_FOR_REPEATERS_CLASSES = gql` 
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String
    ){
        getServiceData(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type){
            data
            }
        }
    `;
class RepeatersRevision extends Component {
    constructor(props) {
        super(props)

        this.state = {
            toggled: Cookies.get("toggle")
        }
    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }
        
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} pageType={""}/>
                </div>
                <div className="main-wrapper">
                
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0" style={{ minHeight: "50vh" }}>
                        <RepeatersRevisionSectionBlock
                            repeatersClassesDetails={this.props.repeatersClassesDetails}
                        />
                    </div>
                </div>
            </div>

            )
    }
}

export default withRouter(compose(
    graphql(FETCH_SERVICE_DATA_FOR_REPEATERS_CLASSES  , {
            options: props => ({
                variables: {
                    exam_id : parseInt(Cookies.get("examid")),
                    service_type : Cookies.get("examid") == 1 ? "NEET_REPEATERS_LIVE_CLASSES" : "JEE_2022_LIVE_CLASSES",
                    service_sub_type: "ALL"
                    
                },
                fetchPolicy: 'network-only'
            }),name: "repeatersClassesDetails"
        })
       
        )(RepeatersRevision));

