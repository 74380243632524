import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import * as Cookies from "es-cookie";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withApollo } from "react-apollo";
import { withRouter, Link } from "react-router-dom";
import { Container, Row, Col, Nav, Tab, Image } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import BookstoreModuleSection from '../components/BookstoreModule/BookstoremoduleSection'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar';
import rizeelogo from '../../images/rizee_bs.png';
import '../components/BookstoreModule/_bookstoremodule.scss';

const getURL = gql`
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String
    ){
        getServiceData(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type){
            url
            }
        }
    `;

const FETCH_GETSERIES = gql` 
query($mobile: String,$exam_group_id: Int) {
    getGroupBasedExams(mobile: $mobile,exam_group_id: $exam_group_id){
        id
        exam_name
        exam_type
        exam_model_id
        exam_session_id
        sub_exam_type
        start_time
        end_time
        is_completed
        title
        short_name
        amount
        speed
        accuracy
        correct_marks
        negative_marks
        total_marks
        is_purchased
        exam_started
        exam_expired
        syllabus
        exam_duration
        uid
        is_scheduled
        is_registered
        syllabus_desc
        is_started
        is_started_mobile
        class_id
        exam_class_id
        subject_id
        enabled_XI
        enabled_XII
        class_session_id
}
}
`;

const MODULE_TOUR = gql`
query($exam_id:Int ,$id:Int,$module_name:String){
    getModuleNavigation(exam_id:$exam_id, id:$id, module_name:$module_name){
		icon_path
        navigation_url
  }
}
`;

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;


class BookStoreModule extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggled: "wrapper sidebar-enable",
            submitError: "",
            headerBottomImg: {
                type: "bookstore_module",
            },
        }
    }

    componentDidMount() {
        this.addStudentAnalytics()
    }

    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 233,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    render() {

        const getServiceData = this.props.getServiceData
        const loading1 = getServiceData.loading
        const error1 = getServiceData.error;

        const getGroupBasedExams = this.props.getGroupBasedExams;
        const loading2 = getGroupBasedExams.loading
        const error2 = getGroupBasedExams.error

        const getModuleNavigation = this.props.getModuleNavigation;
        const loading3 = getModuleNavigation.loading;
        const error3 = getModuleNavigation.error;

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }

        if (error3 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }

        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        let globalsubjects = "";
        if (JSON.parse(localStorage.getItem("globalsubjects")) != "") {
            globalsubjects = JSON.parse(localStorage.getItem("globalsubjects"));
        }
        else {
            this.props.history.push("/student/login");
        }


        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }
        return (
            <div className={Cookies.get("toggle")}>
                <div className="student main-wrapper">
                    <div className="student header-area bookstore">
                        <ScrollTopNavbar
                            headerBottom={this.state.headerBottomImg}
                            onClick={() => this.menuToggler()} />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0">
                        <Container fluid={true}>
                            {
                                loading1 || loading2 || loading3 ?
                                    (
                                        <Container>
                                            <Row>
                                                <Col sm={12}>
                                                    <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                                </Col>
                                            </Row>
                                        </Container>
                                    )
                                    :
                                    (
                                        <BookstoreModuleSection
                                            getServiceData={getServiceData.getServiceData}
                                            getGroupBasedExams={getGroupBasedExams.getGroupBasedExams}
                                            getModuleNavigation={getModuleNavigation.getModuleNavigation}
                                        />
                                    )
                            }

                        </Container>
                    </div>

                    <div className="Book_store">
                        <footer className='bs_footer web_footer'>
                            <div className='container resp_view'>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <h4 className='foo_des_web text-center  mt-2'>To gain access to all the features </h4>
                                    </div>
                                    <div className='col-sm-4'>
                                        <Image src={rizeelogo} className="r_logo" />
                                    </div>
                                    {/* <div className='col-sm-4'>
                                        <button className="tour_button" onClick={() => this.modalaFun()}>Start Tour</button>
                                    </div> */}
                                    <div className='col-sm-8'>
                                        {/* <p className='subs_text text-center'>Nahh iam confident</p> */}
                                        <Link to={Cookies.get("examid") == 1 || Cookies.get("examid") == 2 ? "/student/package" : "/student/plans_section"} style={{ textDecoration: 'none' }}>
                                            <button className="subscribe_btn">Subscribe Now</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>

                    <div className='Book_store'>
                        <footer className='bs_footer resp_view mobile_footer'>
                            <div className='container'>
                                <div className=''>
                                    <h4 className='foo_des text-center mt-2'>To gain access to all the features</h4>
                                </div>
                                <div className='d-flex flex-row'>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div>
                                                <Image src={rizeelogo} className="r_logo" />
                                            </div>
                                        </div>
                                        <div className='col-sm-12'>
                                            <div className=''>
                                                <Link to={Cookies.get("examid") == 1 || Cookies.get("examid") == 2 ? "/student/package" : "/student/plans_section"}>
                                                    <button className="subscribe_btn_mob">Subscribe Now</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        )
    }
}


export default withApollo(withRouter(
    compose(
        graphql(getURL, {
            options: props => ({
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    service_type: "Rizee_Partnership",
                    service_sub_type: "ALL"
                },
                fetchPolicy: "network-only",
            }), name: "getServiceData"
        }),
        graphql(FETCH_GETSERIES,
            {
                options: props => ({
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_group_id: Cookies.get("examid") == "3" ? 68 : Cookies.get("examid") == "6" ? 69 : Cookies.get("examid") == "7" ? 70 : Cookies.get("examid") == "8" ? 71 : Cookies.get("examid") == "1" ? 66 : Cookies.get("examid") == "2"? 67 : Cookies.get("examid") == "9"? 81 :Cookies.get("examid") == "10"? 82  : 83
                    },
                    fetchPolicy: 'network-only'
                }), name: "getGroupBasedExams"
            }),
        graphql(MODULE_TOUR,
            {
                options: props => ({
                    variables: {
                        exam_id: parseInt(Cookies.get("examid")),
                        id: 54,
                        module_name: "Rizee_Partnership"
                    },
                    fetchPolicy: 'network-only'
                }), name: "getModuleNavigation"
            }),
        graphql(UPDATE_STUDENT_ANALYTICS, {
            name: "submitpt"
        }),
    )(BookStoreModule)
));
