import React, { Component } from 'react';
import { Row, Col, Card, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import './_eamcethome.scss';
import LiveImg from '../../../images/homeImages/live_img.png'
import Previous from '../../../images/homeImages/previous.png'
import SyllImg from '../../../images/homeImages/syllabus.png';
import BluePrt from '../../../images/homeImages/blue_prnt.png';
import ShortNts from '../../../images/homeImages/short_notes.png'
import Exercise from '../../../images/homeImages/exc.png'
import UserRestrictionAlertInstitute from './UserRestrictionAlertInstitute';
import { Link } from 'react-router-dom';
import * as Cookies from "es-cookie";
import LockSimple from '../../../images/LockSimple.png'

import ipe_lec from '../../../images/ipe_lec.png';
import Eamcet_shortNotes from '../../../images/eam_shortnotes.png';
import Eamcet_Quiz from '../../../images/eam_quiz.png';
import Eamcet_PreviousTest from '../../../images/eam_pt.png';
import Eamcet_MockTest from '../../../images/eam_mock_test.png';
import Eamcet_CrashCourse from '../../../images/eam_crashcourse.png';
import Eamcet_BluePrint from '../../../images/eam_blueprint.png';
import Eamcet_RankPredictor from '../../../images/rankpredictor_home.png'
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import Predictor from '../../../images/college_branch.png';
import Foundation from '../../../images/foundation.png';
import LongTerm from '../../../images/long-term.png';
import VideoPopupSection from './VideoPopupSection';

class EamcetHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userRestionModalShow: false,
            userVideoModalShow: false,
            videoUrl: "",
            videoTitle: ""
        };
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }
    modalaFun = (type) => {
        let isStudentUserValid = ""
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }

        if (Cookies.get("institution_id") == 30 && isStudentUserValid.current_plan_id != 23) {
            if (type == "blueprint") {

                this.props.history.push({
                    pathname: "/student/BluePrint"
                });
            }
            else if (type == "exam") {
                this.props.history.push({
                    pathname: "/student/exams"
                });
            }
        }

        else {
            this.setState({
                userRestionModalShow: true,
            });
        }

    }

    overvieVideoModalPopup = (videoTitle, videoUrl) => {
        this.setState({
            userVideoModalShow: true,
            videoUrl: videoUrl,
            videoTitle: videoTitle
        });


    }

    componentDidMount = () => {
        const title = GoogleAnalyticsArray[0].Eamcet_Home;
        ReactGA.pageview('/student/home', ["rizee"], title);

    }

    render() {
        let isuserValid = JSON.parse(
            this.props.isStudentUserValid.user_access_restictions
        );
        return (
            <div className='eamcet_home_section pt-4'>
                <Row>


                    {
                        Cookies.get("examid") == "3" || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                            (
                                Cookies.get("student_userlevel") != "1" ? (
                                    isuserValid.ST_IPELectures2022 == true ? ("") : (
                                        Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? (
                                            <Col sm={4} className="mb-3">
                                                <Card className="block_white">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col sm={8}>
                                                                <h4 className="color_213046 font-18">College &amp; Branch Predictor</h4>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <Card.Img variant="top" className="eamcet_home_img" src={Predictor} />
                                                            </Col>
                                                        </Row>
                                                        <p className="text_pos font-16 color_4C6487">
                                                            Now you can predict the best colleges and branches for your web options with your Rank / Marks, Reservation Category.
                                                        </p>

                                                        <Row>
                                                            <Col sm={6}>
                                                                <Link to="/student/college-rank-predictor" >
                                                                    <Button type="button" className="btn btn-block btn-eamcet btn_pos mt-4 f-14" >
                                                                        View Predictor
                                                                    </Button>
                                                                </Link>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Understand College &amp; Branch Predictor</Tooltip>}>
                                                                    <i class="fa fa-video mt_1_5 video_icon_css float-right" onClick={() => this.overvieVideoModalPopup("College &amp; Branch Predictor", "qRTjqv97gDs")}></i>
                                                                </OverlayTrigger>

                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ) : ("")
                                    )) : (
                                    ""
                                )
                            )
                            :
                            ("")
                    }

                    {
                        Cookies.get("examid") == "3" || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                            (
                                Cookies.get("student_userlevel") != "1" ? (
                                    isuserValid.ST_RankPredictor == true ? ("") : (
                                        <Col sm={4} className='mb-3'>
                                            <Card className="block_white">
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <h4 className="color_213046 font-18">Rank Predictor</h4>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_RankPredictor} />
                                                        </Col>
                                                    </Row>
                                                    <p className="text_pos font-16 color_4C6487">
                                                        Predict your EAMCET rank based on your EAMCET anticipated marks    </p>
                                                    <Link to="/student/eamcet-rankPredictor" >
                                                        <Button type="button" className="btn btn-block btn-eamcet mt-4" >
                                                            View Rank Predictor
                                                        </Button>
                                                    </Link>

                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )) : (
                                    isuserValid.ST_RankPredictor == true ? ("") : (

                                        <Col sm={4} className='mb-3'>
                                            <Card className="block_white">
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <h4 className="color_213046 font-18">Rank Predictor</h4>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_RankPredictor} />
                                                        </Col>
                                                    </Row>
                                                    <p className="text_pos font-16 color_4C6487">
                                                        Predict your EAMCET rank based on your EAMCET anticipated marks    </p>
                                                    <Link to="/student/eamcet-rankPredictor" >
                                                        <Button type="button" className="btn btn-block btn-eamcet mt-4" >
                                                            View Rank Predictor
                                                        </Button>
                                                    </Link>

                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))
                            )
                            :
                            ("")
                    }
                    {
                        Cookies.get("examid") == "3" || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                            (
                                Cookies.get("student_userlevel") != "1" ? (
                                    isuserValid.FoundationCourse == true ? ("") : (
                                        <Col sm={4} className="mb-3">
                                            <Card className="block_white">
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <h4 className="color_213046 font-18">Foundation Course</h4>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Card.Img variant="top" className="eamcet_home_img" src={Foundation} />
                                                        </Col>
                                                    </Row>
                                                    <p className="text_pos font-16 color_4C6487">
                                                        Understand every concept from your academics to buid a strong foundation with the help of live classes, daily notes and tests.
                                                    </p>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <Link to="foundation-course/home" >
                                                                <Button type="button" className="btn btn-eamcet btn_pos mt_4 btn-primary" >
                                                                    View  Course
                                                                </Button>
                                                            </Link>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Understand Foundation Course</Tooltip>}>
                                                                <i class="fa fa-video mt_4 video_icon_css float-right" onClick={() => this.overvieVideoModalPopup("Understand Foundation Course", "mkDs8B7_ZXA")}></i>
                                                            </OverlayTrigger>

                                                        </Col>
                                                    </Row>


                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )) : (
                                    isuserValid.FoundationCourse == true ? ("") : (

                                        <Col sm={4} className="mb-3">
                                            <Card className="block_white">
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <h4 className="color_213046 font-18">Foundation Course</h4>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Card.Img variant="top" className="eamcet_home_img" src={Foundation} />
                                                        </Col>
                                                    </Row>
                                                    <p className="text_pos font-16 color_4C6487">
                                                        Understand every concept from your academics to buid a strong foundation with the help of live classes, daily notes and tests.
                                                    </p>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <Link to="foundation-course/home" >
                                                                <Button type="button" className="btn btn-eamcet btn_pos mt_4 btn-primary" >
                                                                    View  Course
                                                                </Button>
                                                            </Link>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Understand Foundation Course</Tooltip>}>
                                                                <i class="fa fa-video mt_4 video_icon_css float-right" onClick={() => this.overvieVideoModalPopup("Understand Foundation Course", "mkDs8B7_ZXA")}></i>
                                                            </OverlayTrigger>

                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                )
                            )
                            :
                            ("")


                    }
                    {/* {
                        Cookies.get("examid") == "3" || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                            (
                                Cookies.get("student_userlevel") != "1" ? (
                                    isuserValid.EAMCET_LongTerm == true ? ("") : (
                                        <Col sm={4} className="mb-3">
                                            <Card className="block_white">
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <h4 className="color_213046 font-18">Long Term Course</h4>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Card.Img variant="top" className="eamcet_home_img" src={LongTerm} />
                                                        </Col>
                                                    </Row>
                                                    <p className="text_pos font-16 color_4C6487">
                                                        Thorough explanations on IPE core concepts for better understanding through recorded classes.
                                                    </p>
                                                    <Link to="eamcet-longterm-course/home" >
                                                        <Button type="button" className="btn btn-block btn-eamcet btn_pos mt_4 btn-primary" >
                                                            View Long Term Course
                                                        </Button>
                                                    </Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )) : (
                                    isuserValid.EAMCET_LongTerm == true ? ("") : (

                                        <Col sm={4} className="mb-3">
                                            <Card className="block_white">
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <h4 className="color_213046 font-18">Lont Term Course</h4>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Card.Img variant="top" className="eamcet_home_img" src={LongTerm} />
                                                        </Col>
                                                    </Row>
                                                    <p className="text_pos font-16 color_4C6487">
                                                        Thorough explanations on IPE core concepts for better understanding through recorded classes.
                                                    </p>
                                                    <Link to="eamcet-longterm-course/home" >
                                                        <Button type="button" className="btn btn-block btn-eamcet btn_pos mt_4" >
                                                            View Long Term Course
                                                        </Button>
                                                    </Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                )
                            )
                            :
                            ("")


                    } */}

                    {
                        Cookies.get("student_userlevel") != "1" ? (
                            isuserValid.ST_ShortNotes == true ? ("") : (
                                <Col sm={4} className='mb-3'>
                                    <Card className="block_white">
                                        <Card.Body>
                                            <Row>
                                                <Col sm={8}>
                                                    <h4 className="color_213046 font-18">Blue Print</h4>
                                                </Col>
                                                <Col sm={4}>
                                                    <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_BluePrint} />
                                                </Col>
                                            </Row>
                                            <p className="text_pos font-16 color_4C6487">
                                                Accurate plan for your {Cookies.get("examid") == "9" || Cookies.get("examid") == "10" || Cookies.get("examid") == "11" ? "KCET" : "EAMCET"}  journey with a cumulative of top-tier Subject/ Chapter wise weightage
                                            </p>
                                            <Link to="/student/StateBluePrint" >
                                                <Button type="button" className="btn btn-block btn-eamcet btn_pos mt-4" >
                                                    View Blue Print
                                                </Button>
                                            </Link>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            )) : (
                            isuserValid.ST_ShortNotes == true ? ("") : (

                                <Col sm={4} className='mb-3'>
                                    <Card className="block_white">
                                        <Card.Body>
                                            <Row>
                                                <Col sm={8}>
                                                    <h4 className="color_213046 font-18">Blue Print</h4>
                                                </Col>
                                                <Col sm={4}>
                                                    <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_BluePrint} />
                                                </Col>
                                            </Row>
                                            <p className="text_pos font-16 color_4C6487">
                                                Accurate plan for your {Cookies.get("examid") == "9" || Cookies.get("examid") == "10" || Cookies.get("examid") == "11" ? "KCET" : "EAMCET"} journey with a cumulative of top-tier Subject/ Chapter wise weightage</p>
                                            <Link to="/student/StateBluePrint" >
                                                <Button type="button" className="btn btn-block btn-eamcet btn_pos mt-4" >
                                                    View Blue Print
                                                </Button>
                                            </Link>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        )}
                    {
                        Cookies.get("examid") == "3" || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                            (
                                Cookies.get("student_userlevel") != "1" ? (
                                    isuserValid.ST_IPELectures2022 == true ? ("") : (
                                        <Col sm={4} className="mb-3">
                                            <Card className="block_white">
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <h4 className="color_213046 font-18">IPE Lectures</h4>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Card.Img variant="top" className="eamcet_home_img" src={ipe_lec} />
                                                        </Col>
                                                    </Row>
                                                    <p className="text_pos font-16 color_4C6487">
                                                        Thorough explanations on IPE core concepts for better understanding through recorded classes.
                                                    </p>
                                                    <Link to="IPEClasses" >
                                                        <Button type="button" className="btn btn-block btn-eamcet btn_pos mt_4" >
                                                            View Live Classes
                                                        </Button>
                                                    </Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )) : (
                                    isuserValid.ST_IPELectures2022 == true ? ("") : (

                                        <Col sm={4} className="mb-3">
                                            <Card className="block_white">
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <h4 className="color_213046 font-18">IPE Lectures</h4>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Card.Img variant="top" className="eamcet_home_img" src={ipe_lec} />
                                                        </Col>
                                                    </Row>
                                                    <p className="text_pos font-16 color_4C6487">
                                                        Thorough explanations on IPE core concepts for better understanding through recorded classes.
                                                    </p>
                                                    <Link to="IPEClasses" >
                                                        <Button type="button" className="btn btn-block btn-eamcet btn_pos mt_4" >
                                                            View Live Classes
                                                        </Button>
                                                    </Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                )
                            )
                            :
                            ("")
                    }


                    {
                        Cookies.get("student_userlevel") != "1" ? (
                            isuserValid.ST_Practice == true ? ("") : (
                                <Col sm={4} className='mb-3'>
                                    <Card className="block_white">
                                        <Card.Body>
                                            <Row>
                                                <Col sm={8}>
                                                    <h4 className="color_213046 font-18">Practice Sessions </h4>
                                                </Col>
                                                <Col sm={4}>
                                                    <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_PreviousTest} />
                                                </Col>
                                            </Row>
                                            <p className="text_pos font-16 color_4C6487">
                                                Arise as a perfectionist and get yourself ready confidently for final examinations with weightage oriented practice sessions.                                </p>
                                            <Link to="/student/practise_test" >
                                                <Button type="button" className="btn btn-block btn-eamcet mt-4" >
                                                    View Practice Sessions
                                                </Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )) : (
                            isuserValid.ST_Practice == true ? ("") : (
                                <Col sm={4} className='mb-3'>
                                    <Card className="block_white">
                                        <Card.Body>
                                            <Row>
                                                <Col sm={8}>
                                                    <h4 className="color_213046 font-18">Practice Sessions </h4>
                                                </Col>
                                                <Col sm={4}>
                                                    <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_PreviousTest} />
                                                </Col>
                                            </Row>
                                            <p className="text_pos font-16 color_4C6487">
                                                Arise as a Perfectionist and get yourself ready confidently for final examinations with weightage oriented practice sessions.                                </p>
                                            <Link to="/student/practise_test" >
                                                <Button type="button" className="btn btn-block btn-eamcet " >
                                                    View Practice Sessions
                                                </Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        )
                    }


                    {
                        Cookies.get("examid") == "3" || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                            (
                                Cookies.get("student_userlevel") != "1" ? (
                                    isuserValid.ST_ShortNotes == true ? ("") : (
                                        <Col sm={4} className='mb-3'>
                                            <Card className="block_white">
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <h4 className="color_213046 font-18">Short Notes</h4>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_shortNotes} />
                                                        </Col>
                                                    </Row>
                                                    <p className="text_pos font-16 color_4C6487">
                                                        Brief conceptual abstract of important chapters for revision                                </p>
                                                    <Link to="/student/StateShortNotes" >
                                                        <Button type="button" className="btn btn-block btn-eamcet btn_pos mt_4" >
                                                            View Short Notes
                                                        </Button>
                                                    </Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )) : (
                                    isuserValid.ST_ShortNotes == true ? ("") : (

                                        <Col sm={4} className='mb-3'>
                                            <Card className="block_white">
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <h4 className="color_213046 font-18">Short Notes</h4>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_shortNotes} />
                                                        </Col>
                                                    </Row>
                                                    <p className="text_pos font-16 color_4C6487">
                                                        Brief conceptual abstract of important chapters for revision                                </p>
                                                    <Link to="/student/StateShortNotes" >
                                                        <Button type="button" className="btn btn-block btn-eamcet btn_pos mt_4" >
                                                            View Short Notes
                                                        </Button>
                                                    </Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                )
                            )
                            :
                            ('')
                    }

                    {
                        Cookies.get("examid") == "9" || Cookies.get("examid") == "10" || Cookies.get("examid") == "11" ?
                            (
                                <Col sm={4} className='mb-3'>
                                    <Card className="block_white">
                                        <Card.Body>
                                            <Row>
                                                <Col sm={8}>
                                                    <h4 className="color_213046 font-18">Short Notes</h4>
                                                </Col>
                                                <Col sm={4}>
                                                    <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_shortNotes} />
                                                </Col>
                                            </Row>
                                            <p className="text_pos font-16 color_4C6487">Brief conceptual abstract of important chapters for revision</p>
                                            <Link to="/student/short-notes" >
                                                <Button type="button" className="btn btn-block btn-eamcet btn_pos mt_3_2" >
                                                    View Short Notes
                                                </Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                            :
                            ("")
                    }

                    {
                        Cookies.get("student_userlevel") != "1" ? (
                            isuserValid.ST_PreviousTests == true ? ("") : (
                                <Col sm={4} className="mb-3">
                                    <Card className="block_white">
                                        <Card.Body>
                                            <Row>
                                                <Col sm={8}>
                                                    <h4 className="color_213046 font-18">Previous Paper Test </h4>
                                                </Col>
                                                <Col sm={4}>
                                                    <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_PreviousTest} />
                                                </Col>
                                            </Row>
                                            <p className="text_pos font-16 color_4C6487">
                                                An absolute collection of  {Cookies.get("examid") == "9" || Cookies.get("examid") == "10" || Cookies.get("examid") == "11" ? "30+" : "2560"} previous years question papers for your strategical preparations.                                 </p>
                                            <Link to="/student/StatePreviousPapers" >
                                                <Button type="button" className="btn btn-block btn-eamcet btn_pos mt-4" >
                                                    View Previous Paper
                                                </Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )) : (
                            isuserValid.ST_PreviousTests == true ? ("") : (

                                <Col sm={4} className="mb-3">
                                    <Card className="block_white">
                                        <Card.Body>
                                            <Row>
                                                <Col sm={8}>
                                                    <h4 className="color_213046 font-18">Previous Paper Test </h4>
                                                </Col>
                                                <Col sm={4}>
                                                    <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_PreviousTest} />
                                                </Col>
                                            </Row>
                                            <p className="text_pos font-16 color_4C6487">
                                                An absolute collection of {Cookies.get("examid") == "9" || Cookies.get("examid") == "10" || Cookies.get("examid") == "11" ? "30+" : "2560"} previous years question papers for your strategical preparations.                                 </p>
                                            <Link to="/student/StatePreviousPapers" >
                                                <Button type="button" className="btn btn-block btn-eamcet btn_pos mt-4" >
                                                    View Previous Paper
                                                </Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>)
                        )}


                    {
                        Cookies.get("student_userlevel") != "1" ? (
                            isuserValid.ST_MockTests == true ? ("") : (
                                <Col sm={4} className="mb-3">
                                    <Card className="block_white">
                                        <Card.Body>
                                            <Row>
                                                <Col sm={8}>
                                                    <h4 className="color_213046 font-18">Mock Tests</h4>
                                                </Col>
                                                <Col sm={4}>
                                                    <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_MockTest} />
                                                </Col>
                                            </Row>
                                            <p className="text_pos font-16 color_4C6487">
                                                Weekly State - wide Mock Tests to predict your Rank Range. Access the Premium Mock Test to uplift your scores.                                </p>
                                            <Link to="/student/Mocktests" >
                                                <Button type="button" className="btn btn-block btn-eamcet btn_pos mt_4" >
                                                    View Mock Tests
                                                </Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )) : (
                            isuserValid.ST_MockTests == true ? ("") : (
                                <Col sm={4} className="mb-3">
                                    <Card className="block_white">
                                        <Card.Body>
                                            <Row>
                                                <Col sm={8}>
                                                    <h4 className="color_213046 font-18">Mock Tests</h4>
                                                </Col>
                                                <Col sm={4}>
                                                    <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_MockTest} />
                                                </Col>
                                            </Row>
                                            <p className="text_pos font-16 color_4C6487">
                                                Weekly State - wide mock tests to predict your Rank Range. Access the Premium mock test to uplift your scores.                                </p>
                                            <Link to="/student/Mocktests" >
                                                <Button type="button" className="btn btn-block btn-eamcet btn_pos mt_4" >
                                                    View Mock Tests
                                                </Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}



                    {
                        Cookies.get("student_userlevel") != "1" ? (
                            isuserValid.ST_Quiz == true ? ("") : (
                                <Col sm={4} className='mb-3'>
                                    <Card className="block_white">
                                        <Card.Body>
                                            <Row>
                                                <Col sm={8}>
                                                    <h4 className="color_213046 font-18">Daily Quiz</h4>
                                                </Col>
                                                <Col sm={4}>
                                                    <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_Quiz} />
                                                </Col>
                                            </Row>
                                            <p className="text_pos font-16 color_4C6487">
                                                Calculate your understanding levels on the concepts and get a quick review for yourself by giving 15-min daily Quiz.                                </p>
                                            <Link to="/student/exams/quiz_exam" >
                                                <Button type="button" className="btn btn-block btn-eamcet mt-4" >
                                                    View Quiz
                                                </Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )) : (
                            isuserValid.ST_Quiz == true ? ("") : (
                                <Col sm={4} className='mb-3'>
                                    <Card className="block_white">
                                        <Card.Body>
                                            <Row>
                                                <Col sm={8}>
                                                    <h4 className="color_213046 font-18">Daily Quiz</h4>
                                                </Col>
                                                <Col sm={4}>
                                                    <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_Quiz} />
                                                </Col>
                                            </Row>
                                            <p className="text_pos font-16 color_4C6487">
                                                Calculate your understanding levels on the concepts and get a Quick review for yourself by giving 15-min daily Quiz.                                </p>
                                            <Link to="/student/exams/quiz_exam" >
                                                <Button type="button" className="btn btn-block btn-eamcet mt-4" >
                                                    View Quiz
                                                </Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        )}




                    {
                        Cookies.get("student_userlevel") == "1" ? (
                            <Col sm={4} className='mb-3'>
                                <Card className="block_white">
                                    <Card.Body>
                                        <Row>
                                            <Col sm={8}>
                                                <h4 className="color_213046 font-18">Schedule Exam</h4>
                                            </Col>
                                            <Col sm={4}>
                                                <Card.Img variant="top" className="eamcet_home_img" src={Eamcet_CrashCourse} />
                                            </Col>
                                        </Row>
                                        <p className="text_pos font-16 color_4C6487">
                                            Speed up your revision with Live classes, daily assignments, 15 Min - Live quizzes and weekly scheduled exams and score more than you expect.

                                        </p>
                                        <Link to="/student/exams/schedule-exam" >
                                            <Button type="button" className="btn btn-block btn-eamcet mt-4" >
                                                Start Exam
                                            </Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : ("")

                    }

                </Row>
                <VideoPopupSection
                    show={this.state.userVideoModalShow}
                    onHide={() => this.setState({ userVideoModalShow: false })}
                    videoUrl_data={this.state.videoUrl}
                    videoTitle_data={this.state.videoTitle}
                />
            </div >
        )
    }
}
export default withRouter(compose(
)(EamcetHome));




















