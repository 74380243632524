import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { Row, Col, Nav, Card, CardGroup, Tab, Image, Button, Container, Dropdown, Media } from 'react-bootstrap'
import moment from 'moment';
import * as Cookies from "es-cookie";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import { get } from 'jquery';


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import './quiz_exam.scss'
import Slash from '../../../../images/Line-10.png'
import Availablewhite from '../../../../images/available.white.png'
import Availableblue from '../../../../images/available.blue.png'

import Completedwhite from '../../../../images/completed.white.png'

import Completedblue from '../../../../images/completed.blue.png'

import Missedwhite from '../../../../images/missed.white.png'

import Missedblue from '../../../../images/missed.blue.png'

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../../pages/GoogleAnalytics';


const FETCH_SESSION_DATA = gql`
  query(
          $mobile: String!, $exam_session_id: ID) {
            getStudentExamSessions(mobile: $mobile, exam_session_id: $exam_session_id){
              id
              correct_marks
              negative_marks
              total_marks
              speed
             subject_report{
                id
                subject
                correct
                wrong
                not_answered
                pMarks
                nMarks
                accuracy
                total_time
                in_time
                less_time
                over_time
                speed

                observations{
                    theoryObservation
                    qtypeObservation
                    complexityObservation
                }
               }
               chapter_report{
                subject
                subject_name
                chapter_name
                correct
                wrong
                not_answered
                total
                accuracy
              }
              correct_vs_complexity{
                complexity
                in_time
                less_time
                over_time
                in_total_time
                less_total_time
                over_total_time
              }
              wrong_vs_complexity{
                complexity
                in_time
                less_time
                over_time
                in_total_time
                less_total_time
                over_total_time
              }
              error_report{
                error
                error_name
                count
                error_subject_count{
                    id
                    subject
                    count
                }
              }
              qtype_report{
                question_type
                question_type_name
                accuracy
                correct
                wrong
                not_answered
                total_time
                qtype_correct_report{
                    id
                    subject
                    count 
                }
                qtype_wrong_report{
                    id
                    subject
                    count 
                }
                qtype_skipped_report{
                    id
                    subject
                    count 
                }
                
              }
              theory_report{
                question_theory_name
                correct
                wrong
                not_answered
                accuracy
                total_time
              }
            }
      }
`;
class QuizExamSection extends Component {
    constructor(props) {
        super(props)
        let sampleArray = [];
        let ongoing = [];
        let upcomig = [];
        let completed = [];
        //let nowdate = [];
        let upcommingexams = [];
        let currentexams = [];
        let compexams = [];


        this.state = {
            subtype: "1",
            mainsActive: "active",
            advanceActive: "",
            blockType: "available",
            arrowDirection: 'f078',
            currentTime: moment().unix()

        }
    }

    // handleSelect(val) {
    //     console.log("val",)
    //     this.setState({
    //         blockType: val,

    //     })
    // }
    handleSelect = (val) => {
        this.setState({
            blockType: val,
            arrowDirection: "f077"
        })
       

    }



    startExam = (data) => {

        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        localStorage.setItem("resumeExam", false);
        localStorage.setItem("subexamtype", this.state.subtype);
        localStorage.setItem("examtype_pattern", "quiz_exam");


        if (isMobile == true && Cookies.get("institution_id") == 30) {
            window.location.replace("/student/subject/exam");
        } else {
            window.open("/student/subject/exam", "_blank")
        }

    }


    resumeExam = (data) => {
        console.log("this.state.subtype", data.exam_name, data.exam_name);
        
        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        localStorage.setItem("subexamtype", this.state.subtype);
        localStorage.setItem("examtype_pattern", "quiz_exam");
        localStorage.setItem("resumeExam", true);

        // this.props.history.push({
        //     pathname: "/student/subject/series_test",

        // })
        if (isMobile == true && Cookies.get("institution_id") == 30) {
            window.location.replace("/student/subject/exam");
        } else {
            window.open("/student/subject/exam", "_blank")
        }
    }

    componentDidMount() {
        const title = GoogleAnalyticsArray[0].Quiz_Exams;
        ReactGA.pageview('/student/exams/quiz_exam', ["rizee"], title);

        let islive = this.props.getQuizExams.find((a) => a.exam_started == true && a.is_completed == false);
        if (islive != undefined) {
            const end_time = islive.end_time;
            const currentTime = this.state.currentTime;
            //console.log("comislive", end_time, currentTime);
            this.timer = setInterval(() => {
                this.setState({ currentTime: moment().unix() });

                if (currentTime >= end_time) {
                    console.log("comislive", end_time, currentTime);
                    clearInterval(this.timer);
                }

            }, 1000);
        }



    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }



    subtypeClick = (type) => {
        console.log("type", type);
        this.setState({
            subtype: type
        });


        if (type == "1") {
            this.setState({
                mainsActive: "active",
                advanceActive: ""
            });

        } else {
            this.setState({
                advanceActive: "active",
                mainsActive: ""
            });
        }

    }

    handleResultFunction = (id) => {
        console.log("id",id)
        console.log("thisss",this.props)

            this.props.history.push({            
                pathname: "/student/subject/quiz-exam-result",
                state: {
                    sessionid: id,
                    examtype: "quiz_exam",    
                }
            })        
    }

    render() {
        
        const getStudentExamSessions = this.props.getStudentExamSessions
        console.log("getStudentExamSessions", getStudentExamSessions)

        console.log("currentState", this.state);
        const examname = this.props.studentGlobals.exams.find((a) => a.id == Cookies.get("examid"));
        const nowdate = moment().unix();
        const endofdate = moment(moment(moment().unix() * 1000).endOf('day').unix() * 1000).unix();

        console.log("props.getQuizExams", this.props.getQuizExams);
        const upcommingexams = this.props.getQuizExams.filter((data) => parseInt(data.start_time) > endofdate && data.is_completed == false);
        console.log("upcommingexams", upcommingexams);
        let currentexams = this.props.getQuizExams?.filter(data => (((nowdate > data.start_time && nowdate <= data.end_time && data.is_completed == false) || (nowdate < data.start_time))))
        console.log("currentexams", currentexams);
        const compexams = this.props.getQuizExams.filter((data) => data.is_completed == true);

        const expiredexams = this.props.getQuizExams.filter((data) => nowdate > data.end_time && data.is_completed == false);

        // const results=this.props.getStudentExamSessions
        // console.log("results",this.props.getStudentExamSessions)
        console.log("expiredexams", expiredexams);
        console.log("compexams", compexams)
        let currentTime_currentSec = Math.round((new Date()).getTime() / 1000);
     
        return (

            <section className='quiz_section'>
            <Container>
                <Row>
                    <Col sm={4} className='mt-4 mb-3'>
                    <Card className={this.state.blockType == "available" ? "active quiz_block_view" : " quiz_block_view"} onClick={() => this.handleSelect("available")}>
                        <Card.Body>
                            <img className="mt-2"  src={Availableblue}  alt="Available Quiz"/>
                            <Card.Title className="f-24 text-center mt-3">Available 
                                <span className="quiz-arrow float-right mt-1">
                                    <i class="far fa-arrow-right"></i>
                                </span>
                            </Card.Title>   
                               
                        </Card.Body>
                       
                       
                        </Card>
                    </Col>
                    <Col sm={4} className='mt-4 mb-3'>
                        <Card className={this.state.blockType == "completed" ? "active quiz_block_view" : " quiz_block_view"} onClick={() => this.handleSelect("completed")}>
                            <Card.Body>
                                <img className="mt-2"  src={Completedblue}  alt="completed Quiz"/>
                                <Card.Title className="f-24 text-center mt-3">Completed 
                                    <span className="quiz-arrow float-right mt-1">
                                        <i class="far fa-arrow-right"></i>
                                    </span>
                                </Card.Title>   
                                
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col sm={4} className='mt-4 mb-3'>
                        <Card className={this.state.blockType == "missed" ? "active quiz_block_view" : " quiz_block_view"} onClick={() => this.handleSelect("missed")}>
                            <Card.Body>
                                <img className="mt-2"  src={Missedblue}  alt="Missed Quiz"/>
                                <Card.Title className="f-24 text-center mt-3">Missed 
                                    <span className="quiz-arrow float-right mt-1">
                                        <i class="far fa-arrow-right"></i>
                                    </span>
                                </Card.Title>   
                                
                            </Card.Body>
                        </Card>
                       
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card as={Card.Body} className='mt-4'>
                            {
                                this.state.blockType == "available" ? 
                                   
                                    <Row className=''>
                                         <Col sm={12}>
                                            <h5 className='font-weight-bold mb-3'>Available Quizzes : </h5>
                                        </Col>
                                       {
                                           currentexams.length>0 ?
                                           (
                                            currentexams.map(item => 
                                               
                                                       <Col sm={6} className='mt-2'>
                                                           <Card className='border_pad_card'>
                                                               <Card.Body className='p-2'>
                                                                   <h6 className='text-center mt-2 mb-3'>{item.exam_name}</h6> 
                                                                   <p className='mt-2 mb-1'>Duration : <b>{item.exam_duration}</b></p>
                                                                   <p className='mt-2 mb-1'>Syllabus : <b>{item.syllabus}</b></p>
                                                                   {
                                                                        moment.unix(item.start_time).format("DD-MMM-YYYY") == moment.unix(currentTime_currentSec).format("DD-MMM-YYYY") && parseInt(item.start_time - 600) < parseInt(currentTime_currentSec) ? (

                                                                            item.is_started == true && currentTime_currentSec > item.start_time  ? (
                                                                                <p className='mt-2 mb-1'>Start Date : <b>{moment.unix(item.start_time).format("lll") }</b> <Button type="button" className='btn-custom-blue float-right f-14' onClick={() => this.resumeExam(item)}>Resume Quiz</Button>  </p> 

                                                                            ) : (
                                                                                <p className='mt-2 mb-1'>Start Date : <b>{moment.unix(item.start_time).format("lll") }</b> <Button type="button" className='btn-custom-blue float-right f-14' onClick={() => this.startExam(item)}>Start Quiz</Button>  </p> 

                                                                            )
                                                                        ) : (
                                                                            <p className='mt-2 mb-1'>Start Date : <b>{moment.unix(item.start_time).format("lll") }</b> <Button type="button" className='btn-custom-blue float-right f-14 cursor_disabled' disabled >Start Quiz</Button>  </p> 
                                                                        )
                                                                    }
                                                                   
                                                                   
                                                               </Card.Body>
                                                           </Card>
                                                       </Col>
                                                   
                                               )
                                           )
                                       :
                                        (<h6 className='text-center mt-2'>Next Quiz Available Soon</h6> )
                                       }
                                        
                                    </Row>

                                    : this.state.blockType == "completed" ?
                                        <Row className=''>
                                            <Col sm={12}>
                                            <h5 className='font-weight-bold mb-3'>Completed Quizzes : </h5>
                                        </Col>
                                        {
                                            compexams.length>0 ?
                                            (
                                                compexams.map(item => 
                                                
                                                        <Col sm={6} className='mt-2'>
                                                            <Card className='border_pad_card'>
                                                                <Card.Body className='p-2'>
                                                                    <h6 className='text-center mt-2 mb-3'>{item.exam_name}</h6> 
                                                                    <p className='mt-2 mb-1'>Duration : <b>{item.exam_duration}</b></p>
                                                                    <p className='mt-2 mb-1'>Syllabus : <b>{item.syllabus}</b></p>
                                                                    <p className='mt-2 mb-1'>Start Date : <b>{moment.unix(item.start_time).format("lll") }</b></p>
                                                                    <Row>
                                                                        <Col sm={8}>
                                                                            <Row>
                                                                                <Col sm={6} className='mt-2'>
                                                                                    <div className='box_1'>
                                                                                        <p className='text-center'>Your Score</p>
                                                                                        <h5 className="text-center">{item.marks_obtained}/{item.total_marks}</h5>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm={6} className='mt-2'>
                                                                                    <div className='box_1'>
                                                                                        <p className='text-center'>Avg Time/Q</p>
                                                                                        <h5 className="text-center">{item.speed}</h5>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col sm={4}>
                                                                            <Button type="button" className='btn-custom-blue float-right f-14 mt-4' onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results</Button>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                    
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    
                                                )
                                            )
                                        :
                                        (<h6 className='text-center mt-2'>You have not attempted any quiz </h6> )
                                        }
                                        
                                    </Row>
                                    
                                    : this.state.blockType == "missed" ?
                                        <Row className=''>
                                            <Col sm={12}>
                                            <h5 className='font-weight-bold mb-3'>Missed Quizzes : </h5>
                                        </Col>
                                        {
                                            expiredexams.length>0 ?
                                            (
                                                expiredexams.map(item => 
                                                
                                                        <Col sm={6} className='mt-2'>
                                                            <Card className='border_pad_card'>
                                                                <Card.Body className='p-2'>
                                                                    <h6 className='text-center mt-2 mb-3'>{item.exam_name}</h6> 
                                                                    <p className='mt-2 mb-1'>Duration : <b>{item.exam_duration}</b></p>
                                                                    <p className='mt-2 mb-1'>Syllabus : <b>{item.syllabus}</b></p>
                                                                    <p className='mt-2 mb-1'>Start Date : <b>{moment.unix(item.start_time).format("lll") }</b></p>
                                                                    
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    
                                                )
                                            )
                                        :
                                        (<h6 className='text-center mt-2'>No Missed Quiz Exams</h6> )
                                        }
                                        
                                    </Row>

                                     : ""
                                    
                                    
                            }
                        </Card> 
                    </Col>
                </Row>
               
                   

            </Container>
        </section>
        )
    }
}

export default withRouter((QuizExamSection));