import React, { Component } from 'react'
import { Row, Col, Tabs,Tab,Card,Table} from 'react-bootstrap';
import './_bluePrintexamanalysis.scss';
import BootstrapTable from 'react-bootstrap-table-next';

import * as Cookies from "es-cookie";
//// ApCheData

let ApCheData = [
          {
          YEAR: "FIRSTYEAR",
          CHAPTERNAME: "STOICHIOMETRY",
          NOOFQUESTIONS: "2",
        
      },
      {
          YEAR: "FIRSTYEAR",
          CHAPTERNAME: "STATES OF MATTER ",
          NOOFQUESTIONS: "2",
      },
      {
          YEAR: "FIRSTYEAR",
          CHAPTERNAME: "ATOMIC STRUCTURE",
          NOOFQUESTIONS: "1",
      },
      {
          YEAR: "FIRSTYEAR",
          CHAPTERNAME: "CHEMICAL BONDING AND MOLECULAR STRUCTURE",
          NOOFQUESTIONS: "2",
      },
      {
          YEAR: "FIRSTYEAR",
          CHAPTERNAME: "CHEMICAL THERMODYNAMICS",
          NOOFQUESTIONS: "1",
      },
      {
          YEAR: "FIRSTYEAR",
          CHAPTERNAME: "CHEMICAL EQUILIBRIUM",
          NOOFQUESTIONS: "1",
      },
      {
          YEAR: "FIRSTYEAR",
          CHAPTERNAME: "IONIC EQUILIBRIUM",
          NOOFQUESTIONS: "1",
      },
      {
          YEAR: "FIRSTYEAR",
          CHAPTERNAME: "CLASSIFICATION OF ELEMENTS AND PERIODICITY IN PROPERTIES",
          NOOFQUESTIONS: "2",
      },
      {
          YEAR: "FIRSTYEAR",
          CHAPTERNAME: "HYDROGEN",
          NOOFQUESTIONS: "1",
      },
      {
          YEAR: "FIRSTYEAR",
          CHAPTERNAME: "S-BLOCK ELEMENTS",
          NOOFQUESTIONS: "2",
      },
      {
          YEAR: "FIRSTYEAR",
          CHAPTERNAME: "P-BLOCK ELEMENTS",
          NOOFQUESTIONS: "2",
      },
  
      {
          YEAR: "FIRSTYEAR",
          CHAPTERNAME: "ENVIRONMENTAL CHEMISTRY",
          NOOFQUESTIONS: "1",
      },
  
      {
          YEAR:"FIRSTYEAR",
          CHAPTERNAME:"SOME BASIC PRINCIPLES OF ORGANIC CHEMISTRY",
          NOOFQUESTIONS:"3",
      },
  
      {
          YEAR:"FIRSTYEAR",
          CHAPTERNAME:"HYDROCARBONS",
          NOOFQUESTIONS:"2",
      },
  
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"SOLUTIONS",
          NOOFQUESTIONS:"1",
      },
  
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"ELECTRO CHEMISTRY",
          NOOFQUESTIONS:"1",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"SURFACE CHEMISTRY",
          NOOFQUESTIONS:"1",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"SOLID STATE",
          NOOFQUESTIONS:"1",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"CHEMICAL KINETICS",
          NOOFQUESTIONS:"1",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"CO-ORDINATION COMPOUNDS",
          NOOFQUESTIONS:"2",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"P-BLOCK ELEMENTS",
          NOOFQUESTIONS:"2",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"D AND F BLOCK ELEMENTS",
          NOOFQUESTIONS:"1",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"ORGANIC COMPOUNDS CONTAINING HALOGENS",
          NOOFQUESTIONS:"2",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"ORGANIC COMPOUNDS CONTAINING OXYGEN",
          NOOFQUESTIONS:"",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"1) ALCOHOLS, PHENOLS AND ETHERS",
          NOOFQUESTIONS:"2",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"2) ALDEHYDES AND KETONES",
          NOOFQUESTIONS:"2",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"3) CARBOXYLIC ACIDS",
          NOOFQUESTIONS:"1",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"ORGANIC COMPOUNDS CONTAINING NITROGEN",
          NOOFQUESTIONS:"1",
      },
      {
          YEAR:"SECONDYEAR",
          CHAPTERNAME:"BIOMOLECULES",
          NOOFQUESTIONS:"1",
      }
      

  ];

///// TsCheData

  let TsCheData = [
    {
    YEAR: "FIRSTYEAR",
    CHAPTERNAME: "STOICHIOMETRY",
    NOOFQUESTIONS: "2",
  
},
{
    YEAR: "FIRSTYEAR",
    CHAPTERNAME: "STATES OF MATTER ",
    NOOFQUESTIONS: "2",
},
{
    YEAR: "FIRSTYEAR",
    CHAPTERNAME: "ATOMIC STRUCTURE",
    NOOFQUESTIONS: "1",
},
{
    YEAR: "FIRSTYEAR",
    CHAPTERNAME: "CHEMICAL BONDING AND MOLECULAR STRUCTURE",
    NOOFQUESTIONS: "2",
},
{
    YEAR: "FIRSTYEAR",
    CHAPTERNAME: "CHEMICAL THERMODYNAMICS",
    NOOFQUESTIONS: "1",
},
{
    YEAR: "FIRSTYEAR",
    CHAPTERNAME: "CHEMICAL EQUILIBRIUM",
    NOOFQUESTIONS: "1",
},
{
    YEAR: "FIRSTYEAR",
    CHAPTERNAME: "IONIC EQUILIBRIUM",
    NOOFQUESTIONS: "1",
},
{
    YEAR: "FIRSTYEAR",
    CHAPTERNAME: "CLASSIFICATION OF ELEMENTS AND PERIODICITY IN PROPERTIES",
    NOOFQUESTIONS: "1",
},
{
    YEAR: "FIRSTYEAR",
    CHAPTERNAME: "HYDROGEN",
    NOOFQUESTIONS: "1",
},
{
    YEAR: "FIRSTYEAR",
    CHAPTERNAME: "S-BLOCK ELEMENTS",
    NOOFQUESTIONS: "2",
},
{
    YEAR: "FIRSTYEAR",
    CHAPTERNAME: "P-BLOCK ELEMENTS",
    NOOFQUESTIONS: "2",
},

{
    YEAR: "FIRSTYEAR",
    CHAPTERNAME: "ENVIRONMENTAL CHEMISTRY",
    NOOFQUESTIONS: "1",
},

{
    YEAR:"FIRSTYEAR",
    CHAPTERNAME:"SOME BASIC PRINCIPLES OF ORGANIC CHEMISTRY",
    NOOFQUESTIONS:"3",
},

{
    YEAR:"FIRSTYEAR",
    CHAPTERNAME:"HYDROCARBONS",
    NOOFQUESTIONS:"2",
},

{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"SOLUTIONS",
    NOOFQUESTIONS:"1",
},

{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"ELECTROCHEMISTRY",
    NOOFQUESTIONS:"1",
},
{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"SURFACE CHEMISTRY",
    NOOFQUESTIONS:"1",
},
{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"SOLID STATE",
    NOOFQUESTIONS:"1",
},
{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"CHEMICAL KINETICS",
    NOOFQUESTIONS:"1",
},
{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"CO-ORDINATION COMPOUNDS",
    NOOFQUESTIONS:"2",
},
{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"P-BLOCK ELEMENTS",
    NOOFQUESTIONS:"2",
},
{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"D AND F BLOCK ELEMENTS",
    NOOFQUESTIONS:"1",
},
{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"ORGANIC COMPOUNDS CONTAINING HALOGENS",
    NOOFQUESTIONS:"1",
},
{
    // YEAR:"SECONDYEAR",
    CHAPTERNAME:"ORGANIC COMPOUNDS CONTAINING OXYGEN",
    // NOOFQUESTIONS:"0",
},
{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"1) ALCOHOLS, PHENOLS AND ETHERS",
    NOOFQUESTIONS:"2",
},
{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"2) ALDEHYDES AND KETONES",
    NOOFQUESTIONS:"2",
},
{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"3) CARBOXYLIC ACIDS",
    NOOFQUESTIONS:"1",
},
{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"ORGANIC COMPOUNDS CONTAINING NITROGEN",
    NOOFQUESTIONS:"1",
},
{
    YEAR:"SECONDYEAR",
    CHAPTERNAME:"BIOMOLECULES",
    NOOFQUESTIONS:"1",
}


];

  class ChemistryDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    
    colFunction(){

        let columns = [
            {
                dataField:'YEAR',
                text:'Year',
                sort: true
            },
            {
               dataField:'CHAPTERNAME',
               text:'CHAPTERNAME',
               sort: true
           },
           {
               dataField:'NOOFQUESTIONS',
               text:'NOOFQUESTIONS',
               sort: false
           },
       
        ];
        return columns;
       }
           
           render() {
               return (
               <Row>
                   <Col>

                   {
                        Cookies.get("examid") == 3 || Cookies.get("examid") ==7 ? (
                            <Card>
                                <BootstrapTable
                                    keyField="id"
                                    columns={this.colFunction()}
                                    data={ApCheData}
                                    defaultSorted={true}
                                    wrapperClasses="table-responsive"
                                    bootstrap4
                                />
                            </Card>

                        ) : null
                    }

                    {
                        Cookies.get("examid") == 6 || Cookies.get("examid") == 8 ? (
                            <Card>
                                <BootstrapTable
                                    keyField="id"
                                    columns={this.colFunction()}
                                    data={TsCheData}
                                    defaultSorted={true}
                                    wrapperClasses="table-responsive"
                                    bootstrap4
                                />
                            </Card>

                        ) : null
                    }
                   </Col>
               </Row>
               
               )
           }

}
export default ChemistryDataSection