import React, { Component } from 'react'
import * as Cookies from "es-cookie";
import { withRouter } from "react-router-dom";
import InnerOverAllChapterStrength from "./InnerOverAllChapterStrength";

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import * as compose from 'lodash.flowright';

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

class OverAllChapterStrength extends Component {
    constructor(props){
        super(props)
        this.state={
            submitError:""

        }
    }
    componentDidMount = () => {
        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Learn_Practice_Dashboard;
        ReactGA.pageview('/student/subject/chapter-status',["rizee"], title);
    }
    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 19,
            source: 2
        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
    render() {

        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        return (
            <InnerOverAllChapterStrength
                getData={this.props.history.location.state != undefined ? this.props.history.location.state : ""} />
        )
    }
}



export default withRouter(compose(
        graphql(UPDATE_STUDENT_ANALYTICS, {
            name: "submitpt"
        }),
)(OverAllChapterStrength));