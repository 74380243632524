import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import BookmarkHeaderSection from '../components/bookmarks/bookmark_videos/BookmarkHeaderSection'
import BookmarkSingleShortNoteMaterialRevisionsSection from '../components/bookmarks/bookmark_shortnotes_materials/BookmarkSingleShortNoteMaterialRevisionsSection';


import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as Cookies from "es-cookie";
import * as compose from 'lodash.flowright';
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import { withRouter } from 'react-router';



class BookmarkSingleShortNoteMaterialRevisions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: "wrapper sidebar-enable",
            views: ""
        }
    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
         if (toggled === "wrapper") {
             this.setState({toggled:"wrapper sidebar-enable"});
             Cookies.set("toggle", "wrapper sidebar-enable");
         } else {
             this.setState({toggled:"wrapper"});
             Cookies.set("toggle", "wrapper");
         }
     };
    componentDidMount() {
        window.addEventListener('contextmenu',
            event => event.preventDefault());


        // window.addEventListener("mousedown", e => {
        //     e.preventDefault();
        //     e.returnValue = "false";

        // });
        window.addEventListener("selectstart", e => {
            e.preventDefault();
            e.returnValue = "false";

        });
    }

    componentWillUnmount() {

        window.removeEventListener('contextmenu', () => { });
        // window.removeEventListener("mousedown", () => { });
        window.removeEventListener("selectstart", () => { });

    }
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");
        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper">
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper">
                        {/* <BookmarkHeaderSection
                            studentGlobals={studentGlobals.studentGlobals}
                            getSubjects={getSubjects.getSubjects}
                            phandleSelectInputChange={this.handleSelectInputChange}
                            pTaghandleSelectInputChange={this.TaghandleSelectInputChange}
                            stateData={this.state}
                        /> */}
                        <BookmarkSingleShortNoteMaterialRevisionsSection
                            studentGlobals={studentGlobals}
                            stateData={this.state}
                            getData={this.props.history.location.state != undefined ? this.props.history.location.state : ""}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(BookmarkSingleShortNoteMaterialRevisions);
