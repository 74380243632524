import React from "react";
import { Fragment } from "react";
import { Row, Col, Card, Button, CardColumns,Image,Container } from "react-bootstrap";
import "./subscriptionPlan.scss";
import TickmarkLogo from "./../../../../src/images/CircleWavyCheck.png";
import XmarkLogo from "./../../../../src/images/x-circle.png";
import moment from "moment";
function SubscriptionPlan(props) {
let studentPlan = props.getStudentPlansData
let expirydays = props.isStudentUserValid.isStudentUserValid.expiry_date;
let currentime = moment().unix();
const days = Math.round(parseInt(expirydays - currentime) / (24 * 60 * 60));
console.log(props)
    return (
        <React.Fragment>
            <section className="student-package ">
                    <div className="breadcrumb-content mb-">
                        <Container>
                            <Row className="justify-content-center">
                                <Col xl={6} lg={6} md={8} sm={12} className="mx-auto">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Image src={require('../../../images/subscribe_img.png')} className="mx-2" alt="img" fluid />
                                    </div>
                                </Col>
                                <Col sm={12}>
                                    <h5 className="text-white">Subscription Plans</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    </section>
            
            <div className="container main-container">
                <Card className="plan-card al">
                    <Card.Body className="padd_5">
                        <Card.Title className="SubscribeTitle">
                            Simple <span className="PlansCover">Plans</span> For Everyone
                        </Card.Title>
                        {/* <Card.Text className="APTSPricingTitle">
                            AP EAMCET & TS EAMCET Pricing
                        </Card.Text> */}


                        <div className="padd_4">
                            <Row>
                                <Col className="mt-2">
                                    <Card className="sub-plan sub-plan-free col-12" >
                                        <Card.Body className="pad_res_5">
                                            <h4 className="text-center">Free Trail Features</h4>
                                            <h6 className="text-center f-12 color_yash_new">
                                            To get you started with your EAMCET Preparation
                                            </h6>
                                           
                                            <Card.Text className="price align-middle ">
                                                <span className="price-text">  Free</span>
                                            </Card.Text>
                                            
                                            <Card.Text className="features-trail f1">
                                                <Card.Img variant="top" className="circle-Feature" src={TickmarkLogo} />
                                                <span className="feature-text">Access to limited Live & Recorded Classes Only</span>
                                            </Card.Text>

                                            <Card.Text className="features-trail f2">
                                                <Card.Img variant="top" className="circle-Feature" src={TickmarkLogo} />
                                                <span className="feature-text">Access to Scholarship Tests Only</span>
                                            </Card.Text>

                                            <Card.Text className="features-trail f3">
                                                <Card.Img variant="top" className="circle-Feature" src={TickmarkLogo} />
                                                <span className="feature-text">Access to limited Short Notes Only</span>
                                            </Card.Text>

                                            <Card.Text className="features-trail f4">
                                                <Card.Img variant="top" className="circle-Feature" src={TickmarkLogo} />
                                                <span className="feature-text">Access to Previous Year Question Papers Only</span>
                                            </Card.Text>
                                            {(props.isStudentUserValid.isStudentUserValid.current_plan_id == "23")?(<Card.Text className="price align-middle  ">
                                                <span className="newPrice price-text current-plan">Current Plan</span>
                                            </Card.Text>):('')
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="mt-2">
                                    <Card className="sub-plan justify-content-around col-12" >
                                        <Card.Body className="pad_res_5">
                                            <h4 className="text-center">Subscription Features</h4>
                                            <h6 className="text-center f-12 color_yash_new">
                                            Complete Access of All Features to Help You Crack Eamcet 2021 
                                            </h6>
                                            
                                            {/* <Card.Text className="plan-expiry">
                                 <span className="plan-expire-60">60 Days</span>
                                 </Card.Text> */}
                                            <Card.Text className="price align-middle ">
                                                <span className="oldPrice price-text"> <s>₹ 9999</s></span>
                                                <span className="newPrice price-text">  Rs 4999/</span>
                                            </Card.Text>
                                            <Card.Text className="features-trail f1">
                                                <Card.Img variant="top" className="circle-Feature" src={TickmarkLogo} />
                                                <span className="feature-text">Complete Access to Live & Recorded Classes</span>
                                            </Card.Text>

                                            <Card.Text className="features-trail f2">
                                                <Card.Img variant="top" className="circle-Feature" src={TickmarkLogo} />
                                                <span className="feature-text">Live Doubt Solving</span>
                                            </Card.Text>

                                            <Card.Text className="features-trail f3">
                                                <Card.Img variant="top" className="circle-Feature" src={TickmarkLogo} />
                                                <span className="feature-text">Complete Access to Mock Tests, Error Exam & Live Grand Exams</span>
                                            </Card.Text>

                                            <Card.Text className="features-trail f4">
                                                <Card.Img variant="top" className="circle-Feature" src={TickmarkLogo} />
                                                <span className="feature-text">Complete Access to Short Notes, Detailed Notes, and Exercises</span>
                                            </Card.Text>
                                            <Card.Text className="features-trail f4">
                                                <Card.Img variant="top" className="circle-Feature" src={TickmarkLogo} />
                                                <span className="feature-text">Complete Access to BluePrint with Chapter-wise Weightage</span>
                                            </Card.Text>
                                            <Card.Text className="features-trail f4">
                                                <Card.Img variant="top" className="circle-Feature" src={TickmarkLogo} />
                                                <span className="feature-text">Complete Access to Previous Year Question Papers with Solutions</span>
                                            </Card.Text>
                                            {
                                                (props.isStudentUserValid.isStudentUserValid.current_plan_id != "23")?(<Card.Text className="price align-middle  ">
                                                <span className="newPrice price-text current-plan">Current Plan</span>
                                            </Card.Text>):(<Button variant="outline-" className="buy-now" disabled onClick={()=> props.clickButtonBuyNow(studentPlan[0])}>Buy Now</Button>)
                                            }

                                        
                
                                            
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );

}

export default SubscriptionPlan;