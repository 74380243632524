import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import { Row, Tabs, Tab, Card, Image, Button, Col, Table, Media, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Chemistry from '../../../images/chemistry_icon.png';
import Zoology from '../../../images/zoology_icon.png';
import Physics from '../../../images/physics_icon.png';
import Maths from '../../../images/maths_icon.png';
import Botany from '../../../images/botany_icon.png';
import Arrow from "../../../images/eamcet_arrow.png"
import './_stateShortNotes.scss'
import { withRouter } from "react-router-dom";
import * as compose from "lodash.flowright";
import EamcetRestrictionAlert from "../home/EamcetRestrictionAlert";



class StateShortNotesSection extends React.Component {
    constructor(props) {
        super(props)
        this.handleChapterChange = this.handleChapterChange.bind(this);
        this.state = {
            getSubjectsArr: [],
            selectedSubjectVal: "",
            onloadSubjectWiseChapters: 0,
            chapterValue: "",
            classData: { value: 1, label: 'Class-XI' },
            subscribeDetails: "",
            getChaptersArr: [],
            userRestionModalShow: false,

        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }


    componentDidMount = () => {
        if (this.props.getSubjectOrder != undefined || this.props.getSubjectOrder != null) {
            let selectedSubject = "";
            const getSubjects = this.props.getSubjectOrder.map((gmap, index) => {
                if (index == 0) {
                    selectedSubject = gmap.id
                    return { ...gmap, active: "active" }
                }
                else {
                    return { ...gmap, active: "" }
                }
            })
            this.setState({
                getSubjectsArr: getSubjects,
                selectedSubjectVal: selectedSubject,
            });
        }
    }


    subjectClassName(data) {
        let classname = "";
        if (data.subject == "Botany") {
            classname = `block_white botany mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Physics") {
            classname = `block_white physics mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Chemistry") {
            classname = `block_white chemistry mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Zoology") {
            classname = `block_white zoology mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Mathematics") {
            classname = `block_white maths mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Biology") {
            classname = `block_white botany mobile_card_eamcet ${data.active}`
        }
        return classname;
    }


    handleSelect = (subid) => {
        this.setState({ onloadSubjectWiseChapters: 1 });
        const getSubjects = this.state.getSubjectsArr.map((gmap, index) => {
            if (gmap.id == subid) {
                return { ...gmap, active: "active" }
            }
            else {
                return { ...gmap, active: "" }
            }
        });

        this.setState({
            getSubjectsArr: getSubjects,
            selectedSubjectVal: subid,
        });
    }

    selectHandleInputChange = (evalue) => {
        const value = evalue
        let labelval = ""

        if (value == "1") {
            labelval = "Class  XI"
        } else {
            labelval = "Class  XII"
        }
        this.setState({
            classData: {
                value: value,
                label: labelval
            },
        })
    }

    getSubjectName = (subid) => {
        let subjectName = "";
        if (subid == "1") {
            subjectName = "Botany";
        } else if (subid == "2") {
            subjectName = "Physics";
        } else if (subid == "3") {
            subjectName = "Chemistry";
        } else if (subid == "4") {
            subjectName = "Mathematics";
        } else if (subid == "5") {
            subjectName = "Zoology";
        } else if (subid == "6") {
            subjectName = "Biology"
        }
        return subjectName;
    }


    handleChapterChange = (event) => {
        this.setState({
            chapterValue: event.target.value
        })
    }

    handleSubscribeModal = () => {
        this.setState({
            userRestionModalShow: true,
        })
    }

    showShortNotesfunc = (chapterid) => {
        this.props.history.push({
            pathname: "/student/subject/start-learning",
            state: {
                subjectid: this.state.selectedSubjectVal,
                otid: "0",
                ocid: chapterid,
            }
        })
    }

    render() {
        let chaptersData = []

        chaptersData = this.props.getStateChapterNames.filter(item => item.id == this.state.selectedSubjectVal)

        if (this.state.classData.value == 1) {
            chaptersData = chaptersData[0]?.state_chapters.filter(item => item.class_id == 1)
        } else {
            chaptersData = chaptersData[0]?.state_chapters.filter(item => item.class_id == 2)
        }

        let keyword = this.state.chapterValue

        if (keyword !== '') {
            chaptersData = chaptersData.filter((item) => {
                return item.chapter.toLowerCase().startsWith(keyword.toLowerCase());
            });
        }

        return (
            <Container className='kcet-short-notes mt-2'>
                <Row>
                    <Col sm={12}>
                        <div className='subject-blocks-view'>
                            {
                                this.state.getSubjectsArr.map(sub =>
                                    <Media className={this.subjectClassName(sub)} onClick={() => this.handleSelect(sub.id)}>
                                        <Media.Body>
                                            <h5 className='mt-2 text-center mt-2'>{sub.subject}</h5>

                                        </Media.Body>
                                        {
                                            sub.id == "1" ?
                                                (<Image src={Botany} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "2" ?
                                                (<Image src={Physics} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "3" ?
                                                (<Image src={Chemistry} className="ml-4 " alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "5" ?
                                                (<Image src={Zoology} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "4" ?
                                                (<Image src={Maths} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "6" ?
                                                (<Image src={Botany} className="ml-4" alt="subject" />) : ("")
                                        }
                                    </Media>
                                )
                            }

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <ul className='list-inline class_type_li mt-4'>
                            <li className={this.state.classData.value == "1" ? "active" : ""} onClick={() => this.selectHandleInputChange("1")}>Class XI</li>
                            <li className={this.state.classData.value == "2" ? "active ml-4" : "ml-4"} onClick={() => this.selectHandleInputChange("2")}>Class XII</li>
                        </ul>
                    </Col>

                    <Col sm={6}>
                        <input
                            className='float-right search_bar mt-4'
                            type="search"
                            placeholder='  Search Chapter'
                            value={this.state.chapterValue}
                            onChange={this.handleChapterChange}
                        />
                        <i className='fa fa-search search-icon'></i>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className='mt-4'>
                        <h5> Short Notes- ( Subject : {this.getSubjectName(this.state.selectedSubjectVal)},  Class : {this.state.classData.value == 1 ? "XI" : "XII"} )</h5>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    {
                        chaptersData != "" && chaptersData != undefined && chaptersData.map((item, index) => {
                            return (
                                item.enabled == false ?
                                    (
                                        <Col sm={12} lg={6}>
                                            <Card className='chapter_card mt-4 cursor_pointer' onClick={() => this.showShortNotesfunc(item.chapter_id)}>
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={10}>
                                                            <h6 className='chapter_name mt-2'><span className='mr-2'>{index + 1}.</span>{item.chapter_name}</h6>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <img src={Arrow} className='arrow pos_sn_aligntment' />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                    :
                                    (
                                        <Col sm={12} lg={6}>
                                            <Card className='chapter_card mt-4 cursor_pointer' onClick={() => this.handleSubscribeModal()}>
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={10}>
                                                            <h6 className='chapter_name mt-2'><span className='mr-2'>{index + 1}.</span>{item.chapter_name}</h6>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <img src={Arrow} className='arrow pos_sn_aligntment' />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                            )
                        })
                    }
                </Row>
                <EamcetRestrictionAlert
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />
            </Container>
        )
    }
}

export default React.memo(withRouter(compose(
)(StateShortNotesSection)));