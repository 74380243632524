import React, { Component } from 'react'
import { Row, Col, Table, Image, Button, Container, Accordion, Card, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import { components } from 'react-select'
import * as Cookies from "es-cookie";
import moment from 'moment';
import "./_referFriend.scss";
import referImage from '../../../images/refer_an_friend.png'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import {
    FacebookShareButton,
    InstapaperShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
// import RewardsImage from "../../../images/modal-gift-box.png"
import RewardsImage from "../../../images/exams_logo.png"

class RewardsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Modal {...this.props} className='p-3 rewards_his_modal'
                size="lg" aria-labelledby="example-modal-sizes-title-lg" >
                <Modal.Header closeButton className='p-2'>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {
                                this.props.studentsData.length > 0 ?
                                (
                                    <div className='table-responsive'>
                                    <table id="rewards">
                                        <tr>
                                            <th>Name</th>
                                            <th>Registered date</th>
                                        </tr>
                                        {
                                            this.props.studentsData.map(item => (
                                                <tr>
                                                    <td>{item.name}</td>
                                                    <td>{moment.unix(item.timestamp).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                </tr>
                                            ))
                                        }
                                    </table>
                                </div>
                                )
                                :
                                (<h6 className='text-center no_rewards'>No referrals registered</h6>)
                            }
                           
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
        )
    }
}

export default RewardsModal
