import React, { Component } from 'react'
import { Row, Col, Tabs,Tab,Card,Table} from 'react-bootstrap';
import './_bluePrintexamanalysis.scss';
import BootstrapTable from 'react-bootstrap-table-next';

import * as Cookies from "es-cookie";


///   ApBipcData

let ApBipcData = [
    {
        CHAPTERNAME:"UNIT 1 DIVERSITY IN LIVING WORLD",
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "WHAT IS LIFE TO KINGDOM ANIMALIA ",
        NOOFQUESTIONS: "1",
      
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "BIODIVERSITY",
        NOOFQUESTIONS: "1",
      
    },
    {
        
        
        CHAPTERNAME: "STRUCTURAL ORGANIZATION IN ANIMALS SCOELOM",
      
    },
    {
       
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "LEVELS OF ORGANISATION",
        NOOFQUESTIONS: "1",
      
    },
    {
       
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "MULTICELLULARITY",
        NOOFQUESTIONS: "0",
      
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"SYMMETRY",
        NOOFQUESTIONS:"1",
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "COELOM",
        NOOFQUESTIONS: "1",
      
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "TISSUES",
        NOOFQUESTIONS: "1",

    },
    
    {
        
        
        CHAPTERNAME: "ANIMAL DIVERSITY 1-INVERTEBRATE PHYLUM",
        
      
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PHYLUM PORIFERA",
        NOOFQUESTIONS: "1",
      
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PHYLUM CNIDARIA",
        NOOFQUESTIONS: "0",
      
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PHYLUM CTENOPHORA",
        NOOFQUESTIONS: "0",
      
    },

    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PHYLUM PLATYHELMINTHES",
        NOOFQUESTIONS: "0",
      
    },

 
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PHYLUM NEMATODA",
        NOOFQUESTIONS: "0",
      
    },

    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PHYLUM ANNELIDA",
        NOOFQUESTIONS: "0",
      
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ARTHROPODA",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"MOLLUSCA",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ECHINODERMATA",
        NOOFQUESTIONS:"",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"PHYLUM HEMICHORDATA",
        NOOFQUESTIONS:"1",
    },

    {
        
        
        CHAPTERNAME: "UNIT-IV ANIMAL DIVERSITYII PHYLUM CHORDATA",
        
      
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"PHYLUM CHORDATA",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"SUBPHYLUM UROCHORDATA",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"SUBPHYLUM CEPHALOCHORDATA",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"SUPERCLASS AGNATHA",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"SUPERCLASS GNATHOSTOMATA",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"TETRAPODA",
        NOOFQUESTIONS:"2",
    },
    {
        CHAPTERNAME:"UNIT V LOCOMOTION AND REPRODUCTION IN PROTOZOA",
    },

    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "LOCOMOTION",
        NOOFQUESTIONS: "0",
      
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"FLAGELLAR AND CILIARY MOVEMENT",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"REPRODUCTION",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ASEXUAL",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"SEXUAL",
        NOOFQUESTIONS:"",
    },
    {
        
        CHAPTERNAME:"UNIT VI BIOLOGY IN HUMAN WELFARE",

    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"PARASITISM AND PARASITIC ADAPTATION",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"HEALTH AND DISEASE",
        NOOFQUESTIONS:"2",
    },


    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"BRIEF ACCOUNT OF PATHOGENECITY",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"DRUGS AND ALCOHOL ABUSE",
        NOOFQUESTIONS:"1",
    },
    {
        
        CHAPTERNAME:"UNIT VII PERIPLANETA AMERICANA COCKROACH",

    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"MORPHOLOGY",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ORGAN SYSTEMS",
        NOOFQUESTIONS:"0",
    },
    {
        
        CHAPTERNAME:"UNIT-VIII :ECOLOGY AND ENVIRONMENT",
        
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ORGANISMS AND ENVIRONMENT",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ECOSYSTEM",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ECOLOGICAL ADAPTATIONS",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"POPULATION INTERACTIONS",
        NOOFQUESTIONS:"1",
        
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ECOLOGICAL PYRAMIDS",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"NUTRIENT CYCLING",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"POPULATION ATTRIBUTES",
        NOOFQUESTIONS:"0",
    },
    
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ENVIRONMENTAL ISSUES",
        NOOFQUESTIONS:"2",
    },
    {
        
        CHAPTERNAME:"UNIT I HUMAN ANATOMY AND PHYSIOLOGY",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"IB BREATHING AND RESPIRATION",
        NOOFQUESTIONS:"1",
    },
    {
       
        CHAPTERNAME:"UNITII HUMAN ANATOMY AND PHYSIOLOGY",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"II A BODY FLUIDS AND CIRCULATION",
        NOOFQUESTIONS:"2",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"IIBEXCRETORY PRODUCTS AND THEIR ELIMINATION",
        NOOFQUESTIONS:"1",
    },
    {
        
        CHAPTERNAME:"UNIT III HUMAN ANATOMY AND PHYSIOLOGY",
       
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"III A MUSCULO SKELETAL SYSTEM",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"SKELETAL MUSCLE ULTRASTRUCTUTRE",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"CONTRACTILE PROTEINS",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"MUSCLE CONTRACTION",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"IIIB NEURAL CONTROL AND COORDINATION",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"NERVOUS SYTEM",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"GENERATION AND CONDUCTION OF NERVE IMPULSE",
        NOOFQUESTIONS:"0",
    },
    {
        
        CHAPTERNAME:"UNIT IV HUMAN ANATOMY AND PHYSIOLOGY",
    
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"IVA ENDOCRINE SYSTEM AND CHEMICAL  COORDINATION",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"IVB IMMUNE SYSTEM",
        NOOFQUESTIONS:"1",
    },
    {
        
        CHAPTERNAME:"UNIT V HUMAN REPRODUCTION",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"VA HUMAN REPRODUCTIVE SYSTEM",
        NOOFQUESTIONS:"2",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"VB REPRODUCTIVE SYSTEM",
        NOOFQUESTIONS:"1",
    },
    {
       
        CHAPTERNAME:"UNIT VI GENETICS",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"GENETICS",
        NOOFQUESTIONS:"1",
    },
    {
        
        CHAPTERNAME:"UNIT VIII APPLIED BIOLOGY",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"DAIRY MANAGEMENT ",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"ANIMAL BREEDING",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"BIOPHARMACY TECHNOLOGY",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"APPLICATION OF BIOTECHNOLOGY IN HEALTH",
        NOOFQUESTIONS:"1",
    },
    
  
  ];

  let TsBipcData = [
    {
        CHAPTERNAME:"DIVERSITY IN LIVING WORLD"
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "BIODIVERSITY",
        NOOFQUESTIONS: "2",
      
    },
    {
        
        
        CHAPTERNAME: "STRUCTURAL ORGANIZATION IN ANIMALS SCOELOM",
      
    },
    {
       
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "ANIMAL TISSUES",
        NOOFQUESTIONS: "1",
      
    },
    // {
       
    //     YEAR: "FIRSTYEAR",
    //     CHAPTERNAME: "ANIMAL TISSSUES",
    //     NOOFQUESTIONS: "1",
      
    // },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "COELOM",
        NOOFQUESTIONS: "1",
      
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "IMPORTANCE IF SYMMETRY",
        NOOFQUESTIONS: "1",

    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "LEVELS OF ORGANISATION",
        NOOFQUESTIONS: "0",
      
    },
    {
        
        
        CHAPTERNAME: "ANIMAL DIVERSITY 1-INVERTEBRATE PHYLUM",
        
      
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PHYLUM PORIFERA",
        NOOFQUESTIONS: "1",
      
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PHYLUM CNIDARIA & CTENOPHORA",
        NOOFQUESTIONS: "0",
      
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PHYLUM PLATHYELMINTHES & NEMATODA",
        NOOFQUESTIONS: "1",
      
    },

    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PHYLUM ANNELIDA ARTHROPODA",
        NOOFQUESTIONS: "0",
      
    },
    {
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "PHYLUM HEMICHORDATA",
        NOOFQUESTIONS: "1",
      
    },

    {
        
        
        CHAPTERNAME: "LOCOMOTION AND REPRODUCTION PROTOZOA ",
        
      
    },
    {
        
        YEAR: "FIRSTYEAR",
        CHAPTERNAME: "LOCOMOTION IN PROTOZOA",
        NOOFQUESTIONS: "0",
      
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"FLAGELLAR AND CILIARY MOVEMENT",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ASEXUAL REPRODUCTION",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"SEXUAL REPRODUCTION",
        NOOFQUESTIONS:"0",
    },
    {
        
        CHAPTERNAME:"PERIPLANETA AMERICANA",

    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"HABIT AND HABIT",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"EXTERNAL FEATURES(MORPHOLOGY)",
        NOOFQUESTIONS:"1",
    },


    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"LOCOMOTION",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ORGAN SYSTEM",
        NOOFQUESTIONS:"0",
    },
    {
        
        CHAPTERNAME:"ANIMAL DIVERSITY II PHYLUM CHORDATA",

    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"PHYLUM CHORDATA",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"SUBPHYLUM CEPHALOCHORDATA",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"SUBPHYLUM VERTEBRATA/CRANIATA",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"SUPERCLASS AGNATHA",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"SUPERCLASS GNATHOSTOMATA",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"TETRAPODA",
        NOOFQUESTIONS:"1",
    },
    {
        
        CHAPTERNAME:"BIOLOGY IN HUMAN WELFARE",
        
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"TOBACCO,DRUGS & ALCOHOL ABUSE",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"BRIEF ACCOUNT OF SOME OTHER DISEASES",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"HEALTH & DISEASE",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"PARASITISM AND PARASITIC ADAPTATIONS",
        NOOFQUESTIONS:"0",
    },
    {
       
        CHAPTERNAME:"ECOLOGY AND ENVIRONMENT",
        
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ORGANISMS AND ENVIRONMENT",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ECOSYSTEM---ELEMENTARY ASPECTS",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"POPULATION INTERACTIONS",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ECOSYSTEM AND THEIR COMPONENTS",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"FOOD CHAINS,FOODWEBS,PRODUCTIVITY,ENERGY FLOW",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"NUTRIENT CYCLES",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"POPULATION",
        NOOFQUESTIONS:"0",
    },
    {
        YEAR:"FIRSTYEAR",
        CHAPTERNAME:"ENVIRONMENT ISSUES",
        NOOFQUESTIONS:"2",
    },
    {
        
        CHAPTERNAME:"HUMAN ANATOMY AND PHYSIOLOGY I",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"BREATHING AND EXCHANGE OF GASES",
        NOOFQUESTIONS:"2",
    },
    {
        
        CHAPTERNAME:"HUMAN ANATOMY AND PHYSIOLOGY II",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"BODY FLUIDS AND CIRCULATION",
        NOOFQUESTIONS:"2",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"EXCRETORY PRODUCTS AND THEIR ELIMINATION",
        NOOFQUESTIONS:"1",
    },
    {
        
        CHAPTERNAME:"HUMAN ANATOMY AND PHYSIOLOGY III",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"MUSCULOSKEKETAL SYSTEM",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"NEURAL CONTROL AND COORDINATION",
        NOOFQUESTIONS:"2",
    },
    {
        
        CHAPTERNAME:"HUMAN ANATOMY AND PHYSIOLOGY IV",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"ENDOCRINE SYSTEM AND CHEMICAL COORDINATION",
        NOOFQUESTIONS:"2",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"IMMUNE SYTEM",
        NOOFQUESTIONS:"2",
    },
    {
        
        CHAPTERNAME:"HUMAN REPRODUCTION",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"HUMAN REPRODUCTIVE SYSTEM",
        NOOFQUESTIONS:"1",
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"REPRODUCTIVE HEALTH",
        NOOFQUESTIONS:"2",
    },
    {
        
        CHAPTERNAME:"GENETICS",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"GENETICS",
        NOOFQUESTIONS:"2",
    },
    {
        
        CHAPTERNAME:"ANIMAL BREEDING",
        
    },
    {
        YEAR:"SECONDYEAR",
        CHAPTERNAME:"ANIMAL BREEDING",
        NOOFQUESTIONS:"1",
    },
    
  ];
  class ZoologyDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    
    colFunction(){

        let columns = [
            {
                dataField:'YEAR',
                text:'Year',
                sort: true
            },
            {
               dataField:'CHAPTERNAME',
               text:'CHAPTERNAME',
               sort: true
           },
           {
               dataField:'NOOFQUESTIONS',
               text:'NO OF QUESTIONS',
               sort: false
           },
       
        ];
        return columns;
       }
           
           render() {
               return (
               <Row>
                   <Col>
                   {
                        Cookies.get("examid") == 7 ?(
                            <Card>
                                <BootstrapTable
                                    keyField="id"
                                    columns={this.colFunction()}
                                    data={ApBipcData}
                                    defaultSorted={true}
                                    wrapperClasses="table-responsive"
                                    bootstrap4
                                />
                            </Card>

                        ) : null
                    }

                    {
                        Cookies.get("examid") == 8 ? (
                            <Card>
                                <BootstrapTable
                                    keyField="id"
                                    columns={this.colFunction()}
                                    data={TsBipcData}
                                    defaultSorted={true}
                                    wrapperClasses="table-responsive"
                                    bootstrap4
                                />
                            </Card>

                        ) : null
                    }
                   </Col>
               </Row>
               
               )
           }

}

export default ZoologyDataSection