import React, { Component } from 'react'
import SideNavbar from '../components/navbars/SideNavbar'
import NavbarOne from '../components/navbars/NavbarOne'
import Preloader from '../components/preloader/Preloader'
import CategorySection from '../components/examinations/categories/CategorySection'
import Footer from '../components/footer/Footer'

class Category extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="left-side-menu">
                    <SideNavbar onClick={() => this.props.changeToggle()}/>
                </div>
                <div className="content-page">
                    <Preloader />
                    <NavbarOne onClick={() => this.props.changeToggle()} />
                    <div className="overlay" onClick={() => this.props.changeToggle()} />
                    <div className="main-content">
                        <CategorySection />
                    </div>
                    <Footer />
                </div>
            </React.Fragment>
        )
    }
}

export default Category
