import React, { Component } from 'react'
import Navbars from '../components/navbars/Navbars'
import AsideNavbar from '../components/navbars/AsideNavbar'
import SingleBlogSection from '../components/blog/SingleBlogSection'
import axios, { post } from "axios";
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import * as Cookies from "es-cookie";

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import { withRouter } from 'react-router-dom';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';


const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

class StudentSingleBlog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            singleBlog: "",
            submitError:""
            

        }

    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
         if (toggled === "wrapper") {
             this.setState({toggled:"wrapper sidebar-enable"});
             Cookies.set("toggle", "wrapper sidebar-enable");
         } else {
             this.setState({toggled:"wrapper"});
             Cookies.set("toggle", "wrapper");
         }
     };
    componentDidMount = () => {
        this.addStudentAnalytics();
        const title = GoogleAnalyticsArray[0].Blog_View;
        ReactGA.pageview('/student/student-blog/student-blog-view',["rizee"], title);
        let url = ``;
        if(this.props?.history?.location?.pathname != "/student/action/Blog" && this.props?.history?.location?.pathname != "/student/action/ST_Blog"){
            url = `https://rizee.in/blog/wp-json/wp/v2/posts/${this.props.history.location.state.blogid}?_fields=id,date,status,title,link,featured_media,content,prevpost,nextpost`
        }else{
            url = `https://rizee.in/blog/wp-json/wp/v2/posts/${this.props?.history?.location?.search?.slice(4)}?_fields=id,date,status,title,link,featured_media,content,prevpost,nextpost`
        }
        axios.get(url)
            .then(response => {
                console.log("SingleBlogSection", response);
                if (response.status == 200) {
                    this.setState({
                        singleBlog: response.data
                    });
                }
                else {
                    this.setState({
                        singleBlog: ""
                    });
                }

            })
            .catch(error => {
                console.log(error);
            });
    }
    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 92,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
    render() {
        console.log("welcome",this.props)
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper">
                    {this.state.singleBlog == "" ? (<PreloaderTwo />) : ("")}
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0">
                        {this.state.singleBlog != "" ? (
                            <SingleBlogSection singleBlog={this.state.singleBlog} />
                        ) : ("")}

                    </div>
                </div>
            </div>
        )

    }
}

export default
withRouter(
    compose(
            graphql(UPDATE_STUDENT_ANALYTICS, {
                name: "submitpt"
            }),
    )(StudentSingleBlog));