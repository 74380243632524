import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Image, Navbar, Dropdown, Form, FormControl, Button, Container, Media,Accordion } from 'react-bootstrap';
import * as Cookies from "es-cookie";
import { components } from 'react-select';
import '../_collegeRankPredictor.scss';
import SelectDropDown from '../../../../neetjee_guru/components/selectdropdown/SelectDropDown';
import MultiSelect from "react-multi-select-component";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withApollo } from "react-apollo";
import DraggableBranchSection from './DraggableBranchSection';
import DraggableCollegeSection from './DraggableCollegeSection';
import DraggablePriorityOrderSection from './DraggablePriorityOrderSection';
import ReferFriendImage from '../../../../images/refer_friend_sht.png'
import DownloadWOImage from '../../../../images/download_web_option.png';
import DeleteRecordModal from './deleteRecordModal.jsx';
import ExcelContainer from './excelContainer.jsx';


const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
};





const FETCH_SHORTLIST_BRANCH_COLLEGES = gql` 
    query($mobile: String,$exam_id:Int) {
        getShortlistedCollegesBranches(mobile: $mobile,exam_id:$exam_id){
            mobile
            branches{
                id
                branch_name
                order_no
                index_val
            }
            colleges{
                id
                college_name
                order_no
                index_val
            }
         }
    }
    `;




class ShortListPageSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconChange:"up",
            subCasteData_ret:[],
            orderPriorityDetails_ret:[],
        }
    }


    componentDidMount = async () => {
        if(this.props.getStudentWebOptions !=undefined && this.props.getStudentWebOptions.length>0){
            this.props.getOnloadSUbCasts();
            this.props.getOnloadPriorityOrder();
            this.props.getBranchWiseData();
            //getStudentBranchCollegeDetails
            
        }
           
    };

    

    getMainCastes() {
        let newArray = [];
        
        this.props.getMainCastes.map((item) => {
            if (item != undefined) {
                const newObj = {
                    value: item.id,
                    label: item.main_caste
                }
                newArray.push(newObj);
            }
        }
        )
        return newArray;
    };

    getAccordianIconChange = () => {
        if (this.state.iconChange == "up") {
            this.setState({ iconChange: "down" })
        } else {
            this.setState({ iconChange: "up" })
        }
    }

    referFriendRedirection =()=>{
         this.props.history.push("/student/referFriend")
    }

    render() {
        const getShortlistedCollegesBranches = this.props.getShortlistedCollegesBranches
        const loading2 = getShortlistedCollegesBranches.loading
        const error2 = getShortlistedCollegesBranches.error

        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }

        if(!loading2){
            if(this.props.stateData.uniqueBranchList.length == 0 || this.props.stateData.uniqueCollegesList.length == 0){
                if(getShortlistedCollegesBranches.getShortlistedCollegesBranches.length>0){
                    this.props.stateData.uniqueBranchList = getShortlistedCollegesBranches.getShortlistedCollegesBranches[0].branches;
                    this.props.stateData.uniqueCollegesList = getShortlistedCollegesBranches.getShortlistedCollegesBranches[0].colleges;

                    const newBranches = this.props.stateData.uniqueBranchList.filter(item2 => item2.index_val == 0).map(item => item.index_val)
                    const newColleges = this.props.stateData.uniqueCollegesList.filter(item2 => item2.index_val == 0).map(item => item.index_val)

                   
                    if(newBranches.length>0 || newColleges.length>0){
                        this.props.stateData.new_Branches_colleges = [...newBranches,...newColleges]
                    }
                    

                }
                
            }
           
        }

       console.log("new_Branches_colleges",this.props.stateData.orderPriorityDetails)

         
        
         let scastenewArray = [];
         this.props.stateData.subCasteData.map((item) => {
           
             if (item != undefined) {
                 const newObj = {
                     value: item.id,
                     label: item.name,
                 }
                 scastenewArray.push(newObj);
             }
 
         });

         console.log("scastenewArray",scastenewArray)
         let scasteselectall = true;
         let scastelabelledBY = "Select Sub Category";
         let scastedisablesearch = false;
         if (scastenewArray.length > 0) {
             scasteselectall = true;
             scastedisablesearch = false;
         }
         else {
             scastedisablesearch = true;
             scasteselectall = false;
             scastelabelledBY = "Select Sub Category"
         }


         

        return (
            <Container className="college-rank-predictor">
                    <Row className='justify-content-center'>
                        <Col sm={5}>
                            <ul className='list-inline exam_type_list_cls mt-4'>
                                <li className={this.props.stateData.examActiveType == "AP" ? "active" : ""} onClick={(e) => this.props.changeExamType(e,3)}>AP EAMCET 2023 - ENGG</li>
                                <li className={this.props.stateData.examActiveType == "TS" ? "active" : ""} onClick={(e) => this.props.changeExamType(e,6)}>TS EAMCET 2023 - ENGG</li>
                            </ul>
                        </Col>
                    </Row>
                    <Form>
                        <Row className='mt-2 justify-content-center' >
                            <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="colleges">
                                    <div className="custom-control custom-radio custom-control-inline margin_right0">
                                        <input type="radio" id="rankId" value="rank" name="input_pattern_type" className="custom-control-input" checked={this.props.stateData.rank_radio_box} onChange={(e) => this.props.handleSelectInputChange(e)} />
                                        <label className="custom-control-label font-weight-normal" htmlFor="rankId" >  My Rank </label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline margin_right0 right_pos">
                                        <input type="radio" id="marksId" value="marks" name="input_pattern_type" className="custom-control-input" checked={this.props.stateData.marks_radio_box}  onChange={(e) => this.props.handleSelectInputChange(e)} />
                                        <label className="custom-control-label font-weight-normal" htmlFor="marksId" >  My Marks</label>
                                    </div>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="marks"
                                        autoComplete="off"
                                        className='custom_selection mt-2'
                                        value={this.props.stateData.inputRank_Marks_count}
                                        onChange={(e) => this.props.handleInputChange(e)}
                                    />
                                    <h6 className='font-12 errorCls mt-2'>{this.props.stateData.error_display_rank_marks}</h6>
                                </Form.Group>
                            <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="caste">
                                <Form.Label className="text-uppercase font-weight-bold input_title">Reservation Category</Form.Label>
                                <SelectDropDown
                                    stateData={this.props.stateData.caste}
                                    handleChange={this.props.selecthandleInputChange}
                                    name="caste"
                                    options={this.getMainCastes()}
                                    placeholderName={'Caste'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                />
                                <span className='errorCls font-12'>{this.props.stateData.error_display_caste}</span>
                            </Form.Group>

                            <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} controlId="subCaste">
                                <Form.Label className="text-uppercase font-weight-bold input_title">Reservation Sub Category</Form.Label>
                                <MultiSelect
                                    overrideStrings={{
                                        "allItemsAreSelected": "All",
                                        "selectSomeItems": scastelabelledBY
                                    }}
                                    hasSelectAll={scasteselectall}
                                    options={scastenewArray}
                                    disableSearch={scastedisablesearch}
                                    value={this.props.stateData.subcasteseldata}
                                    onChange={(e) => this.props.handleMultipleSelectInputChange(e, "scaste")}
                                    labelledBy={"Select"}
                                />
                                <span className='errorCls font-12'>{this.props.stateData.error_display_sub_caste}</span>
                            </Form.Group>
                            <Form.Group as={Col} xl={3} lg={3} md={3} sm={3} >
                                    <Button type="button" className='btn btn-blue-color mt-3 mb-4 center-auto text-uppercase mt-2' onClick={(e) => {this.props.savePrimaryDetails(e)}}>SAVE DETAILS
                                        <span className={this.props.stateData.spinnerPrimaryBlockShow == "show" ? "spinner-border text-primary text-center loading-img-center width_align ml-3" : ""}></span>
                                        {/*
                                            this.props.stateData.successMessage == "" ?
                                            ("")
                                            :
                                            ("Updated")
                                        */}
                                    </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                    {/*<Row>
                        <Col sm={4}>
                            <h6 className='input_title text-uppercase'>Set Shortlisting Priority Based On : </h6>        
                        </Col>
                        <Col sm={8} className='pos_left_list'>
                            <div className="custom-control custom-radio custom-control-inline margin_right0">
                                <input type="radio" id="branch_selId" value="2" name="input_priority_type" className="custom-control-input" checked={this.props.stateData.checked_for_branch} onChange={(e) => this.props.handleSelectInputChange(e)} />
                                <label className="custom-control-label font-weight-bold f-16" htmlFor="branch_selId" > BRANCH </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline margin_right0 right_pos">
                                <input type="radio" id="college_selId" value="1" name="input_priority_type" className="custom-control-input"  checked={this.props.stateData.checked_for_college} onChange={(e) => this.props.handleSelectInputChange(e)} />
                                <label className="custom-control-label font-weight-bold f-16" htmlFor="college_selId" >  COLLEGE </label>
                            </div>  
                            <span className='errorCls font-12'>{this.props.stateData.error_display_priority_type}</span>   
                        </Col>
                     </Row>*/}
                    {(loading2 == true ) && (
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className='spinner-border text-primary text-center loading-img-center'></div>
                                    </Col>
                                </Row>
                            </Container>
                        )}

                        {
                            !loading2 &&  (

                                <Row>
                                    <Col sm={12}>
                                        <div className='flex-items mt-3'>
                                            <DraggableBranchSection onDragEndP = {this.props.onDragEndData} data={this.props.stateData.uniqueBranchList}/>
                                            <DraggableCollegeSection onDragEndC = {this.props.onDragEndCollegeData} data={this.props.stateData.uniqueCollegesList}/>   
                                        </div>
                                    </Col>
                                    
                                </Row>
                            )
                        }
                   
                    

                    

                    {
                        loading2 == false &&  this.props.stateData.uniqueBranchList.length == 0 && this.props.stateData.uniqueCollegesList.length == 0 ?
                        (
                            <Row>
                                <Col sm={12}>
                                    <h5 className='text-center font-weight-bold mt-4'>You Have Not  ShortListed  Any Branches & Colleges...</h5>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            this.props.stateData.new_Branches_colleges.length>0 ?
                            (
                                <>
                                <Row>
                                    <Col sm={12}>
                                        <h6 className='mt-2  errorCls text-center'>Note : New Colleges & Branches Added,Please Generate priority order</h6>
                                    </Col>
                                </Row>
                                 <Row>
                                    <Col sm={12}>
                                        <Button type="button" className={this.props.stateData.disabled_button == false ? "btn btn-blue-color mt-3 mb-4 center-auto text-uppercase" : "btn btn-blue-color mt-3 mb-4 center-auto text-uppercase disabled_button_cursor"}  disabled={this.props.stateData.disabled_button} onClick={(e) => {this.props.saveAndGetDetails(e)}}>Generate Priority Order
                                            <span className={this.props.stateData.spinnerBlockShow == "show" ? "spinner-border text-primary text-center loading-img-center width_align ml-3" : ""}></span>
                                        </Button>       
                                    </Col>
                                </Row>
                                </>
                            )
                            :
                            (
                                loading2 == false  && this.props.stateData.uniqueBranchList.length> 0 && this.props.stateData.uniqueCollegesList.length>0 ?
                                (
                                    <Row>
                                        <Col sm={12}>
                                            <Button type="button" className={this.props.stateData.disabled_button == false ? "btn btn-blue-color mt-3 mb-4 center-auto text-uppercase" : "btn btn-blue-color mt-3 mb-4 center-auto text-uppercase disabled_button_cursor"} disabled={this.props.stateData.disabled_button} onClick={(e) => {this.props.saveAndGetDetails(e)}}>Generate Priority Order
                                                <span className={this.props.stateData.spinnerBlockShow == "show" ? "spinner-border text-primary text-center loading-img-center width_align ml-3" : ""}></span>
                                            </Button>       
                                        </Col>
                                    </Row>
                                )
                                :
                                ("")
                               
                            )
                           
                        )
                    }
                    
                    {
                        this.props.stateData.getDifficultyLevelCollegesData !=undefined && this.props.stateData.getDifficultyLevelCollegesData.length>0 ?
                        (
                            <Row>
                                <Col sm={12} className="mt-2 ">
                                    <React.Fragment>
                                        <Accordion defaultActiveKey="0">
                                            <Card className='block_shadow_css p-0'>
                                                <Accordion.Toggle className="bg-white" as={Card.Header} eventKey="0" onClick={this.getAccordianIconChange}>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="mt-2 ml-4 color_blue text-uppercase cursor_pointer">Your Shortlisted Branches Analysis <i className={this.state.iconChange == "up" ? "fas fa-chevron-up float-right" : "fas fa-chevron-down  float-right"}></i></h6>
                                                        </Col>

                                                    </Row>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body className="p-4">
                                                        <React.Fragment>
                                                            {
                                                                this.props.stateData.getDifficultyLevelCollegesData.map((item,index) => {
                                                                    return(
                                                                        <Accordion eventKey="0">
                                                                            <Card className='mt-2 cursor_pointer'>
                                                                                <Accordion.Toggle className="bg-white" as={Card.Header} eventKey="0" >
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <h6 className="mt-2 color_sky_blue">{item.branch_name} <i className={this.state.iconChange == "up" ? "fas fa-chevron-up float-right" : "fas fa-chevron-down  float-right"}></i></h6>
                                                                                        </Col>

                                                                                    </Row>
                                                                                </Accordion.Toggle>
                                                                                <Accordion.Collapse eventKey="0">
                                                                                    <Card.Body className="p-1">
                                                                                        <table className='table table-bordered table_custom'>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th className=''>Difficult</th>
                                                                                                    <th className=''>Moderate</th>
                                                                                                    <th className=''>Easy</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <>
                                                                                                {
                                                                                                   
                                                                                                    item.values.length>0 ? 
                                                                                                    (
                                                                                                        item.values.map(item2=>{
                                                                                                            return(
                                                                                                                <tr>
                                                                                                                    <td className='color_dark_red'>
                                                                                                                        {
                                                                                                                            item2.diff == "" ?
                                                                                                                            ("-")
                                                                                                                            :
                                                                                                                            (item2.diff)
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                    
                                                                                                                    <td className='color_yash'>
                                                                                                                        {
                                                                                                                            item2.moda == "" ?
                                                                                                                            ("-")
                                                                                                                            :
                                                                                                                            (item2.moda)
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                    <td className='color_light_green'>
                                                                                                                        {
                                                                                                                            item2.easy == "" ?
                                                                                                                            ("-")
                                                                                                                            :
                                                                                                                            (item2.easy)
                                                                                                                        }
                                                                                                                        </td>
                                                                                                                    
                                                                                                                </tr>
                                                                                                            )
                                                                                                        })
                                                                                                    )
                                                                                                    :
                                                                                                    (<tr><td  colspan="3">No Data Available</td></tr>)
                                                                                                       
                                                                                                    
                                                                                                    
                                                                                                }
                                                                                                </>
                                                                                                
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </Card.Body>
                                                                                </Accordion.Collapse>
                                                                            </Card>
                                                                        </Accordion>
                                                            
                                                                    )
                                                                })
                                                            }
                                                            
                                                        </React.Fragment>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </React.Fragment>
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    
                    }
                    

                     {
                        this.props.stateData.orderPriorityDetails.length>0 ?
                        (
                            <>
                            <Row>
                                <Col sm={12} className="mt-2">
                                    <DraggablePriorityOrderSection deleteRecordModal={this.props.deleteRecordModal} onDragEndPriortyOrder = {this.props.onDragEndPriortyOrder} data={this.props.stateData.orderPriorityDetails} stateData={this.props.stateData} />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <Button type="button" className='btn btn-blue-color mt-3 mb-4 center-auto text-uppercase' onClick={(e) => {this.props.updateAndGetDetails(e,"update")}}>Update Priority Order
                                        <span className={this.props.stateData.orderspinnerBlockShow == "show" && this.props.stateData.deleteRecordSpinnerShow != "show" ? "spinner-border text-primary text-center loading-img-center width_align ml-3" : ""}></span>
                                    </Button>       
                                </Col>
                            </Row>
                            </>
                        )
                        :
                        (
                           ""
                        )
                     }   

                     {
                        this.props.stateData.orderPriorityDetails.length>0 ?
                        (
                            <>
                            <Row>
                                <Col sm={12}>
                                    <div className='block_refer_cls mt-4'>
                                        <Row>
                                            <Col sm={4}>
                                                <Image src={ReferFriendImage} alt="Refer Image" className='center-auto'></Image>
                                            </Col>
                                            <Col sm={8}>
                                                <p className='refer_text'>
                                                    Are you happy with our predictions? Then refer your friends and let them know about our amazing college & branch predictor and help them choose right options for their WEB OPTIONS
                                                </p>
                                                <a onClick={this.referFriendRedirection}><Button type="button" className='btn btn-blue-color mt-3 mb-4 center-auto'>REFER FRIEND</Button></a>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <div className='mt-4'>
                                        <Row>
                                            <Col sm={4}>
                                                <Image src={DownloadWOImage} alt="PDF Image" className='center-auto'></Image>
                                            </Col>
                                            <Col sm={8}>
                                                <p className='refer_text'>
                                                Congragrulations! You have completed the hard yard.Now download this list for your WEB OPTION 
                                                </p>
                                                <ExcelContainer stateData={this.props.stateData}/>                                                
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            </>
                        )
                        :
                        ("")
                     }
                    

                    <DeleteRecordModal 
                        deleteBranchCollegeRecord={this.props.deleteBranchCollegeRecord}
                        show={this.props.stateData.deleteModal}
                        stateData={this.props.stateData}
                        onHide={this.props.onHideDeleteModal}
                    />

                    
            </Container>
        )
    }
}



export default withRouter(withApollo(compose(
   
   
    graphql(FETCH_SHORTLIST_BRANCH_COLLEGES,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_id:props?.stateData.exam_type_id
                },
                fetchPolicy: "network-only"
            }), name: "getShortlistedCollegesBranches"
        })
)(ShortListPageSection)));



 