import React, { Component } from 'react'
import TopNavbar from '../components/analysis/result_analysis/TopNavbar'
import AsideNavbar from '../components/navbars/AsideNavbar'
import ResultAnalysisSection from '../components/analysis/result_analysis/ResultAnalysisSection'
import { Container } from 'react-bootstrap'
import * as Cookies from "es-cookie";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import PreloaderTwo from '../components/preloader/PreloaderTwo';

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import JeeNEwPlanPopup from '../components/mock_series/jeeNewPlanpopup'
import NEETPrepBundlePopup from '../components/mock_series_newNTAPattern/NEETPrepBundlepopup'


const FETCH_ISSTUDENTUSERVALID = gql` 
query($mobile: String) {
    isStudentUserValid(mobile: $mobile){
        estatus
        current_plan_id
        expiry_date
        app_version
        user_access_restictions
        module_restrictions
        chapter_ids
     }
}

`;

const UPDATE_STUDENT_ANALYTICS = gql`
  mutation(
    $params:AnalyticsStudents1  
    ) {
        studentAnalytics(
        params: $params
     )
  }
`;

class ResultAnalysis extends Component {
    constructor(props) {
        super(props)
        this.state = {
            exam_type: "0,1",
            class_id: "1,2",
            exam_typevalue: { value: 2, label: 'ALL' },
            class_idvalue: { value: 0, label: 'ALL' },
            headerBottomImg: {
                titleImg: require('../../images/analysis/result_analysis.svg'),
                title: 'Performance Analysis',
                subTitle: 'Analysis all the your strengths and weakness',
                description: 'Dear Student you have a over confidence you making lots of errors by spending less time on reading questions Spend some appropriate time to read the Question and answer it.',
                helpImg: require('../../images/Ask_me_for_Help.gif')
            },
            planpopup: true

            // headerBottomImg: {
            //     titleImg: require('../../images/analysis/result_analysis.svg'),
            //     Img: require('../../images/previous-paper-analysis.svg'),
            //     Title: "Previous Paper Analysis",
            //     width:200,
            //     helpImg: require('../../images/Ask_me_for_Help.gif')
            // }

        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }

    }
    componentDidMount = () => {

        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Performance_Analysis;
        ReactGA.pageview('/student/result-analysis', ["rizee"], title);
    }

    onHide = () => {
        this.setState({
            planpopup: false
        })
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 68,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    selecthandleInputChange = (ename, evalue) => {
        console.log("selecthandleInputChange", ename, evalue);
        const name = ename;
        const value = evalue;
        if (name == "exam_type") {
            if (value == "0") {
                this.setState({
                    exam_typevalue: { value: value, label: 'Practise' },
                    exam_type: value
                });
            }
            else if (value == "1") {
                this.setState({
                    exam_typevalue: { value: value, label: 'Exam' },
                    exam_type: value
                });
            }
            else if (value == "2") {
                this.setState({
                    exam_typevalue: { value: value, label: 'ALL' },
                    exam_type: "0,1"
                });
            }

        }
        if (name == "class_id") {
            if (value == "0") {
                this.setState({
                    class_idvalue: { value: value, label: 'ALL' },
                    class_id: "1,2"
                });
            }
            else if (value == "1") {
                this.setState({
                    class_idvalue: { value: value, label: 'Class-XI' },
                    class_id: value
                });
            }
            else if (value == "2") {
                this.setState({
                    class_idvalue: { value: value, label: 'Class-XII' },
                    class_id: value
                });
            }
        }
    }
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        const isStudentUserValid = this.props.isStudentUserValid;
        const loading5 = isStudentUserValid.loading;
        const error5 = isStudentUserValid.error;
        if (error5 !== undefined) {
            alert("Server Error. " + error5.message);
            return null;
        }

        if (isStudentUserValid.isStudentUserValid != undefined) {
            if (isStudentUserValid.isStudentUserValid.estatus == 0) {
                Cookies.remove("token");
                Cookies.remove("username");
                Cookies.remove("refreshtoken");
                Cookies.remove("email");
                Cookies.remove("id");
                Cookies.remove("institutionid");
                Cookies.remove("userlevel");
                Cookies.remove("name");
                this.props.history.push("/student/login");
            }
            localStorage.removeItem('isStudentUserValid');
            localStorage.setItem(
                "isStudentUserValid",
                JSON.stringify(isStudentUserValid.isStudentUserValid)
            );
        }

        const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);
        return (
            <div className={Cookies.get("toggle")}>
                <div className="student main-wrapper">
                    <div className="student header-area">
                        <TopNavbar headerBottom={this.state.headerBottomImg} onClick={() => this.menuToggler()} />
                    </div>
                    {(loading5 == true) && (<PreloaderTwo />)}
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0">
                        <Container fluid={true}>
                            {
                                !loading5 && (
                                    <>
                                        <ResultAnalysisSection
                                            mobile={Cookies.get("mobile")}
                                            seperationType="student"
                                            stateData={this.state}
                                            selecthandleInputChange={this.selecthandleInputChange}
                                            isStudentUserValid={isStudentUserValid.isStudentUserValid}
                                        />

                                        {/* {
                                            Cookies.get("examid") == 2 && isuserValid.jeePrepBundleAcess == true ?
                                                (
                                                    <JeeNEwPlanPopup
                                                        show={this.state.planpopup}
                                                        onHide={this.onHide}
                                                    />
                                                )
                                                :
                                                (
                                                    Cookies.get("examid") == 1 && isuserValid.jeePrepBundleAcess == true ?
                                                        (
                                                            <NEETPrepBundlePopup
                                                                show={this.state.planpopup}
                                                                onHide={this.onHide}
                                                            />
                                                        )
                                                        :
                                                        ("")
                                                )
                                        } */}
                                    </>

                                )
                            }



                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(compose(
    graphql(FETCH_ISSTUDENTUSERVALID,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: 'network-only'
            }), name: "isStudentUserValid"
        }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
)(ResultAnalysis));
