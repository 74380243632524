import React, { Component } from 'react'
import { withRouter, Link } from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown, Image, Form, Button, Media, Row, Col } from 'react-bootstrap'
import './_navbars.scss';
import * as Cookies from "es-cookie";
import ChangePassword from './ChangePassword';
import * as compose from 'lodash.flowright';
import Notification from './Notification';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import GITHAMCOLLEGELOGO from '../../../images/gitam_college_logo.png';
import MEGHACOLLEGELOGO from '../../../images/megha.png'
import SEGACOLLEGELOGO from '../../../images/sega_college_logo.jpeg'
import PRAGNACOLLEGELOGO from '../../../images/pragna_college.png'
import OMEGHACOLLEGELOGO from '../../../images/omega.png'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const USERLOGOUT = gql`
  mutation(
    $mobile: String!,
    $device_id: String) {
    userLogout(mobile: $mobile,device_id: $device_id)
  }
`;

class Navbars extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
            loader: 0
        }
    }

    logout = e => {
        e.preventDefault();
        this.setState({
            loader: 1
        })
        this.userLogout(Cookies.get("mobile"), "").catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
            console.error(
                "ERR =>",
                error.graphQLErrors.map(x => x.message)
            );
        });

    };
    userLogout = async (mobile,
        device_id) => {
        await this.props.userLogout({
            variables: {
                mobile,
                device_id
            },
            update: (store, { data }) => {
                console.log("data.userLogout", data.userLogout);
                if (data.userLogout) {
                    Cookies.remove("studenttoken");
                    Cookies.remove("studentrefreshtoken");
                    Cookies.remove("studentusername");
                    Cookies.remove("studentemail");

                    Cookies.remove("mobile");
                    Cookies.remove("classid");
                    Cookies.remove("examid");
                    Cookies.remove("exam_name");
                    Cookies.remove("mobileverified");
                    Cookies.remove("targetyear");
                    Cookies.remove("videos");
                    Cookies.remove("branch_name");
                    Cookies.remove("role", "");
                    Cookies.remove("profile_pic");
                    Cookies.remove("student_userlevel");
                    Cookies.remove("stulogintype", "");
                    localStorage.removeItem('profile_pic');
                    localStorage.removeItem('studentglobals');
                    localStorage.removeItem('globalsubjects');
                    localStorage.removeItem("packageplan");

                    Cookies.remove("toggle");
                    this.setState({
                        loader: 0
                    })
                    console.log("window.ReactNativeWebView")
                    if (window.ReactNativeWebView) {
                        this.props.history.push("/student/logineamcet");
                        window.ReactNativeWebView.postMessage("LOGOUT");
                        return;
                    }
                    this.props.history.push("/student/login");



                }
            }
        });
    };
    render() {

        let isStudentUserValid = "";
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        else {
            this.props.history.push("/student/login");
        }
        const isuserValid = JSON.parse(
            isStudentUserValid.user_access_restictions
        );

        console.log("isuserValid", isuserValid.referred_mobile)

        return (
            <div className="header-top">
                <Navbar bg="white" >
                    <Container>
                        <Button variant="light" className="px-2 py-1 mr-2" onClick={() => this.props.onClick()}>
                            <i className="fal fa-bars" />
                        </Button>


                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            {
                                this.props?.pageType != "categoryVideos" && this.props.pageType != "quickQuiz" && this.props.pageType != "ReferFriend" && this.props.pageType != "foundation_exams" ? (<Button variant={this.props.pageType == "categoryVideos" ? " mr-2 d-flex align-items-center backcssCls" : "light text-dark mr-2 d-flex align-items-center"} onClick={this.props.history.goBack}> <i className="fas fa-long-arrow-alt-left" /> <span className="ml-2 d-none d-md-block">Back</span></Button>) : ("")
                            }
                            <div>
                            {
                                    isBrowser == true && (Cookies.get("institution_id") == 46 || Cookies.get("institution_id") == 50 || Cookies.get("institution_id") == 51 || Cookies.get("institution_id") == 54 || Cookies.get("institution_id") == 53) ?
                                        (

                                            <Media className="college_logo_syles_white_bar" >
                                                {

                                                }
                                                <Image
                                                    className="mr-1"
                                                    src={
                                                        Cookies.get("institution_id") == 46 ?
                                                            (GITHAMCOLLEGELOGO) :
                                                            (
                                                                Cookies.get("institution_id") == 50 ?
                                                                    (MEGHACOLLEGELOGO) :
                                                                    (
                                                                        Cookies.get("institution_id") == 51 ?
                                                                            (SEGACOLLEGELOGO) :
                                                                            (
                                                                                Cookies.get("institution_id") == 54 ?
                                                                                    (PRAGNACOLLEGELOGO) :
                                                                                    (
                                                                                        Cookies.get("institution_id") == 53 ?
                                                                                            (OMEGHACOLLEGELOGO) : ("")
                                                                                    )
                                                                            )
                                                                    )
                                                            )
                                                    }
                                                    alt="logo"
                                                />
                                                <Media.Body>
                                                    {
                                                        Cookies.get("institution_id") == 46 ?
                                                            (
                                                                <div>
                                                                    <p class="mb-0">CODE:59425</p>
                                                                    <h5 className="mb-0">GITAM JUNIOR COLLEGE</h5>
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                Cookies.get("institution_id") == 50 ?

                                                                    (<h5 className="mb-0 mt-3 ml-2">MEGHA JUNIOR COLLEGE</h5>)
                                                                    :
                                                                    (
                                                                        Cookies.get("institution_id") == 51 ?
                                                                            (<h5 className="mb-0 mt-3 ml-2">SAGE ACADEMY</h5>) :
                                                                            (
                                                                                Cookies.get("institution_id") == 54 ?
                                                                                    (<h5 className="mb-0 mt-3 ml-2">PRAGNA ACADEMY</h5>) :
                                                                                    (
                                                                                        Cookies.get("institution_id") == 53 ?
                                                                                            (<h5 className="mb-0 mt-3 ml-2">OMEGA JUNIOR COLLEGE</h5>)
                                                                                            : ("")
                                                                                    )
                                                                            )
                                                                    )
                                                            )
                                                    }
                                                </Media.Body>
                                            </Media>
                                        )
                                        :
                                        (
                                            Cookies.get("institution_id") == 46 ?
                                                (<Image className="d-xl-block d-lg-block" src={GITHAMCOLLEGELOGO} style={{ width: '50px', height: '45px' }} />)
                                                :
                                                Cookies.get("institution_id") == 50 ?
                                                    (<Image className="d-xl-block d-lg-block" src={MEGHACOLLEGELOGO} style={{ width: '50px', height: '45px' }} />)
                                                    :
                                                    (
                                                        Cookies.get("institution_id") == 51 ?
                                                            (<Image className="d-xl-block d-lg-block" src={SEGACOLLEGELOGO} style={{ width: '50px', height: '45px' }} />) :
                                                            (
                                                                Cookies.get("institution_id") == 54 ?
                                                                    (<Image className="d-xl-block d-lg-block" src={PRAGNACOLLEGELOGO} style={{ width: '50px', height: '45px' }} />)
                                                                    :
                                                                    (
                                                                        Cookies.get("institution_id") == 53 ?
                                                                            (<Image className="d-xl-block d-lg-block" src={OMEGHACOLLEGELOGO} style={{ width: '50px', height: '45px' }} />)
                                                                            :
                                                                            (
                                                                                <>
                                                                                    {Cookies.get("institute_logo") != "" && this.props?.headerBottom?.type == "homePage" ? (
                                                                                        <Image className="d-none d-xl-block d-lg-block" src={Cookies.get("institute_logo")} height="30" style={{ width: 'auto', minWidth: 60 }} />
                                                                                    ) : ("")}

                                                                                    {
                                                                                        Cookies.get("institution_id") != "30" && this.props?.headerBottom?.type == "homePage" ? <h6 className="ml-2 mb-0 d-none d-xl-block d-lg-block">{Cookies.get("institute_name")}</h6> : ""
                                                                                    }

                                                                                </>
                                                                            )

                                                                    )

                                                            )

                                                    )

                                        )
                                }
                            </div>
                            <div className="ml-resp-navbar mr-auto d-flex align-items-center ">
                                {
                                    this.props.pageType == "categoryVideos" ? (<h6 className="categoryVideosTitle">Watch&nbsp;&&nbsp;Learn</h6>) : ("")
                                }
                                {
                                    this.props.pageType == "quickQuiz" ? (<h6 className="ml-respview categoryVideosTitle">Quiz</h6>) : ("")
                                }
                                {
                                    this.props.pageType == "ReferFriend" ? (<h6 className="ml-respview categoryVideosTitle">Refer A Friend</h6>) : ("")
                                }


                                {
                                    this.props?.headerBottom?.type == "foundation_course_exams" ?

                                        (
                                            <Link to={{
                                                pathname: "/student/foundation-course/home"
                                            }}>
                                                <Button variant={this.props.pageType == "foundation_course_exams" ? " mr-2 d-flex align-items-center backcssCls" : "light text-dark mr-2 d-flex align-items-center"} > <i className="fas fa-long-arrow-alt-left" /> <span className="ml-2 d-none d-md-block">Back</span></Button>
                                            </Link>
                                        )
                                        :
                                        ("")
                                }
                                {
                                    this.props.pageType == "LiveSessions" ? (<h6 className="ml-14 categoryVideosTitle">NEET Rise Course</h6>) : ("")
                                }
                                {
                                    this.props.pageType == "LiveSessionsCrashCourse" ? (<h6 className="ml-14 categoryVideosTitle">NEET 2023 Crash Course</h6>) : ("")
                                }
                                {
                                    this.props.pageType == "col_pred_short_list" ? (<h6 className="ml-14 categoryVideosTitle">Short List Page</h6>) : ("")
                                }
                                {
                                    isBrowser == true && this.props?.headerBottom?.type == "foundation_course" ? (<h6 className="ml-14 eamcetViewTitle">{this.props?.headerBottom?.title}</h6>) : ("")
                                }
                                {
                                    isBrowser == true && this.props?.headerBottom?.type == "eamcet_view" ? (<h6 className="ml-14 eamcetViewTitle">{this.props?.headerBottom?.title}</h6>) : ("")
                                }
                                {
                                    isBrowser == true && this.props?.headerBottom?.type == "foundation_course_exams" ? (<h6 className="ml-14 eamcetViewTitle">{this.props?.headerBottom?.title}</h6>) : ("")
                                }
                                {
                                    isBrowser == true && this.props?.headerBottom?.type == "foundation_classes" ? (<h6 className="ml-14 eamcetViewTitle">{this.props?.headerBottom?.title}</h6>) : ("")
                                }
                                {
                                    isBrowser == true && this.props?.headerBottom?.type == "homePage" && Cookies.get("student_userlevel") == 2 ? (<h6 className="ml-14 eamcetViewTitle">{this.props?.headerBottom?.title}</h6>) : ("")

                                }
                                
                            </div>

                            {/* <div className="mr-auto" inline="true">
                            <Button variant="link text-dark" onClick={this.props.history.goBack}>Go Back</Button>
                        </div> */}

                            {/* <Form inline className="search mr-auto">
                            <i className="fal fa-search" />
                            <Form.Control className="search-bar border-0"
                                type="text"
                                placeholder="Search..."
                                aria-describedby="inputGroupPrepend"
                                required
                            />
                        </Form> */}

                            {
                                isuserValid.referred_mobile == "gsl635" ? ("") : (

                                    <Nav className="ml-auto">
                                        <Notification />
                                        <div className="profile-img d-flex align-items-center">
                                            {Cookies.get("profile_pic") != "" ? (

                                                <Image src={`https://admin.rizee.in/files/${localStorage.getItem("profile_pic")}`} roundedCircle alt="profile-img" />
                                            ) : (
                                                <Image src={require('../../../images/businessman.png')} roundedCircle alt="profile-img" />
                                            )}


                                            <div className="text mx-2 mt-2">
                                                {this.state.loader == 0 ? (
                                                    <React.Fragment>
                                                        <div className="profile-name">{Cookies.get("studentusername")}</div>
                                                        <small>Exam- {Cookies.get("exam_name")}, Class - {Cookies.get("classid") == "1" ? ("XI") : ("XII")}</small>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <div className="profile-name text-dark">{Cookies.get("studentusername")}</div>
                                                        <small className="text-muted">Logging Out...</small>

                                                    </React.Fragment>
                                                )}

                                            </div>
                                            <NavDropdown id="basic-nav-dropdown2" className="no-arrow" alignRight title={
                                                <i className="fal fa-ellipsis-v text-dark fa-2x mx-2 pt-1" aria-hidden="true"></i>
                                            }>
                                                <NavDropdown.Item className="font-weight-bold">{Cookies.get("studentemail")}</NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item as={Link} to="/student/profile"><i class="fas fa-key"></i> Activate Partner Offer</NavDropdown.Item>
                                                {isuserValid.ins_profile_tab == true ? ("") : (<NavDropdown.Item as={Link} to="/student/profile"><i className="far fa-user mr-2" /> My Profile</NavDropdown.Item>)}
                                                {isuserValid.ins_package_tab == true ? ("") : (<NavDropdown.Item as={Link} to={Cookies.get("examid") == 1 || Cookies.get("examid") == 2 ? "/student/package" : "/student/plans_section"}><i className="far fa-sack mr-2" /> Subscription Plans</NavDropdown.Item>)}

                                                {/* <NavDropdown.Item onClick={() => this.setState({ modalShow: true })}><i className="far fa-key mr-2" /> Change Password</NavDropdown.Item> */}
                                                {isuserValid.ins_feedback_tab == true ? ("") : (<NavDropdown.Item as={Link} to="/student/feedback"><i className="far fa-comment-alt-lines mr-2" /> Feedback</NavDropdown.Item>)}

                                                <NavDropdown.Divider />
                                                <NavDropdown.Item as={Link} onClick={this.logout}><i className="far fa-sign-out mr-2" /> Logout</NavDropdown.Item>
                                            </NavDropdown>
                                        </div>
                                    </Nav>
                                )
                            }


                        </Navbar.Collapse>
                    </Container>
                    <ChangePassword
                        show={this.state.modalShow}
                        onHide={() => this.setState({ modalShow: false })}
                    />

                </Navbar>
                {
                    isMobile == true && this.props?.headerBottom?.type == "eamcet_view" ? (<h6 className="ml-14 eamcetViewTitle_mobile">{this.props?.headerBottom?.title}</h6>) : ("")
                }
                {
                    isMobile == true && this.props?.headerBottom?.type == "foundation_course" ? (<h6 className="ml-14 eamcetViewTitle_mobile">{this.props?.headerBottom?.title}</h6>) : ("")
                }
                {
                    isMobile == true && this.props?.headerBottom?.type == "foundation_course_exams" ? (<h6 className="ml-14 eamcetViewTitle_mobile">{this.props?.headerBottom?.title}</h6>) : ("")
                }
                {
                    isMobile == true && this.props?.headerBottom?.type == "foundation_classes" ? (<h6 className="ml-14 eamcetViewTitle_mobile">{this.props?.headerBottom?.title}</h6>) : ("")
                }
            </div>
        )
    }
}

export default React.memo(withRouter(compose(
    graphql(USERLOGOUT, {
        name: "userLogout"
    })
)(Navbars)));

