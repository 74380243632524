import React, { Component } from 'react';
import { Row, Col,Button } from 'react-bootstrap';
import * as Cookies from "es-cookie";
import Pdf from './pdf'
import { Link,withRouter } from 'react-router-dom'

class SyllabusPdfTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            url1: "",
            url2: "",
            url3: "",
            url4: ""
        }
    }

    render() {
        
        return (
            
                <Row>
                    <Col sm={12}>
                        {
                            this.props.showPdf && this.props.pageType == "previousPapergePage" ?
                            (
                                <div className="text-link">
                                    <button onClick={() => this.props.handlePdfView('',1)} type="button" class="btn btn-light text-dark mr-2 d-flex align-items-center">  <span class="ml-2 d-none d-block">Close</span> <i class="far fa-times-circle ml-2" style={{color: 'red'}}></i></button>
                                </div>
                            )
                            :
                            ("")
                        }
                    </Col>
                
                        <Col sm={12} className="mt-4">
                            <div>{
                            this.props.showPdf?
                            (<React.Fragment>
                                <Pdf file={this.props.pdfUrl} />
                            </React.Fragment>):('')
                            }
                            </div>
                        </Col>
                    </Row>
        )

        
    }
}
export default withRouter(SyllabusPdfTable);

