import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_bluePrintexamanalysis.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import * as Cookies from "es-cookie";



let ApMpcData = [
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "FUNCTIONS",
        NOOFQUESTIONS: "2",

    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "MATHEMATICAL INDUCTION",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "MATRICES",
        NOOFQUESTIONS: "3",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "ADDITIONS OF VECTORS",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "PRODUCTS OF VECTORS",
        NOOFQUESTIONS: "3",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "TRIGONOMETRIC RATIOS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "PERIODICITY & EXTREME VALUES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "COMPOUND ANGLES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "MULTIPLE & SUB-MULTIPLE ANGLES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "TRANSFORMATIONS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "TRIGONOMETRIC EQUATIONS",
        NOOFQUESTIONS: "1",
    },

    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "INVERSE TRIGONOMETRIC FUNCTIONS",
        NOOFQUESTIONS: "1",
    },

    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "HYPERBOLIC FUNCTIONS",
        NOOFQUESTIONS: "1",
    },

    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "PROPERTIES OF TRIANGLES",
        NOOFQUESTIONS: "3",
    },

    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "PREREQUISITES (CO-ORDINATE GEOMETRY)",
        NOOFQUESTIONS: "2",
    },

    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "LOCUS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "CHANGE OF AXIS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "STRAIGHT LINES",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "PAIR OF STRAIGHT LINES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "THREE DIMENSIONAL GEOMETRY",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "DIRECTION COSINES & DIRECTION RATIOS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "3D-LINES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "3D-PLANES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "LIMITS",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "CONTINUITY",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "DIFFERENTIATION",
        NOOFQUESTIONS: "3",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "ERRORS & APPROXIMANTS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "TANGENTS & NORMAL",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "RATE OF CHANGE",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "MAXIMA AND MINIMA",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "MEAN VALUE THEOREMS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "COMPLEX NUMBERS",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "DE MOIVRE’S THEOREM",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "QUADRATIC EXPRESSIONS",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "THEORY OF EQUATIONS",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "PERMUTATIONS AND COMBINATIONS",
        NOOFQUESTIONS: "3",
    },

    
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "PARTIAL FUNCTIONS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "RANDOM VARIABLES",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "PROBABILITY",
        NOOFQUESTIONS: "3",
    },
    // {
    //     YEAR: "SECOND YEAR( II A)",
    //     CHAPTERNAME: "MEASURES OF DISPERSION",
    //     NOOFQUESTIONS: "2",
    // },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "CIRCLE",
        NOOFQUESTIONS: "4",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "SYSTEM OF CIRCLE",
        NOOFQUESTIONS: "2",
    },

    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "PARABOLA",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "ELLIPSE",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "HYPERBOLA",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "INTEGRATION",
        NOOFQUESTIONS: "3",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "DEFINITE INTEGRALS",
        NOOFQUESTIONS: "3",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "DIFFERENTIAL EQUATION",
        NOOFQUESTIONS: "2",
    },


];

/// TsMpcData

let TsMpcData = [
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "FUNCTIONS",
        NOOFQUESTIONS: "3",

    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "MATHEMATICAL INDUCTION",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "MATRICES",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "ADDITIONS OF VECTORS",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "PRODUCTS OF VECTORS",
        NOOFQUESTIONS: "3",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "TRIGONOMETRIC RATIOS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "PERIODICITY & EXTREME VALUES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "COMPOUND ANGLES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "MULTIPLE & SUB-MULTIPLE ANGLES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "TRANSFORMATIONS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "TRIGONOMETRIC EQUATIONS",
        NOOFQUESTIONS: "1",
    },

    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "INVERSE TRIGONOMETRIC FUNCTIONS",
        NOOFQUESTIONS: "1",
    },

    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "HYPERBOLIC FUNCTIONS",
        NOOFQUESTIONS: "1",
    },

    {
        YEAR: "FIRST YEAR( IA)",
        CHAPTERNAME: "PROPERTIES OF TRIANGLES",
        NOOFQUESTIONS: "3",
    },

    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "PREREQUISITES (CO-ORDINATE GEOMETRY)",
        NOOFQUESTIONS: "2",
    },

    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "LOCUS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "CHANGE OF AXIS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "STRAIGHT LINES",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "PAIR OF STRAIGHT LINES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "THREE DIMENSIONAL GEOMETRY",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "DIRECTION COSINES & DIRECTION RATIOS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "3D-LINES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "3D-PLANES",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "LIMITS",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "CONTINUITY",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "DIFFERENTIATION",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "ERRORS & APPROXIMANTS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "TANGENTS & NORMAL",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "RATE OF CHANGE",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "MAXIMA AND MINIMA",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "FIRST YEAR( IB)",
        CHAPTERNAME: "MEAN VALUE THEOREMS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "COMPLEX NUMBERS",
        NOOFQUESTIONS: "3",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "DE MOIVRE’S THEOREM",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "QUADRATIC EXPRESSIONS",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "THEORY OF EQUATIONS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "PERMUTATIONS AND COMBINATIONS",
        NOOFQUESTIONS: "3",
    },

    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "BINOMIAL THEOREM",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "PARTIAL FUNCTIONS",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "PROBABILITY",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR( II A)",
        CHAPTERNAME: "MEASURES OF DISPERSION",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "CIRCLE",
        NOOFQUESTIONS: "4",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "SYSTEM OF CIRCLE",
        NOOFQUESTIONS: "2",
    },

    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "PARABOLA",
        NOOFQUESTIONS: "2",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "ELLIPSE",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "HYPERBOLA",
        NOOFQUESTIONS: "1",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "INTEGRATION",
        NOOFQUESTIONS: "3",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "DEFINITE INTEGRALS",
        NOOFQUESTIONS: "3",
    },
    {
        YEAR: "SECOND YEAR ( II B)",
        CHAPTERNAME: "DIFFERENTIAL EQUATION",
        NOOFQUESTIONS: "2",
    },


];
class MathsDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    colFunction() {

        let columns = [
            {
                dataField: 'YEAR',
                text: 'Year',
                sort: true
            },
            {
                dataField: 'CHAPTERNAME',
                text: 'CHAPTERNAME',
                sort: true
            },
            {
                dataField: 'NOOFQUESTIONS',
                text: 'NO OF QUESTIONS',
                sort: false
            },

        ];
        return columns;
    }

    render() {
        return (
            <Row>
                <Col>
                    {
                        Cookies.get("examid") == 3 ? (
                            <Card>
                                <BootstrapTable
                                    keyField="id"
                                    columns={this.colFunction()}
                                    data={ApMpcData}
                                    defaultSorted={true}
                                    wrapperClasses="table-responsive"
                                    bootstrap4
                                />
                            </Card>

                        ) : null
                    }

                    {
                        Cookies.get("examid") == 6 ? (
                            <Card>
                                <BootstrapTable
                                    keyField="id"
                                    columns={this.colFunction()}
                                    data={TsMpcData}
                                    defaultSorted={true}
                                    wrapperClasses="table-responsive"
                                    bootstrap4
                                />
                            </Card>

                        ) : null
                    }
                </Col>
            </Row>

        )
    }

} export default MathsDataSection