import React, { Component } from 'react'
import * as Cookies from "es-cookie";
import AsideNavbar from '../components/navbars/AsideNavbar'
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import AllVideosSection from '../components/category_wise_videos/all-videos/AllVideosSection'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'
import Navbars from '../components/navbars/Navbars'

class CustomAllVideos extends Component {
    constructor(props) {
        super(props)

        this.state = {
            headerBottomImg: {
                Img: require('../../images/Vieos Image.png'),
                Title: "Videos",
                width: 200,
                helpImg: require('../../images/Ask_me_for_Help.gif'),
                pageType:"CategoryVideos"
                
            }
        }
    }
   
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    render() {
        let Data = this.props.location.state;
        console.log("dddd",Data)
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

            return (
                <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} pageType={"categoryVideos"}/>
                </div>
                <div className="main-wrapper">
                   
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0 customCategoryVideos" style={{ minHeight: "50vh" }}>
                        <AllVideosSection  subCategoryWiseData={this.props.location.state}/>
                    </div>
                </div>
            </div>

            )
        
    }

    
}

export default withRouter(CustomAllVideos);