import React, { Component } from 'react'
import { Row, Col, Table, Image, Button, Container, Accordion, Card, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import { components } from 'react-select'
import * as Cookies from "es-cookie";
import moment from 'moment';
import "./_referFriend.scss";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import {
    FacebookShareButton,
    InstapaperShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";


const GET_REWARDS_DETAILS = gql`
query($id :Int){
    getReferralStaticData(id:$id){
        no_of_days
        referral_points
        no_of_points
        converted_days

      }
}
`

class ReferModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textAreaValue: "Hi! I found the best exam preparation platform called Rizee. It really helped me prepare for my exams and I am sure it will help you too! Here is a link to register with Rizee. Hurry and sign up now!",
            textToCopy: '',
        }
    }


    render() {

        const getReferralStaticData = this.props.getReferralStaticData;
        const loading5 = getReferralStaticData.loading;
        const error5 = getReferralStaticData.error;

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }

        this.state.textToCopy = `https://rizee.in/Registration/?src=friend_refferal_web&referal_code=${studentGlobals.uid}` 

        let rewardDays = 0

        if (getReferralStaticData.getReferralStaticData != undefined) {
            rewardDays = getReferralStaticData.getReferralStaticData[0].no_of_days
        }

        if (error5 !== undefined) {
            alert("Server Error. " + error5.message);
            return null;
        }

        return (
            <Modal {...this.props} className='refer_modal_Result p-3'
                size="lg" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton className='p-2'>
                    <Modal.Title className='refer-title'>Refer Your Friend</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-popup'>
                    <Row>
                        <Col sm={12} className="mt-4">
                            <div className="pad_10">
                                <Row>
                                    <Col>
                                        <div className={isMobile == true ? "" : "arrow"}>
                                            <Image src={require('../../../images/invite_friend.png')} alt="invite_friend" className="img-center" ></Image>
                                            <h5 className="text-center mt-3">Invite Your Friend</h5>
                                            <p className="text-center color_yash f-16">Spread the word with your link via social sharing</p>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={isMobile == true ? "" : "arrow-down"}>
                                            <Image src={require('../../../images/registrations.png')} alt="registrations" className="img-center" ></Image>
                                            <h5 className="text-center mt-3">Registration</h5>
                                            <p className="text-center color_yash f-16">Your friend signs up to <span className='font-weight-bold color_black'>Rizee</span> & gets 3 days <span className='font-weight-bold color_black'>Free</span>  trial access</p>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Image src={require('../../../images/rizee_free.png')} alt="rizee_free" className="img-center" ></Image>
                                        <h5 className="text-center mt-3">Rizee for Free</h5>
                                        <p className="text-center color_yash f-16">You  get {rewardDays} days <span className='font-weight-bold color_black'>Rizee</span> subscription as a reward</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        <Col sm={8} className="">
                            <ul class="social-media-icons">
                                <li className="mt-2 font-weight-bold color_yash">Share On</li>
                                <li>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>share on Facebook</Tooltip>}>
                                        <a className="fb-color" > <FacebookShareButton url={"https://rizee.in/Registration/?src=friend_refferal_web&referal_code=" + studentGlobals.uid} title={this.state.textAreaValue}>
                                            <i class="fab fa-facebook-f icon"></i>
                                        </FacebookShareButton></a>
                                    </OverlayTrigger>
                                </li>
                                <li>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>share on Whatsapp</Tooltip>}>
                                        <a className="wt-color"> <WhatsappShareButton url={"https://rizee.in/Registration/?src=friend_refferal_web&referal_code=" + studentGlobals.uid} title={this.state.textAreaValue}>
                                            <i class="fab fa-whatsapp icon"></i>
                                        </WhatsappShareButton>
                                        </a>
                                    </OverlayTrigger>

                                </li>
                                <li>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>share on Telegram</Tooltip>}>
                                        <a className="te-color"> <TelegramShareButton url={"https://rizee.in/Registration/?src=friend_refferal_web&referal_code=" + studentGlobals.uid} title={this.state.textAreaValue}>
                                            <i class="fab fa-telegram icon"></i>
                                        </TelegramShareButton>
                                        </a>
                                    </OverlayTrigger>

                                </li>
                                <li>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>share on Twitter</Tooltip>}>
                                        <a className="tw-color"><TwitterShareButton url={"https://rizee.in/Registration/?src=friend_refferal_web&referal_code=" + studentGlobals.uid} title={this.state.textAreaValue}>
                                            <i class="fab fa-twitter icon"></i>
                                        </TwitterShareButton>
                                        </a>
                                    </OverlayTrigger>  
                                </li>
                            </ul>
                        </Col>
                        <Col sm={1}>
                            <h6 className="mt-res-3 text-center-res">or</h6>
                        </Col>
                        <Col sm={12}>
                            <Row>
                                <Col sm={10}>
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" className="input-box-color" value={this.state.textToCopy} />
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col sm={2}>
                                    <Button type="button" variant="secondary" className="f-12 mt-1" onClick={() => { navigator.clipboard.writeText(this.state.textToCopy) }}>COPY</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        )
    }
}

export default withRouter(compose(

    graphql(GET_REWARDS_DETAILS,
        {
            options: props => ({
                variables: {
                    id: 0
                }
                ,
                fetchPolicy: "cache-and-network"
            }), name: "getReferralStaticData"
        }),
)(ReferModal));
