import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import { Container, Row, Col, Image } from 'react-bootstrap'

import * as Cookies from "es-cookie";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import * as compose from 'lodash.flowright';
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import EamcetPlansHomeSection from '../components/eamcet_plans_section/EamcetPlansHomeSection';




const FETCH_ISSTUDENTUSERVALID = gql` 
query($mobile: String) {
    isStudentUserValid(mobile: $mobile){
        estatus
        current_plan_id
        expiry_date
        app_version
        user_access_restictions
     }
}

`;

const FETCH_PLANS = gql`
query($mobile:String){
        getSubscriptionPlans(mobile:$mobile) {
            id
            name
            exam_id
            student_plan_id
            order_by
            image_path
            description
            studentPlans{
                id
                plan_name
                plan_title
                amount
                exam_id
                original_amount
                valid_days
                image
                additional_text
                plan_features
                color
                coupon_display
                upgrade_access
                upgrade_amount
                upgrade_description
                expiry_date
          }
        }
}
`

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

class EamcetPlans extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerBottomImg: {
                type: "eamcet_view",
                title: "SUBSCRIPTION"
            },
            toggled: "wrapper sidebar-enable",
            submitError: ""


        }

    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 127,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    componentDidMount = () => {
        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Eamcet_Plans;
        ReactGA.pageview('/student/plans_section', ["rizee"], title);
    }
    render() {

        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        const isStudentUserValid = this.props.isStudentUserValid;
        const loading5 = isStudentUserValid.loading;
        const error5 = isStudentUserValid.error;

        const getSubscriptionPlans = this.props.getSubscriptionPlans;
        const loading4 = getSubscriptionPlans.loading;
        const error4 = getSubscriptionPlans.error


        if (error4 !== undefined) {
            alert("Server Error. " + error4.message);
            return null;
        }
        if (error5 !== undefined) {
            alert("Server Error. " + error5.message);
            return null;
        }


        if (isStudentUserValid.isStudentUserValid != undefined) {
            if (isStudentUserValid.isStudentUserValid.estatus == 0) {
                Cookies.remove("token");
                Cookies.remove("username");
                Cookies.remove("refreshtoken");
                Cookies.remove("email");
                Cookies.remove("id");
                Cookies.remove("institutionid");
                Cookies.remove("userlevel");
                Cookies.remove("name");
                this.props.history.push("/student/login");
            }
        }

        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars headerBottom={this.state.headerBottomImg} onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper bg-white scrolling">
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0">
                        {
                            loading5 == true || loading4 == true ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                ) : (
                                    <EamcetPlansHomeSection
                                        isStudentUserValid={isStudentUserValid.isStudentUserValid}
                                        getSubscriptionPlans={getSubscriptionPlans.getSubscriptionPlans}
                                    />
                                )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(

    graphql(FETCH_ISSTUDENTUSERVALID,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: "cache-and-network"
            }), name: "isStudentUserValid"
        }),
    graphql(FETCH_PLANS,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: "network-only"
            }), name: "getSubscriptionPlans"
        }),


    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
)(EamcetPlans));
