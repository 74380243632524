import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import CollegeRankPredictorHome from '../components/collegeRankPredictor/collegeRankPredictorHome';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withApollo } from "react-apollo";
import { Row, Col, Image, Container } from 'react-bootstrap';
import EamcetRestrictionAlert from "../components/home/EamcetRestrictionAlert";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const FETCH_BRANCHES = gql` 
query($id: Int) {
    getEamcetBranchesPrediction(id: $id state_id:Int){
        id
        branch_name
     }
}`;

const FETCH_CASTES = gql` 
query($id: Int) {
    getCasteNames(id: $id){
        id
        main_caste
     }
}
`;

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;
const GET_COLLEGES_TYPE = gql`
query($eamcet_college_type_id:Int){
    getCollegeType(eamcet_college_type_id:$eamcet_college_type_id){
        college_type
        college_type_id
      }
}
`


/*const queryParams = new URLSearchParams(window.location.search);
const exam_type_url = queryParams.get('exam_type');
const rank_url = queryParams.get('rank');
const caste_url = queryParams.get('caste').split(",");

const caste_id_url = caste_url[0];
const caste_name_url = caste_url[1];*/

const exam_type_url = 24;
const caste_url = null;
const rank_url = null;
const caste_id_url = null;
const caste_name_url = null;

const ExamTypes = [
    { value: '24', label: 'TS EAMCET 2023 - ENGG' },
    { value: '2', label: 'AP EAMCET 2023 - ENGG' },
    // { value: '1', label: 'AP EAMCET 2020 - ENGG' },
];

class CollegeRankPredictor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerBottomImg: {
                type: "col_pred",
            },
            toggled: isMobile == true && Cookies.get("institution_id") == 30 ? "wrapper" : "wrapper sidebar-enable",
            submitError: "",
            exam_type: exam_type_url == 24 ? { value: "24", label: "TS EAMCET 2023 - ENGG" } : { value: "1", label: "AP EAMCET 2023 - ENGG" },
            branch: [],
            ebranchseldata: [],
            ebranchselvalue: [],
            caste: { value: '1', label: "BC_A" },
            subCaste: { value: '0', label: "ALL" },
            subCasteData: [],
            examTypes: ExamTypes,
            escasteseldata: [],
            escasteselvalue: [],
            collegeData: [],
            collegeseldata: [],
            collegeselvalue: [],
            predictData: [],
            userRestionModalShow: false,
            input_pattern_type: "rank",
            inputRank_Marks_count: 0,
            collegeFilterData: [],
            checked: true,
            collegetypeseldata: [],
            collegetypeselvalue: [],
            districtData: [],
            districtseldata: [],
            districtselvalue: [],
            branchOnchangeData: "",
            districtOnchnagedata: "",
            collegetypesOnchnagedata: "",
        }

        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }

    componentDidMount() {
        this.addStudentAnalytics();

        const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

        if (isuserValid.college_prediction_package_acess == true) {
            this.setState({ userRestionModalShow: true });
        }

        // if (this.isStudentUserValid.current_plan_id != 61) {
        //     this.setState({ userRestionModalShow: true });
        // }

        let ebvalue = Array();
        let ebtype = Array();

        if (this.state.branch != undefined && this.state.branch.length > 0) {
            console.log("this.props.getBranches", this.state.branch);
            for (let i = 0; i < this.state.branch.length; i++) {
                const etesttypeval = this.state.branch[i];
                const newObj = {
                    label: etesttypeval.branch_name,
                    value: etesttypeval.id
                }
                ebvalue.push(newObj);
                ebtype.push(newObj.value);
            }
            this.setState({
                ebranchseldata: ebvalue,
                ebranchselvalue: ebtype
            });
        }
    };

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 205,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };


    pselecthandleInputChange = (ename, evalue) => {

        console.log("eeee", ename, evalue);
        const value = evalue;
        const name = ename;
        if (name == 'examType') {
            if (value == "1") {
                this.setState({
                    exam_type: {
                        value: value,
                        label: "AP EAMCET 2023 - ENGG"
                    },
                    ebranchseldata: [],
                    caste: { value: '1', label: "BC_A" },
                    subCaste: { value: '0', label: "ALL" },
                    escasteseldata: [],
                    inputRank_Marks_count: 0,
                    input_pattern_type: "rank",
                    collegeData: [],
                    collegeFilterData: [],
                    collegeseldata: [],
                    collegeselvalue: [],
                    ebranchseldata: [],
                    ebranchselvalue: [],
                    collegetypeselvalue: [],
                    collegetypeseldata: [],
                    districtselvalue: [],
                    districtOnchnagedata: "",
                    checked: true,
                    districtseldata: [],
                });
            } else if (value == "24") {
                this.setState({
                    exam_type: {
                        value: value,
                        label: "TS EAMCET 2023 - ENGG"
                    },
                    ebranchseldata: [],
                    caste: { value: '1', label: "BC_A" },
                    subCaste: { value: '0', label: "ALL" },
                    escasteseldata: [],
                    inputRank_Marks_count: 0,
                    input_pattern_type: "rank",
                    collegeData: [],
                    collegeFilterData: [],
                    collegeseldata: [],
                    collegeselvalue: [],
                    collegetypeseldata: [],
                    ebranchseldata: [],
                    ebranchselvalue: [],
                    districtselvalue: [],
                    checked: true,
                    districtOnchnagedata: "",
                    districtseldata: [],
                    collegetypeselvalue: []



                });
            } else {
                this.setState({
                    exam_type: {
                        value: value,
                        label: "AP EAMCET 2023 - ENGG"
                    },
                    ebranchseldata: [],
                    caste: { value: '1', label: "BC_A" },
                    subCaste: { value: '0', label: "ALL" },
                    escasteseldata: [],
                    inputRank_Marks_count: 0,
                    input_pattern_type: "rank",
                    collegeData: [],
                    collegeFilterData: [],
                    collegeseldata: [],
                    collegeselvalue: [],
                    collegetypeselvalue: [],
                    districtselvalue: [],
                    ebranchseldata: [],
                    ebranchselvalue: [],
                    districtOnchnagedata: "",
                    districtseldata: [],
                    collegetypeseldata: [],

                    checked: true

                });
            }
        } else if (name == 'caste') {
            if (value != "0") {
                let ecasteData = this.props.getMainCastes.getCasteNames.find((a) => a.id == value);
                console.log("this.props.getMainCastes", ecasteData);
                this.setState({
                    caste: {
                        value: ecasteData.id,
                        label: ecasteData.main_caste
                    },
                    escasteseldata: []
                });
            }
            else {
                this.setState({
                    caste: {
                        value: "0",
                        label: "ALL"
                    }
                });
            }
        } else if (name == 'subCaste') {
            if (value != "0") {
                let esubcasteData = this.state.subCasteData.find((a) => a.id == value);
                this.setState({
                    subCaste: {
                        value: esubcasteData.id,
                        label: esubcasteData.name
                    }
                });
            }
            else {
                this.setState({
                    caste: {
                        value: "0",
                        label: "ALL"
                    }
                });
            }
        }
    };

    phandleMultipleSelectInputChange = async (e, name) => {
        console.log("eeeee", e, name);
        if (name == "branch") {
            this.setState({
                collegeFilterData: []
            });
            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }
                this.setState({
                    ebranchseldata: etesttypevalue,
                    ebranchselvalue: etesttype,
                    collegetypeselvalue: [],
                    collegetypeseldata: [],
                });
            } else {
                this.setState({
                    ebranchseldata: [],
                    ebranchselvalue: [],
                    collegeseldata: [],
                    collegetypeselvalue: [],
                    collegetypeseldata: [],

                });
            }
            let barnch_ids_Arr = etesttypevalue.map(item => item.value);
            barnch_ids_Arr.join(", ");
            console.log("barnch_ids_Arr", barnch_ids_Arr.toString())

            this.setState({
                branchOnchangeData: barnch_ids_Arr.toString()
            });

            const result = await this.props.client.query({
                query: gql` 
                        query($state: Int!,$branch_id:String,$eamcet_college_type_id:String,$district:String) {
                            getCollegeName(state: $state,branch_id:$branch_id,eamcet_college_type_id:$eamcet_college_type_id,district:$district )
                                {
                                    college_id
                                    college_name
                                }
                        }
                    `,
                variables: {
                    state: parseInt(this.state.exam_type.value),
                    branch_id: barnch_ids_Arr.toString(),
                    eamcet_college_type_id: this.state.collegeselvalue.toString(),
                    district: this.state.districtselvalue.toString()
                },
                fetchPolicy: 'network-only'
            });


            if (result.data.getCollegeName.length > 0) {
                this.setState({
                    collegeFilterData: result.data.getCollegeName
                });
            }
        } else if (name == "scaste") {
            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }
                this.setState({
                    escasteseldata: etesttypevalue,
                    escasteselvalue: etesttype
                });
            } else {
                this.setState({
                    escasteseldata: [],
                    escasteselvalue: []
                });
            }
        } else if (name == "college") {
            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }
                this.setState({
                    collegeseldata: etesttypevalue,
                    collegeselvalue: etesttype
                });
            } else {
                this.setState({
                    collegeseldata: [],
                    collegeselvalue: []
                });
            }
        } else if (name == "collegeType") {
            let etesttype = Array();
            let etesttypevalue = Array();

            if (e != null && e.length > 0) {
                console.log("sssss", e)
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }
                this.setState({
                    collegetypeseldata: etesttypevalue,
                    collegetypeselvalue: etesttype,
                    collegeData: [],
                    collegeFilterData: [],
                    collegeseldata: [],
                    collegeselvalue: [],
                    districtOnchnagedata: "",


                });
            } else {
                console.log("qqqqq", e)
                this.setState({
                    collegetypeseldata: [],
                    collegetypeselvalue: [],
                    collegeData: [],
                    collegeFilterData: [],
                    collegeseldata: [],
                    collegeselvalue: [],
                    // districtseldata:[],
                    // districtselvalue:[],
                    // districtOnchnagedata:"",  
                });
            }
            let collegetypes_ids_Arr = etesttypevalue.map(item => item.value);
            collegetypes_ids_Arr.join(", ");
            console.log("barnch_ids_Arr", this.state.districtselvalue)

            this.setState({
                collegetypesOnchnagedata: collegetypes_ids_Arr.toString()
            })

            const result = await this.props.client.query({
                query: gql` 
                        query($state: Int!,$branch_id:String,$eamcet_college_type_id:String,$district:String) {
                            getCollegeName(state: $state,branch_id:$branch_id,eamcet_college_type_id:$eamcet_college_type_id,district:$district )
                                {
                                    college_id
                                    college_name
                                }
                        }
                    `,
                variables: {
                    state: parseInt(this.state.exam_type.value),
                    branch_id: this.state.branchOnchangeData,
                    eamcet_college_type_id: collegetypes_ids_Arr.toString(),
                    district: this.state.districtselvalue.toString(),
                },
                fetchPolicy: 'network-only'
            });

            if (result.data.getCollegeName.length > 0) {
                this.setState({
                    collegeFilterData: result.data.getCollegeName
                });
            }
        } else if (name == "district") {
            this.setState({
                collegeFilterData: []
            });
            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }
                this.setState({
                    districtseldata: etesttypevalue,
                    districtselvalue: etesttype,
                    collegeseldata: [],
                    collegeselvalue: [],
                });
            } else {
                this.setState({
                    districtseldata: [],
                    districtselvalue: [],
                    collegeseldata: [],
                    collegeselvalue: [],
                });
            }
            let district_ids_Arr = etesttypevalue.map(item => item.value);
            district_ids_Arr.join(", ");
            // console.log("barnch_ids_Arr", district_ids_Arr.toString())

            this.setState({
                districtOnchnagedata: district_ids_Arr.toString()
            })


            const result = await this.props.client.query({
                query: gql` 
                        query($state: Int!,$branch_id:String,$eamcet_college_type_id:String,$district:String) {
                            getCollegeName(state: $state,branch_id:$branch_id,eamcet_college_type_id:$eamcet_college_type_id,district:$district )
                                {
                                    college_id
                                    college_name
                                }
                        }
                    `,
                variables: {
                    state: parseInt(this.state.exam_type.value),
                    branch_id: this.state.branchOnchangeData,
                    eamcet_college_type_id: this.state.collegetypeselvalue.toString(),
                    district: district_ids_Arr.toString(),
                },
                fetchPolicy: 'network-only'
            });

            if (result.data.getCollegeName.length > 0) {
                this.setState({
                    collegeFilterData: result.data.getCollegeName
                });
            }
        }
    };

    handleInputChange = (e) => {
        const value = e.target.value;
        this.setState({ inputRank_Marks_count: value });
    }

    handleSelectChange = (e) => {
        const value = e.target.value;
        if (value == "marks") {
            this.setState({ input_pattern_type: value, inputRank_Marks_count: 0, checked: false });
        } else {
            this.setState({ input_pattern_type: value, inputRank_Marks_count: 0, checked: true });
        }
    }

    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    handleSubscribeModal = () => {
        this.setState({
            userRestionModalShow: true,
        })
    }

    closeModal = () => {
        this.setState({
            userRestionModalShow: false,
        });
        this.props.history.push({
            pathname: "/student/home"
        })
    }

    render() {

        console.log("state11111",this.state);
        const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

        const getMainCastes = this.props.getMainCastes;
        const loading1 = getMainCastes.loading;
        const error1 = getMainCastes.error;

        const getCollegeType = this.props.getCollegeType;
        const loading2 = getCollegeType.loading
        const error2 = getCollegeType.error


        console.log("getCollegeType",)

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }

        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");




        return (
            <div className={this.state.toggled}>
                <div className="student main-wrapper">
                    <div className="student header-area col_pred">
                        <ScrollTopNavbar
                            headerBottom={this.state.headerBottomImg}
                            onClick={() => this.menuToggler()} />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper-pad0">
                        {
                            (loading1) == true || (loading2) == true ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    isuserValid.college_prediction_package_acess == false ?
                                        (
                                            <CollegeRankPredictorHome
                                                getMainCastes={getMainCastes.getCasteNames}
                                                stateData={this.state}
                                                selecthandleInputChange={this.pselecthandleInputChange}
                                                handleMultipleSelectInputChange={this.phandleMultipleSelectInputChange}
                                                handleInputChange={this.handleInputChange}
                                                handleSelectChange={this.handleSelectChange}
                                                getCollegeType={getCollegeType.getCollegeType}
                                            />
                                        )
                                        :
                                        (
                                            <EamcetRestrictionAlert
                                                show={this.state.userRestionModalShow}
                                                onHide={() => this.closeModal()}
                                                buildType="college_predictor"
                                            />
                                        )
                                )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(compose(
    graphql(FETCH_CASTES,
        {
            options: props => ({
                variables: {
                    id: 0
                },
                fetchPolicy: "network-only"
            }), name: "getMainCastes"
        }),
    graphql(GET_COLLEGES_TYPE,
        {
            options: props => ({
                variables: {
                    eamcet_college_type_id: 0
                },
                fetchPolicy: "network-only"
            }), name: "getCollegeType"
        }),

    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
)(CollegeRankPredictor)));
