import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import EamcetCrashCourseExamsSection from '../components/Eamcet_Crash_Course/eamcet_exams/EamcetCrashCourseExamsSection.jsx';
import * as Cookies from "es-cookie";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";



const FETCH_ISSTUDENTUSERVALID = gql` 
query($mobile: String) {
    isStudentUserValid(mobile: $mobile){
        estatus
        current_plan_id
        expiry_date
        
        user_access_restictions
        module_restrictions
        chapter_ids
        previous_sets
     }
}

`;
const FETCH_EAMCET_CRASH_COURSE = gql`
    query($id : Int!){
        getGlobalType(id : $id){
            global_name
            comments
            global_data{
                global_type_id
                date_time
                value
            }
        }
    }
`;

const FETCH_COURSE_MODULE = gql`
    query($id : Int!){
        getCourseModuleAccess(id : $id){
            id
            course_name
            description
            exam_id
            courseModuleAccess {
              id
              student_plan_id
              course_id
              subject_ids
              subject_names
              course_module_ids
              courseModules {
                id
                course_id
                module_name
              }
            
            }
        }
    }
`;

const FETCH_GETSERIES = gql` 
query($mobile: String,
    $exam_group_id: Int) {
        getGroupWiseExams(
            mobile: $mobile,
            exam_group_id: $exam_group_id)
            {
                freeExams{
                    id
                    exam_name
                    exam_type
                    exam_session_id
                    sub_exam_type
                    is_completed
                    is_started
                    title
                    short_name
                    amount
                    speed
                    accuracy
                    correct_marks
                    negative_marks
                    total_marks
                    start_time
                    end_time
                    is_purchased
                    exam_started
                    is_scheduled
                    is_registered
                    exam_expired
                    exam_duration
                    syllabus
                }
                paidExams{
                    id
                    exam_name
                    exam_type
                    exam_session_id
                    sub_exam_type
                    is_completed
                    is_started
                    title
                    short_name
                    amount
                    speed
                    accuracy
                    correct_marks
                    negative_marks
                    total_marks
                    start_time
                    end_time
                    is_purchased
                    exam_started
                    is_scheduled
                    is_registered
                    exam_expired
                    exam_duration
                    syllabus
                }
                
    }
}

`;
const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

class EamcetCrashCourseExams extends Component {
    constructor(props) {
        super(props)

        this.state = {
            headerBottomImg: {
                type: "eamcet_view",
                title: "EAMCET 2022 CRASH COURSE"
            },
            toggled: isMobile == true && Cookies.get("institution_id") == 30 ? "wrapper" : Cookies.get("toggle"),
            submitError: ""

        }
    }
    componentDidMount = () => {
        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Eamcet_Crash_Course_Exams;
        ReactGA.pageview('/student/eamcet-crashcourse/exams', ["rizee"], title);
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 141,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };



    menuToggler = () => {
        const toggled = this.state.toggled;
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    render() {
        const isStudentUserValid = this.props.isStudentUserValid;
        const loading2 = isStudentUserValid.loading;
        const error2 = isStudentUserValid.error;

        const getGroupWiseExams = this.props.getGroupWiseExams
        const loading1 = getGroupWiseExams.loading
        const error1 = getGroupWiseExams.error

        const getCourseModuleAccess = this.props.getCourseModuleAccess;
        const loading3 = getCourseModuleAccess.loading;
        const error3 = getCourseModuleAccess.error;

        const getGlobalType = this.props.getGlobalType;
        const loading4 = getGlobalType.loading;
        const error4 = getGlobalType.error;

        if (error2 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        if (error3 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }if (error4 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }


        console.log("getUserExplored", isStudentUserValid.isStudentUserValid);
        if (isStudentUserValid.isStudentUserValid != undefined) {
            if (isStudentUserValid.isStudentUserValid.estatus == 0) {
                Cookies.remove("token");
                Cookies.remove("username");
                Cookies.remove("refreshtoken");
                Cookies.remove("email");
                Cookies.remove("id");
                Cookies.remove("institutionid");
                Cookies.remove("userlevel");
                Cookies.remove("name");
                this.props.history.push("/student/login");
            }
            localStorage.removeItem('isStudentUserValid');
            localStorage.setItem(
                "isStudentUserValid",
                JSON.stringify(isStudentUserValid.isStudentUserValid)
            );
        };

        let getFreeExamsData = [], getPaidExamsData = [];
        if (getGroupWiseExams.getGroupWiseExams != undefined) {
            getGroupWiseExams.getGroupWiseExams.map(item => {
                item.freeExams.map(exams => {
                    getFreeExamsData.push(exams);
                })

            });

            getGroupWiseExams.getGroupWiseExams.map(item => {
                item.paidExams.map(exams => {
                    getPaidExamsData.push(exams);
                })

            });
        }


        return (
            <div className={this.state.toggled}>
                <div className="student main-wrapper">
                    <div className="student header-area exam_pattern_topnavbar">
                        <ScrollTopNavbar
                            headerBottom={this.state.headerBottomImg}
                            onClick={() => this.menuToggler()} />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper-pad0">
                        {
                            loading1 == true || loading2 == true || loading3 == true || loading4 == true ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>) : (
                                    <EamcetCrashCourseExamsSection
                                        isStudentUserValid={isStudentUserValid.isStudentUserValid}
                                        getFreeExamsData={getFreeExamsData}
                                        getPaidExamsData={getPaidExamsData}
                                        getGlobalType={getGlobalType.getGlobalType}
                                        getCourseModuleAccess={this.props.getCourseModuleAccess} />
                                )
                        }

                    </div>

                </div>
            </div>
        )
    }
}


export default withRouter(compose(
    graphql(FETCH_ISSTUDENTUSERVALID,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: 'network-only'
            }), name: "isStudentUserValid"
        }),
    graphql(FETCH_GETSERIES,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_group_id: Cookies.get("examid") == "3" ? 49 : Cookies.get("examid") == "6" ? 51 : Cookies.get("examid") == "7" ? 50 : 52
                },
                fetchPolicy: 'network-only'
            }), name: "getGroupWiseExams"
        }),
    graphql(FETCH_COURSE_MODULE,
        {
            options: props => ({
                variables: {
                    id: 1
                }
                ,
            }), name: "getCourseModuleAccess"
        }),
    graphql(FETCH_EAMCET_CRASH_COURSE,
        {
            options: props => ({
                variables: {
                    id: 1
                }
                ,
            }), name: "getGlobalType"
        }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
)(EamcetCrashCourseExams));
