import React, { Component } from 'react'
import { Container, Row, Col, Card, Button, Image, Form, Accordion } from 'react-bootstrap';
import * as Cookies from "es-cookie";
import './_previousPaperExams.scss'
import ScrollMenu from 'react-horizontal-scrolling-menu';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import gql from "graphql-tag";
import { withRouter, Link } from "react-router-dom";
import UserRestrictionAlert from "../home/UserRestrictionAlert";

let studentGlobals = "";


let filteredExams = [];
let uniqueYears = [];
let selectedYear = '';

if (Cookies.get("examid") == 1) {
  selectedYear = '2022'
} else {
  selectedYear = '2022'
}




// eslint-disable-next-line react/prop-types
const MenuItem = ({ text, selected }) => {

  return <div cclassName={`menu-item ${selected ? 'active' : ''}`}>
    <ul className='list_style_years mt-4'>
      <li className={text == selectedYear ? 'active' : ''} >
        {text}
      </li>
    </ul>
  </div>;
};

export const Menu = (uniqueYears, selected) =>
  uniqueYears.map(el => {
    const { name } = el;
    return (
      <MenuItem text={el} key={el} selected={selected} />
    );
  }
  );


const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};


export const ArrowLeft = Arrow({ text: <i className="fas fa-chevron-left "></i>, className: 'arrow-prev' });
export const ArrowRight = Arrow({ text: <i className="fas fa-chevron-right "></i>, className: 'arrow-next' });


const STUDENR_PREVIOUS_EXAM = gql`
  mutation(
    $params:StudentPreviousPaperExam  
    ) {
        studentPreviousPaperExam(
        params: $params
     )
  }
`;

class PreviousPaperExamsHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alignCenter: true,
      clickWhenDrag: true,
      dragging: true,
      hideArrows: true,
      hideSingleArrow: true,
      itemsCount: uniqueYears.length,
      selected: Cookies.get("examid") == 1 ? '2022' : '2022',
      scrollToSelected: false,
      translate: -1,
      transition: 0.4,
      wheel: true,
      showList: true,
      inertiascrolling: false,
      slowdownFactor: 0.25,
      getTypeArr: [],
      mainType: { value: 1, label: 'Mains' },
      filterExams: [],
      currentActiveKey: "",
      expandval: "",
      userRestionModalShow: false
    };

    if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
      studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));

    }

    if (Cookies.get("examid") == 2) {
      filteredExams = studentGlobals.previousSets.filter((type) => type.pexamtype == "1");
    } else {
      filteredExams = studentGlobals.previousSets;
    }

    const uniqueArr = [... new Set(filteredExams.map(data => data.year))];
    uniqueYears = uniqueArr;
    console.log("uniqueArr", uniqueArr);

    this.menu = null;
    this.menuItems = Menu(uniqueYears.slice(0, uniqueYears.length), this.state.selected);
  }



  componentDidUpdate(prevProps, prevState) {
    const { alignCenter } = prevState;
    const { alignCenter: alignCenterNew } = this.state;
    if (alignCenter !== alignCenterNew && this.menu) {
      this.menu.setInitial();
      this.menu.forceUpdate();
      this.forceUpdate();
    }
  }

  componentDidMount() {

  };
  onUpdate = ({ translate }) => {
    console.log(`onUpdate: translate: ${translate}`);
    this.setState({ translate });
  };

  onSelect = key => {
    selectedYear = "";
    console.log(`onSelect: ${key}`);
    this.setState({ selected: key });
    selectedYear = key;
  };


  setSlowdownFactor = ev => {
    this.setState({ slowdownFactor: ev.target.value });
  };

  setSelected = ev => {
    selectedYear = "";
    const { value } = ev.target;
    this.setState({ selected: String(value) });
    selectedYear = String(value);
  };

  toggle = (index) => {
    if (this.state.currentActiveKey != index.toString()) {
      this.setState({ currentActiveKey: index })
    }
    else {
      this.setState({ currentActiveKey: "" })
    }

  }


  handleSelectMainType = (evalue) => {
    filteredExams = [];
    uniqueYears = [];
    const value = evalue
    let labelval = "";
    selectedYear = "";

    if (value == "1") {
      labelval = "Mains";
      if (Cookies.get("examid") == 1) {
        selectedYear = 2022
      } else {
        selectedYear = 2022
      }

    } else {
      labelval = "Advance"
      selectedYear = 2021
    }
    this.setState({
      mainType: {
        value: value,
        label: labelval,
      },
      selected: selectedYear,
      translate: -20
    });

    if (Cookies.get("examid") == 2) {
      filteredExams = studentGlobals.previousSets.filter((type) => type.pexamtype == value);
    } else {
      filteredExams = studentGlobals.previousSets;
    }

    const uniqueArr = [... new Set(filteredExams.map(data => data.year))];
    uniqueYears = uniqueArr;

    this.menuItems = Menu(uniqueYears.slice(0, uniqueYears.length), this.state.selected);
  }
  getblocks = (indexVal) => {
    if (this.state.expandval != indexVal.toString()) {
      this.setState({ expandval: indexVal })
    }
    else {
      this.setState({ expandval: "" })
    }
  }

  handleFormSubmit = (data) => {
    console.log(data)
    const params = {
      mobile: Cookies.get("mobile"),
      sub_type: data.year,
      exam_type: parseInt(Cookies.get("examid")),
      previous_exam_type: parseInt(data.exam),
      source: 0,
      set_id: parseInt(data.id),
    };

    this.customfunction(
      params
    ).catch(error => {
      console.log("catch if error");
      console.log(error);
      this.setState({
        submitError: error.graphQLErrors.map(x => x.message)
      });
      console.error("ERR =>", error.graphQLErrors.map(x => x.message));
    });

  };
  restrictionPopup = () => {
    this.setState({
      userRestionModalShow: true
    });
  }

  customfunction = async (
    params) => {
    await this.props.customfunction({
      variables: {
        params
      },
      update: (store, { data }) => {
        console.log("updatedata", data);
        if (data.studentPreviousPaperExam) {
          console.log("updatedatadataID", data.studentPreviousPaperExam)
          localStorage.setItem("sessionid", data.studentPreviousPaperExam);
          localStorage.setItem("type", "Previous Paper Exam");
          localStorage.setItem("stype", "");
          localStorage.setItem("exam_paper_id", "0");
          localStorage.setItem("etype", "previous");

          if (isMobile == true && Cookies.get("institution_id") == 30) {
            this.props.history.push({
              pathname: "/student/subject/exam",
            })
          } else {
            window.open("/student/subject/exam", "_blank");
          }
        }
      }
    });
  };
  render() {


    const {
      alignCenter,
      clickWhenDrag,
      hideArrows,
      dragging,
      hideSingleArrow,
      itemsCount,
      selected,
      translate,
      transition,
      wheel,
      showList,
      scrollToSelected,
      inertiascrolling,
      slowdownFactor,
    } = this.state;

    const menu = this.menuItems;
    let filterExamyear = filteredExams.filter((item) => item.year == this.state.selected);
    const uniqueMonths = [... new Set(filterExamyear.map(data1 => data1.examgroup.trimRight().trimLeft()))]

    return (
      <section className="previousPaperExams">
        <div className='typeSelection'>
          <Container>
            <Row >
              <Col sm={12}>
                {
                  Cookies.get("examid") == 2 ? (
                    <Row>
                      <Col sm={8}>
                        <ul className='list_style_types mt-4'>
                          <li className={this.state.mainType.value == "1" ? "active" : ""} onClick={() => this.handleSelectMainType('1')}>Mains</li>
                          <li className={this.state.mainType.value == "2" ? "active ml-4" : "ml-4"} onClick={() => this.handleSelectMainType('2')} >Advance</li>
                        </ul>
                      </Col>
                      <Col sm={4} className='mt-4'>
                        <div className="d-flex justify-content-end align-items-center">
                          <Link
                            to={{
                              pathname: "/student/subject/exam-history",
                              state: {
                                examtype: "previous_exam",
                                uniqueYears: uniqueYears
                              }
                            }}
                            className="btn btn-outline-primary hst-Btn">Exam History
                          </Link>
                        </div>
                      </Col>
                    </Row>

                  ) : (
                    <Row>
                      <Col>
                        <div className="d-flex justify-content-end align-items-center">
                          <Link
                            to={{
                              pathname: "/student/subject/exam-history",
                              state: {
                                examtype: "previous_exam",
                                uniqueYears: uniqueYears
                              }
                            }}
                            className="btn btn-outline-primary hst-Btn">Exam History
                          </Link>
                        </div>
                      </Col>
                    </Row>

                  )
                }
              </Col>
            </Row>
            <Row >
              <Col>
                {showList && (
                  <ScrollMenu
                    alignCenter={alignCenter}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    clickWhenDrag={clickWhenDrag}
                    data={menu}
                    dragging={dragging}
                    hideArrows={hideArrows}
                    hideSingleArrow={hideSingleArrow}
                    inertiaScrolling={inertiascrolling}
                    inertiaScrollingSlowdown={slowdownFactor}
                    //onFirstItemVisible={this.onFirstItemVisible}
                    //onLastItemVisible={this.onLastItemVisible}
                    onSelect={this.onSelect}
                    onUpdate={this.onUpdate}
                    ref={el => (this.menu = el)}
                    rtl={false}
                    scrollBy={0}
                    scrollToSelected={scrollToSelected}
                    selected={selected}
                    transition={+transition}
                    translate={translate}
                    useButtonRole={true}
                    wheel={wheel}
                  />
                )}
              </Col>
            </Row>
          </Container>
          <Container>
            <Accordion defaultActiveKey={0}>
              {
                uniqueMonths.map((item, index) => {
                  return (
                    <Row>
                      <Col sm={12}>
                        <React.Fragment key={index} className="border-0">
                          <div className={parseInt(this.state.expandval) == parseInt(index) ? "exams_block_view mt-3 active" : "exams_block_view mt-3"} onClick={(e) => this.getblocks(index)}>
                            <Accordion.Toggle
                              as={Card}
                              className='mt-2 border_none_fff '
                              eventKey={index}
                            >
                              <Row>
                                <Col sm={11}>
                                  <h5><span className='ml-3'>{item} {this.state.selected}</span></h5>
                                </Col>
                                <Col sm={1}>
                                  {parseInt(this.state.expandval) == parseInt(index) ?
                                    <i className="fas fa-chevron-up float-right res_arrow"></i>
                                    : <i className="fas fa-chevron-down float-right res_arrow"></i>
                                  }
                                </Col>
                              </Row>
                            </Accordion.Toggle>
                          </div>

                          <Accordion.Collapse eventKey={index}>
                            <Card as={Card.Body} className="p-2">
                              <Row>
                                {
                                  filteredExams.map((a) => {
                                    console.log("a", a);
                                    if (a.examgroup == item && a.year == this.state.selected) {
                                      return (
                                        <Col sm={4} className='mt-2'>
                                          <Card className='border_pad_card' style={{ cursor: "pointer" }}>
                                            <Card.Body className='p-2'>
                                              <h6 className='mt-2 mb-3 ml-1'>{a.qset}</h6>
                                              <p className='mt-2 mb-3 ml-1 durTxt'>Duration : <b>{a.exam_duration_min}</b>
                                                {a.enabled == false ? (
                                                  a.attempted == false ? (
                                                    <button type="button" class="btn-custom-blue float-right f-12 text-uppercase btn btn-primary" onClick={() => this.handleFormSubmit(a)}>Start Test</button>

                                                  ) : (
                                                    <button type="button" class="btn-custom-blue float-right f-12 text-uppercase btn btn-primary" onClick={() => this.handleFormSubmit(a)}>ReTake Test</button>

                                                  ))
                                                  : (
                                                    <button type="button" class="btn-custom-blue float-right f-12 text-uppercase btn btn-primary" onClick={() => this.restrictionPopup(a)}><Image src={require('../../../images/locked.png')} width="12" style={{ marginTop: "-4px", marginRight: "2px" }} alt="locked image" /> Start Test</button>

                                                  )
                                                }
                                              </p>
                                            </Card.Body>
                                          </Card>
                                        </Col>
                                      )
                                    }

                                  }
                                  )
                                  // ): (<Col sm={12}><h6 className='text-center'>Coming Soon...</h6></Col>)
                                }
                              </Row>
                            </Card>
                          </Accordion.Collapse>

                        </React.Fragment>
                      </Col>

                    </Row>
                  )


                })
              }
            </Accordion>
            <UserRestrictionAlert
              show={this.state.userRestionModalShow}
              onHide={() => this.setState({ userRestionModalShow: false })}
            />
          </Container>
        </div>
      </section>
    )
  }
}

export default withRouter(compose(graphql(STUDENR_PREVIOUS_EXAM, {
  name: "customfunction"
})
)(PreviousPaperExamsHome));