import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_liveClassess.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import LiveClassesModal from '../liveClasses/LiveClassesModal';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';


import FileText from "../../../images/images1/FileText.png";
import FileArrowDown from "../../../images/images1/FileArrowDown.png";
import Live from "../../../images/images1/Live.png"

import UserRestrictionAlertInstitute from '../home/UserRestrictionAlertInstitute';
import LockSimple from "../../../images/LockSimple.png";
import * as Cookies from "es-cookie";
import moment from 'moment';

//import Group3172 from "../../../images/images1/Group3172.png";



let QuestionData = [
    {
        DATE: "02-07-2021",
        ClassTimings: "2 PM to 3 PM",
        time: "14",
        Subject: "Mathematics",
        Chapter: "Trigonometry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("C7Ov9OxYimw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "2 PM to 3 PM",
        time: "14",
        Subject: "Mathematics",
        Chapter: "Introduction to Fundamental Mathematics",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("gXsLQuq-s7w"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "2 PM to 3 PM",
        time: "14",
        Subject: "Mathematics",
        Chapter: "First Year Topics - Briefing",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("JbUYOyPeuQs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "2 PM to 3 PM",
        time: "14",
        Subject: "Mathematics",
        Chapter: "Second Year Topics - Briefing",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("NUGUCLMWew"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Co-ordinate Geometry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_5022_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_8232_Sol.pdf"),
        VideoLecture: ("kmVxXYILmrc"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Trigonometric Ratios",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_6516_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_2283_Sol.pdf"),
        VideoLecture: ("Q0Jsy5pz24M"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "08-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Locus,Change of Axis",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_5325_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_3048_Sol.pdf"),
        VideoLecture: ("f35RKYDyCtg"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "08-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Compound Angels,Multiple and Sub-Multiple angels",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_5476_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_7453_Sol.pdf"),
        VideoLecture: ("Mqf2aS_g0uw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "09-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Straight Lines-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_5661_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_1347_Sol.pdf"),
        VideoLecture: ("qdGvpTbOGxM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "09-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Transformations and Trigonometric Identies",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_9328_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_84_Sol.pdf"),
        VideoLecture: ("SDabqaBGTww"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "10-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Straight Lines-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_7994_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_7941_Sol.pdf"),
        VideoLecture: ("9Ok_kxt-tvE"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "10-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Periodicity and Extreme Values,Hyperbolic Functions",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_4655_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_9043_Sol.pdf"),
        VideoLecture: ("P0hPiit6hCg"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "12-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Pair of Straight Lines-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_3091_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_1923_Sol.pdf"),
        VideoLecture: ("wDVkGHmLgC4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "12-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Trigonometric Equations",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_49_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_3367_Sol.pdf"),
        VideoLecture: ("nhe8LDN0LkU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "13-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Pair of Straight Lines-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_56_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_1833_Sol.pdf"),
        VideoLecture: ("RI-mkpxXWiM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "13-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Inverse Trigonometry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_5973_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_7318_Sol.pdf"),
        VideoLecture: ("NuDtzRN48f0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Three Dimensional Geometry,Direction Ratios and Direction Cosines",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_5807_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_4606_Sol.pdf"),
        VideoLecture: ("75-2CYdhFyA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Properties of Triangle-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_5414_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_8203_Sol.pdf"),
        VideoLecture: ("0aXTXLu0_uI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "3D-Lines,3D-Planes",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_2028_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_3178_Sol.pdf"),
        VideoLecture: ("Wf89btf3RS4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Properties of Triangle-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_8231_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_9353_Sol.pdf"),
        VideoLecture: ("PZy5hS0HyfE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Limits and Continuity-1",
        ShortNotes: "",
        Assignments: ("https://rizee.in/econtent/LiveClass/D9_6492_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_3711_Sol.pdf"),
        VideoLecture: ("iN6i624UBHA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Functions-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_9661_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_8891_Sol.pdf"),
        VideoLecture: ("B6doHRL_mn8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Limits and Continuity-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_8944_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_7003_Sol.pdf"),
        VideoLecture: ("-6uXjCZwAXk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Functions-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_8831_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_9317_Sol.pdf"),
        VideoLecture: ("r3dB0RnvSCc"),
        Live: (""),
        Zoom: (" "),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Differentiation-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_951_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_9748_Sol.pdf"),
        VideoLecture: ("G9KdV61HJmU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Mathematical Induction",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_4305_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_4483_Sol.pdf"),
        VideoLecture: ("_iRnOmzcuMM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Differentiation-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_4636_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_1786_Sol.pdf"),
        VideoLecture: ("XIkbRskJTPs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "4:30 PM to 6.00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Matrices-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_8322_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_618_Sol.pdf"),
        VideoLecture: ("yXe4gJx7c7Q"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Errors and Approximation,Rate of Change",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_496_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_2568_Sol.pdf"),
        VideoLecture: ("EnTJl4-V3S0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Matrices-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_3735_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_256_Sol.pdf"),
        VideoLecture: ("lGA3oN0_aec"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Tangent and Normals",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_1723_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_7158_Sol.pdf"),
        VideoLecture: ("HDaRUaKJugU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Addition of Vectors",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_3297_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_8162_Sol.pdf"),
        VideoLecture: ("4fSMV4UFPo0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Maxima and Minima,Mean value Theorem",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_9777_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_2047_Sol.pdf"),
        VideoLecture: ("Zwgjlsoongc"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Product of Vectors-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_1904_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_1304_Sol.pdf"),
        VideoLecture: ("I38lj4VJ1Ic"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Circles-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_1541_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_6834_Sol.pdf"),
        VideoLecture: ("a9w8MC2Ekqw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Product of Vectors-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_4201_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_6495_Sol.pdf"),
        VideoLecture: ("_-MuXrH4icI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Circles-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_7465_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_7768_Sol.pdf"),
        VideoLecture: ("A7DXsu3-MaU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Complex Numbers",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_8342_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_5199_Sol.pdf"),
        VideoLecture: ("0eEp_P0qZNA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "System of Circles",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_9761_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_8312_Sol.pdf"),
        VideoLecture: ("MrRlTpT3Yvk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "De Moiver's Theorem",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_1748_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_47_Sol.pdf"),
        VideoLecture: ("6JasQfKGSk8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Parabola",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_9822_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_1262_Sol.pdf"),
        VideoLecture: ("GVn_SOxdFWk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Quadratic Euations",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_1959_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_4673_Sol.pdf"),
        VideoLecture: ("wr4HDu0l1Bo"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Ellipse",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_162_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_4689_Sol.pdf"),
        VideoLecture: ("y9DSineLLT4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Theorey of Equations",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_9384_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_5265_Sol.pdf"),
        VideoLecture: ("uK98wWS7dX4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Hyperbola",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_6606_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_7416_Sol.pdf"),
        VideoLecture: ("X2Cung2oliM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Permutations and Combinations",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_5142_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_6445_Sol.pdf"),
        VideoLecture: ("BKGoy4K0mDA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Integration-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_647_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_2089_Sol.pdf"),
        VideoLecture: ("2DK65zLVyxA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Probability-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_2113_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_6375_Sol.pdf"),
        VideoLecture: ("x4_Bb2fqoS4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Integration-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_7408_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_8671_Sol.pdf"),
        VideoLecture: ("SkIwkA_9pLY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Probability-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_7089_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_6512_Sol.pdf"),
        VideoLecture: ("GOqksAY-KaA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Definite Integration-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_2823_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_9798_Sol.pdf"),
        VideoLecture: ("C95geHme_PI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Random Variables",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_5782_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_896_Sol.pdf"),
        VideoLecture: ("RxGT2jbb-YQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Definte Integration-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_8342_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_8106_Sol.pdf"),
        VideoLecture: ("mjjiaZZOxS4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Partial Fractions",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_267_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_7926_Sol.pdf"),
        VideoLecture: ("_g_3hQxSUWE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Differential Equations-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_6967_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_7024_Sol.pdf"),
        VideoLecture: ("KextXf8FdFg"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Binomial Theorem",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_325_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_4417_Sol.pdf"),
        VideoLecture: ("sTyJETXCV_o"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Mathematics",
        Chapter: "Differential Equations-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_354_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_5134_Sol.pdf"),
        VideoLecture: ("ZtilXbBldkM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "4:30 PM to 6:00 PM",
        time: "16",
        Subject: "Mathematics",
        Chapter: "Measuers of Dispersion",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_4508_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_264_Sol.pdf"),
        VideoLecture: ("tWixiA7U5QY"),
        Live: (""),
        Zoom: (""),
    },
];

let LiveClassesDataMaths = []

for (var i in QuestionData) {
    let currentDate = moment().format("DD/MM/YYYY");
    if ((QuestionData[i]["VideoLecture"] || QuestionData[i]["Live"] || QuestionData[i]["Zoom"]) && (currentDate == moment(QuestionData[i].DATE, "DD-MM-YYYY").format("DD/MM/YYYY"))) {
        LiveClassesDataMaths.push(QuestionData[i]);
    }
}

var Zoom;
var Livevideo;


class MathsDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            dataLiveUrl: "",
            userRestionModalShow: false,
            allLiveClasses: true
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }

    modalFunSubscribed = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7 && Cookies.get("institution_id") == 30 && this.isStudentUserValid.current_plan_id == "23") {
            this.setState({
                userRestionModalShow: true,
            });
        } else {
            this.setState({
                liveModalShow: true,
                dataLiveUrl: row.Live

            });
        }



    }
    checkForDate = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7) {
            return true
        }
        return false
    }

    modalaFun = (row) => {
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });

    }

    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }
    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    sortFuncDate(a, b, order, dataField, rowA, rowB) {
        if (order == 'desc') {

            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }
    actionFormatterAssignments(cell, row, rowIndex) {
        return (
            <>
                {
                    row.Assignments != "" ?
                        <div className="Assignments">
                            <img src={FileText} alt="file-text-img" ></img>
                            <a href={row.Assignments} target="_blank"> Assignment Download</a>
                        </div> : <h6 className="text-center">-</h6>
                }

                {
                    row.Solutions != "" ?
                        <div className="Solutions">
                            <img src={FileArrowDown} alt="File-Arrowdown-img"></img>
                            <a href={row.Solutions} target="_blank" > Solutions</a>
                        </div> : ""
                }
            </>

        )
    }

    actionFormatterVideoLecture(cell, row, rowIndex) {

        return (
            <>
                <div className="allsubjects">
                    {
                        row.Live == "" ?

                            row.VideoLecture != "" ?
                                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                : "-"


                            :
                            <>

                                {
                                    <a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-success zoom_effect effect-1">Zoom</button></a>
                                }
                                {
                                    <button type="button" className="btn btn-warning live live_effect effect-1" onClick={() => this.modalaFun(row.Live)}>Live</button>
                                }



                            </>
                    }

                </div>

            </>
        )
    }


    colFunction() {

        let columns = [
            // {
            //     dataField: "DATE",
            //     text: "Date",
            //     sort: true,
            //     headerStyle: {
            //         width: "13%"
            //     },
            //     style: {
            //         fontWeight: "bold !important"
            //     },
            //     sortFunc: this.sortFuncDate.bind(this)
            // },
            // {
            //     dataField: "ClassTimings",
            //     text: "Class Timings",
            //     sort: true,
            //     headerStyle: {
            //         width: "23%"
            //     },
            //     sortFunc: this.sortFuncMulti.bind(this)
            // },

            {
                dataField: "Subject",
                text: "Subject",
                style: (row, rowIndex) => {
                    return { fontWeight: "bold !important" };
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                    width: "35%"
                }
            },
            {
                dataField: "Assignments/Solution",
                text: "Assignment/Solution",
                headerStyle: {
                    width: "55%"
                },

                formatter: this.actionFormatterAssignments.bind(this)
            },
            // {
            //     dataField: "Solutions",
            //     text: "Solutions",
            //     formatter: this.actionFormatterSolutions
            // },
            {
                dataField: "Videos/Recorded/Live",
                text: "Videos(Recorded/Live)",
                formatter: this.actionFormatterVideoLecture.bind(this)
            },
            // {
            //     dataField: "Live/Zoom",
            //     text: "Live/Zoom",
            //     formatter: this.actionFormatterLive
            // },
        ];
        return columns;
    }

    render() {
        return (
            <Row>
                <Col align="right" sm={12}>
                    <h6 className="switch_button_cls mt-res-4">
                        <p className="allClassesAlignment">All Classes</p>
                        <label class="switch">
                            <input type="checkbox"
                                onChange={() => {

                                    this.setState((state) => {

                                        return {
                                            allLiveClasses: !state.allLiveClasses
                                        }
                                    })
                                }}
                                checked={!this.state.allLiveClasses} />
                            <span class="slider round"></span>
                        </label>
                        <p className="liveClassesAlignment">Live Classes</p>
                    </h6>
                </Col>
                <Col sm={12}>
                    {
                        this.state.allLiveClasses ?
                            <CardLessDataTableWithSearch
                                parentData={QuestionData}
                                particlecolumns={this.colFunction()}
                                defaultSorted={[{
                                    dataField: "ClassTimings",
                                    order: "asc"
                                }]}
                            />
                            :
                            <CardLessDataTableWithSearch
                                parentData={LiveClassesDataMaths}
                                particlecolumns={this.colFunction()}
                                defaultSorted={[{
                                    dataField: "ClassTimings",
                                    order: "asc"
                                }]}
                            />
                    }
                    {/* <Card>
                        <BootstrapTable
                            keyField="id"
                            columns={this.colFunction()}
                            data={QuestionData}
                            defaultSorted={[{
                                dataField: "ClassTimings",
                                order: "asc"
                            }]}
                            wrapperClasses="table-responsive"
                            bootstrap4

                        />
                    </Card> */}

                    <LiveClassesModal data={this.state.dataLiveUrl} show={this.state.liveModalShow} onHide={() => this.setState({ liveModalShow: false })} />
                    <UserRestrictionAlertInstitute
                        show={this.state.userRestionModalShow}
                        onHide={() => this.setState({ userRestionModalShow: false })} />
                </Col>
            </Row>

        )
    }

} export default MathsDataSection