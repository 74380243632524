import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import CollegeRankPredictorHome from '../components/collegeRankPredictor/collegeRankPredictorHome';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withApollo } from "react-apollo";
import { Row, Col, Image, Container } from 'react-bootstrap';
import NTAPredictorSection from '../components/NTA_college_predictor/NtaCollegePredictorSection'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import ReactGA from 'react-ga';

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

const GET_QUOTA_NAMES = gql`
query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int){
    getNTAPredictorQuota(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type) {
        id
        quota_name
      }
}
`;

const GET_SEAT_TYPES = gql`
query($exam_id:Int,$year:Int,$exam_type:Int,$b_arch_type:Int,$quota_Ids:String){
    getNTAPredictorSeatTypes(exam_id: $exam_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,quota_Ids:$quota_Ids) {
        id
        seat_type
      }
}
`;

const GET_COLLEGE_TYPES = gql`
query($exam_id:Int,$state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
    getNTAPredictorCollegeTypes(exam_id:$exam_id,state_id:$state_id,exam_type:$exam_type,b_arch_type:$b_arch_type,year:$year,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
        id
        college_type
        enabled
      }
}
`;

const GET_STATES = gql`
query($year:Int,$exam_id:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String){
    getStates(year:$year,exam_id:$exam_id,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids){
        state_id
        state_name
      }
}
`;

const GET_COLLEGES = gql`
query($exam_id:Int , $state_id:String, $college_type:String ,$branch_id:String,$year:Int,$exam_type:Int,$b_arch_type:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
    getNTAPredictorColleges(exam_id:$exam_id ,state_id:$state_id,college_type:$college_type,branch_id:$branch_id,year:$year,exam_type:$exam_type,b_arch_type:$b_arch_type,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
        id
        college_name
        enabled
      }
}
`;

const GET_YEARS = gql`
query($exam_id:Int){
    getNTAPredictorYears(exam_id:$exam_id){
        name
        year
      }
}
`;

const GET_ROUNDS = gql`
query($id:Int){
    getNTAPredictorRounds(id:$id){
        id
        round
      }
}
`

const GET_BRANCHES = gql`
query($exam_id:Int, $college_type:String , $state_id:String,$exam_type:Int,$b_arch_type:Int,$year:Int,$degree_type:Int,$quota_Ids:String,$seat_type_Ids:String,$mobile:String){
    getNTAPredictorBranches(exam_id:$exam_id ,college_type:$college_type,state_id:$state_id,b_arch_type:$b_arch_type,exam_type:$exam_type,year:$year,degree_type:$degree_type,quota_Ids:$quota_Ids,seat_type_Ids:$seat_type_Ids,mobile:$mobile){
        id
        branch_name
        branch_logo
        enabled
      }
}
`;

const GET_GENDERTYPES = gql`
query($id:Int){
    getNtaGenderTypes(id:$id){
        id
        gender_type
      }
}
`;

class NtaCollegePredictor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggled: isMobile == true && Cookies.get("institution_id") == 30 ? "wrapper" : "wrapper sidebar-enable",
            headerBottomImg: {
                type: "col_pred",
            },
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }

    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    componentDidMount = () => {
        Cookies.set("toggle", "wrapper")

        this.addStudentAnalytics();
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 241,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    render() {

        const getNTAPredictorQuota = this.props.getNTAPredictorQuota;
        const loading1 = getNTAPredictorQuota.loading;
        const error1 = getNTAPredictorQuota.error;

        const getNTAPredictorSeatTypes = this.props.getNTAPredictorSeatTypes;
        const loading2 = getNTAPredictorSeatTypes.loading;
        const error2 = getNTAPredictorSeatTypes.error;

        const getNTAPredictorCollegeTypes = this.props.getNTAPredictorCollegeTypes;
        const loading3 = getNTAPredictorCollegeTypes.loading;
        const error3 = getNTAPredictorCollegeTypes.error;

        const getStates = this.props.getStates;
        const loading4 = getStates.loading;
        const error4 = getStates.error

        const getNTAPredictorColleges = this.props.getNTAPredictorColleges
        const loading5 = getNTAPredictorColleges.loading
        const error5 = getNTAPredictorColleges.error

        const getNTAPredictorYears = this.props.getNTAPredictorYears
        const loading6 = getNTAPredictorYears.loading
        const error6 = getNTAPredictorYears.error

        const getNTAPredictorRounds = this.props.getNTAPredictorRounds
        const loading7 = getNTAPredictorRounds.loading;
        const error7 = getNTAPredictorRounds.error

        const getNTAPredictorBranches = this.props.getNTAPredictorBranches;
        const loading8 = getNTAPredictorBranches.loading
        const error8 = getNTAPredictorBranches.error

        const getNtaGenderTypes = this.props.getNtaGenderTypes;
        const loading9 = getNtaGenderTypes.loading;
        const error9 = getNtaGenderTypes.error

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }
        if (error3 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }
        if (error4 !== undefined) {
            alert("Server Error. " + error4.message);
            return null;
        }

        if (error5 !== undefined) {
            alert("Server Error. " + error5.message);
            return null;
        }
        if (error6 !== undefined) {
            alert("Server Error. " + error6.message);
            return null;
        }
        if (error7 !== undefined) {
            alert("Server Error. " + error7.message);
            return null;
        }

        if (error8 !== undefined) {
            alert("Server Error. " + error8.message);
            return null;
        }

        if (error9 !== undefined) {
            alert("Server Error. " + error9.message);
            return null;
        }
        return (
            <div className={this.state.toggled}>
                <div className="student main-wrapper">
                    <div className="student header-area col_pred">
                        <ScrollTopNavbar
                            headerBottom={this.state.headerBottomImg}
                            onClick={() => this.menuToggler()} />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper-pad0">
                        {
                            (loading1) == true || (loading2) == true || (loading3) == true || (loading4) == true || (loading5) == true || (loading6) == true || (loading7) == true || (loading8) == true || (loading9) == true ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <NTAPredictorSection
                                        getNTAPredictorQuota={getNTAPredictorQuota.getNTAPredictorQuota}
                                        getNTAPredictorSeatTypes={getNTAPredictorSeatTypes.getNTAPredictorSeatTypes}
                                        getNTAPredictorCollegeTypes={getNTAPredictorCollegeTypes.getNTAPredictorCollegeTypes}
                                        getStates={getStates.getStates}
                                        getNTAPredictorColleges={getNTAPredictorColleges.getNTAPredictorColleges}
                                        getNTAPredictorYears={getNTAPredictorYears.getNTAPredictorYears}
                                        getNTAPredictorRounds={getNTAPredictorRounds.getNTAPredictorRounds}
                                        getNTAPredictorBranches={getNTAPredictorBranches.getNTAPredictorBranches}
                                        getNtaGenderTypes={getNtaGenderTypes.getNtaGenderTypes}
                                    />
                                )
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(withApollo(compose(

    graphql(GET_QUOTA_NAMES,
        {
            options: props => ({
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    exam_type: 1,
                    b_arch_type: 0,
                    year: 2021
                },
                fetchPolicy: "network-only"
            }), name: "getNTAPredictorQuota"
        }),
    graphql(GET_SEAT_TYPES,
        {
            options: props => ({
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    exam_type: 1,
                    b_arch_type: 0,
                    year: 2021,
                    quota_Ids: ""
                },
                fetchPolicy: "network-only"
            }), name: "getNTAPredictorSeatTypes"
        }),
    graphql(GET_COLLEGE_TYPES,
        {
            options: props => ({
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    state_id: "",
                    exam_type: 1,
                    b_arch_type: 0,
                    year: 2021,
                    quota_Ids: "",
                    seat_type_Ids: "",
                    mobile: Cookies.get("mobile")
                },
                fetchPolicy: "network-only"
            }), name: "getNTAPredictorCollegeTypes"
        }),
    graphql(GET_STATES,
        {
            options: props => ({
                variables: {
                    year: 2021,
                    exam_id: parseInt(Cookies.get("examid")),
                    exam_type: 1,
                    b_arch_type: 0,
                    degree_type: 1,
                    quota_Ids: "",
                    seat_type_Ids: "",
                },
                fetchPolicy: "network-only"
            }), name: "getStates"
        }),
    graphql(GET_COLLEGES,
        {
            options: props => ({
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    state_id: "",
                    college_type: "",
                    branch_id: "",
                    year: 2021,
                    b_arch_type: 0,
                    exam_type: 1,
                    degree_type: 1,
                    quota_Ids: "",
                    seat_type_Ids: "",
                    mobile:Cookies.get("mobile")
                },
                fetchPolicy: "network-only"
            }), name: "getNTAPredictorColleges"
        }),
    graphql(GET_YEARS,
        {
            options: props => ({
                variables: {
                    exam_id: parseInt(Cookies.get("examid"))
                },
                fetchPolicy: "network-only"
            }), name: "getNTAPredictorYears"
        }),
    graphql(GET_ROUNDS,
        {
            options: props => ({
                variables: {
                    id: 0
                },
                fetchPolicy: "network-only"
            }), name: "getNTAPredictorRounds"
        }),
    graphql(GET_GENDERTYPES,
        {
            options: props => ({
                variables: {
                    id: 0
                },
                fetchPolicy: "network-only"
            }), name: "getNtaGenderTypes"
        }),
    graphql(GET_BRANCHES,
        {
            options: props => ({
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    college_type: "",
                    state_id: "",
                    exam_type: 1,
                    b_arch_type: 0,
                    year: 2021,
                    degree_type: 1,
                    quota_Ids: "",
                    seat_type_Ids: "",
                    mobile: Cookies.get("mobile")
                },
                fetchPolicy: "network-only"
            }), name: "getNTAPredictorBranches"
        }),
        graphql(UPDATE_STUDENT_ANALYTICS, {
            name: "submitpt"
        }),
)(NtaCollegePredictor)));
