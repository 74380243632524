var jwt = require('jsonwebtoken');
export function tokenChecking(token) {
  if (token == undefined) {
    this.props.history.push("/student/login");
  }
  else {
    // jwt.decode(token, { json: true }, function (err, decoded) {
    //   if (err) {
    //     console.log("err", err);
    //     /*
    //       err = {
    //         name: 'TokenExpiredError',
    //         message: 'jwt expired',
    //         expiredAt: 1408621000
    //       }
    //     */
    //   }
    //   else {
    //     console.log("elseelseelse");
    //   }
    // });

  }

  let expiry = jwt.decode(token, { json: true });
  console.log(expiry);
  if (expiry.exp > Date.now() / 1000) {
    return true
  }
  return false
}

export function tokenCheckingForValidity(token) {
  if (token == undefined) {
    this.props.history.push("/student/login");
  }
  else {
    let expiry = jwt.decode(token, { json: true });
    console.log(expiry);
    if (expiry.exp > Date.now() / 1000) {
      return true
    }
    return false

    }
            
    
}