import React, { Component } from 'react'
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap'
import { withRouter } from "react-router-dom";
import Chemistry from '../../../../images/chemistry_icon.png';
import Zoology from '../../../../images/zoology_icon.png';
import Physics from '../../../../images/physics_icon.png';
import Maths from '../../../../images/maths_icon.png';
import Botany from '../../../../images/botany_icon.png';
import All from '../../../../images/all_icon.png';
import CardLessDataTableWithSearch from '../../data_tables/CardLessDataTableWithSearch';
import './_foundationVideosSection.scss';
import moment from 'moment';
import livevideo from '../../../../images/video_live.png'

import * as Cookies from "es-cookie";
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../../pages/GoogleAnalytics';
import WatchNowVideosModal from '../../Eamcet_Crash_Course/eamcet_liveClasses/WatchNowVideosModal';
import SyllabusPdfTable from './syllabusPdfTable';
import EamcetRestrictionAlert from "../../home/EamcetRestrictionAlert";
import UserRestrictionAlert from "../../home/UserRestrictionAlert";


class FoundationVideoClasses extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allLiveClasses: "all",
            search: "",
            selectedSubjectVal: 0,
            CoursesMainData: [],
            LiveClassesCoursesData: [],
            getSubjectsData: [],
            showPdf: false,
            pdfUrl: "",
            classId: this?.props?.propsData?.location?.state?.classValue,
            syllabusType: this?.props?.propsData?.location?.state?.syllabusType,
            userRestionModalShow: false
        };
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }

    componentDidMount = () => {

        if (this.props.stateData.getSubjectsArr != undefined || this.props.stateData.getSubjectsArr != null) {
            const getSubjects = this.props.stateData.getSubjectsArr.map((gmap, index) => {
                if (index == 0) {
                    return { ...gmap, active: "active" }
                }
                else {
                    return { ...gmap, active: "" }
                }
            })
            this.setState({
                getSubjectsData: getSubjects
            });
        }
    }


    handleSelect = (subid) => {
        this.setState({
            CoursesMainData: [],
            LiveClassesCoursesData: []
        });
        const getSubjects = this.props.stateData.getSubjectsArr.map((gmap, index) => {
            console.log("gmap");
            if (gmap.id == subid) {
                return { ...gmap, active: "active" }
            }
            else {
                return { ...gmap, active: "" }
            }
        })
        this.setState({
            getSubjectsData: getSubjects,
            selectedSubjectVal: subid
        });
    }

    handleClasses = (val) => {
        console.log(val)
        this.setState({
            allLiveClasses: val,
            showPdf: false
        });
    }

    modalaFun = (row) => {
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row
        });
    }

    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }

    subjectClassName(data) {
        console.log("dta", data);
        let classname = "";
        if (data.subject == "Botany") {
            classname = `block_white botany mobile_card_eamcet  ${data.active}`
        }
        else if (data.subject == "Physics") {
            classname = `block_white physics mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Chemistry") {
            classname = `block_white chemistry mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Zoology") {
            classname = `block_white zoology mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Maths") {
            classname = `block_white maths mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "All") {
            classname = `block_white all mobile_card_eamcet ${data.active}`
        }
        return classname;
    }

    handleSelect = (subid) => {
        this.setState({
            CoursesMainData: [],
            LiveClassesCoursesData: []
        });
        const getSubjects = this.props.stateData.getSubjectsArr.map((gmap, index) => {
            console.log("gmap");
            if (gmap.id == subid) {
                return { ...gmap, active: "active" }
            }
            else {
                return { ...gmap, active: "" }
            }
        })


        this.setState({
            getSubjectsData: getSubjects,
            selectedSubjectVal: subid,
            showPdf: false
        });
    }
    handlePdfView = (ppt) => {
        this.setState({
            showPdf: !this.state.showPdf,
            pdfUrl: ppt
        });
    }
    PDFFun = (info, index) => {
        let isStudentUserValid = ""
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        return (
            this.handlePdfView(info)
        )
    }
    getAccessPlans = (planId, class_id) => {
        if (class_id == 1) {
            if (this.props.getServiceData[0].enabled_XI == true) {
                return false
            } else {
                return true
            }
        } else if (class_id == 2) {
            if (this.props.getServiceData[0].enabled_XII == true) {
                return false
            } else {
                return true
            }

        }

    }

    sortFuncDate(a, b, order, dataField, rowA, rowB) {
        if (order == 'desc') {
            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }

    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }
            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }
    }
    actionFormatterPpt(cell, row, rowIndex) {
        return (
            <>
                <div className='text-center'>
                    {
                        row.Pdf != "" ?
                            (
                                this.getAccessPlans(this.isStudentUserValid.current_plan_id, this.state.classId) || row.freeAccess == "true" ?
                                    (<button className='btn pdf_button' onClick={() => this.PDFFun(row.Pdf)}>VIEW NOTES</button>)
                                    :
                                    (<button className='btn pdf_button' onClick={() => this.modalFunAssignments()}>VIEW NOTES</button>)

                            )
                            :
                            (<p>Coming soon</p>)
                    }
                </div>
            </>
        )
    }


    actionFormatterVideoLecture(cell, row, rowIndex) {
        let currentDate = moment().format("DD-MM-YYYY");
        let currentMonth = moment().format('MM')
        let currentYear = moment().format('YYYY')
        let currentRowMonth = moment(row.DATE, "DD-MM-YYYY").format("MM")
        let currentRowYear = moment(row.DATE, "DD-MM-YYYY").format("YYYY")


        let date1 = moment(row.time, "h:mm").format("H:mm");
        let date2 = moment(date1, "h:mm").add(100, 'm').format("H:mm");
        let date3 = moment().format("H:mm");
        let date4 = moment(date2, " HH:mm").diff(moment(date3, " HH:mm"));


        return (
            <>
                <div className="text-center">
                    {
                        this.getAccessPlans(this.isStudentUserValid.current_plan_id, this.state.classId) || row.freeAccess == "true" ?
                           
                            (currentRowYear == currentYear ?
                                (
                                    (currentMonth == currentRowMonth) ?
                                        (
                                            currentDate == row.DATE && date4 > 0 ?

                                                (
                                                    <a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-custom-red"><span><img src={livevideo} className="fc_live" /></span>Live Now</button></a>

                                                )
                                                :
                                                (
                                                    currentDate < row.DATE ?
                                                        (
                                                            currentDate == row.DATE && date4 < 0 ? (
                                                                <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                                            ) : (
                                                                <button type="button" className="btn btn-custom-disabled-cls effect" disabled>Live Now</button>
                                                            )
                                                        ) :
                                                        (
                                                            <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                                        )
                                                )
                                        )
                                        :
                                        (
                                            currentMonth < currentRowMonth ? (
                                                <button type="button" className="btn btn-custom-disabled-cls effect" disabled>Live Now</button>
                                            ) : (
                                                <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
                                            )
                                        )
                                )
                                :
                                (
                                    <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
                                )
                            )
                            : 
                            (
                                <a className="" onClick={() => this.modalFunAssignments()} >
                                    <button type="button" className="btn subscribe-button">Subscribe</button>
                                </a>
                            )
                            
                    }
                </div>
            </>
        )
    }

    colFunction(val) {
        let columns = [
            {
                dataField: "DATE",
                text: "Date",
                sort: true,
                headerStyle: {
                    width: "12%", background: "#E1E1E1", color: "#000000"
                },
                style: {
                    fontWeight: "bold !important"
                },
                sortFunc: this.sortFuncDate.bind(this)
            },
            {
                dataField: "ClassTimings",
                text: "Class Timings",
                sort: true,
                headerStyle: {
                    width: "10%", height: "10%", background: "#E1E1E1", color: "#000000"
                },
                sortFunc: this.sortFuncMulti.bind(this)
            },
            {
                dataField: "Subject",
                text: "Subject",
                headerStyle: {
                    width: "13%", height: "10%", background: "#E1E1E1", color: "#000000"
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                    width: "29%", height: "10%", background: "#E1E1E1", color: "#000000"
                }
            },
            {
                dataField: "VideoLecture",
                text: "Video Lecture",
                headerStyle: {
                    width: "20%", height: "10%", background: "#E1E1E1", color: "#000000"
                },
                formatter: this.actionFormatterVideoLecture.bind(this),
            },
            {
                dataField: "Pdf",
                text: "Booklets",
                headerStyle: {
                    width: "25%", height: "10%", background: "#E1E1E1", color: "#0000003"
                },
                formatter: this.actionFormatterPpt.bind(this)

            }
        ];
        return columns;
    }
    colSubjectFunction(val) {
        let columns = [
            {
                dataField: "DATE",
                text: "Date",
                sort: true,
                headerStyle: {
                    width: "12%", background: "#E1E1E1", color: "#000000"
                },
                style: {
                    fontWeight: "bold !important"
                },
                sortFunc: this.sortFuncDate.bind(this)
            },
            {
                dataField: "ClassTimings",
                text: "Class Timings",
                sort: true,
                headerStyle: {
                    width: "10%", background: "#E1E1E1", color: "#000000"
                },
                sortFunc: this.sortFuncMulti.bind(this)
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                    width: "30%", background: "#E1E1E1", color: "#000000"
                }
            },

            {
                dataField: "VideoLecture",
                text: "Video Lecture",
                headerStyle: {
                    width: "22%", background: "#E1E1E1", color: "#000000"
                },
                formatter: this.actionFormatterVideoLecture.bind(this),

            },
            {
                dataField: "Pdf",
                text: "Booklets",
                headerStyle: {
                    width: "25%", background: "#E1E1E1", color: "#000000"
                },
                formatter: this.actionFormatterPpt.bind(this)
            }

        ];
        return columns;
    }

    render() {

        console.log("this.state.classId", this.state.classId)
        const getServiceData = this.props.getServiceData

        let subjectData = [];

        if (this.state.selectedSubjectVal == 0) {
            subjectData = getServiceData.length > 0 && getServiceData[0].data != null && getServiceData[0].data.filter((item) => item.Class == this.state.classId && item.Type == this.state.syllabusType)
        } else if (this.state.selectedSubjectVal != 0) {
            subjectData = getServiceData.length > 0 && getServiceData[0].data != null && getServiceData[0].data.filter((item) => item.Class == this.state.classId && item.Type == this.state.syllabusType && item.Subject_id == this.state.selectedSubjectVal)
        }

        this.state.CoursesMainData = subjectData

        let currentDate = moment().format("DD/MM/YYYY");
        let defaultLiveClassesCourses = [];
        for (var i in subjectData) {
            if (currentDate == moment(subjectData[i].DATE, "DD-MM-YYYY").format("DD/MM/YYYY")) {
                if (this.state.allLiveClasses == "today") {
                    console.log('subjectData[i]', subjectData[i]);
                    defaultLiveClassesCourses.push(subjectData[i])
                    console.log("subjectData", defaultLiveClassesCourses);
                    this.state.LiveClassesCoursesData = defaultLiveClassesCourses;
                }

            }
        }

        return (
            <Container className='foundation_classes'>
                <Row>
                    <Col sm={12} className="mt-2">
                        <div className='subject-blocks-view'>
                            {
                                this.state.getSubjectsData.map(sub =>
                                    <div className={this.subjectClassName(sub)} onClick={() => this.handleSelect(sub.id)}  >
                                        <Row>
                                            <Col sm={6}>
                                                <h5 className='mt-2'>{sub.subject}</h5>
                                            </Col>
                                            <Col sm={6}>
                                                {
                                                    sub.id == "0" ?
                                                        (<Card.Img variant="top" src={All} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "1" ?
                                                        (<Card.Img variant="top" src={Botany} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "2" ?
                                                        (<Card.Img variant="top" src={Physics} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "3" ?
                                                        (<Card.Img variant="top" src={Chemistry} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "5" ?
                                                        (<Card.Img variant="top" src={Zoology} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "4" ?
                                                        (<Card.Img variant="top" src={Maths} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            }
                        </div>
                    </Col>
                </Row>

                <Row className='mt-2'>
                    <Col sm={8}>
                        <ul className='list-inline class_type_li mt-4'>
                            <li className={this.state.allLiveClasses == "all" ? "active" : ""} onClick={() => this.handleClasses("all")}>All Classes</li>
                            <li className={this.state.allLiveClasses == "today" ? "active ml-4" : "ml-4"} onClick={() => this.handleClasses("today")}>Today Classes</li>
                        </ul>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} className="mobile_table_resp" >
                        {
                            this.state.CoursesMainData == "" && this.state.allLiveClasses == "all" ?
                                (
                                    <div className="table-responsive fontWeightTDs">
                                        <table className="table  table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Class Timings</th>
                                                    <th>Subject</th>
                                                    <th>Chapter</th>
                                                    <th>Videos(Recorded/Live)</th>
                                                    <th>Booklets</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={7} className='text-center'>Coming Soon</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                                :
                                (
                                    <>
                                        {
                                            !this.state.showPdf ?
                                                (
                                                    this.state.allLiveClasses == "all" ?
                                                        <CardLessDataTableWithSearch
                                                            parentData={this.state.CoursesMainData}
                                                            particlecolumns={this.colFunction()}
                                                            defaultSorted={[{
                                                                dataField: "ClassTimings",
                                                                order: "asc"
                                                            }]}
                                                            searchBar={true}

                                                        />
                                                        :
                                                        ("")
                                                )
                                                :
                                                ("")



                                        }
                                    </>
                                )
                        }
                        {
                            this.state.LiveClassesCoursesData == "" && this.state.allLiveClasses == "today" ?
                                (
                                    <div className="table-responsive fontWeightTDs">
                                        <table className="table  table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Class Timings</th>
                                                    <th>Subject</th>
                                                    <th>Chapter</th>
                                                    <th>Videos(Recorded/Live)</th>
                                                    <th>Booklets</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={7} className='text-center'>You don't have any classes Today....</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                                :
                                (
                                    <>
                                        {
                                            !this.state.showPdf ?
                                                (
                                                    this.state.allLiveClasses == "today" ?
                                                        <CardLessDataTableWithSearch
                                                            parentData={this.state.LiveClassesCoursesData}
                                                            particlecolumns={this.colFunction()}
                                                            defaultSorted={[{
                                                                dataField: "ClassTimings",
                                                                order: "asc"
                                                            }]}
                                                            searchBar={true}

                                                        />
                                                        :
                                                        ("")

                                                )
                                                :
                                                ("")

                                        }
                                    </>
                                )
                        }
                    </Col>
                </Row>
                <WatchNowVideosModal data={this.state.dataLiveUrl}
                    show={this.state.liveModalShow}
                    onHide={() => this.setState({ liveModalShow: false })}
                />
                <SyllabusPdfTable
                    showPdf={this.state.showPdf}
                    pdfUrl={this.state.pdfUrl}
                    handlePdfView={this.handlePdfView}
                />
                {
                    Cookies.get("examid") == 1 || Cookies.get("examid") == 2 ?
                        (
                            <UserRestrictionAlert
                                show={this.state.userRestionModalShow}
                                onHide={() => this.setState({ userRestionModalShow: false })}
                                planDetailsShow="Neet_Jee_foundation"
                            />
                        )
                        :
                        (
                            <EamcetRestrictionAlert
                                show={this.state.userRestionModalShow}
                                onHide={() => this.setState({ userRestionModalShow: false })}
                            />
                        )
                }
            </Container>
        )
    }
}

export default FoundationVideoClasses