import React, { Component } from 'react'
import { components } from 'react-select'
import Select from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars'
import { Row, Col, Card, Table, Form, ButtonGroup, Button } from 'react-bootstrap'
import BreadcrumbCustomQuestionTwo from '../../../../breadcrumbs/BreadcrumbCustomQuestionTwo'
import SelectDropDown from '../../../../selectdropdown/SelectDropDown';
import QuestionModal from '../../QuestionModal';
import DownloadQuestionPaperModal from '../../../../download_question_paper/DownloadQuestionPaperModal';
import { Link } from 'react-router-dom';

import './_ownquestiontype.scss';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as Cookies from "es-cookie";
import * as compose from 'lodash.flowright';
import parse, { domToReact } from 'html-react-parser';
import PreloaderTwo from '../../../../preloader/PreloaderTwo';
const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        width: '3px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
};
const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
};

const FETCH_INSTITUTIONQUESTIONS = gql` 
query($subject:String!,
$chapter:String!,
$question_type:String,
$question_theory:String,
    $institute_id: Int!) {
    getInstituteQuestions(
        subject: $subject,
        chapter: $chapter,
        question_type: $question_type,
        question_theory: $question_theory,
        institute_id: $institute_id){
            id
            subject
            question
            chapter
            topic
            complexity
            question_theory
            qtype
        
    }
}

`;
class QuestionComponent extends Component {
    constructor(props) {
        super(props)
        // const getinsQuestions = props.getInstituteQuestions.map((item) => {
        //     return { ...item, checked: true }
        // })
        console.log("QuestionComponent", props);
        let clsdata = props.globals.globals.classes.map((item) => {
            if (item.id == "1") {
                return { ...item, active: "outline-secondary active" }
            }
            return { ...item, active: "outline-secondary" }

        })
        this.state = {

            classData1: clsdata,
            owedqfilterclass: "1",
            searchsubject: ""
            //getInstituteQuestions: getinsQuestions
        }
    }
    decodefun(data, id) {
        var decdata = "";
        try {
            decdata = decodeURIComponent(data);
        }
        catch (err) {
            console.log("Error Message:", err.message, id, data);
        }
        return decdata;
    }
    // QuestionFunction = (e, data) => {
    //     let Array = this.state.getInstituteQuestions.map((item) => {
    //         if (item.id == data) {
    //             if (e.target.checked == true) {
    //                 return { ...item, checked: true }
    //             }
    //             else {
    //                 return { ...item, checked: false }
    //             }
    //         }
    //         return { ...item }
    //     })
    //     this.setState({});

    // }
    ownedquestionClassFun1 = (e, data) => {
        console.log("owedqfilterclass", data);
        let classArray = this.state.classData1.map((item) => {
            if (item.id == data) {
                return { ...item, active: "outline-secondary active" }
            }
            else {
                return { ...item, active: "outline-secondary" }
            }

        })
        this.setState({ classData1: classArray, owedqfilterclass: data });
    }
    getquestiontypes() {
        let getArray = [];
        this.props.globals.globals.questionTypes.map((questionData) => {
            const newObj = {
                value: questionData.id,
                label: questionData.questiontype
            }
            getArray.push(newObj);
        })
        return getArray;
    }
    applicationtheory() {
        let getArray = [];
        this.props.globals.globals.questionTheory.map((theoryData) => {
            const newObj = {
                value: theoryData.id,
                label: theoryData.question_theory
            }
            getArray.push(newObj);
        })
        return getArray;
    }
    getSubjects() {
        let getArray = [];
        for (let i = 0; i <= this.props.stateData.subjects.length; i++) {
            let idata = this.props.stateData.subjects[i];
            if (idata != undefined) {
                const newObj = {
                    value: idata.id,
                    label: idata.subject
                }
                getArray.push(newObj);

            }

        }
        return getArray;
    }
    getChapters = () => {
        let getArray = [];
        for (let i = 0; i <= this.props.stateData.subjects.length; i++) {
            let idata = this.props.stateData.subjects[i];
            if (idata != undefined) {
                if (idata.id == this.props.stateData.searchsubject) {
                    let chData = "";
                    if (this.state.owedqfilterclass != "0") {
                        chData = idata.chapters.filter((a) => a.class == this.state.owedqfilterclass);
                    }
                    else {
                        chData = idata.chapters;
                    }
                    chData.map((chmapData) => {
                        const newObj = {
                            value: chmapData.id,
                            label: chmapData.chapter
                        }
                        getArray.push(newObj);
                    })

                }
            }

        }
        return getArray;
    }
    handleChange = (e) => {
        this.setState({
            searchsubject: e.target.value
        });

    }
    render() {
        //console.log("rendercurrentState", this.state);
        const getInstituteQuestions = this.props.getInstituteQuestions;
        const loading3 = getInstituteQuestions.loading;
        const error3 = getInstituteQuestions.error;
        // if (loading3) return <PreloaderTwo />;
        if (loading3) {
            return (
                <React.Fragment>
                    <li>
                        <Card className="border-0 shadow-sm">
                            <Card.Header className="d-sm-flex justify-content-between align-items-center bg-white">
                                <h6>Own Added Questions</h6>
                                <Form.Group className="my-0 d-flex align-items-center">
                                    <Form.Label className="mr-2">Weightage </Form.Label>
                                    <Form.Control style={{ width: 65 }} type="text" value={this.props.stateData.ownaddedpercentage + "%"}
                                        placeholder="%"
                                        disabled />
                                </Form.Group>
                            </Card.Header>
                            <Card.Body>
                                <div className="my-3 d-md-flex justify-content-between align-items-center">
                                    <h6>Filter</h6>
                                    <div className="d-flex align-items-center">
                                        <div className="mr-2">Class:</div>
                                        <ButtonGroup aria-label="Basic example">
                                            {this.state.classData1.map((classmapData) => (
                                                <Button onClick={(e) => this.ownedquestionClassFun1(e, classmapData.id)} variant={classmapData.active}>{classmapData.class}
                                                </Button>))}
                                            {/* <Button variant="outline-secondary">XII</Button> */}
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <Card as={Card.Body}>
                                    <Form>
                                        <Row>
                                            <Form.Group as={Col} xl={4} lg={4} md={6} sm={12} controlId="slectSubject">
                                                <Form.Label className="text-uppercase">Subject</Form.Label>
                                                <SelectDropDown
                                                    stateData={this.props.stateData.searchsubjectvalue}
                                                    handleChange={this.props.pselecthandleInputChange}
                                                    name="searchsubject"
                                                    options={this.getSubjects()}
                                                    placeholderName={'Subject'}
                                                    dropdownIndicator={{ DropdownIndicator }}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} xl={4} lg={4} md={6} sm={12} controlId="SelectChapters">
                                                <Form.Label className="text-uppercase">Chapters</Form.Label>
                                                <SelectDropDown
                                                    stateData={this.props.stateData.searchchaptervalue}
                                                    handleChange={this.props.pselecthandleInputChange}
                                                    name="searchchapter"
                                                    options={this.getChapters()}
                                                    placeholderName={'Chapters'}
                                                    dropdownIndicator={{ DropdownIndicator }}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} xl={4} lg={4} md={6} sm={12} controlId="selectTypeofQuestions">
                                                <Form.Label className="text-uppercase">Type Of Questions</Form.Label>
                                                <SelectDropDown
                                                    stateData={this.props.stateData.questiontypesvalue}
                                                    handleChange={this.props.pselecthandleInputChange}
                                                    name="questiontypes"
                                                    options={this.getquestiontypes()}
                                                    placeholderName={'Type Of Questions'} dropdownIndicator={{ DropdownIndicator }} />
                                            </Form.Group>
                                            <Form.Group as={Col} xl={4} lg={4} md={6} sm={12} controlId="selectApplicationTheory">
                                                <Form.Label className="text-uppercase">Application Theory</Form.Label>
                                                <SelectDropDown
                                                    stateData={this.props.stateData.applicationtheoryvalue}
                                                    handleChange={this.props.pselecthandleInputChange}
                                                    name="applicationtheory"
                                                    options={this.applicationtheory()}
                                                    placeholderName={'Application Theory'} dropdownIndicator={{ DropdownIndicator }} />
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </Card>

                                <div className="my-3 d-md-flex justify-content-between align-items-center">
                                    <h6>Questions</h6>
                                    <Form.Group className="my-2 d-flex align-items-center">
                                        <Form.Control style={{ width: 150 }} type="text" placeholder="Search" />
                                    </Form.Group>
                                </div>
                                <Card as={Card.Body} className="justify-content-center flex-row">
                                    <div class="spinner-border text-primary text-center"></div>
                                </Card>
                            </Card.Body>
                        </Card>
                    </li>
                </React.Fragment>
            )
        };
        if (error3 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }
        const newData = getInstituteQuestions.getInstituteQuestions.map((item) => {
            let finddata = [];
            this.props.stateData.subjects.map((item1) => {
                if (item1.id == item.subject) {
                    item1.chapters.map((item3) => {
                        if (item3.id == item.chapter) {
                            finddata = item3.ownedarray
                        }
                    })
                }
            })
            if (finddata.length > 0) {
                if (finddata.find((a) => a.id == item.id)) {
                    return { ...item, checked: true }
                }
                else {
                    return { ...item, checked: false }
                }
            }
            else {
                return { ...item, checked: false }
            }

        });
        let latestData = newData.filter((a) => a.id.includes(this.state.searchsubject));
        //console.log("latestData", latestData);
        return (
            <React.Fragment>
                <li>
                    <Card className="border-0 shadow-sm">
                        <Card.Header className="d-sm-flex justify-content-between align-items-center bg-white">
                            <h6>Own Added Questions</h6>
                            <Form.Group className="my-0 d-flex align-items-center">
                                <Form.Label className="mr-2">Weightage </Form.Label>
                                <Form.Control style={{ width: 65 }} type="text" value={this.props.stateData.ownaddedpercentage + "%"}
                                    placeholder="%"
                                    disabled />
                            </Form.Group>
                        </Card.Header>
                        <Card.Body>
                            <div className="my-3 d-md-flex justify-content-between align-items-center">
                                <h6>Filter</h6>
                                <div className="d-flex align-items-center">
                                    <div className="mr-2">Class:</div>
                                    <ButtonGroup aria-label="Basic example">
                                        {this.state.classData1.map((classmapData) => (<Button onClick={(e) => this.ownedquestionClassFun1(e, classmapData.id)} variant={classmapData.active}>{classmapData.class}</Button>))}
                                        {/* <Button variant="outline-secondary">XII</Button> */}
                                    </ButtonGroup>
                                </div>
                            </div>
                            <Card as={Card.Body}>
                                <Form>
                                    <Row>
                                        <Form.Group as={Col} xl={4} lg={4} md={6} sm={12} controlId="slectSubject">
                                            <Form.Label className="text-uppercase">Subject</Form.Label>
                                            <SelectDropDown
                                                stateData={this.props.stateData.searchsubjectvalue}
                                                handleChange={this.props.pselecthandleInputChange}
                                                name="searchsubject"
                                                options={this.getSubjects()}
                                                placeholderName={'Subject'}
                                                dropdownIndicator={{ DropdownIndicator }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} xl={4} lg={4} md={6} sm={12} controlId="SelectChapters">
                                            <Form.Label className="text-uppercase">Chapters</Form.Label>
                                            <SelectDropDown
                                                stateData={this.props.stateData.searchchaptervalue}
                                                handleChange={this.props.pselecthandleInputChange}
                                                name="searchchapter"
                                                options={this.getChapters()}
                                                placeholderName={'Chapters'}
                                                dropdownIndicator={{ DropdownIndicator }}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} xl={4} lg={4} md={6} sm={12} controlId="selectTypeofQuestions">
                                            <Form.Label className="text-uppercase">Type Of Questions</Form.Label>
                                            <SelectDropDown
                                                stateData={this.props.stateData.questiontypesvalue}
                                                handleChange={this.props.pselecthandleInputChange}
                                                name="questiontypes"
                                                options={this.getquestiontypes()}
                                                placeholderName={'Type Of Questions'} dropdownIndicator={{ DropdownIndicator }} />
                                        </Form.Group>
                                        <Form.Group as={Col} xl={4} lg={4} md={6} sm={12} controlId="selectApplicationTheory">
                                            <Form.Label className="text-uppercase">Application Theory</Form.Label>
                                            <SelectDropDown
                                                stateData={this.props.stateData.applicationtheoryvalue}
                                                handleChange={this.props.pselecthandleInputChange}
                                                name="applicationtheory"
                                                options={this.applicationtheory()}
                                                placeholderName={'Application Theory'} dropdownIndicator={{ DropdownIndicator }} />
                                        </Form.Group>
                                    </Row>
                                </Form>
                            </Card>

                            <div className="my-3 d-md-flex justify-content-between align-items-center">
                                <h6>Questions</h6>
                                <Form.Group className="my-2 d-flex align-items-center">
                                    <Form.Control
                                        style={{ width: 150 }}
                                        type="text"
                                        name="quesionsearch"
                                        value={this.state.quesionsearch}
                                        onChange={this.handleChange}
                                        placeholder="Search"
                                    />
                                </Form.Group>
                            </div>
                            <Card as={Card.Body}>
                                <Scrollbars style={{ height: 250 }}
                                    {...this.props}
                                    renderThumbVertical={renderThumb}
                                    autoHide
                                    autoHideTimeout={500}
                                    autoHideDuration={200}>
                                    {latestData.map((questionsData, index) => (
                                        <Card as={Card.Body} className="bg-light my-2">
                                            <Form.Check type="checkbox" id={"checkbox_01" + index} custom>
                                                <Form.Check.Input type="checkbox" checked={questionsData.checked} onClick={(e) => this.props.pQuestionFunction(e, questionsData)} />
                                                <Form.Check.Label htmlFor={"checkbox_01" + index}>{questionsData.id}. {parse(this.decodefun(questionsData.question, questionsData.id))}</Form.Check.Label>
                                            </Form.Check>
                                        </Card>))}
                                </Scrollbars>
                            </Card>
                        </Card.Body>
                    </Card>
                </li>
            </React.Fragment>
        )
    }
}



export default
    compose(

        graphql(FETCH_INSTITUTIONQUESTIONS,
            {
                options: props => ({

                    variables: {
                        //institution_id: parseInt(Cookies.get("institutionid"))
                        subject: props.stateData.searchsubject,
                        chapter: props.stateData.searchchapter,
                        question_type: props.stateData.questiontypes,
                        question_theory: props.stateData.applicationtheory,
                        institute_id: parseInt(Cookies.get("institutionid"))
                    }
                }), name: "getInstituteQuestions"
            })

    )
        (QuestionComponent);
