import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import EamcetLongTermCourseLiveClassesSection from '../components/Eamcet_LongTerm_Course/eamcet_liveClasses/EamcetLongTermCourseLiveClassesSection';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';


import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';


const FETCH_SERVICE_DATA_FOR_EAMCET_CLASSES = gql` 
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String,
    $mobile:String
    ){
        getServiceData(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type,
            mobile:$mobile){
            data
            enabled
            }
        }
    `;

const VIDEO_DESCRIPTION = gql`
    query(
        $id : Int
        $video_code : String,
        $video_url : String
    ){
        getVideoDescription(
            id :$id
            video_code : $video_code
            video_url : $video_url
        ){
            video_code
            video_url
            description
        }
    }
    `;
const FETCH_COURSE_MODULE = gql`  
    query(
        $id : Int!
        $mobile:String 
        ){
        getCourseModuleAccess(
            id : $id 
            mobile:$mobile
        ){
            id
            course_name
            description
            exam_id
            courseModuleAccess {
              id
              student_plan_id
              course_id
              subject_ids
              subject_names
              course_module_ids
              courseModules {
                id
                course_id
                module_name
              }
            
            }
        }
    }
`;

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

const GET_LONGTERM_EXAMS = gql`
query($mobile: String,$exam_group_id: Int) {
        getGroupBasedExams(mobile: $mobile,exam_group_id: $exam_group_id){
            id
            exam_name
            exam_type
            exam_model_id
            exam_session_id
            sub_exam_type
            start_time
            end_time
            is_completed
            title
            short_name
            amount
            speed
            accuracy
            correct_marks
            negative_marks
            total_marks
            is_purchased
            exam_started
            exam_expired
            syllabus
            exam_duration
            uid
            is_scheduled
            is_registered
            syllabus_desc
            is_started
            is_started_mobile
            class_id
            exam_class_id
            subject_id
            enabled_XI
            enabled_XII
            class_session_id
    }
}
`;




let globalsubjectsStatic = []
class EamcetCrashCourseLiveClasses extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submitError: ""

        }
        if (Cookies.get("examid") == 3 || Cookies.get("examid") == 6) {
            globalsubjectsStatic = [
                { id: 0, subject: 'All', active: "" },
                { id: 4, subject: 'Maths', active: "" },
                { id: 2, subject: 'Physics', active: "" },
                { id: 3, subject: 'Chemistry', active: "" },
            ]

        } else {
            globalsubjectsStatic = [
                { id: 0, subject: 'All', active: "" },
                { id: 1, subject: 'Botany', active: "" },
                { id: 5, subject: 'Zoology', active: "" },
                { id: 2, subject: 'Physics', active: "" },
                { id: 3, subject: 'Chemistry', active: "" },
            ]
        }

        this.state = {
            headerBottomImg: {
                type: "eamcet_view",
                title: "LONG TERM COURSE CLASSES"
            },
            toggled: Cookies.get("toggle"),
            getSubjectsArr: globalsubjectsStatic,
        }
    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    componentDidMount = () => {
        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Eamcet_Crash_Course_Classes;
        ReactGA.pageview('/student/eamcet-crashcourse/liveclasses', ["rizee"], title);
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 216,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };


    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        let globalsubjects = "";
        if (JSON.parse(localStorage.getItem("globalsubjects")) != "") {
            globalsubjects = JSON.parse(localStorage.getItem("globalsubjects"));
        }
        else {
            this.props.history.push("/student/login");
        }


        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }

        const eamcetClasses = this.props.eamcetClasses;
        const loading1 = eamcetClasses.loading;
        const error1 = eamcetClasses.error;

        const getCourseModuleAccess = this.props.getCourseModuleAccess;
        const loading2 = getCourseModuleAccess.loading;
        const error2 = getCourseModuleAccess.error;

        const videoDescription = this.props.videoDescription;
        const loading3 = videoDescription.loading;
        const error3 = videoDescription.error;

        const getlongtermQuizzes = this.props.getGroupBasedExams;
        const loading4 = getlongtermQuizzes.loading;
        const error4 = getlongtermQuizzes.error

        const getLongtermAssignments = this.props.getGroupBasedExamsAssign;
        const loading5 = getLongtermAssignments.loading;
        const error5 = getLongtermAssignments.error;

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }
        if (error3 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }
        if (error4 !== undefined) {
            alert("Server Error. " + error4.message);
            return null;
        }
        if (error5 !== undefined) {
            alert("Server Error. " + error5.message);
            return null;
        }
    

        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars
                        headerBottom={this.state.headerBottomImg}
                        onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper">

                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0" style={{ minHeight: "50vh" }}>

                        {
                            loading1 == true || loading2 == true || loading3 == true || loading4 == true || loading5 == true
                                ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                ) : (
                                    <EamcetLongTermCourseLiveClassesSection
                                        stateData={this.state}
                                        eamcetClasses={eamcetClasses.getServiceData}
                                        videoDescription={this.props.videoDescription}
                                        getCourseModuleAccess={this.props.getCourseModuleAccess}
                                        getlongtermQuizzes={getlongtermQuizzes.getGroupBasedExams}
                                        getLongtermAssignments={getLongtermAssignments.getGroupBasedExams}
                                    />
                                )
                        }
                    </div>
                </div>
            </div>
        )

    }
}

export default withRouter(compose(
    graphql(FETCH_SERVICE_DATA_FOR_EAMCET_CLASSES, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                service_type: "Eamcet_Long_Term_Course",
                service_sub_type: "ALL",
                mobile: Cookies.get("mobile")
            },
            fetchPolicy: 'network-only'

        }),
        name: "eamcetClasses"
    }),
     graphql(VIDEO_DESCRIPTION, {
        options: props => ({
            variables: {
                id: 0
            }
        }), name: "videoDescription"
    }),
    graphql(FETCH_COURSE_MODULE,
        {
            options: props => ({
                variables: {
                    id: 6,
                    mobile: Cookies.get("mobile")
                }
                ,
            }), name: "getCourseModuleAccess"
        }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
    graphql(GET_LONGTERM_EXAMS,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_group_id: Cookies.get("examid") == "3" ? 59 : Cookies.get("examid") == "6" ? 60 : Cookies.get("examid") == "7" ? 61 : 62
                },
                fetchPolicy: 'network-only'
            }), name: "getGroupBasedExams"
        }),
    graphql(GET_LONGTERM_EXAMS,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_group_id: Cookies.get("examid") == "3" ? 55 : Cookies.get("examid") == "6" ? 56 : Cookies.get("examid") == "7" ? 57 : 58
                },
                fetchPolicy: 'network-only'
            }), name: "getGroupBasedExamsAssign"
        }),
)(EamcetCrashCourseLiveClasses));

