import React, { Component } from 'react'
import { Row, Col, Card, Image, Button, Container, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Cookies from "es-cookie";
import moment from 'moment';
import { withRouter } from "react-router-dom";
import ViewSyllabus from './ViewSyllabus';
import './_eamcetLongTermCourseExams.scss';
import EamcetRestrictionAlert from "../../home/EamcetRestrictionAlert";
import { GoogleAnalyticsArray } from '../../../pages/GoogleAnalytics';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import { controllers } from 'chart.js';

class EamcetCrashCourseExamsSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            examTypeString: "ongoing",
            activeOngoingExamType: "active",
            activeAttendedExamType: "",
            activeMissedExamType: "",
            getExamsNewPattern: "",
            viewSyllabusModal: false,
            viewSyllabusDetails: "",
            // accessCourseModules: props.getCourseModuleAccess?.getCourseModuleAccess,
            userRestionModalShow: false,
            userRestionPaidModalShow: false,
            examAcess: 1,
            currentTime: moment().unix(),
            getPaidExamsNewPattern: "",

        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));

        }

    }

    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }

    startExam = (data) => {
        localStorage.setItem("mocklivestatus", "");

        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        localStorage.setItem("mocktest", true);
        localStorage.setItem("resumeExam", false);
        localStorage.setItem("examtype_pattern", "longterm_exams");

        if (isMobile == true && Cookies.get("institution_id") == 30) {
            this.props.history.push({
                pathname: "/student/subject/series_test",
            })
        } else {
            window.open("/student/subject/series_test", "_blank");
        }
    }

    resumeExam = (data) => {
        console.log("this.state.subtype", data.exam_name, data.exam_name);
        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }
        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        localStorage.setItem("mocktest", true);
        localStorage.setItem("examtype_pattern", "longterm_exams");
        localStorage.setItem("resumeExam", true);

        if (isMobile == true && Cookies.get("institution_id") == 30) {
            this.props.history.push({
                pathname: "/student/subject/series_test",
            })
        } else {
            window.open("/student/subject/series_test", "_blank");
        }
    }

    handleResultFunction = (id) => {

        this.props.history.push({
            pathname: "/student/subject/exam-result",
            state: {
                sessionid: id,
                examtype: "longterm_exams",
                //mocktype: "history"
            }
        })
    }

    handleShowModal = (data) => {
        console.log(data)
        this.setState({
            viewSyllabusModal: true,
            viewSyllabusDetails: data
        });
    }
    handleSelect = (examType) => {
        let activeOngoingExamType = "", activeAttendedExamType = "", activeMissedExamType = ""
        this.setState({
            examTypeString: "",
            activeOngoingExamType: "",
            activeAttendedExamType: "",
            activeMissedExamType: ""
        });

        if (examType == "ongoing") {
            activeOngoingExamType = `active`;
        } else if (examType == "attended") {
            activeAttendedExamType = `active`;
        } else if (examType == "missed") {
            activeMissedExamType = `active`;
        }

        this.setState({
            examTypeString: examType,
            activeOngoingExamType: activeOngoingExamType,
            activeAttendedExamType: activeAttendedExamType,
            activeMissedExamType: activeMissedExamType,
        });

    }

    render() {
        // const currentPlanId = this.isStudentUserValid.current_plan_id;
        // if (this.state.accessCourseModules != undefined) {
        //     this.state.accessCourseModules.map((item, index) => {
        //         item.courseModuleAccess.map((item1, index) => {
        //             if (this.props.getGroupBasedExams.length > 0 && this.props.getGroupBasedExams != undefined) {
        //                 if (currentPlanId == item1.student_plan_id || this.props.getGroupBasedExams[0].is_purchased == true) {
        //                     this.state.examAcess = 0
        //                 } else {
        //                     this.state.examAcess = 1
        //                 }
        //             }
        //         })
        //     });
        // }



        if (this.props.getGroupBasedExams.length > 0 && this.props.getGroupBasedExams != undefined) {
            if (this.props.getGroupBasedExams[0].is_purchased == true) {
                this.state.examAcess = 0
            } else {
                this.state.examAcess = 1
            }
        }

        let currentDateTime = moment().unix();
        const endofdate = moment(moment(moment().unix() * 1000).endOf('day').unix() * 1000).unix();
        let currentTime_currentSec = this.props.getTime.timestamp;

        let ongoingExams = this.props.getGroupBasedExams?.sort((a, b) => a.start_time - b.start_time).filter(item => (((currentDateTime > item.start_time && currentDateTime <= item.end_time && item.is_completed == false) || (currentDateTime < item.start_time))))
        let attendedExams = this.props.getGroupBasedExams?.filter(item => item.is_completed == true)
        let missedExams = this.props.getGroupBasedExams?.filter(item => currentDateTime > item.end_time && item.is_completed != true);
        if (this.state.examTypeString == "ongoing") {
            this.state.getExamsNewPattern = ongoingExams
        }
        else if (this.state.examTypeString == "attended") {
            this.state.getExamsNewPattern = attendedExams
        }
        else if (this.state.examTypeString == "missed") {
            this.state.getExamsNewPattern = missedExams
        }

        return (
            <Container fluid>
                <Row>
                    <Col sm={12} className="bg_color_blue">
                        <ul className="list-inline exam_types_li">
                            <li className={this.state.activeOngoingExamType} onClick={() => this.handleSelect("ongoing")}>Ongoing</li>
                            <li className={this.state.activeAttendedExamType} onClick={() => this.handleSelect("attended")} >Attended </li>
                            <li className={this.state.activeMissedExamType} onClick={() => this.handleSelect("missed")} >Missed </li>
                        </ul>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <h5 className='font-weight-bold text-uppercase mt-3 text_color_cls'></h5>
                        <div className="exams_pattern_bg mt-2">
                            <Row>
                                {
                                    this.state.getExamsNewPattern.map((item, index) =>
                                        <Col sm={6} className="mt-2">
                                            <Card className="card-border-exams">
                                                <Card.Body>
                                                    <Media>
                                                        <div className="date_block mr-3 text-center">
                                                            <p className="mt-1 f-22 border-bootom2"> {moment.unix(item.start_time).format("MMM")}</p>
                                                            <p className="mt-2 f-22">{moment.unix(item.start_time).format("DD")}</p>
                                                        </div>
                                                        <Media.Body>
                                                            {
                                                                currentDateTime > item.start_time && currentDateTime <= item.end_time ?
                                                                    (
                                                                        item.is_completed == true || item.exam_expired == true ?
                                                                            (<Card.Title><h6 className="f-14">{item.exam_name}</h6></Card.Title>)
                                                                            :
                                                                            (<Card.Title><h6 className="f-14">{item.exam_name} <span className="float-right live-class">Live</span></h6></Card.Title>)
                                                                    )
                                                                    :
                                                                    (<Card.Title><h6 className="f-14">{item.exam_name}</h6></Card.Title>)
                                                            }

                                                            <Row>
                                                                <Col sm={7}>
                                                                    <p className="f-14">Time : {moment.unix(item.start_time).format("LT")} to {moment.unix(item.end_time).format("LT")}</p>
                                                                </Col>
                                                                <Col sm={5}>
                                                                    <p className="float-right-resp f-14"> Duration : {item.exam_duration}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={6} className="mt-2">
                                                                    <Button type="button" className="btn btn-blue-exam" onClick={() => this.handleShowModal(item)}>View Syllabus</Button>
                                                                </Col>
                                                                <Col sm={6} className="mt-2">

                                                                    {
                                                                        item.exam_expired == true ?
                                                                            ("")
                                                                            :
                                                                            (
                                                                                <>
                                                                                    {
                                                                                        item.is_completed == true ?
                                                                                            (
                                                                                                <Button type="button" className="btn btn-blue-exam float-right-resp" onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results </Button>
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                currentDateTime > item.end_time ?
                                                                                                    ("")
                                                                                                    :
                                                                                                    (
                                                                                                        moment.unix(item.start_time).format("DD-MMM-YYYY") == moment.unix(currentTime_currentSec).format("DD-MMM-YYYY") && parseInt(item.start_time - 600) < parseInt(currentTime_currentSec) ?
                                                                                                            (
                                                                                                                this.state.examAcess == 1 ?

                                                                                                                    (
                                                                                                                        item.is_started == true && currentTime_currentSec > item.start_time ?
                                                                                                                            (<Button type="button" className="btn btn-blue-exam float-right-resp" onClick={() => this.resumeExam(item)}>Resume Test</Button>) :

                                                                                                                            (<Button type="button" className="btn btn-blue-exam float-right-resp" onClick={() => this.startExam(item)}>Start Now</Button>)

                                                                                                                    )
                                                                                                                    :
                                                                                                                    (
                                                                                                                        <Button type="button" className="btn btn-orange-exam float-right-resp" onClick={() => this.modalFunAssignments()}>Subscribe</Button>


                                                                                                                    )
                                                                                                            )
                                                                                                            :
                                                                                                            (
                                                                                                                this.state.examAcess == 1 ?
                                                                                                                    (<Button type="button" className="btn btn-blue-exam float-right-resp" disabled>Start Now</Button>)
                                                                                                                    :
                                                                                                                    (<Button type="button" className="btn btn-orange-exam float-right-resp" onClick={() => this.modalFunAssignments()}>Subscribe</Button>)
                                                                                                            )
                                                                                                    )
                                                                                            )
                                                                                    }
                                                                                </>
                                                                            )
                                                                    }

                                                                </Col>
                                                            </Row>
                                                        </Media.Body>
                                                    </Media>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                }
                                {
                                    this.state.examTypeString == "ongoing" && this.state.getExamsNewPattern.length == 0 ?

                                        (
                                            <Col sm={12}>
                                                <div className="no-test">
                                                    Coming Soon
                                                </div>
                                            </Col>
                                        ) : this.state.examTypeString == "attended" && this.state.getExamsNewPattern.length == 0 ?

                                            (
                                                <Col sm={12}>
                                                    <div className="no-test">
                                                        You have not Attempted any Exam
                                                    </div>
                                                </Col>
                                            ) : this.state.examTypeString == "missed" && this.state.getExamsNewPattern.length == 0 ?
                                                (
                                                    <Col sm={12}>
                                                        <div className="no-test">
                                                            No Missed Exams
                                                        </div>

                                                    </Col>
                                                ) : ("")
                                }

                            </Row>
                        </div>
                    </Col>

                </Row>

                <EamcetRestrictionAlert
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                    buildType="lt_course"
                    />
                <ViewSyllabus SyllabusDetails={this.state.viewSyllabusDetails} show={this.state.viewSyllabusModal} onHide={() => this.setState({ viewSyllabusModal: false })} />
            </Container>

        )



    }

}

export default withRouter((EamcetCrashCourseExamsSection));