import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Image, Navbar, Dropdown, Form, FormControl, Button, Container, Media } from 'react-bootstrap';
import { components } from 'react-select';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import * as Cookies from "es-cookie";
import './_collegeRankPredictor.scss';
import SelectDropDown from '../../../neetjee_guru/components/selectdropdown/SelectDropDown';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';
import CardLessDataTableWithOutSearch from "../../../neetjee_guru/components/datatables/CardLessDataTableWithOutSearch"
import ShortListman from '../../../images/successful_sl.png'


const GET_BRANCHWISE_COUNTS = gql`
query($mobile:String){
    getShortlistedCount(mobile:$mobile){
        branch_code
        count
        branch_id
        exam_id
      }
}
`
class ShortListPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error_display: "",
            headerBottomImg: {
                type: "sl_pred",
            },
            branchId: 0,
            stateId: parseInt(Cookies.get("examid")) == 3 ? 2 : 24
        }
    }

    componentDidMount() {
        window.scroll(0, 0)
    }

    actionFormatterSNO(cell, row, rowIndex) {
        return (
            <>
                <p className='text-center'>{rowIndex + 1}</p>
            </>
        )
    }

    colFunction() {
        let columns = [
            {
                dataField: "S_No",
                text: "S no",
                headerStyle: {
                    background: "#134F5C",
                    color: "#FFFFFF",
                    width: "10%"
                },
                formatter: this.actionFormatterSNO.bind(this),
            },
            {
                dataField: "college_name",
                text: "Name of College",
                headerStyle: {
                    background: "#134F5C",
                    color: "#FFFFFF",
                },
                sort: true,
            },
            {
                dataField: "college_code",
                text: "College Code",
                headerStyle: {
                    background: "#134F5C",
                    color: "#FFFFFF",
                },
                sort: true,
            },
            {
                dataField: "branch_name",
                text: "Branch Name",
                headerStyle: {
                    background: "#134F5C",
                    color: "#FFFFFF",
                },
                sort: true,
            },
            {
                dataField: "branch_code",
                text: "Branch Code",
                headerStyle: {
                    background: "#134F5C",
                    color: "#FFFFFF",
                },
                sort: true,
            },
        ]
        return columns;
    }

    handleselect = (row) => {
        this.setState({ branchId: row })
    }

    selectHandleInputChange = (row) => {
        this.setState({ stateId: row, branchId:0 })
    }

    render() {
        const getShortlistedCount = this.props.getShortlistedCount
        const loading = getShortlistedCount.loading
        const error = getShortlistedCount.error

        if (error !== undefined) {
            alert("Server Error. " + error.message);
            return null;
        }

        let ExamId = this.state.stateId == 24 ? 6 : 3

        let branchGroupCounts = [];

        if (this.props.getShortlistedCount != "" && this.props.getShortlistedCount != undefined) {
            branchGroupCounts = this.props.getShortlistedCount.getShortlistedCount?.filter((item)=>item.exam_id == ExamId)
        }

        let tableData = []

        if (this.state.stateId == 24) {
            if (this.state.branchId == 0) {
                tableData = this.props.getBranchDraftData.filter((item) => item.exam_id == 6)
            } else {
                tableData = this.props.getBranchDraftData.filter((item) => item.branch_id == this.state.branchId && item.exam_id == 6)
            }
        } else {
            if (this.state.branchId == 0) {
                tableData = this.props.getBranchDraftData.filter((item) => item.exam_id == 3)
            } else {
                tableData = this.props.getBranchDraftData.filter((item) => item.branch_id == this.state.branchId && item.exam_id == 3)
            }
        }

        let totalLength = ''

        if (this.state.stateId == 24) {
            totalLength = this.props.getBranchDraftData.filter((item) => item.exam_id == 6).length
        } else {
            totalLength = this.props.getBranchDraftData.filter((item) => item.exam_id == 3).length
        }

        return (
            <Container className="shortlistPage">

                {
                    this.props.stateData.shortlistPageLoading == "show" ?
                        (
                            <Row>
                                <Col sm={12}>
                                    <Image src={require('../../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                </Col>

                            </Row>
                        )
                        :
                        (
                            <>
                                <Row className='mt-3'>
                                    <Col sm={12}>
                                        <h4 className='sl_heading text-center'>Here are the list of shortlisted colleges</h4>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={6}>
                                        <ul className='list-inline class_type_li mt-4'>
                                            <li className={this.state.stateId == 24 ? "active" : ""} onClick={() => this.selectHandleInputChange(24)}>TS Colleges</li>
                                            <li className={this.state.stateId == 2 ? "active ml-4" : "ml-4"} onClick={() => this.selectHandleInputChange(2)}>AP Colleges</li>
                                        </ul>
                                    </Col>
                                </Row>

                                <Row className='mb-2'>
                                    <Col sm={2}>
                                        <div className="branch_block mr-3 text-center" onClick={() => this.handleselect(0)}>
                                            <p className="mt-1">Total : <span className='counts'>{totalLength}</span></p>
                                        </div>
                                    </Col>

                                    {
                                        branchGroupCounts && branchGroupCounts.map((item) => {
                                            return (
                                                <Col sm={2} className="mb-2">
                                                    <div className="branch_block mr-3 text-center" onClick={() => this.handleselect(item.branch_id)}>
                                                        <p className="mt-1">{item.branch_code}: <span className='counts'>{item.count}</span></p>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                                <CardLessDataTableWithOutSearch
                                    parentData={tableData}
                                    particlecolumns={this.colFunction()}
                                    pageType="sl_college"
                                />
                            </>
                        )
                }

                {
                    tableData.length > 0 && this.props.stateData.shortlistPageLoading == "hide" ?
                        (
                            <Card className='goto_card p-4 mb-4'>
                                <Row>
                                    <Col sm={4}>
                                        <img src={ShortListman} className="sl_image" alt="image" />
                                    </Col>
                                    <Col sm={8}>
                                        <h4 className='goto_text text-center'>Congragrulations! You have completed the hard yard.
                                            Now download this list for your WEB OPTION </h4>
                                        <button className='goto_short_btn center-sl-auto'> <i class="far fa-cloud-download-alt mr-1"></i>DOWNLOAD LIST</button>
                                        <span className='soon_text'>(Coming soon...)</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className='text-center mt-2 disclaimer'>*Disclaimer: Predictions made are based on historical seat allocation data from 2021.</p>
                                    </Col>
                                </Row>

                            </Card>
                        )
                        :
                        (
                            this.props.stateData.shortlistPageLoading == "hide" ?
                                (
                                    <Col sm={12}>
                                        <p className='text-center no_data_text'>You have not shortlisted any colleges</p>
                                    </Col>
                                )
                                :
                                ("")
                        )
                }
            </Container >
        )
    }
}


export default withRouter(withApollo(compose(
    graphql(GET_BRANCHWISE_COUNTS,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                },
                fetchPolicy: "network-only"
            }), name: "getShortlistedCount"
        }),
)(ShortListPage)));
