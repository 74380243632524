import { Modal, Image, Card, Button } from 'react-bootstrap'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './_liveClassess.scss'

 class LiveClassesModal extends Component {

    render() {
        console.log("this.props.Data", this.props.data);
        return (
            
            <Modal {...this.props}
                size="lg" aria-labelledby="example-modal-sizes-title-lg" className="">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Video</Modal.Title>   
                </Modal.Header>
                <Modal.Body>
                        <iframe className="embed-responsive-item" src={`https://www.youtube.com/embed/${this.props.data}`} title='video' allowFullScreen className="iframevideo"></iframe>
                         
                </Modal.Body>
            </Modal>
        )
    }
}

export default LiveClassesModal
