export const GoogleAnalyticsArray = [
    {
        Videos: "Videos",
        Videos_Home: "Videos View More",
        VideoPayment: "VideoPayment",
        Topics_View: "Tiopic Dashboard",
        Topics: "Tiopic List",
        Test_Series_Home: "MockTest Home Page",
        MockTest_History: "MockTest  History",
        MockTest_Results: "MockTest  Results",
        MockTest_QandA_View: "MockTest  Q&A View",
        Subject_Videos_Tab_from_Learn_and_Practice: "Subject Videos Tab from Learn and Practice",
        Learn_Practice_Short_Notes_View: "Learn & Practice Short Notes  View",
        Semi_Grand_Exam: "Semi Grand Exam",
        Schedule_Exam_page: "Schedule_Exam_page",
        Revision_Material: "Revision Material",
        Performance_Analysis: "Performance Analysis",
        Register: "Register",
        Referral: "Referral",
        GetReady_Exam: "GetReady_Exam",
        Profile: "Profile",
        Previous_Papers_Selection: "Previous Papers Selection",
        Previous_Paper_Exam: "Previous Paper Exam",
        Practice_Results_Page: "Practice Results Page",
        Practice_Instructions: "Practice Instructions",
        Practice_Exam: "Practice Exam page",
        Payment: "Payment",
        Packages: "Subscription Plans",
        Notifications: "Notification List Page",
        NotificationsView: "Notification View Page",
        Notes: "Notes",
        Login: "Login Page",
        Linkage_Analysis_home: "Linkage Analysis home",
        Linkage_Analysis_Detail_Page: "Linkage Analysis Detail Page",
        Learn_Practice: "Learn & Practice Home",
        Home: "Home Page",
        Grand_Exam: "Grand Exam",
        Feedback: "Feedback",
        Exams: "Create Exam",
        Exam_Result: "Exam Result",
        Exam_Instructions: "Exam Instructions",
        Error_practice_Instructions: "Error practice Instructions",
        Error_Practice_page: "Error Practice page",
        Error_Exam: "Error Exam",
        Error_Cumulative_Exam: "Error Cumulative Exam",
        Error_Chapter_Exam: "Error Chapter Exam",
        Doubts_Forum: "Doubts Forum",
        Custom_Exam:"Custom Exam",
        Cumulative_Exam: "Cumulative Exam",
        Choose_Plan: "Choose Plan",
        Learn_Practice_Subject_Home: "Learn & Practice Subject Home",
        Chapter_Videos_Tab_from_Learn_and_Practice: "Chapter Videos Tab from Learn and Practice",
        Chapter_Exam: "Chapter Exam",
        Bookmarks: "Book Marks",
        Blog: "Blog",
        Previous_Paper_Analysis_home: "Previous Paper Analysis home",
        Previous_Paper_Analysis_Detail_Page: "Previous Paper Analysis Detail Page",
        Learn_Practice_Dashboard:"Learn & Practice Dashboard",
        Practice_Exam_History:"Practice Exam History",
        Practice_QandA_View:"Practice Q&A View",
        Learn_Practice_Start_Learning:"Learn & Practice Start Learning",
        Doubts_App:"Doubts App",
        Blog_View:"Blog View",
        Videos_Playing:"Videos Playing",
        Video_Notes_View:"Video Notes View",
        Error_Practice_Dashboard:"Error_Practice_Dashboard",
        Error_Practice_Results_Page:"Error Practice Results Page",
        Errror_Practice_QandA_View:"Errror Practice Q&A View",
        Schedule_Exam_instructions:"Schedule Exam instructions",
        MockTest_Instruction_page:"MockTest Instruction page",
        MockTest_Exam_page:"MockTest Exam page",
        Schedule_Exam_History:"Schedule Exam History",
        Custom_Exam_History:"Custom Exam History",
        Error_Exam_History:"Error_Exam_History",
        Previous_Exam_History:"Previous_Exam_History",
        Previous_Exam_Results:"Previous_Exam_Results",
        Schedule_Exam_Reports:"Schedule Exam Reports",
        Custom_Exam_Reports:"Custom Exam Reports",
        Error_Exam_Reports:"Error_Exam_Reports",
        Schedule_Exams_QandA_View:"Schedule Exams Q & A View",
        Custom_Exams_QandA_View:"Custom Exams Q & A View",
        Exams_History :"Exams History",
        Exams_QandA_View:"Exams Q & A View",
        Chapter_Exam_page:"Chapter Exam page",
        Cumulative_Exam_page:"Cumulative Exam page",
        Semi_grand_Exam_page:"Semi grand Exam page",
        Grand_Exam_page:"Grand Exam page",
        Previous_Paper_Exam_page:"Previous Paper Exam page",
        Previous_Paper_Exam_Instructions:"Previous Paper Exam Instructions",
        Chapter_Exam_instructions:"Chapter Exam  instructions",
        Cumulative_Exam_Instructions:"Cumulative Exam Instructions",
        Semi_grand_Exam_Instructions:"Semi grand Exam Instructions",
        Grand_Exam_Instructions:"Grand Exam Instructions",
        Error_Exam_Instruction:"Error Exam Instruction",
        Error_Exam_Page:"Error Exam Page",
        Practice_and_Exam_Analysis:"Practice & Exam Analysis",
        Strength_Analysis:"Strength Analysis",
        Complexity_Analysis:"Complexity Analysis",
        Time_Analysis:"Time Analysis",
        Question_Type_Analysis:"Question Type Analysis",
        Question_Theory_Analysis:"Question Theory Analysis",
        Revision_Material_Content_View:"Revision Material Content View",
        GetReady_Created_Exam_list:"GetReady - Created Exam list",
        Create_Chapter_Exam:"Create Chapter Exam",
        Create_Cumulative_Exam:"Create Cumulative Exam",
        Create_SemigrandExam:"Create SemigrandExam",
        Create_Grand_Exam:"Create Grand Exam",
        Exam_syllabus:"Exam syllabus",
        Jee_Mains_2021_Home_Page:"Jee Mains 2021 Home Page",
        Jee_Mains_2021_Instruction_page:"Jee Mains 2021  Instruction page",
        Jee_Mains_2021_Exam_page:"Jee Mains 2021 Exam page",
        Jee_Mains_2021_Results:"Jee Mains 2021  Results",
        Jee_Mains_2021_QandA_View:"Jee Mains 2021  Q&A View",
        Help_Videos_Home_Page:"Help Videos Home Page",
        Eamcet_Home:"EM Home ",
        Eamcet_Crash_Course:"EM Crash Course",
        Eamcet_BluePrint:"EM Blue Print",
        Eamcet_Crash_Course_Exams:"EM Crash Course Exams",
        Eamcet_Crash_Course_Results:"EM Crash Course Exam Results",
        Eamcet_Crash_Course_Classes:"EM Crash Course Classes",
        Eamcet_CrashCourse_QA_View:"EM Crash Course Exam Q&A View",
        Eamcet_Revision_Material:"EM Revision Materials",
        Eamcet_Mocktests:"EM Mocktest Home Page",
        Eamcet_Mocktests_History:"EM MockTest History",
        Eamcet_Mocktest_Results:"EM MockTest Results",
        Eamcet_Mocktest_QA_View:"EM MockTest  Q&A View",
        Eamcet_Mock_Instructions:"EM MockTest Instruction Page",
        Eamcet_Practice_Tests:"EM Practice Home Page",
        Eamcet_Practice_Instructions:"EM Practice Instruction Page",
        Eamcet_Practice_Results:"EM Practice Results",
        Eamcet_Practice_QA_View:"EM Practice Q&A View",
        Eamcet_Previous_Papers:"EM Previous Papers Page",
        Eamcet_Previous_Instructions:"EM Previous Papers Instruction Page",
        Eamcet_Previous_Exam_page:"EM Previous Papers Exam page",
        Eamcet_Previous_Results:"EM Previous Papers Results",
        Eamcet_Previous_QA_View:"EM Previous Papers Q&A View",
        Eamcet_Rank_Predictor:"EM Rank Predictor",
        Eamcet_Short_Notes:"EM Short Notes",
        Eamcet_IPE_Lectures:"EM IPE Lectures",
        Eamcet_Quiz:"EM Quiz Home Page",
        Quiz_Exams:"Quiz Exams",
        Quiz_Results:"Quiz Results",
        Quiz_QA_View:"Quiz Q&A View",
        Eamcet_Plans:"EM Subscription page",
        Eamcet_Blog:"EM Blog",
        Cbse:"CBSE home page",
        Eamcet_Notifications:"EM Notification List Page",
        Eamcet_Notification_view:"EM Notification View Page",
        Plan_Summary:"Plan Summary Page",
        Eamcet_Plan_Summary:"EM Plan Summary",


    }
  ];
