import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import ErrorExamSection from '../components/exams/error_exam/ErrorExamSection';
import * as Cookies from "es-cookie";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import PreloaderTwo from '../components/preloader/PreloaderTwo';

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import JeeNEwPlanPopup from '../components/mock_series/jeeNewPlanpopup'
import NEETPrepBundlePopup from '../components/mock_series_newNTAPattern/NEETPrepBundlepopup'
const title = GoogleAnalyticsArray[0].ErrorExam;
ReactGA.pageview('/student/exams/error-exam', title);


const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

class ErrorExam extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggled: "wrapper sidebar-enable",
            submitError: "",
            planpopup: true

        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }

    }

    componentDidMount = () => {
        this.addStudentAnalytics();
    }

    onHide = () => {
        this.setState({
            planpopup: false
        })
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 63,
            source: 2
        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        const subjects = this.props.subjects;
        const loading1 = subjects.loading;
        const error1 = subjects.error;
        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }

        const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

        console.log("globalsubjects", subjects);
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper">
                    {(loading1 == true) && (<PreloaderTwo />)}
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper">
                        <Container>
                            {
                                !loading1 && (
                                    <>
                                        <ErrorExamSection
                                            getSubjects={subjects.getSubjects}
                                            studentGlobals={studentGlobals}
                                        />

                                        {/* {
                                            Cookies.get("examid") == 2 && isuserValid.jeePrepBundleAcess == true ?
                                                (
                                                    <JeeNEwPlanPopup
                                                        show={this.state.planpopup}
                                                        onHide={this.onHide}
                                                    />
                                                )
                                                :
                                                (
                                                    Cookies.get("examid") == 1 && isuserValid.jeePrepBundleAcess == true ?
                                                        (
                                                            <NEETPrepBundlePopup
                                                                show={this.state.planpopup}
                                                                onHide={this.onHide}
                                                            />
                                                        )
                                                        :
                                                        ("")
                                                )
                                        } */}
                                    </>
                                )
                            }

                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(

    graphql(gql` 
    query($mobile: String,
        $exam_id: Int,
        $class_id: Int,$subject: Int) {
        getSubjects(mobile: $mobile,
        exam_id: $exam_id,
        class_id: $class_id, subject: $subject){
            id
            studentChapters{
                id
                enabled
                error_questions
                exam_unanswered
                exam_wrong_answered
                practice_wrong_answered
                practice_unanswered
                class
                errorExamsEnabled
            }
            }
    }
    `,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_id: parseInt(Cookies.get("examid")),
                    class_id: parseInt(Cookies.get("classid"))

                },
                fetchPolicy: 'cache-and-network'
            }),
            name: "subjects"
        }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),)(ErrorExam));
