import React, { Component } from 'react'
import { Row, Col, Container, Image, Media, Button } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import FoundationClasses from '../../../images/foundationexams.png'
import FoundationExams from '../../../images/foundationcourse.png'
import FoundArrow from '../../../images/foundationarrow.png'
import foundationBooklets from '../../../images/foundationBooklets.png'
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';
import * as Cookies from "es-cookie"
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import * as compose from 'lodash.flowright';

import './_foundationcourse.scss'

const getURL = gql`
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String
    ){
        getServiceData111(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type){
            url
            }
        }
    `;

class FoundationCourseMainBlock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blockType: "",
            classValue: 1,
            syllabusType: Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ? "Ipe" : "Cbse",
            foundationExamsArray: []
        }
    }

    componentDidMount() {
        this.getOnloadExams()
        window.scroll(0, 0)
    }

    selectHandleInputChange = async (e) => {
        const name = e?.target?.name;
        const value = e?.target?.value;
        if (name == "class") {
            if (value == "1") {
                this.setState({
                    classValue: 1
                })
            } else {
                this.setState({
                    classValue: 2
                })
            }
        }
        if (name == "syllabus") {
            if (value == "1") {
                this.setState({ foundationExamsArray: [] })
                const result = await this.props.client.query({
                    query: gql`
                    query($mobile:String,$exam_group_id:Int){
                        getGroupWiseExams(mobile: $mobile,exam_group_id: $exam_group_id){
                            paidExams{
                                    id
                                    exam_name
                                    exam_type
                                    exam_session_id
                                    sub_exam_type
                                    is_completed
                                    is_started
                                    title
                                    short_name
                                    amount
                                    speed
                                    accuracy
                                    correct_marks
                                    negative_marks
                                    total_marks
                                    start_time
                                    end_time
                                    is_purchased
                                    exam_started
                                    is_scheduled
                                    is_registered
                                    exam_expired
                                    exam_duration
                                    syllabus
                                    class_id
                                    exam_class_id
                                    syllabus_desc
                                    subject_id
                                    enabled_XI
                                    enabled_XII
                                }   
                    }
                    }
                    `,
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_group_id: Cookies.get("examid") == "1" ? 12 : Cookies.get("examid") == 2 ? 13 : Cookies.get("examid") == 3 ? 13 : Cookies.get("examid") == 6 ? 13 : Cookies.get("examid") == 7 ? 12 : 12
                    },
                    fetchPolicy: 'network-only'
                })

                if (result.data.getGroupWiseExams[0].paidExams.length > 0) {
                    this.setState({
                        foundationExamsArray: result.data.getGroupWiseExams[0].paidExams
                    })
                }
                this.setState({
                    syllabusType: "Cbse"
                })
            } else {
                this.setState({ foundationExamsArray: [] })
                const result = await this.props.client.query({
                    query: gql`
                    query($mobile:String,$exam_group_id:Int){
                        getGroupWiseExams(mobile: $mobile,exam_group_id: $exam_group_id){
                            paidExams{
                                    id
                                    exam_name
                                    exam_type
                                    exam_session_id
                                    sub_exam_type
                                    is_completed
                                    is_started
                                    title
                                    short_name
                                    amount
                                    speed
                                    accuracy
                                    correct_marks
                                    negative_marks
                                    total_marks
                                    start_time
                                    end_time
                                    is_purchased
                                    exam_started
                                    is_scheduled
                                    is_registered
                                    exam_expired
                                    exam_duration
                                    syllabus
                                    class_id
                                    exam_class_id
                                    syllabus_desc
                                    subject_id
                                    enabled_XI
                                    enabled_XII
                                }   
                    }
                    }
                    `,
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_group_id: Cookies.get("examid") == "3" ? 8 : Cookies.get("examid") == "6" ? 9 : Cookies.get("examid") == "7" ? 10 : Cookies.get("examid") == "8" ? 11 : Cookies.get("examid") == "1" ? 10 : 8
                    },
                    fetchPolicy: 'network-only'
                })


                if (result.data.getGroupWiseExams[0].paidExams.length > 0) {
                    this.setState({
                        foundationExamsArray: result.data.getGroupWiseExams[0].paidExams
                    })
                }
                this.setState({
                    syllabusType: "Ipe"
                })
            }
        }
    }


    getOnloadExams = async () => {
        this.setState({ foundationExamsArray: [] })
        const result = await this.props.client.query({
            query: gql`
                    query($mobile:String,$exam_group_id:Int){
                        getGroupWiseExams(mobile: $mobile,exam_group_id: $exam_group_id){
                            paidExams{
                                    id
                                    exam_name
                                    exam_type
                                    exam_session_id
                                    sub_exam_type
                                    is_completed
                                    is_started
                                    title
                                    short_name
                                    amount
                                    speed
                                    accuracy
                                    correct_marks
                                    negative_marks
                                    total_marks
                                    start_time
                                    end_time
                                    is_purchased
                                    exam_started
                                    is_scheduled
                                    is_registered
                                    exam_expired
                                    exam_duration
                                    syllabus
                                    class_id
                                    exam_class_id
                                    syllabus_desc
                                    subject_id
                                    enabled_XI
                                     enabled_XII
                                }   
                    }
                    }
                    `,
            variables: {
                mobile: Cookies.get("mobile"),
                exam_group_id: Cookies.get("examid") == "3" ? 8 : Cookies.get("examid") == "6" ? 9 : Cookies.get("examid") == "7" ? 10 : Cookies.get("examid") == "8" ? 11 : Cookies.get("examid") == "1" ? 12 : 13
            },
            fetchPolicy: 'network-only'
        })

        if (result.data.getGroupWiseExams[0].paidExams.length > 0) {
            this.setState({
                foundationExamsArray: result.data.getGroupWiseExams[0].paidExams
            })
        }
    }

    stopVideoPlay = () => {
        var videos = document.querySelectorAll('iframe, video');
        Array.prototype.forEach.call(videos, function (video) {
            if (video.tagName.toLowerCase() == 'video') {
                video.pause();
            } else {
                var src = video.src;
                video.src = src;
            }
        });
           
    }

    render() {

        const getServiceData111 = this.props.getServiceData111
        const loading = getServiceData111.loading;
        const error = getServiceData111.error

        console.log("getServiceDatagetServiceData", getServiceData111)

        let videoUrls = [];

        if (loading != true) {
            videoUrls = getServiceData111.getServiceData111.length > 0 && getServiceData111.getServiceData111[0].url != null && getServiceData111.getServiceData111[0].url.split(',');

        }


        return (
            <section className="foundationcourse_home">
                {
                    loading == true ?
                        (
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <Image src={require('../../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                    </Col>
                                </Row>
                            </Container>
                        )
                        :
                        (
                            <Container>
                                <Row className='mt-4-resp'>
                                    <Col sm={6}>
                                        <Row>
                                            <h4 className='mr-3 ml-3'>Exam Syllabus :</h4>
                                            <div className='mt-1'>
                                                <div className="custom-control custom-radio custom-control-inline ">
                                                    <input
                                                        type="radio"
                                                        id="IPE"
                                                        value="1"
                                                        name="syllabus"
                                                        className="custom-control-input"
                                                        defaultChecked={Cookies.get("examid") == 1 || Cookies.get("examid") == 2}
                                                        onChange={this.selectHandleInputChange}
                                                    />
                                                    <label
                                                        className="custom-control-label input_text"
                                                        htmlFor="IPE"
                                                    >
                                                        CBSE
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline ">
                                                    <input
                                                        type="radio"
                                                        id="CBSE"
                                                        value="2"
                                                        name="syllabus"
                                                        className="custom-control-input"
                                                        onChange={this.selectHandleInputChange}
                                                        defaultChecked={Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8}

                                                    />
                                                    <label
                                                        className="custom-control-label input_text"
                                                        htmlFor="CBSE"
                                                    >
                                                        IPE
                                                    </label>
                                                </div>
                                            </div>

                                        </Row>
                                    </Col>

                                    <Col sm={6}>
                                        <Row>
                                            <h4 className='mr-3 ml-3'>Class :</h4>
                                            <div className='mt-1'>
                                                <div className="custom-control custom-radio custom-control-inline ">
                                                    <input
                                                        type="radio"
                                                        id="class_XI"
                                                        value="1"
                                                        name="class"
                                                        className="custom-control-input"
                                                        defaultChecked={true}
                                                        onChange={this.selectHandleInputChange}
                                                    />
                                                    <label
                                                        className="custom-control-label input_text"
                                                        htmlFor="class_XI"
                                                    >
                                                        XI
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline ">
                                                    <input
                                                        type="radio"
                                                        id="class_XII"
                                                        value="2"
                                                        name="class"
                                                        className="custom-control-input"
                                                        onChange={this.selectHandleInputChange}
                                                    />
                                                    <label
                                                        className="custom-control-label input_text"
                                                        htmlFor="class_XII"
                                                    >
                                                        XII
                                                    </label>
                                                </div>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <div className='white_pad25 mt-4'>
                                            <Row>
                                                <Col sm={6}>
                                                    <h5 className='over_view'>OVERVIEW</h5>
                                                    <p className='course_des'>Foundation courses act as a bridge between your current education (Class XI or XII) and your previous education (Class IX & X). Our comprehensive foundation course is designed to plug any academic gaps, improve your basics in every subject and strengthen your foundation</p>
                                                </Col>
                                                <Col sm={6}>
                                              <Carousel interval={false} nextIcon={<span aria-hidden="true" className="fas fa-chevron-right f-22 color_black" onClick={() => this.stopVideoPlay()} />} prevIcon={<span aria-hidden="true" className="fas fa-chevron-left f-22 color_black m_let_30" onClick={() => this.stopVideoPlay()} />}>
                                                        {
                                                            videoUrls.length > 0 ?
                                                                (
                                                                    videoUrls.map(item => {
                                                                        let embdUrl = item.split('/');
                                                                        return (

                                                                            <Carousel.Item >
                                                                                <iframe className='video_block'
                                                                                    src={`https://www.youtube.com/embed/${embdUrl[embdUrl.length - 1]}`}
                                                                                    allowFullScreen
                                                                                >
                                                                                </iframe>

                                                                            </Carousel.Item>

                                                                        )
                                                                    })
                                                                )
                                                                :
                                                                ("")
                                                        }
                                                    </Carousel>


                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='mt-4'>
                                    <Col sm={12}>
                                        <h3 className='fc_course_det'>Course Details</h3>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <div className='white_pad25 mt-1'>
                                            <Row>
                                                <Col sm={9}>
                                                    <h5 className=''>Video Lessons & Class Notes</h5>
                                                    <ul className='custom_li_alignment'>
                                                        <li className='course_des'>Live video lessons from our expert faculty</li>
                                                        <li className='course_des'>Full fleged comprehensive course</li>
                                                        <li className='course_des'>Get class notes after every class</li>
                                                    </ul>
                                                    <Link
                                                        to={{
                                                            pathname: "/student/foundation-course-classes",
                                                            state: {
                                                                classValue: this.state.classValue,
                                                                syllabusType: this.state.syllabusType
                                                            }
                                                        }}>
                                                        <Button type="button" className='found_btn mt-2 center-btn'>Watch Lessons</Button>
                                                    </Link>
                                                </Col>
                                                <Col sm={3}>
                                                    <Image
                                                        className="found_img"
                                                        src={FoundationClasses}
                                                        alt="Videos"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <div className='white_pad25 mt-2'>
                                            <Row>
                                                <Col sm={9}>
                                                    <h5 className=''>Foundation Tests</h5>
                                                    <ul className='custom_li_alignment'>
                                                        <li className='course_des'>Take Chapter wise single and cumulative test</li>
                                                        <li className='course_des'>Test based on complete learning </li>
                                                        <li className='course_des'>Build strong foundation for your competitive exam</li>
                                                    </ul>
                                                    <Link
                                                        to={{
                                                            pathname: "/student/foundation-course-exams",
                                                            state: {
                                                                classValue: this.state.classValue,
                                                                syllabusType: this.state.syllabusType,
                                                                foundationExams: this.state.foundationExamsArray
                                                            }
                                                        }}>
                                                        <Button type="button" className='found_btn mt-2 center-btn'>View Test</Button>
                                                    </Link>
                                                </Col>
                                                <Col sm={3}>
                                                    <Image
                                                        className="found_img"
                                                        src={FoundationExams}
                                                        alt="Exams"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        )
                }
            </section >
        )
    }
}

export default withRouter(withApollo(compose(
    graphql(getURL, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                service_type: "Foundation_Course",
                service_sub_type: "ALL"
            },
            fetchPolicy: "network-only",
        }), name: "getServiceData111"
    })
)(FoundationCourseMainBlock)));