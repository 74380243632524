import { thisExpression } from '@babel/types';
import React, { Component } from 'react';
import { Row, Tabs, Tab, Card, Image, Button, Col, Table } from 'react-bootstrap';
import './detailednotes.scss';
import DetailedPdfTable from './detailedPdfTable';
import UserRestrictionAlertInstitute from '../home/UserRestrictionAlertInstitute';
import * as Cookies from "es-cookie";
import LockSimple from './LockSimple.png';

class PhysicsDetailedNotes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPdf: false,
            pdfUrl: "",
            userRestionModalShow: false
        }

        this.data = [
            {
                name: 'PHYSICAL WORLD',
                url: "https://rizee.in/econtent/detailed_notes/PHY1_5412_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'MOTION IN STRAIGHT LINE ',
                url: "https://rizee.in/econtent/detailed_notes/PHY3_5694_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'MOTION IN PLANE ',
                url: "https://rizee.in/econtent/detailed_notes/PHY4_5544_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'UNITS AND MEASUREMENTS',
                url: "https://rizee.in/econtent/detailed_notes/PHY2_5442_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'LAWS OF MOTION',
                url: "https://rizee.in/econtent/detailed_notes/PHY5_2839_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'WORK ,ENERGY AND POWER',
                url: "https://rizee.in/econtent/detailed_notes/PHY6_4107_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'GRAVITATION',
                url: "https://rizee.in/econtent/detailed_notes/PHY9_8825_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'OSCILLATION',
                url: "https://rizee.in/econtent/detailed_notes/PHY8_6938_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'SYSTEM OF PARTICLE AND ROTATIONAL MOTION',
                url: "https://rizee.in/econtent/detailed_notes/PHY7_1241_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'MECHNICAL PROPERTIES OF SOLIDS',
                url: "https://rizee.in/econtent/detailed_notes/PHY10_7606_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'MECHNICAL PROPERTIES OF FLUIDS',
                url: "https://rizee.in/econtent/detailed_notes/PHY11_5105_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'THERMAL PROPERTIES OF MATTER',
                url: "https://rizee.in/econtent/detailed_notes/PHY12_2127_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'THERMODYNAMICS',
                url: "https://rizee.in/econtent/detailed_notes/PHY13_7481_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'KINETIC THEORY',
                url: "https://rizee.in/econtent/detailed_notes/PHY14_5893_LG.pdf",
                value: "View Notes",
                year: "First Year"
            },
            {
                name: 'WAVES',
                url: "https://rizee.in/econtent/detailed_notes/PHY15_7811_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'RAY OPTICS AND OPTICAL INSTRUMENTS',
                url: "https://rizee.in/econtent/detailed_notes/PHY16_1919_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'WAVE OPTICS',
                url: "https://rizee.in/econtent/detailed_notes/PHY17_6563_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'ELECTRIC STATICS ',
                url: "https://rizee.in/econtent/detailed_notes/PHY18_7132_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'CURRENT ELECTRICITY',
                url: "https://rizee.in/econtent/detailed_notes/PHY20_4148_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'MOVING CHARGES AND MAGNETISM',
                url: "https://rizee.in/econtent/detailed_notes/PHY21_6798_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'MAGNETISM AND MATTER',
                url: "https://rizee.in/econtent/detailed_notes/PHY22_4822_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'SEMICONDUCTOR ELECTRONICS:MATERIALS,DEVICES AND SIMPLE CIRCUIT',
                url: "https://rizee.in/econtent/detailed_notes/PHY29_1378_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'COMMUNICATION SYSTEM',
                url: "https://rizee.in/econtent/detailed_notes/PHY30_3622_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'DUAL NATURE OF MATTER AND RADIATION',
                url: "https://rizee.in/econtent/detailed_notes/PHY26_1432_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'ELECTROMAGNETIC INDUCTION',
                url: "https://rizee.in/econtent/detailed_notes/PHY23_7872_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'ELECTROMAGNETIC WAVES',
                url: "https://rizee.in/econtent/detailed_notes/PHY25_1234_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'ALTERNATING CURRENT',
                url: "https://rizee.in/econtent/detailed_notes/PHY24_5024_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'ATOMS',
                url: "https://rizee.in/econtent/detailed_notes/PHY27_3965_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
            {
                name: 'NUCLIE',
                url: "https://rizee.in/econtent/detailed_notes/PHY28_9322_LG.pdf",
                value: "View Notes",
                year: "Second Year"
            },
        ]
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }
    modalaFun = (info, index) => {
        console.log(info)
        let isStudentUserValid = ""
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        console.log(isStudentUserValid)
        console.log(isStudentUserValid.current_plan_id)
        console.log(Cookies.get("institution_id"))
        return (
            this.handlePdfView(info.url)
        )

    }

    handlePdfView = (url) => {
        console.log("physics")
        console.log(url)
        this.setState({
            showPdf: !this.state.showPdf,
            pdfUrl: url
        });
    }

    render() {
        return (


            <Row>
                {
                    !this.state.showPdf && this.data.map((cardInfo, index) => {
                        return (
                            <Col sm={6} className="mt-4">
                                <div class="bg_white">
                                    <Row>
                                        <Col sm={8}>
                                            <h6 class="title mt-2">{cardInfo.name} </h6>

                                        </Col>
                                        <Col sm={4}>
                                            {
                                                cardInfo.value === "Coming Soon"
                                                    ? <h6 className="float-right viewNotesComingSoonCls" onClick={() => this.modalaFun(cardInfo, index)}>{cardInfo.value}</h6> :
                                                    <h6 className="float-right viewNotesCls" onClick={() => this.modalaFun(cardInfo, index)}>{cardInfo.value}</h6>
                                            }

                                        </Col>
                                    </Row>
                                </div>
                            </Col>


                        )
                    })
                }
                <DetailedPdfTable
                    showPdf={this.state.showPdf}
                    pdfUrl={this.state.pdfUrl}
                />
                <UserRestrictionAlertInstitute
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />
            </Row>
        )

        /*return (

<div class="container">
    <Row>
        {
            !this.state.showPdf && this.data.map((cardInfo) => {
                return (
                    <Col>
                        <Card className="card_main mt-8">
                            <Card.Body className="d-flex justify-content-between">
                                <Card.Title className="card_title">{cardInfo.name}</Card.Title>
                                <Button variant="info" size="md" className="button content-style" onClick={()=> this.handlePdfView(cardInfo.url)}>{cardInfo.value}</Button>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                )
            })
        }
        </Row>
        <DetailedPdfTable
                         showPdf={this.state.showPdf}
                         pdfUrl={this.state.pdfUrl}
                />
        </div>
        )*/
    }
}
export default PhysicsDetailedNotes;
