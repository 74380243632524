import React, { Component } from 'react'
import { Container, Row, Col, Card, Image, Button, Media } from 'react-bootstrap';
import './EamcetSubPlansSection.scss'
import { withRouter, Link } from "react-router-dom";
import * as Cookies from "es-cookie";
import moment from "moment";
import self_study from '../../../images/self_study.png';



//import ipe_lec from '../../../images/ipe_lec.png';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';
import ipe_lec from "../../../images/university-college-school-building-vector.png";

class EamcetPlansHomeSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            planDetails: '',
            selectedPlanId: '',
        }
    }

    paymentFun = (data) => {
        console.log("data", data)
        this.props.history.push({
            pathname: "/student/subscribe-plans-order-summary",
            state: {
                data: data
            }
        })
    };

    componentDidMount = () => {
        const title = GoogleAnalyticsArray[0].Eamcet_Plans;
        ReactGA.pageview('/student/plans_section', ["rizee"], title);
    }

    getSubPlanDetails = (planDetails, planNameActiveCls) => {
        this.setState(state => ({ ...state, planDetails: planDetails }));
        this.state.selectedPlanId = planNameActiveCls
    }

    render() {

        const getSubscriptionPlans = this.props.getSubscriptionPlans;


        if (this.state.planDetails == "") {
            if (this.props.history.location.state == undefined || this.props.history.location.state.activePlan == undefined) {
                this.state.selectedPlanId = getSubscriptionPlans[0].id;
            } else {
                this.state.selectedPlanId = this.props.history.location.state.activePlan;
            }

            if (getSubscriptionPlans != undefined) {
                getSubscriptionPlans.map((defaultPlan, index) => {
                    if (defaultPlan.id == this.state.selectedPlanId) {
                        this.state.planDetails = defaultPlan;
                    }
                });
            }
        }

        return (
            <Container className={isMobile == true ? "plan-eamcet-package mt-5" : "plan-eamcet-package"}>
                <Row>
                    <Col sm={12} className="mt-4">
                        <h3 className="font-weight-bold">Learning Plans</h3>
                        <p className="">Choose your plan to continue learning with Rizee</p>
                    </Col>
                </Row>

                <Row>
                    {
                        getSubscriptionPlans && getSubscriptionPlans.map((planDetails) => {
                            return (
                                <Col sm={6} className="mt-4" onClick={() => this.getSubPlanDetails(planDetails, planDetails.id)}>
                                    <Card className={this.state.selectedPlanId == planDetails.id ? "white_block active active_icon" : "white_block"}>
                                        <Card.Body>
                                            <div className="d-flex align-items-center mt-2">
                                                <Card.Subtitle>
                                                    <Image src={planDetails.image_path}></Image>
                                                </Card.Subtitle>
                                                <Card.Title className="ml-2">
                                                    <h5>{planDetails.name}</h5>
                                                    <p className="f-14 color_yash text-capitalize">{planDetails.description}</p>
                                                </Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>

                <Row>
                        {
                            this.state?.planDetails && this.state.planDetails.studentPlans.map(row => {
                                let colorname = "";
                                if (row.id == "44") {
                                    colorname = "92.06deg, #06BEB6 0%, #48B1BF 100%";
                                }
                                else if (row.id == "65") {
                                    colorname = "92.06deg, #CC2B5E 0%, #753A88 100%";
                                } else if (row.id == "61") {
                                    colorname = "92.06deg, #06BEB6 0%, #48B1BF 100%";
                                } else {
                                    colorname = "92.06deg, #CC2B5E 0%, #753A88 100%";
                                }

                                let features = row.plan_features.split("#");

                                let expDate = row.expiry_date
                                let currentime = moment().unix();

                                const days = Math.floor(parseInt(expDate - currentime) / (24 * 60 * 60));
                                console.log("daysssss", Math.sign(days))

                                return (                            
                                    <Col xl={4} lg={6} md={6} sm={12} className="mt-4" >
                                            <Card className={`white_block_plan_details`}>
                                                <Card.Header className='text-center font-weight-bold color_white f-18 text-uppercase border-top-left-right' style={{ background: `linear-gradient(${colorname})` }}>
                                                    {row.plan_name}
                                                </Card.Header>
                                                <Card.Body className="card-body padding_1">

                                                    <div className="plan-subtitles text-center">
                                                        <h5 className="price_new_design">
                                                            <h6 className="f-18">{row.plan_title}</h6>
                                                            {
                                                                row.original_amount == row.amount ?

                                                                    (<><span className="f-22"><i class="fas fa-rupee-sign f-18"></i> {row.amount}/-</span></>)
                                                                    :
                                                                    (
                                                                        <h6 className='font-weight-normal mt-4'>
                                                                            <span class="color_strike"><i class="fas fa-rupee-sign f-16"></i> {row.original_amount}</span>
                                                                            <span className="ml-2 f-22"><i class="fas fa-rupee-sign f-18"></i> {row.amount}/-</span>
                                                                        </h6>
                                                                    )
                                                            }
                                                        </h5>
                                                    </div>


                                                    {
                                                        row.plan_features != "" ? (
                                                            <ul className="mb-3 list-inline list_custom_styles ml-2">
                                                                {
                                                                    features.map((item1, index) => {
                                                                        return (
                                                                            <li key={index}>
                                                                                {item1}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        )
                                                            :
                                                            ("")
                                                    }

                                                    <div className='text-center'>
                                                        {
                                                            row.upgrade_access == true ?
                                                                (<Button onClick={() => this.paymentFun(row)} className='btn btn-block btn-subscribe'>Upgrade your plan with {row.upgrade_amount + " /-"}</Button>)
                                                                :
                                                                (
                                                                    <>
                                                                        {
                                                                            currentime < row.expiry_date && row.expiry_date != "" ?
                                                                                (<Button className='btn btn-block btn-current_plan'> Current Plan</Button>)

                                                                                :
                                                                                (<Button onClick={() => this.paymentFun(row)} className='btn btn-block btn-subscribe'>Subscribe Now</Button>)
                                                                        }
                                                                        {
                                                                            row.expiry_date != "" ?
                                                                                (
                                                                                    <>
                                                                                        {
                                                                                            (Math.sign(days) == 1) ?
                                                                                                (<p className="mt-3" style={{ color: "#ff5200", fontWeight: "bold", fontSize: 13 }}>This package will expire in {days} days</p>)
                                                                                                :
                                                                                                Math.sign(days) == -1 ?
                                                                                                    (<p className="mt-3" style={{ color: "#ff5200", fontWeight: "bold", fontSize: 13 }}>This package is expired</p>)
                                                                                                    :
                                                                                                    (<p className="mt-3" style={{ color: "#ff5200", fontWeight: "bold", fontSize: 13 }}>This package is expired</p>)
                                                                                        }
                                                                                    </>
                                                                                )
                                                                                :
                                                                                ("")
                                                                        }
                                                                    </>
                                                                )
                                                        }
                                                    </div>

                                                    {<div className="coupons mt-2 text-center">
                                                        {row.additional_text != "" ? (<p> {row.additional_text}</p>) : ("")}

                                                    </div>}
                                                </Card.Body>
                                            </Card>
                                    </Col>
                                )
                            })
                        }
                </Row>
            </Container>
        )
    }

}
export default withRouter(EamcetPlansHomeSection);
