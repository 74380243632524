import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import * as Cookies from "es-cookie";
import Pdf from './pdf'
import { Link, withRouter } from 'react-router-dom'
import './_eamcetShortNotes.scss';


class SyllabusPdfTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            url1: "",

        }
    }

    render() {
        return (
            <>
                <Col sm={12}>
                    {
                        this.props.showPdf ?
                            (
                                <div className="text-link">
                                    <button onClick={() => this.props.handlePdfView('', 1)} type="button" class="btn btn-custom-red mr-2 d-flex align-items-center float-right close_button">  <span class="ml-2 d-none d-block">Close PDF</span> </button>
                                </div>
                            )
                            :
                            ("")
                    }
                </Col>

                <Col sm={12} className="mt-2">    
                    <div>{
                        this.props.showPdf ?
                            (<React.Fragment>
                                <Pdf file={this.props.pdfUrl} className='w-100'/>
                            </React.Fragment>) : ('')
                    }
                    </div>
                </Col>
                </>
        )


    }
}
export default withRouter(SyllabusPdfTable);

