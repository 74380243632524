import React, { Component } from 'react'
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap'
import { withRouter } from "react-router-dom";
import './_eamcetLongTermLiveClasses.scss';
import Chemistry from '../../../../images/chemistry_icon.png';
import Zoology from '../../../../images/zoology_icon.png';
import Physics from '../../../../images/physics_icon.png';
import Maths from '../../../../images/maths_icon.png';
import Botany from '../../../../images/botany_icon.png';
import All from '../../../../images/all_icon.png';
import CardLessDataTableWithSearch from '../../data_tables/CardLessDataTableWithSearch';
import moment from 'moment';
import EamcetRestrictionAlert from "../../home/EamcetRestrictionAlert";
import WatchNowVideosModal from './WatchNowVideosModal';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import * as Cookies from "es-cookie";
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../../pages/GoogleAnalytics';


class EamcetCrashCourseLiveClassesSection extends Component {
    constructor(props) {
        super(props)
        this.handleChapterChange = this.handleChapterChange.bind(this);
        this.state = {

            allLiveClasses: this.props?.eamcetClasses[0].enabled == false ? "today" : "all",
            search: "",
            selectedSubjectVal: 0,
            CoursesMainData: [],
            LiveClassesCoursesData: [],
            accessCourseModules: props.getCourseModuleAccess?.getCourseModuleAccess,
            getAccessSubjectNames: [],
            getSubjectsData: []


        };
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));

        }
    }

    componentDidMount = () => {
        this.displayClasses();

        const title = GoogleAnalyticsArray[0].Eamcet_Crash_Course_Classes;
        ReactGA.pageview('/student/eamcet-crashcourse/liveclasses', ["rizee"], title);

        if (this.props.stateData.getSubjectsArr != undefined || this.props.stateData.getSubjectsArr != null) {
            const getSubjects = this.props.stateData.getSubjectsArr.map((gmap, index) => {
                if (index == 0) {
                    return { ...gmap, active: "active" }
                }
                else {
                    return { ...gmap, active: "" }
                }
            })
            this.setState({
                getSubjectsData: getSubjects
            });
        }
    }
    handleSelect = (subid) => {
        this.setState({
            CoursesMainData: [],
            LiveClassesCoursesData: []

        });

        const getSubjects = this.props.stateData.getSubjectsArr.map((gmap, index) => {
            if (gmap.id == subid) {
                return { ...gmap, active: "active" }

            }
            else {
                return { ...gmap, active: "" }
            }

        })
        this.setState({
            getSubjectsData: getSubjects,
            selectedSubjectVal: subid

        });
    }


    subjectClassName(data) {
        let classname = "";
        if (data.subject == "Botany") {
            classname = `block_white botany mobile_card_eamcet  ${data.active}`
        }
        else if (data.subject == "Physics") {
            classname = `block_white physics mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Chemistry") {
            classname = `block_white chemistry mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Zoology") {
            classname = `block_white zoology mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Maths") {
            classname = `block_white maths mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "All") {
            classname = `block_white all mobile_card_eamcet ${data.active}`
        }

        return classname;

    }

    colFunction(val) {
        let columns = [
            {
                dataField: "DATE",
                text: "Date",
                sort: true,
                headerStyle: {
                    width: "12%", background: "#E9ECF1", color: "#0B3573"
                },
                style: {
                    fontWeight: "bold !important"
                },
                sortFunc: this.sortFuncDate.bind(this)
            },
            {
                dataField: "ClassTimings",
                text: "Class Timings",
                sort: true,
                headerStyle: {
                    width: "14%", background: "#E9ECF1", color: "#0B3573"
                },
                sortFunc: this.sortFuncMulti.bind(this)
            },

            {
                dataField: "Subject",
                text: "Subject",
                headerStyle: {
                    background: "#E9ECF1", color: "#0B3573"
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                    width: "20%", background: "#E9ECF1", color: "#0B3573"
                }
            },
            {
                dataField: "Assignment",
                text: "Assignment",
                headerStyle: {
                    width: "18%", background: "#E9ECF1", color: "#0B3573"
                },
                formatter: this.actionFormatterAssignMent.bind(this)
            },
            {
                dataField: "Quiz",
                text: "Quiz",
                headerStyle: {
                    width: "18%", background: "#E9ECF1", color: "#0B3573"
                },
                formatter: this.actionFormatterQuiz.bind(this)
            },
            {
                dataField: "VideoLecture",
                text: "Video Lecture",
                headerStyle: {
                    width: "40%", background: "#E9ECF1", color: "#0B3573"
                },
                formatter: this.actionFormatterVideoLecture.bind(this),

            },

        ];
        return columns;
    }
    // colSubjectFunction(val) {
    //     let columns = [
    //         {
    //             dataField: "DATE",
    //             text: "Date",
    //             sort: true,
    //             headerStyle: {
    //                 width: "12%", background: "#E9ECF1", color: "#0B3573"
    //             },
    //             style: {
    //                 fontWeight: "bold !important"
    //             },
    //             sortFunc: this.sortFuncDate.bind(this)
    //         },
    //         {
    //             dataField: "ClassTimings",
    //             text: "Class Timings",
    //             sort: true,
    //             headerStyle: {
    //                 width: "15%", background: "#E9ECF1", color: "#0B3573"
    //             },
    //             sortFunc: this.sortFuncMulti.bind(this)
    //         },
    //         {
    //             dataField: "Chapter",
    //             text: "Chapter",
    //             headerStyle: {
    //                 width: "21%", background: "#E9ECF1", color: "#0B3573"
    //             }
    //         },
    //         {
    //             dataField: "Assignment",
    //             text: "Assignment",
    //             headerStyle: {
    //                 width: "18%", background: "#E9ECF1", color: "#0B3573"
    //             },
    //             formatter: this.actionFormatterAssignMent.bind(this)
    //         },
    //         {
    //             dataField: "Quiz",
    //             text: "Quiz",
    //             headerStyle: {
    //                 width: "18%", background: "#E9ECF1", color: "#0B3573"
    //             },
    //             formatter: this.actionFormatterQuiz.bind(this)
    //         },
    //         {
    //             dataField: "VideoLecture",
    //             text: "Video Lecture",
    //             headerStyle: {
    //                 width: "40%", background: "#E9ECF1", color: "#0B3573"
    //             },
    //             formatter: this.actionFormatterVideoLecture.bind(this),

    //         },

    //     ];
    //     return columns;
    // }

    handleClasses = (val) => {
        this.setState({
            allLiveClasses: val
        });
    }


    handleChapterChange = (event) => {
        this.setState({
            search: event.target.value
        })
    }

    modalaFun = (row) => {
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });
    }
    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }

    handleResultFunction = (id) => {

        this.props.history.push({
            pathname: "/student/subject/exam-result",
            state: {
                sessionid: id,
                examtype: "eamcet_quiz_exam",
                //mocktype: "history"

            }
        })
    }
    //new code 

    getAccessPlans = (planId) => {
        if (this.props.eamcetClasses[0].enabled == true) {
            return false
        } else {
            return true
        }
    }

    subjectAcess(row) {
        let data = this.state.getAccessSubjectNames.filter(item => item == row.Subject);
        if (data.length > 0) {
            return true;
        }
        return false;
    }


    actionFormatterQuiz(cell, row, rowIndex) {
        const epriryDateInunixTime = this.isStudentUserValid.expiry_date;
        let currentTime_currentSec = Math.round((new Date()).getTime() / 1000);
        return (
            <>
                <div className='text-center'>
                    {
                        this.getAccessPlans(this.isStudentUserValid.current_plan_id) || row.freeAccess == "true" ?
                            (
                                this.props.getlongtermQuizzes.map((item) => {

                                    let data = moment.unix(item.start_time).format("DD-MM-YYYY");
                                    return (
                                        (data == row.DATE && item.subject_id == row.Subject_id && item.class_session_id == row.class_session_id) ?
                                            (
                                                item.is_completed == true ?
                                                    (
                                                        <button type="button" className="btn btn-custom-blue" onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results </button>
                                                    )
                                                    :
                                                    (
                                                        item.is_started == true ? (
                                                            <button type="button" className="btn btn-custom-blue" onClick={() => this.resumeExam(item)}>Resume Test</button>
                                                        ) : (
                                                            currentTime_currentSec > item.start_time && currentTime_currentSec < item.end_time ? (
                                                                <button type="button" className="btn btn-custom-blue" onClick={() => this.startExam(item)}>Start Test</button>
                                                            ) : (
                                                                currentTime_currentSec < item.start_time ? (
                                                                    <button type="button" className="btn btn-custom-blue cursor_disabled" disabled>Start Test</button>
                                                                ) : (
                                                                    <h6>Quiz Missed</h6>
                                                                )
                                                            )
                                                        )
                                                    )
                                            ) : ("")

                                    )
                                }
                                )
                            )
                            :
                            (
                                this.subjectAcess(row) ?
                                    (
                                        this.props.getlongtermQuizzes.map((item) => {

                                            let data = moment.unix(item.start_time).format("DD-MM-YYYY");
                                            return (
                                                (data == row.DATE && item.subject_id == row.Subject_id && item.class_session_id == row.class_session_id) ?
                                                    (
                                                        item.is_completed == true ?
                                                            (
                                                                <button type="button" className="btn btn-custom-blue" onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results </button>
                                                            )
                                                            :
                                                            (
                                                                item.is_started == true ? (
                                                                    <button type="button" className="btn btn-custom-blue" onClick={() => this.resumeExam(item)}>Resume Test</button>
                                                                ) : (
                                                                    currentTime_currentSec > item.start_time && currentTime_currentSec < item.end_time ? (
                                                                        <button type="button" className="btn btn-custom-blue" onClick={() => this.startExam(item)}>Start Test</button>
                                                                    ) : (
                                                                        currentTime_currentSec < item.start_time ? (
                                                                            <button type="button" className="btn btn-custom-blue cursor_disabled" disabled>Start Test</button>
                                                                        ) : (
                                                                            <h6>Quiz Missed</h6>
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                    ) : ("")

                                            )
                                        }
                                        )
                                    )
                                    :
                                    (
                                        <a onClick={() => this.modalFunAssignments()} className="">
                                            <button type="button" className="btn btn-custom-blue">Start Test</button>
                                        </a>
                                    )
                            )
                    }

                </div>

            </>
        )

    }


    actionFormatterVideoLecture(cell, row, rowIndex) {
        const epriryDateInunixTime = this.isStudentUserValid.expiry_date;
        let currentTime_currentSec = Math.round((new Date()).getTime() / 1000);



        let currentDate = moment().format("DD-MM-YYYY");
        let currentMonth = moment().format('MM')
        let currentYear = moment().format('YYYY')
        let currentRowMonth = moment(row.DATE, "DD-MM-YYYY").format("MM")
        let currentRowYear = moment(row.DATE, "DD-MM-YYYY").format("YYYY")


        let date1 = moment(row.time, "h:mm").format("H:mm");
        let date2 = moment(date1, "h:mm").add(100, 'm').format("H:mm");
        let date3 = moment().format("H:mm");
        let date4 = moment(date2, " HH:mm").diff(moment(date3, " HH:mm"));

        return (
            <>
                {
                    this.getAccessPlans(this.isStudentUserValid.current_plan_id) || row.freeAccess == "true" ?
                        (
                            currentRowYear == currentYear ?
                                (
                                    (currentMonth == currentRowMonth) ?
                                        (
                                            currentDate == row.DATE && date4 > 0 ?

                                                (
                                                    <a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-custom-red">Live Now</button></a>

                                                )
                                                :
                                                (
                                                    currentDate < row.DATE ?

                                                        (
                                                            currentDate == row.DATE && date4 < 0 ? (
                                                                <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                                            ) : (
                                                                <button type="button" className="btn btn-custom-disabled-cls effect" disabled>Live Now</button>
                                                            )

                                                        ) :

                                                        (
                                                            <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                                        )
                                                )
                                        )
                                        :
                                        (
                                            currentMonth < currentRowMonth ? (
                                                <button type="button" className="btn btn-custom-disabled-cls effect" disabled>Live Now</button>
                                            ) : (
                                                <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
                                            )
                                        )

                                )
                                :
                                (
                                    <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
                                )
                        )
                        :
                        (
                            this.subjectAcess(row) ?
                                (
                                    currentRowYear == currentYear ?
                                        (
                                            (currentMonth == currentRowMonth) ?
                                                (
                                                    currentDate == row.DATE && date4 > 0 ?

                                                        (
                                                            <a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-custom-red">Live Now</button></a>

                                                        )
                                                        :
                                                        (
                                                            currentDate < row.DATE ?

                                                                (
                                                                    currentDate == row.DATE && date4 < 0 ? (
                                                                        <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                                                    ) : (
                                                                        <button type="button" className="btn btn-custom-disabled-cls effect" disabled>Live Now</button>
                                                                    )

                                                                ) :

                                                                (
                                                                    <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                                                )
                                                        )
                                                )
                                                :
                                                (
                                                    currentMonth < currentRowMonth ? (
                                                        <button type="button" className="btn btn-custom-disabled-cls effect" disabled>Live Now</button>
                                                    ) : (
                                                        <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
                                                    )
                                                )

                                        )
                                        :
                                        (
                                            <button type="button" className="btn btn-custom-blue" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
                                        )
                                )
                                :
                                (<a onClick={() => this.modalFunAssignments()} className="">
                                    <button type="button" className="btn subscribe-button">Subscribe</button>
                                </a>)

                        )
                }
            </>
        )
    }

    actionFormatterAssignMent(cell, row, rowIndex) {
        const epriryDateInunixTime = this.isStudentUserValid.expiry_date;

        let currentTime_currentSec = Math.round((new Date()).getTime() / 1000);
        return (
            <>
                <div className='text-center'>
                    {
                        this.getAccessPlans(this.isStudentUserValid.current_plan_id) || row.freeAccess == "true" ?
                            (
                                this.props.getLongtermAssignments.map((item) => {

                                    let data = moment.unix(item.start_time).format("DD-MM-YYYY");
                                    return (

                                        (data == row.DATE && item.subject_id == row.Subject_id && item.class_session_id == row.class_session_id) ?
                                            (
                                                item.is_completed == true ?
                                                    (<button type="button" className="btn btn-custom-blue" onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results </button>)
                                                    :
                                                    (
                                                        item.is_started == true ? (
                                                            <button type="button" className="btn btn-custom-blue" onClick={() => this.resumeExam(item)}>Resume Test</button>
                                                        ) : (
                                                            currentTime_currentSec > item.start_time && currentTime_currentSec < item.end_time ? (
                                                                <button type="button" className="btn btn-custom-blue" onClick={() => this.startExam(item)}>Start Test</button>
                                                            ) : (
                                                                currentTime_currentSec < item.start_time ? (
                                                                    <button type="button" className="btn btn-custom-blue cursor_disabled" disabled>Start Test</button>
                                                                ) : (
                                                                    <h6>Assign Missed</h6>
                                                                )
                                                            )

                                                        )

                                                    )

                                            ) : ("")
                                    )

                                }
                                )
                            )
                            :
                            (
                                this.subjectAcess(row) ?
                                    (
                                        this.props.getLongtermAssignments.map((item) => {

                                            let data = moment.unix(item.start_time).format("DD-MM-YYYY");
                                            return (

                                                (data == row.DATE && item.subject_id == row.Subject_id && item.class_session_id == row.class_session_id) ?
                                                    (
                                                        item.is_completed == true ?
                                                            (<button type="button" className="btn btn-custom-blue" onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results </button>)
                                                            :
                                                            (
                                                                item.is_started == true ? (
                                                                    <button type="button" className="btn btn-custom-blue" onClick={() => this.resumeExam(item)}>Resume Test</button>
                                                                ) : (
                                                                    currentTime_currentSec > item.start_time && currentTime_currentSec < item.end_time ? (
                                                                        <button type="button" className="btn btn-custom-blue" onClick={() => this.startExam(item)}>Start Test</button>
                                                                    ) : (
                                                                        currentTime_currentSec < item.start_time ? (
                                                                            <button type="button" className="btn btn-custom-blue cursor_disabled" disabled>Start Test</button>
                                                                        ) : (
                                                                            <h6>Assign Missed</h6>
                                                                        )
                                                                    )

                                                                )

                                                            )

                                                    ) : ("")
                                            )

                                        }
                                        )
                                    )
                                    :
                                    (
                                        <a onClick={() => this.modalFunAssignments()} className="">
                                            <button type="button" className="btn btn-custom-blue">Start Test</button>
                                        </a>
                                    )
                            )

                    }
                </div>

            </>
        )

    }



    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    sortFuncDate(a, b, order, dataField, rowA, rowB) {
        if (order == 'desc') {

            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }



    startExam = (data) => {
        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name;
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "eamcet_exam");
        localStorage.setItem("examname", examname);
        localStorage.setItem("resumeExam", false);
        localStorage.setItem("subexamtype", this.state.subtype);
        localStorage.setItem("examtype_pattern", "eamcet_quiz_exam");

        if (isMobile == true && Cookies.get("institution_id") == 30) {
            this.props.history.push({
                pathname: "/student/subject/series_test",
            })
        } else {
            window.open("/student/subject/series_test", "_blank");
        }
    }


    resumeExam = (data) => {
        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name;
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "eamcet_exam");
        localStorage.setItem("examname", examname);
        localStorage.setItem("subexamtype", this.state.subtype);
        localStorage.setItem("examtype_pattern", "eamcet_quiz_exam");
        localStorage.setItem("resumeExam", true);

        if (isMobile == true && Cookies.get("institution_id") == 30) {
            this.props.history.push({
                pathname: "/student/subject/series_test",
            })
        } else {
            window.open("/student/subject/series_test", "_blank");
        }
    }

    displayClasses() {
        if (this.state.accessCourseModules != undefined) {
            this.state.accessCourseModules.map((item) => {
                if (item.courseModuleAccess.length > 0) {
                    this.setState({ allLiveClasses: "today" })
                }
            })
        }
    }

    render() {
        let currentPlanId = this.isStudentUserValid.current_plan_id;
        let currentTime = moment().unix();
        let currentExpDate = this.isStudentUserValid.expiry_date;
        let currentDate = moment().format("DD/MM/YYYY");
        const eamcetClasses = this.props.eamcetClasses;
        console.log("eamcetClasses", eamcetClasses)


        let subjectData = [];
        if (this.props.eamcetClasses != undefined && this.props.eamcetClasses.length > 0 && this.props.eamcetClasses[0].data != null) {
            if (this.state.selectedSubjectVal != 0) {
                subjectData = eamcetClasses[0].data.filter((item) => item.Subject_id == this.state.selectedSubjectVal)
            } else {
                subjectData = eamcetClasses[0].data
            }

            this.state.CoursesMainData = subjectData


            let defaultLiveClassesCourses = [];
            for (var i in subjectData) {
                if (currentDate == moment(subjectData[i].DATE, "DD-MM-YYYY").format("DD/MM/YYYY")) {
                    if (this.state.allLiveClasses == "today") {
                        defaultLiveClassesCourses.push(subjectData[i])
                        this.state.LiveClassesCoursesData = defaultLiveClassesCourses;
                    }
                }
            }

            // if (this.state.accessCourseModules != undefined) {
            //     this.state.accessCourseModules.map((item, index) => {

            //         item.courseModuleAccess.map((item1, index) => {
            //             if (currentPlanId == item1.student_plan_id && currentTime < currentExpDate) {
            //                 var subject_namesArr = item1.subject_names.split(",");
            //                 this.state.getAccessSubjectNames = subject_namesArr
            //             }
            //         })
            //     });
            // }

            if (this.state.accessCourseModules != undefined) {
                this.state.accessCourseModules.map((item, index) => {
                    if (item.courseModuleAccess.length > 0) {
                        var subject_namesArr = item.courseModuleAccess[0].subject_names.split(",");
                        this.state.getAccessSubjectNames = subject_namesArr
                    }
                })
            }
        }

        return (
            <Container className='eamcet-longterm-classes mt-3'>
                <Row>
                    <Col sm={12} className="mt-res-2">
                        <div className='subject-blocks-view'>
                            {
                                this.state.getSubjectsData.map(sub =>
                                    <div className={this.subjectClassName(sub)} onClick={() => this.handleSelect(sub.id)}  >
                                        <Row>
                                            <Col sm={6}>
                                                <h5 className='mt-2'>{sub.subject}</h5>
                                            </Col>
                                            <Col sm={6}>
                                                {
                                                    sub.id == "0" ?
                                                        (<Card.Img variant="top" src={All} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "1" ?
                                                        (<Card.Img variant="top" src={Botany} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "2" ?
                                                        (<Card.Img variant="top" src={Physics} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "3" ?
                                                        (<Card.Img variant="top" src={Chemistry} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "5" ?
                                                        (<Card.Img variant="top" src={Zoology} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "4" ?
                                                        (<Card.Img variant="top" src={Maths} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                            </Col>
                                        </Row>

                                    </div>
                                )
                            }

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <ul className='list-inline class_type_li mt-4'>
                            <li className={this.state.allLiveClasses == "all" ? "active" : ""} onClick={() => this.handleClasses("all")}>All Classes</li>
                            <li className={this.state.allLiveClasses == "today" ? "active ml-4" : "ml-4"} onClick={() => this.handleClasses("today")}>Today Classes</li>
                        </ul>
                    </Col>
                </Row>
                {/* <Row>
                    <Col sm={12} className="mobile_table_resp">
                        <div className="table-responsive fontWeightTDs">
                            <table className="table  table-bordered">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Class Timings</th>
                                        <th>Subject</th>
                                        <th>Chapter</th>
                                        <th>Quiz</th>
                                        <th>Assignment</th>
                                        <th>Videos(Recorded/Live)</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={7} className='text-center'>Coming Soon</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row> */}
                <Row>
                    <Col sm={12} className="mobile_table_resp" >
                        {
                            this.state.CoursesMainData == "" && this.state.allLiveClasses == "all" ?
                                (
                                    <div className="table-responsive fontWeightTDs">
                                        <table className="table  table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Class Timings</th>
                                                    <th>Subject</th>
                                                    <th>Chapter</th>
                                                    <th>Assignment</th>
                                                    <th>Quiz</th>
                                                    <th>Videos(Recorded/Live)</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={7} className='text-center'>Coming soon</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                                :
                                (
                                    this.state.allLiveClasses == "all" ?
                                        (
                                            <CardLessDataTableWithSearch
                                                parentData={this.state.CoursesMainData}
                                                particlecolumns={this.colFunction()}
                                                defaultSorted={[{
                                                    dataField: "ClassTimings",
                                                    order: "asc"
                                                }]}
                                                searchBar={true}
                                                pageType="EamcetCourse"
                                            />
                                        )
                                        :
                                        ("")

                                )
                        }
                        {
                            this.state.LiveClassesCoursesData == "" && this.state.allLiveClasses == "today" ?
                                (
                                    <div className="table-responsive fontWeightTDs">
                                        <table className="table  table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Class Timings</th>
                                                    <th>Subject</th>
                                                    <th>Chapter</th>
                                                    <th>Assignment</th>
                                                    <th>Quiz</th>
                                                    <th>Videos(Recorded/Live)</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={7} className='text-center'>{this.state.CoursesMainData == "" ? "Coming soon" : "You don't have any classes Today...."}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                                :
                                (
                                    this.state.allLiveClasses == "today" ?
                                        (
                                            <CardLessDataTableWithSearch
                                                parentData={this.state.LiveClassesCoursesData}
                                                particlecolumns={this.colFunction()}
                                                defaultSorted={[{
                                                    dataField: "ClassTimings",
                                                    order: "asc"
                                                }]}
                                                searchBar={true}
                                                pageType="EamcetCourse"
                                            />
                                        )
                                        :
                                        ("")

                                )

                        }
                    </Col>
                </Row>
                <WatchNowVideosModal data={this.state.dataLiveUrl}
                    show={this.state.liveModalShow}
                    onHide={() => this.setState({ liveModalShow: false })}
                    videoDescription={this.props.videoDescription?.getVideoDescription.filter((a) => a.video_code == this.state.dataLiveUrl)} />
                <EamcetRestrictionAlert
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                    planDetailsShow="lt_course"
                    getAccessSubjectNames={this.state.getAccessSubjectNames}
                />

            </Container>
        )
    }

}
export default withRouter(EamcetCrashCourseLiveClassesSection);
