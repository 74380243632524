import React, { Component } from 'react'
import ExamHistoryAsideBar from '../../navbars/ExamHistoryAsideBar';
import ViewQuestionAnswerSection from './ViewQuestionAnswerSection';
import { Container } from 'react-bootstrap';
import '../_preexam.scss';
import ExamHistoryNavbar from '../../navbars/ExamHistoryNavbar';
import gql from "graphql-tag";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
const UPDATE_ERROR_REASONS = gql`
mutation(
    $params:AddStudentExamReasons
    ){
        addStudentExamReasons(
            params:$params
        )
    }
`;

class ViewQuestionAnswerSectionMiddle extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: 0,
            questions: props.getStudentExamSessions,
            wrong: "",
            skip: "",
            correct: "",
            all: "active",
            reloader: "0",
            question_id: "",
            reason_for_wrong: { value: 0, label: "Select" },
            comments: "",
            isChanged: false

        }
    }

    componentDidMount() {
        let question = this.props.getStudentExamSessions[0];

        if (question.reason_for_wrong != "") {

            let reason = this.props.studentGlobals.errorReasons.find((item) => item.reason == question.reason_for_wrong);
            let reasonObj = {
                value: reason.id,
                label: reason.reason
            }
            this.setState({
                question_id: question.id,
                reason_for_wrong: reasonObj,
                comments: question.comments,
            })
        }
    }

    nextQuestionfunction = () => {

        let stateData = this.state;

        let params = {
            exam_session_id: this.props.history.location.state.sessionid,
            question_id: parseInt(stateData.question_id),
            reason_for_wrong: parseInt(stateData.reason_for_wrong.value),
            comments: stateData.comments.toString(),

        }

        let question = this.state.questions[stateData.index + 1];


        if (question.reason_for_wrong != "") {
            let reason = this.props.studentGlobals.errorReasons.find((item) => item.reason == question.reason_for_wrong);
            let reasonObj = {
                value: reason.id,
                label: reason.reason
            }
            this.setState({
                index: parseInt(this.state.index + 1),
                question_id: question.id,
                reason_for_wrong: reasonObj,
                comments: question.comments,
            });

        } else {
            this.setState({
                index: parseInt(this.state.index + 1),
                question_id: "",
                reason_for_wrong: { value: 0, label: "Select" },
                comments: "",
            });
        }



        if (this.state.isChanged) {
            this.addStudentExamReasons(params)
        }
    }


    PreQuestionfunction = () => {

        let stateData = this.state;

        let params = {
            exam_session_id: this.props.history.location.state.sessionid,
            question_id: parseInt(stateData.question_id),
            reason_for_wrong: parseInt(stateData.reason_for_wrong.value),
            comments: stateData.comments.toString(),

        }

        let question = this.state.questions[stateData.index - 1];


        if (question.reason_for_wrong != "") {
            let reason = this.props.studentGlobals.errorReasons.find((item) => item.reason == question.reason_for_wrong);
            let reasonObj = {
                value: reason.id,
                label: reason.reason
            }
            this.setState({
                index: parseInt(this.state.index + 1),
                question_id: question.id,
                reason_for_wrong: reasonObj,
                comments: question.comments,
            });

        } else {
            this.setState({
                index: parseInt(this.state.index + 1),
                question_id: "",
                reason_for_wrong: { value: 0, label: "Select" },
                comments: "",
            });
        }

        this.setState({
            index: parseInt(this.state.index - 1),
        });

        if (this.state.isChanged) {
            this.addStudentExamReasons(params)
        }

    }

    addStudentExamReasons = (param) => {
        let params = param

        this.submitpt(
            params
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });

    }



    submitpt = async (
        params) => {
        await this.props.addStudentExamReasons({
            variables: {
                params
            },
            update: (store, { data }) => {

                if (data.addStudentExamReasons) {
                    let reason = this.props.studentGlobals.errorReasons.find((item) => item.id == params.reason_for_wrong);
                    let arr = this.state.questions;
                    let questionIndex = this.state.questions.findIndex((item) => item.id == params.question_id)
                    arr[questionIndex].comments = params.comments;
                    arr[questionIndex].reason_for_wrong = reason.reason;
                    this.setState({
                        questions: arr,
                        isChanged: false
                    })
                }
            }
        });
    };



    psideQFun = (getindex) => {


        let question = this.props.getStudentExamSessions[getindex];
        console.log("question");

        if (question.reason_for_wrong != "") {

            let reason = this.props.studentGlobals.errorReasons.find((item) => item.reason == question.reason_for_wrong);
            let reasonObj = {
                value: reason.id,
                label: reason.reason
            }
            this.setState({
                question_id: question.id,
                reason_for_wrong: reasonObj,
                comments: question.comments,
            })
        } else {
            this.setState({
                question_id: "",
                reason_for_wrong: { value: 0, label: "Select" },
                comments: "",
            })
        }

        this.setState({
            index: parseInt(getindex)
        });

    }
    separationCounts = (type) => {
        let questions = this.props.getStudentExamSessions;
        console.log("etStudentExamSessions", this.props);
        // 0skipp.1wrong,2correct
        if (type == "wrong") {
            questions = questions.filter((a) => a.status == 1);
        }
        if (type == "skip") {
            questions = questions.filter((a) => a.status == 0);

        }
        if (type == "correct") {
            questions = questions.filter((a) => a.status == 2);

        }
        if (type == "all") {
            questions = this.props.getStudentExamSessions;

        }

        if (type == "wrong") {
            if (this.state.wrong != "active") {
                this.setState({
                    index: 0,
                    wrong: "active",
                    skip: "",
                    correct: "",
                    all: "",
                    questions: questions
                });
            }
            else {
                this.setState({
                    index: 0,
                    wrong: "",
                    skip: "",
                    correct: "",
                    all: "",
                    questions: questions
                });
            }

        }
        if (type == "skip") {
            if (this.state.skip != "active") {
                this.setState({
                    index: 0,
                    wrong: "",
                    skip: "active",
                    correct: "",
                    all: "",
                    questions: questions
                });
            }
            else {
                this.setState({
                    index: 0,
                    wrong: "",
                    skip: "",
                    correct: "",
                    all: "",
                    questions: questions
                });
            }
        }
        if (type == "correct") {
            if (this.state.correct != "active") {
                this.setState({
                    wrong: "",
                    index: 0,
                    skip: "",
                    correct: "active",
                    all: "",
                    questions: questions
                });
            }
            else {
                this.setState({
                    index: 0,
                    wrong: "",
                    skip: "",
                    correct: "",
                    all: "",
                    questions: questions
                });
            }
        }
        if (type == "all") {
            if (this.state.all != "active") {
                this.setState({
                    index: 0,
                    wrong: "",
                    skip: "",
                    correct: "",
                    all: "active",
                    questions: questions
                });
            }
            else {
                this.setState({
                    index: 0,
                    wrong: "",
                    skip: "",
                    correct: "",
                    all: "active",
                    questions: questions
                });
            }
        }


    }
    parentonScrollgetQuestionById = (questionData) => {

        this.setState({
            reloader: "2"
        });
        const questions = this.state.questions.map((item) => {
            if (item.id == questionData[0].id) {
                return {
                    ...item,
                    question: questionData[0].question,
                    option1: questionData[0].option1,
                    option2: questionData[0].option2,
                    option3: questionData[0].option3,
                    option4: questionData[0].option4,
                    compquestion: questionData[0].compquestion,
                    list1type: questionData[0].list1type,
                    list2type: questionData[0].list2type,
                    mat_question: questionData[0].mat_question,
                    explanation: questionData[0].explanation,
                    inputquestion: questionData[0].inputquestion,

                }

            }
            else {
                return { ...item }
            }

        });

        this.setState({
            questions: questions,
            reloader: "1"
        });
        setTimeout(() => { this.SetreLoad() }, 1500);
    }
    SetreLoad = () => {
        this.setState({
            reloader: "0"
        });
    }

    handleSelectChange = (name, value) => {
        let arr = this.state.questions[this.state.index];
        let comments = "";
        let reasons = [];
        if (value != 0) {
            this.props.studentGlobals.errorReasons.filter((item) => item.id == value).map((reason) => {
                reasons.push({ value: reason.id, label: reason.reason })
            })
            comments = arr.comments
        } else {
            reasons.push({ value: 0, label: "Select" })
        }

        this.setState({
            question_id: arr.id,
            reason_for_wrong: reasons[0],
            isChanged: true,
            comments: comments
        })
    }
    commentsFunction = (e) => {
        let arr = this.state.questions[this.state.index];
        this.setState({
            question_id: arr.id,
            comments: e.target.value,
            isChanged: true
        })
    }

    render() {
        console.log("ssss", this.props, this.state)
        return (
            <React.Fragment>
                <div className="header-area-section">
                    <ExamHistoryNavbar
                        onClick={() => this.props.changeToggle()}
                        locdata={this.props.locdata}
                        type="cexam"
                        getStudentExamSessions={this.props.getStudentExamSessions}
                        stateData={this.state}
                    />
                </div>

                <div className="main-wrapper-section">

                    <ExamHistoryAsideBar
                        psideQFun={this.psideQFun}
                        stateData={this.state}
                        getData={this.props.getData}
                        separationCounts={this.separationCounts}

                    />
                    <div className="content-wrapper p-0">
                        <Container>
                            <ViewQuestionAnswerSection
                                stateData={this.state}
                                getStudentExamSessions={this.props.getStudentExamSessions}
                                PnextQuestionfunction={this.nextQuestionfunction}
                                PreQuestionfunction={this.PreQuestionfunction}
                                parentonScrollgetQuestionById={this.parentonScrollgetQuestionById}
                                onClick={() => this.props.changeToggle()}
                                studentGlobals={this.props.studentGlobals}
                                ParentSelecthandleChange={this.handleSelectChange}
                                pCommentsFunction={this.commentsFunction}
                            />
                        </Container>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
// export default withRouter(ViewQuestionAnswerSectionMiddle);


export default withRouter(withApollo(
    graphql(UPDATE_ERROR_REASONS, {

        name: "addStudentExamReasons"
    })
        (ViewQuestionAnswerSectionMiddle))
);

