import React, { Component } from 'react';
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import * as Cookies from "es-cookie";
import { withRouter } from "react-router-dom";
import './_eamcetPratiseTest.scss';
import Chemistry from '../../../images/chemistry_icon.png';
import Zoology from '../../../images/zoology_icon.png';
import Physics from '../../../images/physics_icon.png';
import Maths from '../../../images/maths_icon.png';
import Botany from '../../../images/botany_icon.png';
import moment from 'moment';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import EamcetRestrictionAlert from "../home/EamcetRestrictionAlert";
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';
import Arrow from "../../../images/eamcet_arrow.png"


let subArray = [];
// {
//     (Cookies.get("examid") == 7) || (Cookies.get("examid") == 8) ? (

//         subArray = [{ id: '1', subject: 'Botany' },{ id: '5', subject: 'Zoology' },{ id: '2', subject: 'Physics' },{ id: '3', subject: 'Chemistry' }]
//     ) : (
//         subArray = [{id: '4', subject: 'Mathematics'},{ id: '2', subject: 'Physics' },{ id: '3', subject: 'Chemistry' }]

//     )
// }

class EamcetPractiseTestSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getSubjectsArr: [],
            classValue: "1",
            selectedSubjectVal: "",
            getEamcetPracticeTestsArr: [],
            onloadSubjectWiseChapters: 0,
            expandval: "",
            subData: subArray,
            userRestionModalShow: false
        };
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }

    // componentDidMount = () => {
    //     const title = GoogleAnalyticsArray[0].Eamcet_Practice_Tests;
    //     ReactGA.pageview('/student/eamcet_practise_test',["rizee"], title);

    //     if (this.props.globalsubjects != undefined || this.props.globalsubjects != null) {
    //         let selectedSubject = "";
    //         const getSubjects = this.state.subData.map((gmap, index) => {
    //             if (index == 0) {
    //                 selectedSubject = gmap.id
    //                 return { ...gmap, active: "active" }

    //             }
    //             else {
    //                 return { ...gmap, active: "" }
    //             }

    //         })
    //         this.setState({
    //             getSubjectsArr: getSubjects,
    //             selectedSubjectVal: selectedSubject
    //         });
    //     }

    // }

    componentDidMount = () => {
        if (this.props.getSubjectOrder != undefined || this.props.getSubjectOrder != null) {
            let selectedSubject = "";
            const getSubjects = this.props.getSubjectOrder.map((gmap, index) => {
                if (index == 0) {
                    selectedSubject = gmap.id
                    return { ...gmap, active: "active" }
                }
                else {
                    return { ...gmap, active: "" }
                }
            })
            this.setState({
                getSubjectsArr: getSubjects,
                selectedSubjectVal: selectedSubject
            });
        }
    }

    subjectClassName(data) {
        let classname = "";
        if (data.subject == "Botany") {
            classname = `block_white botany mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Physics") {
            classname = `block_white physics mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Chemistry") {
            classname = `block_white chemistry mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Zoology") {
            classname = `block_white zoology mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Mathematics") {
            classname = `block_white maths mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Biology") {
            classname = `block_white botany mobile_card_eamcet ${data.active}`

        }

        return classname;

    }

    handleSelect = (subid) => {
        this.setState({ getEamcetPracticeTests: [], onloadSubjectWiseChapters: 1 });
        const getSubjects = this.state.getSubjectsArr.map((gmap, index) => {
            if (gmap.id == subid) {
                return { ...gmap, active: "active" }
            }
            else {
                return { ...gmap, active: "" }
            }
        });

        // let subjectWiseTestsArr = [];
        // if (this.props.getEamcetPracticeTests != undefined) {
        //     const getTestsArr = this.props.getEamcetPracticeTests;
        //     getTestsArr.filter(item => item.class_id == parseInt(this.state.classValue) && item.subject_id == parseInt(subid))
        //         .map(item2 => {

        //             subjectWiseTestsArr.push(item2);
        //         });
        // }

        this.setState({
            getSubjectsArr: getSubjects,
            selectedSubjectVal: subid,
            // getEamcetPracticeTestsArr: subjectWiseTestsArr
        });
    }

    handleSelectClassVal(val) {
        this.setState({ getEamcetPracticeTests: [], onloadSubjectWiseChapters: 1 });

        // let subjectWiseTestsArr = [];
        // if (this.props.getEamcetPracticeTests != undefined) {
        //     const getTestsArr = this.props.getEamcetPracticeTests;
        //     getTestsArr.filter(item => item.class_id == parseInt(val) && item.subject_id == parseInt(this.state.selectedSubjectVal))
        //         .map(item2 => {

        //             subjectWiseTestsArr.push(item2);
        //         });
        // }

        this.setState({
            classValue: val,
            // getEamcetPracticeTestsArr: subjectWiseTestsArr
        });
    }

    getSubjectName = (subid) => {
        let subjectName = "";
        if (subid == "1") {
            subjectName = "Botany";
        } else if (subid == "2") {
            subjectName = "Physics";
        } else if (subid == "3") {
            subjectName = "Chemistry";
        } else if (subid == "4") {
            subjectName = "Mathematics";
        } else if (subid == "5") {
            subjectName = "Zoology";
        } else if (subid == "6") {
            subjectName = "Biology"
        }

        return subjectName;
    }
    getClassName = (classid) => {
        let className = "";
        if (classid == "1") {
            className = "XI";
        } else if (classid == "2") {
            className = "XII";
        }
        return className;
    }

    getblocks = (indexVal) => {
        this.setState({ onloadSubjectWiseChapters: 1 });
        if (this.state.expandval != indexVal.toString()) {
            this.setState({ expandval: indexVal })
        }
        else {
            this.setState({ expandval: "" })
        }

    }
    handleResultFunction = (id) => {

        this.props.history.push({
            pathname: "/student/subject/exam-result",
            state: {
                sessionid: id,
                examtype: "eamcet_practice",
                //mocktype: "history"

            }
        })
    }

    modalaFun = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }


    startExam = (data) => {

        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        localStorage.setItem("resumeExam", false);
        localStorage.setItem("subexamtype", this.state.subtype);
        localStorage.setItem("examtype_pattern", "eamcet_practice");

        if (isMobile == true && Cookies.get("institution_id") == 30) {
            this.props.history.push({
                pathname: "/student/subject/exam",
            })
        } else {
            window.open("/student/subject/exam", "_blank");
        }

    }

    resumeExam = (data) => {
        console.log("this.state.subtype", data.exam_name, data.exam_name);

        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        localStorage.setItem("subexamtype", this.state.subtype);
        localStorage.setItem("examtype_pattern", "eamcet_practice");
        localStorage.setItem("resumeExam", true);

        if (isMobile == true && Cookies.get("institution_id") == 30) {
            this.props.history.push({
                pathname: "/student/subject/exam",
            })
        } else {
            window.open("/student/subject/exam", "_blank");
        }
    }

    startLearning=(data)=>{
        this.props.history.push({
            pathname: "/student/chapter-wise-learning",
            state: {
                chapterData:data,
                clickType:"practise_sessions",
                Ipe_chapter_id:data.chapter_id

            }
        })
    }

    handleSubscribeModal = () => {
        this.setState({
            userRestionModalShow: true,
        })

    }

    render() {
        console.log("getEamcetPracticeTests", this.props.getStateChapterNames);
        let currentTime_currentSec = Math.round((new Date()).getTime() / 1000);
        let indexVal = [];
        // if (this.state.onloadSubjectWiseChapters == 0) {
        //     if (this.props.getEamcetPracticeTests != undefined) {
        //         const getTestsArr = this.props.getEamcetPracticeTests;
        //         getTestsArr.filter(item => item.class_id == parseInt(this.state.classValue) && item.subject_id == parseInt(this.state.selectedSubjectVal))
        //             .map(item2 => {
        //                 this.state.getEamcetPracticeTestsArr.push(item2);
        //             });
        //     }
        // }

        let ChaptersArray=[]

        if(this.props.getStateChapterNames != undefined){

            ChaptersArray = this.props.getStateChapterNames.filter(item => item.id == this.state.selectedSubjectVal)

            if(this.state.classValue == 1){
                ChaptersArray = ChaptersArray[0]?.state_chapters.filter(item => item.class_id == 1)
            }else{
                ChaptersArray = ChaptersArray[0]?.state_chapters.filter(item => item.class_id == 2)
            }
        }

        return (
            <Container className={isMobile == true ? 'eamcet-practise-test mt-5' : 'eamcet-practise-test mt-2'}>
                <Row>
                    <Col sm={12}>
                        <div className='subject-blocks-view'>
                            {
                                this.state.getSubjectsArr.map(sub =>
                                    <div className={this.subjectClassName(sub)} onClick={() => this.handleSelect(sub.id)}>
                                        <Row>
                                            <Col sm={8}>
                                                <h5 className='mt-2'>{sub.subject}</h5>
                                            </Col>
                                            <Col sm={4}>
                                                {
                                                    sub.id == "1" ?
                                                        (<Card.Img variant="top" src={Botany} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "2" ?
                                                        (<Card.Img variant="top" src={Physics} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "3" ?
                                                        (<Card.Img variant="top" src={Chemistry} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "5" ?
                                                        (<Card.Img variant="top" src={Zoology} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "4" ?
                                                        (<Card.Img variant="top" src={Maths} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == 6 ?
                                                        (<Card.Img variant="top" src={Botany} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                            </Col>
                                        </Row>

                                    </div>
                                )
                            }

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <ul className='list-inline class_type_li mt-4'>
                            <li className={this.state.classValue == "1" ? "active" : ""} onClick={() => this.handleSelectClassVal("1")}>Class XI</li>
                            <li className={this.state.classValue == "2" ? "active ml-4" : "ml-4"} onClick={() => this.handleSelectClassVal("2")}>Class XII</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className='mt-4'>
                        <h5>Chapter Wise Practise Sessions - ( Subject : {this.getSubjectName(this.state.selectedSubjectVal)},Class : {this.getClassName(this.state.classValue)} )</h5>
                        <hr />
                    </Col>
                </Row>

                {/* <Row>
                    {
                        this.state.getEamcetPracticeTestsArr.map((chap, index) =>
                            <Col sm={parseInt(this.state.expandval) == parseInt(index) ? 12 : 6} className={parseInt(this.state.expandval) == parseInt(index) ? "active_expand" : ""} id={`chapterId${index}`}>
                                <div className={parseInt(this.state.expandval) == parseInt(index) ? "chap_block_view mt-3 active" : "chap_block_view mt-3"} onClick={(e) => this.getblocks(index)}>
                                    <Row>
                                        <Col sm={11}>
                                            <p>{index + 1}<span>.</span><span className='ml-2'>{chap.chapter_name}</span></p>
                                        </Col>
                                        <Col sm={1}>
                                            {parseInt(this.state.expandval) == parseInt(index) ?
                                                <i className="fas fa-chevron-up float-right res_arrow"></i>
                                                : <i className="fas fa-chevron-down float-right res_arrow"></i>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                <div className={parseInt(this.state.expandval) == parseInt(index) ? "showblock" : "hideBlock"}>
                                    <div className='block_white_pad mt-3'>
                                        <Row>

                                            {
                                                chap.exam_paper_one.length > 0 ?
                                                    (
                                                        chap.exam_paper_one.map(item =>
                                                            <Col sm={4} className='mt-2'>
                                                                <Card className='border_pad_card'>
                                                                    <Card.Body className='p-2'>
                                                                        <h6 className='mt-2 mb-3'>{item.exam_name}
                                                                            {
                                                                                item.complexity_id == 1 ?
                                                                                    (<span className='easy_cls float-right'>Level-1</span>)
                                                                                    :
                                                                                    (
                                                                                        <>
                                                                                            {
                                                                                                item.complexity_id == 2 ?
                                                                                                    (<span className='modarate_cls float-right'>Level-2</span>)
                                                                                                    :
                                                                                                    (
                                                                                                        <>
                                                                                                            {
                                                                                                                item.complexity_id == 3 ?
                                                                                                                    (<span className='difficult_cls float-right'>Level-3</span>)
                                                                                                                    :
                                                                                                                    ("")
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                            }
                                                                        </h6>
                                                                        <p className='mt-2 mb-1'>Duration : <b>{item.exam_duration_min + " Min"}</b>

                                                                            {
                                                                                item.is_completed == true ? (
                                                                                    <Button type="button" className='btn-custom-blue float-right f-14' onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results</Button>

                                                                                ) : (
                                                                                    item.enabled == false ? (
                                                                                        item.is_started == true && currentTime_currentSec > item.start_time ? (
                                                                                            <Button type="button" className='btn-custom-blue float-right f-14' onClick={() => this.resumeExam(item)}>Resume Practice</Button>
                                                                                        ) : (
                                                                                            <Button type="button" className='btn-custom-blue float-right f-14' onClick={() => this.startExam(item)}>Start Practice</Button>
                                                                                        )
                                                                                    ) : (
                                                                                        <Button type="button" className='btn-custom-blue float-right f-14' onClick={() => this.modalaFun()}>Start Test</Button>

                                                                                    )

                                                                                )
                                                                            }
                                                                        </p>


                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>


                                                        )
                                                    )
                                                    :
                                                    (<Col sm={12}><h6 className='text-center mt-2'>Coming Soon...</h6> </Col>)
                                            }
                                        </Row>
                                    </div>
                                </div>

                            </Col>
                        )
                    }
                </Row> */}

                <Row>
                    {
                        ChaptersArray != "" && ChaptersArray != undefined && ChaptersArray.map((item, index) => {
                            return (
                                item.practiceEnabled == false ?
                                    (
                                        <Col sm={12} lg={6}>
                                            <Card className='chapter_card mt-4 cursor_pointer' onClick={() => this.startLearning(item)}>
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={10}>
                                                            <h6 className='chapter_name mt-2'><span className='mr-2'>{index + 1}.</span>{item.chapter_name}</h6>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <img src={Arrow} className='arrow pos_sn_aligntment' />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                    :
                                    (
                                        <Col sm={12} lg={6}>
                                            <Card className='chapter_card mt-4 cursor_pointer' onClick={() => this.handleSubscribeModal()}>
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={10}>
                                                            <h6 className='chapter_name mt-2'><span className='mr-2'>{index + 1}.</span>{item.chapter_name}</h6>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <img src={Arrow} className='arrow pos_sn_aligntment' />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>

                                        </Col>
                                    )
                            )
                        })
                    }
                </Row>


                <EamcetRestrictionAlert
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />

            </Container>
        )
    }
}
export default withRouter(EamcetPractiseTestSection);