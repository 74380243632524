import React, { Component } from 'react'
import { Row, Col,Button, Container} from 'react-bootstrap';
import * as Cookies from "es-cookie";
import { components } from 'react-select';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import '../_collegeRankPredictor.scss';


class excelContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }


    render() {
      console.log("stateDatastateData",this.props.stateData)

        return (
          <>
          <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="btn btn-blue-color mt-3 mb-4 center-auto btn btn-primary"
              table="table-to-xls"
              filename="EAMCET_Priority_List"
              sheet="EAMCET_Priority_List"
              buttonText="DOWNLOAD LIST"
            />
            <table id="table-to-xls" style={{ display: "none" }}>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "rgb(64, 131, 225)",color:"#fff",fontSize:"22px" }}>SNO</th>
                  <th style={{ backgroundColor: "rgb(64, 131, 225)",color:"#fff",fontSize:"22px" }}>COLLEGE CODE</th>
                  <th style={{ backgroundColor: "rgb(64, 131, 225)",color:"#fff",width:"500px",fontSize:"22px" }}>COLLEGE NAME</th>
                  <th style={{ backgroundColor: "rgb(64, 131, 225)",color:"#fff",fontSize:"22px" }}>BRANCH CODE</th>
                  <th style={{ backgroundColor: "rgb(64, 131, 225)",color:"#fff",width:"500px",fontSize:"22px" }}>BRANCH NAME</th>
                </tr>
              </thead>
              <tbody>
                  {
                    this.props.stateData.orderPriorityDetails.map(item => {
                      return(
                        <tr>
                          <td style={{ textAlign: "center",fontSize:"18px",fontWeight:"bold" }}>{item.user_order}</td>
                          <td style={{ textAlign: "center",fontSize:"18px",fontWeight:"bold" }}>{item.college_code}</td>
                          <td style={{ textAlign: "center",fontSize:"18px",fontWeight:"bold" }}>{item.college_name}</td>
                          <td style={{ textAlign: "center",fontSize:"18px",fontWeight:"bold" }}>{item.branch_name.split("-")[0]} </td>
                          <td style={{ textAlign: "center",fontSize:"18px",fontWeight:"bold"}}>{item.branch_name}</td>
                      </tr>
                      )
                    })
                  }
              </tbody>
            </table>
            </>
        )
    }
}



export default excelContainer



 