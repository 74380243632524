import { Modal, Row, Col, Card,ListGroup } from 'react-bootstrap'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './_crashCourseExams.scss';

 class ViewSyllabus extends Component {

    render() {
        console.log("this.props.Data", this.props.SyllabusDetails);
        return (
            
            <Modal {...this.props}
                size="lg" aria-labelledby="example-modal-sizes-title-lg" className="view_syllabus">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg" className="f-20">Syllabus Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.props.SyllabusDetails.syllabus_desc?.length > 0 ?
                            (
                                this.props.SyllabusDetails.syllabus_desc?.map((item, index) => {
                                    return (
                                        <>
                                            <Row>
                                                <Col>

                                                    <h5 className="text-center">{item.NAME}</h5>
                                                </Col>
                                            </Row>


                                            <Row>
                                                    {
                                                        item.syllabus?.map((item1, index) => {
                                                            return (

                                                                <Col sm={6} className="mt-3">
                                                                    <Card className="border-blue">
                                                                        <Card.Header className="text-center font-weight-bold">{item1.subject}</Card.Header>
                                                                        <ListGroup variant="flush">
                                                                            {
                                                                                item1.Chapters?.map((chapter, index) =>
                                                                                    <ListGroup.Item>{chapter}</ListGroup.Item>
                                                                                )
                                                                            }
                                                                        </ListGroup>
                                                                    </Card>
                                                                </Col>

                                                            )
                                                        }
                                                        )
                                                    }
                                            </Row>
                                        </>
                                    )
                                }
                                ))
                            :
                            (<Row>
                                <Col sm={12} className="mt-3">Data Not Available...</Col>
                            </Row>)
                    }
                </Modal.Body>
            </Modal>
        )
    }
}

export default ViewSyllabus
