import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_liveClassess.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import LiveClassesModal from '../liveClasses/LiveClassesModal';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';
import FileText from "../../../images/images1/FileText.png";
import FileArrowDown from "../../../images/images1/FileArrowDown.png";
import UserRestrictionAlertInstitute from '../home/UserRestrictionAlertInstitute';
import LockSimple from "../../../images/LockSimple.png";
import * as Cookies from "es-cookie";
import moment from 'moment';

let QuestionDataEngg = [
    {
        DATE: "02-07-2021",
        ClassTimings: "3 PM to 4 PM",
        time: "15",
        Subject: "Physics",
        Chapter: "Gravitation",

        Assignments: "",
        Solutions: "",
        VideoLecture: "d94Gqco3xIw",
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "3 PM to 4 PM",
        time: "15",
        Subject: "Physics",
        Chapter: "General Physics - Briefing",

        Assignments: "",
        Solutions: "",
        VideoLecture: ("BwxXbySoCxU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "3 PM to 4 PM",
        time: "15",
        Subject: "Physics",
        Chapter: "Introduction to Mathematical physics ",

        Assignments: "",
        Solutions: "",
        VideoLecture: ("mGS3_K_ulXk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "3 PM to 4 PM",
        time: "15",
        Subject: "Physics",
        Chapter: "Application of physics",

        Assignments: "",
        Solutions: "",
        VideoLecture: ("-wrvGzsX6sc"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Motion in straight line",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_5062_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_1071_Sol.pdf"),
        VideoLecture: ("TZtL4BKRbf8"),
        Live: (""),
        Zoom: (""),
    },

    {
        DATE: "08-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Motion in plane",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_1517_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_7134_Sol.pdf"),
        VideoLecture: ("CdOLviEiGTQ"),
        Live: (""),
        Zoom: (""),
    },

    {
        DATE: "09-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Units and Measurement",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_9761_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_7444_Sol.pdf"),
        VideoLecture: ("5qsbgFx0F60"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "10-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Laws of motion",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_7803_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_9966_Sol.pdf"),
        VideoLecture: ("g9KbwnXTpeQ"),
        Live: (""),
        Zoom: ("")
    },

    {
        DATE: "12-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Work ,energy and power",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_92_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_405_Sol.pdf"),
        VideoLecture: ("I7yqOkE0umI"),
        Live: (""),
        Zoom: (""),
    },

    {
        DATE: "13-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Gravitation",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_7925_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_3001_Sol.pdf"),
        VideoLecture: ("QxF1nubi42g"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Oscillation",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_549_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_5042_Sol.pdf"),
        VideoLecture: ("gvNS7pAym9Q"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "System of particle and rotational motion",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_3117_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_151_Sol.pdf"),
        VideoLecture: ("Ke-hg7ZVgcs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Mechnical properties of solids",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_8184_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_1519_Sol.pdf"),
        VideoLecture: ("AyUhXSedki8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Mechnical properties of fluids",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_3515_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_8288_Sol.pdf"),
        VideoLecture: ("32bMPRNRnaA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Thermal properties of matter",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_3355_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_4048_Sol.pdf"),
        VideoLecture: ("2PQKEtTOxlI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Thermodynamics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_5953_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_7404_Sol.pdf"),
        VideoLecture: ("5FkQfAvjjxs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Kinetic theory",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_3045_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_2146_Sol.pdf"),
        VideoLecture: ("wBp04FDjQmY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Waves",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_6192_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_6478_Sol.pdf"),
        VideoLecture: ("MraOSPOJcuQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Ray optics and optical instruments",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_5652_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_1295_Sol.pdf"),
        VideoLecture: ("2HKLOKGpDIQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Wave optics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_7009_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_1576_Sol.pdf"),
        VideoLecture: ("o7Ll71wQZ74"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Electric charges and field",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_5848_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_3898_Sol.pdf"),
        VideoLecture: ("ETolepm3MzM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Electro static potential and capacitance",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_2424_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_1321_Sol.pdf"),
        VideoLecture: ("2_NJ2dyyWDQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Current electricity-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_2013_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_2412_Sol.pdf"),
        VideoLecture: ("QOo-V06AcDQ"),
        Live: (""),
        Zoom: (""),

    },
    {
        DATE: "29-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Current electricity-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_6403_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_1111_Sol.pdf"),
        VideoLecture: ("QcHOOsVWJKQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Moving charges and magnetism,magnetism and matter",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_771_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_6301_Sol.pdf"),
        VideoLecture: ("R-rH6HQi_lI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Semiconductor electronics :materials,devices and simple circuit-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_2207_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_3557_Sol.pdf"),
        VideoLecture: ("ziEBjM4h0Gw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Semiconductor electronics:materials,devices and simple circuits-II,Communication system-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_4874_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_3719_Sol.pdf"),
        VideoLecture: ("HI0su0QKNSE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Dual nature of matter and radiation",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_8261_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_1362_Sol.pdf"),
        VideoLecture: ("_xpGSTgz_pA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Electromagnetic induction, E.M waves",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_9369_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_6048_Sol.pdf"),
        VideoLecture: ("tgMEuiSJ4rA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Alternating current",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_6562_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_7738_Sol.pdf"),
        VideoLecture: ("60pEof3Scuw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Physics",
        Chapter: "Atoms - Nuclie",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_5001_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_2787_Sol.pdf"),
        VideoLecture: ("FEeLhFWozxE"),
        Live: (""),
        Zoom: (""),
    },
];
//// physicsagricultue
let QuestionDataAgri = [
    {
        DATE: "02-07-2021",
        ClassTimings: "3 PM to 4 PM",
        time: "15",
        Subject: "Physics",
        Chapter: "Gravitation",

        Assignments: "",
        Solutions: "",
        VideoLecture: "d94Gqco3xIw",
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "3 PM to 4 PM",
        time: "15",
        Subject: "Physics",
        Chapter: "General Physics-Briefing",

        Assignments: "",
        Solutions: "",
        VideoLecture: ("BwxXbySoCxU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "3 PM to 4 PM",
        time: "15",
        Subject: "Physics",
        Chapter: "Introduction to Mathematical physics ",

        Assignments: "",
        Solutions: "",
        VideoLecture: ("mGS3_K_ulXk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "3 PM to 4 PM",
        time: "15",
        Subject: "Physics",
        Chapter: "Application of physics",

        Assignments: "",
        Solutions: "",
        VideoLecture: ("-wrvGzsX6sc"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "2:30 PM to 4.00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Motion in straight line",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_1602_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_6195_Sol.pdf"),
        VideoLecture: ("gMknL-7boz0"),
        Live: (""),
        Zoom: (""),

    },
    {
        DATE: "08-07-2021",
        ClassTimings: "2:30 PM to 4.00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Motion in plane",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_4804_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_6954_Sol.pdf"),
        VideoLecture: ("j8jIFhtC7uU"),
        Live: (""),
        Zoom: (""),

    },
    {
        DATE: "09-07-2021",
        ClassTimings: "2:30 PM to 4.00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Units and Measurements",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_7913_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_6575_Sol.pdf"),
        VideoLecture: ("N6QDAQ_kL5Q"),
        Live: (""),
        Zoom: (""),

    },
    {
        DATE: "10-07-2021",
        ClassTimings: "2:30 PM to 4.00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Laws of motion",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_8324_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_5082_Sol.pdf"),
        VideoLecture: ("nOsxE5VOR9Q"),
        Live: (""),
        Zoom: (""),

    },
    {
        DATE: "12-07-2021",
        ClassTimings: "2:30 PM to 4.00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Work ,energy and power",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_6383_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_1581_Sol.pdf"),
        VideoLecture: ("c_RTp-QdQa0"),
        Live: (""),
        Zoom: (""),

    },
    {
        DATE: "13-07-2021",
        ClassTimings: "2:30 PM to 4.00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Gravitation",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_8173_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_6386_Sol.pdf"),
        VideoLecture: ("nwdbtUkfnGw"),
        Live: (""),
        Zoom: (""),

    },
    {
        DATE: "14-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Oscillation",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_5625_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_6812_Sol.pdf"),
        VideoLecture: ("sDRgsonecYw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "System of particle and rotational motion",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_258_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_169_Sol.pdf"),
        VideoLecture: ("itez-dp2J30"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Mechnical properties of solids",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_7269_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_6638_Sol.pdf"),
        VideoLecture: ("2o5aBiErFfY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Mechnical properties of fluids",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_2556_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_1242_Sol.pdf"),
        VideoLecture: ("YoWml-PH-7c"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Thermal properties of matter",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_281_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_7232_Sol.pdf"),
        VideoLecture: ("NSUQjt3oUiU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Thermodynamics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_809_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_3401_Sol.pdf"),
        VideoLecture: ("KPGAoXSRGUI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Kinetic theory",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_3915_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_7628_Sol.pdf"),
        VideoLecture: ("3X1g7azB4LM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Waves",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_5331_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_5169_Sol.pdf"),
        VideoLecture: ("pDZpz6aWBdQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Ray optics and optical instruments",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_1573_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_3516_Sol.pdf"),
        VideoLecture: ("eiRzqMh_UA0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Wave optics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_6862_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_3031_Sol.pdf"),
        VideoLecture: ("pX1HwqwPWNE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Electric charges and field",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_1958_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_5048_Sol.pdf"),
        VideoLecture: ("yF17fScceYo"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Electro static potential and capacitance",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_9599_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_1447_Sol.pdf"),
        VideoLecture: ("TwIiKT7SAvs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Current electricity-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_6228_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_3468_Sol.pdf"),
        VideoLecture: ("84Hr55y6PWI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Current electricity-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_5453_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_7619_Sol.pdf"),
        VideoLecture: ("pU3MpNuVXvY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Moving charges and magnetism,magnetism and matter",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_5531_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_4516_Sol.pdf"),
        VideoLecture: ("-1cVeu3lpmM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Semiconductor electronics :materials,devices and simple circuit-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_5817_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_8876_Sol.pdf"),
        VideoLecture: ("7QVESRzUiXY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Semiconductor electronics:materials,devices and simple circuits-II,Communication system-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_685_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_398_Sol.pdf"),
        VideoLecture: ("WbDwqm7NMVM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Dual nature of matter and radiation",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_3684_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_4222_Sol.pdf"),
        VideoLecture: ("Mup3k4puxZU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Electromagnetic induction, E.M waves",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_9566_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_3481_Sol.pdf"),
        VideoLecture: ("MDYRILnN5hs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Alternating current",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_5679_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_5019_Sol.pdf"),
        VideoLecture: ("IEjgcYhfyzA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Physics",
        Chapter: "Atoms - Nuclie",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_5358_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_2736_Sol.pdf"),
        VideoLecture: ("vN_vMtSHJgc"),
        Live: (""),
        Zoom: (""),
    },

];
let QuestionData = []

if (Cookies.get("examid") == 7 || Cookies.get("examid") == 8) {

    QuestionData.push(...QuestionDataAgri)

} else {
    QuestionData.push(...QuestionDataEngg)
}

let LiveClassesDataPhysics = []

for (var i in QuestionData) {
    let currentDate = moment().format("DD/MM/YYYY");
    if ((QuestionData[i]["VideoLecture"] || QuestionData[i]["Live"] || QuestionData[i]["Zoom"]) && (currentDate == moment(QuestionData[i].DATE, "DD-MM-YYYY").format("DD/MM/YYYY"))) {
        LiveClassesDataPhysics.push(QuestionData[i]);
    }
}

var Zoom;
var Livevideo;


class PhysicsDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            dataLiveUrl: "",
            userRestionModalShow: false,
            allLiveClasses: true
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }
    modalFunSubscribed = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7 && Cookies.get("institution_id") == 30 && this.isStudentUserValid.current_plan_id == "23") {
            this.setState({
                userRestionModalShow: true,
            });
        } else {
            this.setState({
                liveModalShow: true,
                dataLiveUrl: row.Live

            });
        }



    }
    checkForDate = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7) {
            return true
        }
        return false
    }

    modalaFun = (row) => {
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });

    }
    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    sortFuncDate(a, b, order, dataField, rowA, rowB) {
        if (order == 'desc') {

            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }
    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }

    actionFormatterAssignments(cell, row, rowIndex) {
        return (
            <>
                {
                    row.Assignments != "" ? <div className="Assignments">
                        <img src={FileText} alt="file-text-img" ></img>
                        <a href={row.Assignments} target="_blank"> Assignment Download</a>
                    </div> : <h6 className="text-center">-</h6>
                }

                {
                    row.Solutions != "" ? <div className="Solutions">
                        <img src={FileArrowDown} alt="File-Arrowdown-img"></img>
                        <a href={row.Solutions} target="_blank" > Solutions</a>
                    </div> : ""
                }
            </>

        )
    }

    actionFormatterVideoLecture(cell, row, rowIndex) {

        return (
            <>
                <div className="allsubjects">
                    {
                        row.Live == "" ?

                            row.VideoLecture != "" ?
                                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                : "-"


                            :
                            <>

                                {
                                    (this.checkForDate(row) && Cookies.get("institution_id") == 30 && this.isStudentUserValid.current_plan_id == "23") ? (<a onClick={() => this.modalFunSubscribed(row)}><button type="button" className="btn btn-success Zoom">Zoom <img src={LockSimple} className="btn_lock_live_zoom_Classes" /></button> </a>) : (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-success zoom_effect effect-1">Zoom</button></a>)
                                }
                                {
                                    (this.checkForDate(row) && Cookies.get("institution_id") == 30 && this.isStudentUserValid.current_plan_id == "23") ? (<a onClick={() => this.modalFunSubscribed(row)}><button type="button" className="btn btn-warning live">Live <img src={LockSimple} className="btn_lock_live_zoom_Classes" /></button> </a>) : (<button type="button" className="btn btn-warning live live_effect effect-1" onClick={() => this.modalaFun(row.Live)}>Live</button>)
                                }



                            </>
                    }

                </div>

            </>
        )
    }



    colFunction() {

        let columns = [
            // {
            //     dataField: "DATE",
            //     text: "Date",
            //     sort: true,
            //     headerStyle: {
            //         width: "13%"
            //     },
            //     style: {
            //         fontWeight: "bold !important"
            //     },
            //     sortFunc: this.sortFuncDate.bind(this)
            // },
            // {
            //     dataField: "ClassTimings",
            //     text: "Class Timings",
            //     sort: true,
            //     headerStyle: {
            //         width: "23%"
            //     },
            //     sortFunc: this.sortFuncMulti.bind(this)
            // },

            {
                dataField: "Subject",
                text: "Subject",
                style: (row, rowIndex) => {
                    return { fontWeight: "bold !important" };
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                    width: "35%"
                }
            },
            {
                dataField: "Assignments/Solution",
                text: "Assignment/Solution",
                headerStyle: {
                    width: "55%"
                },

                formatter: this.actionFormatterAssignments.bind(this)
            },
            // {
            //     dataField: "Solutions",
            //     text: "Solutions",
            //     formatter: this.actionFormatterSolutions
            // },
            {
                dataField: "Videos/Recorded/Live",
                text: "Videos(Recorded/Live)",
                formatter: this.actionFormatterVideoLecture.bind(this)
            },
            // {
            //     dataField: "Live/Zoom",
            //     text: "Live/Zoom",
            //     formatter: this.actionFormatterLive
            // },
        ];
        return columns;
    }

    render() {
        return (
            <Row>
                <Col align="right" sm={12}>
                    <h6 className="switch_button_cls mt-res-4">
                        <p className="allClassesAlignment">All Classes</p>
                        <label class="switch">
                            <input type="checkbox"
                                onChange={() => {

                                    this.setState((state) => {

                                        return {
                                            allLiveClasses: !state.allLiveClasses
                                        }
                                    })
                                }}
                                checked={!this.state.allLiveClasses} />
                            <span class="slider round"></span>
                        </label>
                        <p className="liveClassesAlignment">Live Classes</p>
                    </h6>
                </Col>
                <Col sm={12}>
                    {
                        this.state.allLiveClasses ?
                            <CardLessDataTableWithSearch
                                parentData={QuestionData}
                                particlecolumns={this.colFunction()}
                                defaultSorted={[{
                                    dataField: "ClassTimings",
                                    order: "asc"
                                }]}
                            />
                            :
                            <CardLessDataTableWithSearch
                                parentData={LiveClassesDataPhysics}
                                particlecolumns={this.colFunction()}
                                defaultSorted={[{
                                    dataField: "ClassTimings",
                                    order: "asc"
                                }]}
                            />
                    }
                    {/* <Card>
                        <BootstrapTable
                            keyField="id"
                            columns={this.colFunction()}
                            data={QuestionData}
                            defaultSorted={[{
                                dataField: "ClassTimings",
                                order: "asc"
                            }]}
                            wrapperClasses="table-responsive"
                            bootstrap4

                        />
                    </Card> */}

                    <LiveClassesModal data={this.state.dataLiveUrl} show={this.state.liveModalShow} onHide={() => this.setState({ liveModalShow: false })} />
                    <UserRestrictionAlertInstitute
                        show={this.state.userRestionModalShow}
                        onHide={() => this.setState({ userRestionModalShow: false })} />
                </Col>
            </Row>

        )
    }

}
export default PhysicsDataSection