import React, { Component } from 'react'
import { Row, Col, Table, Image, Button, Container, Accordion, Card, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
// import './_collegeRankPredictor.scss';
import CardLessDataTableWithOutSearch from "../../../neetjee_guru/components/datatables/CardLessDataTableWithOutSearch"



class CollegeAllocatedSeatsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    colFunction() {

        let columns = [
            {
                dataField: "gender",
                text: "Gender",
                headerStyle: {
                    background: "#E9ECF1", color: "#0B3573"
                },
            },
            {
                dataField: "region",
                text: "Region",
                headerStyle: {
                    background: "#E9ECF1", color: "#0B3573", textAlign: 'center'
                },

            },
            {
                dataField: "rank",
                text: "Rank",
                headerStyle: {
                    background: "#E9ECF1", color: "#0B3573", textAlign: 'center'
                },
                sort: true

            },
        ];
        return columns;

    }

    render() {
        return (
            <Modal {...this.props}
                size="lg" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Container>
                            <Row>
                                <Col>
                                    <h5>Details of Allocated seats</h5>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            {
                                !this.props.stateData.length > 0 ? (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <div className="spinner-border text-primary loading-img-center" >
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                ) : (
                                    <CardLessDataTableWithOutSearch
                                        parentData={this.props.stateData}
                                        particlecolumns={this.colFunction()}
                                        defaultSorted={[{
                                            dataField: "rank",
                                            order: "asc"
                                        }]}
                                    />
                                )
                            }

                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(compose(

)(CollegeAllocatedSeatsModal));