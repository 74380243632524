import React, { Component } from 'react'
import { Row, Col, Card, Image, Button,Media } from 'react-bootstrap'
import './_eamcetMockTests.scss';
import ViewSyllabusEamcet from './ViewSyllabusEamcet';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter, Link } from "react-router-dom";
import * as Cookies from "es-cookie";
import PreloaderTwo from '../preloader/PreloaderTwo';
import UserRestrictionAlert from "../home/UserRestrictionAlert";
import moment from 'moment';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';

const MOCKTEST_REGISTER = gql`
  mutation($mobile: String!,
    $exam_id: Int!) {
    registerForExam(mobile: $mobile,exam_id: $exam_id)
  }
`;



class EamcetMockTestHistoryFree extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            complateMackTest: false,
            userRestionModalShow: false,
            exam_name: "",
            currentTime: moment().unix(),
            viewSyllabusdetails: "",
            viewSyllabusModal: false,


        };
    }
    componentDidMount() {
        const title = GoogleAnalyticsArray[0].Eamcet_Mocktests_History;
        ReactGA.pageview('/student/Mocktests',["rizee"], title);
        
        let Schedule = this.props.getStudentTestSeries.filter((a) => a.is_scheduled == "1" && a.exam_expired == false && a.exam_session_id == 0);
        let islive = Schedule.find((a) => a.exam_started == true && a.is_completed == false);

        if (islive != undefined) {
            const end_time = islive.end_time;
            const currentTime = this.state.currentTime;
            //console.log("comislive", end_time, currentTime);
            this.timer = setInterval(() => {
                this.setState({ currentTime: moment().unix() });

                if (currentTime >= end_time) {
                    console.log("comislive", end_time, currentTime);
                    clearInterval(this.timer);
                }

            }, 60000);
        }



    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }
    modalaFun = (exam_name) => {
        this.setState({
            userRestionModalShow: true,
            exam_name: exam_name
        });
    }

    startExam = (data) => {
        //console.log("this.state.subtype", id, this.state.subtype);
        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", "Schedule Exam");
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        //localStorage.setItem("subexamtype", this.state.subtype);


        if(isMobile == true && Cookies.get("institution_id") == 30){
            this.props.history.push({
                pathname: "/student/subject/series_test",
            })
        }else{
            window.open("/student/subject/series_test", "_blank");
        }


        // this.props.history.push({
        //     pathname: "/student/subject/series_test",

        // })
        /*this.props.history.push({
            pathname: "/student/home",

        })*/

    }

    handleResultFunction = (id) => {
        this.props.history.push({
            pathname: "/student/subject/mock-exam-result",
            state: {
                sessionid: id,
                examtype: "eamcet_exam",
                mocktype: "history"
            }
        })
    }

    graphValue(getseries) {
        let data = "";
        if (getseries != undefined) {
            data = parseInt(getseries.correct_marks) - parseInt(getseries.negative_marks);
        }
        //console.log("graphValue123", data);
        return data;

    }
    paymentFun = (data) => {
        this.props.history.push({
            pathname: "/student/moock-test-order-summary",
            state: {
                data: data

            }
        })

    }

    register = async (e, id) => {

        e.preventDefault();
        await this.props.register({
            variables: {
                mobile: Cookies.get("mobile"),
                exam_id: parseInt(id)
            },
            update: (store, { data }) => {
                const getdata = this.props.getStudentTestSeries.map((item) => {
                    if (item.id == id) {
                        console.log("true", item.id, id);
                        return { ...item, is_registered: true }
                    }
                    return { ...item }
                })
                console.log("store", getdata);
                this.props.getStudentTestSeries = getdata;

                if (data.registerForExam) {
                    this.setState({
                        regstatus: 2
                    })
                    setTimeout(() => {
                        this.RegisterSetpageLoad();
                    }, 1000);
                }
            }
        });
    };
    RegisterSetpageLoad = () => {

        this.setState({ regstatus: 1 });
    };

    handleShowModal = (data) => {
        console.log("dataaa", data)
        this.setState({
            viewSyllabusModal: true,
            viewSyllabusDetails: data
        });
    }

    render() {
        console.log("getStudentTestSeries",this.props.getStudentTestSeries)

        let freeSchedule = this.props.getStudentTestSeries.filter((a) => a.exam_expired == true || a.is_completed == true);
        // let Schedule = this.state.getStudentTestSeries.filter((a) => a.is_scheduled == "1" && a.exam_expired == false && a.exam_session_id == 0);
        //let islive = Schedule.find((a) => a.exam_started == true && a.is_completed == false);
         freeSchedule=freeSchedule.sort(function(a, b){ 
      
            return  b.start_time - a.start_time; 
        });
        return (
           <Row>
               {
                    freeSchedule.length > 0 ?
                    (
                        freeSchedule.map((item) => {
                            return (
                                <Col sm={6}>
                                    <Card className="card-border-exams mt-3">
                                        <Card.Body>
                                        <Media>
                                            <div className="date_block mr-3 text-center">
                                                <p className="mt-1 f-22 border-bootom2"> {moment.unix(item.start_time).format("MMM")}</p>
                                                <p className="mt-2 f-22">{moment.unix(item.start_time).format("DD")}</p>
                                            </div>       
                                                <Media.Body>
                                                    <Card.Title>
                                                        <h6 className="f-14">{item.exam_name} 
                                                        
                                                    </h6>
                                                    </Card.Title>
                                                    
                                                    <Row>
                                                        <Col sm={12}>
                                                            <p className="f-14">Time : {moment.unix(item.start_time).format("LT")} to {moment.unix(item.end_time).format("LT")}</p>
                                                            <p className="f-14"> Duration : {item.exam_duration}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} className="mt-2">
                                                            <Button type="button" className="btn btn-blue-exam" onClick={() => this.handleShowModal(item)}>View Syllabus</Button>
                                                        </Col>
                                                        <Col sm={6} className="mt-2">

                                                            {
                                                                item.exam_expired == true && item.is_completed == false ?
                                                                (
                                                                    <h6 className="red_color float-right-resp" >Exam Expired </h6>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                    {
                                                                        item.is_completed == true ?
                                                                        (
                                                                            <Button type="button" className="btn btn-blue-exam float-right-resp" onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results </Button>
                                                                        )
                                                                        :
                                                                        (
                                                                            <Button className='btn-custom-Red' disabled>Exam Expired</Button>
                                                                            
                                                                        )
                                                                    }
                                                                    </>
                                                                    
                                                                )

                                                                
                                                            }
                                                            
                                                        </Col>
                                                    </Row>
                                                    </Media.Body>
                                                </Media>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    )
                    :
                    (<Col sm={12} className="text-center"><h6>Data Not Available</h6></Col>)
               }
               <ViewSyllabusEamcet show={this.state.viewSyllabusModal} syllabusDetails={this.state.viewSyllabusDetails} onHide={() => this.setState({ viewSyllabusModal: false })} />
           </Row>
        )


    }
}
export default withRouter(compose(

    graphql(MOCKTEST_REGISTER, {
        name: "register"
    })
)(EamcetMockTestHistoryFree));
