import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_liveClassess.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import LiveClassesModal from '../liveClasses/LiveClassesModal';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';

import FileText from "../../../images/images1/FileText.png";
import FileArrowDown from "../../../images/images1/FileArrowDown.png";
//import Group3172 from "../../../images/images1/Group3172.png";

import UserRestrictionAlertInstitute from '../home/UserRestrictionAlertInstitute';
import LockSimple from "../../../images/LockSimple.png";
import * as Cookies from "es-cookie";
import moment from 'moment';

let QuestionData = [
    {
        DATE: "02-07-2021",
        ClassTimings: "12 PM to 1 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Animal Tissues",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("s4Sc4KXOxY8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "12 PM to 1 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Introduction to Zoology-Branches and Tips and Tricks to Score above 100Marks",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("Ooyg1f10CA8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "12 PM to 1 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Animal kingdom classification - First year  units Briefing",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("o3vqnA1D7dM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "12 PM to 1 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Second Year Topics - Briefing | EAMCET | Zoology | Agriculture |",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("5FZ-UFyVXnE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Diversity of Living World-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_4485_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_2757_Sol.pdf"),
        VideoLecture: ("Jmu8WdPBHbk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "08-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Diversity of Living World-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_3849_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_7663_Sol.pdf"),
        VideoLecture: ("P6hYc0CVLEc"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "09-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Structural organization in animals-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_8876_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_7369_Sol.pdf"),
        VideoLecture: ("1lcGSieveZ4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "10-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Structural organization in animals-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_6153_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_6039_Sol.pdf"),
        VideoLecture: ("vYQ2T4qbMh4"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "12-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Structural organization in animals-3",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_7902_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_1844_Sol.pdf"),
        VideoLecture: ("QNY0EPIe5Mk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "13-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Animal Diversity-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_5548_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_9508_Sol.pdf"),
        VideoLecture: ("y6W9_gXrbc4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Animal Diversity-2-I",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_3746_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_7919_Sol.pdf"),
        VideoLecture: ("xYhtW8vV4uU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Animal Diversity-2-II",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_5743_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_9115_Sol.pdf"),
        VideoLecture: ("R_2ufMCx3g8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Locomotion and Reproduction",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_7343_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_7371_Sol.pdf"),
        VideoLecture: ("GoRg3b3hyok"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Biology in Human Welfare-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_8507_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_9873_Sol.pdf"),
        VideoLecture: ("In691UWxE84"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Biology in Human Welfare-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_317_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_3633_Sol.pdf"),
        VideoLecture: ("p7CU8D6CnoA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Periplaneta americana(Cockroach)",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_1528_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_7718_Sol.pdf"),
        VideoLecture: ("X1EBuUOQ82c"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Ecology and Environment-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_5272_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_4856_Sol.pdf"),
        VideoLecture: ("0I1kAbYgOo0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Ecology and Environment-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_3481_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_9115_Sol.pdf"),
        VideoLecture: ("ykGz5QdALUM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Human Anatomy and Physiology-1B",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_4191_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_8567_Sol.pdf"),
        VideoLecture: ("Bu4s-BLBuvg"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: " Body Fluids and Circulation",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_5389_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_5384_Sol.pdf"),
        VideoLecture: ("oz0oRQ97mBQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Excretory products and their Elimination",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_584_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_2349_Sol.pdf"),
        VideoLecture: ("yt9-GfrfXHk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Musculoskeletal system",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_8633_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_169_Sol.pdf"),
        VideoLecture: ("JV7VgdBzt7M"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Neural control and coordination",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_6403_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_2947_Sol.pdf"),
        VideoLecture: ("ZMvA99-r_Ks"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Endocrine system and Chemical coordination",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_4746_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_1674_Sol.pdf"),
        VideoLecture: ("cS2YE5yFIrU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Immune System",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_1704_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_7083_Sol.pdf"),
        VideoLecture: ("43ixKreKBPA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Human Reproduction-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_8927_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_7509_Sol.pdf"),
        VideoLecture: ("Ywtot2GAYxU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Human Reproduction-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_6989_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_9086_Sol.pdf"),
        VideoLecture: ("AxoGYdO_dug"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Reproductive Health",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_6815_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_288_Sol.pdf"),
        VideoLecture: ("0F304ZdLie4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Genetics-1",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_5709_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_9855_Sol.pdf"),
        VideoLecture: ("hQP5ljbmPI0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Genetics-2",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_65_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_5652_Sol.pdf"),
        VideoLecture: ("Y1zNF9uqnrI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "12 PM to 1.30 PM",
        time: "12",
        Subject: "Zoology",
        Chapter: "Applied Biology",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_4427_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_8831_Sol.pdf"),
        VideoLecture: ("0ePGrGer8Bs"),
        Live: (""),
        Zoom: (""),
    }
];
let LiveClassesDataZoology = []

for (var i in QuestionData) {
    let currentDate = moment().format("DD/MM/YYYY");
    if ((QuestionData[i]["VideoLecture"] || QuestionData[i]["Live"] || QuestionData[i]["Zoom"]) && (currentDate == moment(QuestionData[i].DATE, "DD-MM-YYYY").format("DD/MM/YYYY"))) {
        LiveClassesDataZoology.push(QuestionData[i]);
    }
}

var time;
var Zoom;
var Livevideo;
class ZoologyDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            dataLiveUrl: "",
            userRestionModalShow: false,
            allLiveClasses: true
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }
    modalFunSubscribed = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7 && Cookies.get("institution_id") == 30 && this.isStudentUserValid.current_plan_id == "23") {
            this.setState({
                userRestionModalShow: true,
            });
        } else {
            this.setState({
                liveModalShow: true,
                dataLiveUrl: row.Live
            });
        }
    }
    checkForDate = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7) {
            return true
        }
        return false
    }
    modalaFun = (row) => {
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });
    }
    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }
    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    sortFuncDate(a, b, order, dataField, rowA, rowB) {
        if (order == 'desc') {

            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }
    actionFormatterAssignments(cell, row, rowIndex) {
        return (
            <>
                {
                    row.Assignments != "" ?
                        <div className="Assignments">
                            <img src={FileText} alt="file-text-img" ></img>
                            <a href={row.Assignments} target="_blank"> Assignment Download</a>
                        </div> : <h6 className="text-center">-</h6>
                }

                {
                    row.Solutions != "" ?
                        <div className="Solutions">
                            <img src={FileArrowDown} alt="File-Arrowdown-img"></img>
                            <a href={row.Solutions} target="_blank" > Solutions</a>
                        </div> : ""
                }
            </>

        )
    }

    actionFormatterVideoLecture(cell, row, rowIndex) {

        return (
            <>
                <div className="allsubjects">
                    {
                        row.Live == "" ?

                            row.VideoLecture != "" ?
                                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                : "-"


                            :
                            <>

                                {
                                    <a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-success zoom_effect effect-1">Zoom</button></a>
                                }
                                {
                                    <button type="button" className="btn btn-warning live live_effect effect-1" onClick={() => this.modalaFun(row.Live)}>Live</button>
                                }



                            </>
                    }

                </div>

            </>
        )
    }
    colFunction() {

        let columns = [
            // {
            //     dataField: "DATE",
            //     text: "Date",
            //     sort: true,
            //     headerStyle: {
            //         width: "13%"
            //     },
            //     style: {
            //         fontWeight: "bold !important"
            //     },
            //     sortFunc: this.sortFuncDate.bind(this)
            // },
            // {
            //     dataField: "ClassTimings",
            //     text: "Class Timings",
            //     sort: true,
            //     headerStyle: {
            //         width: "23%"
            //     },
            //     sortFunc: this.sortFuncMulti.bind(this)
            // },

            {
                dataField: "Subject",
                text: "Subject",
                style: (row, rowIndex) => {
                    return { fontWeight: "bold !important" };
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                    width: "35%"
                }
            },
            {
                dataField: "Assignments/Solution",
                text: "Assignment/Solution",
                headerStyle: {
                    width: "55%"
                },

                formatter: this.actionFormatterAssignments.bind(this)
            },
            // {
            //     dataField: "Solutions",
            //     text: "Solutions",
            //     formatter: this.actionFormatterSolutions
            // },
            {
                dataField: "Videos/Recorded/Live",
                text: "Videos(Recorded/Live)",
                formatter: this.actionFormatterVideoLecture.bind(this)
            },
            // {
            //     dataField: "Live/Zoom",
            //     text: "Live/Zoom",
            //     formatter: this.actionFormatterLive
            // },
        ];
        return columns;
    }

    render() {
        return (
            <Row>
                <Col align="right" sm={12}>
                    <h6 className="switch_button_cls mt-res-4">
                        <p className="allClassesAlignment">All Classes</p>
                        <label class="switch">
                            <input type="checkbox"
                                onChange={() => {

                                    this.setState((state) => {

                                        return {
                                            allLiveClasses: !state.allLiveClasses
                                        }
                                    })
                                }}
                                checked={!this.state.allLiveClasses} />
                            <span class="slider round"></span>
                        </label>
                        <p className="liveClassesAlignment">Live Classes</p>
                    </h6>
                </Col>
                <Col sm={12}>
                    {
                        this.state.allLiveClasses ?
                            <CardLessDataTableWithSearch
                                parentData={QuestionData}
                                particlecolumns={this.colFunction()}
                                defaultSorted={[{
                                    dataField: "ClassTimings",
                                    order: "asc"
                                }]}
                            />
                            :
                            <CardLessDataTableWithSearch
                                parentData={LiveClassesDataZoology}
                                particlecolumns={this.colFunction()}
                                defaultSorted={[{
                                    dataField: "ClassTimings",
                                    order: "asc"
                                }]}
                            />
                    }
                    {/* <Card>
                        <BootstrapTable
                            keyField="id"
                            columns={this.colFunction()}
                            data={QuestionData}
                            defaultSorted={[{
                                dataField: "ClassTimings",
                                order: "asc"
                            }]}
                            wrapperClasses="table-responsive"
                            bootstrap4

                        />
                    </Card> */}

                    <LiveClassesModal data={this.state.dataLiveUrl} show={this.state.liveModalShow} onHide={() => this.setState({ liveModalShow: false })} />
                    <UserRestrictionAlertInstitute
                        show={this.state.userRestionModalShow}
                        onHide={() => this.setState({ userRestionModalShow: false })} />
                </Col>
            </Row>

        )
    }
}
export default ZoologyDataSection