import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import EamcetMockTestsSection from '../components/EamcetMocktests/EamcetMockTestsSections'
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';


const FETCH_GETSERIES = gql` 
query($mobile: String,
    $exam_group_id: Int) {
        getGroupWiseExams(
            mobile: $mobile,
            exam_group_id: $exam_group_id)
            {
                freeExams{
                    id
                    exam_name
                    exam_type
                    exam_session_id
                    sub_exam_type
                    is_completed
                    is_started
                    title
                    short_name
                    amount
                    speed
                    accuracy
                    correct_marks
                    negative_marks
                    total_marks
                    start_time
                    end_time
                    is_purchased
                    exam_started
                    is_scheduled
                    is_registered
                    exam_expired
                    exam_duration
                    syllabus
                    class_id
                    exam_class_id
                    syllabus_desc
                }
                paidExams{
                    id
                    exam_name
                    exam_type
                    exam_session_id
                    sub_exam_type
                    is_completed
                    is_started
                    title
                    short_name
                    amount
                    speed
                    accuracy
                    correct_marks
                    negative_marks
                    total_marks
                    start_time
                    end_time
                    is_purchased
                    exam_started
                    is_scheduled
                    is_registered
                    exam_expired
                    exam_duration
                    syllabus
                    class_id
                    exam_class_id
                    syllabus_desc
                }
                
    }
}

`;


const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;



class EamcetMockTests extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerBottomImg: {
                type: "eamcet_view",
                title: "MOCK TEST"
            },
            submitError: ""

        }
    }

    menuToggler = () => {
        const toggled = this.state.toggled;
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    componentDidMount = () => {
        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Eamcet_Mocktests;
        ReactGA.pageview('/student/Mocktests', ["rizee"], title);
    }
    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 105,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };



    render() {

        const getGroupWiseExams = this.props.getGroupWiseExams
        const loading = getGroupWiseExams.loading
        const error = getGroupWiseExams.error

        if (error !== undefined) {
            alert("Server Error. " + error.message);
            return null;
        }



        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }
        let getGroupWiseExamsData = [];
        if(!loading){
            getGroupWiseExams.getGroupWiseExams.map(item => {
                item.freeExams.map(exams => {
                    getGroupWiseExamsData.push(exams);
                })
            });
            getGroupWiseExams.getGroupWiseExams.map(item => {
                item.paidExams.map(exams => {
                    getGroupWiseExamsData.push(exams);
                })
            });
        }
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars
                        headerBottom={this.state.headerBottomImg}
                        onClick={() => this.menuToggler()}
                    />
                </div>
                <div className="main-wrapper">

                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper">
                        {
                            loading == true ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <EamcetMockTestsSection
                                        getStudentTestSeries={getGroupWiseExamsData} data={this.props}
                                    />
                                )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(
    graphql(FETCH_GETSERIES,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_group_id: Cookies.get("examid") == "3" ? 3 : Cookies.get("examid") == "6"  ?  4 : Cookies.get("examid") == "7" ? 5 : Cookies.get("examid") == "8"? 6: Cookies.get("examid") == "9"? 29 :Cookies.get("examid") == "10"? 30:31
                }
                ,
                fetchPolicy: 'network-only'
            }), name: "getGroupWiseExams"
        }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),

)(EamcetMockTests));
