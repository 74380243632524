import React, { Component } from 'react';
import { Container, Row, Col, Card, Button, Image, Form, Accordion, Media } from 'react-bootstrap'
import * as Cookies from "es-cookie";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";

import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import gql from "graphql-tag";
import moment from 'moment';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import EamcetRestrictionAlert from "../home/EamcetRestrictionAlert";
import { log } from 'logrocket';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';
import './_ipequestionpapers.scss'

import QuestionPaperDisplaySection from './questionPaperDisplay'

class IpeQuestionPapersSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defaultSelect: "",
            stateval: Cookies.get("examid") == 3 || Cookies.get("examid") == 7 ? 12 : 13,
            defaultSelect: "",
            userRestionModalShow: false,
            currentActiveKey: "",
            mainPaperData: [],
            questionPaperPage: false,
            showLoading: false,
            getIPEPrevousQuestions: [],
            examPaperDetails: [],
        }
    }

    componentDidMount() {
        const title = GoogleAnalyticsArray[0].Eamcet_Previous_Papers;
        ReactGA.pageview('/student/StatePreviousPapers', ["rizee"], title);

        if (Cookies.get("examid") == 3 || Cookies.get("examid") == 7) {
            this.setState({
                defaultSelect: "AP"
            })
        } else {
            this.setState({
                defaultSelect: "TS"
            })
        }
    }

    toggle = (index) => {
        if (this.state.currentActiveKey != index.toString()) {
            this.setState({ currentActiveKey: index })
        }
        else {
            this.setState({ currentActiveKey: "" })
        }

    }


    handlestate(type, examid) {
        this.setState({
            defaultSelect: type,
            currentActiveKey: "",
            mainPaperData: []
        })
        this.setState({
            stateval: examid
        })
    }

    goBack = () => {
        this.setState({ questionPaperPage: false, currentActiveKey: "", getIPEPrevousQuestions: [] })
    }

    showQuestions = async (paperid) => {
        const paperDetails = paperid

        const value = paperid.id
        this.setState({
            questionPaperPage: true,
            showLoading: true,
            examPaperDetails: paperDetails
        })

        const result = await this.props.client.query({
            query: gql`
            query($paper_id:Int){
                getIPEPrevousQuestions(paper_id: $paper_id) {
                    id
                    slno
                    question_name
                    explanation
                    qtype
                    marks
                  }
            }
            `,
            variables: {
                paper_id: parseInt(value)
            },
            fetchPolicy: 'network-only'

        })

        if (result.data.getIPEPrevousQuestions.length > 0) {
            this.setState({
                getIPEPrevousQuestions: result.data.getIPEPrevousQuestions,
                showLoading: false
            })
        } else {
            this.setState({
                getIPEPrevousQuestions: [],
                showLoading: false
            })
        }
    }
    modalaFun = () => {
        this.setState({
            userRestionModalShow: true,
        })
    }

    render() {

        let examYears = ['2016', '2017', '2018', '2019', '2020', '2021', '2022'];
        let currentTime_currentSec = Math.round((new Date()).getTime() / 1000);
        let papersData = this.props.studentGlobals.previousSets.filter((item) => item.exam == this.state.stateval);
        const uniqueArr = [... new Set(papersData.map(data => data.year))];
        let filterArray = examYears.filter(val => !uniqueArr.includes(val));
        let data = uniqueArr.concat(filterArray).sort((a, b) => b - a);
        let examsData = [];

        data.forEach(function (item1) {
            let examValues = [];
            let obj = {
                year: item1,
                value: examValues
            }
            papersData.map((item) => {
                if (item.year == obj.year) {
                    let obj1 = {
                        ...item
                    }
                    examValues.push(obj1)
                }
            });
            examsData.push(obj)
        });
        this.state.mainPaperData = examsData;

        return (
            <Container className='ipe_previous_papers'>
                <Row className='mt_resp_5'>
                    <Col sm={12}>
                        {
                            this.state.questionPaperPage == true ?
                                (
                                    <button type="button" className="btn-custom-red float-right f-14" onClick={() => this.goBack()}>Go Back</button>
                                )
                                :
                                ("")
                        }
                    </Col>
                </Row>
                {
                    this.state.questionPaperPage == true ?
                        (
                            <QuestionPaperDisplaySection
                                questionsData={this.state.getIPEPrevousQuestions}
                                showLoading={this.state.showLoading}
                                examPaperDetails={this.state.examPaperDetails}
                                marksRadioButton={this.state.getIPEPrevousQuestions.length > 0 && this.state.getIPEPrevousQuestions[0].marks == undefined ? false : true}
                            />
                        )
                        :
                        (
                            <>
                                <Row className='mt-2 bg_white_list'>
                                    <Col sm={12}>
                                        <ul className='list-inline list_style_EPP mt-3'>
                                            <li className={this.state.defaultSelect == 'AP' ? 'active' : ''} onClick={() => this.handlestate('AP', 12)} >AP IPE</li>
                                            <li className={this.state.defaultSelect == 'TS' ? 'ml-4 active' : 'ml-4'} onClick={() => this.handlestate('TS', 13)}>TS IPE</li>
                                        </ul>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <Form.Text className="form-text text-danger">
                                            {this.state.submitError}
                                        </Form.Text>
                                        <React.Fragment>
                                            <Accordion>
                                                {
                                                    this.state.mainPaperData.map((item, index) => {
                                                        console.log("Accordion", item);
                                                        return (
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <React.Fragment key={index} className="border-0">
                                                                        <Accordion.Toggle
                                                                            as={Card}
                                                                            className='mt-2 border_none_fff'
                                                                            eventKey={index}
                                                                            onClick={() => this.toggle(index)}
                                                                        >
                                                                            <div >
                                                                                <Row>
                                                                                    <Col sm={6}>
                                                                                        <h6 className={this.state.currentActiveKey == index.toString() ? "chap_block_view active" : "chap_block_view "} >
                                                                                            <span className='ml-2'>{item.year} Previous Papers</span>
                                                                                            {this.state.currentActiveKey == index.toString() ? (
                                                                                                <i className="fas fa-chevron-up float-right" style={{ cursor: "pointer" }}></i>
                                                                                            ) : (
                                                                                                <i className="fas fa-chevron-down float-right" style={{ cursor: "pointer" }}></i>
                                                                                            )}
                                                                                        </h6>
                                                                                    </Col>
                                                                                </Row>


                                                                            </div>

                                                                        </Accordion.Toggle>

                                                                        <Accordion.Collapse eventKey={index}>
                                                                            <Card as={Card.Body} className="p-2">
                                                                                <Row>
                                                                                    {item.value != null && item.value.length > 0 ?
                                                                                        (
                                                                                            item.value.map((item) => {
                                                                                                console.log("aaaaaaaaaaa", item)
                                                                                                return (
                                                                                                    <Col sm={4} className='mt-2'>
                                                                                                        <Card className='border_pad_card' style={{ cursor: "pointer" }}>
                                                                                                            <Card.Body className='p-2'>
                                                                                                                <h6 className='mt-2 mb-3 ml-1'>{item.qset}</h6>
                                                                                                                <p className='mt-2 mb-3 ml-1'>Duration : <b>{item.exam_duration_min}</b>
                                                                                                                    {
                                                                                                                        item.enabled == false ?
                                                                                                                            (
                                                                                                                                <button type="button" className="btn btn-custom-blutst float-right f-14" onClick={(e) => this.showQuestions(item)}>View Paper</button>
                                                                                                                            )
                                                                                                                            :
                                                                                                                            (
                                                                                                                                <button type="button" className="btn btn-custom-blutst float-right f-14" onClick={() => this.modalaFun()}>View Paper</button>
                                                                                                                            )
                                                                                                                    }

                                                                                                                </p>
                                                                                                            </Card.Body>
                                                                                                        </Card>
                                                                                                    </Col>
                                                                                                )
                                                                                            }
                                                                                            ))
                                                                                        : (<Col sm={12}><h6 className='text-center'>Coming Soon...</h6></Col>)
                                                                                    }
                                                                                </Row>
                                                                            </Card>
                                                                        </Accordion.Collapse>
                                                                    </React.Fragment>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })
                                                }
                                            </Accordion>
                                        </React.Fragment>
                                        <EamcetRestrictionAlert
                                            show={this.state.userRestionModalShow}
                                            onHide={() => this.setState({ userRestionModalShow: false })}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )
                }

            </Container>
        )
    }
}

export default withRouter(withApollo(compose(

)(IpeQuestionPapersSection)));