import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import * as Cookies from "es-cookie";
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'
import PreviousPaperExamsHome from '../components/previous_paper_exams/PreviousPaperExamsHome';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import moment from 'moment';



import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import JeeNEwPlanPopup from '../components/mock_series/jeeNewPlanpopup'
import NEETPrepBundlePopup from '../components/mock_series_newNTAPattern/NEETPrepBundlepopup'


const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

class PreviousPaperExams extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggled: "wrapper sidebar-enable",
            headerBottomImg: {
                type: "prev_exams",
                title: "Previous Year Papers",
            },
            submitError: "",
            planpopup: true
        }

        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }

    componentDidMount = () => {

        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Eamcet_Crash_Course;
        ReactGA.pageview('/student/eamcet-crashcourse/home', ["rizee"], title);
    }

    onHide = () => {
        this.setState({
            planpopup: false
        })
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 60,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };


    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    render() {
        const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);
        

        return (
            <div className={Cookies.get("toggle")}>
                <div className="student main-wrapper">
                    <div className="student header-area previous-paper-topnavbar">
                        <ScrollTopNavbar
                            headerBottom={this.state.headerBottomImg}
                            onClick={() => this.menuToggler()} />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper">
                        <PreviousPaperExamsHome />
                         {/* {
                            Cookies.get("examid") == 2 && isuserValid.jeePrepBundleAcess == true ?
                                (
                                    <JeeNEwPlanPopup
                                        show={this.state.planpopup}
                                        onHide={this.onHide}
                                    />
                                )
                                :
                                (
                                    Cookies.get("examid") == 1 && isuserValid.jeePrepBundleAcess == true ?
                                        (
                                            <NEETPrepBundlePopup
                                                show={this.state.planpopup}
                                                onHide={this.onHide}
                                            />
                                        )
                                        :
                                        ("")
                                )
                        } */}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(

    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
)(PreviousPaperExams));
