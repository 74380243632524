import React, { Component } from 'react'
import { Row, Col, Table, Image, Button, Container, Accordion, Card, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { withRouter, Link } from "react-router-dom";
import * as Cookies from "es-cookie";
import "./_referFriend.scss";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import {
    FacebookShareButton,
    InstapaperShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    FacebookIcon,
    FacebookMessengerIcon,
    InstapaperIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isFirefox
} from "react-device-detect";
import RewardsHistoryModal from './rewardsHistoryModal';
import RewardsModal from './rewardsModal'

const FETCH_REFERALDETAILS = gql` 
    query($mobile: String!) {
        getReferredStudents(mobile: $mobile){
            total
            students{
                name
                joined
            }
        }
    }
    `;

class ReferFriendSecton extends Component {
    constructor(props) {
        super(props)

        this.state = {
            textAreaValue: "Hi! I found the best exam preparation platform called Rizee. It really helped me prepare for my exams and I am sure it will help you too! Here is a link to register with Rizee. Hurry and sign up now!",
            textToCopy: '',
            isShow: '',
            iconChange: "up",
            rewardsHistoryModal: false,
            usersModalShow: false,
        }
    }

    showOrHideCollapse = () => {
        if (this.state.isShow) {
            this.setState(state => ({ ...state, isShow: '' }))
        } else {
            this.setState(state => ({ ...state, isShow: 'show' }))
        }
    }


    handleChange = (event) => {
        this.setState({ textAreaValue: event.target.value });
    }

    getAccordianIconChange = () => {
        if (this.state.iconChange == "up") {
            this.setState({ iconChange: "down" })
        } else {
            this.setState({ iconChange: "up" })
        }
    }

    handleHistoryRewards = () => {
        this.setState({
            rewardsHistoryModal: true
        })
    }

    handleShowUsers = () => {
        this.setState({
            usersModalShow: true
        })
    }

    render() {

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }

        this.state.textToCopy = `https://rizee.in/Registration/?src=friend_refferal_web&referal_code=${studentGlobals.uid}` 


        const getReferredStudents = this.props.getReferredStudents;
        const loading5 = getReferredStudents.loading;
        const error5 = getReferredStudents.error;

        
        if (error5 !== undefined) {
            alert("Server Error. " + error5.message);
            return null;
        }

        let totalCount = 0;
        if (getReferredStudents.getReferredStudents != undefined) {
            totalCount = getReferredStudents.getReferredStudents.total
        }

        let referralRewards = this.props.getReferralStaticData[0].no_of_days;
        let referralTotalData = this.props.getReferralClaimedPoints[0].no_of_days;


        return (
            <Container className="refer_friend mt-4">

                {/* <Row>
                    <Col sm={12}>
                        <div>
                            <h1 className="text-center-res img-center-res">Refer A Friend</h1>
                        </div>
                    </Col>
                </Row> */}

                <Row>
                    <Col sm={3} className="mt-4">
                        <Image src={require('../../../images/refer_an_friend.png')} alt="refer_an_friend" className="w-100 pos_align_img_res" ></Image>
                    </Col>

                    <Col sm={9} className="mt-4">
                        <div className="pad_10">
                            <Row>
                                <Col>
                                    <div className={isMobile == true ? "" : "arrow"}>
                                        <Image src={require('../../../images/invite_friend.png')} alt="invite_friend" className="w-130 img-center" ></Image>
                                        <h5 className="text-center mt-3">Invite Your Friend</h5>
                                        <p className="text-center color_yash f-16">Spread the word with your link via social sharing</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={isMobile == true ? "" : "arrow-down"}>
                                        <Image src={require('../../../images/registrations.png')} alt="registrations" className="w-130 img-center" ></Image>
                                        <h5 className="text-center mt-3">Registration</h5>
                                        <p className="text-center color_yash f-16">Your friend signs up to <span className='font-weight-bold color_black'>Rizee</span> & gets 3 days <span  className='font-weight-bold color_black'>Free</span> trial access</p>
                                    </div>
                                </Col>
                                <Col>
                                    <Image src={require('../../../images/rizee_free.png')} alt="rizee_free" className="w-130 img-center" ></Image>
                                    <h5 className="text-center mt-3">Rizee for Free</h5>
                                    <p className="text-center color_yash f-16">You get {referralRewards} days <span className='font-weight-bold color_black'>Rizee</span> subscription as a reward</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                {
                    Cookies.get("institution_id") != 30 ?
                        (
                            <Row>
                                <Col className="mt-4">
                                    <React.Fragment>
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Accordion.Toggle className="bg-white" as={Card.Header} eventKey="0" onClick={this.getAccordianIconChange}>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="mt-2 ml-res-4">Your Referrals and  Rewards <i className={this.state.iconChange == "up" ? "fas fa-chevron-up float-right" : "fas fa-chevron-down  float-right"}></i></h6>
                                                        </Col>

                                                    </Row>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body className="p-4">
                                                        <Row>
                                                            <Col sm={5}>
                                                                <h6 className="" onClick={() => this.handleShowUsers()} >Total referrals registered : <span className="f-16 ml-2 user-hover">{totalCount} {totalCount > 1 || totalCount == 0 ? "Users" : "User"}</span> </h6>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <h6> Total days rewarded : {referralTotalData} <i class="fas fa-gift color_red ml-2"></i></h6>
                                                                <h6 className="color_yash f-12 ml-res-4"><span className='font-weight-bold color_black'>1 User</span> = {referralRewards} Days</h6>
                                                            </Col>
                                                            <Col sm={3}>
                                                                {
                                                                    this.props.getReferralClaimedPoints[0].name == "" ?
                                                                        (<Button type="submit" disabled className='claim-history-button-disabled'>Rewards History</Button>
                                                                        )
                                                                        :
                                                                        (<Button type="submit" onClick={(e) => this.handleHistoryRewards()} className='claim-history-button'>Rewards History</Button>
                                                                        )
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </React.Fragment>
                                </Col>
                            </Row>
                        )
                        : ("")
                }

                <Row>
                    <Col sm={12}>
                        <div className="share-block-cls mt-4">
                            <Row className="">
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={12} >
                                            <Form>
                                                <Form.Group className="mb-3 mt-2 text-area-center" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label><h5 id="sendinvitation">Send Invite:</h5> </Form.Label>
                                                    <Form.Control as="textarea" rows={2} className="text-center f-16" placeholder="Hi! I found the best exam preparation platform called Rizee. It really helped me prepare for my exams and I am sure it will help you too! Here is a link to register with Rizee. Hurry and sign up now!" value={this.state.textAreaValue} onChange={this.handleChange} />
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={5}>
                                            <Row>
                                                <Col>
                                                    <ul class="social-media-icons">
                                                        <li className="align_top res_hide mt-2 color_yash">Share On</li>
                                                        <li>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>share on Facebook</Tooltip>}>
                                                                <a className="fb-color" > <FacebookShareButton url={"https://rizee.in/Registration/?src=friend_refferal_web&referal_code=" + studentGlobals.uid} title={this.state.textAreaValue}>
                                                                    <i class="fab fa-facebook-f icon"></i>
                                                                </FacebookShareButton></a>
                                                            </OverlayTrigger>

                                                        </li>
                                                        <li>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>share on Whatsapp</Tooltip>}>
                                                                <a className="wt-color"> <WhatsappShareButton url={"https://rizee.in/Registration/?src=friend_refferal_web&referal_code=" + studentGlobals.uid} title={this.state.textAreaValue}>
                                                                    <i class="fab fa-whatsapp icon"></i>
                                                                </WhatsappShareButton>
                                                                </a>
                                                            </OverlayTrigger>

                                                        </li>
                                                        <li>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>share on Telegram</Tooltip>}>
                                                                <a className="te-color"> <TelegramShareButton url={"https://rizee.in/Registration/?src=friend_refferal_web&referal_code=" + studentGlobals.uid} title={this.state.textAreaValue}>
                                                                    <i class="fab fa-telegram icon"></i>
                                                                </TelegramShareButton>
                                                                </a>
                                                            </OverlayTrigger>

                                                        </li>

                                                        <li>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>share on Twitter</Tooltip>}>
                                                                <a className="tw-color"><TwitterShareButton url={"https://rizee.in/Registration/?src=friend_refferal_web&referal_code=" + studentGlobals.uid} title={this.state.textAreaValue}>
                                                                    <i class="fab fa-twitter icon"></i>
                                                                </TwitterShareButton>
                                                                </a>
                                                            </OverlayTrigger>

                                                        </li>

                                                    </ul>

                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={1}>
                                            <h6 className="mt-res-3 text-center-res">or</h6>
                                        </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={10} className="">
                                                    <Form>
                                                        <Form.Group className="mb-3">
                                                            <Form.Control type="text" placeholder="" className="height-rf input-box-color" value={this.state.textToCopy} />
                                                        </Form.Group>
                                                    </Form>
                                                </Col>
                                                <Col sm={2}>
                                                    <Button type="button" variant="secondary" className="f-12 mt-1" onClick={() => { navigator.clipboard.writeText(this.state.textToCopy) }}>COPY</Button>
                                                </Col>
                                            </Row>

                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <RewardsHistoryModal
                    show={this.state.rewardsHistoryModal}
                    onHide={() => this.setState({ rewardsHistoryModal: false })}
                    redeemHistory={this.props.getReferralClaimedPoints}
                />

                <RewardsModal show={this.state.usersModalShow}
                    onHide={() => this.setState({ usersModalShow: false })}
                    studentsData={this.props.getTotalReferrals}
                />
                

            
            </Container>
        )

    }
}

export default withRouter(compose(

    graphql(FETCH_REFERALDETAILS,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: "cache-and-network"
            }), name: "getReferredStudents"
        })
)(ReferFriendSecton));

