import React, { Component } from 'react'
import { Container, Row, Col, Card,DropdownButton } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import * as Cookies from "es-cookie";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';

import './_categorywisevideos.scss';
import Category from '../../../neetjee_guru/pages/Category';
import Live_image from '../../../images/play.png'




const FETCH_CUSTOM_VIDEO_CATEGORIES = gql` 
query($mobile: String!,
    $exam: String) {
        getCustomVideoCategories(params:{mobile: $mobile,
            exam: $exam}){
            category
            exam
            ExamType
            order_by
            description
            CustomVideoSubCategory{
                category
                exam
                order_by
                CustomVideosList{
                    paid_video
                    title
                    exam_type
                    class
                    description
                    video_url
                    vimeo_url
                    subjectName
                    ChapterName
	topicName
                    likes
                    dislikes
                    views
                    is_purchased
                    thumbnail
                    video_id
                    created_timestamp
                    pdf_file
                    video_source
                    is_live
                    is_upcoming 
                    subject   
                    publish
                }
            }
    }
}

`;

const FETCH_SERVICE_DATA_FOR_LIVE_CLASSES_SCHEDULED = gql` 
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String
    ){
        getServiceData(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type){
            data
            }
        }
    `;

class VideosHomeSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }


    }

    checkVideoSubCategoryideosList = (category) => {
        let value = false
        category.CustomVideoSubCategory.map(data => {
            if (data.CustomVideosList != null && data.CustomVideosList.length > 0) {
                value = true
            }
        })
        return value
    }

    render() {

        const EXAMTYPE = [{
            id: "1",
            examType: null,
            value: "NEET"
        },
        {
            id: "2",
            examType: "1",
            value: "JEE Mains"
        },
        {
            id: "2",
            examType: "2",
            value: "JEE Advanced"
        }]

        const getCustomVideoCategories = this.props.getCustomVideoCategories;
        console.log("videocate", getCustomVideoCategories)
        const loading1 = getCustomVideoCategories.loading;
        const error1 = getCustomVideoCategories.error;


        const getServiceData = this.props.getServiceData;
        console.log("videocate", getServiceData)
        const loading2 = getServiceData.loading;


        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        if (loading1) {

            return (<Container><Row className="my-3">
                <Col>
                    <div class="spinner-border text-primary text-center loading-img-center"></div>
                </Col>
            </Row></Container>)
        } else {

            return (


                <Container className="">

                    {EXAMTYPE.map((emap) => {
                        if (Cookies.get("examid") == 1 && emap.id == "1") {
                            return (
                                <React.Fragment>
                                    <Row className="mt-res-4rem" >
                                        <Col sm={4}> <h4 className="mb-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" className="svg-pos-cls">
                                                <path d="M12.427,16.331 C4.726,21.324 0.660,19.226 0.228,10.039 C-0.203,0.852 3.647,-1.643 11.780,2.552 C19.913,6.747 20.128,11.340 12.427,16.331 Z" fill="#2a4ed0" />
                                            </svg>
                                            <span className="ml-2">{emap.value}</span>
                                        </h4>
                                        </Col>
                                        {
                                            getServiceData.getServiceData != undefined ? (
                                                getServiceData.getServiceData.map((mainData,index) => 
                                                mainData.data != null && mainData.data.length >0 ? 
                                                (
                                                    <Col sm={8} className="dropdowm-scheduled">
                                                        <a onClick={()=>this.setState({ isCardView: !this.state.isCardView })}>
                                                        <DropdownButton id="dropdown-basic-button" title="View Schedule" className={this.state.isCardView ? "dropdown-icon-reverse float-right-res" : "dropdown-icon float-right-res"}>
                                                            {
                                                                mainData.data.map((scheduledUrls, subindex) => 
                                                                <Row>
                                                                    <Col>
                                                                        <a href={scheduledUrls.url} target="_blank" className="mt-2 color-blue"><h6>{scheduledUrls.short_description}<i class="fas fa-download float-right"></i></h6></a>
                                                                    </Col>
                        
                                                                </Row>
                                                                ) 
                                                            }
                                                                
                                                        </DropdownButton>
                                                    </a>
                                                    </Col>
                                                )
                                                :
                                                ("")
                                                )
                                            ) : ("")
                                                
                                            }
                                    </Row>
                                    <Row>
                                        {
                                            getCustomVideoCategories.getCustomVideoCategories.sort((a,b) => a.order_by - b.order_by).filter((item, indexVal) => item.ExamType == emap.examType)
                                                .map((data, index) => {
                                                                                
                                                        return (
                                                            data.CustomVideoSubCategory != null && data.CustomVideoSubCategory.length > 0 ?
                                                                (
                                                                    this.checkVideoSubCategoryideosList(data) ?
                                                                        (<Col sm={4} className="">
                                                                            <Link to={{
                                                                                    pathname: "/student/category_wise_videos/level-wise-videos" ,
                                                                                    state: {
                                                                                        categoryNameType: data,
                                                                                        mainCategoryName: data.category,
                                                                                        scheduledType : "neet"
                                                                                    }
                                                                                }} >
                                                                            
                                                                                <Card className={index > 3 ? "categoryName mt-4" : "categoryName  mt-4" }>
                                                                                    <Card.Body>
                                                                                        <Row>
                                                                                            <Col sm={10}>
                                                                                                <Card.Title className="f-24">{data.category}
                                                                                                    {
                                                                                                        data.category == "Live Classes" ?
                                                                                                            (
                                                                                                                <span className=""><img src={Live_image}  alt="New" class="ml-2" width="50" /></span>
                                                                                                            )
                                                                                                            :
                                                                                                            ("")
                                                                                                    }
    
                                                                                                </Card.Title>
                                                                                                <Card.Text className="categorySubName">
                                                                                                    {data.description}
                                                                                                </Card.Text>
                                                                                            </Col>
                                                                                            <Col sm={2}>
                                                                                                <span className="category-arrow float-right">
                                                                                                    <i class="far fa-arrow-right"></i>
                                                                                                </span>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Card.Body>
    
                                                                                </Card>
    
                                                                            </Link>
                                                                        </Col>) : ('')
    
                                                                )
                                                                :
                                                                ("")
    
                                                        )
                                                                                                
                                                   

                                                    


                                                })}

                                    </Row>
                                </React.Fragment>
                            )
                        } else if (Cookies.get("examid") == 2 && emap.id == "2") {
                            return (
                                <React.Fragment>
                                    {
                                        emap.examType == 1 ?
                                            (
                                                <Row className="mt-4">
                                                    <Col> <h4 className="mb-0" sm={4}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" className="svg-pos-cls">
                                                            <path d="M12.427,16.331 C4.726,21.324 0.660,19.226 0.228,10.039 C-0.203,0.852 3.647,-1.643 11.780,2.552 C19.913,6.747 20.128,11.340 12.427,16.331 Z" fill="#2a4ed0" />
                                                        </svg>
                                                        <span className="ml-2">{emap.value}</span>
                                                    </h4>
                                                    </Col>
                                                    {
                                                        getServiceData.getServiceData != undefined ? (
                                                            getServiceData.getServiceData.map((mainData,index) => 
                                                            mainData.data != null && mainData.data.length >0 ? 
                                                            (
                                                                <Col sm={8} className="dropdowm-scheduled">
                                                                    <a onClick={()=>this.setState({ isCardView: !this.state.isCardView })}>
                                                                    <DropdownButton id="dropdown-basic-button" title="View Schedule" className={this.state.isCardView ? "dropdown-icon-reverse float-right-res" : "dropdown-icon float-right-res"}>
                                                                        {
                                                                            mainData.data.map((scheduledUrls, subindex) => 
                                                                            <Row>
                                                                                <Col>
                                                                                    <a href={scheduledUrls.url} target="_blank" className="mt-2 color-blue"><h6>{scheduledUrls.short_description}<i class="fas fa-download float-right"></i></h6></a>
                                                                                </Col>
                                    
                                                                            </Row>
                                                                            ) 
                                                                        }
                                                                            
                                                                    </DropdownButton>
                                                                </a>
                                                                </Col>
                                                            )
                                                            :
                                                            ("")
                                                            )
                                                        ) : ("")
                                                            
                                                        }
                                                </Row>
                                            )
                                            :
                                            (
                                                <Row className="mt-5">
                                                    <Col> <h4 className="mb-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" className="svg-pos-cls">
                                                            <path d="M12.427,16.331 C4.726,21.324 0.660,19.226 0.228,10.039 C-0.203,0.852 3.647,-1.643 11.780,2.552 C19.913,6.747 20.128,11.340 12.427,16.331 Z" fill="#2a4ed0" />
                                                        </svg>
                                                        <span className="ml-2">{emap.value}</span>
                                                    </h4>
                                                    </Col>
                                                </Row>
                                            )
                                    }

                                    <Row>
                                        {
                                            getCustomVideoCategories.getCustomVideoCategories.sort((a,b) => a.order_by - b.order_by).filter((item, indexVal) => item.ExamType == emap.examType)
                                                .map((data, index) => {
                                                    return (
                                                        data.CustomVideoSubCategory != null && data.CustomVideoSubCategory.length > 0 ?
                                                            (
                                                                //data.CustomVideoSubCategory.CustomVideosList != null && data.CustomVideoSubCategory.CustomVideosList.length > 0 ?
                                                                this.checkVideoSubCategoryideosList(data) ?
                                                                    (
                                                                        <Col sm={4} className="">
                                                                            <Link to={{
                                                                                pathname: "/student/category_wise_videos/level-wise-videos",
                                                                                state: {
                                                                                    categoryNameType: data,
                                                                                    mainCategoryName: data.category,
                                                                                    scheduledType : "jee"
                                                                                }
                                                                            }} >


                                                                                <Card className={emap.examType == 1 && index > 3 ? "categoryName mt-4" : "categoryName  mt-4" }>
                                                                                    <Card.Body className="p-3">
                                                                                        <Row>
                                                                                            <Col sm={10}>
                                                                                                <Card.Title className="f-24">{data.category}
                                                                                                    {
                                                                                                        data.category == "Live Classes" ?
                                                                                                            (
                                                                                                                <span className=""><img src={Live_image} alt="New" class="ml-2" width="50" /></span>
                                                                                                            )
                                                                                                            :
                                                                                                            ("")
                                                                                                    }

                                                                                                </Card.Title>
                                                                                                <Card.Text className="categorySubName">
                                                                                                    {data.description}
                                                                                                </Card.Text>
                                                                                            </Col>
                                                                                            <Col sm={2}>
                                                                                                <span className="category-arrow float-right">
                                                                                                    <i class="far fa-arrow-right"></i>
                                                                                                </span>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Card.Body>

                                                                                </Card>



                                                                            </Link>
                                                                        </Col>
                                                                    )
                                                                    :
                                                                    ("")

                                                            )
                                                            :
                                                            ("")
                                                    )
                                                })}

                                    </Row>
                                </React.Fragment>

                            )

                        }
                    })}


                </Container>
            )

        }


    }


}

export default withRouter(compose(
    graphql(FETCH_CUSTOM_VIDEO_CATEGORIES,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam: Cookies.get("examid")

                }
                ,
                fetchPolicy: 'network-only'
            }), name: "getCustomVideoCategories"
        }),graphql(FETCH_SERVICE_DATA_FOR_LIVE_CLASSES_SCHEDULED  , {
            options: props => ({
                variables: {
                    exam_id : parseInt(Cookies.get("examid")),
                    service_type : "schedule_data",
                    service_sub_type: Cookies.get("examid") == 1 ? "neet" : "jee"
                    
                },
                fetchPolicy: 'network-only'
            }),name: "getServiceData"
        }
        )
        )(VideosHomeSection));
