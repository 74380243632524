import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_rapidRevision.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import LiveClassesModal from './LiveClassesModal';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';
import UserRestrictionAlert from "../home/UserRestrictionAlert";
import moment from 'moment';
import * as Cookies from "es-cookie";
import FileText from "../../../images/images1/FileText.png";
import FileArrowDown from "../../../images/images1/FileArrowDown.png";
import Live from "../../../images/images1/Live.png"
// import LockSimple from "../../../images/LockSimple.png"
import { withRouter } from "react-router-dom";

//import Group3172 from "../../../images/images1/Group3172.png";



/// Engg physics
let physicsQuestionData = [
    {
        DATE: "09-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Current Electricity",

        Learn: ("2,101,0"),
        Practice: ("2,101,0"),
        VideoLecture: ("qI8AJOMSsYo"),
        Zoom: ("https://zoom.us/j/98980145720?pwd=akxQTHAyZ2lvSGZaMHJmL3BjMjBPQT09"),
        
    },
    {
        DATE: "10-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Electric Charges and Field",

        Learn: ("2,97,0"),
        Practice: ("2,97,0"),
        VideoLecture: ("d35nyRcinzk"),
        Zoom:("https://zoom.us/j/91613283361?pwd=alNoY1Q1NFBQd1E0TmtGb3F0aVp1dz09"),
    },
    {
        DATE: "11-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Electrostatic Potential and Capacitance",

        Learn: ("2,100,0"),
        Practice: ("2,100,0"),
        VideoLecture: "3ncSVRYW7v0",
        Zoom: ("https://zoom.us/j/96652889433?pwd=Z0E1Nko0azR5WElMOGVobk9TU0d5dz09"),
    },
    {
        DATE: "12-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Semi Conductor Electronics: Material Devices and Simple Circuit",

        Learn: ("2,113,0"),
        Practice: ("2,113,0"),
        VideoLecture: "AlGIP-d7rs8",
        Zoom: ("https://zoom.us/j/97867660522?pwd=cFl4M2pwZ3E2SUluMm1XSzlaYWdoUT09"),
    },
    {
        DATE: "13-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Laws of Motion",

        Learn: ("2,56,0"),
        Practice: ("2,56,0"),
        VideoLecture: "A5pQe0Lyu9I",
        Zoom: ("https://zoom.us/j/93900224728?pwd=cUJEeW1EcUpUWUdINWNMelAxd0VXQT09"),
    },
    {
        DATE: "14-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Work, Energy and Power",

        Learn: ("2,57,0"),
        Practice: ("2,57,0"),
        VideoLecture: "vr8-urU8N-A",
        Zoom: ("https://zoom.us/j/94004457451?pwd=d21KMVNvWWZ4NTNmREY0WEd3U0NCUT09"),
    },
    {
        DATE: "16-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Gravitation",

        Learn: ("2,59,0"),
        Practice: ("2,59,0"),
        VideoLecture: "HdReSsnL1LI",
        Zoom: ("https://zoom.us/j/98948894982?pwd=TkRYdTZLRk0xOE81MFhOZC9hUkI4dz09"),
    }, 
    {
        DATE: "17-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Oscillation",

        Learn: ("2,65,0"),
        Practice: ("2,65,0"),
        VideoLecture: "PV_y3f0BG4Q",
        Zoom: ("https://zoom.us/j/94219407330?pwd=MHp4SHNXNUsrOEdMUFRUc3owS014QT09"),
    },
    /* {
        DATE: "18-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "System Of Particles and Rotational Motion",

        Learn: ("2,58,0"),
        Practice: ("2,58,0"),
        VideoLecture: "sbv8G9b_raE",
        Zoom: ("https://zoom.us/j/99820660957?pwd=aVFjMFV1cU1sZXhUeW9JRUQ1dVlDdz09"),
    },
    {
        DATE: "19-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Mechanical Properties Of Solids",

        Learn: ("2,80,0"),
        Practice: ("2,80,0"),
        VideoLecture: "mBcSpyDhseI",
        Zoom: ("https://zoom.us/j/94841356338?pwd=THFHTGdKd2JHeVN3Nng5aEI3ZCtXUT09"),
    },
    {
        DATE: "20-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Mechanical Properties Of Fluids",

        Learn: ("2,81,0"),
        Practice: ("2,81,0"),
        VideoLecture: "yb5mB70yZDg",
        Zoom: ("https://zoom.us/j/96652366346?pwd=SFhtR1BzWmp6RGpuMFVWMUV2WjRlZz09"),
    },
    {
        DATE: "21-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Thermal Properties of Matter",

        Learn: ("2,62,0"),
        Practice: ("2,62,0"),
        VideoLecture: "RIfW2BLjn8s",
        Zoom: ("https://zoom.us/j/94775099786?pwd=VGYwRnBVSnpBbVhRRUUzSnFlWHFPZz09"),
    }, */
    // {
    //     DATE: "23-08-2021",
    //     ClassTimings: "6:30 PM to 7:30 PM",
    //     time: "18",
    //     Subject: "Physics",
    //     Chapter: "Thermodynamics",

    //     Learn: ("2,63,0"),
    //     Practice: ("2,63,0"),
    //     VideoLecture: "rSGq-D4zyOw",
    //     Zoom: ("https://zoom.us/j/94035821098?pwd=azBxQ2lyQ29mOHRLY1ZtOStxc0lrdz09"),
    // }, 
    {
        DATE: "24-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Waves",

        Learn: ("2,66,0"),
        Practice: ("2,66,0"),
        VideoLecture: "Tzaw1fI1Jbw",
        Zoom: ("https://zoom.us/j/93297261486?pwd=MlpjbVFiMGhHVi9ZVUpSSFk5MWxEdz09"),
    }, 
    {
        DATE: "25-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Ray Optics and Optical Instruments",

        Learn: ("2,108,0"),
        Practice: ("2,108,0"),
        VideoLecture: "47yeclPF_80",
        Zoom: ("https://zoom.us/j/97011670665?pwd=ZzBWdTZLd0M3STZiamdOQ2VzaUxoZz09"),
    },
    {
        DATE: "26-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Wave Optics",

        Learn: ("2,109,0"),
        Practice: ("2,109,0"),
        VideoLecture: "gIZx8qf0cxc",
        Zoom: ("https://zoom.us/j/96111897569?pwd=L25FdVA3d0JVRlZDNkFqQWpyNTJ6dz09"),
    },
    {
        DATE: "27-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Motion In Straight Line",

        Learn: ("2,54,0"),
        Practice: ("2,54,0"),
        VideoLecture: "rGIcHvw13uI",
        Zoom: ("https://zoom.us/j/95259183982?pwd=Y0RFV0VQdVEyZXFTTmw2NDJKSGdKUT09"),
    },  
    {
        DATE: "28-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Motion In Plane",

        Learn: ("2,55,0"),
        Practice: ("2,55,0"),
        VideoLecture: "e7evjWC8Vrk",
        Zoom: ("https://zoom.us/j/97871439176?pwd=MlhKNHNEU2V1ME10MUNEc1JaekQwdz09"),
    },
    {
        DATE: "30-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Units and Measurement",

        Learn: ("2,53,0"),
        Practice: ("2,53,0"),
        VideoLecture: "y-m2A19ye1k",
        Zoom: ("https://zoom.us/j/92526619643?pwd=Y1Z1c1JNNDZTWVBDOE5LcEcyRjJrZz09"),
    }, 
    {
        DATE: "31-08-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Moving Charges and Magnetism & Magnetism and Matter",

        Learn: ("2,102,0"),
        Practice: ("2,102,0"),
        VideoLecture: "zdN_IyQKdsU",
        Zoom: ("https://zoom.us/j/94029527179?pwd=eCttOG5JSjhkdFN0MGo2V2YrK3FHdz09"),
    },
    {
        DATE: "01-09-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Dual Nature Of Matter and Radiation",

        Learn: ("2,110,0"),
        Practice: ("2,110,0"),
        VideoLecture: "qn5pKAN9vpk",
        Zoom: ("https://zoom.us/j/97720386367?pwd=NjJmZ2k2WElpQjhNdW9NYUJETTVjdz09"),
    }, 
    {
        DATE: "02-09-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Electro Magnetic Induction",

        Learn: ("2,104,0"),
        Practice: ("2,104,0"),
        VideoLecture: "1y811HsIEa4",
        Zoom: ("https://zoom.us/j/99956877986?pwd=K0t0cFljZ25RL3BhT1c2UDkrZjgxZz09"),
    },
    {
        DATE: "02-09-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Electro Magnetic Waves",

        Learn: ("2,107,0"),
        Practice: ("2,107,0"),
        VideoLecture: "1y811HsIEa4",
        Zoom: ("https://zoom.us/j/99956877986?pwd=K0t0cFljZ25RL3BhT1c2UDkrZjgxZz09"),
    },
    {
        DATE: "03-09-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Alternating Current",

        Learn: ("2,106,0"),
        Practice: ("2,106,0"),
        VideoLecture: "eXruZaEn-gc",
        Zoom: ("https://zoom.us/j/91652085703?pwd=UnNQenZXSkJGSGE4VFFMYTRsWjBWQT09"),
    },
    {
        DATE: "04-09-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Atoms",

        Learn: ("2,111,0"),
        Practice: ("2,111,0"),
        VideoLecture: "QGvgcRBEIjA",
        Zoom: ("https://zoom.us/j/93490944964?pwd=WDJNalNlUHlpd2ZoTFozckRrc0pmZz09"),
    }, 
    {
        DATE: "04-09-2021",
        ClassTimings: "6:30 PM to 7:30 PM",
        time: "18",
        Subject: "Physics",
        Chapter: "Nucleii",

        Learn: ("2,112,0"),
        Practice: ("2,112,0"),
        VideoLecture: "QGvgcRBEIjA",
        Zoom: ("https://zoom.us/j/93490944964?pwd=WDJNalNlUHlpd2ZoTFozckRrc0pmZz09"),
    },     
];


//Chemistry 
let chemistryQuestionDataEngg = [
    {
        DATE: "09-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Organic Chemistry Some Basic Principles and Techniques",

        Learn: ("3,34,0"),
        Practice: ("3,34,0"),
        VideoLecture: ("4QMzqFC4DRU"),
        Zoom: ("https://zoom.us/j/98598698845?pwd=ZlcyZHNJZ2RVWXZ4dkhlNjkvYmxTUT09"),
    },
    {
        DATE: "10-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Hydrocarbons",

        Learn: ("3,36,0"),
        Practice: ("3,36,0"),
        VideoLecture: ("TUdIdX6Q_t0"),
        Zoom: ("https://zoom.us/j/96896383507?pwd=bm8wdERuNEUvemlrcUx6eHFTOWhxQT09"),
    },
    {
        DATE: "11-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Halo alkanes and Halo Arenes",

        Learn: ("3,46,0"),
        Practice: ("3,46,0"),
        VideoLecture: ("7RFBdX-rD_c"),
        Zoom: ("https://zoom.us/j/93705412716?pwd=NHZiL0EzczVuYStpOTVEOTBWcjdlUT09"),
    },
    {
        DATE: "12-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Alcohols, Phenols and Ethers",

        Learn: ("3,47,0"),
        Practice: ("3,47,0"),
        VideoLecture: ("I5RpPgZJoG0"),
        Zoom: ("https://zoom.us/j/98944927172?pwd=RXpZSHZnNk80bEE0R2psTGFpWGc0dz09"),
    },
    {
        DATE: "13-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Aldehydes, Ketones and Carboxylic Acids",

        Learn: ("3,48,0"),
        Practice: ("3,48,0"),
        VideoLecture: ("SW529H_V9oU"),
        Zoom: ("https://zoom.us/j/98602302524?pwd=eDJmVEp2YTgycVp6c0tNNEp4TFZ2dz09"),
    },
    {
        DATE: "14-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Amines",

        Learn: ("3,49,0"),
        Practice: ("3,49,0"),
        VideoLecture: ("WBEuoPnqb40"),
        Zoom: ("https://zoom.us/j/99782785285?pwd=a3dUNU13dndjeW5ZUU9DZCszeGdPUT09"),
    },
    {
        DATE: "16-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Some Basic Concepts Of Chemistry",

        Learn: ("3,23,0"),
        Practice: ("3,23,0"),
        VideoLecture: ("vFoh5uHqpWk"),
        Zoom: ("https://zoom.us/j/93003871308?pwd=bEdQV1RsQktXd0RoYmdLVGsvQUh6dz09"),
    },
    {
        DATE: "17-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Chemical Bonding",

        Learn: ("3,26,0"),
        Practice: ("3,26,0"),
        VideoLecture: ("DPDAktsVbH0"),
        Zoom: ("https://zoom.us/j/92680758091?pwd=TnVNTXcrQkdKdmI3RWJ4TUwzdFVwdz09"),
    },
    {
        DATE: "18-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Thermodynamics",

        Learn: ("3,28,0"),
        Practice: ("3,28,0"),
        VideoLecture: ("oNaiTsJzO7E"),
        Zoom: ("https://zoom.us/j/91004417902?pwd=TnJJeTFjUFQyWEowRE04Vm1Ncmk5UT09"),
    },
    {
        DATE: "19-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Chemical Equilibrium",

        Learn: ("3,29,0"),
        Practice: ("3,29,0"),
        VideoLecture: ("X_u3Z8Zbrrs"),
        Zoom: ("https://zoom.us/j/92232843065?pwd=cC9KOEI5UlYrRVg0emZhRlZuWXBKUT09"),
    },
    {
        DATE: "20-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Solutions",

        Learn: ("3,37,0"),
        Practice: ("3,37,0"),
        VideoLecture: ("aR3gqpEriYk"),
        Zoom: ("https://zoom.us/j/93094938430?pwd=Z1RKOFJZZnc0OFg1b3QzbVBuREhQdz09"),
    },
    {
        DATE: "21-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Chemical Kinetics",

        Learn: ("3,40,0"),
        Practice: ("3,40,0"),
        VideoLecture: ("PmcRvNlNYp4"),
        Zoom: ("https://zoom.us/j/92061223831?pwd=d3d5WHFveCs1WHAzYkZaMFhRdXF3UT09"),
    },
    {
        DATE: "23-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "States Of Matter",

        Learn: ("3,27,0"),
        Practice: ("3,27,0"),
        VideoLecture: ("qaHanLUUqnU"),
        Zoom: ("https://zoom.us/j/93427902827?pwd=QkhpYjVpUDBNaVUvSnZVSjc5cWdtQT09"),
    },
    {
        DATE: "24-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Solid State",

        Learn: ("3,35,0"),
        Practice: ("3,35,0"),
        VideoLecture: ("1nzbkff8rJA"),
        Zoom: ("https://zoom.us/j/98462546308?pwd=T2R4eEhOTTR2N3F6SERLcWY2Vlc0dz09"),
    },
    {
        DATE: "25-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Ionic Equilibrium",

        Learn: ("3,153,0"),
        Practice: ("3,153,0"),
        VideoLecture: ("mTEirWyyXNg"),
        Zoom: ("https://zoom.us/j/96213411947?pwd=Y1RsVVFNMjZmTCtqUFlmZG1PZWM0Zz09"),
    },
    {
        DATE: "26-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Electro Chemistry",

        Learn: ("3,39,0"),
        Practice: ("3,39,0"),
        VideoLecture: ("sFISaigDuuI"),
        Zoom: ("https://zoom.us/j/97424274114?pwd=VU83eldSTHFNbngzTitOUjNTdloxZz09"),
    },
    {
        DATE: "27-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Co-ordination Compounds",

        Learn: ("3,45,0"),
        Practice: ("3,45,0"),
        VideoLecture: ("icwkBHXaffA"),
        Zoom: ("https://zoom.us/j/95918070024?pwd=L1FFOWF1ZUF5bXJTQlJaM0gzVXJTdz09"),
    },
    {
        DATE: "28-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "The P Block Elements(XI)",

        Learn: ("3,33,0"),
        Practice: ("3,33,0"),
        VideoLecture: ("FfAi9YI5fOE"),
        Zoom: ("https://zoom.us/j/94539530325?pwd=VzRQVzlRVjkxNGUxendrVWs4Zi8yZz09"),
    },
    {
        DATE: "30-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "The P-Block Elements(XII) - 1",

        Learn: ("3,43,0"),
        Practice: ("3,43,0"),
        VideoLecture: ("MCqNcNFXXcM"),
        Zoom: ("https://zoom.us/j/95653887544?pwd=bTFqdlVqeHE5VUkvNCtJREY5bXFvZz09"),
    },
    {
        DATE: "31-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "The P-Block Elements(XII) - 2",

        Learn: ("3,43,0"),
        Practice: ("3,43,0"),
        VideoLecture: ("Tjes0OUa91U"),
        Zoom: ("https://zoom.us/j/91616952641?pwd=YTdsa2N6SFI1ZTdRaFpUL1JTeUZWZz09"),
    },
    {
        DATE: "01-09-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "S-Block Elements",

        Learn: ("3,32,0"),
        Practice: ("3,32,0"),
        VideoLecture: "_NwdGtTROpQ",
        Zoom: ("https://zoom.us/j/92910918755?pwd=ckRlQU4zWkMzSWdEMWE4S0FURnZDUT09"),
    }, 
    {
        DATE: "02-09-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "General Principles and Isolation of Elements",

        Learn: ("3,42,0"),
        Practice: ("3,42,0"),
        VideoLecture: "-fOBtolMY4w",
        Zoom: ("https://zoom.us/j/95201983088?pwd=bEplaXNFb1krRG15S0FiYmIwUUJGQT09"),
    }, 
    {
        DATE: "03-09-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Surface Chemistry",

        Learn: ("3,41,0"),
        Practice: ("3,41,0"),
        VideoLecture: "HVe1nfOFyZw",
        Zoom: ("https://zoom.us/j/91596264740?pwd=RjFXSlQvRExUSWswdkh5NW1COUNRZz09"),
    }, 
    {
        DATE: "04-09-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Environmental Chemistry",

        Learn: ("3,38,0"),
        Practice: ("3,38,0"),
        VideoLecture: "m42Sqf3R1xY",
        Zoom: ("https://zoom.us/j/94598861661?pwd=QmJPY2NZN2FCSW0zckN0OFJJY3VUZz09"),
    }, 
   
];
 
// botany
let botonyQuestionData = [
    {
        DATE: "09-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Molecular Basis of Inheritance - I",

        Learn: ("1,123,0"),
        Practice: ("1,123,0"),
        VideoLecture: ("erTJt-Pj3VU"),
        Zoom: ("https://zoom.us/j/91425239257?pwd=ODVYbEUxNFY2cFlCNlMwVllqSWRlUT09"),
    },
    {
        DATE: "10-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Molecular Basis of Inheritance - II",

        Learn: ("1,123,0"),
        Practice: ("1,123,0"),
        VideoLecture: ("oKSqQDrfcdI"),
        Zoom: ("https://zoom.us/j/97543168806?pwd=RzlkOHljZlVPSkIyd29qaW5zcHR6UT09"),
    },
    {
        DATE: "11-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Principles of Inheritance and variation - I",

        Learn: ("1,122,0"),
        Practice: ("1,122,0"),
        VideoLecture: ("JotU2Kn0xRc"),
        Zoom: ("https://zoom.us/j/92219409876?pwd=a2p3dE9RWFllZGVtWERxQmNMdkw1QT09"),
    },
    {
        DATE: "12-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Principles of Inheritance and variation - II",

        Learn: ("1,122,0"),
        Practice: ("1,122,0"),
        VideoLecture: ("JotU2Kn0xRc"),
        Zoom: ("https://zoom.us/j/94369706242?pwd=OUFzT2NpL29OaEpxQWxZazdsNnNHZz09"),
    },
    {
        DATE: "13-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Biotechnology - Principles and Processes",

        Learn: ("1,126,0"),
        Practice: ("1,126,0"),
        VideoLecture: ("a1XfHcXN98o"),
        Zoom: ("https://zoom.us/j/99227711166?pwd=Rm8xamdXVFVablV3YkxUdmZHRUszZz09"),
    },
    {
        DATE: "14-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Biotechnology and Its Applications",

        Learn: ("1,127,0"),
        Practice: ("1,127,0"),
        VideoLecture: ("pUHhgou2pF8"),
        Zoom: ("https://zoom.us/j/94335448062?pwd=WG4vdHhvTXNCakdtSFhsYy91Ny9MQT09"),
    },
    {
        DATE: "16-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Biomolecules",

        Learn: ("1,7,0"),
        Practice: ("1,7,0"),
        VideoLecture: ("ydiPigjntnc"),
        Zoom: ("https://zoom.us/j/96755071637?pwd=SHM2ditHWGNURmZUUG9wWExIckUvQT09"),
    },
    {
        DATE: "17-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "The living world",

        Learn: ("1,1,0"),
        Practice: ("1,1,0"),
        VideoLecture: ("fgYztolKyoU"),
        Zoom: ("https://zoom.us/j/95933385051?pwd=VTdqalFpMlJRRFdRMGtFR29nMnNQZz09"),
    },
    {
        DATE: "18-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Cell Cycle and Cell Division",

        Learn: ("1,8,0"),
        Practice: ("1,8,0"),
        VideoLecture: ("BKmFEjd-khA"),
        Zoom: ("https://zoom.us/j/94356738588?pwd=dXhOUVFRdUZKSVlLMDZpVHZzMzh6UT09"),
    },
    {
        DATE: "19-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Biological Classification",

        Learn: ("1,2,0"),
        Practice: ("1,2,0"),
        VideoLecture: ("cjO2KpJjwVM"),
        Zoom: ("https://zoom.us/j/95895017162?pwd=Uy9nSXYwYitPTzRxZXNjWVpxazNWUT09"),
    },
    {
        DATE: "20-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Plant Growth and Development",

        Learn: ("1,13,0"),
        Practice: ("1,13,0"),
        VideoLecture: ("2hw_94XhvuY"),
        Zoom: ("https://zoom.us/j/91589214812?pwd=LzhFczhXanpCZHZLaEdBekNNaGNaZz09"),
    },
    {
        DATE: "21-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Photosynthesis in Higher Plants",

        Learn: ("1,11,0"),
        Practice: ("1,11,0"),
        VideoLecture: ("RIslRHRAyKo"),
        Zoom: ("https://zoom.us/j/96642455350?pwd=WWlPL2dMVXRZK0hRMVAwcFc3aUhKdz09"),
    },
    {
        DATE: "23-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Respiration in Plants",

        Learn: ("1,12,0"),
        Practice: ("1,12,0"),
        VideoLecture: ("-q1iFVkaG2A"),
        Zoom: ("https://zoom.us/j/97181290863?pwd=UVUrUzc3NWhTcWNFaTg1MFJYdG0vQT09"),
    },
    {
        DATE: "24-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Sexual Reproduction in Flowering Plants",

        Learn: ("1,121,0"),
        Practice: ("1,121,0"),
        VideoLecture: ("q6KP4qYn5Bo"),
        Zoom: ("https://zoom.us/j/94881931311?pwd=N0dSLzlzaWs0eXBHckM3UGdmdnNyQT09"),
    },
    {
        DATE: "24-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Reproduction in Organism",

        Learn: ("1,120,0"),
        Practice: ("1,120,0"),
        VideoLecture: ("q6KP4qYn5Bo"),
        Zoom: ("https://zoom.us/j/94881931311?pwd=N0dSLzlzaWs0eXBHckM3UGdmdnNyQT09"),
    },
    {
        DATE: "25-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Morphology of flowering plants ",

        Learn: ("1,4,0"),
        Practice: ("1,4,0"),
        VideoLecture: ("uHVj3gDAsEA"),
        Zoom: ("https://zoom.us/j/99315333161?pwd=aXFOVmpZUnNxT1MxV0pZemtxbVI0QT09"),
    },
    {
        DATE: "26-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Microbes in Human Welfare",

        Learn: ("1,125,0"),
        Practice: ("1,125,0"),
        VideoLecture: ("YazUdBfLj3g"),
        Zoom: ("https://zoom.us/j/99636451382?pwd=cnFNcjFDTWRaWGNSaFprcWZTZjFpQT09"),
    },
    {
        DATE: "27-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Anatomy of Flowering plants",

        Learn: ("1,5,0"),
        Practice: ("1,5,0"),
        VideoLecture: ("CP6L04kT23Q"),
        Zoom: ("https://zoom.us/j/96670394469?pwd=d3ZmWUo3MDZMMG53ci9kcy9ISkYrdz09"),
    },
    {
        DATE: "28-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Plant Breeding",

        Learn: ("1,124,0"),
        Practice: ("1,124,0"),
        VideoLecture: ("galLAF_i3cQ"),
        Zoom: ("https://zoom.us/j/94843119757?pwd=Vk5VNXU2cUV4Z3lKbWJWalluNkl4UT09"),
    },
    {
        DATE: "30-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Mineral Nutrition",

        Learn: ("1,10,0"),
        Practice: ("1,10,0"),
        VideoLecture: ("XkuJtRN71ZA"),
        Zoom: ("https://zoom.us/j/94377525883?pwd=TkRVNEI3RUdFb05uaGpXaDdnM1ZhZz09"),
    },
    {
        DATE: "31-08-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "The Unit of life - I",

        Learn: ("1,6,0"),
        Practice: ("1,6,0"),
        VideoLecture: ("DHJ7glLEzX4"),
        Zoom: ("https://zoom.us/j/95561851238?pwd=b2VyUWQ5MlVsMnlOUmZWdHc0STQyUT09"),
    },
    {
        DATE: "01-09-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "The Unit of life -II",

        Learn: ("1,6,0"),
        Practice: ("1,6,0"),
        VideoLecture: "RC2syCCJ3UM",
        Zoom: ("https://zoom.us/j/98448345173?pwd=Yzk2UFBkTUdlQjZlc1lzSmYyZnlhQT09"),
    }, 
    {
        DATE: "02-09-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Plant Kingdom",

        Learn: ("1,3,0"),
        Practice: ("1,3,0"),
        VideoLecture: "QCrK-JpvzPU",
        Zoom: ("https://zoom.us/j/94682208901?pwd=UStWby9acGh3c3VWTnljMFlJQkF2dz09"),
    },
    {
        DATE: "03-09-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Biomolecules",

        Learn: ("1,7,0"),
        Practice: ("1,7,0"),
        VideoLecture: "SlF3gJHGGds",
        Zoom: ("https://zoom.us/j/94523859690?pwd=RVFwcXZnK2VyN240YjQvZUlLK2NiQT09"),
    },
    {
        DATE: "04-09-2021",
        ClassTimings: "4:30 PM to 5:30 PM",
        time: "16",
        Subject: "Botany",
        Chapter: "Transport in plants",

        Learn: ("1,9,0"),
        Practice: ("1,9,0"),
        VideoLecture: "_0-7jiAC5HY",
        Zoom: ("https://zoom.us/j/94060615873?pwd=OHlvSjhlQ3IzaCthYVVRcE5TNWhUZz09"),
    },   
    
];
///Zoology
let zoologyQuestionData = [
    {
        DATE: "09-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Animal kingdom - I",

        Learn: ("5,14,0"),
        Practice: ("5,14,0"),
        VideoLecture: ("xgRdzHo5Hno"),
        Zoom: ("https://zoom.us/j/95280968622?pwd=N0xlQ09PMjlMc0hQZFVXVStzd09Tdz09"),
    },
    {
        DATE: "10-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Structural organization in Animals",

        Learn: ("5,15,0"),
        Practice: ("5,15,0"),
        VideoLecture: ("qYzfUNU9Sxo"),
        Zoom: ("https://zoom.us/j/97813985497?pwd=T25lbWlpQVZwYUlnYkVKMnJ6NGg2dz09"),
        
    },
    {
        DATE: "11-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Human physiology  and digestion",

        Learn: ("5,16,0"),
        Practice: ("5,16,0"),
        VideoLecture: ("s1T7qtjp7E0"),
        Zoom: ("https://zoom.us/j/99457296726?pwd=VUlBNHRPWG90WTg1S1N2S2piSVZqUT09"),
    },
    {
        DATE: "12-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Breathing and exchange of gases",

        Learn: ("5,17,0"),
        Practice: ("5,17,0"),
        VideoLecture: ("QDNS9ggdSbA"),
        Zoom: ("https://zoom.us/j/98354928989?pwd=NHlldk44dHpWL3g1MGNKeFZvWHBIdz09"),
    },
    {
        DATE: "13-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Body fluids and circulation",

        Learn: ("5,18,0"),
        Practice: ("5,18,0"),
        VideoLecture: ("9khPtkqD0wY"),
        Zoom: ("https://zoom.us/j/93241778728?pwd=eWg0M0YxL2RIL2tUMmdaWE1uUEFpQT09"),
    },
    {
        DATE: "14-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Excretory products and their Elimination",

        Learn: ("5,19,0"),
        Practice: ("5,19,0"),
        VideoLecture: ("lI3nPriSDZM"),
        Zoom: ("https://zoom.us/j/96058403836?pwd=M1N0eFF0TWZrbzBZNWlGOERDMkJFZz09"),
    },
    {
        DATE: "16-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Locomotion and Movement",

        Learn: ("5,20,0"),
        Practice: ("5,20,0"),
        VideoLecture: ("BpO8GlGKPQo"),
        Zoom: ("https://zoom.us/j/94483566517?pwd=S2thODhLSFVnMTFxRkV6czlVMGMxZz09"),
    },
    {
        DATE: "17-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Neural Control and Co-ordination",

        Learn: ("5,21,0"),
        Practice: ("5,21.0"),
        VideoLecture: ("vl6tV5QH7RI"),
        Zoom: ("https://zoom.us/j/95530649279?pwd=VjBZdHNkTCtsVFM2YVdGZDk0QlRYdz09"),
    },
    {
        DATE: "18-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Chemical Coordination and Control",

        Learn: ("5,22,0"),
        Practice: ("5,22,0"),
        VideoLecture: ("wd3x0enn7io"),
        Zoom: ("https://zoom.us/j/97930140428?pwd=dS91dHc4VndWNDhMMXk1eGtGaVhYZz09"),
    },
    {
        DATE: "19-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Human Health and Disease ",

        Learn: ("5,128,0"),
        Practice: ("5,128,0"),
        VideoLecture: ("MH61uRZSz2o"),
        Zoom: ("https://zoom.us/j/99039475923?pwd=UGx4amRnQit5b0djNTBEUmplRy9YZz09"),
    },
    {
        DATE: "20-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Biotechnology and Its applications",

        Learn: ("5,131,0"),
        Practice: ("5,131,0"),
        VideoLecture: ("F_JrjgqWA30"),
        Zoom: ("https://zoom.us/j/97173447370?pwd=Zzh5bTFNUjVQMGZhYmYxUmZrSVFKQT09"),
    },
    {
        DATE: "21-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Animal Husbandry",

        Learn: ("5,130,0"),
        Practice: ("5,130,0"),
        VideoLecture: ("CCsujcR0S1M"),
        Zoom: ("https://zoom.us/j/96693845391?pwd=V09vLzUwT1UvY3Qvd0V0KzhPWWdidz09"),
    },
    {
        DATE: "23-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Human physiology - II",

        Learn: ("5,16,0"),
        Practice: ("5,16,0"),
        VideoLecture: ("r7mcvD8vC0A"),
        Zoom: ("https://zoom.us/j/93708017405?pwd=UHVGc090SmtobzdEMmRINTZBQU9nUT09"),
    },
    {
        DATE: "24-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Human Reproduction ",

        Learn: ("5,114,0"),
        Practice: ("5,114,0"),
        VideoLecture: ("ZN8L9vlVL5Q"),
        Zoom: ("https://zoom.us/j/97762102422?pwd=WVpUVUJ5OG5NUW9LL25MQ3Q3aWI1Zz09"),
    },
    {
        DATE: "25-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Reproductive Health",

        Learn: ("5,115,0"),
        Practice: ("5,115,0"),
        VideoLecture: ("1n9aNKnReGM"),
        Zoom: ("https://zoom.us/j/91661924326?pwd=OE4zVDNzOE5EM3Q5OEJ6ckJzM2NMZz09"),
    },
    {
        DATE: "26-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Principles of Inheritance and Variation",

        Learn: ("5,116,0"),
        Practice: ("5,116,0"),
        VideoLecture: ("GPoL9J9FJ7g"),
        Zoom: ("https://zoom.us/j/91974893563?pwd=VS9tTTRCL05VOWYvem02cGorOTRCdz09"),
    },
    {
        DATE: "27-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Evolution-I",

        Learn: ("5,119,0"),
        Practice: ("5,119,0"),
        VideoLecture: ("7hzF6Um_qro"),
        Zoom: ("https://zoom.us/j/92016421469?pwd=dmdjMzBNTTBKbVZtWHpCbXA4L0R4Zz09"),
    },
    {
        DATE: "28-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Evolution - II",

        Learn: ("5,119,0"),
        Practice: ("5,119,0"),
        VideoLecture: ("VKgpgM3nyBM"),
        Zoom: ("https://zoom.us/j/95062720883?pwd=cS9zKzZyTE5zbjdCNlZRVGx6cHRIUT09"),
    },
    {
        DATE: "30-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Eco system",

        Learn: ("5,133,0"),
        Practice: ("5,133,0"),
        VideoLecture: ("IuDJmUKc47A"),
        Zoom: ("https://zoom.us/j/95187768648?pwd=ek1CRDZidSt6OFJEa0cwakd4Y3Mxdz09"),
    },
    {
        DATE: "31-08-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Environmental issues",

        Learn: ("5,135,0"),
        Practice: ("5,135,0"),
        VideoLecture: ("XLxCnaJZDNM"),
        Zoom: ("https://zoom.us/j/99680175480?pwd=RWxENDdNOTI2L1FHM3YrODZ3SFlZQT09"),
    },
    {
        DATE: "01-09-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Biodiversity and conservation",

        Learn: ("5,134,0"),
        Practice: ("5,134,0"),
        VideoLecture: ("z6NOs3wxls4"),
        Zoom: ("https://zoom.us/j/99497866947?pwd=QXhRTU5DVVU5R0ZQeVpmK09aRHB2UT09"),
    },
    {
        DATE: "02-09-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Animal Kingdom ",

        Learn: ("5,14,0"),
        Practice: ("5,14,0"),
        VideoLecture: ("02Wwg4xLsR8"),
        Zoom: ("https://zoom.us/j/93746673978?pwd=bGx3UFQwVStNZzZXcFduclpENCt5UT09"),
    },
    {
        DATE: "03-09-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Organisms and Population",

        Learn: ("5,132,0"),
        Practice: ("5,132,0"),
        VideoLecture: ("NLhqVxV-Zcg"),
        Zoom: ("https://zoom.us/j/93549109464?pwd=ZEN6U2ZXRUlZZzJGaUlCSzhJemFrQT09"),
    },
    {
        DATE: "04-09-2021",
        ClassTimings: "11:00AM to 12:00 PM",
        time: "11",
        Subject: "Zoology",
        Chapter: "Organisms and Population",

        Learn: ("5,132,0"),
        Practice: ("5,132,0"),
        VideoLecture: ("SYlVX_ghba4"),
        Zoom: ("https://zoom.us/j/97393584162?pwd=NC9XeEttNmNvTDUxVEI5Vk9IZFljdz09"),
    },
    
];
let QuestionDataWithOutSort = [];


// QuestionDataWithOutSort.push(...QuestionDataAgri)
QuestionDataWithOutSort.push(...physicsQuestionData)
QuestionDataWithOutSort.push(...botonyQuestionData)
QuestionDataWithOutSort.push(...zoologyQuestionData) 
// // QuestionDataWithOutSort.push(...ChemistryQuestionDataAgri)
QuestionDataWithOutSort.push(...chemistryQuestionDataEngg) 

const sortedActivities = QuestionDataWithOutSort.sort ( function (a, b){
    let date1 = moment(a.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
    let date2 = moment(b.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
    
   if (a.DATE === b.DATE) {
    return a.time - b.time;
}
   return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
});

let count = 0;
var QuestionData = sortedActivities.map((el,index) => {
    var o = Object.assign({}, el);
    if(el.Assignments !="" || el.Solutions !="" ){
        count++;
         o.indexvalue = count
         
    }
    return o;
  })

let LiveClassesData = []

    for(var i in QuestionData){
        let currentDate = moment().format("DD/MM/YYYY");
        if ((QuestionData[i]["VideoLecture"] || QuestionData[i]["Zoom"]) && (currentDate ==  moment(QuestionData[i].DATE,"DD-MM-YYYY").format("DD/MM/YYYY"))) {
            LiveClassesData.push(QuestionData[i]);
          }
    }
	
var Zoom;
var Livevideo;


class AllSubjects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            userRestionModalShow: false,
            dataLiveUrl: "",
			allLiveClasses:true
        }
        
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));

        }
        //console.log("local",localStorage)
        console.log("planid",this.isStudentUserValid.current_plan_id)
    }


    modalaFun = (row) => {

        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });

    }
    
    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }
    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        //console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    sortFuncDate(a, b, order, dataField, rowA, rowB)  { 
        if (order == 'desc') {
            
            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY")  > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY")  > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }
    getAccessPlans = (planId)  => {
        const epriryDateInunixTime = this.isStudentUserValid.expiry_date;
        const currentDateInUnixTime = parseInt((new Date().getTime() / 1000).toFixed(0))

        // console.log("exU",epriryDateInunixTime)
        // console.log("cU",currentDateInUnixTime)
        // console.log("eeeeee",this.isStudentUserValid)
        if(planId == 5){
            return true; //restrict
        }else{

            if(currentDateInUnixTime > epriryDateInunixTime){
                return true; 
            }
        }

        return false
    }
    learnFormateAction = (row) => {
        
        let practiceIds = row.split(',');
        //console.log("practiceIds",practiceIds)
        this.props.history.push({
            pathname: "/student/subject/start-learning",
            state: {
              subjectid: practiceIds[0],
              ocid: practiceIds[1],
              otid: practiceIds[2]
            }
          }
        )
    }
    actionFormatterLearn(cell, row, rowIndex){
        return (
            <>
            {
                row.Learn != "" ?  (row.indexvalue > 12 && this.getAccessPlans(this.isStudentUserValid.current_plan_id) ) ? 
                
                    (<div className="learnTextLock">
                        <a onClick={() => this.modalFunAssignments()}> <span>Learn Chapter</span></a>
                    </div>) :
                    (<div className="learnText">
                        <a onClick={() => this.learnFormateAction(row.Learn)} target="_blank"> Learn Chapter</a>
                    </div>) : <h6 className="text-center">-</h6>
            }
            </>
        )
    }
    practiceFormateAction = (row) => {
        
        let practiceIds = row.split(',');
        //console.log("practiceIds",practiceIds)
        localStorage.setItem("subjectid", practiceIds[0]);
        localStorage.setItem("type", "practice");
        //localStorage.setItem("ocid", "0");
        localStorage.setItem("ocid", practiceIds[1]);
        localStorage.setItem("otid", practiceIds[2]);
        window.open("/student/subject/practice-test", "_blank") //to open new page
    }
    actionFormatterPractice(cell, row, rowIndex){
        return (
            <>
            {
                row.Practice != "" ?  (row.indexvalue > 12 && this.getAccessPlans(this.isStudentUserValid.current_plan_id) ) ? 
                
                    (<div className="practiceTextLock">
                        <a onClick={() => this.modalFunAssignments()}> <span>Practice Here</span></a>
                    </div>) :
                    (<div className="practiceText">
                        <a   onClick={() => this.practiceFormateAction(row.Practice)}> Practice Here </a>
                    </div>) : <h6 className="text-center">-</h6>
            }
            </>
        )
    }
    actionFormatterVideoLecture(cell, row, rowIndex) {
        let currentDate = moment().format("DD-MM-YYYY");
        let currentMonth = moment().format('MM')
        let currentRowMonth = moment(row.DATE,"DD-MM-YYYY").format("MM") 
        let currentDay = moment().format('DD')
        let currentRowDay = moment(row.DATE,"DD-MM-YYYY").format("DD") 
        console.log("currentMonth",currentMonth)
        console.log("rowMonth",currentRowMonth)
        
        return (

            <>
                <div className="text-center">
                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
{/* 
                    {
                      currentMonth == currentRowMonth   ? 
                        (
                            currentDate == row.DATE ? 
                        (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) :
                        (
                            row.DATE < currentDate  ? 
                            :
                            
                            (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                        )
                        ) 
                        :
                        (

                        currentDate == row.DATE ? 
                        (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) :
                        (
                            row.DATE > currentDate  ? 
                            (<button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>) 
                            :
                            
                            (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                        )
                        
                        )

                        
                    } */}
                </div>
            </>

            // <>
            //     <div className="text-center">
            //         {
            //             row.Zoom == "" ?

            //                 row.VideoLecture != "" ?
            //                     (<button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>)
                            
            //                     : "-"


            //                 :
            //                 <>
                            
            //                     {
            //                         currentDate == row.DATE ? 
            //                         (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) 
            //                         :
            //                         (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                                    
            //                     }
                                



            //                 </>
            //         }

            //     </div>

            // </>
        )
    }

    colFunction() {

        let columns = [
            {
                dataField: "DATE",
                text: "Date",
                sort: true,
                headerStyle: {
                    width: "12%"
                },
                style: {
                    fontWeight: "bold !important"
                },
                sortFunc: this.sortFuncDate.bind(this)
            },
            {
                dataField: "ClassTimings",
                text: "Class Timings",
                sort: true,
                headerStyle: {
                    width: "22%"
                },
                sortFunc: this.sortFuncMulti.bind(this)
            },

            {
                dataField: "Subject",
                text: "Subject",
                style: (row, rowIndex) => {
                    return { fontWeight: "bold !important" };
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                width: "25%"
                }
            },
             {
                dataField: "Learn",
                text: "Learn",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterLearn.bind(this)
            },
            {
                dataField: "Practice",
                text: "Practice",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterPractice.bind(this)
            }, 
           
            {
                dataField: "VideoLecture",
                text: "Video Lecture",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterVideoLecture.bind(this),
                
            },
            
        ];
        return columns;
    }

    render() {
        console.log("cond",Cookies.get("studenttoken"))
        return (
            <Row>
                <Col align="right" sm={12}>
                    <h6 className="switch_button_Rapid_cls mt-res-4">
                        <p className="allClassesAlignment">All Classes</p>
                        <label class="switch">
                            <input type="checkbox" 
                                onChange={() => {
                                    
                                    this.setState((state) => {
                                        
                                        return {
                                            allLiveClasses: !state.allLiveClasses
                                        }
                                    })
                                }}
                                checked={!this.state.allLiveClasses} />
                            <span class="slider round"></span>
                        </label>
                        <p className="liveClassesAlignment">Live</p>
                    </h6>
                </Col>
                <Col sm={12}>
                {
                    this.state.allLiveClasses ?
                    <CardLessDataTableWithSearch 
                    parentData={QuestionData}
                    particlecolumns={this.colFunction()}
                    defaultSorted={[{
                        dataField: "ClassTimings",
                        order: "asc"
                    }]}
                    />
                    :
                    <CardLessDataTableWithSearch 
                    parentData={LiveClassesData}
                    particlecolumns={this.colFunction()}
                    defaultSorted={[{
                        dataField: "ClassTimings",
                        order: "asc"
                    }]}
                    />
                }
                    {/* <Card>
                        <BootstrapTable
                            keyField="id"
                            columns={this.colFunction()}
                            data={QuestionData}
                            defaultSorted={[{
                                dataField: "ClassTimings",
                                order: "asc"
                            }]}
                            wrapperClasses="table-responsive"
                            bootstrap4

                        />
                    </Card> */}

                    <LiveClassesModal data={this.state.dataLiveUrl} show={this.state.liveModalShow} onHide={() => this.setState({ liveModalShow: false })} />
                    <UserRestrictionAlert
                        show={this.state.userRestionModalShow}
                        onHide={() => this.setState({ userRestionModalShow: false })}
                    />
                </Col>
            </Row>

        )
    }

}
export default  withRouter(AllSubjects);