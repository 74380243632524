import React, { Component } from 'react'
import { Row, Col, Table, Image, Button, Container, Accordion, Card, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import { components } from 'react-select'
import * as Cookies from "es-cookie";
import moment from 'moment';


class ClearModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Modal {...this.props} className='p-3'
                size="md" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton className='p-2'>
                    <Modal.Title className='refer-title'></Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-popup'>
                <Row>
                    <Col  sm={12} className="text-center">
                        {this.props.spinnerClear == '1' ? (<div class="spinner-border text-primary"></div>) : ("")}
                    </Col>
                    <Col sm={12} class="text-center">
                        <h6 className="text-center mb-4">Are you sure you want to Clear</h6>
                        <div className="d-flex flex-row justify-content-center">
                            <Button className="btn btn-danger text-uppercase px-lg-5 ml-1" onClick={this.props.clearAll}>yes</Button>
                            <Button className="btn btn-success text-uppercase px-lg-5 ml-1" onClick={this.props.onHide}>No</Button>
                        </div>
                    </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}

export default ClearModal
