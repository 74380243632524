import React, { Component } from 'react'
import { Container, Row, Col, Card, Image, Button, Media } from 'react-bootstrap';
import './SubPlansSection.scss'
import { withRouter, Link } from "react-router-dom";
import * as Cookies from "es-cookie";
import moment from "moment";



class SubPlansSection extends Component {
    constructor(props) {
        super(props)


        this.state = {
            planDetails: '',
            selectedPlanId: '',
        }
    }


    getSubPlanDetails = (planDetails, planNameActiveCls) => {
        this.setState(state => ({ ...state, planDetails: planDetails }));
        this.state.selectedPlanId = planNameActiveCls
    }


    paymentFun = (data) => {
        console.log("data", data)
        this.props.history.push({
            pathname: "/student/subscribe-plans-order-summary",
            state: {
                data: data

            }
        })
    }

    render() {
        const getSubscriptionPlans = this.props.getSubscriptionPlans;

        let data = [];
        if (getSubscriptionPlans != undefined) {
            data = getSubscriptionPlans.map((item) => {
                let studentPlans = []
                if (item.id == 12) {
                    item.studentPlans.map((item1) => {
                        studentPlans.push(item1)
                    })
                    let value = {
                        additional_text: "upto JEE 2023 exam", amount: 99, color: "#006c81", coupon_display: true, exam_id: "2", expiry_date: "", id: "79", image: "", original_amount: 99, plan_features: "Find the likelihood of acceptance into your dream college#Know your college admission chances based on your category, sub category and exam result.", plan_name: "College & Branch Predictor", plan_title: "1 Year", popup_plan_desc: "Your one stop for predicting the best colleges and branches for you", sort_order: 18, upgrade_access: false, upgrade_amount: 99, upgrade_description: "", url: "https://youtu.be/qRTjqv97gDs", valid_days: 90
                    }
                    studentPlans.push(value)
                } else {
                    item.studentPlans.map((item1) => {
                        studentPlans.push(item1)
                    })
                }
                return {
                    ...item,
                    studentPlans
                }
            });
        }

        if (this.state.planDetails == "") {
            if (this.props.history.location.state == undefined || this.props.history.location.state.activePlan == undefined) {
                this.state.selectedPlanId = 1;
            } else {
                this.state.selectedPlanId = this.props.history.location.state.activePlan;
            }

            if (data != undefined) {
                data.map((defaultPlan, index) => {
                    if (defaultPlan.id == this.state.selectedPlanId) {
                        this.state.planDetails = defaultPlan;
                    }
                });
            }
        }



        return (

            <Container fluid className="plan-group-package">
                <Row>
                    <Col sm={12} className="mt-4">
                        <h3 className="font-weight-bold">Learning Plans</h3>
                        <p className="">Choose your plan to continue learning with Rizee</p>
                    </Col>
                </Row>

                <Row>
                    {
                        data && data.map((planDetails) => {
                            console.log("planDetails", planDetails)
                            return (
                                <Col sm={6} className={this.state.selectedPlanId == planDetails.id ? "active_icon mt-4" : "mt-4"} onClick={() => this.getSubPlanDetails(planDetails, planDetails.id)}>
                                    <Card className={this.state.selectedPlanId == planDetails.id ? "white_block active" : "white_block"}>
                                        <Card.Body>
                                            <div className="d-flex align-items-center mt-2">
                                                <Card.Subtitle>
                                                    <Image src={planDetails.image_path}></Image>
                                                </Card.Subtitle>
                                                <Card.Title className="ml-2">
                                                    <h5>{planDetails.name}</h5>
                                                    <p className="f-14 color_yash text-capitalize">{planDetails.description}</p>
                                                </Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>


                <Row>
                    {this.state?.planDetails && this.state.planDetails.studentPlans.map(row => {
                        console.log("aaaaa", row)
                        let colorname = "";
                        if (row.plan_title == "3 MONTHS") {
                            colorname = "#c46f01";
                        }
                        else if (row.plan_title == "6 MONTHS") {
                            colorname = "#002e7e";
                        }
                        else if (row.plan_title == "1 YEAR") {
                            colorname = "#006b7e";
                        }
                        else if (row.plan_title == "12 MONTHS") {
                            colorname = "#006b7e";
                        }
                        else if (row.plan_title == "1 MONTH") {
                            colorname = "#08946a";
                        } else if (row.id == 27) {
                            colorname = "#08946a";
                        } else if (row.id == 25) {
                            colorname = "#c46f01";
                        } else if (row.id == 36) {
                            colorname = "#08946a";
                        } else if (row.id == 37) {
                            colorname = "#c46f01";
                        } else {
                            colorname = "#c46f01";
                        }

                        let features = row.plan_features.split("#");
                        let currentime = moment().unix();

                        let expDate = row.expiry_date
                        const days = Math.floor(parseInt(expDate - currentime) / (24 * 60 * 60));
                        console.log("daysssss", Math.sign(days))



                        //if (row.id != "5") {

                        return (
                            <Col xl={3} lg={6} md={6} sm={12} className="mt-4" >

                                <Card className={`white_block_plan_details card d-flex ${row.plan_title}`}>
                                    <Card.Body className="card-body padding_1">

                                        <div className="plan-subtitles text-center">
                                            {
                                                row.id == 27 || row.id == 25 || row.id == 36 || row.id == 37 ?
                                                    ("") :
                                                    (<h6 className={`text-capitalize font-weight-bold`} style={{ color: `${colorname}` }}>{row.plan_title}</h6>)
                                            }

                                            {
                                                row.id == 27 || row.id == 25 || row.id == 36 || row.id == 37 ?
                                                    (<h6 className="mt-2 font-weight-bold" style={{ color: `${colorname}` }}>{row.plan_name}</h6>) :
                                                    (<h6 className="mt-2 f-14">{row.plan_name}</h6>)
                                            }


                                            <h5 className="price_new_design" style={{ border: `1px solid ${colorname}`, color: `${colorname}` }}>
                                                {
                                                    row.original_amount == row.amount ?

                                                        (<span className=""><i class="fas fa-rupee-sign f-16"></i> {row.amount}/-</span>)
                                                        :
                                                        (
                                                            <>
                                                                <span class="color_strike font-weight-normal"><i class="fas fa-rupee-sign f-16"></i> {row.original_amount}</span>
                                                                <span className="ml-2"><i class="fas fa-rupee-sign f-16"></i> {row.amount}/-</span>
                                                            </>
                                                        )
                                                }

                                            </h5>
                                        </div>


                                        {row.plan_features != "" ? (
                                            <ul className={row.id == 25 ? "mb-3 list-inline list_custom_styles_align" : "mb-3 list-inline list_custom_styles"}>
                                                {
                                                    features.map((item1, index) => {
                                                        return (
                                                            <li key={index} className="mt-3">
                                                                <div className="ml-2">{item1}</div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        ) : ("")}

                                        {/* {<div className="coupons mt-2 text-center">
                                                    {row.referral_text == "" ? (<p>Use Coupons Code <strong>{row.referral_text}</strong></p>) : ("")}
                                                    {row.referral_subtext == "" ? (<h5 className="text-danger">{row.referral_subtext}</h5>) : ("")}

                                                </div>} */}

                                        <div className='text-center'>
                                            {
                                                row.upgrade_access == true ?
                                                    (<Button onClick={() => this.paymentFun(row)} className={`btn-block ${row.plan_title} f-14`} style={{ background: `${colorname}`, borderColor: "transparent" }}><i class="fas fa-arrow-right" ></i>Upgrade your plan with {row.upgrade_amount + " /-"}</Button>)
                                                    :
                                                    (
                                                        <>
                                                            {
                                                                currentime < row.expiry_date && row.expiry_date != "" ?
                                                                    (<Button className={`btn-block ${row.plan_title}`} style={{ background: `${colorname}`, borderColor: "transparent" }}><i class="fas fa-arrow-right" ></i> Current Plan</Button>)

                                                                    :
                                                                    (<Button onClick={() => this.paymentFun(row)} className={`btn-block ${row.plan_title}`} style={{ background: `${colorname}`, borderColor: "transparent" }}><i class="fas fa-arrow-right" ></i> Subscribe Now</Button>)
                                                            }
                                                            {
                                                                row.expiry_date != "" ?
                                                                    (
                                                                        <>
                                                                            {
                                                                                (Math.sign(days) == 1) ?
                                                                                    (<p className="mt-3" style={{ color: "#ff5200", fontWeight: "bold", fontSize: 13 }}>This package will expire in {days} days</p>)
                                                                                    :
                                                                                    Math.sign(days) == -1 ?
                                                                                        (<p className="mt-3" style={{ color: "#ff5200", fontWeight: "bold", fontSize: 13 }}>This package is expired</p>)
                                                                                        :
                                                                                        (<p className="mt-3" style={{ color: "#ff5200", fontWeight: "bold", fontSize: 13 }}>This package is expired</p>)
                                                                            }
                                                                        </>
                                                                    )
                                                                    :
                                                                    ("")
                                                            }
                                                        </>
                                                    )
                                            }

                                        </div>

                                        {<div className="coupons mt-2 text-center">
                                            {row.additional_text != "" ? (<p> {row.additional_text}</p>) : ("")}

                                        </div>}


                                    </Card.Body>
                                </Card>

                            </Col>
                        )
                        //}
                    })}
                </Row>
            </Container>
        )
    }
}
export default withRouter(SubPlansSection);
