import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import Pdf from '../short_notes/pdf'
import { Row, Col,Button } from 'react-bootstrap';

class DetailedPdfTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            url1: "",
            url2: "",
            url3: "",
            url4: ""
        }
    }

    render() {
        return (
            <Col>
                {/*<Button variant="outline-secondary mr-2 d-flex align-items-center float-right" onClick={this.props.history.goBack}> <i className="fas fa-long-arrow-alt-left" /> <span className="ml-2 d-none d-md-block">Back</span></Button>*/}
            
                    
                        <div>{
                        this.props.showPdf?
                        (<React.Fragment>
                            <Pdf file={this.props.pdfUrl} />
                        </React.Fragment>):('')
                        }
                        </div>
                    </Col>
        )

    }
}
export default DetailedPdfTable;