import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Image, Navbar, Dropdown, Form, FormControl, Button, Container, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Cookies from "es-cookie";
import CardLessDataTableWithSearch from '../../data_tables/CardLessDataTableWithSearch';
import UserRestrictionAlert from "../../home/UserRestrictionAlert";
import moment from 'moment';
import { withRouter } from "react-router-dom";
import SelectDropDown from '../../../../neetjee_guru/components/selectdropdown/SelectDropDown';
import { components } from 'react-select';
import WatchNowVideosModal from './WatchNowVideosModal';
import '../../Neet_Rise_Course/_repeatersRevision.scss';
import LockSimple from "../../../../images/locked.png";
import Book from "../../../../images/book-spells.png"
import BookAlt from "../../../../images/book-alt.png"
import BookOpen from "../../../../images/book-open.png"
import ChalkBoardTeacher from "../../../../images/chalkboard-teacher.png"
import File from "../../../../images/file-alt.png"
const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <i className="fal fa-angle-down" />
            </components.DropdownIndicator>
        )
    );
};
class CrashCourseLiveClassesSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            dataLiveUrl: "",
            userRestionModalShow: false,
            allLiveClasses: "all",
            weekStartDate: "",
            weekEndDate: "",
            currentWeekSelected: 0,
            CoursesMainData: [],
            LiveClassesCoursesData: [],
            defaultLiveClassStored: "",
            accessCourseModules: props.getCourseModuleAccess?.getCourseModuleAccess,
            getAccessSubjectNames: []
        }

        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));

        }
    }

    handleSelect = (val) => {
        console.log(val)
        this.setState({
            allLiveClasses: val
        });
    }

    modalaFun = (row) => {
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });

    }
    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }
    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    sortFuncDate(a, b, order, dataField, rowA, rowB) {
        if (order == 'desc') {

            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }

    getAccessPlans = (planId) => {
        const epriryDateInunixTime = this.isStudentUserValid.expiry_date;
        const currentDateInUnixTime = parseInt((new Date().getTime() / 1000).toFixed(0))

        console.log("exU", epriryDateInunixTime)
        console.log("cU", currentDateInUnixTime)
        console.log("eeeeee", planId)

        if (planId == 36) {
            if (currentDateInUnixTime > epriryDateInunixTime) {
                return true;
            }
        } else {
            return true;
        }
        return false;

        /* if(currentDateInUnixTime > epriryDateInunixTime){
             return true; 
         }else{
             return false; 
         }*/

    }


    learnFormateAction = (row) => {

        let practiceIds = row.split(',');
        console.log("practiceIds", practiceIds)
        this.props.history.push({
            pathname: "/student/subject/start-learning",
            state: {
                subjectid: practiceIds[0],
                ocid: practiceIds[1],
                otid: practiceIds[2]
            }
        }
        )
    }
    actionFormatterLearn(cell, row, rowIndex) {
        let currentDate = moment().format("DD-MM-YYYY");
        let currentMonth = moment().format('MM')
        let currentRowMonth = moment(row.DATE, "DD-MM-YYYY").format("MM")
        console.log("currentMonth", currentMonth)
        console.log("rowMonth", currentRowMonth)
        return (
            <>
                {
                    this.getAccessPlans(this.isStudentUserValid.current_plan_id) && row.courseAccess != "true" && row.freeAccess != "true" ?
                        (
                            <>
                                <a onClick={() => this.modalFunAssignments()} className="">
                                    <h6 className="learn_lock_text"> Learn Here <Image src={Book} alt="book" className="icons ml-3"></Image></h6>
                                    <h6 className="practise_lock_text mt-3 ">Practice Here <Image src={BookOpen} alt="bookopen" className="icons"></Image></h6>
                                </a>
                            </>
                        )
                        :

                        (
                            <>
                                {
                                    row.Learn != "" ?
                                        (
                                            <div className="learnText_R">
                                                <a onClick={() => this.learnFormateAction(row.Learn)} target="_blank"> Learn Here <Image src={Book} alt="book" className="icons ml-3"></Image></a>
                                            </div>
                                        ) : (<h6 className="text-center">-</h6>)
                                }

                                {
                                    row.Practice != "" ?
                                        (
                                            <div className="practiceText_R mt-3">
                                                <a onClick={() => this.practiceFormateAction(row.Learn)} target="_blank"> Practice Here <Image src={BookOpen} alt="bookopen" className="icons"></Image></a>
                                            </div>
                                        ) : (<h6 className="text-center">-</h6>)
                                }
                            </>

                        )

                }
            </>
        )
    }
    practiceFormateAction = (row) => {

        let practiceIds = row.split(',');
        //console.log("practiceIds",practiceIds)
        localStorage.setItem("subjectid", practiceIds[0]);
        localStorage.setItem("type", "practice");
        //localStorage.setItem("ocid", "0");
        localStorage.setItem("ocid", practiceIds[1]);
        localStorage.setItem("otid", practiceIds[2]);
        window.open("/student/subject/practice-test", "_blank") //to open new page
    }

    actionFormatterAssignMentSolution(cell, row, rowIndex) {
        let currentDate = moment().format("DD-MM-YYYY");
        let currentMonth = moment().format('MM')
        let currentRowMonth = moment(row.DATE, "DD-MM-YYYY").format("MM")
        console.log("currentMonth", currentMonth)
        console.log("rowMonth", currentRowMonth)

        return (
            <>
                {
                    this.getAccessPlans(this.isStudentUserValid.current_plan_id) && row.courseAccess != "true" && row.freeAccess != "true" ?
                        (
                            <>
                                <a onClick={() => this.modalFunAssignments()} className="">
                                    <h6 className="assignment_lock_text">Assignment  <Image src={BookAlt} alt="BookAlt" className="icons"></Image></h6>
                                    <h6 className="practise_lock_text mt-2">Solutions<Image src={ChalkBoardTeacher} alt="ChalkBoardTeacher" className="icons ml-2"></Image></h6>
                                    <h6 className="ClassNotesText_lock mt-2">Class Notes <Image src={File} alt="File" className="icons"></Image></h6>
                                </a>
                            </>
                        )
                        :
                        (
                            <>
                                {
                                    ((row.Assignment !== undefined && row.Assignment !== "") || (row.Solution !== undefined && row.Solution !== "") || (row.Ppt !== undefined && row.Ppt !== ""))
                                        ?
                                        (
                                            <>
                                                {
                                                    row.Assignment !== undefined && row.Assignment !== "" ?
                                                        (
                                                            <div className="AssignmentText_R">
                                                                <a href={row.Assignment} target="_blank"> Assignment <Image src={BookAlt} alt="BookAlt" className="icons"></Image></a>
                                                            </div>
                                                        ) : (<h6 className="text-center">-</h6>)
                                                }

                                                {
                                                    row.Solution !== undefined && row.Solution !== "" ?
                                                        (
                                                            <div className="SolutionText_R mt-3">
                                                                <a href={row.Solution} target="_blank"> Solutions <Image src={ChalkBoardTeacher} alt="ChalkBoardTeacher" className="icons"></Image></a>
                                                            </div>
                                                        ) : (<h6 className="text-center">-</h6>)
                                                }
                                                {
                                                    row.Ppt !== undefined && row.Ppt !== "" ?
                                                        (
                                                            <div className="ClassNotesText_R mt-3">
                                                                <a href={row.Ppt} target="_blank"> Class Notes <Image src={File} alt="File" className="icons"></Image></a>
                                                            </div>
                                                        ) : ("")
                                                }
                                            </>
                                        )
                                        :
                                        (<h6 className="text-center">-</h6>)
                                }

                            </>

                        )

                }
            </>
        )

    }
    /*actionFormatterVideoLecture(cell, row, rowIndex) {
        let currentDate = moment().format("DD-MM-YYYY");
        let currentMonth = moment().format('MM')
        let currentRowMonth = moment(row.DATE,"DD-MM-YYYY").format("MM") 
        console.log("currentMonth",currentMonth)
        console.log("rowMonth",currentRowMonth)
        let currentTime = moment().add(2, 'm').format('mm');
        let currentTime111 = moment(row.DATE,"DD-MM-YYYY mm").format('mm');

        let date1 = moment(row.ClassTimings, "h:mm").format("h:mm");
        let date2 = moment(date1,"h:mm").add(100, 'm').format("h:mm");
        let date3 = moment().format("h:mm");
        console.log("currentTime",date1)   
        console.log("currentTime11",date2)
        console.log("currentTime13",date3)

        return (

            <>
                <div className="text-center">
                    {
                        this.getAccessPlans(this.isStudentUserValid.current_plan_id) && row.freeAccess != "true" && row.freeAccess != "true" ?
                        (
                            <a onClick={() => this.modalFunAssignments()} className="">
                                <button type="button" className="btn subscribe-button">Subscribe&nbsp;<i class="fas fa-lock display_block_align"></i></button>
                            </a>
                        )
                        :
                        (
                            currentMonth == currentRowMonth ? 
                        (
                            currentDate == row.DATE ? 
                        (  
                            <a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect effect-1">Live Now</button></a>
                            
                        ) 
                        :
                        (
                            row.DATE < currentDate ? 
                            (
                                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
                                
                            ) 
                            :
                            
                            (<button type="button" className="btn btn-custom-disabled-cls effect" disabled>Live Now</button>)
                        )
                        ) 
                        :
                        (
                            <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
                        )
                        )
                         

                        
                    }
                </div>
            </>
           
        )
    }*/

    actionFormatterVideoLecture(cell, row, rowIndex) {
        let timing = moment(row.ClassTimings, "DD-MM-YYYY").format("MM")
        console.log("Timings", row.ClassTimings);
        let currentDate = moment().format("DD-MM-YYYY");
        let currentMonth = moment().format('MM')
        let currentYear = moment().format('YYYY')
        let currentRowMonth = moment(row.DATE, "DD-MM-YYYY").format("MM")
        let currentRowYear = moment(row.DATE, "DD-MM-YYYY").format("YYYY")
        console.log("currentMonth", currentMonth)
        console.log("rowMonth", currentRowMonth)
        let currentTime = moment().add(2, 'm').format('mm');
        let currentTime111 = moment(row.DATE, "DD-MM-YYYY mm").format('mm');

        let date1 = moment(row.time, "h:mm").format("H:mm");
        let date2 = moment(date1, "h:mm").add(100, 'm').format("H:mm");
        let date3 = moment().format("H:mm");
        let date4 = moment(date2, " HH:mm").diff(moment(date3, " HH:mm"));

        console.log("currentTime", date1)
        console.log("currentTime11", date2, date3)
        console.log("currentTime13", date3)

        return (

            <>
                <div className="text-center">
                    {
                        this.getAccessPlans(this.isStudentUserValid.current_plan_id) && row.courseAccess != "true" && row.freeAccess != "true" ?
                            (
                                <a onClick={() => this.modalFunAssignments()} className="">
                                    <button type="button" className="btn subscribe-button">Subscribe</button>
                                </a>
                            )
                            :
                            (currentRowYear == currentYear ?
                                (
                                    currentMonth == currentRowMonth ?
                                        (
                                            currentDate == row.DATE && date4 > 0 ?

                                                (
                                                    <a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect effect-1">Live Now</button></a>

                                                )
                                                :
                                                (
                                                    currentDate < row.DATE ?

                                                        (
                                                            currentDate == row.DATE && date4 < 0 ? (
                                                                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                                            ) : (
                                                                <button type="button" className="btn btn-custom-disabled-cls effect" disabled>Live Now</button>
                                                            )

                                                        ) :

                                                        (
                                                            <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                                        )
                                                )

                                        )
                                        :
                                        (
                                            currentMonth < currentRowMonth ? (
                                                <button type="button" className="btn btn-custom-disabled-cls effect" disabled>Live Now</button>

                                            ) : (
                                                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
                                            ))
                                )
                                :
                                (
                                    <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>
                                    // <button type="button" className="btn btn-custom-disabled-cls effect" disabled>Live Now</button>
                                )
                            )
                    }
                </div>
            </>

        )
    }

    colFunction() {

        let columns = [
            {
                dataField: "DATE",
                text: "Date",
                sort: true,
                headerStyle: {
                    width: "15%"
                },
                style: {
                    fontWeight: "bold !important"
                },
                sortFunc: this.sortFuncDate.bind(this)
            },
            {
                dataField: "ClassTimings",
                text: "Class Timings",
                sort: true,
                headerStyle: {
                    width: "20%"
                },
                sortFunc: this.sortFuncMulti.bind(this)
            },

            {
                dataField: "Subject",
                text: "Subject",
                style: (row, rowIndex) => {
                    return { fontWeight: "bold !important" };
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                    width: "21%"
                }
            },
            /*{
                dataField: "Topics",
                text: "Topic",
                headerStyle: {
                width: "25%"
                }
                //formatter: this.actionFormatterTopic.bind(this)
            },*/
            {
                dataField: "Learn/Practice",
                text: "Learn/Practice",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterLearn.bind(this)
            },
            {
                dataField: "Assignment/Solutions",
                text: "Assignment/Solution",
                headerStyle: {
                    width: "18%"
                },
                formatter: this.actionFormatterAssignMentSolution.bind(this)
            },

            {
                dataField: "VideoLecture",
                text: "Video Lecture",
                headerStyle: {
                    width: "25%"
                },
                formatter: this.actionFormatterVideoLecture.bind(this),

            },

        ];
        return columns;
    }
    selecthandleInputChange = (ename, evalue) => {
        this.setState({ defaultLiveClassStored: "onloadValuesStored" });
        console.log("selecthandleInputChange", evalue);
        this.setState({ LiveClassesCoursesData: [] });
        const repeatersClassesDetails = this.props.repeatersClassesDetails;
        let currentDate = moment().format("DD/MM/YYYY");



        repeatersClassesDetails.getServiceData.map((mainData, mainindex) => {
            mainData.data.map((item, index) => {
                if (index == evalue) {
                    let coursesDetails = item.COURSES.map((i, index) => {
                        var o = Object.assign({}, i);
                        this.state.getAccessSubjectNames.map((e2, index) => {
                            if (i.Subject == e2) {
                                o.courseAccess = "true";
                            }
                        });

                        return o;
                    })
                    this.setState({ CoursesMainData: coursesDetails, weekStartDate: item.STARTDATE, weekEndDate: item.ENDDATE });
                    let storeLiveClassesCourses = [];
                    //for(var i in item.COURSES){
                    coursesDetails.map((i, index) => {

                        if (currentDate == moment(i.DATE, "DD-MM-YYYY").format("DD/MM/YYYY")) {
                            console.log("courses", i)
                            storeLiveClassesCourses.push(i)
                            console.log("StoreLiveClassesCourses", storeLiveClassesCourses)
                            this.setState({ LiveClassesCoursesData: storeLiveClassesCourses });
                        }

                    })


                    //}


                }

            })

        })


    }

    render() {

        const repeatersClassesDetails = this.props.repeatersClassesDetails;
        const currentPlanId = this.isStudentUserValid.current_plan_id;
        let currentTime = moment().unix();
        const currentExpDate = this.isStudentUserValid.expiry_date;
        if (this.state.accessCourseModules != undefined) {
            this.state.accessCourseModules.map((item, index) => {
                item.courseModuleAccess.map((item1, index) => {
                    if (currentPlanId == item1.student_plan_id && currentTime < currentExpDate) {
                        var subject_namesArr = item1.subject_names.split(",");
                        this.state.getAccessSubjectNames = subject_namesArr
                    }


                })

            });
        }

        let weeksNames = [], CoursesDataWithIndexVal = [];
        if (this.state.weekStartDate == "" && this.state.weekEndDate == "") {
            this.state.weekStartDate = moment().isoWeekday('Monday').format('DD-MM-YYYY');
            this.state.weekEndDate = moment().weekday(7).format('DD-MM-YYYY');

            console.log("this.state.weekStartDate", this.state.weekStartDate)
            console.log("this.state.weekEndDate", this.state.weekEndDate)
        }


        repeatersClassesDetails.getServiceData.map((mainData) => {
            mainData.data.map((item, index) => {
                                if ((item.STARTDATE == "05-04-2023") && (item.ENDDATE == "08-04-2023")) {
                    this.state.currentWeekSelected = index;
                    if (this.state.defaultLiveClassStored == "") {
                        CoursesDataWithIndexVal = item.COURSES;
                    }
                } else if ((item.STARTDATE == this.state.weekStartDate) && (item.ENDDATE == this.state.weekEndDate)) {
                    this.state.currentWeekSelected = index;
                    if (this.state.defaultLiveClassStored == "") {
                        CoursesDataWithIndexVal = item.COURSES;
                    }

                }

                const obj = {
                    value: index,
                    label: item.NAME + ` : ` + ` ${item.STARTDATE} to ${item.ENDDATE}`
                }
                weeksNames.push(obj);
            })
        })

        let count = 0;
        var QuestionData = CoursesDataWithIndexVal.map((el, index) => {
            var o = Object.assign({}, el);
            count++;
            o.indexvalue = count;
            this.state.getAccessSubjectNames.map((e2, index) => {
                if (el.Subject == e2) {
                    o.courseAccess = "true";
                }
            })
            return o;
        });


        console.log("this.state.CoursesMainData", QuestionData)
        if (this.state.defaultLiveClassStored == "") {
            this.state.CoursesMainData = QuestionData;
        }
        let currentDate = moment().format("DD/MM/YYYY");
        let defaultLiveClassesCourses = [];
        for (var i in QuestionData) {
            if (currentDate == moment(QuestionData[i].DATE, "DD-MM-YYYY").format("DD/MM/YYYY")) {
                if (this.state.defaultLiveClassStored == "") {
                    defaultLiveClassesCourses.push(QuestionData[i])
                    this.state.LiveClassesCoursesData = defaultLiveClassesCourses;
                }

            }
        }

        //this.state.CoursesMainData =  CoursesDataWithIndexVal;

        console.log("this.state.CoursesMainData", this.state.CoursesMainData);

        return (
            <Container>
                <section className='repeters-liveclasses'>
                    <React.Fragment>
                        <Row>
                            <Col sm={8} className="mt-4 mt-res-6">
                                <h5>Weekly Class Schedules:</h5>
                            </Col>
                            <Col sm={4} className="mt-4">
                                <SelectDropDown
                                    handleChange={this.selecthandleInputChange}
                                    name="weeks"
                                    options={weeksNames}
                                    placeholderName={'weeks'}
                                    dropdownIndicator={{ DropdownIndicator }}
                                    defaultValue={weeksNames.filter(({ value }) => value === this.state.currentWeekSelected)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>

                                <ul className='list-inline custom_class_ul_li mt-3'>
                                    <li className={this.state.allLiveClasses == "all" ? "active" : ""} onClick={() => this.handleSelect("all")}>All Classes</li>
                                    <li className={this.state.allLiveClasses == "today" ? "active ml-4" : "ml-4"} onClick={() => this.handleSelect("today")}>Today Classes</li>
                                </ul>

                                {/* <h6 className="switch_button_repeaters_cls mt-3">
                            <p className="allClassesAlignment">All Classes</p>
                            <label class="switch">
                                <input type="checkbox" 
                                    onChange={() => {
                                        
                                        this.setState((state) => {
                                            
                                            return {
                                                allLiveClasses: !state.allLiveClasses
                                            }
                                        })
                                    }}
                                    checked={!this.state.allLiveClasses} /> 
                                <span class="slider round"></span>
                            </label>
                            <p className="liveClassesAlignment">Today Classes</p>
                                </h6>*/}
                            </Col>

                        </Row>
                        <Row>

                            <Col sm={12} className='mt-4'>
                                {
                                    this.state.allLiveClasses == "all" ?
                                        (<h5>All Classes Details : </h5>)
                                        :
                                        (<h5>Today Classes Details : </h5>)
                                }
                            </Col>
                            <Col sm={12}>
                                {
                                    this.state.CoursesMainData == "" && this.state.allLiveClasses == "all" ?
                                        (
                                            <div className="table-responsive fontWeightTDs">
                                                <table className="table  table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Class Timings</th>
                                                            <th>Subject</th>
                                                            <th>Chapter</th>
                                                            <th>Learn/Practice</th>
                                                            <th>Assignment/Solution</th>
                                                            <th>Video Lecture</th>
                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={7} className='text-center'>Coming Soon</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                        :
                                        (
                                            <>
                                                {

                                                    this.state.allLiveClasses == "all" ?
                                                        <CardLessDataTableWithSearch
                                                            parentData={this.state.CoursesMainData}
                                                            particlecolumns={this.colFunction()}
                                                            defaultSorted={[{
                                                                dataField: "ClassTimings",
                                                                order: "asc"
                                                            }]}
                                                            searchBar={false}
                                                            pageType="RiseCourse"
                                                        />
                                                        : ("")


                                                }
                                            </>
                                        )
                                }
                                {
                                    this.state.LiveClassesCoursesData == "" && this.state.allLiveClasses != "all" ?
                                        (
                                            <div className="table-responsive fontWeightTDs">
                                                <table className="table  table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Class Timings</th>
                                                            <th>Subject</th>
                                                            <th>Chapter</th>
                                                            <th>Learn/Practice</th>
                                                            <th>Assignment/Solution</th>
                                                            <th>Video Lecture</th>
                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={7} className='text-center'>Coming Soon</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                        :
                                        (
                                            <>
                                                {
                                                    this.state.allLiveClasses != "all" ?
                                                        (
                                                            <CardLessDataTableWithSearch
                                                                parentData={this.state.LiveClassesCoursesData}
                                                                particlecolumns={this.colFunction()}
                                                                defaultSorted={[{
                                                                    dataField: "ClassTimings",
                                                                    order: "asc"
                                                                }]}
                                                                searchBar={false}
                                                                pageType="RiseCourse"
                                                            />
                                                        )
                                                        :
                                                        ("")
                                                }
                                            </>
                                        )
                                }

                                <WatchNowVideosModal data={this.state.dataLiveUrl}
                                    show={this.state.liveModalShow}
                                    onHide={() => this.setState({ liveModalShow: false })}
                                    videoDescription={this.props.videoDescription?.getVideoDescription.filter((a) => a.video_code == this.state.dataLiveUrl)} />
                                <UserRestrictionAlert
                                    show={this.state.userRestionModalShow}
                                    onHide={() => this.setState({ userRestionModalShow: false })}
                                    planDetailsShow="5"
                                    getAccessSubjectNames={this.state.getAccessSubjectNames}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                </section>
            </Container>
        )


    }

}
export default withRouter(CrashCourseLiveClassesSection);