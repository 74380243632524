const CustomPatternData = {
    CustomPatternOne: {
        id: 1,
        image: require('../../../../images/Neet-Exam.png'),
        examName: 'Neet Exam',
        ClassName: 'Class-XI',
        SectionName: 'Section-A',
        description: 'Some more text'
    },
    CustomPatternTwo: {
        id: 2,
        image: require('../../../../images/Jee(Mains)-Exam.png'),
        examName: 'JEE (Main) Exam',
        ClassName: 'Class-XI',
        SectionName: 'Section-A',
        description: 'Some more text'
    },
    CustomPatternThree: {
        id: 3,
        image: require('../../../../images/Eamcet-Exam.png'),
        examName: 'EAMCET Exam',
        ClassName: 'Class-XI',
        SectionName: 'Section-A',
        description: 'Some more text'
    },
    CustomPatternFour: {
        id: 4,
        image: require('../../../../images/Neet-Exam.png'),
        examName: 'Neet Exam',
        ClassName: 'Class-XI',
        SectionName: 'Section-A',
        description: 'Some more text'
    },
    CustomPatternFive: {
        id: 5,
        image: require('../../../../images/Jee(Advance)-Exam.png'),
        examName: 'JEE (Advance) Exam',
        ClassName: 'Class-XI',
        SectionName: 'Section-A',
        description: 'Some more text'
    },
    CustomPatternSix: {
        id: 6,
        image: require('../../../../images/Eamcet-Exam.png'),
        examName: 'EAMCET Exam',
        ClassName: 'Class-XI',
        SectionName: 'Section-A',
        description: 'Some more text'
    }
}
export default CustomPatternData;