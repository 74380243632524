import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar';
import Navbars from '../components/navbars/Navbars';
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withApollo } from "react-apollo";
import { Row, Col, Image, Container } from 'react-bootstrap';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import NTAShortlistsection from '../components/NTA_college_predictor/ntashortlist/NtashortlistSection'

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

const GET_CASTES = gql`
query($id:Int){
    getNTAPredictorCastes(id: $id) {
        id
        seat_type
      }
}
`;

const FETCH_STUDENT_WEB_OPTIONS = gql` 
    query($mobile: String,$exam_id:Int,$b_arch_type:Int,$exam_type:Int) {
        getStudentWebOptions(mobile: $mobile,exam_id:$exam_id,b_arch_type:$b_arch_type,exam_type:$exam_type){
            rank
            marks
            eamcet_caste_id
            eamcet_seat_category_id
            selection_type
         }
    }
    `;

class NTAShortlistPage extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }

    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    componentDidMount = () => {
        this.addStudentAnalytics();
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 242,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    render() {

        const getNTAPredictorCastes = this.props.getNTAPredictorCastes;
        const loading = getNTAPredictorCastes.loading;
        const error = getNTAPredictorCastes.error;

        const getStudentWebOptions = this.props.getStudentWebOptions;
        const loading2 = getStudentWebOptions.loading
        const error2 = getStudentWebOptions.error

        if (error !== undefined) {
            alert("Server Error. " + error.message);
            return null;
        }

        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }


        return (
            <div className={this.state.toggled}>
                <div className="student main-wrapper">
                    <div className="student header-area col_pred_short_list">
                        <Navbars
                            headerBottom={this.state.headerBottomImg}
                            onClick={() => this.menuToggler()}
                            pageType="col_pred_short_list" />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper-pad0">
                        {
                            loading == true || loading2 == true ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <NTAShortlistsection
                                        getNTAPredictorCastes={getNTAPredictorCastes.getNTAPredictorCastes}
                                        getStudentWebOptions={getStudentWebOptions.getStudentWebOptions}
                                    />

                                )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(compose(
    graphql(GET_CASTES,
        {
            options: props => ({
                variables: {
                    id: 0
                },
                fetchPolicy: "network-only"
            }), name: "getNTAPredictorCastes"
        }),
        graphql(FETCH_STUDENT_WEB_OPTIONS,
            {
                options: props => ({
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_id:parseInt(Cookies.get("examid")),
                        exam_type:1,
                        b_arch_type:0
                    },
                    fetchPolicy: "network-only"
                }), name: "getStudentWebOptions"
            }),
            graphql(UPDATE_STUDENT_ANALYTICS, {
                name: "submitpt"
            }),


)(NTAShortlistPage)));