import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import * as Cookies from "es-cookie";
import Pdf from './pdf'
import { Link, withRouter } from 'react-router-dom'
import './_chapterWiseLearning.scss'

class SyllabusPdfTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            url1: "",
        }
    }

    render() {

        return (
            <>
                <Col sm={12} className="mt-2">
                    <div>{
                        this.props.showPdf ?
                            (<React.Fragment>
                                <Pdf file={this.props.pdfUrl} className='w-100' />
                            </React.Fragment>) : ('')
                    }
                    </div>
                </Col>
            </>
        )


    }
}
export default withRouter(SyllabusPdfTable);

