import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap'
import { Document, Page, pdfjs } from 'react-pdf';
import PDF from 'react-pdf-js-infinite';
import './_foundationVideosSection.scss';


function Pdf(props) {
  pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);

  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  function onDocumentLoadSuccess({ numPages }) {

  }



  return (
    <>
      <div className="main_view_fc" style={{ border: "1px solid #D8D6EC", boxShadow: "0px 0px 60px #00000029", borderRadius: "5px", padding: "15px" }}>
        {
          props.file == "" ?
            (<h6 className='text-center'>Coming Soon...</h6>)
            :
            (<Document
              file={props.file}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <PDF file={props.file} scale={2.5} className="pdf w-100 pdf_border_shadow" />
            </Document>)
        }
      </div>
    </>
  );
}

export default Pdf;