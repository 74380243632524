import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import FormulaeSheetsEamcet from '../components/EamcetFormulaeSheets/FormulaeSheetsEamcet'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';

const GET_CHAPTER_NAMES = gql`
query($exam_id:Int,$mobile:String){
    getStateChapterNames(exam_id:$exam_id,mobile:$mobile){
        id
        subject_name
        state_chapters{
            id
          chapter_id
          class_id
          chapter_name
          enabled
          questionsCount
           previousChapterEnabled
          practiceEnabled
          revisionMaterialEnabled
          errorExamEnabled
        }
      }
}
`;

const FETCH_SERVICE_DATA_FOR_EAMCETFORMULAESHEETS = gql` 
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String,
    $mobile:String
    ){
        getServiceData111(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type,
            mobile:$mobile){
            data
            enabled
            }
        }
    `;
const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

const GET_SUBJECTS = gql`

query($exam_id:Int){
getSubjectOrder(exam_id:$exam_id){
    id
    subject
}
}

`

class EamcetFormulaeSheets extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggled: "wrapper sidebar-enable",
            submitError:"",
            headerBottomImg: {
                type: "eamcet_view",
                title: "Revision Material"
            },

        }
    }
    menuToggler = () => {
        const toggled = this.state.toggled;
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    componentDidMount = () => {
        this.addStudentAnalytics();
        const title = GoogleAnalyticsArray[0].Eamcet_Revision_Material;
        ReactGA.pageview('/student/FormulaeSheets', ["rizee"], title);
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""
        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 130,
            source: 2
        }
        console.log("addStudentObj", addStudentObj)
        this.submitpt(addStudentObj).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        const getServiceData111 = this.props.getServiceData111
        const loading = getServiceData111.loading
        const error = getServiceData111.error

        const getSubjectOrder=this.props.getSubjectOrder
        const loading2 = getSubjectOrder.loading;
        const error2 = getSubjectOrder.error;

        const getStateChapterNames = this.props.getStateChapterNames
        const loading3 = getStateChapterNames.loading;
        const error3 = getStateChapterNames.error;

        if (error !== undefined) {
            alert("Server Error. " + error.message);
            return null;
        }
        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }
        if (error3 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }

        let globalsubjects = "";
        if (JSON.parse(localStorage.getItem("globalsubjects")) != "") {
            globalsubjects = JSON.parse(localStorage.getItem("globalsubjects"));
        }
        else {
            this.props.history.push("/student/login");
        }

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }




        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} headerBottom={this.state.headerBottomImg}/>
                </div>
                <div className="main-wrapper">
                    {/* {(loading1 == true) && (<PreloaderTwo />)} */}
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper" style={{ paddingTop: 10, minHeight: "80vh" }}>
                        {
                            loading || loading2 || loading3 ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <FormulaeSheetsEamcet
                                        globalsubjects={globalsubjects}
                                        getEamcetFormulaeSheetsArr={this.props.getServiceData111.getServiceData111}
                                        getSubjectOrder={getSubjectOrder.getSubjectOrder}
                                        getStateChapterNames={getStateChapterNames.getStateChapterNames}
                                    />
                                )
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(compose(

    graphql(FETCH_SERVICE_DATA_FOR_EAMCETFORMULAESHEETS, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                service_type: "Formulae_Sheets",
                service_sub_type: "All",
                mobile:Cookies.get("mobile")
            },
            fetchPolicy: 'network-only'
        }), name: "getServiceData111"
    }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
    graphql(GET_SUBJECTS, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
            },
            fetchPolicy: 'network-only'
        }), name: "getSubjectOrder"
    }),
    graphql(GET_CHAPTER_NAMES, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: (Cookies.get("mobile")),

            },
            fetchPolicy: 'network-only'
        }), name: "getStateChapterNames"
    }),
)(EamcetFormulaeSheets));
