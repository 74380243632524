import React, { Component } from 'react';
import { Container, Row, Col, Card, Button, Image, Form, Accordion, Media } from 'react-bootstrap'
import * as Cookies from "es-cookie";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import gql from "graphql-tag";
import moment from 'moment';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import EamcetRestrictionAlert from "../home/EamcetRestrictionAlert";
import { log } from 'logrocket';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';
import './_ipequestionpapers.scss'
import parse, { domToReact } from 'html-react-parser';


class QuestionPaperDisplaySection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            twoMarksCheckbox: false,
            fourMarksCheckbox: false,
            sevenMarksCheckbox: false,
            eightMarksCheckbox: false,
            allCheckBox:true,
            marksValue: 0
        }
    }

    handleChangeMarksType = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        console.log("qqqqqqqqqq", name, value)

        if (value == "two_marks") {
            this.setState({
                marksValue: 2,
                twoMarksCheckbox: true,
                fourMarksCheckbox: false,
                sevenMarksCheckbox: false,
                eightMarksCheckbox: false,
                allCheckBox:false
            })
        } else if (value == "four_marks") {
            this.setState({
                marksValue: 4,
                twoMarksCheckbox: false,
                fourMarksCheckbox: true,
                sevenMarksCheckbox: false,
                eightMarksCheckbox: false,
                allCheckBox:false

            })
        } else if (value == "seven_marks") {
            this.setState({
                marksValue: 7,
                twoMarksCheckbox: false,
                fourMarksCheckbox: false,
                sevenMarksCheckbox: true,
                eightMarksCheckbox: false,
                allCheckBox:false

            })
        } else if (value == "eight_marks") {
            this.setState({
                marksValue: 8,
                twoMarksCheckbox: false,
                fourMarksCheckbox: false,
                sevenMarksCheckbox: false,
                eightMarksCheckbox: true,
                allCheckBox:false

            })
        } else if (value == "All") {
            this.setState({
                marksValue: 0,
                twoMarksCheckbox: false,
                fourMarksCheckbox: false,
                sevenMarksCheckbox: false,
                eightMarksCheckbox: false,
                allCheckBox:true
            })
        } 
    }

    render() {
        const paperDetails = this.props.examPaperDetails
        console.log("questionsData", this.props.questionsData)

        let questionsList = []

        if (this.props.questionsData.length > 0 && this.props.marksRadioButton == true && this.state.marksValue != 0) {
            questionsList = this.props.questionsData.filter((item) => item.marks == this.state.marksValue)
        }
        else{
            questionsList = this.props.questionsData
        }

        console.log("questionsList", questionsList)

        let subject = paperDetails.qset;
        let ourSubject = "Maths";

        let enableSevenMarks = false

        if (subject.includes(ourSubject)) {
            enableSevenMarks = true
        } else {
             enableSevenMarks = false
        }

        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <h5>Subject: <span className='details_paper'>{paperDetails.qset}</span>,     Year:   <span className='details_paper'>{paperDetails.year}</span></h5>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col sm={12}>
                        {
                            this.props.showLoading == true ?
                                (<Image src={require('../../../images/rizee_loading.png')} className="loading_img" alt="Lock" />)
                                :
                                (
                                    <>
                                        <Row>
                                            <Col sm={12}>
                                                {
                                                    this.props.marksRadioButton == false ?
                                                        ("")
                                                        :
                                                        (
                                                            <div className='float-right'>
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" id="All" value="All" name="input_exam_type" className="custom-control-input" checked={this.state.allCheckBox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                                    <label className="custom-control-label inp_text" htmlFor="All" >All</label>
                                                                </div>
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" id="two_marks" value="two_marks" name="input_exam_type" className="custom-control-input" checked={this.state.twoMarksCheckbox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                                    <label className="custom-control-label inp_text" htmlFor="two_marks" >2 Marks</label>
                                                                </div>

                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" id="four_marks" value="four_marks" name="input_exam_type" className="custom-control-input" checked={this.state.fourMarksCheckbox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                                    <label className="custom-control-label inp_text" htmlFor="four_marks" >4 Marks</label>
                                                                </div>
                                                                {
                                                                    enableSevenMarks == false ?
                                                                        (
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" id="eight_marks" value="eight_marks" name="input_exam_type" className="custom-control-input" checked={this.state.eightMarksCheckbox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                                                <label className="custom-control-label inp_text" htmlFor="eight_marks" >8 Marks</label>
                                                                            </div>
                                                                        )
                                                                        :
                                                                        (
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" id="seven_marks" value="seven_marks" name="input_exam_type" className="custom-control-input" checked={this.state.sevenMarksCheckbox} onChange={(e) => this.handleChangeMarksType(e)} />
                                                                                <label className="custom-control-label inp_text" htmlFor="seven_marks" >7 Marks</label>
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>
                                                        )
                                                }

                                            </Col>
                                        </Row>
                                        <Card className='p-3'>
                                            {
                                                questionsList.length > 0 ?
                                                    (
                                                        questionsList.map((item) => {
                                                            return (
                                                                <Row>
                                                                    <Col xl={12} lg={12} md={12} sm={12} className="my-2">
                                                                        <div className="d-flex align-items-center" >
                                                                            <span className='font-weight-bold'>{item.slno + '.'}</span>
                                                                            <div className="ml-2 font-weight-bold ">{parse(item.question_name)}</div>
                                                                        </div>

                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <p className='text-center font-weight-bold'>Coming soon...</p>

                                                                </Col>

                                                            </Row>
                                                        </>
                                                    )
                                            }
                                        </Card>
                                    </>
                                )
                        }

                    </Col>
                </Row>
            </Container>
        )
    }
}
export default withRouter(compose(

)(QuestionPaperDisplaySection));