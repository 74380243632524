import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_rapidRevision.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import LiveClassesModal from './LiveClassesModal';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';

import FileText from "../../../images/images1/FileText.png";
import FileArrowDown from "../../../images/images1/FileArrowDown.png";
//import Group3172 from "../../../images/images1/Group3172.png";

import UserRestrictionAlert from "../home/UserRestrictionAlert";
// import LockSimple from "../../../images/LockSimple.png";
import * as Cookies from "es-cookie";
import moment from 'moment';
import { withRouter } from "react-router-dom";

//ENGNEERING CHEMISTRY
let QuestionDataCheEngg = [
    {
        DATE: "09-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Organic Chemistry Some Basic Principles and Techniques",

        Learn: ("3,34,0"),
        Practice: ("3,34,0"),
        VideoLecture: ("4QMzqFC4DRU"),
        Zoom: ("https://zoom.us/j/98598698845?pwd=ZlcyZHNJZ2RVWXZ4dkhlNjkvYmxTUT09"),
    },
    {
        DATE: "10-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Hydrocarbons",

        Learn: ("3,36,0"),
        Practice: ("3,36,0"),
        VideoLecture: ("TUdIdX6Q_t0"),
        Zoom: ("https://zoom.us/j/96896383507?pwd=bm8wdERuNEUvemlrcUx6eHFTOWhxQT09"),
    },
    {
        DATE: "11-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Halo alkanes and Halo Arenes",

        Learn: ("3,46,0"),
        Practice: ("3,46,0"),
        VideoLecture: ("7RFBdX-rD_c"),
        Zoom: ("https://zoom.us/j/93705412716?pwd=NHZiL0EzczVuYStpOTVEOTBWcjdlUT09"),
    },
    {
        DATE: "12-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Alcohols, Phenols and Ethers",

        Learn: ("3,47,0"),
        Practice: ("3,47,0"),
        VideoLecture: ("I5RpPgZJoG0"),
        Zoom: ("https://zoom.us/j/98944927172?pwd=RXpZSHZnNk80bEE0R2psTGFpWGc0dz09"),
    },
    {
        DATE: "13-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Aldehydes, Ketones and Carboxylic Acids",

        Learn: ("3,48,0"),
        Practice: ("3,48,0"),
        VideoLecture: ("SW529H_V9oU"),
        Zoom: ("https://zoom.us/j/98602302524?pwd=eDJmVEp2YTgycVp6c0tNNEp4TFZ2dz09"),
    },
    {
        DATE: "14-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Amines",

        Learn: ("3,49,0"),
        Practice: ("3,49,0"),
        VideoLecture: ("WBEuoPnqb40"),
        Zoom: ("https://zoom.us/j/99782785285?pwd=a3dUNU13dndjeW5ZUU9DZCszeGdPUT09"),
    },
    {
        DATE: "16-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Some Basic Concepts Of Chemistry",

        Learn: ("3,23,0"),
        Practice: ("3,23,0"),
        VideoLecture: ("vFoh5uHqpWk"),
        Zoom: ("https://zoom.us/j/93003871308?pwd=bEdQV1RsQktXd0RoYmdLVGsvQUh6dz09"),
    },
    {
        DATE: "17-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Chemical Bonding",

        Learn: ("3,26,0"),
        Practice: ("3,26,0"),
        VideoLecture: ("DPDAktsVbH0"),
        Zoom: ("https://zoom.us/j/92680758091?pwd=TnVNTXcrQkdKdmI3RWJ4TUwzdFVwdz09"),
    },
    {
        DATE: "18-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Thermodynamics",

        Learn: ("3,28,0"),
        Practice: ("3,28,0"),
        VideoLecture: ("oNaiTsJzO7E"),
        Zoom: ("https://zoom.us/j/91004417902?pwd=TnJJeTFjUFQyWEowRE04Vm1Ncmk5UT09"),
    },
    {
        DATE: "19-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Chemical Equilibrium",

        Learn: ("3,29,0"),
        Practice: ("3,29,0"),
        VideoLecture: ("X_u3Z8Zbrrs"),
        Zoom: ("https://zoom.us/j/92232843065?pwd=cC9KOEI5UlYrRVg0emZhRlZuWXBKUT09"),
    },
    {
        DATE: "20-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Solutions",

        Learn: ("3,37,0"),
        Practice: ("3,37,0"),
        VideoLecture: ("aR3gqpEriYk"),
        Zoom: ("https://zoom.us/j/93094938430?pwd=Z1RKOFJZZnc0OFg1b3QzbVBuREhQdz09"),
    },
    {
        DATE: "21-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Chemical Kinetics",

        Learn: ("3,40,0"),
        Practice: ("3,40,0"),
        VideoLecture: ("PmcRvNlNYp4"),
        Zoom: ("https://zoom.us/j/92061223831?pwd=d3d5WHFveCs1WHAzYkZaMFhRdXF3UT09"),
    },
    {
        DATE: "23-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "States Of Matter",

        Learn: ("3,27,0"),
        Practice: ("3,27,0"),
        VideoLecture: ("qaHanLUUqnU"),
        Zoom: ("https://zoom.us/j/93427902827?pwd=QkhpYjVpUDBNaVUvSnZVSjc5cWdtQT09"),
    },
    {
        DATE: "24-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Solid State",

        Learn: ("3,35,0"),
        Practice: ("3,35,0"),
        VideoLecture: ("1nzbkff8rJA"),
        Zoom: ("https://zoom.us/j/98462546308?pwd=T2R4eEhOTTR2N3F6SERLcWY2Vlc0dz09"),
    },
    {
        DATE: "25-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Ionic Equilibrium",

        Learn: ("3,153,0"),
        Practice: ("3,153,0"),
        VideoLecture: ("mTEirWyyXNg"),
        Zoom: ("https://zoom.us/j/96213411947?pwd=Y1RsVVFNMjZmTCtqUFlmZG1PZWM0Zz09"),
    },
    {
        DATE: "26-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Electro Chemistry",

        Learn: ("3,39,0"),
        Practice: ("3,39,0"),
        VideoLecture: ("sFISaigDuuI"),
        Zoom: ("https://zoom.us/j/97424274114?pwd=VU83eldSTHFNbngzTitOUjNTdloxZz09"),
    },
    {
        DATE: "27-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Co-ordination Compounds",

        Learn: ("3,45,0"),
        Practice: ("3,45,0"),
        VideoLecture: ("icwkBHXaffA"),
        Zoom: ("https://zoom.us/j/95918070024?pwd=L1FFOWF1ZUF5bXJTQlJaM0gzVXJTdz09"),
    },
    {
        DATE: "28-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "The P Block Elements(XI)",

        Learn: ("3,33,0"),
        Practice: ("3,33,0"),
        VideoLecture: ("FfAi9YI5fOE"),
        Zoom: ("https://zoom.us/j/94539530325?pwd=VzRQVzlRVjkxNGUxendrVWs4Zi8yZz09"),
    },
    {
        DATE: "30-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "The P-Block Elements(XII) - 1",

        Learn: ("3,43,0"),
        Practice: ("3,43,0"),
        VideoLecture: ("MCqNcNFXXcM"),
        Zoom: ("https://zoom.us/j/95653887544?pwd=bTFqdlVqeHE5VUkvNCtJREY5bXFvZz09"),
    },
    {
        DATE: "31-08-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "The P-Block Elements(XII) - 2",

        Learn: ("3,43,0"),
        Practice: ("3,43,0"),
        VideoLecture: ("Tjes0OUa91U"),
        Zoom: ("https://zoom.us/j/91616952641?pwd=YTdsa2N6SFI1ZTdRaFpUL1JTeUZWZz09"),
    },
    {
        DATE: "01-09-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "S-Block Elements",

        Learn: ("3,32,0"),
        Practice: ("3,32,0"),
        VideoLecture: "_NwdGtTROpQ",
        Zoom: ("https://zoom.us/j/92910918755?pwd=ckRlQU4zWkMzSWdEMWE4S0FURnZDUT09"),
    }, 
    {
        DATE: "02-09-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "General Principles and Isolation of Elements",

        Learn: ("3,42,0"),
        Practice: ("3,42,0"),
        VideoLecture: "-fOBtolMY4w",
        Zoom: ("https://zoom.us/j/95201983088?pwd=bEplaXNFb1krRG15S0FiYmIwUUJGQT09"),
    }, 
    {
        DATE: "03-09-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Surface Chemistry",

        Learn: ("3,41,0"),
        Practice: ("3,41,0"),
        VideoLecture: "HVe1nfOFyZw",
        Zoom: ("https://zoom.us/j/91596264740?pwd=RjFXSlQvRExUSWswdkh5NW1COUNRZz09"),
    }, 
    {
        DATE: "04-09-2021",
        ClassTimings: "5:30 PM to 6:30 PM",
        time: "17",
        Subject: "Chemistry",
        Chapter: "Environmental Chemistry",

        Learn: ("3,38,0"),
        Practice: ("3,38,0"),
        VideoLecture: "m42Sqf3R1xY",
        Zoom: ("https://zoom.us/j/94598861661?pwd=QmJPY2NZN2FCSW0zckN0OFJJY3VUZz09"),
    }, 
   
];



let QuestionDataChemistry = [];
// QuestionDataChemistry.push(...QuestionDataCheAgri)
QuestionDataChemistry.push(...QuestionDataCheEngg)


const sortedActivities = QuestionDataChemistry.sort ( function (a, b){
  let date1 = moment(a.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
  let date2 = moment(b.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
  
 if (a.DATE === b.DATE) {
  return a.time - b.time;
}
 return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
});

let count = 0;
var QuestionData = sortedActivities.map((el,index) => {
  var o = Object.assign({}, el);
  if(el.Assignments !="" || el.Solutions !="" ){
      count++;
       o.indexvalue = count
       
  }
  return o;
});


/*if (Cookies.get("examid") == 7 || Cookies.get("examid") == 8) {
    QuestionData.push(...QuestionDataCheAgri)
}else{
	 QuestionData.push(...QuestionDataCheEngg)
}*/


let LiveClassesDataChemistry = []

    for(var i in QuestionData){
        let currentDate = moment().format("DD/MM/YYYY");
        if ((QuestionData[i]["VideoLecture"]  || QuestionData[i]["Zoom"]) && (currentDate ==  moment(QuestionData[i].DATE,"DD-MM-YYYY").format("DD/MM/YYYY"))) {
            LiveClassesDataChemistry.push(QuestionData[i]);
          }
    }
	
var Zoom;
var Livevideo;


class ChemistryDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            dataLiveUrl: "",
            userRestionModalShow: false,
			      allLiveClasses:true
        }

        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
          this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));

      }
        
    }
    modalaFun = (row) => {
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });

    }

    
    sortFuncDate(a, b, order, dataField, rowA, rowB)  { 
        if (order == 'desc') {
            
            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY")  > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY")  > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }
    checkForDate = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7) {
            return true
        }
        return false
    }
    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }
    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    getAccessPlans = (planId)  => {
        const epriryDateInunixTime = this.isStudentUserValid.expiry_date;
        const currentDateInUnixTime = parseInt((new Date().getTime() / 1000).toFixed(0))

        // console.log("exU",epriryDateInunixTime)
        // console.log("cU",currentDateInUnixTime)
        // console.log("eeeeee",this.isStudentUserValid)
        if(planId == 5){
            return true; //restrict
        }else{

            if(currentDateInUnixTime > epriryDateInunixTime){
                return true; 
            }
        }

        return false
    }
    learnFormateAction = (row) => {
        
        let practiceIds = row.split(',');
        //console.log("practiceIds",practiceIds)
        this.props.history.push({
            pathname: "/student/subject/start-learning",
            state: {
              subjectid: practiceIds[0],
              ocid: practiceIds[1],
              otid: practiceIds[2]
            }
          }
        )
    }
    actionFormatterLearn(cell, row, rowIndex){
        return (
            <>
            {
                row.Learn != "" ?  (row.indexvalue > 3 && this.getAccessPlans(this.isStudentUserValid.current_plan_id) ) ? 
                
                    (<div className="learnTextLock">
                        <a onClick={() => this.modalFunAssignments()}> <span>Learn Chapter</span></a>
                    </div>) :
                    (<div className="learnText">
                        <a onClick={() => this.learnFormateAction(row.Learn)} target="_blank"> Learn Chapter</a>
                    </div>) : <h6 className="text-center">-</h6>
            }
            </>
        )
    }
    practiceFormateAction = (row) => {
        
        let practiceIds = row.split(',');
        //console.log("practiceIds",practiceIds)
        localStorage.setItem("subjectid", practiceIds[0]);
        localStorage.setItem("type", "practice");
        //localStorage.setItem("ocid", "0");
        localStorage.setItem("ocid", practiceIds[1]);
        localStorage.setItem("otid", practiceIds[2]);
        window.open("/student/subject/practice-test", "_blank") //to open new page
    }
    actionFormatterPractice(cell, row, rowIndex){
        return (
            <>
            {
                row.Practice != "" ?  (row.indexvalue > 3 && this.getAccessPlans(this.isStudentUserValid.current_plan_id) ) ? 
                
                    (<div className="practiceTextLock">
                        <a onClick={() => this.modalFunAssignments()}> <span>Practice Here</span></a>
                    </div>) :
                    (<div className="practiceText">
                        <a   onClick={() => this.practiceFormateAction(row.Practice)}> Practice Here </a>
                    </div>) : <h6 className="text-center">-</h6>
            }
            </>
        )
    }
    actionFormatterVideoLecture(cell, row, rowIndex) {
        let currentDate = moment().format("DD-MM-YYYY");
        let currentMonth = moment().format('MM')
        let currentRowMonth = moment(row.DATE,"DD-MM-YYYY").format("MM") 
        console.log("currentMonth",currentMonth)
        console.log("rowMonth",currentRowMonth)
        
        return (

            <>
                <div className="text-center">
                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                    {/* {
                        currentMonth == currentRowMonth ? 
                        (
                            currentDate == row.DATE ? 
                        (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) :
                        (
                            row.DATE < currentDate  ? 
                            :
                            
                            (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                        )
                        ) 
                        :
                        (
                            currentDate == row.DATE ? 
                            (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) :
                            (
                                row.DATE > currentDate  ? 
                                (<button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>) 
                                :
                                
                                (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                            )
                        )

                        
                    } */}
                </div>
            </>


            //    { <div className="text-center">
            //         {
            //             row.Zoom == "" ?

            //                 row.VideoLecture != "" ?
            //              (<button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>)
                            
            //                     : "-"


            //                 :
            //                 <>
                            
            //                     {
            //                         currentDate == row.DATE ? 
            //                         (<a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-danger btn-danger-hover effect_live effect-1">Live Now</button></a>) 
            //                         :
            //                         (<button type="button" className="btn btn-custom-disabled-cls" disabled>Live Now</button>)
                                    
            //                     }
                                



            //                 </>
            //         }

            //     </div> }

        )
    }

    colFunction() {

        let columns = [
            {
                dataField: "DATE",
                text: "Date",
                sort: true,
                headerStyle: {
                    width: "12%"
                },
                style: {
                    fontWeight: "bold !important"
                },
                sortFunc: this.sortFuncDate.bind(this)
            },
            {
                dataField: "ClassTimings",
                text: "Class Timings",
                sort: true,
                headerStyle: {
                    width: "22%"
                },
                sortFunc: this.sortFuncMulti.bind(this)
            },

            {
                dataField: "Subject",
                text: "Subject",
                style: (row, rowIndex) => {
                    return { fontWeight: "bold !important" };
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                width: "25%"
                }
            },
            {
                dataField: "Learn",
                text: "Learn",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterLearn.bind(this)
            },
            {
                dataField: "Practice",
                text: "Practice",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterPractice.bind(this)
            },
           
            {
                dataField: "VideoLecture",
                text: "Video Lecture",
                headerStyle: {
                    width: "20%"
                },
                formatter: this.actionFormatterVideoLecture.bind(this),
                
            },
            
        ];
        return columns;
    }

    render() {
        return (
            <Row>
                <Col align="right" sm={12}>
                    <h6 className="switch_button_cls mt-res-4">
                        <p className="allClassesAlignment">All Classes</p>
                        <label class="switch">
                            <input type="checkbox" 
                                onChange={() => {
                                    
                                    this.setState((state) => {
                                        
                                        return {
                                            allLiveClasses: !state.allLiveClasses
                                        }
                                    })
                                }}
                                checked={!this.state.allLiveClasses} />
                            <span class="slider round"></span>
                        </label>
                        <p className="liveClassesAlignment">Live</p>
                    </h6>
                </Col>
                <Col sm={12}>
                {
                    this.state.allLiveClasses ?
                    <CardLessDataTableWithSearch 
                    parentData={QuestionData}
                    particlecolumns={this.colFunction()}
                    defaultSorted={[{
                        dataField: "ClassTimings",
                        order: "asc"
                    }]}
                    />
                    :
                    <CardLessDataTableWithSearch 
                    parentData={LiveClassesDataChemistry}
                    particlecolumns={this.colFunction()}
                    defaultSorted={[{
                        dataField: "ClassTimings",
                        order: "asc"
                    }]}
                    />
                }
                    {/* <Card>
                        <BootstrapTable
                            keyField="id"
                            columns={this.colFunction()}
                            data={QuestionData}
                            defaultSorted={[{
                                dataField: "ClassTimings",
                                order: "asc"
                            }]}
                            wrapperClasses="table-responsive"
                            bootstrap4

                        />
                    </Card> */}

                    <LiveClassesModal data={this.state.dataLiveUrl} show={this.state.liveModalShow} onHide={() => this.setState({ liveModalShow: false })} />
                    <UserRestrictionAlert
                        show={this.state.userRestionModalShow}
                        onHide={() => this.setState({ userRestionModalShow: false })}
                    />
                </Col>
            </Row>

        )
    }

}
export default withRouter (ChemistryDataSection);