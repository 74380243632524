import React, { Component } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar';
import Navbars from '../components/navbars/Navbars';
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import * as Cookies from "es-cookie";
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import DayWiseSubscribeOrderSummarySection from '../components/register_steps/DayWisePackageorderSummary'

const COUPN_VALIDATION = gql`
  mutation($mobile: String, $plan_id: Int, $referral_code: String) {
    validateReferralCode(
      mobile: $mobile
      plan_id: $plan_id
      referral_code: $referral_code
    ) {
      referral_id
      applied_amount
      status
      desc
      type
      value
    }
  }
`;


class DayWiseSubscribeOrderSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            couponob: "",
            submitError4: "",
            headerBottomImg: {
                Img: require('../../images/mocktext-img.png'),
                Title: "Mock Test Order Summary",
                width: 150,
                helpImg: require('../../images/Ask_me_for_Help.gif')
            },
            discountPrice: 0

        }
    }

    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };



    coupnValidation = (e, data) => {
        e.preventDefault();
        if (data != "") {
            this.coupnval(
                Cookies.get("mobile"),
                // parseInt(this.props.history.location.state.data.id),
                parseInt(this.props.history.location.state.data.perDayWisePlan[0].id),
                data
            ).catch(error => {
                console.log("catch if error");
                console.log(error);
                this.setState({
                    submitError4: error.graphQLErrors.map(x => x.message)
                });
                console.error("ERR =>", error.graphQLErrors.map(x => x.message));
            });

        } else {
            this.setState({ submitError4: "Please fill COUPON ID" });
        }
    }

    coupnval = async (mobile,
        plan_id,
        referral_code
    ) => {
        await this.props.coupnval({
            variables: {
                mobile,
                plan_id,
                referral_code

            },
            update: (store, { data }) => {
                // console.log("coupnvaldata", data);
                if (data.validateReferralCode) {
                    let amount =  (parseInt(this.props.history.location.state.data.amount) * parseInt(data.validateReferralCode.value)) / 100 
                    console.log("amountamountamount",amount)
                    this.setState({
                        couponob: data.validateReferralCode,
                        submitError4: "",
                        discountPrice: parseInt(amount)
                    });

                }
            }
        });
    };
    render() {
        console.log("aaaaa", this.props.history.location.state.data.amount)
        if (this.props.history.location.state == undefined) {
            this.props.history.push("/student/action/CS_packages")
            return null;
        } else {
            return (
                <div className={Cookies.get("toggle")}>
                    <div className="header-area">
                        <Navbars onClick={() => this.menuToggler()} />
                    </div>
                    <div className="main-wrapper">
                        <AsideNavbar onClick={() => this.menuToggler()} />
                        <div className="student-overlay" onClick={() => this.menuToggler()} />
                        <div className="content-wrapper">
                            <section className="student-package">
                                <Container>
                                    <DayWiseSubscribeOrderSummarySection
                                        type=""
                                        coupnValidation={this.coupnValidation}
                                        stateData={{
                                            planObj: this.props.history.location.state.data.perDayWisePlan[0],
                                            couponob: this.state.couponob,
                                            no_of_days: this.props.history.location.state.data.days,
                                            discountPrice:this.state.discountPrice
                                        }}
                                        locgetData={this.props.history.location.state != undefined ? this.props.history?.location?.state : ""}
                                    />
                                </Container>
                            </section>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(
    graphql(COUPN_VALIDATION, {
        name: "coupnval"
    },
    )(DayWiseSubscribeOrderSummary));

