import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row, Col, Tabs, Tab, Card, Image, Navbar, Dropdown, Form, FormControl, Button, Container, Media, Accordion } from 'react-bootstrap';
import DeleteRecordModal from './deleteRecordModal.jsx';


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "lightgreen" : "#fff",
  border: '1px dashed #3C78EE',
  fontSize: '13px',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250,
});

/*let deleteFav = (item) => {
  console.log("item", item);
  this.props.deleteBranchCollegeRecord(item.id)
}*/


const DraggablePriorityOrderSection = (props) => {

  //const [modal, setModal] = useState(false);
  //const [data, deleteData] = useState({});


  /*let deleteRecordModal = (item) => {
    setModal(true);
    deleteData({ item })
  }*/


  return (
    <DragDropContext onDragEnd={props.onDragEndPriortyOrder}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            //style={getListStyle(snapshot.isDraggingOver)}
            className='block_shadow_css'
          >
            <h5 className="text-center center_text_color mt-2 mb-3">Prioritize your favourite Colleges & Branches by simple drag and drop from the below list</h5>
            <ul className="list-inline">
              {props.data.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className="card pad_card"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <li>
                        <Row>
                          <Col sm={1} className='center-auto text-center'>
                            <h6 className='font-15'>{index + 1}.</h6>
                          </Col>
                          <Col sm={11}>
                            {/*<i className="fa fa-trash float-right color_red cursor_pointer" onClick={() => props.deletePriorityOrderModal(item)}></i>*/}
                            <div className='row'>
                              <div className="col-sm-6">
                                <p className='font-12'>Branch Name</p>
                                <h6 className='mt-2 font-15 text-uppercase'>{item.branch_name}</h6>
                              </div>
                              <div className="col-sm-6">
                                <p className='font-12'>College Name</p>
                                <h6 className='mt-2 font-15 text-uppercase'>{item.college_code + " - " + item.college_name}</h6>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {
                          item.last_year_cutoffs.length > 0 ?
                            (
                              <Row className="justify-content-end">
                                <Col sm={5} className="pos_cut_offs">
                                  <h6 className="font-12 color_red mb-1 mt-2">Last Year Cut offs Category Wise : </h6>
                                  <ul className="list-inline list_type_number_cls ml-3">
                                    {
                                      item.last_year_cutoffs.map(item1 => {
                                        return (
                                          <>
                                            <li>{item1.eamcet_seat_category_name} : Start Rank : <b>{item1.start_rank}</b> - End Rank : <b>{item1.end_rank}</b></li>
                                          </>
                                        )
                                      })
                                    }

                                  </ul>
                                </Col>
                                <Col sm={1}>
                                  <button onClick={() => props.deleteRecordModal(item)} className="trash-btn"><i class="fal fa-trash-alt trash"></i></button>
                                </Col>
                              </Row>
                            )
                            :
                            ("")
                        }
                      </li>
                    </div>
                  )}
                </Draggable>
              ))}
            </ul>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      
    </DragDropContext>
  );
};

export default DraggablePriorityOrderSection;