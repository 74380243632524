import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import StudentHomeSection from '../components/home/StudentHomeSection'
import HomeNavbar from '../components/home/HomeNavbar';
import * as Cookies from "es-cookie";
import Navbars from '../components/navbars/Navbars';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import PreloaderFour from '../components/preloader/PreloaderFour';
import EamcetHome from '../components/home/EamcetHome';

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import { tokenChecking } from './GlobalFunctions';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const FETCH_ISSTUDENTUSERVALID = gql` 
query($mobile: String) {
    isStudentUserValid(mobile: $mobile){
        estatus
        current_plan_id
        expiry_date
        user_access_restictions
        module_restrictions
        chapter_ids
        previous_sets
     }
}
`;

const UPDATE_STUDENT_ANALYTICS = gql`
  mutation(
    $params:AnalyticsStudents1  
    ) {
        studentAnalytics(
        params: $params
     )
  }
`;

class StudentHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerBottomImg: {
                type: "homePage",
                title: Cookies.get("examid") == 3 ? "AP EAPCET (MPC)" : Cookies.get("examid") == 6 ? "TS EAMCET (MPC)" : Cookies.get("examid") == 7 ? "AP EAPCET (BiPC)" : Cookies.get("examid") == 8 ? "TS EAMCET (BiPC)" : Cookies.get("examid") == 9 ? "KCET (MBiPC)" : Cookies.get("examid") == 10 ? "KCET (MPC)" : "KCET (BiPC)"
            },
            mainindex: 0,
            getSubjects: [],

            toggled: isMobile == true && Cookies.get("institution_id") == 30 ? "wrapper" : Cookies.get("toggle"),
            submitError:"",
        }
    }


    menuToggler = () => {
        const toggled = this.state.toggled;
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    componentDidMount = () => {

        this.addStudentAnalytics();

        let title = "";
        if (Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8) {
            title = GoogleAnalyticsArray[0].Eamcet_Home;
            ReactGA.pageview('/student/home', title);
        }
        if (Cookies.get("examid") == 1 || Cookies.get("examid") == 2) {
            title = GoogleAnalyticsArray[0].Home;
            ReactGA.pageview('/student/home', title);
        }

        let globalsubjects = "";
        if (JSON.parse(localStorage.getItem("globalsubjects")) != "") {
            globalsubjects = JSON.parse(localStorage.getItem("globalsubjects"));
        }
        else {
            this.props.history.push("/student/login");
        }
        //console.log("didmount", globalsubjects);
        if (globalsubjects != undefined || globalsubjects != null) {
            const getSubjects = globalsubjects.map((gmap, index) => {
                if (index == 0) {
                    return { ...gmap, active: "active" }
                }
                else {
                    return { ...gmap, active: "" }
                }
            })
            this.setState({
                getSubjects: getSubjects
            });
        }
    }
    addStudentAnalytics = e => {
        let addStudentObj = ""

        if (Cookies.get("examid") == 1 || Cookies.get("examid") == 2) {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 4,
                source: 2
            }
        }  else if(Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ){
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 104,
                source :2
            }
        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    handleSelect = (subid) => {
        const getSubjects = this.state.getSubjects.map((gmap, index) => {
            if (index == subid) {
                return { ...gmap, active: "active" }
            }
            else {
                return { ...gmap, active: "" }
            }
        })
        this.setState({
            getSubjects: getSubjects,
            mainindex: subid
        });
    }

    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");
        // tokenChecking(Cookies.get("studenttoken"));
        const isStudentUserValid = this.props.isStudentUserValid;
        const loading5 = isStudentUserValid.loading;
        const error5 = isStudentUserValid.error;

        if (error5 !== undefined) {
            alert("Server Error. " + error5.message);
            return null;
        }

        if (isStudentUserValid.isStudentUserValid != undefined) {
            if (isStudentUserValid.isStudentUserValid.estatus == 0) {
                Cookies.remove("token");
                Cookies.remove("username");
                Cookies.remove("refreshtoken");
                Cookies.remove("email");
                Cookies.remove("id");
                Cookies.remove("institutionid");
                Cookies.remove("userlevel");
                Cookies.remove("name");
                this.props.history.push("/student/login");
            }
            localStorage.removeItem('isStudentUserValid');
            localStorage.setItem(
                "isStudentUserValid",
                JSON.stringify(isStudentUserValid.isStudentUserValid)
            );
        }
        let globalsubjects = "";
        if (JSON.parse(localStorage.getItem("globalsubjects")) != "") {
            globalsubjects = JSON.parse(localStorage.getItem("globalsubjects"));
        }
        else {
            this.props.history.push("/student/login");
        }

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }
        console.log("home", this.state);
        if (this.state.getSubjects.length
            > 0) {
            return (
                <div className={this.state.toggled}>
                    <div className="student main-wrapper">
                        <div className="student header-area">
                            {
                                (Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8  || Cookies.get("examid") == 9  || Cookies.get("examid") == 10 || Cookies.get("examid") == 11) ?
                                    (<Navbars onClick={() => this.menuToggler()} headerBottom={this.state.headerBottomImg} />)
                                    :
                                    (
                                        <>
                                            {
                                                !loading5 && (
                                                    <HomeNavbar
                                                        stateData={this.state}
                                                        getSubjects={this.state.getSubjects}
                                                        onClick={() => this.menuToggler()}
                                                        logout={this.props.logout}
                                                        studentGlobals={studentGlobals}
                                                        pageType={"referalfriendimage"} />
                                                )
                                            }
                                        </>
                                    )
                            }
                        </div>
                        {Cookies.get("institution_id") != "30" ? (loading5 == true) && (<PreloaderTwo />) : ""}
                        <AsideNavbar onClick={() => this.menuToggler()} />
                        <div className="student-overlay" onClick={() => this.menuToggler()} />
                        <div className="content-wrapper pt-0">
                            <Container>
                                {(Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 || Cookies.get("examid") == 9 || Cookies.get("examid")== 10 || Cookies.get("examid")== 11 ) ? (
                                    !loading5 && (
                                        <EamcetHome
                                            stateData={this.state}
                                            studentGlobals={studentGlobals}
                                            isStudentUserValid={isStudentUserValid.isStudentUserValid}
                                        />
                                    )
                                ) : (

                                        !loading5 && (
                                            <StudentHomeSection
                                                stateData={this.state}
                                                studentGlobals={studentGlobals}
                                                getSubjects={this.state.getSubjects}
                                                handleSelect={this.handleSelect}
                                                isStudentUserValid={isStudentUserValid.isStudentUserValid} />)

                                    )
                                }
                            </Container>
                        </div>
                    </div>
                </div>
            )
        }
        return (<PreloaderTwo />);

    }
}


export default withRouter(compose(

    graphql(FETCH_ISSTUDENTUSERVALID,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: "cache-and-network"
            }), name: "isStudentUserValid"
        }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),

)(StudentHome));
