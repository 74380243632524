import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import IpeVideosSection from '../components/IpeVideos/IpeVideosSection'
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';



const FETCH_SERVICE_DATA_FOR_IPE = gql` 
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String
    ){
        getServiceData111(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type){
            data
            }
        }
    `;

    const UPDATE_STUDENT_ANALYTICS = gql`
  mutation(
    $params:AnalyticsStudents1  
    ) {
        studentAnalytics(
        params: $params
     )
  }
`;


class IpeClasses extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerBottomImg: {
                type:"eamcet_view",
                title:"IP IMPORTANT Q&A"
            },
            toggled: "wrapper sidebar-enable"
        }
    }

    menuToggler = () => {
        const toggled = this.state.toggled;
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    
    componentDidMount = () => {

        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Eamcet_IPE_Lectures;
	ReactGA.pageview('/student/IpeClasses',["rizee"], title);
    }
    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 123,
            source :2

        }
        console.log("addStudentObj",addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");


        const ipeClassesData = this.props.ipeClassesData;
        const loading1 = ipeClassesData.loading;
        const error1 = ipeClassesData.error;

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        let globalsubjects = "";
        if (JSON.parse(localStorage.getItem("globalsubjects")) != "") {
            globalsubjects = JSON.parse(localStorage.getItem("globalsubjects"));
        }
        else {
            this.props.history.push("/student/login");
        }


        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }

        return (

            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars 
                    headerBottom={this.state.headerBottomImg}
                    onClick={() => this.menuToggler()}
                     />
                </div>
                <div className="main-wrapper">
                    {/* {(loading1 == true) && (<PreloaderTwo />)} */}
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper" style={{ paddingTop: 10, minHeight: "80vh", backgroundColor: "#f6f7fb" }}>
                        {
                            loading1 ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <IpeVideosSection
                                        globalsubjects={globalsubjects}
                                        ipeClassesData={ipeClassesData}
                                    />
                                )
                        }

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(
    graphql(FETCH_SERVICE_DATA_FOR_IPE, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                service_type: "IP_Classes",
                service_sub_type: "All"

            },
            fetchPolicy: 'network-only'
        }), name: "ipeClassesData"
    }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
)(IpeClasses));
