// Exams
export const Exams = [
    { value: 1, label: 'NEET' },
    { value: 2, label: 'JEE' }
];

// Subjects
export const Subjects = [
    { value: 1, label: 'Maths' },
    { value: 2, label: 'Physics' },
    { value: 3, label: 'Biology' },
    { value: 4, label: 'Chemistry' }
];

// topics
export const Topics = [
    { value: 1, label: 'topics-1' },
    { value: 2, label: 'topics-2' },
    { value: 3, label: 'topics-3' },
    { value: 4, label: 'topics-4' }
];

// Answers
export const Answers = [
    { value: 1, label: 'Answers-1' },
    { value: 2, label: 'Answers-2' },
    { value: 3, label: 'Answers-3' },
    { value: 4, label: 'Answers-4' }
];
// Images
export const Images = [
    { value: 1, label: 'Images-1' },
    { value: 2, label: 'Images-2' },
    { value: 3, label: 'Images-3' },
    { value: 4, label: 'Images-4' }
];

// Correct Answer
export const CorrectAnswer = [
    { value: 1, label: 'Correct Answer-1' },
    { value: 2, label: 'Correct Answer-2' },
    { value: 3, label: 'Correct Answer-3' },
    { value: 4, label: 'Correct Answer-4' }
];

// Complexity
export const Complexity = [
    { value: 1, label: 'Moderate' },
    { value: 2, label: 'Difficult' },
    { value: 3, label: 'Easy' }
];

// QuestionTheory
export const QuestionTheory = [
    { value: 1, label: 'Applications' },
    { value: 2, label: 'Concept' }
];

// typeofquestions
export const Typeofquestions = [
    { value: 1, label: 'A & R ' },
    { value: 2, label: 'Comprehension' },
    { value: 3, label: ' Diagram ' },
    { value: 4, label: ' Formula ' },
    { value: 5, label: ' General ' },
    { value: 6, label: ' Integer ' },
    { value: 7, label: ' Linkage ' },
    { value: 8, label: ' Matching ' },
    { value: 9, label: ' Matrix ' },
    { value: 10, label: ' Matching ' },
];
