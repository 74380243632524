import React, { Component } from 'react'
import MockTestSeriesSection from '../components/mock_series/MockTestSeriesSection'
import AsideNavbar from '../components/navbars/AsideNavbar'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'
import { withRouter } from 'react-router-dom';
import * as Cookies from "es-cookie";
import moment from 'moment';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import * as compose from 'lodash.flowright';
import JeeNEwPlanPopup from '../components/mock_series/jeeNewPlanpopup'

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

class MockTestSeries extends Component {
    constructor(props) {
        super(props)

        this.state = {
            headerBottomImg: {
                Img: require('../../images/mocktext-img.png'),
                Title: "Mock Test",
                width: 150,
                helpImg: require('../../images/Ask_me_for_Help.gif')
            },
            toggled: isMobile == true && Cookies.get("institution_id") == 30 ? "wrapper" : Cookies.get("toggle"),
            submitError: "",
            planpopup: true
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }
    componentDidMount = () => {
        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Test_Series_Home;
        ReactGA.pageview('/student/exams/test-series', ["rizee"], title);
    }

    onHide = () => {
        this.setState({
            planpopup: false
        })
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 7,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
    menuToggler = () => {
        const toggled = this.state.toggled;
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    render() {
        const isuserValid = JSON.parse(this.isStudentUserValid.user_access_restictions);

        return (
            <div className={this.state.toggled}>
                <div className="student main-wrapper">
                    <div className="student header-area mock-tests-eries-topnavbar">
                        <ScrollTopNavbar headerBottom={this.state.headerBottomImg} onClick={() => this.menuToggler()} />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper">
                        <MockTestSeriesSection data={this.props} />
                        {/* {
                        isuserValid.jeePrepBundleAcess == true?
                        (
                            <JeeNEwPlanPopup
                            show={this.state.planpopup}
                            onHide={this.onHide}
                        />
                        )
                        :
                        ("")
                       } */}                       
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(

    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
)(MockTestSeries));