import React, { Component } from 'react'
import AsideNavbar from '../components/navbars/AsideNavbar'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar';
import Navbars from '../components/navbars/Navbars';
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import ShortListPageSection from '../components/collegeRankPredictor/short-list-page/ShortListPageSection';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withApollo } from "react-apollo";
import { Row, Col, Image, Container } from 'react-bootstrap';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const FETCH_CASTES = gql` 
query($id: Int) {
    getCasteNames(id: $id){
        id
        main_caste
     }
}
`;
const DELETE_BRANCH_COLLEGE_DETATILS = gql`
    mutation(
        $params:CollegePrediction1
    ){
        deleteStudentBranchCollegeDetails(
            params:$params
        )
    }
    `;

const ORDER_WISE_SHORT_LIST = gql`
    mutation(
        $params :OrderwiseShortlistedData
    ){
        saveOrderwiseShortlistedData(
            params : $params
        ){
            collegeBranchDetails {
                id
                college_name
                branch_name
                default_order
                user_order
                college_id
                branch_id
                college_code
                rank
                last_year_cutoffs{
                    eamcet_seat_category_name
                    start_rank
                    end_rank
                  }
            }
            
            
        }
    }
    `;

const ORDER_UPDATE_WISE_SHORT_LIST = gql`
    mutation(
        $params :OrderwiseUpdateShortlistedData
    ){
        updateOrderwiseShortlistedData(
            params : $params
        ){
            collegeBranchDetails {
                id
                college_name
                branch_name
                default_order
                user_order
                college_id
                branch_id
                college_code
                rank
                last_year_cutoffs{
                    eamcet_seat_category_name
                    start_rank
                    end_rank
                  }
            }
            
          
        }
    }
    `;

const FETCH_STUDENT_WEB_OPTIONS = gql` 
    query($mobile: String,$exam_id:Int) {
        getStudentWebOptions(mobile: $mobile,exam_id:$exam_id){
            rank
            marks
            eamcet_caste_id
            eamcet_seat_category_id
            selection_type
         }
    }
    `;

const PRIMARY_SAVE = gql`
    mutation(
        $params : StudentOptions
    ){
        saveStudentWebOptions(
            params : $params
        )
    }
    `;
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class ShortListPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerBottomImg: {
                type: "col_pred_short_list",
            },
            toggled: isMobile == true && Cookies.get("institution_id") == 30 ? "wrapper" : "wrapper",
            exam_type_id: props?.location?.state.exam_type_value,
            examActiveType: props?.location?.state.exam_type_value == 3 ? "AP" : "TS",
            caste: "",
            subcasteseldata: [],
            subcasteselvalue: [],
            subCasteData: [],
            input_priority_type: '2',
            input_pattern_type: "rank",
            inputRank_Marks_count: 0,
            orderPriorityDetails: [],
            uniqueBranchList: [],
            uniqueCollegesList: [],
            savingOrderBranchList: [],
            savingOrderCollegeList: [],
            error_display_caste: '',
            error_display_sub_caste: '',
            error_display_priority_type: '',
            error_display_rank_marks: '',
            spinnerBlockShow: 'hide',
            checked_for_branch: true,
            checked_for_college: false,
            getDifficultyLevelCollegesData: [],
            getStudentWebOptions_examType: [],
            onloadWebOptions: "onload",
            orderspinnerBlockShow: "hide",
            new_Branches_colleges: [],
            spinnerPrimaryBlockShow: "hide",
            successMessage: "",
            rank_radio_box: true,
            marks_radio_box: false,
            disabled_button: false,
            deleteRecordSpinnerShow: 'hide',
            deleteModal: false,
            deleteItem: "",
            deleteMessage: ""


        }

        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }

    }


    componentDidMount() {

    };

    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    changeExamType = async (e, exam_type_val) => {
        let getCasteNamesData = [];
        this.setState({ examActiveType: '', onloadWebOptions: "change" });
        if (exam_type_val == 3) {
            this.setState({ examActiveType: 'AP' });
            this.setState({ exam_type_id: 3 });
        } else {
            this.setState({ examActiveType: 'TS' });
            this.setState({ exam_type_id: 6 });
        }

        this.setState({
            subcasteseldata: [],
            subcasteselvalue: [],
            subCasteData: [],
            caste: '',
            input_pattern_type: "rank",
            inputRank_Marks_count: 0,
            input_priority_type: '2',
            orderPriorityDetails: [],
            uniqueBranchList: [],
            uniqueCollegesList: [],
            savingOrderBranchList: [],
            savingOrderCollegeList: [],
            error_display_caste: '',
            error_display_sub_caste: '',
            error_display_priority_type: '',
            error_display_rank_marks: '',
            spinnerBlockShow: 'hide',
            checked_for_branch: false,
            checked_for_college: false,
            getStudentWebOptions_examType: [],
            orderspinnerBlockShow: 'hide',
            new_Branches_colleges: [],
            spinnerPrimaryBlockShow: "hide",
            successMessage: "",
            getDifficultyLevelCollegesData: [],
            rank_radio_box: true,
            marks_radio_box: false,
            disabled_button: false,
            deleteRecordSpinnerShow: 'hide',
            deleteModal: false,
            deleteItem: "",
            deleteMessage: ""
        });



        const result_caste = await this.props.client.query({
            query: gql` 
                query($id: Int) {
                    getCasteNames(id: $id){
                        id
                        main_caste
                    }
                }
                `,
            variables: {
                id: 0
            },
            fetchPolicy: 'network-only'
        });
        if (result_caste.data.getCasteNames.length > 0) {
            getCasteNamesData = result_caste.data.getCasteNames;
        }

        const result = await this.props.client.query({
            query: gql` 
                query($mobile: String,$exam_id:Int) {
                    getStudentWebOptions(mobile: $mobile,exam_id:$exam_id){
                        rank
                        marks
                        eamcet_caste_id
                        eamcet_seat_category_id
                        selection_type
                    }
                }
                `,
            variables: {
                mobile: Cookies.get("mobile"),
                exam_id: parseInt(exam_type_val)
            },
            fetchPolicy: 'network-only'
        });


        if (result.data.getStudentWebOptions.length > 0) {
            this.setState({
                getStudentWebOptions_examType: result.data.getStudentWebOptions
            });


            if (getCasteNamesData.length > 0) {
                getCasteNamesData.map((item) => {
                    if (item != undefined) {
                        if (this.state.getStudentWebOptions_examType[0].eamcet_caste_id == item.id) {
                            const newObj = {
                                value: item.id,
                                label: item.main_caste
                            }
                            this.setState({ caste: newObj });

                        }

                    }
                });
            }


            if (this.state.getStudentWebOptions_examType[0].rank != 0) {
                this.state.inputRank_Marks_count = this.state.getStudentWebOptions_examType[0].rank;
                this.state.rank_radio_box = true;
                this.state.marks_radio_box = false;
                this.state.input_pattern_type = "rank";
            } else {
                this.state.inputRank_Marks_count = this.state.getStudentWebOptions_examType[0].marks;
                this.state.rank_radio_box = true;
                this.state.marks_radio_box = false;
                this.state.input_pattern_type = "marks";
            }

            //this.state.input_priority_type = this.state.getStudentWebOptions_examType[0].selection_type;

            //if(this.state.getStudentWebOptions_examType[0].selection_type == 2){
            //this.state.checked_for_branch=true;
            //this.state.checked_for_college=false;
            //}else{
            //this.state.checked_for_branch=false;
            //this.state.checked_for_college=true;
            //}
        }

        if (this.state.getStudentWebOptions_examType.length > 0) {

            const result1 = await this.props.client.query({
                query: gql` 
                        query($eamcet_caste_id: Int!,$state_id:Int) {
                            getEamcetCasteSubCategories(eamcet_caste_id: $eamcet_caste_id,state_id:$state_id)
                                {
                                    id
                                    name
                                }
                        }
                    `,
                variables: {
                    eamcet_caste_id: parseInt(this.state.getStudentWebOptions_examType[0].eamcet_caste_id),
                    state_id: exam_type_val == 3 ? 2 : 24
                },
                fetchPolicy: 'network-only'
            });


            if (result1.data.getEamcetCasteSubCategories.length > 0) {
                this.setState({ subCasteData: result1.data.getEamcetCasteSubCategories })

                let etesttypevalue = Array();
                let etesttype = Array();
                var eamcet_seat_category_ids = this.state.getStudentWebOptions_examType[0].eamcet_seat_category_id.split(",");

                eamcet_seat_category_ids.map(item1 => {
                    this.state.subCasteData.filter(checkVal => checkVal.id == item1).map((item) => {
                        const newObj = {
                            value: item.id,
                            label: item.name,
                        }
                        etesttypevalue.push(newObj);
                        etesttype.push(item.id);
                        this.setState({ subcasteseldata: etesttypevalue })
                        this.setState({ subcasteselvalue: etesttype })
                    })
                })
            }

            const result2 = await this.props.client.query({
                query: gql` 
                            query($mobile: String,$exam_id:Int,$selection_type:Int) {
                                getStudentBranchCollegeDetails(mobile: $mobile,exam_id:$exam_id,selection_type:$selection_type)
                                    {
                                        collegeBranchDetails {
                                            id
                                            college_name
                                            branch_id
                                            default_order
                                            user_order
                                            college_id
                                            branch_name
                                            college_code
                                            rank
                                            last_year_cutoffs{
                                                eamcet_seat_category_name
                                                start_rank
                                                end_rank
                                              }
                                        }
                                        
                                    }
                            }
                        `,
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_id: parseInt(exam_type_val),
                    selection_type: 2
                },
                fetchPolicy: 'network-only'
            });


            if (result2.data.getStudentBranchCollegeDetails.length > 0) {
                this.setState({ orderPriorityDetails: result2.data.getStudentBranchCollegeDetails[0].collegeBranchDetails })
            }
        }

        setTimeout(() => { this.getBranchWiseData(); }, 2000);

    }

    selecthandleInputChange = async (ename, evalue) => {
        console.log("eeee", ename, evalue);
        const value = evalue;
        const name = ename;

        this.setState({
            subcasteseldata: [],
            subcasteselvalue: [],
            subCasteData: [],
        });

        if (name == 'caste') {
            this.setState({ onloadWebOptions: "change" });
            let ecasteData = this.props.getMainCastes.getCasteNames.find((a) => a.id == value);
            this.setState({
                caste: {
                    value: ecasteData.id,
                    label: ecasteData.main_caste
                },
            });

            const result = await this.props.client.query({
                query: gql` 
                        query($eamcet_caste_id: Int!,$state_id:Int) {
                            getEamcetCasteSubCategories(eamcet_caste_id: $eamcet_caste_id,state_id:$state_id)
                                {
                                    id
                                    name
                                }
                        }
                    `,
                variables: {
                    eamcet_caste_id: parseInt(value),
                    state_id: this.state.exam_type_id == 3 ? 2 : 24
                },
                fetchPolicy: 'network-only'
            });


            if (result.data.getEamcetCasteSubCategories.length > 0) {
                this.setState({
                    subCasteData: result.data.getEamcetCasteSubCategories
                });
            }

        }

    };

    deleteBranchCollegeRecordData = async (item) => {
        this.setState({ deleteRecordSpinnerShow: 'show' })
        await this.props.deleteStudentBranchCollegeDetails({

            variables: {
                params: {
                    mobile: Cookies.get("mobile"),
                    eamcet_college_id: parseInt(item.college_id),
                    eamcet_branch_id: parseInt(item.branch_id),
                    exam_id: parseInt(this.state.exam_type_id)
                }
            },
            update: async (store, { data }) => {
                if (data.deleteStudentBranchCollegeDetails) {
                    const result2 = await this.props.client.query({
                        query: gql` 
                                    query($mobile: String,$exam_id:Int,$selection_type:Int) {
                                        getStudentBranchCollegeDetails(mobile: $mobile,exam_id:$exam_id,selection_type:$selection_type)
                                            {
                                                collegeBranchDetails {
                                                    id
                                                    college_name
                                                    branch_id
                                                    default_order
                                                    user_order
                                                    college_id
                                                    branch_name
                                                    college_code
                                                    rank
                                                    last_year_cutoffs{
                                                        eamcet_seat_category_name
                                                        start_rank
                                                        end_rank
                                                      }
                                                }
                                                
                                            }
                                    }
                                `,
                        variables: {
                            mobile: Cookies.get("mobile"),
                            exam_id: parseInt(this.state.exam_type_id),
                            selection_type: 2
                        },
                        fetchPolicy: 'network-only'
                    });


                    if (result2.data.getStudentBranchCollegeDetails.length > 0) {
                        this.setState({ orderPriorityDetails: result2.data.getStudentBranchCollegeDetails[0].collegeBranchDetails, deleteRecordSpinnerShow: "hide" })
                        this.setState({ deleteMessage: "Deleted SuccessFully..." })
                        setTimeout(() => { this.setState({ deleteModal: false, deleteItem: "", deleteMessage: "" }) }, 1000);
                    } else {
                        this.setState({ orderPriorityDetails: [], getDifficultyLevelCollegesData: [], deleteRecordSpinnerShow: "hide" })
                        this.setState({ deleteMessage: "SomeThing Wrong,Please Check Once." })
                        //setTimeout(() => { this.setState({ deleteModal: false,deleteItem:"" }) }, 1000);
                    }




                    this.getBranchWiseData();
                    this.updateAndGetDetails("", "delete");


                }
            }
        });
    }

    handleMultipleSelectInputChange = async (e, name) => {
        this.setState({ disabled_button: false })
        console.log("wwwwwwwwwwwwwwwwwwww", e);
        if (name == "scaste") {
            let etesttype = Array();
            let etesttypevalue = Array();
            if (e != null && e.length > 0) {
                for (let i = 0; i < e.length; i++) {
                    const etesttypeval = e[i];
                    const newObj = {
                        label: etesttypeval.label,
                        value: etesttypeval.value
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(etesttypeval.value);
                }
                this.setState({
                    subcasteseldata: etesttypevalue,
                    subcasteselvalue: etesttype
                });
            } else {
                this.setState({
                    subcasteseldata: [],
                    subcasteselvalue: []
                });
            }
        }
    };

    handleInputChange = (e) => {
        this.setState({ disabled_button: false })

        this.setState({ onloadWebOptions: "change" });
        const value = e.target.value;

        this.setState({ inputRank_Marks_count: value });

    }

    handleSelectInputChange = (e) => {
        this.setState({ disabled_button: false })

        this.setState({ onloadWebOptions: "change" });
        console.log("eeeeeeeeeeeeeee", e.target.value);
        const value = e.target.value;
        //this.setState({ inputRank_Marks_count: 0 });
        if (value == "marks") {
            this.setState({ input_pattern_type: value, inputRank_Marks_count: 0 });
            this.setState({ rank_radio_box: false, marks_radio_box: true });
        } else if (value == "rank") {
            this.setState({ input_pattern_type: value, inputRank_Marks_count: 0 });
            this.setState({ rank_radio_box: true, marks_radio_box: false });
        } else if (value == 1) {
            this.setState({ input_priority_type: value, checked_for_branch: false, checked_for_college: true });
        } else if (value == 2) {
            this.setState({ input_priority_type: value, checked_for_branch: true, checked_for_college: false });
        }



    }

    onDragEndData = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            this.state.uniqueBranchList,
            result.source.index,
            result.destination.index
        );


        //setItems(reorderedItems);
        const reorderedData = reorderedItems;
        this.setState({
            uniqueBranchList: reorderedData
        });

    };

    onDragEndCollegeData = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            this.state.uniqueCollegesList,
            result.source.index,
            result.destination.index
        );


        //setItems(reorderedItems);
        const reorderedData1 = reorderedItems;
        this.setState({
            uniqueCollegesList: reorderedData1
        });

    };

    onDragEndPriortyOrder = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            this.state.orderPriorityDetails,
            result.source.index,
            result.destination.index
        );


        //setItems(reorderedItems);
        const reorderedData2 = reorderedItems;
        this.setState({
            orderPriorityDetails: reorderedData2
        });

    };


    updateAndGetDetails = (e, itemType) => {
        if (itemType == "update") {
            this.setState({ onloadWebOptions: "change", orderspinnerBlockShow: 'show' });
        }

        let getPriorityOrderDetails = "";

        const user_order_change_details = this.state.orderPriorityDetails.map((item, index) => {
            return { eamcet_college_id: item.college_id, eamcet_branch_id: item.branch_id, user_change_order_no: parseInt(index + 1) }
        });

        const branch_ids = this.state.orderPriorityDetails.map(item => item.branch_id);
        const unique_branch_ids = [...new Set(branch_ids)]

        const college_ids = this.state.orderPriorityDetails.map(item => item.college_id);
        const unique_college_ids = [...new Set(college_ids)]


        getPriorityOrderDetails = {
            mobile: Cookies.get("mobile"),
            exam_id: parseInt(this.state.exam_type_id),
            selection_type: parseInt(this.state.input_priority_type),
            priorityOrderDetails: user_order_change_details,
            unique_branch_ids: unique_branch_ids.toString(),
            unique_college_ids: unique_college_ids.toString()
        }

        this.updateOrderwiseShortlistedData(getPriorityOrderDetails, itemType).catch(error => {
            console.log("catch if error");
            console.log(error);
        });



    }

    updateOrderwiseShortlistedData = async (params, itemType) => {
        await this.props.updateOrderwiseShortlistedData({
            variables: {
                params
            },
            update: async (store, { data }) => {
                var collegeBranchDetails = [];
                data.updateOrderwiseShortlistedData.map(item => {
                    console.log("data.ShortlistedData", data.ShortlistedData);
                    item.collegeBranchDetails.map(details => {
                        collegeBranchDetails.push(details);
                    })

                });

                this.setState({ orderPriorityDetails: collegeBranchDetails });
                if (itemType == "update") {
                    this.setState({ orderspinnerBlockShow: "hide" });
                }

                this.getBranchWiseData();


                const result = await this.props.client.query({
                    query: gql` 
                            query($mobile: String,$exam_id:Int) {
                                getShortlistedCollegesBranches(mobile: $mobile,exam_id:$exam_id)
                                    {
                                        mobile
                                        branches{
                                            id
                                            branch_name
                                            order_no
                                            index_val
                                        }
                                        colleges{
                                            id
                                            college_name
                                            order_no
                                            index_val
                                        }
                                    }
                            }
                        `,
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_id: parseInt(this.state.exam_type_id)
                    },
                    fetchPolicy: 'network-only'
                });


                if (result.data.getShortlistedCollegesBranches.length > 0) {
                    if (result.data.getShortlistedCollegesBranches.length > 0) {
                        this.setState({
                            uniqueBranchList: result.data.getShortlistedCollegesBranches[0].branches,
                            uniqueCollegesList: result.data.getShortlistedCollegesBranches[0].colleges

                        });

                        const newBranches = this.state.uniqueBranchList.filter(item2 => item2.index_val == 0).map(item => item.index_val)
                        const newColleges = this.state.uniqueCollegesList.filter(item2 => item2.index_val == 0).map(item => item.index_val)


                        if (newBranches.length > 0 || newColleges.length > 0) {
                            this.setState({ new_Branches_colleges: [] });
                        }

                    }

                }

            }
        });
    }

    saveAndGetDetails = () => {
        this.setState({ onloadWebOptions: "change", disabled_button: true });
        let getSelDetails = "";
        let rank_val = 0, marrks_val = 0;
        this.setState({ error_display_rank_marks: "", error_display_caste: '', error_display_sub_caste: '', error_display_priority_type: '' });

        if (this.state.input_pattern_type == "rank") {
            rank_val = this.state.inputRank_Marks_count;
        } else {
            marrks_val = this.state.inputRank_Marks_count;
        }

        if (this.state.input_pattern_type == "rank" && rank_val == 0) {
            this.setState({ error_display_rank_marks: "Please Enter Rank" });
            return;
        }
        else if (this.state.input_pattern_type == "marks" && marrks_val == 0) {
            this.setState({ error_display_rank_marks: "Please Enter Marks" });
            return;
        }
        else if (this.state.input_pattern_type == "marks" && this.state.inputRank_Marks_count > 160) {
            this.setState({ error_display_rank_marks: "Please Enter Valid Marks" });
            return;
        }

        if (this.state.caste == '' || this.state.caste == 0) {
            this.setState({ error_display_caste: "Please Select Caste" });
            return;
        }

        if (this.state.subcasteselvalue.toString() == '') {
            this.setState({ error_display_sub_caste: "Please Select Sub Category" });
            return;
        }
        if (this.state.input_priority_type == '') {
            this.setState({ error_display_priority_type: "Please Select Priority" });
            return;
        }

        this.setState({ orderPriorityDetails: [] });
        this.setState({ spinnerBlockShow: "show" });

        this.state.savingOrderBranchList = this.state.uniqueBranchList.map((item, index) => {
            return { eamcet_branch_id: parseInt(item.id), order_no: parseInt(index + 1) }
        });

        this.state.savingOrderCollegeList = this.state.uniqueCollegesList.map((item, index) => {
            return { eamcet_college_id: parseInt(item.id), order_no: parseInt(index + 1) }
        });

        getSelDetails = {
            mobile: Cookies.get("mobile"),
            exam_id: parseInt(this.state.exam_type_id),
            rank: parseInt(rank_val),
            marks: parseInt(marrks_val),
            eamcet_caste_id: parseInt(this.state.caste.value),
            eamcet_seat_category_id: this.state.subcasteselvalue.toString(),
            selectionType: parseInt(this.state.input_priority_type),
            branchShortlist: this.state.savingOrderBranchList,
            collegeShortlist: this.state.savingOrderCollegeList
        }

        this.saveOrderwiseShortlistedData(getSelDetails).catch(error => {
            console.log("catch if error");
            console.log(error);
        })
    }

    saveOrderwiseShortlistedData = async (params) => {
        await this.props.saveOrderwiseShortlistedData({
            variables: {
                params
            },
            update: async (store, { data }) => {
                var collegeBranchDetails = [];
                data.saveOrderwiseShortlistedData.map(item => {
                    item.collegeBranchDetails.map(details => {
                        collegeBranchDetails.push(details);
                    })

                });

                this.setState({ orderPriorityDetails: collegeBranchDetails });
                this.setState({ spinnerBlockShow: "hide", disabled_button: false });
                this.getBranchWiseData();

                const result = await this.props.client.query({
                    query: gql` 
                            query($mobile: String,$exam_id:Int) {
                                getShortlistedCollegesBranches(mobile: $mobile,exam_id:$exam_id)
                                    {
                                        mobile
                                        branches{
                                            id
                                            branch_name
                                            order_no
                                            index_val
                                        }
                                        colleges{
                                            id
                                            college_name
                                            order_no
                                            index_val
                                        }
                                    }
                            }
                        `,
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_id: parseInt(this.state.exam_type_id)
                    },
                    fetchPolicy: 'network-only'
                });


                if (result.data.getShortlistedCollegesBranches.length > 0) {
                    if (result.data.getShortlistedCollegesBranches.length > 0) {
                        this.setState({
                            uniqueBranchList: result.data.getShortlistedCollegesBranches[0].branches,
                            uniqueCollegesList: result.data.getShortlistedCollegesBranches[0].colleges

                        });

                        const newBranches = this.state.uniqueBranchList.filter(item2 => item2.index_val == 0).map(item => item.index_val)
                        const newColleges = this.state.uniqueCollegesList.filter(item2 => item2.index_val == 0).map(item => item.index_val)



                        this.setState({ new_Branches_colleges: [] });

                    }

                }




            }
        });
    }

    getBranchWiseData = async (e) => {
        this.setState({ error_display_rank_marks: "", error_display_caste: '', error_display_sub_caste: '', error_display_priority_type: '' });
        const result = await this.props.client.query({
            query: gql` 
                query($mobile:String,$exam_id:Int,$state:Int) {
                    getDifficultyLevelColleges(mobile:$mobile,exam_id:$exam_id,state:$state){
                        branch_name
                        branch_id
                        values{
                            easy
                            moda
                            diff
                        }
                    }
                }
                `,
            variables: {
                mobile: Cookies.get("mobile"),
                exam_id: parseInt(this.state.exam_type_id),
                state: this.state.exam_type_id == 3 ? 2 : 24,
            },
            fetchPolicy: 'network-only'
        });

        if (result.data.getDifficultyLevelColleges.length > 0) {
            this.setState({
                getDifficultyLevelCollegesData: result.data.getDifficultyLevelColleges
            });
        }
    }

    getOnloadSUbCasts = async () => {
        const result = await this.props.client.query({
            query: gql` 
                    query($eamcet_caste_id: Int!,$state_id:Int) {
                        getEamcetCasteSubCategories(eamcet_caste_id: $eamcet_caste_id,state_id:$state_id)
                            {
                                id
                                name
                            }
                    }
                `,
            variables: {
                eamcet_caste_id: parseInt(this.state.getStudentWebOptions_examType[0].eamcet_caste_id),
                state_id: this.state.exam_type_id == 3 ? 2 : 24
            },
            fetchPolicy: 'network-only'
        });

        if (result.data.getEamcetCasteSubCategories.length > 0) {
            this.setState({ subCasteData: result.data.getEamcetCasteSubCategories });

            let etesttypevalue = Array();
            let etesttype = Array();
            var eamcet_seat_category_ids = this.state.getStudentWebOptions_examType[0].eamcet_seat_category_id.split(",");

            eamcet_seat_category_ids.map(item1 => {
                this.state.subCasteData.filter(checkVal => checkVal.id == item1).map((item) => {
                    const newObj = {
                        value: item.id,
                        label: item.name,
                    }
                    etesttypevalue.push(newObj);
                    etesttype.push(item.id);
                    this.setState({ subcasteseldata: etesttypevalue })
                    this.setState({ subcasteselvalue: etesttype })

                })
            })
        } else {
            this.setState({ subCasteData: [] })
        }
    }

    getOnloadPriorityOrder = async () => {
        const result1 = await this.props.client.query({
            query: gql` 
                    query($mobile: String,$exam_id:Int,$selection_type:Int) {
                        getStudentBranchCollegeDetails(mobile: $mobile,exam_id:$exam_id,selection_type:$selection_type)
                            {
                                collegeBranchDetails {
                                    id
                                    college_name
                                    branch_id
                                    default_order
                                    user_order
                                    college_id
                                    branch_name
                                    college_code
                                    rank
                                    last_year_cutoffs{
                                        eamcet_seat_category_name
                                        start_rank
                                        end_rank
                                    }
                                }
                                
                            }
                    }
                `,
            variables: {
                mobile: Cookies.get("mobile"),
                exam_id: parseInt(this.state.exam_type_id),
                selection_type: 2
            },
            fetchPolicy: 'network-only'
        });

        if (result1.data.getStudentBranchCollegeDetails.length > 0) {
            this.setState({ orderPriorityDetails: result1.data.getStudentBranchCollegeDetails[0].collegeBranchDetails })
        } else {
            this.setState({ orderPriorityDetails: [] })
        }
    }

    savePrimaryDetails = () => {
        let getPrimarySelDetails = "";
        let rank_val = 0, marrks_val = 0;
        this.setState({ error_display_rank_marks: "", error_display_caste: '', error_display_sub_caste: '', error_display_priority_type: '' });

        if (this.state.input_pattern_type == "rank") {
            rank_val = this.state.inputRank_Marks_count;
        } else {
            marrks_val = this.state.inputRank_Marks_count;
        }

        if (this.state.input_pattern_type == "rank" && rank_val == 0) {
            this.setState({ error_display_rank_marks: "Please Enter Rank" });
            return;
        } else if (this.state.input_pattern_type == "marks" && marrks_val == 0) {
            this.setState({ error_display_rank_marks: "Please Enter Marks" });
            return;
        } else if (this.state.input_pattern_type == "marks" && this.state.inputRank_Marks_count > 160) {
            this.setState({ error_display_rank_marks: "Please Enter Valid Marks" });
            return;
        }

        if (this.state.caste == '' || this.state.caste == 0) {
            this.setState({ error_display_caste: "Please Select Caste" });
            return;
        }

        if (this.state.subcasteselvalue.toString() == '') {
            this.setState({ error_display_sub_caste: "Please Select Sub Category" });
            return;
        }


        this.setState({ spinnerPrimaryBlockShow: "show" });


        getPrimarySelDetails = {
            mobile: Cookies.get("mobile"),
            exam_id: parseInt(this.state.exam_type_id),
            rank: parseInt(rank_val),
            marks: parseInt(marrks_val),
            eamcet_caste_id: parseInt(this.state.caste.value),
            eamcet_seat_category_id: this.state.subcasteselvalue.toString(),
        }

        this.saveStudentWebOptions(getPrimarySelDetails).catch(error => {
            console.log("catch if error");
            console.log(error);
        })
    }

    saveStudentWebOptions = async (params) => {
        await this.props.saveStudentWebOptions({
            variables: {
                params
            },
            update: (store, { data }) => {
                this.setState({ spinnerPrimaryBlockShow: "hide" });
                console.log("ddddddd", data);
                if (data.saveStudentWebOptions == true) {
                    this.setState({ successMessage: "Updated" });
                    this.setState({ disabled_button: false })

                }
            }
        });
    }

    deleteRecordModal = (item) => {
        console.log("ssssssssssssssssssss", item)
        this.setState({ deleteModal: true, deleteItem: item })
    }

    onHideDeleteModal = () => {
        this.setState({ deleteModal: false, deleteItem: "" })
    }



    render() {


        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        console.log("sssssssss", this.state.exam_type_id);
        const getMainCastes = this.props.getMainCastes
        const loading1 = getMainCastes.loading
        const error1 = getMainCastes.error


        const getStudentWebOptions = this.props.getStudentWebOptions;
        const loading2 = getStudentWebOptions.loading
        const error2 = getStudentWebOptions.error

        if (this.state.onloadWebOptions == "onload") {
            this.state.getStudentWebOptions_examType = getStudentWebOptions.getStudentWebOptions;

            console.log("arrrrrrrrr", this.state.getStudentWebOptions_examType);


            if (this.state.getStudentWebOptions_examType != undefined && this.state.getStudentWebOptions_examType.length > 0) {
                if (this.state.getStudentWebOptions_examType[0].rank != 0) {
                    this.state.inputRank_Marks_count = this.state.getStudentWebOptions_examType[0].rank;
                    this.state.rank_radio_box = true;
                    this.state.marks_radio_box = false;
                    this.state.input_pattern_type = "rank";
                } else {
                    this.state.inputRank_Marks_count = this.state.getStudentWebOptions_examType[0].marks;
                    this.state.rank_radio_box = false;
                    this.state.marks_radio_box = true;
                    this.state.input_pattern_type = "marks";
                }

                //this.state.input_priority_type = this.state.getStudentWebOptions_examType[0].selection_type;

                if (this.state.getStudentWebOptions_examType[0].selection_type == 2) {
                    //this.state.checked_for_branch=true;
                    //this.state.checked_for_college=false;
                } else {
                    // this.state.checked_for_branch=false;
                    //this.state.checked_for_college=true;
                }

                if (getMainCastes.getCasteNames != undefined && getMainCastes.getCasteNames.length > 0) {
                    getMainCastes.getCasteNames.map((item) => {
                        if (item != undefined) {
                            if (this.state.getStudentWebOptions_examType[0].eamcet_caste_id == item.id) {
                                const newObj = {
                                    value: item.id,
                                    label: item.main_caste
                                }
                                this.state.caste = newObj

                            }

                        }
                    });
                }
            }


        }




        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        return (
            <div className={this.state.toggled}>
                <div className="student main-wrapper">
                    <div className="student header-area col_pred_short_list">
                        <Navbars
                            headerBottom={this.state.headerBottomImg}
                            onClick={() => this.menuToggler()}
                            pageType="col_pred_short_list" />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper-pad0">

                        {(loading1 == true || loading2 == true) && (
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                    </Col>
                                </Row>
                            </Container>
                        )}

                        {
                            !loading1 && !loading2 && (

                                <ShortListPageSection
                                    deleteRecordModal={this.deleteRecordModal}
                                    onHideDeleteModal={this.onHideDeleteModal}
                                    deleteBranchCollegeRecord={this.deleteBranchCollegeRecordData}
                                    getMainCastes={getMainCastes.getCasteNames}
                                    changeExamType={this.changeExamType}
                                    stateData={this.state}
                                    selecthandleInputChange={this.selecthandleInputChange}
                                    handleMultipleSelectInputChange={this.handleMultipleSelectInputChange}
                                    handleInputChange={this.handleInputChange}
                                    handleSelectInputChange={this.handleSelectInputChange}
                                    saveAndGetDetails={this.saveAndGetDetails}
                                    onDragEndCollegeData={this.onDragEndCollegeData}
                                    onDragEndData={this.onDragEndData}
                                    onDragEndPriortyOrder={this.onDragEndPriortyOrder}
                                    getBranchWiseData={this.getBranchWiseData}
                                    getStudentWebOptions={this.state.getStudentWebOptions_examType}
                                    getOnloadSUbCasts={this.getOnloadSUbCasts}
                                    getOnloadPriorityOrder={this.getOnloadPriorityOrder}
                                    updateAndGetDetails={this.updateAndGetDetails}
                                    savePrimaryDetails={this.savePrimaryDetails}
                                />
                            )
                        }

                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(withApollo(compose(
    graphql(FETCH_CASTES,
        {
            options: props => ({
                variables: {
                    id: 0
                },
                fetchPolicy: "network-only"
            }), name: "getMainCastes"
        }), graphql(ORDER_WISE_SHORT_LIST, {
            name: "saveOrderwiseShortlistedData"
        }), graphql(DELETE_BRANCH_COLLEGE_DETATILS, {
            name: "deleteStudentBranchCollegeDetails"
        }), graphql(FETCH_STUDENT_WEB_OPTIONS,
            {
                options: props => ({
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_id: props.location.state.exam_type_value
                    },
                    fetchPolicy: "network-only"
                }), name: "getStudentWebOptions"
            }), graphql(ORDER_UPDATE_WISE_SHORT_LIST, {
                name: "updateOrderwiseShortlistedData"
            }), graphql(PRIMARY_SAVE, {
                name: "saveStudentWebOptions"
            })
)(ShortListPage)));