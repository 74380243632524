import React, { Component } from 'react'
import { Row, Col, Card, Image, Button, Accordion } from 'react-bootstrap'
import "./_mock-test-series.scss"
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter, Link } from "react-router-dom";
import * as Cookies from "es-cookie";
import PreloaderTwo from '../preloader/PreloaderTwo';
import UserRestrictionAlert from "../home/UserRestrictionAlert";
import moment from 'moment';

const STUDENR_PREVIOUS_EXAM = gql`
  mutation(
    $params:StudentPreviousPaperExam  
    ) {
        studentPreviousPaperExam(
        params: $params
     )
  }
`;



class FreeMockTestSectionSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userRestionModalShow: false,
            currentActiveKey: "",
            yearActivekey: "",
            activeYear: ""
        };
    }
    toggle = (index) => {
        if (this.state.currentActiveKey != index.toString()) {
            this.setState({ currentActiveKey: index })
        }
        else {
            this.setState({ currentActiveKey: "" })
        }

    }
    yeartoggle = (index, year) => {
        if (this.state.yearActivekey != index.toString()) {
            this.setState({ yearActivekey: index, activeYear: year })
        }
        else {
            this.setState({ yearActivekey: "" })
        }

    }
    customUserResctrict = (e, modaldata, a) => {
        let isStudentUserValid = "";
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        else {
            this.props.history.push("/student/login");
        }


        const moduleValid = JSON.parse(isStudentUserValid.module_restrictions);

        if ((a.enabled == true)) {
            // if (modaldata == false) {
            //     this.handleFormSubmit(e, a.year, a.id, a.pexamtype)
            // }
            // else {
            //     this.setState({
            //         userRestionModalShow: true
            //     });
            // }
            this.handleFormSubmit(e, a.year, a.id, a.pexamtype)

        }
        else {
            this.setState({
                userRestionModalShow: true
            });
        }
    }
    customUserResctrictResume = (e, modaldata, a) => {
        let isStudentUserValid = "";
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        else {
            this.props.history.push("/student/login");
        }


        const moduleValid = JSON.parse(isStudentUserValid.module_restrictions);

        if ((a.enabled == true)) {
            // if (modaldata == false) {
            //     this.handleFormSubmit(e, a.year, a.id, a.pexamtype)
            // }
            // else {
            //     this.setState({
            //         userRestionModalShow: true
            //     });
            // }
            this.handleFormSubmitResume(e, a.year, a.id, a.pexamtype, a.start_session_id)

        }
        else {
            this.setState({
                userRestionModalShow: true
            });
        }
    }
    handleFormSubmit = (e, year, id, pexamtype) => {
        console.log("handleFormSubmit", year, id, pexamtype);
        e.preventDefault();
        const params = {
            mobile: Cookies.get("mobile"),
            sub_type: year,
            exam_type: parseInt(pexamtype),
            source: 0,
            set_id: parseInt(id),
            selected_years: [],
            selected_classes: [],
            question_types: [],
            complexity: [],
            question_theory: [],

        };
        console.log("params", params);
        this.customfunction(
            params
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
            console.error("ERR =>", error.graphQLErrors.map(x => x.message));
        });

    };

    handleFormSubmitResume = (e, year, id, pexamtype, start_session_id) => {
        console.log("handleFormSubmit", year, id, pexamtype);
        e.preventDefault();
        const params = {
            mobile: Cookies.get("mobile"),
            sub_type: year,
            exam_type: parseInt(pexamtype),
            source: 0,
            set_id: parseInt(id),
            selected_years: [],
            selected_classes: [],
            question_types: [],
            complexity: [],
            question_theory: [],

        };
        console.log("params", params);
        this.customfunctionResume(start_session_id)
        // .catch(error => {
        //     console.log("catch if error");
        //     console.log(error);
        //     this.setState({
        //         submitError: error.graphQLErrors.map(x => x.message)
        //     });
        //     console.error("ERR =>", error.graphQLErrors.map(x => x.message));
        // });

    };
    customfunction = async (
        params) => {
        await this.props.customfunction({
            variables: {
                params
            },
            update: (store, { data }) => {
                console.log("updatedata", data);
                if (data.studentPreviousPaperExam) {
                    localStorage.setItem("sessionid", data.studentPreviousPaperExam);
                    localStorage.setItem("type", "Previous Paper Exam");
                    localStorage.setItem("stype", "");
                    localStorage.setItem("exam_paper_id", "0");
                    localStorage.setItem("etype", "jeemainsprevious_exam");

                    window.open("/student/subject/exam", "_blank");

                    this.props.history.push({
                        pathname: "/student/home",

                    })
                }
            }
        });
    };

    customfunctionResume = (
        start_session_id) => {
        localStorage.setItem("sessionid", start_session_id);
        localStorage.setItem("type", "Previous Paper Exam");
        localStorage.setItem("stype", "");
        localStorage.setItem("exam_paper_id", "0");
        localStorage.setItem("etype", "jeemainsprevious_exam");
        localStorage.setItem("resumeExam", true);
        window.open("/student/subject/exam", "_blank");

        this.props.history.push({
            pathname: "/student/home",

        })
    };
    handleResultFunction = (id) => {

        this.props.history.push({
            pathname: "/student/subject/exam-result",
            state: {
                sessionid: id,
                examtype: "jeemainsprevious_exam",
            }
        })
    }

    graphValue(getseries) {
        let data = "";
        if (getseries != undefined) {
            data = parseInt(getseries.correct_marks) - parseInt(getseries.negative_marks);
        }
        //console.log("graphValue123", data);
        return data;

    }


    render() {
        let examYears = ['2022', '2021'];
        //for StudentUserValid
        let isStudentUserValid = "";
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        else {
            this.props.history.push("/student/login");
        }

        const isuserValid = JSON.parse(isStudentUserValid.user_access_restictions);
        const moduleValid = JSON.parse(isStudentUserValid.module_restrictions);
        let previousSets = "";
        //console.log("this.props.studentGlobals.previousSets",this.props.studentGlobals.previousSets.filter((a)=>a.mains_2021==true));
        let previous_Papers = this.props.getPreviousMainsPapers;
        let years_UniqueArr = [...new Set(previous_Papers.map(data => data.year))];
        let filter_years = examYears.filter(val => !years_UniqueArr.includes(val));
        let unique_years = years_UniqueArr.concat(filter_years).sort((a, b) => b - a);
        let examsData = [];
        unique_years.forEach(function (item1) {
            let examValues = [];
            let obj = {
                year: item1,
                value: examValues
            }
            previous_Papers.map((item) => {
                if (item.year == obj.year) {
                    let obj1 = {
                        ...item
                    }
                    examValues.push(obj1)
                }
            });
            examsData.push(obj)
        });

        let filterExamData = examsData.filter((item) => item.year == this.state.activeYear);
        let examgroup = [];
        filterExamData.map((item) => {
            item.value.map((monthdata) => {
                examgroup.push(monthdata.examgroup);
            })
        });
        // console.log("filterExamData", filterExamData);
        const uniqueArr = [... new Set(examgroup.map(data1 => data1.trimRight().trimLeft()))]
        return (
            <React.Fragment>
                <Accordion>
                    {
                        unique_years.map((item, index) => {
                            console.log("Accordion", item);
                            return (
                                <React.Fragment key={index} className="border-0">
                                    <Accordion.Toggle
                                        as={Button}
                                        variant="link text-dark font-weight-bold text-decoration-none d-flex align-items-center px-0"
                                        eventKey={index}
                                        onClick={() => this.yeartoggle(index, item)}
                                    >
                                        <div className="h4 mb-0 px-2">

                                            {/* {this.state.yearActivekey == index.toString() ? (
                                                <i className="fa-fw far fa-minus-square mr-2" style={{ cursor: "pointer" }}></i>
                                            ) : (
                                                <i className="fa-fw far fa-plus-square mr-2" style={{ cursor: "pointer" }}></i>
                                            )} */}
                                            {this.state.yearActivekey == index.toString() ? (
                                                <i className="fas fa-chevron-up icon_bg" style={{ cursor: "pointer" }}></i>
                                            ) : (
                                                <i className="fas fa-chevron-down icon_bg" style={{ cursor: "pointer" }}></i>
                                            )}
                                                <span className='ml-2'>{item}</span>

                                        </div>

                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={index}>
                                        <React.Fragment>
                                            <Accordion >
                                                {
                                                    uniqueArr.length > 0 ?
                                                        (
                                                            uniqueArr.map((item, index) => {
                                                                console.log("Accordion", item);
                                                                return (
                                                                    <React.Fragment key={index} className="border-0 ">
                                                                        <Accordion.Toggle
                                                                            as={Button}
                                                                            variant="link text-dark font-weight-bold text-decoration-none d-flex align-items-center px-0"
                                                                            eventKey={index}
                                                                            onClick={() => this.toggle(index)}
                                                                        >
                                                                            <div className="h4 mb-0 px-2 ml-3">

                                                                                {this.state.currentActiveKey == index.toString() ? (
                                                                                    <i className="fa-fw far fa-minus-square mr-2" style={{ cursor: "pointer" }}></i>
                                                                                ) : (
                                                                                    <i className="fa-fw far fa-plus-square mr-2" style={{ cursor: "pointer" }}></i>
                                                                                )}

                                                                                {item}

                                                                            </div>

                                                                        </Accordion.Toggle>
                                                                        <Accordion.Collapse eventKey={index}>
                                                                            <Col>
                                                                                <Card as={Card.Body} className="free-mock p-2">
                                                                                    <Row>
                                                                                        <Col xl={12} lg={12} md={12} className="card-left">
                                                                                            <ul className="row list-unstyled">
                                                                                                {filterExamData[0].value.map((a) => {
                                                                                                    //console.log("ss", a.enabled, moduleValid);
                                                                                                    if (a.examgroup == item) {
                                                                                                        return (
                                                                                                            <li className="col-xl-6 col-lg-12 mb-3">
                                                                                                                <Card as={Card.Body} className={"p-1 single-card active"}>
                                                                                                                    <Row noGutters className="align-items-center">
                                                                                                                        <Col xl={8} lg={7} md={12}>
                                                                                                                            <div className="d-flex align-items-center">
                                                                                                                                <div className="img-block text-center mr-2">
                                                                                                                                    <Image className="mb-2" src={require('../../../images/Neet-Exam.png')} alt="img" width="60" />
                                                                                                                                </div>
                                                                                                                                <div className="card-content">
                                                                                                                                    <h6 className="title text-uppercase">{a.qset + "-" + a.year}</h6>
                                                                                                                                    <p>Duration: 180 min</p>
                                                                                                                                    <p>Syllabus: Class 11 + Class 12</p>

                                                                                                                                    {/* <p style={{ fontWeight: "bold" }}>Date & Time : {moment.unix(a.exam_date).format("Do")} {moment.unix(a.exam_date).format("MMM")} {moment.unix(a.exam_date).format("LT")}
                                                                                    </p> */}

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </Col>
                                                                                                                        <Col xl={4} lg={5} md={12}>
                                                                                                                            <Row className="align-items-center">

                                                                                                                                {a.attempted == true ? (
                                                                                                                                    <React.Fragment>
                                                                                                                                        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                                                                                                                            <Card as={Card.Body} style={{ cursor: "pointer" }} onClick={() => this.handleResultFunction(a.exam_session_id)} className="freemock-score-card border-0 bg-darkblue p-1 text-center text-white">
                                                                                                                                                <ul className="list-inline dot-circle pr-2">
                                                                                                                                                    <li className="list-inline-item"><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /></li>
                                                                                                                                                    <li className="list-inline-item"><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /></li>
                                                                                                                                                    <li className="list-inline-item"><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /></li>
                                                                                                                                                </ul>
                                                                                                                                                <h6 className="status-subtitle mb-0">Score</h6>
                                                                                                                                                <h1 className="status-title mb-0">{this.graphValue(a)} <small>/ {a.total_marks}</small></h1>
                                                                                                                                            </Card>
                                                                                                                                        </Col>
                                                                                                                                        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                                                                                                                            <Card as={Card.Body} style={{ cursor: "pointer" }} onClick={() => this.handleResultFunction(a.exam_session_id)} className="freemock-avgtime-card border-0 bg-warning p-1 text-center">
                                                                                                                                                <ul className="list-inline dot-circle pr-1">
                                                                                                                                                    <li className="list-inline-item"><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /></li>
                                                                                                                                                    <li className="list-inline-item"><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /></li>
                                                                                                                                                    <li className="list-inline-item"><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /><i className="fas fa-circle" /></li>
                                                                                                                                                </ul>
                                                                                                                                                <h6 className="status-subtitle mb-0">AVG Time / Q</h6>
                                                                                                                                                <h1 className="status-title mb-0">{a.speed} <small> Sec</small></h1>
                                                                                                                                            </Card>
                                                                                                                                        </Col>
                                                                                                                                        <Col xs="12">
                                                                                                                                            {a.attempted == true ? (<Button
                                                                                                                                                onClick={() => this.handleResultFunction(a.session_id)}
                                                                                                                                                //onClick={(e) => this.customUserResctrict(e, isuserValid.previous_single, a)}
                                                                                                                                                className="mock-btn mt-1" block> View Result</Button>)
                                                                                                                                                : (a.is_started == false) ? (<Button className="mock-btn mt-1" onClick={(e) => this.customUserResctrict(e, isuserValid.previous_single, a)} block> Start Test</Button>) : (<Button className="mock-btn mt-1" onClick={(e) => this.customUserResctrictResume(e, isuserValid.previous_single, a)} block> Resume Test</Button>)}
                                                                                                                                        </Col>
                                                                                                                                    </React.Fragment>) : (

                                                                                                                                    <React.Fragment>

                                                                                                                                        <Col xs="12">
                                                                                                                                            {(a.enabled == true) ? (
                                                                                                                                                (a.is_started == false) ? (<Button className="mock-btn mt-2" onClick={(e) => this.customUserResctrict(e, isuserValid.previous_single, a)} block> Start Test</Button>) : (<Button className="mock-btn mt-2" onClick={(e) => this.customUserResctrictResume(e, isuserValid.previous_single, a)} block> Resume Test</Button>)
                                                                                                                                            ) : (<Button className="mock-btn mock-complated mt-2" onClick={(e) => this.customUserResctrict(e, isuserValid.previous_single, a)} block> Subscribe</Button>)}


                                                                                                                                        </Col>

                                                                                                                                    </React.Fragment>
                                                                                                                                )}

                                                                                                                            </Row>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </Card>
                                                                                                            </li>
                                                                                                        )
                                                                                                    }
                                                                                                })}

                                                                                            </ul>
                                                                                        </Col>

                                                                                    </Row>
                                                                                </Card>
                                                                            </Col>
                                                                        </Accordion.Collapse>
                                                                    </React.Fragment>

                                                                )
                                                            })
                                                        ) : (<Col sm={12}><h5 className='text-center'>Coming Soon...</h5></Col>)
                                                }
                                            </Accordion>
                                            <UserRestrictionAlert
                                                oneweekplan={false}
                                                show={this.state.userRestionModalShow}
                                                onHide={() => this.setState({ userRestionModalShow: false })}
                                            />
                                        </React.Fragment>
                                    </Accordion.Collapse>
                                </React.Fragment>
                            )
                        })
                    }
                </Accordion>
            </React.Fragment>
        )

    }
}

export default withRouter(compose(graphql(STUDENR_PREVIOUS_EXAM, {
    name: "customfunction"
})
)(FreeMockTestSectionSection));