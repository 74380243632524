import React, { Component } from 'react';
import AsideNavbar from '../components/navbars/AsideNavbar';
import Navbars from '../components/navbars/Navbars';
import * as Cookies from "es-cookie";
import ErrorExamHome from '../components/errorExamNewHome/errorExamHome.jsx';
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import {Container } from 'react-bootstrap';


const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;



class ErrorExamPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headerBottomImg: {
                type: "eamcet_view",
                title: "ERROR EXAM"
            },
        }
    }
    componentDidMount(){
        this.addStudentAnalytics()
    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 222,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        const subjects = this.props.subjects;
        const loading1 = subjects.loading;
        const error1 = subjects.error;
        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars
                        headerBottom={this.state.headerBottomImg}
                        onClick={() => this.menuToggler()}
                    />
                </div>
                <div className="main-wrapper">
                    {(loading1 == true) && (<PreloaderTwo />)}
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper" style={{ paddingTop: 10, minHeight: "80vh", backgroundColor: "#f6f7fb" }}>
                        <Container>
                            {
                                !loading1 && (
                                    <ErrorExamHome
                                        getSubjects={subjects.getSubjects}
                                        studentGlobals={studentGlobals}
                                    />)
                            }

                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(

    graphql(gql` 
    query($mobile: String,
        $exam_id: Int,
        $class_id: Int,$subject: Int) {
        getSubjects(mobile: $mobile,
        exam_id: $exam_id,
        class_id: $class_id, subject: $subject){
            id
            subjectNotAnswered
            subjectWrongAnswered
            subjectErrorCorrected
            subjectPendingQuestions
            studentChapters{
                id
                enabled
                error_questions
                exam_unanswered
                exam_wrong_answered
                practice_wrong_answered
                practice_unanswered
                class
                errorExamsEnabled
            }
            }
    }
    `,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_id: parseInt(Cookies.get("examid")),
                    class_id: parseInt(Cookies.get("classid"))

                },
                fetchPolicy: 'cache-and-network'
            }),
            name: "subjects"
        }),
        graphql(UPDATE_STUDENT_ANALYTICS, {
            name: "submitpt"
        }),
        
        )(ErrorExamPage));