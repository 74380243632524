import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import { Row, Tabs, Tab, Card, Image, Button, Col, Table, Media, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './_blueprint.scss';
import Arrow from "../../../images/eamcet_arrow.png"
import Chemistry from '../../../images/chemistry_icon.png';
import Zoology from '../../../images/zoology_icon.png';
import Physics from '../../../images/physics_icon.png';
import Maths from '../../../images/maths_icon.png';
import Botany from '../../../images/botany_icon.png';
import UserRestrictionAlertInstitute from "../home/UserRestrictionAlertInstitute";
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';
import moment from 'moment';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';


let subArray = []
// {
//     (Cookies.get("examid") == 7) || (Cookies.get("examid") == 8) ? (

//         subArray = [{ id: '1', subject: 'Botany' }, { id: '5', subject: 'Zoology' },{ id: '2', subject: 'Physics' }, { id: '3', subject: 'Chemistry' } ]
//     ) : (
//         subArray = [{ id: '4', subject: 'Mathematics' },{ id: '2', subject: 'Physics' }, { id: '3', subject: 'Chemistry' }]

//     )
// }

class BluePrintEamcet extends Component {
    constructor(props) {
        super(props)
        this.handleChapterChange = this.handleChapterChange.bind(this);

        this.state = {
            getSubjectsArr: [],
            selectedSubjectVal: "",
            getEamcetBlueprintArr: [],
            onloadSubjectWiseChapters: 0,
            classData: { value: 1, label: 'Class-XI' },
            bluePrintdata: [],
            chapterValue: "",
            subData: subArray,
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }

    // componentDidMount = () => {

    //     const title = GoogleAnalyticsArray[0].Eamcet_BluePrint;
    //     ReactGA.pageview('/student/StateBluePrint', ["rizee"], title);


    //     if (this.props.globalsubjects != undefined || this.props.globalsubjects != null) {
    //         let selectedSubject = "";
    //         const getSubjects = this.state.subData.map((gmap, index) => {
    //             if (index == 0) {
    //                 selectedSubject = gmap.id
    //                 return { ...gmap, active: "active" }

    //             }
    //             else {
    //                 return { ...gmap, active: "" }
    //             }

    //         })
    //         this.setState({
    //             getSubjectsArr: getSubjects,
    //             selectedSubjectVal: selectedSubject
    //         });
    //     }
    // }

    componentDidMount = () => {
        if (this.props.getSubjectOrder != undefined || this.props.getSubjectOrder != null) {
            let selectedSubject = "";
            const getSubjects = this.props.getSubjectOrder.map((gmap, index) => {
                if (index == 0) {
                    selectedSubject = gmap.id
                    return { ...gmap, active: "active" }

                }
                else {
                    return { ...gmap, active: "" }
                }

            })
            this.setState({
                getSubjectsArr: getSubjects,
                selectedSubjectVal: selectedSubject
            });
        }
    }

    subjectClassName(data) {
        let classname = "";
        if (data.subject == "Botany") {
            classname = `block_white botany mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Physics") {
            classname = `block_white physics mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Chemistry") {
            classname = `block_white chemistry mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Zoology") {
            classname = `block_white zoology mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Mathematics") {
            classname = `block_white maths mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Biology") {
            classname = `block_white botany mobile_card_eamcet ${data.active}`

        }

        return classname;

    }

    handleSelect = (subid) => {
        this.setState({ getEamcetBlueprintArr: [], onloadSubjectWiseChapters: 1 });
        const getSubjects = this.state.getSubjectsArr.map((gmap, index) => {
            if (gmap.id == subid) {
                return { ...gmap, active: "active" }

            }
            else {
                return { ...gmap, active: "" }
            }

        });

        let subjectWiseTestsArr = [];
        if (this.props.getEamcetBlueprintArr != undefined) {
            const getTestsArr = this.props.getEamcetBlueprintArr;
            getTestsArr.filter(item => item.class_id == parseInt(this.state.classValue) && item.subject_id == parseInt(subid))
                .map(item2 => {

                    subjectWiseTestsArr.push(item2);
                });
        }

        this.setState({
            getSubjectsArr: getSubjects,
            selectedSubjectVal: subid,
            getEamcetBlueprintArr: subjectWiseTestsArr,

        });
    }

    selectHandleInputChange = (evalue) => {
        const value = evalue
        let labelval = ""

        if (value == "1") {
            labelval = "Class  XI"

        } else {
            labelval = "Class  XII"
        }
        this.setState({
            classData: {
                value: value,
                label: labelval
            }
        })
    }

    getSubjectName = (subid) => {
        let subjectName = "";
        if (subid == "1") {
            subjectName = "Botany";
        } else if (subid == "2") {
            subjectName = "Physics";
        } else if (subid == "3") {
            subjectName = "Chemistry";
        } else if (subid == "4") {
            subjectName = "Mathematics";
        } else if (subid == "5") {
            subjectName = "Zoology";
        }else if (subid == "6") {
            subjectName = "Biology"
        }

        return subjectName;
    }

    handleChapterChange = (event) => {
        this.setState({
            chapterValue: event.target.value
        })
    }

    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }

    colFunction() {
        let columns = [
            {
                dataField: "Chapter",
                text: "Chapter Name",
                headerStyle: {
                    width: "70%",
                    background: "#E9ECF1",
                    color: "#0B3573"
                },
                sortFunc: this.sortFuncMulti.bind(this)

            },
            {
                dataField: "Questions",
                text: "No of Questions/Marks",
                headerStyle: {
                    width: "30%",
                    background: "#E9ECF1",
                    color: "#0B3573"

                },
                sortFunc: this.sortFuncMulti.bind(this)
            }
        ]
        return columns;
    }



    render() {

        const getEamcetBlueprintArr = this.props.getEamcetBlueprintArr;


        let subjectData = [];

        if (this.state.selectedSubjectVal != 0) {
            subjectData = getEamcetBlueprintArr.length>0 && getEamcetBlueprintArr[0].data.filter((item) => item.Subject_id == this.state.selectedSubjectVal && item.Class == this.state.classData.value && item.exam_id == parseInt(Cookies.get("examid")))
        }

        let keyword = this.state.chapterValue;

        if (keyword !== '') {
            subjectData = getEamcetBlueprintArr.length>0 && getEamcetBlueprintArr[0].data.filter((item) => {
                if (item.Subject_id == this.state.selectedSubjectVal) {
                    return item.Chapter.toLowerCase().startsWith(keyword.toLowerCase());


                }
            });
        }

        if(getEamcetBlueprintArr.length>0){
            this.state.bluePrintdata = subjectData;
        }
        


        return (
            <Container className={isMobile == true ? 'eamcet-blueprint mt-5' : 'eamcet-blueprint mt-2'}>
                <Row>
                    <Col sm={12}>
                        <div className='subject-blocks-view'>
                            {
                                this.state.getSubjectsArr.map(sub =>
                                    <Media className={this.subjectClassName(sub)} onClick={() => this.handleSelect(sub.id)}>
                                        <Media.Body>
                                            <h5 className='mt-2 text-center'>{sub.subject}</h5>

                                        </Media.Body>
                                        {
                                            sub.id == "1" ?
                                                (<Image src={Botany} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "2" ?
                                                (<Image src={Physics} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "3" ?
                                                (<Image src={Chemistry} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "5" ?
                                                (<Image src={Zoology} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "4" ?
                                                (<Image src={Maths} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == 6 ?
                                            (<Card.Img variant="top" src={Botany} className="mobile_sub_img" alt="subject" />) : ("")
                                        }
                                    </Media>
                                )
                            }

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <ul className='list-inline class_type_li mt-4'>
                            <li className={this.state.classData.value == "1" ? "active" : ""} onClick={() => this.selectHandleInputChange("1")}>Class XI</li>
                            <li className={this.state.classData.value == "2" ? "active ml-4" : "ml-4"} onClick={() => this.selectHandleInputChange("2")}>Class XII</li>
                        </ul>
                    </Col>

                    <Col sm={6}>
                        <input
                            className='float-right search_bar mt-4'
                            type="search"
                            placeholder='  search chapter'
                            value={this.state.chapterValue}
                            onChange={this.handleChapterChange}
                        />
                        <i className='fa fa-search search-icon'></i>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} className='mt-4'>
                        <h5>Blue Print- ( Subject : {this.getSubjectName(this.state.selectedSubjectVal)},  Class : {this.state.classData.value == 1 ? "XI" : "XII"} )</h5>
                        <hr />
                    </Col>
                </Row>

                
                {
                    this.state.bluePrintdata.length == 0 ?
                        (
                            <Col sm={12}>
                                <div className='no_data_text'>Coming Soon...</div>
                            </Col>
                        )
                        :
                        (
                            <Row>
                                <Col sm={12}>
                                    <CardLessDataTableWithSearch
                                        parentData={this.state.bluePrintdata}
                                        particlecolumns={this.colFunction()}
                                        searchBar={false}
                                        pageType="EamcetBluePrint"
                                    />
                                </Col>
                            </Row>
                        )
                }

            </Container>
        )
    }
}

export default BluePrintEamcet
