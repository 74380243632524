import React, { Component } from 'react'
import { Row, Col, Table, Image, Button, Container, Accordion, Card, Form, Tooltip, OverlayTrigger, Modal, Alert } from 'react-bootstrap';
import { components } from 'react-select'
import * as Cookies from "es-cookie";
import moment from 'moment';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import './_chapterwisequestions.scss'
import parse, { domToReact } from 'html-react-parser';


class ExplanationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    parseFun(str) {
        if (str != undefined || str != "") {
            try {
                return parse(str);
            } catch (ex) {
                return false;
            }
        }
        else {
            return false;
        }
    }

    render() {
        const answer = this.props.solution.answer
        const explanation = this.props.solution.explanation
        return (
            <Modal {...this.props} className='exp_popup'
                size="md" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>

                <Modal.Body className='p-3'>
                    <Row>
                        <Col sm={12}>
                            <h6>Answer:   {answer}</h6>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col sm={12}>
                            <Alert variant="success" className="my-3 border-success">
                                <h5>Explanation</h5>
                                {
                                    explanation == "" ?
                                        (<p>Explanation will be available Soon</p>)
                                            :
                                            (<p>{this.parseFun(explanation)}</p>)
                                }
                        </Alert>
                    </Col>
                </Row>
            </Modal.Body>
            </Modal >
        )
    }
}
export default withRouter(compose(
)(ExplanationModal));