import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import HomeSection from '../components/CbseSection/HomeSection'
import HomeNavbar from '../components/home/HomeNavbar';

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';

const FETCH_SERVICE_DATA_FOR_REPEATERS = gql` 
query(
    $exam_id: Int,
    $service_type: String,
    $service_sub_type: String
    ){
        getServiceData(
            exam_id :$exam_id  ,
            service_type : $service_type,
            service_sub_type : $service_sub_type){
            data
            }
        }
    `;

let globalsubjectsStatic = [
    { id: 4, subject: 'AllSubjects',active: "all" },
    { id: 6, subject: 'Maths',active: "" },
    { id: 1, subject: 'Biology',active: "" },
    { id: 2, subject: 'Physics',active: "" },
    { id: 3, subject: 'Chemistry',active: "" }
    
]

class Cbse extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mainindex: 0,
            getSubjectsArr:globalsubjectsStatic,
            toggled: Cookies.get("toggle"),
            subjectNameDisplay:"AllSubjects"
        }
    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    componentDidMount = () => {
        if (globalsubjectsStatic != undefined || globalsubjectsStatic != null) {
            const getSubjects = globalsubjectsStatic.map((gmap, index) => {
                if (index == 0) {
                    return { ...gmap, active: "active" }

                }
                else {
                    return { ...gmap, active: "" }
                }
            })
            this.setState({
                getSubjectsArr: getSubjects
            });
        }
    }

    handleSelect = (subid) => {
        let subjectName="";
        const getSubjects = this.state.getSubjectsArr.map((gmap, index) => {
            if (index == subid) {
                return { ...gmap, active: "active" }

            }
            else {
                return { ...gmap, active: "" }
            }

        })
        getSubjects.map((item,index) => {
            if(item.active == "active"){
                subjectName = item.subject
            }else if(item.subject =="AllSubjects"){
                subjectName=""
            }
        })

        this.setState({
            getSubjectsArr: getSubjects,
            mainindex: subid,
            subjectNameDisplay:subjectName
        });
    }

    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }
        
        
        return (
            <div className={Cookies.get("toggle")}>
                <div className="student main-wrapper">
                    <div className="student header-area">
                    <HomeNavbar
                                stateData={this.state}
                                getSubjects={this.state.getSubjectsArr}
                                onClick={() => this.menuToggler()}
                                logout={this.props.logout} 
                                pageType ="CBSE"
                                />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper">
                    <HomeSection
                        stateData={this.state}
                        getSubjects={this.state.getSubjectsArr}
                        handleSelect={this.handleSelect} 
                        physicsData={this.props.physicsData}
                        chemistryData={this.props.chemistryData}
                        biologyData={this.props.biologyData}
                        mathsData={this.props.mathsData}
                        allData={this.props.allData}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(compose(
    graphql(FETCH_SERVICE_DATA_FOR_REPEATERS  , {
            options: props => ({
                variables: {
                    exam_id:1,
                    service_type : "CBSE_Term1",
                    service_sub_type: "chemistry"
                    
                },
                fetchPolicy: 'network-only'
            }),name: "chemistryData"
        }),
        graphql(FETCH_SERVICE_DATA_FOR_REPEATERS  , {
            options: props => ({
                variables: {
                    exam_id:1,
                    service_type : "CBSE_Term1",
                    service_sub_type: "physics"
                    
                },
                fetchPolicy: 'network-only'
            }),name: "physicsData"
        }),
        graphql(FETCH_SERVICE_DATA_FOR_REPEATERS  , {
            options: props => ({
                variables: {
                    exam_id:1,
                    service_type : "CBSE_Term1",
                    service_sub_type: "biology"
                    
                },
                fetchPolicy: 'network-only'
            }),name: "biologyData"
        }),graphql(FETCH_SERVICE_DATA_FOR_REPEATERS  , {
            options: props => ({
                variables: {
                    // exam_id : parseInt(Cookies.get("examid")),
                    exam_id:1,
                    service_type : "CBSE_Term1",
                    service_sub_type: "maths"
                    
                },
                fetchPolicy: 'network-only'
            }),name: "mathsData"
        }),graphql(FETCH_SERVICE_DATA_FOR_REPEATERS  , {
            options: props => ({
                variables: {
                    // exam_id : parseInt(Cookies.get("examid")),
                    exam_id:1,
                    service_type : "CBSE_Term1",
                    service_sub_type: "all"
                    
                },
                fetchPolicy: 'network-only'
            }),name: "allData"
        })
       
        )(Cbse));

