import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Nav,
  Image,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import ReactGA from 'react-ga';
// import "./subscriptionPlan.scss";
// import TickmarkLogo from "./../../../../src/images/CircleWavyCheck.png";
// import XmarkLogo from "./../../../../src/images/x-circle.png";
import * as Cookies from "es-cookie";
import PackageOrderSummary from "./PackageOrderSummary";
import SubscriptionPlan from './SubscriptionPlan'
import PreloaderTwo from "../preloader/PreloaderTwo";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import * as compose from "lodash.flowright";
import "../liveClasses/_liveClassess.scss"
import LiveClass from "../../../images/homeImages/live_classes.png"


const COUPN_VALIDATION = gql`
  mutation($mobile: String, $plan_id: Int, $referral_code: String) {
    validateReferralCode(
      mobile: $mobile
      plan_id: $plan_id
      referral_code: $referral_code
    ) {
      referral_id
      applied_amount
      status
      desc
      type
      value
    }
  }
`;

const FETCH_STUDENTPLAN = gql`
  query {
    getStudentPlans {
      id
      plan_name
      plan_title
      amount
      original_amount
      image
      additional_text
    }
  }
`;
class Package extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upgradecomponent: 0,
      planObj: "",
      submitError4: "",
      couponob: "",
    };
  }
  upgradeFun = (planid) => {
    //let planfilterData = this.props.getStudentPlans.getStudentPlans.filter((a) => a.id == planid);
    /*this.setState({
      upgradecomponent: 1,
      planObj: planid,
    });*/
    
    console.log("upgradeFun", this.props);
    this.props.history.push({
      pathname: "/student/subscribe-plans-order-summary",
      state: {
          data: planid

      }
  })
  };
  coupnValidation = (e, data) => {
    e.preventDefault();
    if (data != "") {
      this.coupnval(
        Cookies.get("mobile"),
        parseInt(this.state.planObj.id),
        data
      ).catch((error) => {
        console.log("catch if error");
        console.log(error);
        this.setState({
          submitError4: error.graphQLErrors.map((x) => x.message),
        });
        console.error(
          "ERR =>",
          error.graphQLErrors.map((x) => x.message)
        );
      });
    } else {
      this.setState({ submitError4: "Please fill COUPON ID" });
    }
  };
  coupnval = async (mobile, plan_id, referral_code) => {
    await this.props.coupnval({
      variables: {
        mobile,
        plan_id,
        referral_code,
      },
      update: (store, { data }) => {
        console.log("coupnvaldata", data);
        if (data.validateReferralCode) {
          Cookies.set("coupnvalmob", data.validateReferralCode);
          this.setState({
            couponob: data.validateReferralCode,
            submitError4: "",
          });
        }
      },
    });
  };

  render() {
    const getStudentPlans = this.props.getStudentPlans;
    const loading6 = getStudentPlans.loading;
    const error6 = getStudentPlans.error;
    if (error6 !== undefined) {
      alert("Server Error. " + error6.message);
      return null;
    }
    if (loading6) return <PreloaderTwo />;
    console.log(this.props.isStudentUserValid)
    console.log(
      "getStudentPlans.getStudentPlans",
      getStudentPlans.getStudentPlans,
      this.props.isStudentUserValid
    );
    let getStudentPlansData = [];
    let currentPlan = "";
    let validData = "";
    if (
      getStudentPlansData != undefined &&
      this.props.isStudentUserValid != undefined
    ) {
      console.log(
        "this.props.isStudentUserValid",
        this.props.isStudentUserValid
      );
      //getStudentPlansData = getStudentPlans.getStudentPlans;
      getStudentPlansData = [{ "id": "24", "plan_name": " Plan expires in 30 August", "plan_title": "Crash Course 2021", "amount": "4999", "original_amount": "9999", "image": "", "additional_text": "", "__typename": "StudentPlans" }]
      let currentPlanobj = getStudentPlansData.find(
        (a) => a.id == this.props.isStudentUserValid.current_plan_id
      );
      if (currentPlanobj != undefined) {
        currentPlan = currentPlanobj;
      }

    }
    validData = this.props.isStudentUserValid.isStudentUserValid;

    //const currentPlan = getStudentPlansData.find((a) => a.id == this.props.isStudentUserValid.current_plan_id);
    // if (getStudentPlansData != undefined) {
    //     const currentPlan = getStudentPlansData.find((a) => a.id == this.props.isStudentUserValid.current_plan_id);
    // }

    console.log("getStudentPlansData", getStudentPlansData);
    console.log("currentPlan", currentPlan);
    console.log("validData", validData.expiry_date, moment().unix());
    let expirydays = validData.expiry_date;
    let currentime = moment().unix();
    const days = Math.round(parseInt(expirydays - currentime) / (24 * 60 * 60));
    //const days = -1
    console.log("days", days);
    return (
      <React.Fragment>
        {this.state.upgradecomponent == 0 ? (
          <React.Fragment>
            {/* <img src={LiveClass} class="img-fluid img_l"/> */}
           <SubscriptionPlan isStudentUserValid={this.props.isStudentUserValid} getStudentPlansData={getStudentPlansData} clickButtonBuyNow={(item)=> this.upgradeFun(item)}/>
          </React.Fragment>
        ) : (
          <PackageOrderSummary
            stateData={this.state}
            coupnValidation={this.coupnValidation}
            locgetData={this.props.history.location.state != undefined ? this.props.history.location.state : ""}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(
  compose(
    graphql(FETCH_STUDENTPLAN, {
      options: (props) => ({
        fetchPolicy: "cache-and-network",
      }),
      name: "getStudentPlans",
    }),
    graphql(COUPN_VALIDATION, {
      name: "coupnval",
    })
  )(Package)
);
