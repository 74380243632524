import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import ScheduleExamSection from '../components/exams/schedule_exam/ScheduleExamSection';

import * as Cookies from "es-cookie";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'


import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import QuizExamSection from '../components/exams/quiz_exam/QuizExamSection';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";


const FETCH_SCHEUDULE = gql` 
query($mobile: String!,$exam_type:Int) {
    getQuizExams(mobile: $mobile,exam_type:$exam_type){
        id
      exam_name
      exam_type
      start_time
      end_time
      is_completed
      is_started
      status
      time_duration
      syllabus
      exam_session_id
      total_marks
      marks_obtained
      speed
      exam_duration

   }
}

`;

const UPDATE_STUDENT_ANALYTICS = gql`
  mutation(
    $params:AnalyticsStudents1  
    ) {
        studentAnalytics(
        params: $params
     )
  }
`;




class QuizExam extends Component {

    constructor(props) {
        super(props)

        this.state = {
            headerBottomImg: {
                Img: require('../../images/quiz_practice.png'),
                type: "Quiz_Exam",
                Title: "Quiz",
            },
            submitError: "",

            toggled: isMobile == true && Cookies.get("institution_id") == 30 ? "wrapper" : Cookies.get("toggle")
        }
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        if(Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ||Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ){
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 131,
                source :2
    
            }

        }else{
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 147,
                source :2
    
            }
        }

        console.log("addStudentObj",addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
            update: (store, { data }) => {
                console.log("data",data)
                if (data.studentAnalytics) {
                    this.setState({
                        submitError:""
                    });
                }
            }
        });
    };

    componentDidMount = () => {

        this.addStudentAnalytics();


        if (Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8) {
            const title = GoogleAnalyticsArray[0].Eamcet_Quiz;
            ReactGA.pageview('/student/exams/quiz_exam', title);
        }
        else if (Cookies.get("examid") == 1 || Cookies.get("examid") == 2) {
            const title = GoogleAnalyticsArray[0].Quiz_Exams;
            ReactGA.pageview('/student/exams/quiz_exam', ["rizee"], title);
        }
    }



    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");
        const getQuizExams = this.props.getQuizExams;
        const loading1 = getQuizExams.loading;
        const error1 = getQuizExams.error;
        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }
        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }



        return (

            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} pageType={"quickQuiz"} />
                </div>
                <div className="main-wrapper">

                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper pt-0" style={{ minHeight: "50vh" }}>
                        {
                            loading1 ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <QuizExamSection
                                        getQuizExams={getQuizExams.getQuizExams}
                                        studentGlobals={studentGlobals}
                                    />
                                )
                        }

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(
    graphql(FETCH_SCHEUDULE,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_type: parseInt(Cookies.get("examid"))
                }
                ,
                fetchPolicy: "network-only"
            }), name: "getQuizExams"
        }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),

)(QuizExam));
