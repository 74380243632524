export const recentdVideo = [
     {
        id: '01',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/Lll4QeybDEg/1920x1140',
        subTitle: 'Continue video'
    }];

export const similarRecentdVideo = [
     {
        id: '01',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '18:56 Min',
        Likes: '1000k'
    },
     {
        id: '02',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '25:56 Min',
        Likes: '150k'
    },
     {
        id: '03',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '18:56 Min',
        Likes: '1000k'
    },
     {
        id: '0',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '25:56 Min',
        Likes: '150k'
    }
];

export const recommendedVideos = [
     {
        id: '01',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '18:56 Min',
        Likes: '1000k'
    },
     {
        id: '02',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '25:56 Min',
        Likes: '150k'
    },
     {
        id: '03',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '20:30 Min',
        Likes: '50k'
    },
     {
        id: '04',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '22:56 Min',
        Likes: '1M'
    },
     {
        id: '05',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '22:56 Min',
        Likes: '1M'
    },
     {
        id: '06',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '22:56 Min',
        Likes: '1M'
    },
     {
        id: '07',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '18:56 Min',
        Likes: '1000k'
    },
     {
        id: '08',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '25:56 Min',
        Likes: '150k'
    },
     {
        id: '09',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '20:30 Min',
        Likes: '50k'
    },
     {
        id: '10',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '22:56 Min',
        Likes: '1M'
    },
     {
        id: '11',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '22:56 Min',
        Likes: '1M'
    },
     {
        id: '12',
        Title: 'Biology and Its Applications',
        videoThumbnail: 'https://source.unsplash.com/EUfxH-pze7s/300x250',
        videoDurations: '22:56 Min',
        Likes: '1M'
    }
]
