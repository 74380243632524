import React, { Component } from 'react'
import { withRouter, Link } from "react-router-dom";
import { Container, Row, Col, Nav, Navbar, NavDropdown, Image, Button, Media } from 'react-bootstrap'
import ChangePwdModaldModal from '../../components/Profile/ChangePwdModal'
import '../../components/navbars/_navbars.scss'
import * as Cookies from "es-cookie";
import ChangePassword from '../navbars/ChangePassword';
import moment from 'moment';
import Notification from '../navbars/Notification';
import GITHAMCOLLEGELOGO from '../../../images/gitam_college_logo.png'
import MEGHACOLLEGELOGO from '../../../images/megha.png'
import SEGACOLLEGELOGO from '../../../images/sega_college_logo.jpeg'
import refercode from "../../../images/refercode.png";
import refercodeMobile from "../../../images/refercode_mobile.png";
import refercode_eamcet from "../../../images/refercode_eamcet.png";
import CBSE_image from "../../../images/CBSE_image.jpeg";
import PRAGNACOLLEGELOGO from '../../../images/pragna_college.png'
import OMEGHACOLLEGELOGO from '../../../images/omega.png'

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const FETCH_CUSTOM_VIDEO_CATEGORIES = gql` 
query($mobile: String!,
    $exam: String) {
        getCustomVideoCategories(params:{mobile: $mobile,
            exam: $exam}){
            category
            exam
            ExamType
            order_by
            description
            CustomVideoSubCategory{
                category
                exam
                order_by
                CustomVideosList{
                    paid_video
                    title
                    exam_type
                    class
                    description
                    video_url
                    vimeo_url
                    subjectName
                    ChapterName
	topicName
                    likes
                    dislikes
                    views
                    is_purchased
                    thumbnail
                    video_id
                    created_timestamp
                    pdf_file
                    video_source
                    is_live
                    is_upcoming 
                    subject   
                    publish
                }
            }
    }
}

`;

const USERLOGOUT = gql`
  mutation(
    $mobile: String!,
    $device_id: String) {
    userLogout(mobile: $mobile,device_id: $device_id)
  }
`;

class HomeNavbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modalShow: false,
            loader: 0
        }
    }
    navbarClass() {
        console.log("navbarClass", this.props.getSubjects, this.props.stateData.mainindex);
        let getData = this.props.getSubjects[this.props.stateData.mainindex];
        let classname = "";
        if (getData.subject == "Botany") {
            classname = "student-top-nav pb-4 botany"
        }
        else if (getData.subject == "Physics") {
            classname = "student-top-nav pb-4 physics"
        }
        else if (getData.subject == "Chemistry") {
            classname = "student-top-nav pb-4"
        }
        else if (getData.subject == "Zoology") {
            classname = "student-top-nav pb-4 zoology"
        }
        else if (getData.subject == "Mathematics") {
            classname = "student-top-nav pb-4 maths"
        } else if (getData.subject == "AllSubjects") {
            classname = "student-top-nav pb-4 AllSubjects"
        } else if (getData.subject == "Biology") {
            classname = "student-top-nav pb-5 botany"
        } else if (getData.subject == "Maths") {
            classname = "student-top-nav pb-4 maths"
        }
        return classname;

    }
    logout = e => {
        e.preventDefault();
        this.setState({
            loader: 1
        })

        this.userLogout(Cookies.get("mobile"), "").catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });

            console.error(
                "ERR =>",
                error.graphQLErrors.map(x => x.message)
            );
        });

    };
    userLogout = async (mobile,
        device_id) => {
        await this.props.userLogout({
            variables: {
                mobile,
                device_id
            },
            update: (store, { data }) => {
                console.log("data.userLogout", data.userLogout);
                if (data.userLogout) {

                    Cookies.remove("studenttoken");
                    Cookies.remove("studentrefreshtoken");
                    Cookies.remove("studentusername");
                    Cookies.remove("studentemail");

                    Cookies.remove("mobile");
                    Cookies.remove("classid");
                    Cookies.remove("examid");
                    Cookies.remove("exam_name");
                    Cookies.remove("mobileverified");
                    Cookies.remove("targetyear");
                    Cookies.remove("videos");
                    Cookies.remove("branch_name");
                    Cookies.remove("role", "");
                    Cookies.remove("profile_pic");
                    Cookies.remove("student_userlevel");
                    Cookies.remove("stulogintype", "");
                    localStorage.removeItem('profile_pic');
                    localStorage.removeItem('studentglobals');
                    localStorage.removeItem('globalsubjects');
                    localStorage.removeItem("packageplan");

                    Cookies.remove("toggle");
                    this.setState({
                        loader: 0
                    });


                    /*
                         if(window.ReactNativeWebView){
                            window.ReactNativeWebView.postMessage("LOGOUT")
                         }
                        */
                    if (window.ReactNativeWebView) {
                        this.props.history.push("/student/logineamcet");
                        window.ReactNativeWebView.postMessage("LOGOUT");
                    }
                    this.props.history.push("/student/login");




                }
            }
        });
    };
    checkVideoSubCategoryideosList = (category) => {
        let value = false
        category.CustomVideoSubCategory.map(data => {
            if (data.CustomVideosList != null && data.CustomVideosList.length > 0) {
                value = true
            }
        })
        return value
    }

    render() {
     
        let isStudentUserValid = "";
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        else {
            this.props.history.push("/student/login");
        }
        const isuserValid = JSON.parse(
            isStudentUserValid.user_access_restictions
        );

        let userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;

        let onlyMobileViewInWeb = Boolean(
            userAgent.match(
                /Build/i
            )
        );

        let referFriendImageSHow = '';
        const isInStandaloneMode = () =>
            (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
        if (isInStandaloneMode()) {
            referFriendImageSHow = "Appview"
        } else {
            referFriendImageSHow = "mobileview"
        }

        const getCustomVideoCategories = this.props.getCustomVideoCategories;
        const loading1 = getCustomVideoCategories.loading;
        const error1 = getCustomVideoCategories.error;
        let cbseData = "";
        if (getCustomVideoCategories.getCustomVideoCategories != undefined) {
            getCustomVideoCategories.getCustomVideoCategories.map((data, index) => {
                if (data.category == "CBSE 2021") {
                    if (data.CustomVideoSubCategory != null && data.CustomVideoSubCategory.length > 0) {
                        if (this.checkVideoSubCategoryideosList(data)) {
                            cbseData = data;
                        }

                    }

                }
            })
        }
        return (<>

            {Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ? (

                <Navbar className="header-top">
                    <Container>
                        <Button variant="light" className="px-2 py-1 mr-2" onClick={() => this.props.onClick()}>
                            <i className="fal fa-bars" />
                        </Button>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <div className="justify-content-center center-auto">
                                {/* <Button variant="light text-dark mr-2" onClick={this.props.history.goBack}> <i className="fas fa-long-arrow-alt-left" /> Back</Button> */}
                                {Cookies.get("institute_logo") != "" ? (
                                    <Image className="d-none d-xl-block d-lg-block" src={Cookies.get("institute_logo")} height="30" style={{ width: 'auto', minWidth: 60 }} />
                                ) : ("")}

                                {/* {
                                            isBrowser == true ? 
                                            (
                                                <Link to="/student/referFriend">
                                                    <Image alt="Refer Friend" src={refercode_eamcet} className="referalfriendimageAlign" />
                                                </Link>
                                                
                                            ):("")
                                            
                                        } */}
                            </div>

                            <Nav className="flex-column">
                                <div className="d-flex align-items-center">
                                    <Notification />
                                    <div className="profile-img d-flex align-items-center">
                                        {Cookies.get("profile_pic") != "" ? (

                                            <Image src={`https://admin.rizee.in/files/${localStorage.getItem("profile_pic")}`} roundedCircle alt="profile-img" />
                                        ) : (
                                            <Image src={require('../../../images/businessman.png')} roundedCircle alt="profile-img" />
                                        )}

                                        <div className="text mx-2 mt-2">
                                            {this.state.loader == 0 ? (
                                                <React.Fragment>
                                                    <div className="profile-name text-dark">{Cookies.get("studentusername")}</div>

                                                    <small className="text-muted">Exam- {Cookies.get("exam_name")}, Class - {Cookies.get("classid") == "1" ? ("XI") : ("XII")}</small>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <div className="profile-name text-dark">{Cookies.get("studentusername")}</div>
                                                    <small className="text-muted">Logging Out...</small>

                                                </React.Fragment>
                                            )}

                                        </div>
                                        <NavDropdown id="basic-nav-dropdown2" className="no-arrow" alignRight title={
                                            <i className="fal fa-ellipsis-v text-dark fa-2x mx-2" aria-hidden="true"></i>
                                        }>
                                            <NavDropdown.Item className="font-weight-bold">{Cookies.get("email")}</NavDropdown.Item>
                                            {isuserValid.ins_profile_tab == true ? ("") : (<NavDropdown.Item as={Link} to="/student/profile"><i className="far fa-user mr-2" /> My Profile</NavDropdown.Item>)}
                                            {isuserValid.ins_package_tab == true ? ("") : (<NavDropdown.Item as={Link} to={Cookies.get("examid") == 1 || Cookies.get("examid") == 2 ? "/student/package" : "/student/plans_section"}><i className="far fa-sack mr-2" /> Subscription Plans</NavDropdown.Item>)}

                                            {/* <NavDropdown.Item onClick={() => this.setState({ modalShow: true })}><i className="far fa-key mr-2" /> Change Password</NavDropdown.Item> */}
                                            {isuserValid.ins_feedback_tab == true ? ("") : (<NavDropdown.Item as={Link} to="/student/feedback"><i className="far fa-comment-alt-lines mr-2" /> Feedback</NavDropdown.Item>)}
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item as={Link} onClick={this.logout}><i className="far fa-sign-out mr-2" /> Logout</NavDropdown.Item>
                                        </NavDropdown>
                                    </div>

                                </div>
                                <div className="text-light" style={{ paddingLeft: 100 }}>
                                    {moment().format("DD-MM-YYYY @ LT")}
                                </div>
                                {/* <div>         
                                        {
                                        
                                        isMobile == true && onlyMobileViewInWeb == false ?
                                            (
                                                <Link to="/student/referFriend">
                                                    <Image alt="Refer Friend" src={refercode_eamcet} className="referalfriendMobile" />
                                                </Link>
                                            ):("")
                                            
                                            
                                        }    
                                    </div> */}
                            </Nav>

                        </Navbar.Collapse>

                        <ChangePassword
                            show={this.state.modalShow}
                            onHide={() => this.setState({ modalShow: false })}
                        />

                    </Container>
                </Navbar>

            ) : (

                <div className={this.navbarClass()}>
                    <Navbar className="header-top">
                        <Container>
                            <Button variant="light" className="px-2 py-1 mr-2" onClick={() => this.props.onClick()}>
                                <i className="fal fa-bars" />
                            </Button>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <div className="mr-auto d-flex align-items-center">
                                    {/* <Button variant="light text-dark mr-2" onClick={this.props.history.goBack}> <i className="fas fa-long-arrow-alt-left" /> Back</Button> */}
                                    {
                                        isBrowser == true && (Cookies.get("institution_id") == 46 || Cookies.get("institution_id") == 50 || Cookies.get("institution_id") == 51 || Cookies.get("institution_id") == 54 || Cookies.get("institution_id") == 53) ?
                                            (

                                                <Media className="college_logo_syles" >
                                                    <Image
                                                        className="mr-1"
                                                        src={
                                                            Cookies.get("institution_id") == 46 ?
                                                                (GITHAMCOLLEGELOGO) :
                                                                (
                                                                    Cookies.get("institution_id") == 50 ?
                                                                        (MEGHACOLLEGELOGO) :
                                                                        (
                                                                            Cookies.get("institution_id") == 51 ?
                                                                                (SEGACOLLEGELOGO) :
                                                                                (
                                                                                    Cookies.get("institution_id") == 54 ?
                                                                                        (PRAGNACOLLEGELOGO) :
                                                                                        (
                                                                                            Cookies.get("institution_id") == 53 ?
                                                                                                (OMEGHACOLLEGELOGO)
                                                                                                : ("")
                                                                                        )
                                                                                )
                                                                        )
                                                                )
                                                        }
                                                        alt="logo"
                                                    />
                                                    <Media.Body>
                                                        {
                                                            Cookies.get("institution_id") == 46 ?
                                                                (
                                                                    <div>
                                                                        <p class="mb-0">CODE:59425</p>
                                                                        <h5 className="mb-0">GITAM JUNIOR COLLEGE</h5>
                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    Cookies.get("institution_id") == 50 ?

                                                                        (<h5 className="mb-0 mt-3 ml-2">MEGHA JUNIOR COLLEGE</h5>)
                                                                        :
                                                                        (
                                                                            Cookies.get("institution_id") == 51 ?
                                                                                (<h5 className="mb-0 mt-3 ml-2">SAGE ACADEMY</h5>) :
                                                                                (
                                                                                    Cookies.get("institution_id") == 54 ?
                                                                                        (<h5 className="mb-0 mt-3 ml-2">PRAGNA ACADEMY</h5>) :
                                                                                        (
                                                                                            Cookies.get("institution_id") == 53 ?
                                                                                                (<h5 className="mb-0 mt-3 ml-2">OMEGA JUNIOR COLLEGE</h5>) : ("")
                                                                                        )
                                                                                )
                                                                        )
                                                                )
                                                        }


                                                    </Media.Body>
                                                </Media>
                                            )
                                            :
                                            (
                                                <>
                                                    {Cookies.get("institute_logo") != "" ? (
                                                        <Image className="d-none d-xl-block d-lg-block" src={Cookies.get("institute_logo")} height="30" style={{ width: 'auto', minWidth: 60 }} />
                                                    ) : ("")}

                                                    <h6 className="ml-2 mb-0 d-none d-xl-block d-lg-block">{Cookies.get("institute_name")}</h6>
                                                </>
                                            )
                                    }
                                </div>
                                {
                                     Cookies.get("student_userlevel") == 2 ?
                                        (
                                            Cookies.get("examid") == 1 ?
                                                (<h3 className='exam_name_des'>NEET</h3>)
                                                :
                                                (<h3 className='exam_name_des'>JEE (MAINS AND ADVANCED)</h3>)
                                        )
                                        :
                                        ("")
                                }


                                <Nav className="ml-auto flex-column">
                                    <div className="d-flex align-items-center">
                                        <Notification />
                                        <div className="profile-img d-flex align-items-center">
                                            {Cookies.get("profile_pic") != "" ? (

                                                <Image src={`https://admin.rizee.in/files/${localStorage.getItem("profile_pic")}`} roundedCircle alt="profile-img" />
                                            ) : (
                                                <Image src={require('../../../images/businessman.png')} roundedCircle alt="profile-img" />
                                            )}

                                            <div className="text mx-2 mt-2">
                                                {this.state.loader == 0 ? (
                                                    <React.Fragment>
                                                        <div className="profile-name text-dark">{Cookies.get("studentusername")}</div>

                                                        <small className="text-muted">Exam- {Cookies.get("exam_name")}, Class - {Cookies.get("classid") == "1" ? ("XI") : ("XII")}</small>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <div className="profile-name text-dark">{Cookies.get("studentusername")}</div>
                                                        <small className="text-muted">Logging Out...</small>

                                                    </React.Fragment>
                                                )}

                                            </div>


                                            <NavDropdown id="basic-nav-dropdown2" className="no-arrow" alignRight title={
                                                <i className="fal fa-ellipsis-v text-dark fa-2x mx-2" aria-hidden="true"></i>
                                            }>
                                                <NavDropdown.Item className="font-weight-bold">{Cookies.get("email")}</NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item as={Link} to="/student/profile"><i class="fas fa-key"></i> Activate Partner Offer</NavDropdown.Item>

                                                {isuserValid.ins_profile_tab == true ? ("") : (<NavDropdown.Item as={Link} to="/student/profile"><i className="far fa-user mr-2" /> My Profile</NavDropdown.Item>)}
                                                {isuserValid.ins_package_tab == true ? ("") : (<NavDropdown.Item as={Link} to={Cookies.get("examid") == 1 || Cookies.get("examid") == 2 ? "/student/package" : "/student/plans_section"}><i className="far fa-sack mr-2" /> Subscription Plans</NavDropdown.Item>)}

                                                {/* <NavDropdown.Item onClick={() => this.setState({ modalShow: true })}><i className="far fa-key mr-2" /> Change Password</NavDropdown.Item> */}
                                                {isuserValid.ins_feedback_tab == true ? ("") : (<NavDropdown.Item as={Link} to="/student/feedback"><i className="far fa-comment-alt-lines mr-2" /> Feedback</NavDropdown.Item>)}
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item as={Link} onClick={this.logout}><i className="far fa-sign-out mr-2" /> Logout</NavDropdown.Item>
                                            </NavDropdown>
                                        </div>

                                    </div>
                                    <div className="text-light" style={{ paddingLeft: 100 }}>
                                        {moment().format("DD-MM-YYYY @ LT")}
                                    </div>
                                </Nav>

                            </Navbar.Collapse>
                        </Container>

                        <ChangePassword
                            show={this.state.modalShow}
                            onHide={() => this.setState({ modalShow: false })}
                        />
                        {/* <ChangePwdModaldModal show={this.state.modalShow} onHide={() => this.setState({ modalShow: false })} /> */}
                    </Navbar>




                    <Container className="header-bottom">
                        {
                            isMobile == true && (Cookies.get("institution_id") == 46 || Cookies.get("institution_id") == 50 || Cookies.get("institution_id") == 51 || Cookies.get("institution_id") == 54 || Cookies.get("institution_id") == 53) ?
                                (
                                    <Media className="college_logo_syles" >
                                        <Image
                                            className="mr-1"
                                            src={
                                                Cookies.get("institution_id") == 46 ?
                                                    (GITHAMCOLLEGELOGO) :
                                                    (
                                                        Cookies.get("institution_id") == 50 ?
                                                            (MEGHACOLLEGELOGO) :
                                                            (
                                                                Cookies.get("institution_id") == 51 ?
                                                                    (SEGACOLLEGELOGO) :
                                                                    (
                                                                        Cookies.get("institution_id") == 54 ?
                                                                            (PRAGNACOLLEGELOGO) :
                                                                            (
                                                                                Cookies.get("institution_id") == 53 ?
                                                                                    (OMEGHACOLLEGELOGO) : ("")
                                                                            )
                                                                    )
                                                            )
                                                    )
                                            }
                                            alt="logo"
                                        />
                                        <Media.Body>
                                            {
                                                Cookies.get("institution_id") == 46 ?
                                                    (
                                                        <div>
                                                            <p class="mb-0">CODE:59425</p>
                                                            <h5 className="mb-0">GITAM JUNIOR COLLEGE</h5>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        Cookies.get("institution_id") == 50 ?

                                                            (<h5 className="mb-0 mt-3 ml-2">MEGHA JUNIOR COLLEGE</h5>)
                                                            :
                                                            (
                                                                Cookies.get("institution_id") == 51 ?
                                                                    (<h5 className="mb-0 mt-3 ml-2">SAGE ACADEMY</h5>) :
                                                                    (
                                                                        Cookies.get("institution_id") == 54 ?
                                                                            (<h5 className="mb-0 mt-3 ml-2">PRAGNA ACADEMY</h5>) :
                                                                            (
                                                                                Cookies.get("institution_id") == 53 ?
                                                                                    (<h5 className="mb-0 mt-3 ml-2">OMEGA JUNIOR COLLEGE</h5>)
                                                                                    : ("")
                                                                            )
                                                                    )
                                                            )
                                                    )
                                            }
                                        </Media.Body>
                                    </Media>

                                ) : ("")
                        }


                        <Row className="align-items-end pt-xl-2 pt-lg-2 pb-4">
                            {
                                this.props.pageType == "CBSE" ?
                                    (
                                        <Col xl={{ span: 5, order: 1 }} lg={{ span: 6, order: 2 }} md={{ span: 6, order: 1 }} sm={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
                                            <h4 className="text-white">CBSE</h4>
                                        </Col>
                                    )
                                    :
                                    (
                                        <>


                                            <Col xl={{ span: 3, order: 1 }} lg={{ span: 6, order: 2 }} md={{ span: 6, order: 1 }} sm={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
                                                <h6 className="text-white"><span className="font-weight-normal">
                                                    Hey there!
                                                </span><br /> Greetings {Cookies.get("studentusername")},</h6>
                                                <p className="text-light">Start new goal and improve your result</p>
                                            </Col>

                                            {<Col xl={6} lg={{ span: 6, order: 2 }} md={{ span: 6, order: 1 }} sm={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
                                                {/*
                                                    isuserValid.userlevel !=1 && Cookies.get("institution_id") != 28 ?
                                                    (
                                                        isBrowser == true ? 
                                                        (
                                                            <Link to="/student/cbse">
                                                                <Image alt="New" src={CBSE_image} className="referalfriendimage" />
                                                            </Link>   
                                                          
                                                            
                                                        )
                                                        :
                                                        (
                                                            <Link to="/student/cbse">
                                                                <Image alt="New" src={CBSE_image} className="referalfriendMobile" />
                                                            </Link>  
                                                        )
                                                        
                                                    )
                                                    :("")
                                                */}
                                            </Col>}
                                            <Col xl={3} lg={{ span: 3, order: 2 }} md={{ span: 6, order: 1 }} sm={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }} className="text-xl-right">
                                                <p className="text-light" style={{ paddingRight: 10 }}><small className="text-white-50">let's do some task today</small><br />
                                                </p>
                                            </Col>


                                        </>
                                    )
                            }


                        </Row>
                    </Container>
                </div>

            )
            }
        </>
        )
    }
}


export default withRouter(compose(
    graphql(FETCH_CUSTOM_VIDEO_CATEGORIES,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam: Cookies.get("examid")

                }
                ,
                fetchPolicy: 'network-only'
            }), name: "getCustomVideoCategories"
        }), graphql(USERLOGOUT, {
            name: "userLogout"
        })

)(HomeNavbar));
