import React, { Component } from 'react'
import { Container, Row, Col, Card, Image, Button, Media, Accordion } from 'react-bootstrap';
import { withRouter, Link } from "react-router-dom";
import * as Cookies from "es-cookie";
import moment from "moment";

import ReactGA from 'react-ga';
import './_studenttargetting.scss';
import CoffeeCup from '../../../images/images1/cofeecup.png'
import Plus from '../../../images/images1/coffeePlus.png'
import minus from '../../../images/images1/coffeeMinus.png';


class StudentTargettingSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentActiveKey: "",
            days: 1,
            errorMsg: "",
            amount: this.props.getPopupPlan[0].amount,
            perDayWisePlan: this.props.getPopupPlan,
        }
    }

    toggle = (index) => {
        if (this.state.currentActiveKey != index.toString()) {
            this.setState({ currentActiveKey: index })
        }
        else {
            this.setState({ currentActiveKey: "" })
        }
    }

    onDecrease = () => {
        let totaldays = this.state.days - 1
        let perDayPrice = this.props.getPopupPlan[0].amount
        if (this.state.days == 1) {
            this.setState({
                errorMsg: " *You cannot reduce further",
            })
            return;
        } else {
            this.setState({
                days: totaldays,
                amount: totaldays * parseInt(perDayPrice)
            })
        }
    }

    onIncrease = () => {
        let totaldays = this.state.days + 1
        let perDayPrice = this.props.getPopupPlan[0].amount

        this.setState({
            days: totaldays,
            errorMsg: "",
            amount: totaldays * parseInt(perDayPrice)
        })
    }

    redirectToPayment = () => {
        this.props.history.push({
            pathname: "/student/payments-new-summary",
            state: {
                data: this.state
            }
        })
    }

    render() {
        return (
            <Container className='student_targetting'>
                <div className='white_pad25 mt-3' >
                    <Row>
                        <Col sm={12}>
                            <Row>
                                <Col sm={6}>
                                    <img src={CoffeeCup} className="coffee_img" />
                                </Col>
                                <Col sm={4}>
                                    <h3 className='cofee_header'>Your Daily Subscription</h3>
                                    <p className='coffee_des'>Learning with Rizee is now as affordable as a coffee a day</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className='white_pad25 mt-3'>
                    <Row>
                        <Col sm={12}>
                            <Row>
                                <Col sm={6}>
                                    <h6 className='targetting-header'>Plan Details: </h6>
                                    <ul className='custom_li_alignment'>
                                        <li className='list-item-targetting'>Unlimited Full Length Mock Tests</li>
                                        <li className='list-item-targetting'>Unlimited Access to Premium Content</li>
                                        <li className='list-item-targetting'>Structured Revision Materials</li>
                                        <li className='list-item-targetting'>Performance Analysis</li>
                                    </ul>
                                    <p className='whatspp_des'>Having doubts? just drop a message on</p>
                                    <p className='mt-2'><img src={require('../../../images/images1/whatsapp_target.png')} /><span className='contact'>+91 9154110191</span></p>
                                </Col>

                                <Col sm={6} className="mt-3">
                                    <h6 className='targetting-header'>Number of Days</h6>
                                    <p><img src={minus} className="plusminus" onClick={() => this.onDecrease()} />    <span className='days_count'>{this.state.days}</span>   <img src={Plus} className="plusminus" onClick={() => this.onIncrease()} /></p>
                                    <p className='errormsg'>{this.state.errorMsg}</p>

                                    <div className='price_card'>
                                        <p className='amount' onClick={() => this.redirectToPayment()}>Rs.{this.state.amount} Only</p>
                                    </div>
                                    {
                                        this.props.getPopupPlan[0].additional_text != "" ?
                                            (<p className='mt-3 f-12'>{this.props.getPopupPlan[0].additional_text}</p>)
                                            :
                                            ("")
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <h1 className='faq mt-3'>FAQ's</h1>

                <Accordion>
                    <Row>
                        <Col sm={12}>
                            <React.Fragment key={1} className="border-0">
                                <Accordion.Toggle
                                    as={Card}
                                    className='mt-3 border_none_fff'
                                    eventKey="1"
                                    onClick={() => this.toggle("1")}>
                                    <div>
                                        <Row>
                                            <Col sm={12}>
                                                <h6 className={this.state.currentActiveKey == "1" ? "chap_block_view active" : "chap_block_view "} >
                                                    Q. what features can i avail with daily subscription?
                                                    {this.state.currentActiveKey == "1" ? (
                                                        <i className="fas fa-chevron-up float-right" style={{ cursor: "pointer" }}></i>
                                                    ) : (
                                                        <i className="fas fa-chevron-down float-right" style={{ cursor: "pointer" }}></i>
                                                    )}

                                                    {
                                                        this.state.currentActiveKey == "1" ?
                                                            Cookies.get("examid") == 1 ?
                                                                (<p className='answer'>A: Mock Tests,Previous year papers,Foundation course,Quiz,Previous paper analysis,Linkage chapter analysis,Learn and Practice,Revision material</p>)
                                                                :
                                                                Cookies.get("examid") == 2 ?
                                                                    (<p className='answer'>A: Mock Tests,Previous year papers,Foundation course,Quiz,Previous paper analysis,Linkage chapter analysis,Learn and Practice,Revision material,NTA College Predictor</p>) :
                                                                    Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ?
                                                                        (<p className='answer'>A: Mock Tests,Foundation course,Practice sessions,Previous tests,Short notes,Revision material,Performance analysis,Error exam,Performance analysis,College and Branch Predictor,Quiz,Rank predictor</p>)
                                                                        :
                                                                        (<p className='answer'>A: Mock Tests,Foundation course,Practice sessions,Previous tests,Short notes,Revision material,Performance analysis,Error exam,Performance analysis,Quiz</p>)
                                                            :
                                                            ("")
                                                    }
                                                </h6>
                                            </Col>
                                        </Row>
                                    </div>
                                </Accordion.Toggle>

                                <Accordion.Toggle
                                    as={Card}
                                    className='mt-2 border_none_fff'
                                    eventKey="2"
                                    onClick={() => this.toggle("2")}>
                                    <div>
                                        <Row>
                                            <Col sm={12}>
                                                <h6 className={this.state.currentActiveKey == "2" ? "chap_block_view active" : "chap_block_view "} >
                                                    Q. If i purchase full length course after purchasing daily subscription, will i have to pay full amount of that course?
                                                    {this.state.currentActiveKey == "2" ? (
                                                        <i className="fas fa-chevron-up float-right" style={{ cursor: "pointer" }}></i>
                                                    ) : (
                                                        <i className="fas fa-chevron-down float-right" style={{ cursor: "pointer" }}></i>
                                                    )}

                                                    {
                                                        this.state.currentActiveKey == "2" ?
                                                            (<p className='answer'>A: Yes, you can please reach out to us on WhatsApp at +91 9154110191 or email us at info@rizee.in</p>)
                                                            :
                                                            ("")
                                                    }
                                                </h6>
                                            </Col>
                                        </Row>
                                    </div>
                                </Accordion.Toggle>

                                <Accordion.Toggle
                                    as={Card}
                                    className='mt-2 border_none_fff'
                                    eventKey="3"
                                    onClick={() => this.toggle("3")}>
                                    <div>
                                        <Row>
                                            <Col sm={12}>
                                                <h6 className={this.state.currentActiveKey == "3" ? "chap_block_view active" : "chap_block_view "} >
                                                    Q. Will i get discount for daily subscription?
                                                    {this.state.currentActiveKey == "3" ? (
                                                        <i className="fas fa-chevron-up float-right" style={{ cursor: "pointer" }}></i>
                                                    ) : (
                                                        <i className="fas fa-chevron-down float-right" style={{ cursor: "pointer" }}></i>
                                                    )}

                                                    {
                                                        this.state.currentActiveKey == "3" ?
                                                            (<p className='answer'>A: We want to make education accessible and affordable for every one. With daily subscription you can prepare for your competitive exams in a targetted manner, it's light on the pocket too!</p>)
                                                            :
                                                            ("")
                                                    }
                                                </h6>
                                            </Col>
                                        </Row>
                                    </div>
                                </Accordion.Toggle>
                            </React.Fragment>
                        </Col>
                    </Row>
                </Accordion>
            </Container>
        )
    }
}

export default withRouter(StudentTargettingSection);
