import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import { Row, Tabs, Tab, Card, Image, Button, Col, Table, Media, Container, Accordion } from 'react-bootstrap';
import Chemistry from '../../../images/chemistry_icon.png';
import Zoology from '../../../images/zoology_icon.png';
import Physics from '../../../images/physics_icon.png';
import Maths from '../../../images/maths_icon.png';
import Botany from '../../../images/botany_icon.png';
import General from '../../../images/General.png';

import './_instituteRevisonMaterials.scss';
import PDFModel from './pdfShowModel';

class InstituteRevisionMaterialsHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getSubjectsArr: [],
            selectedSubjectVal: "",
            classData: { value: 1, label: 'Class-XI' },
            selectedSubjectClassVal: [],
            currentActiveKey: "",
            modalShow: false,
            file_path: ""

        }
    }

    componentDidMount = () => {
        if (this.props.getSubjectOrder != undefined || this.props.getSubjectOrder != null) {
            let selectedSubject = "";
            const getSubjects = this.props.getSubjectOrder.map((gmap, index) => {
                if (index == 0) {
                    selectedSubject = gmap.id
                    return { ...gmap, active: "active" }
                }
                else {
                    return { ...gmap, active: "" }
                }
            })

            getSubjects.push({ id: 0, subject: "General", active: "" });

            // console.log("getSubjects",getSubjects.push({id:0,subject:"Genreal"}));

            let selectedSubjectClassVal = [];

            if (this.props.instituteRevisionMaterils != undefined && this.props.instituteRevisionMaterils.length > 0) {
                let selectedChaptersClassData = this.props.instituteRevisionMaterils.filter((item) => item.subject_id == selectedSubject && item.class_id == "1");

                let generalSubjectData = this.props.instituteRevisionMaterils.filter((item) => item.subject_id == 0)
                console.log("generalSubjectData", generalSubjectData);

                let uniqueChapters = selectedChaptersClassData.filter((item1, index, array) => {
                    return array.findIndex((item2) => item2.chapter_id == item1.chapter_id) == index;
                });

                if (uniqueChapters.length > 0) {
                    uniqueChapters.map((item1) => {
                        let filesData = selectedChaptersClassData.filter((item2) => item2.chapter_id == item1.chapter_id);
                        selectedSubjectClassVal.push({ chapter_id: item1.chapter_id, chapter_name: item1.chapter_name, filesData: filesData })
                    })
                }
            }
            this.setState({
                getSubjectsArr: getSubjects,
                selectedSubjectVal: selectedSubject,
                selectedSubjectClassVal: selectedSubjectClassVal,
            });
        }
    }

    toggle = (index) => {
        console.log("index", index);
        if (this.state.currentActiveKey != index.toString()) {
            this.setState({ currentActiveKey: index })
        }
        else {
            this.setState({ currentActiveKey: "" })
        }

    }

    subjectClassName(data) {
        let classname = "";
        if (data.subject == "Botany") {
            classname = `block_white botany mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Physics") {
            classname = `block_white physics mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Chemistry") {
            classname = `block_white chemistry mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Zoology") {
            classname = `block_white zoology mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Mathematics") {
            classname = `block_white maths mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Biology") {
            classname = `block_white botany mobile_card_eamcet ${data.active}`
        } else if (data.subject == "General") {
            classname = `block_white_general general mobile_card_eamcet ${data.active}`
        }
        return classname;
    }

    handleSelect = (subid) => {
        this.setState({ onloadSubjectWiseChapters: 1 });
        const getSubjects = this.state.getSubjectsArr.map((gmap, index) => {
            if (gmap.id == subid) {
                return { ...gmap, active: "active" }
            }
            else {
                return { ...gmap, active: "" }
            }
        });
        this.toggle(this.state.currentActiveKey)

        this.setState({
            getSubjectsArr: getSubjects,
            selectedSubjectVal: subid,
        });
        this.updateSelectedData(subid, this.state.classData.value);
    }

    updateSelectedData = (subject_id, class_id) => {
        let selectedSubjectClassVal = [];
        let selectedChaptersClassData = this.props.instituteRevisionMaterils.filter((item) => item.subject_id == subject_id && item.class_id == class_id);

        let generalSubjectData = this.props.instituteRevisionMaterils.filter((item) => item.subject_id == 0)
        console.log("generalSubjectData", generalSubjectData)

        let uniqueChapters = selectedChaptersClassData.filter((item1, index, array) => {
            return array.findIndex((item2) => item2.chapter_id == item1.chapter_id) == index;
        });
        console.log("uniqueChapters", uniqueChapters);


        if (uniqueChapters.length > 0) {
            uniqueChapters.map((item1) => {
                let filesData = selectedChaptersClassData.filter((item2) => item2.chapter_id == item1.chapter_id);
                selectedSubjectClassVal.push({ chapter_id: item1.chapter_id, chapter_name: item1.chapter_name, filesData: filesData })

            })

        }
        this.setState({
            selectedSubjectClassVal: selectedSubjectClassVal,
        });
    }

    selectHandleInputChange = (evalue) => {
        const value = evalue
        let labelval = ""

        if (value == "1") {
            labelval = "Class  XI"
        } else {
            labelval = "Class  XII"
        }

        this.updateSelectedData(this.state.selectedSubjectVal, value);
        this.setState({
            classData: {
                value: value,
                label: labelval
            },
            currentActiveKey: ""
        })
    }

    render() {
        console.log("mahesh", this.state.selectedSubjectClassVal);
        return (
            <Container className='instituteRevisonMaterials'>
                <Row>
                    <Col sm={12}>
                        <div className='subject-blocks-view'>
                            {
                                this.state.getSubjectsArr.map(sub =>
                                    <Media className={this.subjectClassName(sub)} onClick={() => this.handleSelect(sub.id)}>
                                        <Media.Body>
                                            <h5 className='mt-2 text-center mt-2'>{sub.subject}</h5>

                                        </Media.Body>
                                        {
                                            sub.id == "0" ?
                                                (<Card className='ganeralImage'><Card.Body><Image src={General} className="ml-4" alt="subject" /></Card.Body></Card>) : ("")
                                        }
                                        {
                                            sub.id == "1" ?
                                                (<Image src={Botany} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "2" ?
                                                (<Image src={Physics} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "3" ?
                                                (<Image src={Chemistry} className="ml-4 " alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "5" ?
                                                (<Image src={Zoology} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "4" ?
                                                (<Image src={Maths} className="ml-4" alt="subject" />) : ("")
                                        }
                                        {
                                            sub.id == "6" ?
                                                (<Image src={Botany} className="ml-4" alt="subject" />) : ("")
                                        }
                                    </Media>
                                )
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <ul className='list-inline class_type_li mt-4'>
                            <li className={this.state.classData.value == "1" ? "active" : ""} onClick={() => this.selectHandleInputChange("1")}>Class XI</li>
                            <li className={this.state.classData.value == "2" ? "active ml-4" : "ml-4"} onClick={() => this.selectHandleInputChange("2")}>Class XII</li>
                        </ul>
                    </Col>
                </Row>

                {
                    this.state.selectedSubjectClassVal.length > 0 ? (

                        <Accordion>
                            {
                                this.state.selectedSubjectClassVal.map((item, index) => {
                                    console.log("Accordion", item);
                                    return (
                                        <Row>
                                            <Col sm={12}>
                                                <React.Fragment key={index} className="border-0">
                                                    {
                                                        item.chapter_id != 0 && item.chapter_name != "" ? (

                                                            <Accordion.Toggle
                                                                as={Card}
                                                                className='mt-2 border_none_fff'
                                                                eventKey={index}
                                                                onClick={() => this.toggle(index)}
                                                            >
                                                                <div >
                                                                    <Row>
                                                                        <Col sm={6}>
                                                                            <h6 className={this.state.currentActiveKey == index.toString() ? "chap_block_view active" : "chap_block_view"} >
                                                                                <span className='ml-2'>{item.chapter_name}</span>
                                                                                {this.state.currentActiveKey == index.toString() ? (
                                                                                    <i className="fas fa-chevron-up float-right" style={{ cursor: "pointer" }}></i>
                                                                                ) : (
                                                                                    <i className="fas fa-chevron-down float-right" style={{ cursor: "pointer" }}></i>
                                                                                )}
                                                                            </h6>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Accordion.Toggle>

                                                        ) : (
                                                            <Row>
                                                                {item.filesData != null && item.filesData.length > 0 ?
                                                                    (
                                                                        item.filesData.map((item) => {
                                                                            return (
                                                                                <Col sm={6} className='mt-2'>
                                                                                    <Card className='border_pad_card' style={{ cursor: "pointer" }}>
                                                                                        <Card.Body className='p-2'>
                                                                                            <h6 className='mt-2 mb-3 ml-1'><b>{item.file_name}</b>
                                                                                                <button type="button" className="btn btn-custom-blutst float-right f-14" onClick={() => this.setState({ modalShow: true, file_path: item.file_path })}>View PDF</button>
                                                                                            </h6>
                                                                                        </Card.Body>
                                                                                    </Card>
                                                                                </Col>
                                                                            )
                                                                        }
                                                                        ))
                                                                    : (<Col sm={12}><h6 className='text-center'>Coming Soon...</h6></Col>)
                                                                }
                                                            </Row>
                                                        )
                                                    }

                                                    {
                                                        this.state.selectedSubjectClassVal[0].chapter_id != 0 && this.state.selectedSubjectClassVal[0].chapter_name != "" ? 
                                                        (
                                                            <Accordion.Collapse eventKey={index}>
                                                                <Card as={Card.Body} className="p-2">
                                                                    <Row>
                                                                        {item.filesData != null && item.filesData.length > 0 ?
                                                                            (
                                                                                item.filesData.map((item) => {
                                                                                    return (
                                                                                        <Col sm={6} className='mt-2'>
                                                                                            <Card className='border_pad_card' style={{ cursor: "pointer" }}>
                                                                                                <Card.Body className='p-2'>
                                                                                                    <h6 className='mt-2 mb-3 ml-1'><b>{item.file_name}</b>
                                                                                                        <button type="button" className="btn btn-custom-blutst float-right f-14" onClick={() => this.setState({ modalShow: true, file_path: item.file_path })}>View PDF</button>
                                                                                                    </h6>
                                                                                                </Card.Body>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                ))
                                                                            : (<Col sm={12}><h6 className='text-center'>Coming Soon...</h6></Col>)
                                                                        }
                                                                    </Row>
                                                                </Card>
                                                            </Accordion.Collapse>
                                                        ) : ("")
                                                    }

                                                </React.Fragment>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </Accordion>

                    ) : (
                        <Row>
                            <Col>
                                <h6 className='text-center'>NO Data Available</h6>
                            </Col>
                        </Row>
                    )
                }

                <PDFModel show={this.state.modalShow} onHide={() => this.setState({ modalShow: false })} file_path={this.state.file_path} />

            </Container>
        )
    }
}
export default InstituteRevisionMaterialsHome;