import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import { Row, Tabs, Tab, Card, Image, Button, Col, Table, Media, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Chemistry from '../../../images/chemistry_icon.png';
import Zoology from '../../../images/zoology_icon.png';
import Physics from '../../../images/physics_icon.png';
import Maths from '../../../images/maths_icon.png';
import Botany from '../../../images/botany_icon.png';
import './_chapterwisequestions.scss'
import QuestionsPage from './ChapterWiseQuestionsDisplay'
import ArrowNew from '../../../images/arrowNew.png'
import EamcetRestrictionAlert from "../home/EamcetRestrictionAlert";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import * as compose from 'lodash.flowright';


class ChapterWiseQuestionsSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            getSubjectsArr: [],
            selectedSubjectVal: "",
            onloadSubjectWiseChapters: 0,
            displayQuestionsPage: false,
            classData: { value: 1, label: 'Class-XI' },
            userRestionModalShow: false,
            getChapterWiseGroupedQuestions: [],
            showLoading: false,
            exam_type: Cookies.get("examid") == 3 || Cookies.get("examid") == 7 ? "AP EAPCET" : "TS EAMCET",
            exam_type_value: parseInt(Cookies.get("examid")),
            apeamcetCheckbox: Cookies.get("examid") == 3 || Cookies.get("examid") == 7 ? true : false,
            tseamcetCheckbox: Cookies.get("examid") == 6 || Cookies.get("examid") == 8 ? true : false,
            apIPEcheckbox: false,
            tsIPEcheckbox: false,
            onLoadDisplayData: "onload",
            getStateChapterNamesData: [],
            showChaptersLoading: false,
        }
    }

    componentDidMount = () => {
        if (this.props.getSubjectOrder != undefined || this.props.getSubjectOrder != null) {
            let selectedSubject = "";
            const getSubjects = this.props.getSubjectOrder.map((gmap, index) => {
                if (index == 0) {
                    selectedSubject = gmap.id
                    return { ...gmap, active: "active" }
                }
                else {
                    return { ...gmap, active: "" }
                }
            })
            this.setState({
                getSubjectsArr: getSubjects,
                selectedSubjectVal: selectedSubject,
            });
        }
    }
    selectHandleInputChange = (evalue) => {
        const value = evalue
        let labelval = ""

        if (value == "1") {
            labelval = "Class  XI"
        } else {
            labelval = "Class  XII"
        }
        this.setState({
            classData: {
                value: value,
                label: labelval
            },
        })
    }

    subjectClassName(data) {
        let classname = "";
        if (data.subject == "Botany") {
            classname = `block_white botany mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Physics") {
            classname = `block_white physics mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Chemistry") {
            classname = `block_white chemistry mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Zoology") {
            classname = `block_white zoology mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Mathematics") {
            classname = `block_white maths mobile_card_eamcet ${data.active}`
        } else if (data.subject == "Biology") {
            classname = `block_white botany mobile_card_eamcet ${data.active}`
        }
        return classname;
    }

    getSubjectName = (subid) => {
        let subjectName = "";
        if (subid == "1") {
            subjectName = "Botany";
        } else if (subid == "2") {
            subjectName = "Physics";
        } else if (subid == "3") {
            subjectName = "Chemistry";
        } else if (subid == "4") {
            subjectName = "Mathematics";
        } else if (subid == "5") {
            subjectName = "Zoology";
        } else if (subid == "6") {
            subjectName = "Biology"
        }
        return subjectName;
    }

    handleSubscribeModal = () => {
        this.setState({
            userRestionModalShow: true,
        })
    }

    handleChangeExamtype = async (e, examid) => {
        this.setState({ onLoadDisplayData: "change", showChaptersLoading: true })

        const name = e.target.name;
        const value = e.target.value;

        if (value == "AP_EAPCET") {
            const delay = (ms) => new Promise((r) => setTimeout(r, ms));
            await delay(200);

            this.setState({
                exam_type: "AP EAPCET",
                exam_type_value: Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? 3 : 7,
                apeamcetCheckbox: true,
                tseamcetCheckbox: false,
                apIPEcheckbox: false,
                tsIPEcheckbox: false,
            })

            const result = await this.props.client.query({
                query: gql`
                query($exam_id:Int,$mobile:String){
                    getStateChapterNames(exam_id:$exam_id,mobile:$mobile){
                        id
                        subject_name
                        state_chapters{
                            id
                          chapter_id
                          class_id
                          chapter_name
                          enabled
                          questionsCount
                          previousChapterEnabled
                          practiceEnabled
                          revisionMaterialEnabled
                          errorExamEnabled
                        }
                      }
                }
                `,
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_id: this.state.exam_type_value
                },
                fetchPolicy: "network-only"
            });

            if (result.data.getStateChapterNames.length > 0) {
                this.setState({
                    getStateChapterNamesData: result.data.getStateChapterNames,
                    showChaptersLoading: false
                })
            }
        }
        else if (value == "TS_EAMCET") {

            const delay = (ms) => new Promise((r) => setTimeout(r, ms));
            await delay(200);

            this.setState({
                exam_type: "TS EAMCET",
                exam_type_value: Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ? 6 : 8,
                apeamcetCheckbox: false,
                tseamcetCheckbox: true,
                apIPEcheckbox: false,
                tsIPEcheckbox: false,
            })

            const result = await this.props.client.query({
                query: gql`
                query($exam_id:Int,$mobile:String){
                    getStateChapterNames(exam_id:$exam_id,mobile:$mobile){
                        id
                        subject_name
                        state_chapters{
                            id
                          chapter_id
                          class_id
                          chapter_name
                          enabled
                          questionsCount
                          previousChapterEnabled
                          practiceEnabled
                          revisionMaterialEnabled
                          errorExamEnabled
                        }
                      }
                }
                `,
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_id: this.state.exam_type_value
                },
                fetchPolicy: "network-only"

            });
            if (result.data.getStateChapterNames.length > 0) {
                this.setState({
                    getStateChapterNamesData: result.data.getStateChapterNames,
                    showChaptersLoading: false
                })
            }
        }
        else if (value == "AP_IPE") {
            const delay = (ms) => new Promise((r) => setTimeout(r, ms));
            await delay(200);

            const result = await this.props.client.query({
                query: gql`
                query($exam_id:Int,$mobile:String){
                    getStateChapterNames(exam_id:$exam_id,mobile:$mobile){
                        id
                        subject_name
                        state_chapters{
                            id
                          chapter_id
                          class_id
                          chapter_name
                          enabled
                          questionsCount
                          previousChapterEnabled
                          practiceEnabled
                          revisionMaterialEnabled
                          errorExamEnabled
                        }
                      }
                }
                `,
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_id: examid
                },
                fetchPolicy: "network-only"

            });
            if (result.data.getStateChapterNames.length > 0) {
                this.setState({
                    getStateChapterNamesData: result.data.getStateChapterNames,
                    showChaptersLoading: false
                })
            }

            this.setState({
                exam_type_value: examid,
                apIPEcheckbox: true,
                tsIPEcheckbox: false,
                apeamcetCheckbox: false,
                tseamcetCheckbox: false,
            })
        }
        else if (value == "TS_IPE") {

            const delay = (ms) => new Promise((r) => setTimeout(r, ms));
            await delay(200);

            const result = await this.props.client.query({
                query: gql`
                query($exam_id:Int,$mobile:String){
                    getStateChapterNames(exam_id:$exam_id,mobile:$mobile){
                        id
                        subject_name
                        state_chapters{
                            id
                          chapter_id
                          class_id
                          chapter_name
                          enabled
                          questionsCount
                          previousChapterEnabled
                          practiceEnabled
                          revisionMaterialEnabled
                          errorExamEnabled
                        }
                      }
                }
                `,
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_id: examid
                },
                fetchPolicy: "network-only"

            });
            if (result.data.getStateChapterNames.length > 0) {
                this.setState({
                    getStateChapterNamesData: result.data.getStateChapterNames,
                    showChaptersLoading: false
                })
            }
            this.setState({
                exam_type_value: examid,
                apIPEcheckbox: false,
                tsIPEcheckbox: true,
                apeamcetCheckbox: false,
                tseamcetCheckbox: false
            })
        }
    }


    handleSelect = (subid) => {
        this.setState({ onloadSubjectWiseChapters: 1 });
        const getSubjects = this.state.getSubjectsArr.map((gmap, index) => {
            if (gmap.id == subid) {
                return { ...gmap, active: "active" }
            }
            else {
                return { ...gmap, active: "" }
            }
        });

        this.setState({
            getSubjectsArr: getSubjects,
            selectedSubjectVal: subid,
        });
    }
    showchaptersPage = () => {
        this.setState({
            displayQuestionsPage: false
        })
    }

    showQuestions = async (chapterId) => {
        const value = chapterId
        this.setState({
            displayQuestionsPage: true,
            showLoading: true
        })

        const result = await this.props.client.query({
            query: gql`
            query($exam_id:Int,$chapter_id:Int){
                getChapterWiseGroupedQuestions(exam_id: $exam_id, chapter_id: $chapter_id) {
                    id
                    slno
                    question_name
                    option1
                    option2
                    option3
                    option4
                    explanation
                    answer
                    qtype
                    list2type
                    list1type
                    year
                    pexamtype
                    compquestion
                    mat_question
                  }
            }
            `,
            variables: {
                exam_id: parseInt(this.state.exam_type_value),
                chapter_id: parseInt(value)
            },
            fetchPolicy: 'network-only'
        })

        if (result.data.getChapterWiseGroupedQuestions.length > 0) {
            this.setState({
                getChapterWiseGroupedQuestions: result.data.getChapterWiseGroupedQuestions,
                showLoading: false
            })
        } else {
            this.setState({
                getChapterWiseGroupedQuestions: [],
                showLoading: false
            })
        }
    }

    startLearning = (data) => {
        this.props.history.push({
            pathname: "/student/chapter-wise-learning",
            state: {
                chapterData: data,
                clickType: "previous_questions",
                exam_type_value: this.state.exam_type_value,
                isPreviousQuestions: true,
                Ipe_chapter_id: data.chapter_id,
                subject_id: this.state.selectedSubjectVal,
                pathname:"/student/Chapter-wise-questions"
            }
        })
    }

    render() {

        let chaptersdata = [];

        if (Cookies.get("examid") == 1 || Cookies.get("examid") == 2) {
            chaptersdata = this.props.getSubjects.filter(item => item.id == this.state.selectedSubjectVal);
        }
        else {
            if (this.state.onLoadDisplayData == "onload") {
                chaptersdata = this.props.getStateChapterNames.filter(item => item.id == this.state.selectedSubjectVal);
            } else {
                chaptersdata = this.state.getStateChapterNamesData.filter(item => item.id == this.state.selectedSubjectVal);
            }
        }

        if (Cookies.get("examid") == 1 || Cookies.get("examid") == 2) {
            if (this.state.classData.value == 1) {
                chaptersdata = chaptersdata[0]?.studentChapters.filter(item => item.class == 1)
            } else {
                chaptersdata = chaptersdata[0]?.studentChapters.filter(item => item.class == 2)
            }
        } else {
            if (this.state.classData.value == 1) {
                chaptersdata = chaptersdata[0]?.state_chapters.filter(item => item.class_id == 1)
            } else {
                chaptersdata = chaptersdata[0]?.state_chapters.filter(item => item.class_id == 2)
            }
        }

        return (
            <Container className='chapter-wise-questions mt-2'>
                <Row>
                    <Col sm={12}>
                        {
                            this.state.displayQuestionsPage == true ?
                                (<Button type="button" className='btn-custom-red float-right f-14' onClick={() => this.showchaptersPage()}>View Chapters</Button>)
                                :
                                ("")
                        }
                    </Col>
                </Row>


                {
                    this.state.displayQuestionsPage == true ?
                        (
                            <>
                                <Row>
                                    <Col sm={12}>
                                        <QuestionsPage
                                            questionsData={this.state.getChapterWiseGroupedQuestions}
                                            showLoading={this.state.showLoading}
                                        />
                                    </Col>
                                </Row>

                            </>
                        )
                        :
                        (
                            <>
                                <Row>
                                    <Col sm={12}>
                                        <div className='subject-blocks-view'>
                                            {
                                                this.state.getSubjectsArr.map(sub =>
                                                    <Media className={this.subjectClassName(sub)} onClick={() => this.handleSelect(sub.id)}>
                                                        <Media.Body>
                                                            <h5 className='mt-2 text-center mt-2'>{sub.subject}</h5>

                                                        </Media.Body>
                                                        {
                                                            sub.id == "1" ?
                                                                (<Image src={Botany} className="ml-4" alt="subject" />) : ("")
                                                        }
                                                        {
                                                            sub.id == "2" ?
                                                                (<Image src={Physics} className="ml-4" alt="subject" />) : ("")
                                                        }
                                                        {
                                                            sub.id == "3" ?
                                                                (<Image src={Chemistry} className="ml-4 " alt="subject" />) : ("")
                                                        }
                                                        {
                                                            sub.id == "5" ?
                                                                (<Image src={Zoology} className="ml-4" alt="subject" />) : ("")
                                                        }
                                                        {
                                                            sub.id == "4" ?
                                                                (<Image src={Maths} className="ml-4" alt="subject" />) : ("")
                                                        }
                                                        {
                                                            sub.id == "6" ?
                                                                (<Image src={Botany} className="ml-4" alt="subject" />) : ("")
                                                        }
                                                    </Media>
                                                )
                                            }
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="AP_EAPCET" value="AP_EAPCET" name="input_exam_type" className="custom-control-input" checked={this.state.apeamcetCheckbox} onChange={(e) => this.handleChangeExamtype(e)} />
                                            <label className="custom-control-label inp_text" htmlFor="AP_EAPCET" >AP EAPCET</label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="TS_EAMCET" value="TS_EAMCET" name="input_exam_type" className="custom-control-input" checked={this.state.tseamcetCheckbox} onChange={(e) => this.handleChangeExamtype(e)} />
                                            <label className="custom-control-label inp_text" htmlFor="TS_EAMCET" >TS EAMCET </label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="AP_IPE" value="AP_IPE" name="input_exam_type" className="custom-control-input" checked={this.state.apIPEcheckbox} onChange={(e) => this.handleChangeExamtype(e, 12)} />
                                            <label className="custom-control-label inp_text" htmlFor="AP_IPE" >AP IPE</label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="TS_IPE" value="TS_IPE" name="input_exam_type" className="custom-control-input" checked={this.state.tsIPEcheckbox} onChange={(e) => this.handleChangeExamtype(e, 13)} />
                                            <label className="custom-control-label inp_text" htmlFor="TS_IPE" >TS IPE</label>
                                        </div>
                                    </div>
                                </Row>


                                <Row>
                                    <Col sm={6}>
                                        <ul className='list-inline class_type_li mt-4'>
                                            <li className={this.state.classData.value == "1" ? "active" : ""} onClick={() => this.selectHandleInputChange("1")}>Class XI</li>
                                            <li className={this.state.classData.value == "2" ? "active ml-4" : "ml-4"} onClick={() => this.selectHandleInputChange("2")}>Class XII</li>
                                        </ul>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className='mt-4'>
                                        <h5> Chapter Wise Previous Paper Questions- ( Subject : {this.getSubjectName(this.state.selectedSubjectVal)},  Class : {this.state.classData.value == 1 ? "XI" : "XII"} )</h5>
                                        <hr />
                                    </Col>
                                </Row>

                                {
                                    Cookies.get("examid") == 1 || Cookies.get("examid") == 2 ?
                                        (
                                            <Row>
                                                {
                                                    chaptersdata != "" && chaptersdata != undefined && chaptersdata.map((item, index) => {
                                                        return (
                                                            item.enabled == false ?
                                                                (
                                                                    <Col sm={12} lg={6}>
                                                                        <Card className='chapter_card mt-4 cursor_pointer' onClick={() => this.showQuestions(item.id)}>
                                                                            <Card.Body>
                                                                                <Row>
                                                                                    <Col sm={10}>
                                                                                        <h6 className='chapter_name mt-2'><span className='mr-2'>{index + 1}.</span>{item.chapter}</h6>
                                                                                        <p className='count'>( No.Of Questions- {item.chapterWisePreviousQuestionsCount} )</p>
                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <img src={ArrowNew} className='arrow' />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </Col>
                                                                )
                                                                :
                                                                (
                                                                    <Col sm={12} lg={6}>
                                                                        <Card className='chapter_card mt-4 cursor_pointer' onClick={() => this.handleSubscribeModal()}>
                                                                            <Card.Body>
                                                                                <Row>
                                                                                    <Col sm={10}>
                                                                                        <h6 className='chapter_name mt-2'><span className='mr-2'>{index + 1}.</span>{item.chapter}</h6>
                                                                                        <p className='count'>( No.Of Questions- {item.chapterWisePreviousQuestionsCount} )</p>

                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <img src={ArrowNew} className='arrow' />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </Col>
                                                                )
                                                        )
                                                    })
                                                }
                                            </Row>
                                        )
                                        :
                                        (
                                            <Row>
                                                {
                                                    this.state.showChaptersLoading == true ?
                                                        (
                                                            <Container>
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        <Image src={require('../../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        )
                                                        :
                                                        (
                                                            chaptersdata != "" && chaptersdata != undefined && chaptersdata.map((item, index) => {
                                                                return (
                                                                    item.previousChapterEnabled == false ?
                                                                        (
                                                                            <Col sm={12} lg={6}>
                                                                                <Card className='chapter_card mt-4 cursor_pointer' onClick={() => this.startLearning(item)}>
                                                                                    <Card.Body>
                                                                                        <Row>
                                                                                            <Col sm={10}>
                                                                                                <h6 className='chapter_name mt-2'><span className='mr-2'>{index + 1}.</span>{item.chapter_name}</h6>
                                                                                                <p className='count'>( No.Of Questions- {item.questionsCount == null ? "0" : item.questionsCount} )</p>
                                                                                            </Col>
                                                                                            <Col sm={2}>
                                                                                                <img src={ArrowNew} className='arrow' />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Card.Body>
                                                                                </Card>
                                                                            </Col>
                                                                        )
                                                                        :
                                                                        (
                                                                            <Col sm={12} lg={6}>
                                                                                <Card className='chapter_card mt-4 cursor_pointer' onClick={() => this.handleSubscribeModal()}>
                                                                                    <Card.Body>
                                                                                        <Row>
                                                                                            <Col sm={10}>
                                                                                                <h6 className='chapter_name mt-2'><span className='mr-2'>{index + 1}.</span>{item.chapter_name}</h6>
                                                                                                <p className='count'>( No.Of Questions- {item.questionsCount == null ? "0" : item.questionsCount} )</p>
                                                                                            </Col>
                                                                                            <Col sm={2}>
                                                                                                <img src={ArrowNew} className='arrow' />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Card.Body>
                                                                                </Card>
                                                                            </Col>
                                                                        )
                                                                )
                                                            })
                                                        )
                                                }
                                            </Row>
                                        )
                                }
                            </>
                        )
                }

                <EamcetRestrictionAlert
                    show={this.state.userRestionModalShow}
                    onHide={() => this.setState({ userRestionModalShow: false })}
                />



            </Container>
        )
    }
}


export default withRouter(withApollo(compose(

)(ChapterWiseQuestionsSection)));