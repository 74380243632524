import React, { Component } from 'react';
import { Container, Row, Col, Card, Button, Image, Form, Accordion } from 'react-bootstrap'
import * as Cookies from "es-cookie";
import { withRouter } from "react-router-dom";
import './_eamcetPreviousPapers.scss';
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import gql from "graphql-tag";
import moment from 'moment';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import EamcetRestrictionAlert from "../home/EamcetRestrictionAlert";

import { log } from 'logrocket';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';


const STUDENR_PREVIOUS_EXAM = gql`
  mutation(
    $params:StudentPreviousPaperExam  
    ) {
        studentPreviousPaperExam(
        params: $params
     )
  }
`;
class EamcetPreviousPapersSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitError: "",
            stateval: Cookies.get("examid"),
            defaultSelect: "",
            userRestionModalShow: false,
            currentActiveKey: "",
            mainPaperData: [],
        };
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }

    toggle = (index) => {
        if (this.state.currentActiveKey != index.toString()) {
            this.setState({ currentActiveKey: index })
        }
        else {
            this.setState({ currentActiveKey: "" })
        }

    }
    componentDidMount() {
        const title = GoogleAnalyticsArray[0].Eamcet_Previous_Papers;
        ReactGA.pageview('/student/StatePreviousPapers', ["rizee"], title);

        if (Cookies.get("examid") == 3 || Cookies.get("examid") == 7) {
            this.setState({
                defaultSelect: "AP"
            })
        } else {
            this.setState({
                defaultSelect: "TS"
            })
        }
    }

    examDetails(val) {
        this.setState({
            exam_id: val
        });
    }

    handleFormSubmit = (data) => {
        console.log(data)
        const params = {
            mobile: Cookies.get("mobile"),
            sub_type: data.year,
            exam_type: parseInt(Cookies.get("examid")),
            previous_exam_type: parseInt(data.exam),
            source: 0,
            set_id: parseInt(data.id),
        };

        this.customfunction(
            params
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
            console.error("ERR =>", error.graphQLErrors.map(x => x.message));
        });

    };

    handleFormSubmitResume = (data) => {
        console.log("data", data);
        const params = {
            mobile: Cookies.get("mobile"),
            sub_type: data.year,
            exam_type: parseInt(Cookies.get("examid")),
            previous_exam_type: parseInt(data.exam),
            source: 0,
            set_id: parseInt(data.id),
        };

        this.customfunctionResume(
            data.start_session_id
        )
    };





    customfunction = async (
        params) => {
        await this.props.customfunction({
            variables: {
                params
            },
            update: (store, { data }) => {
                console.log("updatedata", data);
                if (data.studentPreviousPaperExam) {
                    console.log("updatedatadataID", data.studentPreviousPaperExam)
                    localStorage.setItem("sessionid", data.studentPreviousPaperExam);
                    localStorage.setItem("type", "Previous Paper Exam");
                    localStorage.setItem("stype", "");
                    localStorage.setItem("exam_paper_id", "0");
                    localStorage.setItem("etype", "eamcet_previous");

                    if (isMobile == true && Cookies.get("institution_id") == 30) {
                        this.props.history.push({
                            pathname: "/student/subject/exam",
                        })
                    } else {
                        window.open("/student/subject/exam", "_blank");
                    }
                }
            }
        });
    };

    customfunctionResume = (
        start_session_id) => {
        localStorage.setItem("sessionid", start_session_id);
        localStorage.setItem("type", "Previous Paper Exam");
        localStorage.setItem("stype", "");
        localStorage.setItem("exam_paper_id", "0");
        localStorage.setItem("etype", "eamcet_previous");
        localStorage.setItem("resumeExam", true);

        if (isMobile == true && Cookies.get("institution_id") == 30) {
            this.props.history.push({
                pathname: "/student/subject/exam",
            })
        } else {
            window.open("/student/subject/exam", "_blank");
        }
    };

    handleResultFunction = (id) => {
        console.log("id", id);

        this.props.history.push({
            pathname: "/student/subject/exam-result",
            state: {
                sessionid: id,
                examtype: "eamcet_previous",
            }
        })
    };

    getblocks = (indexVal) => {
        if (this.state.expandval != indexVal.toString()) {
            this.setState({ expandval: indexVal })
        }
        else {
            this.setState({ expandval: "" })
        }
    }

    handlestate(type) {
        this.setState({
            defaultSelect: type,
            currentActiveKey: "",
            mainPaperData: []
        })
        if (Cookies.get("examid") == 3 || Cookies.get("examid") == 6) {
            if (type == "AP") {
                this.setState({
                    stateval: 3
                })
            } else {
                this.setState({
                    stateval: 6
                })
            }
        } else if (Cookies.get("examid") == 7 || Cookies.get("examid") == 8) {
            if (type == "AP") {
                this.setState({
                    stateval: 7
                })
            } else {
                this.setState({
                    stateval: 8
                })
            }
        }
    };

    modalaFun = () => {
        this.setState({
            userRestionModalShow: true,
        })
    }


    render() {
        console.log("state", this.state);
        let examYears = ['2016', '2017', '2018', '2019', '2020', '2021', '2022'];
        let currentTime_currentSec = Math.round((new Date()).getTime() / 1000);
        console.log("studentGlobals", this.props.studentGlobals.previousSets)
        let papersData = this.props.studentGlobals.previousSets.filter((item) => item.exam == this.state.stateval);
        const uniqueArr = [... new Set(papersData.map(data => data.year))];
        let filterArray = examYears.filter(val => !uniqueArr.includes(val));
        let data = uniqueArr.concat(filterArray).sort((a, b) => b - a);
        let examsData = [];
        data.forEach(function (item1) {
            let examValues = [];
            let obj = {
                year: item1,
                value: examValues
            }
            papersData.map((item) => {
                if (item.year == obj.year) {
                    let obj1 = {
                        ...item
                    }
                    examValues.push(obj1)
                }
            });
            examsData.push(obj)
        });
        this.state.mainPaperData = examsData;


        console.log("mainPaperData", this.state.mainPaperData);

        return (
            <section className='eamcet-previous-papers'>
                <div className='bg_white_list'>
                    <Container >
                        {
                            Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                                (<Row>
                                    <Col sm={12}>
                                        <ul className='list-inline list_style_EPP mt-3'>
                                            <li className={this.state.defaultSelect == 'AP' ? 'active' : ''} onClick={() => this.handlestate('AP')} >AP EAPCET</li>
                                            <li className={this.state.defaultSelect == 'TS' ? 'ml-4 active' : 'ml-4'} onClick={() => this.handlestate('TS')}>TS EAMCET</li>
                                        </ul>
                                    </Col>
                                </Row>)
                                :
                                ("")
                        }
                    </Container>
                </div>
                <div>
                    <Container>
                        <Form.Text className="form-text text-danger">
                            {this.state.submitError}
                        </Form.Text>

                        <React.Fragment>

                            <Accordion>
                                {
                                    this.state.mainPaperData.map((item, index) => {
                                        console.log("Accordion", item);
                                        return (
                                            <Row>
                                                <Col sm={12}>
                                                    <React.Fragment key={index} className="border-0">
                                                        <Accordion.Toggle
                                                            as={Card}
                                                            className='mt-2 border_none_fff'
                                                            eventKey={index}
                                                            onClick={() => this.toggle(index)}
                                                        >
                                                            <div >
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h6 className={this.state.currentActiveKey == index.toString() ? "chap_block_view active" : "chap_block_view "} >
                                                                            <span className='ml-2'>{item.year} Previous Papers</span>
                                                                            {this.state.currentActiveKey == index.toString() ? (
                                                                                <i className="fas fa-chevron-up float-right" style={{ cursor: "pointer" }}></i>
                                                                            ) : (
                                                                                <i className="fas fa-chevron-down float-right" style={{ cursor: "pointer" }}></i>
                                                                            )}
                                                                        </h6>
                                                                    </Col>
                                                                </Row>


                                                            </div>

                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey={index}>
                                                            <Card as={Card.Body} className="p-2">
                                                                <Row>
                                                                    {item.value != null && item.value.length > 0 ?
                                                                        (
                                                                            item.value.map((item) => {
                                                                                return (
                                                                                    <Col sm={4} className='mt-2'>
                                                                                        <Card className='border_pad_card' style={{ cursor: "pointer" }}>
                                                                                            <Card.Body className='p-2'>
                                                                                                <h6 className='mt-2 mb-3 ml-1'>{item.qset}</h6>
                                                                                                <p className='mt-2 mb-3 ml-1'>Duration : <b>{item.exam_duration_min}</b>
                                                                                                    {item.enabled == false ? (
                                                                                                        item.attempted == false ? (
                                                                                                            item.is_started ? (
                                                                                                                <button type="button" className="btn btn-custom-blutst float-right f-14" onClick={() => this.handleFormSubmitResume(item)}>Resume Test</button>
                                                                                                            ) : (
                                                                                                                <button type="button" className="btn btn-custom-blutst float-right f-14" onClick={() => this.handleFormSubmit(item)}>Start Test</button>
                                                                                                            )

                                                                                                        ) : (
                                                                                                            <button type="button" className="btn btn-custom-blutst float-right f-14" onClick={() => this.handleResultFunction(item.examsessionid)}>View Result</button>
                                                                                                        )
                                                                                                    ) : (
                                                                                                        <button type="button" className="btn btn-custom-blutst float-right f-14" onClick={() => this.modalaFun()}>Start Test</button>
                                                                                                    )

                                                                                                    }
                                                                                                </p>


                                                                                            </Card.Body>
                                                                                        </Card>
                                                                                    </Col>
                                                                                )
                                                                            }
                                                                            ))
                                                                        : (<Col sm={12}><h6 className='text-center'>Coming Soon...</h6></Col>)
                                                                    }


                                                                </Row>
                                                            </Card>
                                                        </Accordion.Collapse>
                                                    </React.Fragment>
                                                </Col>
                                            </Row>


                                        )
                                    })
                                }
                            </Accordion>

                        </React.Fragment>

                        <EamcetRestrictionAlert
                            show={this.state.userRestionModalShow}
                            onHide={() => this.setState({ userRestionModalShow: false })}
                        />


                    </Container>
                </div>
            </section>
        )
    }
}

export default withRouter(compose(graphql(STUDENR_PREVIOUS_EXAM, {
    name: "customfunction"
})
)(EamcetPreviousPapersSection));