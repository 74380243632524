import React, { Component } from 'react'
import { Row, Col, Table, Image, Button, Container, Accordion, Card, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import { components } from 'react-select'
import * as Cookies from "es-cookie";
import moment from 'moment';
import "./_referFriend.scss";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import referLoginImage from '../../../images/referFriendLogin.png'


class LoginPagePopup extends Component {
    constructor(props) {
        super(props);

    }

    handlePageRedirect=()=>{
        localStorage.setItem("loginrefermodal",false)
        this.props.history.push({
            pathname:"/student/referFriend"
        })
    }

    render() {
        return (
            <Modal {...this.props} className='login_popup'
                size="md" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-2'>
                    <Row>
                        <Col sm={4}>
                            <img src={referLoginImage} alt="New" className='ref-login-img'  />
                        </Col>

                        <Col sm={8} className="content-box-ref">
                            <h2 className='ref-header text-center'>Invite a study partner</h2>
                            <p className='ref-login-des text-center'>Invite your friends to join you on a study tour and get <span className='font-weight-bold colour_black'>2 days</span> <span className='font-weight-bold colour_black'>Rizee</span> subscription as a reward, also your friend will gets <span className='font-weight-bold colour_black'>3 days</span> Free trail access</p>
                            <Button className='ref-login-btn center-ref-auto' onClick={() => this.handlePageRedirect()}>Invite Friends</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(compose(
)(LoginPagePopup));
