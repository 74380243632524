export const GetReadyExamShortNoteList = [
    {
        id: '01',
        title: 'History and Impact of Science ',
        description: 'The impact of science on our society is very evident. The tremendous progress made in the fields such as agriculture, healthcare, telecommunications, transportation, computerization has brought in all the comforts of modern day lifestyle. Few of the most revolutionary scientific discoveries can be listed as the invention of wheel,steam engine, vaccination etc. The discoveries of planetary system, electricity, gravity, evolution etc too have been remarkable events in the history of mankind. The impact of science on our society is very evident. The tremendous progress made in the fields such as agriculture, healthcare, telecommunications, transportation, computerization has brought in all the comforts of modern day lifestyle. Few of the most revolutionary scientific discoveries can be listed as the invention of wheel,steam engine, vaccination etc. The discoveries of planetary system, electricity, gravity, evolution etc too have been remarkable events in the history of mankind. The impact of science on our society is very evident. The tremendous progress made in the fields such as agriculture, healthcare, telecommunications, transportation, computerization has brought in all the comforts of modern day lifestyle. Few of the most revolutionary scientific discoveries can be listed as the invention of wheel,steam engine, vaccination etc. The discoveries of planetary system, electricity, gravity, evolution etc too have been remarkable events in the history of mankind.'
    },
    {
        id: '02',
        title: 'History and Impact of Science ',
        description: 'The impact of science on our society is very evident. The tremendous progress made in the fields such as agriculture, healthcare, telecommunications, transportation, computerization has brought in all the comforts of modern day lifestyle. Few of the most revolutionary scientific discoveries can be listed as the invention of wheel,steam engine, vaccination etc. The discoveries of planetary system, electricity, gravity, evolution etc too have been remarkable events in the history of mankind.'
    },
    {
        id: '03',
        title: 'History and Impact of Science ',
        description: 'The impact of science on our society is very evident. The tremendous progress made in the fields such as agriculture, healthcare, telecommunications, transportation, computerization has brought in all the comforts of modern day lifestyle. Few of the most revolutionary scientific discoveries can be listed as the invention of wheel,steam engine, vaccination etc. The discoveries of planetary system, electricity, gravity, evolution etc too have been remarkable events in the history of mankind.'
    },
    {
        id: '04',
        title: 'History and Impact of Science ',
        description: 'The impact of science on our society is very evident. The tremendous progress made in the fields such as agriculture, healthcare, telecommunications, transportation, computerization has brought in all the comforts of modern day lifestyle. Few of the most revolutionary scientific discoveries can be listed as the invention of wheel,steam engine, vaccination etc. The discoveries of planetary system, electricity, gravity, evolution etc too have been remarkable events in the history of mankind.'
    }
];

export const GetReadyExamMeterialsData = [
    {
        id: '01',
        title: 'Molecular Formula',
        topicName: '01 -  Topic Name',
        description: 'The impact of science on our society is very evident. The tremendous progress made in the fields such as agriculture, healthcare, telecommunications, transportation, computerization has brought in all the comforts of modern day lifestyle. Few of the most revolutionary scientific discoveries can be listed as the invention of wheel,steam engine, vaccination etc. The discoveries of planetary system, electricity, gravity, evolution etc too have been remarkable events in the history of mankind. The impact of science on our society is very evident. The tremendous progress made in the fields such as agriculture, healthcare, telecommunications, transportation, computerization has brought in all the comforts of modern day lifestyle. Few of the most revolutionary scientific discoveries can be listed as the invention of wheel,steam engine, vaccination etc. The discoveries of planetary system, electricity, gravity, evolution etc too have been remarkable events in the history of mankind. The impact of science on our society is very evident. The tremendous progress made in the fields such as agriculture, healthcare, telecommunications, transportation, computerization has brought in all the comforts of modern day lifestyle. Few of the most revolutionary scientific discoveries can be listed as the invention of wheel,steam engine, vaccination etc. The discoveries of planetary system, electricity, gravity, evolution etc too have been remarkable events in the history of mankind.'
    },
    {
        id: '02',
        title: 'Molecular Formula',
        topicName: '02 -  Topic Name',
        description: 'The impact of science on our society is very evident. The tremendous progress made in the fields such as agriculture, healthcare, telecommunications, transportation, computerization has brought in all the comforts of modern day lifestyle. Few of the most revolutionary scientific discoveries can be listed as the invention of wheel,steam engine, vaccination etc. The discoveries of planetary system, electricity, gravity, evolution etc too have been remarkable events in the history of mankind.'
    },
    {
        id: '03',
        title: 'Molecular Formula',
        topicName: '03 - Topic Name',
        description: 'The impact of science on our society is very evident. The tremendous progress made in the fields such as agriculture, healthcare, telecommunications, transportation, computerization has brought in all the comforts of modern day lifestyle. Few of the most revolutionary scientific discoveries can be listed as the invention of wheel,steam engine, vaccination etc. The discoveries of planetary system, electricity, gravity, evolution etc too have been remarkable events in the history of mankind.'
    },
    {
        id: '04',
        title: 'Molecular Formula',
        topicName: '04 - Topic Name',
        description: 'The impact of science on our society is very evident. The tremendous progress made in the fields such as agriculture, healthcare, telecommunications, transportation, computerization has brought in all the comforts of modern day lifestyle. Few of the most revolutionary scientific discoveries can be listed as the invention of wheel,steam engine, vaccination etc. The discoveries of planetary system, electricity, gravity, evolution etc too have been remarkable events in the history of mankind.'
    }
];