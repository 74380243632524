import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import { Row, Tabs, Tab, Card, Image, Button, Col,Form, Container } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import './_rankpredictor.scss';
import * as compose from 'lodash.flowright';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
import Arrow_Rank from '../../../images/arrow_rank.png';
import Rank_predictor from '../../../images/rank_predictor.png';

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';


const INSERT_IPE_AND_MOCK_MARKS = gql`
  mutation(
    $params:InsertStudentIpeMarks
    ) {
        insertStudentIpeMarks(
           params: $params
     )
  }
`;

class EamcetRankPredictorSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            validateInputMarks:"",
            getRangeRangeValue:"",
            inputMarks:"",
            loader: 0,
            submitError1: "",
            storeInputMarks:""
        }
    }

    componentDidMount() {
        const title = GoogleAnalyticsArray[0].Eamcet_Rank_Predictor;
        ReactGA.pageview('/student/eamcet-rankPredictor',["rizee"], title);

        if(this.props.rankType == "Exams"){
            console.log("ddddddddddddddddddddddddddd",this.props.rankType)
            this.handleSubmit();
        }
    }

    handleSubmit = e => {
        this.setState({validateInputMarks:""});

        //const storedValued=this.state.storeInputMarks
        //this.setState({inputMarks:storedValued});

        //console.log("aaaaaaaaaa",this.state.inputMarks)
        if(this.props.rankType != "Exams"){
            var pattern = /^[0-9\b]+$/;


            if(this.state.inputMarks == "" || this.state.inputMarks == 0){
                this.setState({validateInputMarks:"Please Enter Marks" });
                return;
            }
            if (!pattern.test(this.state.inputMarks)) {
                this.setState({validateInputMarks:"Please Enter Valid Marks" });
                return;
            }
            if(this.state.inputMarks > 160 ){
                this.setState({validateInputMarks:"Please Enter Valid Marks" });
                return;
            }


        }


        this.setState({loader: 1 });

        let marks = "";
        if(this.props.rankType == "Exams"){
            marks = this.props.inputMarks
        }else{
            marks = this.state.inputMarks
        }

        let practisetestobj = "";
        practisetestobj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            mocktest_score: parseInt(marks)
        }

        console.log("practisetestobj", practisetestobj);

        this.submitpt(
            practisetestobj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError1: error.graphQLErrors.map(x => x.message)
            });
        });

    };
    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
            update: (store, { data }) => {
                if (data.insertStudentIpeMarks) {
                    this.setState({
                        loader:0
                    });
                    this.getRankRange();
                }
            }
        });
    };

    handleInputChange = (e) => {
        this.setState({validateInputMarks:"" });

        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;

        /*var pattern = /^[0-9\b]+$/;
        if (!pattern.test(value)) {
            this.setState({validateInputMarks:"Please Enter Valid Marks" });
            return;
        }
        if(value > 160 ){
            this.setState({validateInputMarks:"Please Enter Valid Marks" });
            return;
        }*/


       // setTimeout(() => {
            this.setState({storeInputMarks:value});
            this.setState({ [name]: value });
          //}, 1000);
        //console.log("handleInputChange", this.state.storeInputMarks)
    }

    getRankRange = async () => {
         const result = await this.props.client.query({
                query: gql` 
            query(
                $mobile:String
                ) {
                    getStudentMarksRankPredictor(
                        mobile: $mobile
                    )
                    {
                        rank_range {
                            rank_range
                        }
                }
            }
        `,
                variables: {

                    mobile: Cookies.get("mobile")

                },
                fetchPolicy: 'network-only'
            });

            console.log("aaaaa",result);
            
            if(result.data.getStudentMarksRankPredictor != undefined){
                this.setState({
                    getRangeRangeValue: result.data.getStudentMarksRankPredictor,
                    loader:0
                });
            }
           
    }


    render() {
       let rangeValue =  "";
        if(this.state.getRangeRangeValue != ""){
            this.state.getRangeRangeValue.map(item => {
                rangeValue = item.rank_range[0].rank_range
           })
        }
        
       
       console.log(rangeValue)
       
        return (
            <Container className='rank-predictor'>
                <Row>
                   <Col sm={12}>
                        <Card className={this.props.rankType != "Exams" ? 'box-shadow-cls mt-4' : 'mt-4 border_none'}>
                            <div className={this.props.rankType != "Exams" ? 'flex-block-rank' : 'flex-block-Single-card'} >
                                {
                                    this.props.rankType != "Exams" ?
                                    (
                                        <div className='bg_white_registration w-60-custom'> 
                                            <h5 className='text-center mt-5'>Estimated EAMCET Marks</h5>
                                                <Form.Group as={Col} xl={4} lg={4} md={6} sm={12}  className='center-auto mt-5'>
                                                    <Form.Control
                                                        type="text"
                                                        name="inputMarks"
                                                        placeholder="Enter Marks"
                                                        onChange={this.handleInputChange}
                                                    />
                                            </Form.Group>
                                            <span className='errorCls'>{this.state.validateInputMarks}</span>
                                            {this.state.loader == 1 ? (
                                                <Button  className="btn btn-yellow mt-5 center-auto" type="submit" disabled style={{ width: "" }}>
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                    loding...
                                                </Button>
                                            ) : (
                                                <Button onClick={(e) => this.handleSubmit()} className="btn btn-yellow mt-5 center-auto" type="submit">
                                                    Get My  Predicted Rank Range
                                                </Button>
                                            )}   
                                        </div>
                                    )
                                    :
                                    ("")
                                }
                                
                                <div className='bg_blue_registration w-50-custom'>
                                    <Row className='justify-content-center'>
                                        {
                                            this.props.rankType == "Exams" ?
                                            (
                                                <Col sm={9}>
                                                    <div className='top_pos'>
                                                        <Image src={Rank_predictor} alt="Rank" className='image_rank_pos'/>
                                                        <div className='block_white'>
                                                            {
                                                                rangeValue == "" ?
                                                                (
                                                                    <>
                                                                       <h6  className="center-auto text-center">
                                                                            <span className="spinner-border spinner-border-sm"></span>
                                                                        </h6>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <h2 className='rank_color text-center'>{rangeValue}</h2>
                                                                        <h6 className='text-center mt-2'>Your EAMCET Rank Range </h6>
                                                                    </>
                                                                )
                                                            }
                                                            
                                                        </div>
                                                             
                                                    </div>
                                                    
                                                </Col>
                                            )
                                            :
                                            (
                                                <Col sm={9}>
                                                    <div className='top_pos'>
                                                        <Image src={Rank_predictor} alt="Rank" className='image_rank_pos'/>
                                                        <div className='block_white'>
                                                            {
                                                                rangeValue == "" ?
                                                                (
                                                                    <>
                                                                        <h6 className='text-center'><Image src={Arrow_Rank} className="ml-4" alt="Arrow" /> Enter Your Marks</h6>
                                                                        <h6 className='text-center'>You will get your EAMCET Rank Range </h6>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <h2 className='rank_color text-center'>{rangeValue}</h2>
                                                                        <h6 className='text-center mt-2'>Your EAMCET Rank Range </h6>
                                                                    </>
                                                                )
                                                            }
                                                            
                                                        </div>
                                                          
                                                    </div>
                                                    
                                                </Col>
                                            )
                                        }
                                        
                                    </Row>
                                </div>
                            </div>
                        </Card>
                   </Col>
                </Row>
            </Container>
        )
    }

}

export default
    withRouter(withApollo(
        compose(
            graphql(INSERT_IPE_AND_MOCK_MARKS, {
                name: "submitpt"
            })
)(EamcetRankPredictorSection)));


