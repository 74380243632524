import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.scss";
import "./fontawesome/css/all.min.css";
import "bootstrap/scss/bootstrap.scss";
import * as Cookies from "es-cookie";
import Home from "./neetjee_guru/pages/Home";
import HomeExam from "./neetjee_guru/pages/HomeExam";
import StudentOrganizationProfile from './neetjee_guru/pages/StudentOrganizationProfile';
import Login from "./neetjee_guru/pages/Login";
import StudentSuccessRegister from "./student_module/pages/Success";
import StudentFailureRegister from "./student_module/pages/Failure";
import Package from "./neetjee_guru/pages/Package";
import PackageNew from "./student_module/components/register_steps/PackageNew"
import Category from "./neetjee_guru/pages/Category";
import Branches from "./neetjee_guru/pages/Branches";
import AddSection from "./neetjee_guru/pages/AddSection";
import Groups from "./neetjee_guru/pages/Groups";
import Teachers from "./neetjee_guru/pages/Teachers";
import Students from "./neetjee_guru/pages/Students";
import OrganizationSetup from "./neetjee_guru/pages/OrganizationSetup";
import OrganizationProfile from "./neetjee_guru/pages/OrganizationProfile";
import PromoteStudent from "./neetjee_guru/pages/PromoteStudent";
import CreateQuestionPaper from "./neetjee_guru/pages/CreateQuestionPaper";
import OwnQuestionPaper from "./neetjee_guru/pages/OwnQuestionPaper";
import OwnQuestionCustom from "./neetjee_guru/pages/OwnQuestionCustom";
import QuestionsPreview from "./neetjee_guru/pages/QuestionsPreview";
import OwnQuestionCustompresent from "./neetjee_guru/pages/OwnQuestionCustompresent";
import OwnQuestionOldpresent from "./neetjee_guru/pages/OwnQuestionOldpresent";
import OwnQuestionCollegeExam from "./neetjee_guru/pages/OwnQuestionCollegeExam";
import OwnQuestionCumulative from "./neetjee_guru/pages/OwnQuestionCumulative";
import OwnQuestionChapter from "./neetjee_guru/pages/OwnQuestionChapter";
import OwnQuestionSemiGrand from "./neetjee_guru/pages/OwnQuestionSemiGrand";
import OwnQuestionGrand from "./neetjee_guru/pages/OwnQuestionGrand";
import ManageQuestionPaper from "./neetjee_guru/pages/ManageQuestionPaper";
import QuestionPaperResult from "./neetjee_guru/pages/QuestionPaperResult";
import Settings from "./neetjee_guru/pages/Settings";
import AdaptiveQuestionPaper from "./neetjee_guru/pages/AdaptiveQuestionPaper";
import ErrorQuestion from "./neetjee_guru/pages/ErrorQuestion";
import OurQuestions from "./neetjee_guru/pages/OurQuestions";
import ManageOurQuestionPaper from "./neetjee_guru/pages/ManageOurQuestionPaper";
import QuestionPattern from "./neetjee_guru/pages/QuestionPattern";
import CreateQuestionPattern from "./neetjee_guru/pages/CreateQuestionPattern";
import CreateCustomQuestionPaper from "./neetjee_guru/pages/CreateCustomQuestionPaper";
import CreateCustomSummery from "./neetjee_guru/pages/CreateCustomSummery";
import PageNotFound from "./neetjee_guru/pages/PageNotFound";
import UserCreations from "./neetjee_guru/pages/UserCreations";
import NotifyStudents from "./neetjee_guru/pages/NotifyStudents";
import ResultAnalysisCollege from "./neetjee_guru/pages/ResultAnalysis";
import PracticeExamAnalysisCollege from "./neetjee_guru/pages/PracticeExamAnalysis";
import StrengthAnalysisCollege from "./neetjee_guru/pages/StrengthAnalysis";
import TimeAnalysisCollege from "./neetjee_guru/pages/TimeAnalysis";
import ComplexityAnalysisCollege from "./neetjee_guru/pages/ComplexityAnalysis";
import ErrorAnalysisCollege from "./neetjee_guru/pages/ErrorAnalysis";
import QuestionTypeAnalysisCollege from "./neetjee_guru/pages/QuestionTypeAnalysis";



//Student Module

import ForgotPassword from "./student_module/pages/ForgotPassword";
import StudentPageNotFound from "./student_module/pages/PageNotFound";
import StudentLogin from "./student_module/pages/StudentLogin";
import StudentLoginIndividual from "./student_module/pages/studentLoginIndividual";
import StudentRegister from "./student_module/pages/StudentRegister";
import StudentHome from "./student_module/pages/StudentHome";
import LearnPractice from "./student_module/pages/LearnPractice";
import Subjects from "./student_module/pages/Subjects";
import NewTopics from "./student_module/pages/NewTopics";
import Chapter from "./student_module/pages/Chapter";
import OverAllChapterStrength from "./student_module/pages/OverAllChapterStrength";
import PracticeAndExamHistory from "./student_module/pages/PracticeAndExamHistory";
import ExamHistory from "./student_module/pages/ExamHistory";
import StartErrorExam from "./student_module/pages/StartErrorExam";
import WatchingVideo from "./student_module/pages/WatchingVideo";
import RevisionMaterial from "./student_module/pages/RevisionMaterial";
import SingleVideo from "./student_module/pages/SingleVideo";
import SingleShortNote from "./student_module/pages/SingleShortNote";
import PracticeInstructions from "./student_module/pages/PracticeInstructions";
import CustomInstructions from "./student_module/pages/CustomInstructions";
import PracticeSubmitback from "./student_module/pages/PracticeSubmitback";
import CustomsubmitBack from "./student_module/pages/CustomsubmitBack";
import PracticeExam from "./student_module/pages/PracticeExam";
import StudentAssistentExam from "./student_module/pages/StudentAssistentExam";
import ViewQuestionAnswer from "./student_module/pages/ViewQuestionAnswer";
import PracticeViewQuestionAnswer from "./student_module/pages/PracticeViewQuestionAnswer";
import PracticeErrorExam from "./student_module/pages/PracticeErrorExam";
import CustomExamTest from "./student_module/pages/CustomExamTest";
import Exam from "./student_module/pages/Exam";
import ScheduleExam from "./student_module/pages/ScheduleExam";
import CustomExam from "./student_module/pages/CustomExam";
import PreviousPaper from "./student_module/pages/PreviousPaper";
import CustomPreviousPaper from "./student_module/pages/CustomPreviousPaper";
import AdaptiveExam from "./student_module/pages/AdaptiveExam";
import ErrorExam from "./student_module/pages/ErrorExam";
import GetReadyForExam from "./student_module/pages/GetReadyForExam";
import ChapterExam from "./student_module/pages/ChapterExam";
import CumulativeExam from "./student_module/pages/CumulativeExam";
import GetReadySemiGrandExam from "./student_module/pages/GetReadySemiGrandExam";
import GetReadyGrandExam from "./student_module/pages/GetReadyGrandExam";
import GetReadyExamHistory from "./student_module/pages/GetReadyExamHistory";
import GetReadyExamUpcomingHistory from "./student_module/pages/GetReadyExamUpcomingHistory";
import GetReadyShortNoteMaterialRevisions from "./student_module/pages/GetReadyShortNoteMaterialRevisions";
import GetReadySingleShortNoteMaterialRevisions from "./student_module/pages/GetReadySingleShortNoteMaterialRevisions";
import Bookmarks from "./student_module/pages/Bookmarks";
import BookmarkVideos from "./student_module/pages/BookmarkVideos";
import SingleBookmarkVideo from "./student_module/pages/SingleBookmarkVideo";
import BookmarkShortnotesMaterials from "./student_module/pages/BookmarkShortnotesMaterials";
import BookmarkSingleShortNoteMaterialRevisions from "./student_module/pages/BookmarkSingleShortNoteMaterialRevisions";
import BookmarkPracticeQuestions from "./student_module/pages/BookmarkPracticeQuestions";
import BookmarkExamQuestions from "./student_module/pages/BookmarkExamQuestions";
import Profile from "./student_module/pages/Profile";
import Notes from "./student_module/pages/Notes";
import NoteVideos from "./student_module/pages/NoteVideos";
import SingleNotesVideo from "./student_module/pages/SingleNotesVideo";
import NotesShortnotesMaterials from "./student_module/pages/NotesShortnotesMaterials";
import NotesSingleShortNoteMaterialRevisions from "./student_module/pages/NotesSingleShortNoteMaterialRevisions";
import NotesPracticeQuestions from "./student_module/pages/NotesPracticeQuestions";
import NotesExamQuestions from "./student_module/pages/NotesExamQuestions";
import NotesSingleMaterialRevisions from "./student_module/pages/NotesSingleMaterialRevisions";
import ResultAnalysis from "./student_module/pages/ResultAnalysis";
import PracticeExamAnalysis from "./student_module/pages/PracticeExamAnalysis";
import StrengthAnalysis from "./student_module/pages/StrengthAnalysis";
import TimeAnalysis from "./student_module/pages/TimeAnalysis";
import ComplexityAnalysis from "./student_module/pages/ComplexityAnalysis";
import ErrorAnalysis from "./student_module/pages/ErrorAnalysis";
import QuestionTypeAnalysis from "./student_module/pages/QuestionTypeAnalysis";
import Notification from './student_module/pages/Notification';
import AllNotifications from './student_module/pages/AllNotifications';
import StudentPackage from './student_module/pages/StudentPackage';
import StudentPackageNew from './student_module/pages/StudentPackageNew';
import RevisionMaterialGroups from './student_module/pages/RevisionMaterialGroups';
import CustomRevisionMaterials from './student_module/pages/CustomRevisionMaterials';
import CustomSingleRevisionMaterial from './student_module/pages/CustomSingleRevisionMaterial';
import StudentLoading from "./student_module/pages/StudentLoading";
import StudentFeedBack from './student_module/pages/StudentFeedBack';
import PracticeTestExamResult from './student_module/pages/PracticeTestExamResult';
import MocktestExamResult from "./student_module/pages/MocktestExamResult";
import PracticeExamResult from './student_module/pages/PracticeExamResult';
import PreviousPaperAnalysis from './student_module/pages/PreviousPaperAnalysis';
import LinkageChapterAnalysis from './student_module/pages/LinkageChapterAnalysis';

import SinglePracticeExamResult from './student_module/pages/SinglePracticeExamResult';
import SinglePracticeTestExamResult from './student_module/pages/SinglePracticeTestExamResult';
import StudentWiseResult from "./student_module/pages/StudentWiseResult";

import SingleVideoWatching from './student_module/pages/SingleVideoWatching';
import SubscribeOrderSummary from './student_module/pages/SubscribeOrderSummary';
import MockTestSeries from "./student_module/pages/MockTestSeries";
import MockTestSeriesInstitute from "./student_module/pages/MockTestSeriesInstitute";
import MockTestOrderSummary from './student_module/pages/MockTestOrderSummary';
import VideosRecentlyWatched from './student_module/pages/VideosRecentlyWatched';
import Videos from './student_module/pages/Videos';
import OrderSummary from './student_module/pages/OrderSummary';
import SeriesExamTest from "./student_module/pages/SeriesExamTest";
import AutoStudentLoading from "./student_module/pages/AutoStudentLoading";
import ActionBookmarks from "./student_module/pages/ActionBookmarks";
import ActionRevisionMaterialGroups from "./student_module/pages/ActionRevisionMaterialGroups";
import NotificationLearn from "./student_module/pages/NotificationLearn";
import NotificationPractise from "./student_module/pages/NotificationPractise";
import ActionReadyExam from "./student_module/pages/GetReadyForExam";
import ActionExam from "./student_module/pages/Exam";
import ActionResultAnalysis from "./student_module/pages/ResultAnalysis";
import ActionNotes from "./student_module/pages/Notes";
import ActionFeedback from "./student_module/pages/StudentFeedBack";
import ActionPackages from "./student_module/pages/StudentPackage";
import ActionProfile from "./student_module/pages/Profile";
import ActionPreviousPapers from "./student_module/pages/PreviousPaperAnalysis";
import ActionForum from "./student_module/pages/ActionForum";
import ActionVideosScreen from "./student_module/pages/Videos";
import ActionTestSeriesScreen from "./student_module/pages/MockTestSeries";
import ActionLearnPractice from "./student_module/pages/LearnPractice";

import NotificationMockTestSeries from "./student_module/pages/NotificationMockTestSeries";
import ActionStudentHome from "./student_module/pages/StudentHome";
import ActionLinkageAnalysis from "./student_module/pages/LinkageChapterAnalysis";
import ActionBlog from "./student_module/pages/StudentBlog";
import DefaultVideos from './student_module/pages/DefaultVideos';
import StudentBlog from './student_module/pages/StudentBlog';
import StudentSingleBlog from './student_module/pages/StudentSingleBlog';
import YoutubeVideos from './student_module/pages/YoutubeVideos';
import FeaturedVideos from './student_module/pages/FeaturedVideos';
import CreatePreviousPaper from './student_module/pages/CreatePreviousPaper';
import CollegeSinglePracticeTestExamResult from './student_module/pages/CollegeSinglePracticeTestExamResult';

import RapidRevision from "./student_module/pages/RapidRevision";
import BluePrint from "./student_module/pages/BluePrint";
import PreviousPapers from "./student_module/pages/PreviousPapers";
import ShortNotes from "./student_module/pages/ShortNotes";
import Syllabus from "./student_module/pages/syllabus";
import LiveClasses from "./student_module/pages/LiveClasses";
import MockTestSeriesNewNTAPattern from "./student_module/pages/MockTestSeriesNewNTAPattern";
import CategoryWiseVideos from "./student_module/pages/CategoryWiseVideos";
import CustomAllVideos from "./student_module/pages/CustomAllVideos";
import LevelWiseVideosBlock from "./student_module/pages/LevelWiseVideosBlock";
import ReferFriend from "./student_module/pages/ReferFriend";
import RepeatersRevision from "./student_module/pages/RepeatersRevision";
import SubPlans from "./student_module/pages/SubPlans";
import ReactGA from 'react-ga';
import StudentLoginEamcet from "./student_module/pages/StudentLoginEamcet";
import Cbse from "./student_module/pages/CbsePage";
import NeetRiseCourseExams from "./student_module/pages/NeetRiseCourseExams";
import LiveSessions from "./student_module/pages/LiveSessions";
import QuizExam from "./student_module/pages/QuizExam"
import QuizExamResult from "./student_module/pages/QuizExamResult"
import IpeClasses from "./student_module/pages/IpeClasses";
import NeetCrashCourse from "./student_module/pages/NeetCrashCourse";
import NeetCrashCourseExams from "./student_module/pages/NeetCrashCourseExams";
import LiveSessionsCrashCourse from "./student_module/pages/LiveSessionsCrashCourse";
import EamcetPractiseTest from "./student_module/pages/EamcetPractiseTest";
import EamcetShortNotes from "./student_module/pages/EamcetShortNotes";
import EamcetFormulaeSheets from "./student_module/pages/EamcetFormulaeSheets";
import EamcetMockTests from "./student_module/pages/EamcetMockTests";
import EamcetCrashCourse from './student_module/pages/EamcetCrashCourse';
import EamcetCrashCourseExams from './student_module/pages/EamcetCrashCourseExams';
import EamcetCrashCourseLiveClasses from './student_module/pages/EamcetCrashCourseLiveClasses';
import EamcetPreviousPapers from "./student_module/pages/EamcetPreviousPapers";
import EamcetPlans from "./student_module/pages/EamcetPlans";
import EamcetBluePrint from "./student_module/pages/EamcetBluePrint";
import EamcetRankPredictor from "./student_module/pages/EamcetRankPredictor";
import RazorPaySuccess from "./student_module/pages/RazorPaySuccess";
import SingleVideoWatchingAction from './student_module/pages/SingleVideoWatchingAction';
import PreviousPaperExams from './student_module/pages/PreviousPaperExams';
import CollegeRankPredictor from "./student_module/pages/CollegeRankPredictor";
import ShortListPage from "./student_module/pages/ShortListPage";
import FoundationCourse from './student_module/pages/FoundationCourse';
import FoundationCourseClasses from './student_module/pages/FoundationCourseClasses';
import FoundationCourseExams from './student_module/pages/FoundationCourseExams'
import EamcetLongTermCourse from './student_module/pages/EamcetLongTermCourse';
import EamcetLongTermCourseExams from './student_module/pages/EamcetLongTermCourseExams';
import EamcetLongTermCourseLiveClasses from './student_module/pages/EamcetLongTermCourseLiveClasses';
import ReadyToFoundationExams from './student_module/pages/ReadytoFoundationTests'
import ErrorExamPage from './student_module/pages/newErrorExam';
import PerformanceAnalysis from './student_module/pages/performaceAnalysis';
import BookStoreModule from './student_module/pages/BookstoreModule'
import StateShortNotes from "./student_module/pages/StateShortNotes";
import NtaCollegePredictor from './student_module/pages/NtaCollegePredictor'
import NTAShortlistPage from './student_module/pages/NtaShortlistPage'
import StudentTargettingPayment from './student_module/pages/StudentTargettingPayment';
import DayWiseSubscribeOrderSummary from './student_module/pages/DayWiseSubscribeOrderSummary'
import ChapterWiseQuestions from './student_module/pages/ChapterWiseQuestions'
import NeetJeePreviousQuestions from './student_module/pages/Neet_jeePreviousQuestions'
import ChapterWiseLearningEamcet from './student_module/pages/ChapterWiseLearningEamcet'
import IpeQuestionPapers from './student_module/pages/IpePreviousyearsPapers'
import EamcetVideos from './student_module/pages/EamcetVideos';
import ExamWiseErrorAnalysis from './student_module/pages/ExamWiseErrorAnalysis';
import InstituteRevisionMaterials from './student_module/pages/InsituteRevisonMaterials'
import DineshPubStudentLogin from "./student_module/pages/DineshPubStudentLogin";





ReactGA.initialize([{ trackingId: 'UA-168840247-1', gaOptions: { name: 'rizee' } },], { debug: false, alwaysSendToDefaultTracker: false });

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStudentDomain: false,
            currentPath: "",

        };
    }


    componentDidMount() {
        const domain = window.location.hostname;
        const path = window.location.pathname;
        this.setState({
            isStudentDomain: domain === "rizee.in",
            currentPath: path,
        });
    }


    renderLoginComponent = (props) => {
        const { isStudentDomain } = this.state;
        const LoginComponent = isStudentDomain ? StudentLogin : DineshPubStudentLogin;
        return <LoginComponent {...props} />;
    }

    render() {


        return (
            <Router>

                <div className="App">
                    <Switch>
                        <Route exact path="/clogin" component={Login} />

                        <Route
                            exact
                            path="/student/payment-success/:id/:mobile/:name/:token"
                            render={(props) => (
                                <StudentSuccessRegister
                                    {...props}

                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/payment-failure/:id/:planid/:mobile/:coupnid/:name/:token"
                            render={(props) => (
                                <StudentFailureRegister
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/"
                            render={(props) => (
                                <Home {...props} />
                            )}
                        />
                        <Route
                            exact
                            path="/home-exam"
                            render={(props) => (
                                <HomeExam {...props} />
                            )}
                        />
                        <Route exact path='/home/student-profile' render={(props) => (<StudentOrganizationProfile />)} />

                        <Route
                            exact
                            path="/settings/package"
                            render={(props) => (
                                <Package {...props} />
                            )}
                        />
                        <Route
                            exact
                            path="/settings/category"
                            render={(props) => (
                                <Category
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/settings/branches"
                            render={(props) => (
                                <Branches
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/settings/add-section"
                            render={(props) => (
                                <AddSection
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/settings/groups"
                            render={(props) => (
                                <Groups {...props} />
                            )}
                        />


                        <Route
                            exact
                            path="/settings/teachers"
                            render={(props) => (
                                <Teachers
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/settings/students"
                            render={(props) => (
                                <Students
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/settings/organization-setup"
                            render={(props) => (
                                <OrganizationSetup
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/settings/organization-profile"
                            render={(props) => (
                                <OrganizationProfile
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/settings/promote-students"
                            render={(props) => (
                                <PromoteStudent
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/settings/user-creations"
                            render={(props) => (
                                <UserCreations
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/settings/notify-students"
                            render={(props) => (
                                <NotifyStudents
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper"
                            render={(props) => (
                                <CreateQuestionPaper
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/own-question-paper"
                            render={(props) => (
                                <OwnQuestionPaper
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/questions/create-question-paper/own-question-paper/custom"
                            render={(props) => (
                                <OwnQuestionCustom
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/own-question-paper/questionsPreview"
                            render={(props) => (
                                <QuestionsPreview
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/own-question-paper/main-custom"
                            render={(props) => (
                                <OwnQuestionCustompresent
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/exams/test-series/NewNTAPattern"
                            render={(props) => (
                                <MockTestSeriesNewNTAPattern

                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/TestSeriesNta"
                            render={(props) => (
                                <MockTestSeriesNewNTAPattern

                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/own-question-paper/old-exam"
                            render={(props) => (
                                <OwnQuestionOldpresent
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/own-question-paper/college-exam"
                            render={(props) => (
                                <OwnQuestionCollegeExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/own-question-paper/cumulative"
                            render={(props) => (
                                <OwnQuestionCumulative
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/own-question-paper/chapter"
                            render={(props) => (
                                <OwnQuestionChapter
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/own-question-paper/semi-grand"
                            render={(props) => (
                                <OwnQuestionSemiGrand
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/own-question-paper/grand"
                            render={(props) => (
                                <OwnQuestionGrand
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/questions/manage-question-paper"
                            render={(props) => (
                                <ManageQuestionPaper
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/manage-question-paper/question-paper-result"
                            render={(props) => (
                                <QuestionPaperResult
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/settings"
                            render={(props) => (
                                <Settings
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/adaptive-question-paper"
                            render={(props) => (
                                <AdaptiveQuestionPaper
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/error-question"
                            render={(props) => (
                                <ErrorQuestion
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/our-questions"
                            render={(props) => (
                                <OurQuestions
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/our-questions/manage-our-question-paper"
                            render={(props) => (
                                <ManageOurQuestionPaper
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/question-pattern"
                            render={(props) => (
                                <QuestionPattern
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/question-pattern/create-question-pattern"
                            render={(props) => (
                                <CreateQuestionPattern
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/create-custom-question-paper"
                            render={(props) => (
                                <CreateCustomQuestionPaper
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questions/create-question-paper/create-custom-question-paper/create-custom-summery"
                            render={(props) => (
                                <CreateCustomSummery
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/result-analysis"
                            render={(props) => (
                                <ResultAnalysisCollege
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/practice-exam-analysis"
                            render={(props) => (
                                <PracticeExamAnalysisCollege
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/syllabus"
                            render={(props) => (
                                <Syllabus
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/strength-analysis"
                            render={(props) => (
                                <StrengthAnalysisCollege
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/time-analysis"
                            render={(props) => (
                                <TimeAnalysisCollege
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/complexity-analysis"
                            render={(props) => (
                                <ComplexityAnalysisCollege
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/error-analysis"
                            render={(props) => (
                                <ErrorAnalysisCollege
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/questiontype-analysis"
                            render={(props) => (
                                <QuestionTypeAnalysisCollege
                                    {...props}
                                />
                            )}
                        />
                        <Route exact path='/student/notifications/notification' render={(props) => (<Notification {...props} />)} />
                        <Route exact path='/student/notifications' render={(props) => (<AllNotifications {...props} />)} />



                        {/* Student Module */}

                        <Route
                            exact
                            path="/student/ForgotPassword"
                            render={(props) => (
                                <ForgotPassword
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/login"
                            render={this.renderLoginComponent}
                        />

                        <Route
                            exact
                            path="/student/login"
                            render={this.renderLoginComponent}
                        />

                        <Route
                            exact
                            path="/student"
                            render={this.renderLoginComponent}
                        />

                        <Route
                            exact
                            path="/student/logineamcet"
                            render={(props) => (
                                <StudentLoginEamcet
                                    {...props}
                                />
                            )}
                        />



                        <Route
                            exact
                            path="/student/register"
                            render={(props) => (
                                <StudentRegister
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/home"
                            render={(props) => (
                                <StudentHome
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/BluePrint"
                            render={(props) => (
                                <BluePrint
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/liveClasses"
                            render={(props) => (
                                <LiveClasses
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/IPEClasses"
                            render={(props) => (
                                <IpeClasses
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/PreviousPapers"
                            render={(props) => (
                                <PreviousPapers
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/shortNotes"
                            render={(props) => (
                                <ShortNotes
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/learn-practice"
                            render={(props) => (
                                <LearnPractice
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subjects"
                            render={(props) => (
                                <Subjects
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/topics"
                            render={(props) => (
                                <NewTopics {...props} />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/chapter"
                            render={(props) => (
                                <Chapter {...props} />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/chapter-status"
                            render={(props) => (
                                <OverAllChapterStrength
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/practice-exam-history"
                            render={(props) => (
                                <PracticeAndExamHistory
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/exam-history"
                            render={(props) => (
                                <ExamHistory
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/start-error-exam"
                            render={(props) => (
                                <StartErrorExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/start-watching"
                            render={(props) => (
                                <WatchingVideo
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/video-watching"
                            render={(props) => (
                                <SingleVideo
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/start-learning"
                            render={(props) => (
                                <RevisionMaterial
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/short-notes"
                            render={(props) => (
                                <SingleShortNote
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/practice-instructions"
                            render={(props) => (
                                <PracticeInstructions
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/custom-instructions"
                            render={(props) => (
                                <CustomInstructions
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/subject/practice-submitback"
                            render={(props) => (
                                <PracticeSubmitback
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/custom-submitback"
                            render={(props) => (
                                <CustomsubmitBack
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/subject/practice-test"
                            render={(props) => (
                                <PracticeExam
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/student-assist-exam/:exam_session_id/:exam_type"
                            render={(props) => (
                                <StudentAssistentExam
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/view-question-answer"
                            render={(props) => (
                                <ViewQuestionAnswer
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/practice-view-question-answer"
                            render={(props) => (
                                <PracticeViewQuestionAnswer
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/practice-errortest"
                            render={(props) => (
                                <PracticeErrorExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/subject/exam"
                            render={(props) => (
                                <CustomExamTest
                                    {...props}
                                />
                            )}
                        />


                        <Route
                            exact
                            path="/student/subject/series_test"
                            render={(props) => (
                                <SeriesExamTest
                                    {...props}
                                />
                            )}
                        />


                        <Route
                            exact
                            path="/student/exams"
                            render={(props) => (
                                <Exam {...props} />
                            )}
                        />
                        <Route
                            exact
                            path="/student/exams/schedule-exam"
                            render={(props) => (
                                <ScheduleExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/exams/custom-exam"
                            render={(props) => (
                                <CustomExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/exams/previous-paper-exam"
                            render={(props) => (
                                <PreviousPaper
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/exams/custom-previous-paper-exam"
                            render={(props) => (
                                <CustomPreviousPaper
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/exams/adaptive-exam"
                            render={(props) => (
                                <AdaptiveExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/exams/error-exam"
                            render={(props) => (
                                <ErrorExam
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/get-ready-for-exam"
                            render={(props) => (
                                <GetReadyForExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/get-ready-for-exam/chapter-exam"
                            render={(props) => (
                                <ChapterExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/get-ready-for-exam/cumulative-exam"
                            render={(props) => (
                                <CumulativeExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/get-ready-for-exam/semigrand-exam"
                            render={(props) => (
                                <GetReadySemiGrandExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/get-ready-for-exam/grand-exam"
                            render={(props) => (
                                <GetReadyGrandExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/get-ready-for-exam/history"
                            render={(props) => (
                                <GetReadyExamHistory
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/get-ready-for-exam/upcoming-history"
                            render={(props) => (
                                <GetReadyExamUpcomingHistory
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/get-ready-for-exam/get-ready-shortnotes-and-materials"
                            render={(props) => (
                                <GetReadyShortNoteMaterialRevisions
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/get-ready-for-exam/get-ready-shortnotes/single-shortnote"
                            render={(props) => (
                                <GetReadySingleShortNoteMaterialRevisions
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/bookmark"
                            render={(props) => (
                                <Bookmarks
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/bookmark/videos"
                            render={(props) => (
                                <BookmarkVideos
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/bookmark/videos/watch-video"
                            render={(props) => (
                                <SingleBookmarkVideo
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/bookmark/shortnotes-and-materials"
                            render={(props) => (
                                <BookmarkShortnotesMaterials
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/bookmark/shortnotes-and-material/single-shortnote"
                            render={(props) => (
                                <BookmarkSingleShortNoteMaterialRevisions
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/bookmark/practice-questions"
                            render={(props) => (
                                <BookmarkPracticeQuestions
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/bookmark/exam-questions"
                            render={(props) => (
                                <BookmarkExamQuestions
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/profile"
                            render={(props) => (
                                <Profile {...props} />
                            )}
                        />

                        <Route
                            exact
                            path="/student/notes"
                            render={(props) => (
                                <Notes {...props} />
                            )}
                        />
                        <Route
                            exact
                            path="/student/notes/videos"
                            render={(props) => (
                                <NoteVideos
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/notes/videos/watch-video"
                            render={(props) => (
                                <SingleNotesVideo
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/notes/shortnotes-and-materials"
                            render={(props) => (
                                <NotesShortnotesMaterials
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/notes/shortnotes-and-material/single-shortnote"
                            render={(props) => (
                                <NotesSingleShortNoteMaterialRevisions
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/notes/practice-questions"
                            render={(props) => (
                                <NotesPracticeQuestions
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/notes/exam-questions"
                            render={(props) => (
                                <NotesExamQuestions
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/notes/shortnotes-and-material/single-revisions-material"
                            render={(props) => (
                                <NotesSingleMaterialRevisions
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/result-analysis"
                            render={(props) => (
                                <ResultAnalysis
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/practice-exam-analysis"
                            render={(props) => (
                                <PracticeExamAnalysis
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/strength-analysis"
                            render={(props) => (
                                <StrengthAnalysis
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/time-analysis"
                            render={(props) => (
                                <TimeAnalysis
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/complexity-analysis"
                            render={(props) => (
                                <ComplexityAnalysis
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/error-analysis"
                            render={(props) => (
                                <ErrorAnalysis
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/questiontype-analysis"
                            render={(props) => (
                                <QuestionTypeAnalysis
                                    {...props}
                                />
                            )}
                        />


                        {/* <Route exact path='/student/package' render={(props) => (<StudentPackage {...props}/>)} /> */}
                        <Route exact path='/student/package' render={(props) => (<SubPlans {...props} />)} />
                        <Route exact path='/student/package-new' render={(props) => (<StudentPackageNew {...props} />)} />
                        <Route exact path='/student/revision-material-groups' render={(props) => (<RevisionMaterialGroups {...props} />)} />
                        <Route exact path='/student/revision-material-groups/custom-revision-materials' render={(props) => (<CustomRevisionMaterials {...props} />)} />
                        <Route exact path='/student/revision-material-groups/custom-single-revision-material' render={(props) => (<CustomSingleRevisionMaterial  {...props} />)} />
                        {/* <Route path="/student/loading" component={StudentLoading} /> */}
                        <Route exact path='/student/loading' render={(props) => (<StudentLoading {...props} />)} />

                        <Route exact path='/student/feedback' render={(props) => (<StudentFeedBack {...props} />)} />
                        <Route exact path='/student/previous-paper-analysis' render={(props) => (<PreviousPaperAnalysis {...props} />)} />
                        <Route exact path='/student/linkage-chapter-analysis' render={(props) => (<LinkageChapterAnalysis {...props} />)} />
                        <Route exact path='/student/subject/exam-result' render={(props) => (<PracticeTestExamResult {...props} />)} />
                        <Route exact path='/student/subject/mock-exam-result' render={(props) => (<MocktestExamResult {...props} />)} />

                        <Route exact path='/student/subject/practice-result' render={(props) => (<PracticeExamResult {...props} />)} />
                        <Route exact path='/student/subject/single-practice-result' render={(props) => (<SinglePracticeExamResult {...props} />)} />
                        <Route exact path='/student/subject/single-exam-result' render={(props) => (<SinglePracticeTestExamResult {...props} />)} />
                        <Route
                            exact
                            path="/student/studentwise-result/:mobile"
                            render={(props) => (
                                <StudentWiseResult
                                    {...props}
                                />
                            )}
                        />

                        <Route exact path='/student/subject/start-video-watching' render={(props) => (<SingleVideoWatching {...props} />)} />
                        <Route exact path='/student/video-order-summary' render={(props) => (<OrderSummary {...props} />)} />

                        <Route exact path='/student/moock-test-order-summary' render={(props) => (<MockTestOrderSummary {...props} />)} />
                        <Route exact path='/student/subscribe-plans-order-summary' render={(props) => (<SubscribeOrderSummary {...props} />)} />
                        <Route
                            exact
                            path="/student/exams/test-series"
                            render={(props) => (
                                <MockTestSeries
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/exams/test-series-institute"
                            render={(props) => (
                                <MockTestSeriesInstitute
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/rapidRevision/liveClasses"
                            render={(props) => (
                                <RapidRevision
                                    {...props}
                                />
                            )}
                        />
                        <Route exact path='/student/videos/recently-watched' render={(props) => (<VideosRecentlyWatched {...props} />)} />
                        <Route exact path='/student/videos' render={(props) => (<Videos {...props} />)} />
                        <Route exact path='/student/auto-loading/:username/:token/:type' render={(props) => (<AutoStudentLoading {...props} />)} />
                        {/* <Route path="/student/auto-loading/:username/:token/:type" component={AutoStudentLoading} /> */}
                        <Route
                            exact
                            path="/student/action/Bookmark"
                            render={(props) => (
                                <ActionBookmarks
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/RevisionMaterial"
                            render={(props) => (
                                <ActionRevisionMaterialGroups
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/action/ReadyExam"
                            render={(props) => (
                                <ActionReadyExam
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/action/Exam"
                            render={(props) => (
                                <ActionExam
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/action/ResultAnalysis"
                            render={(props) => (
                                <ActionResultAnalysis
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/action/Notes"
                            render={(props) => (
                                <ActionNotes
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/action/Feedback"
                            render={(props) => (
                                <ActionFeedback
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/action/Packages"
                            render={(props) => (
                                <SubPlans
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/action/Profile"
                            render={(props) => (
                                <ActionProfile
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/action/PreviousPapers"
                            render={(props) => (
                                <ActionPreviousPapers
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/action/Forum"
                            render={(props) => (
                                <ActionForum
                                    {...props}
                                />
                            )}
                        />

                        {/* <Route
                                exact
                                path="/student/action/VideosScreen"
                                render={(props) => (
                                    <ActionVideosScreen
                                    {...props}
                                    />
                                )}
                                />*/}

                        <Route
                            exact
                            path="/student/action/TestSeriesScreen"
                            render={(props) => (
                                <MockTestSeries
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/LiveMockTest"
                            render={(props) => (
                                <NotificationMockTestSeries
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/Home"
                            render={(props) => (
                                <ActionStudentHome
                                    {...props}

                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/Linkage"
                            render={(props) => (
                                <ActionLinkageAnalysis
                                    {...props}

                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/learn/:subjectid/:chapterid/:topicid"
                            render={(props) => (
                                <NotificationLearn
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/practice/:subjectid/:chapterid/:topicid"
                            render={(props) => (
                                <NotificationPractise
                                    {...props}
                                />
                            )}
                        />
                        <Route exact path='/student/default-videos' render={(props) => (<DefaultVideos />)} />
                        <Route
                            exact
                            path="/student/action/Learn"
                            render={(props) => (
                                <ActionLearnPractice
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/RapidRevision"
                            render={(props) => (
                                <RapidRevision

                                />
                            )}
                        />

                        {/* <Route
                            exact
                            path="/student/action/TestSeriesNta"
                            render={(props) => (
                                <MockTestSeriesNewNTAPattern

                                />
                            )}
                        /> */}
                        {/* <Route
                            exact
                            path="/student/action/VideosScreen"
                            render={(props) => (
                                <CategoryWiseVideos

                                />
                            )}
                            /> */}
                        <Route
                            exact
                            path="/student/category_wise_videos"
                            render={(props) => (
                                <CategoryWiseVideos
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/category_wise_videos/all-videos"
                            render={(props) => (
                                <CustomAllVideos

                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/category_wise_videos/level-wise-videos"
                            render={(props) => (
                                <LevelWiseVideosBlock

                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/referFriend"
                            render={(props) => (
                                <ReferFriend
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/Neet_Rise_Course"
                            render={(props) => (
                                <RepeatersRevision
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/Neet_Rise_Course/exams"
                            render={(props) => (
                                <NeetRiseCourseExams
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/Neet_Rise_Course/liveClass_sessions"
                            render={(props) => (
                                <LiveSessions
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/Neet_Rise_Course"
                            render={(props) => (
                                <RepeatersRevision

                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/cbse"
                            render={(props) => (
                                <Cbse
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/cbse"
                            render={(props) => (
                                <Cbse
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/exams/quiz_exam"
                            render={(props) => (
                                <QuizExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/quiz"
                            render={(props) => (
                                <QuizExam
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact path='/student/subject/quiz-exam-result'
                            render={(props) => (
                                <QuizExamResult {...props} />)} />

                        <Route
                            exact
                            path="/student/Neet_Crash_Course"
                            render={(props) => (
                                <NeetCrashCourse
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/live_Test_series/exams"
                            render={(props) => (
                                <NeetCrashCourseExams
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/NEETTestSeries"
                            render={(props) => (
                                <NeetCrashCourseExams
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/Neet_Crash_Course/liveClass_sessions"
                            render={(props) => (
                                <LiveSessionsCrashCourse
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/Neet_Crash_Course"
                            render={(props) => (
                                <NeetCrashCourse

                                />
                            )}
                        />
                        <Route
                            exact path='/student/practise_test'
                            render={(props) => (
                                <EamcetPractiseTest {...props} />)}
                        />
                        <Route
                            exact path='/student/action/ST_Practice'
                            render={(props) => (
                                <EamcetPractiseTest {...props} />)}
                        />
                        <Route
                            exact path='/student/StateShortNotes'
                            render={(props) => (
                                <EamcetShortNotes {...props} />)}
                        />
                        <Route
                            exact path='/student/action/ST_ShortNotes'
                            render={(props) => (
                                <EamcetShortNotes {...props} />)}
                        />
                        <Route
                            exact path='/student/FormulaeSheets'
                            render={(props) => (
                                <EamcetFormulaeSheets {...props} />)}
                        />
                        <Route
                            exact path='/student/action/ST_RevisionMaterial'
                            render={(props) => (
                                <EamcetFormulaeSheets {...props} />)}
                        />
                        <Route
                            exact path='/student/Mocktests'
                            render={(props) => (
                                <EamcetMockTests {...props} />)}
                        />
                        <Route
                            exact
                            path="/student/eamcet-crashcourse/home"
                            render={(props) => (
                                <EamcetCrashCourse
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact path='/student/StatePreviousPapers'
                            render={(props) => (
                                <EamcetPreviousPapers {...props} />)}
                        />
                        <Route
                            exact path='/student/action/ST_PreviousTests'
                            render={(props) => (
                                <EamcetPreviousPapers {...props} />)}
                        />
                        <Route
                            exact path='/student/action/ST_BluePrint'
                            render={(props) => (
                                <EamcetBluePrint {...props} />)}
                        />
                        <Route
                            exact path='/student/StateBluePrint'
                            render={(props) => (
                                <EamcetBluePrint {...props} />)}
                        />
                        <Route
                            exact
                            path="/student/eamcet-crashcourse/liveclasses"
                            render={(props) => (
                                <EamcetCrashCourseLiveClasses
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/eamcet-crashcourse/exams"
                            render={(props) => (
                                <EamcetCrashCourseExams
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact path='/student/plans_section'
                            render={(props) => (
                                <EamcetPlans {...props} />)}
                        />

                        <Route
                            exact
                            path="/student/eamcet-rankPredictor"
                            render={(props) => (
                                <EamcetRankPredictor
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/payment-success"
                            render={(props) => (
                                <RazorPaySuccess
                                    {...props}

                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/VideosScreen"
                            render={(props) => (
                                <CategoryWiseVideos
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/Blog"
                            render={(props) => (
                                <StudentSingleBlog
                                    {...props}

                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/ST_Blog"
                            render={(props) => (
                                <StudentSingleBlog
                                    {...props}

                                />
                            )}
                        />
                        <Route
                            exact path='/student/action/ST_MockTests'
                            render={(props) => (
                                <EamcetMockTests {...props} />)}
                        />

                        <Route
                            exact path='/student/action/ST_Subscription'
                            render={(props) => (
                                <EamcetPlans {...props} />)}
                        />
                        <Route
                            exact
                            path="/student/college-rank-predictor"
                            render={(props) => (
                                <CollegeRankPredictor
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/action/college_branch_predi"
                            render={(props) => (
                                <CollegeRankPredictor
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/college-rank-predictor/short-list-page"
                            render={(props) => (
                                <ShortListPage
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/foundation-course/home"
                            render={(props) => (
                                <FoundationCourse
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/FoundationCourse"
                            render={(props) => (
                                <FoundationCourse
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/foundation-course-classes"
                            render={(props) => (
                                <FoundationCourseClasses
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/foundation-course-exams"
                            render={(props) => (
                                <FoundationCourseExams
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/eamcet-longterm-course/home"
                            render={(props) => (
                                <EamcetLongTermCourse
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/action/EAMCET_LongTerm"
                            render={(props) => (
                                <EamcetLongTermCourse
                                    {...props}
                                />
                            )}
                        />


                        <Route
                            exact
                            path="/student/eamcet-longterm-course/liveclasses"
                            render={(props) => (
                                <EamcetLongTermCourseLiveClasses
                                    {...props}
                                />
                            )}
                        />


                        <Route
                            exact
                            path="/student/eamcet-longterm-course/exams"
                            render={(props) => (
                                <EamcetLongTermCourseExams
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/ready-to-foundation-tests"
                            render={(props) => (
                                <ReadyToFoundationExams
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/Free_FoundationTests"
                            render={(props) => (
                                <ReadyToFoundationExams
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/short-notes"
                            render={(props) => (
                                <StateShortNotes
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/error_exam"
                            render={(props) => (
                                <ErrorExamPage
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/ST_Error_Exam"
                            render={(props) => (
                                <ErrorExamPage
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/performance-analysis"
                            render={(props) => (
                                <PerformanceAnalysis
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/action/ST_Performance"
                            render={(props) => (
                                <PerformanceAnalysis
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/Rizee-partner-offers"
                            render={(props) => (
                                <BookStoreModule
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/NTA-College_predictor"
                            render={(props) => (
                                <NtaCollegePredictor
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/NTA-College_predictor-shortlist"
                            render={(props) => (
                                <NTAShortlistPage
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/action/CS_packages"
                            render={(props) => (
                                <StudentTargettingPayment
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/payments-new-summary"
                            render={(props) => (
                                <DayWiseSubscribeOrderSummary
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/Chapter-wise-questions"
                            render={(props) => (
                                <ChapterWiseQuestions
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/previous-paper-questions"
                            render={(props) => (
                                <NeetJeePreviousQuestions
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/chapter-wise-learning"
                            render={(props) => (
                                <ChapterWiseLearningEamcet
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/student/Ipe-question-papers"
                            render={(props) => (
                                <IpeQuestionPapers
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/Eamcet-Video-classes"
                            render={(props) => (
                                <EamcetVideos
                                    {...props}
                                />
                            )}
                        />


                        <Route
                            exact
                            path="/student/exam/error-analysis"
                            render={(props) => (
                                <ExamWiseErrorAnalysis
                                    {...props}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/student/institute-revison-materials"
                            render={(props) => (
                                <InstituteRevisionMaterials
                                    {...props}
                                />
                            )}
                        />



                        {/*<Route
                            exact
                            path="/student/SubPlans"
                            render={(props) => (
                                <SubPlans
                                {...props}
                                />
                                )}
                            />*/}

                        <Route exact path='/student/student-blog' render={(props) => (<StudentBlog {...props} />)} />
                        <Route exact path='/student/student-blog/student-blog-view' render={(props) => (<StudentSingleBlog {...props} />)} />
                        <Route exact path='/student/subject/college-single-exam-result/:sessionid/:mobile/:examtype' render={(props) => (<CollegeSinglePracticeTestExamResult  {...props} />)} />

                        <Route exact path='/student/action/PlayVideo/:VIDEOID/:SOURCE/:TITLE' render={(props) => (<YoutubeVideos  {...props} />)} />
                        <Route exact path='/student/featuredvideos' render={(props) => (<FeaturedVideos  {...props} />)} />
                        <Route exact path='/student/createpreviouspaperexam' render={(props) => (<CreatePreviousPaper  {...props} />)} />
                        <Route exact path='/student/action/Jee-mains2021' render={(props) => (<CreatePreviousPaper  {...props} />)} />
                        <Route exact path='student/action/FeatureVideos' render={(props) => (<FeaturedVideos  {...props} />)} />
                        <Route exact path='/student/previouspaperexams' render={(props) => (<PreviousPaperExams  {...props} />)} />
                        <Route exact path='/student/action/PreviousYearMockTest' render={(props) => (<PreviousPaperExams  {...props} />)} />

                        <Route
                            exact
                            path="/student/:id"
                            render={(props) => (
                                <StudentLoginIndividual
                                    {...props}
                                />
                            )}
                        />

                        <Route path="/student/*" component={StudentPageNotFound} />

                        <Route path="*" component={PageNotFound} />
                    </Switch>
                </div>

            </Router>
        );
    }
}

export default App;
