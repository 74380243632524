import React, { Component } from 'react';
import * as Cookies from "es-cookie";
import { Row, Tabs, Tab, Card, Image, Button, Col, Table,Media,Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PhysicsShortNotes from './physicsshortnotes';
import ChemistryShortNotes from './chemistryshortnotes';
import MathsShortNotes from './mathsshortnotes';
import BotonyShortNotes from './botanyshortnotes';
import ZoologyShortNotes from './zoologyshortnotes';
import './shortnotes.scss';
import physicslatest from "../../../images/images1/Physics_icon.png"
import botony from "../../../images/images1/Botony_icon.png";
import Chemistryicon from "../../../images/images1/Chemistry_icon.png";
import Maths from "../../../images/images1/Maths_icon.png";

import PhysicsDetailedNotes from '../detailed_notes/physicsdetailednotes';
import ChemistryDetailedNotes from '../detailed_notes/chemistrydetailednotes';
import MathsDetailedNotes from '../detailed_notes/mathsdetailednotes';
import BotonyDetailedNotes from '../detailed_notes/botanydetailednotes';
import ZoologyDetailedNotes from '../detailed_notes/zoologydetailednotes';
class ShortNotesMatter extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            key: "Physics",
            keyValue: "Short",
            

        }
    }
    handleSelect = (val) => {
        console.log(val)
        this.setState({
            key: val
        });
    }
    handleSelectShortOrDetail = (val) => {
        console.log(val)
        this.setState({
            keyValue: val
        });
    }
    render() {
        return (
            <Container fluid>
                <Row>
                    <Col className="short_notes_block">
                        <div className="flex-blocks">
                           <div className="activeClsApply_Ph">
                                <Media className={this.state.key === "Physics" ? "active remingSubCls" : "remingSubCls"}  as={Link} onClick={() => this.handleSelect("Physics")}>
                                            <Image
                                                className="mr-4"
                                                src={physicslatest}
                                                alt="Physics"
                                            />
                                            <Media.Body>
                                                <h5 className="mb-0 mt-3">Physics</h5>
                                                
                                            </Media.Body>
                                    </Media>
                           </div>
                        
                            <div className="activeClsApply_Ch">
                                <Media className={this.state.key === "Chemistry" ? "active remingSubCls widthalignChCls" : "remingSubCls widthalignChCls"}  as={Link} onClick={() => this.handleSelect("Chemistry")}>
                                        <Image
                                            className="mr-4"
                                            src={Chemistryicon}
                                            alt="Chemistry"
                                        />
                                        <Media.Body>
                                            <h5 className="mb-0 mt-3">Chemistry</h5>
                                            
                                        </Media.Body>
                                </Media>
                            </div>
                            {
                            Cookies.get('examid') == 3 || Cookies.get('examid') == 6 ? (
                                <div className="activeClsApply_Mt">
                                 <Media className={this.state.key === "Maths" ? "active remingSubCls" : "remingSubCls"} as={Link} onClick={() => this.handleSelect("Maths")}>
                                            <Image
                                                className="mr-4"
                                                src={Maths}
                                                alt="Maths"
                                            />
                                            <Media.Body>
                                                <h5 className="mb-0 mt-3">Maths</h5>
                                                
                                            </Media.Body>
                                    </Media>
                            </div>
                            ) : ("")
                        }
                        
                        <div className="activeClsApply_Zo">
                        {
                            
                            Cookies.get('examid') == 3 || Cookies.get('examid') == 6 ? ("") : (
                                <Media className={this.state.key === "Zoology" ? "active remingSubCls" : "remingSubCls"} as={Link} onClick={() => this.handleSelect("Zoology")}>
                                <Image
                                    className="mr-4"
                                    src={botony}
                                    alt="Zoology"
                                />
                                <Media.Body>
                                    <h5 className="mb-0 mt-3">Zoology</h5>
                                    
                                </Media.Body>
                            </Media>
                            )}
                        </div>
                        
                            <div className="activeClsApply_Zo">
                            {
                                Cookies.get('examid') == 3 || Cookies.get('examid') == 6 ? ("") : (
                                    <Media className={this.state.key === "Botany" ? "active remingSubCls" : "remingSubCls"} as={Link} onClick={() => this.handleSelect("Botany")}>
                                    <Image
                                        className="mr-4"
                                        src={botony}
                                        alt="Botony"
                                    />
                                    <Media.Body>
                                        <h5 className="mb-0 mt-3">Botany</h5>
                                        
                                    </Media.Body>
                                </Media>
                            )
                        }
                            </div>
                           
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <ul className="list-inline short_notes_li mt-4">
                            <li className={`short ${this.state.keyValue === "Short" ? "active" : "" }`} onClick={() => this.handleSelectShortOrDetail("Short")}>Short Notes</li>
                            <li className={`detail ${this.state.keyValue==="Detail" ? "active" : ""}`} onClick={() => this.handleSelectShortOrDetail("Detail")}>Detailed Notes</li>
                        </ul>
                    </Col>

                </Row>
               
                    {<>
                    {
                        this.state.key === "Physics" && this.state.keyValue === "Short"
                            ? <PhysicsShortNotes />
                            : this.state.key === "Maths" && this.state.keyValue === "Short"
                                ? <MathsShortNotes />
                                : this.state.key === "Chemistry" && this.state.keyValue === "Short"
                                    ? <ChemistryShortNotes />
                                    : this.state.key === "Botany" && this.state.keyValue === "Short"
                                        ? <BotonyShortNotes />
                                        : this.state.key === "Zoology" && this.state.keyValue === "Short"
                                            ? <ZoologyShortNotes />
                                            : this.state.key === "Physics" && this.state.keyValue === "Detail"
                                                ? <PhysicsDetailedNotes />
                                                : this.state.key === "Maths" && this.state.keyValue === "Detail"
                                                    ? <MathsDetailedNotes />
                                                    : this.state.key === "Chemistry" && this.state.keyValue === "Detail"
                                                        ? <ChemistryDetailedNotes />
                                                        : this.state.key === "Botany" && this.state.keyValue === "Detail"
                                                            ? <BotonyDetailedNotes />
                                                            : this.state.key === "Zoology" && this.state.keyValue === "Detail"
                                                                ? <ZoologyDetailedNotes />
                                                                : null
                        }
                    </>
                    }

                
            </Container>
        )
    }

}
export default ShortNotesMatter;