import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import EamcetLongTermCourseExamsSection from '../components/Eamcet_LongTerm_Course/eamcet_exams/EamcetLongTermCourseExamsSection.jsx';
import * as Cookies from "es-cookie";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import ScrollTopNavbar from '../components/navbars/ScrollTopNavbar'

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const FETCH_ISSTUDENTUSERVALID = gql` 
query($mobile: String) {
    isStudentUserValid(mobile: $mobile){
        estatus
        current_plan_id
        expiry_date
        
        user_access_restictions
        module_restrictions
        chapter_ids
        previous_sets
     }
}
`;

const FETCH_EAMCET_CRASH_COURSE = gql`
    query($id : Int!){
        getGlobalType(id : $id){
            global_name
            comments
            global_data{
                global_type_id
                date_time
                value
            }
        }
    }
`;


const FETCH_GETSERIES = gql` 
query($mobile: String,$exam_group_id: Int) {
    getGroupBasedExams(mobile: $mobile,exam_group_id: $exam_group_id){
        id
        exam_name
        exam_type
        exam_model_id
        exam_session_id
        sub_exam_type
        start_time
        end_time
        is_completed
        title
        short_name
        amount
        speed
        accuracy
        correct_marks
        negative_marks
        total_marks
        is_purchased
        exam_started
        exam_expired
        syllabus
        exam_duration
        uid
        is_scheduled
        is_registered
        syllabus_desc
        is_started
        is_started_mobile
        class_id
        exam_class_id
        subject_id
        enabled_XI
        enabled_XII
        class_session_id
}
}
`;
const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

const GET_TIME = gql`
query($mobile :String!){
    getTime(mobile:$mobile){
        timestamp
    }
}
`

class EamcetCrashCourseExams extends Component {
    constructor(props) {
        super(props)

        this.state = {
            headerBottomImg: {
                type: "eamcet_view",
                title: "LONG TERM COURSE"
            },
            toggled: isMobile == true && Cookies.get("institution_id") == 30 ? "wrapper" : Cookies.get("toggle"),
            submitError: ""

        }
    }
    componentDidMount = () => {
        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Eamcet_Crash_Course_Exams;
        ReactGA.pageview('/student/eamcet-longterm-course/exams', ["rizee"], title);
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 217,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };



    menuToggler = () => {
        const toggled = this.state.toggled;
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    render() {
        const isStudentUserValid = this.props.isStudentUserValid;
        const loading2 = isStudentUserValid.loading;
        const error2 = isStudentUserValid.error;

        const getGroupBasedExams = this.props.getGroupBasedExams
        const loading1 = getGroupBasedExams.loading
        const error1 = getGroupBasedExams.error

        const getTime = this.props.getTime
        const loading5 = getTime.loading
        const error5 = getTime.error



        const getGlobalType = this.props.getGlobalType;
        const loading4 = getGlobalType.loading;
        const error4 = getGlobalType.error;

        if (error2 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        if (error4 !== undefined) {
            alert("Server Error. " + error4.message);
            return null;
        }
        if (error5 !== undefined) {
            alert("Server Error. " + error5.message);
            return null;
        }


        console.log("getUserExplored", isStudentUserValid.isStudentUserValid);
        if (isStudentUserValid.isStudentUserValid != undefined) {
            if (isStudentUserValid.isStudentUserValid.estatus == 0) {
                Cookies.remove("token");
                Cookies.remove("username");
                Cookies.remove("refreshtoken");
                Cookies.remove("email");
                Cookies.remove("id");
                Cookies.remove("institutionid");
                Cookies.remove("userlevel");
                Cookies.remove("name");
                this.props.history.push("/student/login");
            }
            localStorage.removeItem('isStudentUserValid');
            localStorage.setItem(
                "isStudentUserValid",
                JSON.stringify(isStudentUserValid.isStudentUserValid)
            );
        };


        return (
            <div className={this.state.toggled}>
                <div className="student main-wrapper">
                    <div className="student header-area exam_pattern_topnavbar">
                        <ScrollTopNavbar
                            headerBottom={this.state.headerBottomImg}
                            onClick={() => this.menuToggler()} />
                    </div>
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper-pad0">
                        {
                            loading1 == true || loading2 == true || loading4 == true || loading5 == true ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>) : (
                                    <EamcetLongTermCourseExamsSection
                                        isStudentUserValid={isStudentUserValid.isStudentUserValid}
                                        getGroupBasedExams={getGroupBasedExams.getGroupBasedExams}
                                        getGlobalType={getGlobalType.getGlobalType}
                                        getTime={getTime.getTime}
                                    />
                                )
                        }
                    </div>

                </div>
            </div>
        )
    }
}


export default withRouter(compose(
    graphql(FETCH_ISSTUDENTUSERVALID,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: 'network-only'
            }), name: "isStudentUserValid"
        }),
    graphql(FETCH_GETSERIES,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    exam_group_id: Cookies.get("examid") == "3" ? 20 : Cookies.get("examid") == "6" ? 21 : Cookies.get("examid") == "7" ? 22 : 23
                },
                fetchPolicy: 'network-only'
            }), name: "getGroupBasedExams"
        }),

    graphql(FETCH_EAMCET_CRASH_COURSE,
        {
            options: props => ({
                variables: {
                    id: 1
                }
                ,
            }), name: "getGlobalType"
        }),
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
    graphql(GET_TIME,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile")
                }
                ,
                fetchPolicy: 'network-only'
            }), name: "getTime"
        }),
)(EamcetCrashCourseExams));
