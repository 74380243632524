import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import PreviousPaperSection from '../components/exams/previous_papers/PreviousPaperSection';
import * as Cookies from "es-cookie";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import PreloaderTwo from '../components/preloader/PreloaderTwo';

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;


class PreviousPaper extends Component {
    constructor(props){
        super(props)
        this.state={
            submitError:"",
        }
    }

    componentDidMount = () => {
        this.addStudentAnalytics();

        const title = GoogleAnalyticsArray[0].Previous_Paper_Exam;
        ReactGA.pageview('/student/exams/previous-paper-exam',["rizee"], title);
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 60,
            source: 2
        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    render() {
        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");

        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars onClick={() => this.menuToggler()} />
                </div>
                <div className="main-wrapper">

                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper">
                        <Container>

                            <PreviousPaperSection
                                type="main"
                                studentGlobals={studentGlobals}
                            />
                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(
        graphql(UPDATE_STUDENT_ANALYTICS, {
            name: "submitpt"
        })
)(PreviousPaper));