import React, { Component } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import BreadcrumbsSection from "../../breadcrumbs/BreadcrumbsSection";
import DataTableWithOutSearch from "../../datatables/DataTableWithOutSearch";
import TeacherModal from "./TeacherModal";
import TeacherModalEdit from "./TeacherModalEdit";
import { Data, Columns, defaultSorted } from "./TeacherTableData";

import "./_teachers.scss";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as Cookies from "es-cookie";
import * as compose from "lodash.flowright";
import { withRouter } from "react-router-dom";

const DELETE_TEACHER = gql`
  mutation($teacherId: ID) {
    deleteTeacher(teacherId: $teacherId)
  }
`;
const EDIT_TEACHER = gql`
  mutation($params: EditTeacherInput) {
    editTeacher(params: $params)
  }
`;

const FETCH_GLOBALS = gql`
  query($institution_id: Int!) {
    globals(institution_id: $institution_id) {
      subjects {
        id
        subject
        chapters {
          id
          chapter
          class
          topics {
            id
            topic
          }
        }
      }
      globalBranches {
        id
        branch_name
      }
      classes {
        id
        class
      }
      globalSections {
        id
        section_name
        branch_id
        category_id
        package_id
      }
    }
  }
`;

const FETCH_TEACHERS = gql`
  query($institution_id: Int!) {
    getTeachers(institution_id: $institution_id) {
      id
      teacher_name
      contact_no
      subject_id
      branch_id
      section_ids
      class_ids
    }
  }
`;

class TeachersSection extends Component {
  constructor(props) {
    super(props);
    console.log("propsdatas", props.globals.globals);
    this.state = {
      currentStep: 1,
      teacher_name: "",
      contact_no: "",
      subject: "",
      branch: "",
      section: "",
      class: "",
      teacherid: "",
      submitError: "",
      globals: "",
      formErrors: {
        teacher_name: "",
        contact_no: "",
        subject: "",
        branch: "",
        class: "",
        section: "",
      },
      teacher_nameValid: true,
      contact_noValid: true,
      subjectValid: true,
      branchValid: true,
      formValid: true,
      BranchesSection: {
        Title: "Teachers",
        btnName: "Add Teacher",
      },
      tableHeaderData: {
        Title: "Teachers",
      },
      modalShow: false,
      modalShow1: false,
      status: 1,
    };
  }

  tableData(teachers) {
    console.log("tableData", this.props.globals.globals);
    const teacher_data = Array();
    // console.log("teachers_u",teachers)
    for (let i = 0; i < teachers.length; i++) {
      const subjects = this.props.globals.globals.subjects.find(
        (e) => e.id == teachers[i].subject_id
      );
      let sub_id;
      let subject1 = "";
      if (subjects != undefined) {
        subject1 = subjects.subject;
        sub_id = subjects.id;
      }

      const branchs = this.props.globals.globals.globalBranches.find(
        (e) => e.id == teachers[i].branch_id
      );
      let b_id = "";
      let branch1 = "";
      if (branchs != undefined) {
        branch1 = branchs.branch_name;
        b_id = branchs.id;
      }

      let class4 = "";
      let cla_id = "";
      let classes = Array();
      let classes1 = Array();
      let class1 = teachers[i].class_ids.toString().split(",");
      for (let j = 0; j < class1.length; j++) {
        let class2 = class1[j];
        const class3 = this.props.globals.globals.classes.find(
          (e) => e.id == class2
        );
        if (class3 != undefined) {
          class4 = class3.class;
          cla_id = class3.id;
        }
        classes.push(class4);
        classes1.push(cla_id);
      }
      let classdata = classes.toString();

      let section4 = "";
      let sec_id = "";
      let sections = Array();
      let sections1 = Array();
      let section1 = teachers[i].section_ids.toString().split(",");
      for (let l = 0; l < section1.length; l++) {
        let section2 = section1[l];
        const section3 = this.props.globals.globals.globalSections.find(
          (e) => e.id == section2
        );
        if (section3 != undefined) {
          section4 = section3.section_name;
          sec_id = section3.id;
        }
        sections.push(section4);
        sections1.push(sec_id);
      }
      let sectiondata = sections.toString();

      teacher_data.push({
        id: teachers[i].id,
        teacher_name: teachers[i].teacher_name,
        contact_no: teachers[i].contact_no,
        subject: subject1,
        branch: branch1,
        section: sectiondata,
        class: classdata,
        subject_id: sub_id,
        branch_id: b_id,
        class_ids: classes1,
        section_ids: sections1,
      });
    }
    return teacher_data;
  }

  getDefaultValuesSection(vals, prop) {
    console.log("getDefaultValues", prop);
    let chars = vals;

    let sections = Array();
    if (chars != undefined) {
      console.log("chars.length", chars);
      for (let i = 0; i < chars.length; i++) {
        const sectionval = chars[i];
        let found = prop.globalSections.find((a) => a.id == sectionval);
        console.log("found", found);
        if (found != undefined) {
          sections.push({
            label: found.section_name,
            value: found.section_name,
            id: found.id,
          });
        }
      }
    }

    return sections;
  }

  getValues = (vals) => {
    // console.log("Sections",vals)
    let sections = Array();
    for (let i = 0; i < vals.length; i++) {
      if (this.state.branch == vals[i].branch_id) {
        const sectionval = vals[i];
        sections.push({
          label: sectionval.section_name,
          value: sectionval.section_name,
          id: sectionval.id,
        });
      }
    }
    return sections;
  }
  handleSelectInputChangeSection = (e) => {
    let sections = Array();
    if (e != undefined) {
      for (let i = 0; i < e.length; i++) {
        const sectionval = e[i];
        sections.push(sectionval.id);
      }
      this.setState({
        section: sections,
      });
    }
  };
  getDefaultValues(vals, prop) {
    let chars = vals;

    let classes = Array();
    if (chars != undefined) {
      // console.log("chars.length", chars);
      for (let i = 0; i < chars.length; i++) {
        const classval = chars[i];
        let found = prop.classes.find((a) => a.id == classval);
        console.log("found", found);
        if (found != undefined) {
          classes.push({
            label: found.class,
            value: found.class,
            id: found.id,
          });
        }
      }
    }

    return classes;
  }

  getClassValues(vals) {
    // console.log("Classes",vals);
    let classes = Array();
    for (let i = 0; i < vals.length; i++) {
      const classval = vals[i];
      classes.push({
        label: classval.class,
        value: classval.class,
        id: classval.id,
      });
    }

    return classes;
  }
  handleSelectInputChange = (e) => {
    let classes = Array();
    if (e != undefined) {
      for (let i = 0; i < e.length; i++) {
        const classval = e[i];
        classes.push(classval.id);
      }
      this.setState({
        class: classes,
      });
    }
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted");
    console.log("Cookies2", Cookies.get("username"));
    console.log("institutionid2", Cookies.get("institutionid"));
    console.log("Data", this.state);

    if (this.state.formValid) {
      let editTeacherobj = {
        id: this.state.teacherid,
        teacher_name: this.state.teacher_name,
        contact_no: this.state.contact_no,
        subject_id: parseInt(this.state.subject),
        branch_id: parseInt(this.state.branch),
        section_ids: this.state.section,
        class_ids: this.state.class.toString(),
        institution_id: parseInt(Cookies.get("institutionid")),
        username: Cookies.get("username"),
      };
      this.editTeacher(editTeacherobj).catch((error) => {
        console.log("catch if error");
        console.log(error);
        this.setState({
          submitError: error.graphQLErrors.map((x) => x.message),
        });
        console.error(
          "ERR =>",
          error.graphQLErrors.map((x) => x.message)
        );
      });
    } else {
      this.setState({ submitError: "Please fill all the values to proceed" });
    }
  };

  editTeacher = async (params) => {
    await this.props.editTeacher({
      variables: {
        params,
      },
      update: (store, { data }) => {
        let data1 = store.readQuery({
          query: FETCH_TEACHERS,
          variables: {
            institution_id: parseInt(Cookies.get("institutionid")),
          },
        });

        const teacher = data1.getTeachers.findIndex(
          (th) => th.id == this.state.teacherid
        );

        console.log("data1", data1, teacher);

        data1.getTeachers[teacher].teacher_name = this.state.teacher_name;
        data1.getTeachers[teacher].contact_no = this.state.contact_no;
        data1.getTeachers[teacher].subject_id = this.state.subject;
        data1.getTeachers[teacher].branch_id = this.state.branch;
        data1.getTeachers[teacher].class_ids = this.state.class;
        data1.getTeachers[teacher].section_ids = this.state.section;

        try {
          store.writeQuery({
            query: FETCH_TEACHERS,
            variables: {
              institution_id: parseInt(Cookies.get("institutionid")),
            },
            data: data1,
          });
        } catch (e) {
          console.log("Exception", e);
        }

        console.log("editteacher", data);

        if (data.editTeacher) {
          this.setState({
            currentStep: 5,
            teacher_name: "",
            contact_no: "",
            subject: "",
            branch: "",
            section: "",
            class: "",
            teacherid: "",
            submitError: "",
            formErrors: {
              teacher_name: "",
              contact_no: "",
              subject: "",
              branch: "",
              class: "",
              section: "",
            },
            teacher_nameValid: true,
            contact_noValid: true,
            subjectValid: true,
            branchValid: true,
            formValid: true,
          });
          setTimeout(() => {
            this.SetpageLoad();
          }, 1500);
        }
      },
    });
  };

  SetpageLoad = () => {
    //console.log("setTimeout");
    this.setState({
      currentStep: 1,
      modalShow1: false,
    });
    //this.props.onHide()
  };

  handleInputChange = (e) => {
    console.log("e.target.name", e.target.name);
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let teacher_nameValid = this.state.teacher_nameValid;
    let contact_noValid = this.state.contact_noValid;
    let subjectValid = this.state.subjectValid;
    let branchValid = this.state.branchValid;

    switch (fieldName) {
      case "teacher_name":
        if (value.length == "") {
          teacher_nameValid = false;
          fieldValidationErrors.teacher_name = "Teacher Name Cannot Be Empty";
        } else {
          teacher_nameValid = true;
          fieldValidationErrors.teacher_name = "";
        }

        break;

      // case "contact_no":
      //   if (value.length > 12) {
      //     contact_noValid = false;
      //     fieldValidationErrors.contact_no = "Mobile No. Maximum 12 digits";
      //   } else if (value.length < 8) {
      //     contact_noValid = false;
      //     fieldValidationErrors.contact_no = "Mobile No. Minimum 8 digits";
      //   } else if (value.length == "") {
      //     contact_noValid = false;
      //     fieldValidationErrors.contact_no = "Mobile No. Cannot Be Empty";
      //   } else {
      //     contact_noValid = true;
      //     fieldValidationErrors.contact_no = "";
      //   }

      //   break;
      case "contact_no":
        var pattern = new RegExp("^[6-9][0-9]{9}$");

        if (value.length == "") {
          contact_noValid = false;
          fieldValidationErrors.contact_no = "contact No. Cannot Be Empty";
        } else if (!pattern.test(value)) {
          contact_noValid = false;
          fieldValidationErrors.contact_no = "Invalid contact No.";
        } else {
          contact_noValid = true;
          fieldValidationErrors.contact_no = "";
        }

        break;

      case "subject":
        if (value.length == "") {
          subjectValid = false;
          fieldValidationErrors.subject = "Exam Type cannot be empty";
        } else {
          subjectValid = true;
          fieldValidationErrors.subject = "";
        }

        break;

      case "branch":
        if (value.length == "") {
          branchValid = false;
          fieldValidationErrors.branch = "Branch id cannot be empty";
        } else {
          branchValid = true;
          fieldValidationErrors.branch = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        teacher_nameValid: teacher_nameValid,
        contact_noValid: contact_noValid,
        subjectValid: subjectValid,
        branchValid: branchValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.teacher_nameValid ||
        this.state.contact_noValid ||
        this.state.subjectValid ||
        this.state.branchValid,
    });
    if (this.state.formValid) {
      this.setState({ submitError: "" }, () => { });
    }
  }

  handleEditFunction = (e, cell, row, rowIndex, formatExtraData) => {
    console.log("rowIndex", rowIndex);
    this.setState({
      modalShow1: true,
      teacherid: rowIndex.id,
      teacher_name: rowIndex.teacher_name,
      contact_no: rowIndex.contact_no,
      subject: rowIndex.subject_id,
      branch: rowIndex.branch_id,
      class: rowIndex.class_ids,
      section: rowIndex.section_ids,
    });
  };

  handleDelete = async (e, cell, row, rowIndex, formatExtraData) => {
    await this.props.handleDelete({
      variables: {
        teacherId: rowIndex.id,
      },
      update: (store, { data }) => {
        console.log("data", data);
        const data1 = store.readQuery({
          query: FETCH_TEACHERS,
          variables: {
            institution_id: parseInt(Cookies.get("institutionid")),
          },
        });
        console.log("data1s", data1.getTeachers);
        console.log("rowIndex.id", rowIndex.id);
        data1.getTeachers = data1.getTeachers.filter(
          (x) => x.id != rowIndex.id
        );
        console.log("data2s", data1.getTeachers);
        try {
          store.writeQuery({
            query: FETCH_TEACHERS,
            variables: {
              institution_id: parseInt(Cookies.get("institutionid")),
            },
            data: data1,
          });
        } catch (e) {
          console.log("Exception", e);
        }

        const data4 = store.readQuery({
          query: FETCH_TEACHERS,
          variables: {
            institution_id: parseInt(Cookies.get("institutionid")),
          },
        });
        data1.getTeachers = data4;
        console.log("data4s", data4);
        if (data.deleteTeacher) {
          this.setState({
            status: 2,
          });
          setTimeout(() => {
            this.DeleteSetpageLoad();
          }, 1000);
        }
      },
    });
  };
  DeleteSetpageLoad = () => {
    console.log("setTimeout");
    this.setState({ status: 1 });
  };

  actionsFormatter2(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="actions-buttons d-flex justify-content-center align-items-top">
        <Button variant="link" name="delete" className="text-danger">
          <i className="far fa-trash-alt" />
        </Button>
      </div>
    );
  }
  actionsFormatter1() {
    return (
      <div className="actions-buttons d-flex justify-content-center align-items-top">
        <Button variant="link" name="edit" className="text-theme">
          <i className="far fa-edit" />
        </Button>
      </div>
    );
  }

  actionsFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="actions-buttons d-flex justify-content-center align-items-top">
        <Button variant="link" name="view" className="text-theme">
          <i className="far fa-eye" />
        </Button>
      </div>
    );
  }

  articlecolumns = [
    {
      dataField: "teacher_name",
      text: "Teacher Name",
      sort: true,
    },
    {
      dataField: "class",
      text: "Classes",
      sort: true,
    },
    {
      dataField: "subject",
      text: "Subjects",
      sort: true,
    },
    {
      dataField: "branch",
      text: "Branches",
      sort: true,
    },
    {
      dataField: "section",
      text: "Sections",
      sort: true,
    },

    {
      dataField: "contact_no",
      text: "Phone NO",
      sort: true,
    },
    {
      dataField: "actions",
      formatter: this.actionsFormatter,
      headerAlign: "center",
    },
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
      formatter: this.actionsFormatter1,
      headerAttrs: { width: 50 },
      attrs: { width: 50, className: "EditRow" },
      headerAlign: "center",
      events: {
        onClick: this.handleEditFunction,
      },
    },
    {
      dataField: "actions",
      formatter: this.actionsFormatter2,
      headerAttrs: { width: 50 },
      attrs: { width: 50, className: "EditRow" },
      headerAlign: "center",
      events: {
        onClick: this.handleDelete,
      },
    },
  ];

  render() {
    console.log("teachercurrentstate", this.state);
    console.log("propsdatr", this.props);
    // console.log("Globalsssss",this.props.globals.globals)

    const globals = this.props.globals;
    const loading1 = globals.loading;
    const error1 = globals.error;

    const getTeachers = this.props.getTeachers;
    const loading2 = getTeachers.loading;
    const error2 = getTeachers.error;

    if (loading1 || loading2) return null;
    if (error1 !== undefined) {
      alert("Server Error. " + error1.message);
      return null;
    }

    if (error2 !== undefined) {
      alert("Server Error. " + error2.message);
      return null;
    }
    console.log("globals.globals", globals.globals);
    return (
      <section className="teachers_section">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <BreadcrumbsSection
              onClick={() => this.setState({ modalShow: true })}
              breadcrumbs={this.state.BranchesSection}
            />
          </Col>

          {/* <Col xl={12} lg={12} md={12} sm={12}>
            <Card as={Card.Body} className="teacher_status border-0">
              <Row>
                <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                  <Card
                    as={Card.Body}
                    className="total_status border-0 shadow-sm p-2"
                  >
                    <div className="mb-2 border-dashed">
                      <p className="mb-0 title">Total Teachers</p>
                      <h2 className="counts mb-0">600</h2>
                    </div>
                    <Card className="border-0">
                      <p className="mb-0 title">Maths</p>
                      <h2 className="counts mb-0">100</h2>
                    </Card>
                    <Card className="border-0">
                      <p className="mb-0 title">Physics</p>
                      <h2 className="counts mb-0">200</h2>
                    </Card>
                    <Card className="border-0">
                      <p className="mb-0 title">Chemistry</p>
                      <h2 className="counts mb-0">200</h2>
                    </Card>
                    <Card className="border-0">
                      <p className="mb-0 title">Biology</p>
                      <h2 className="counts mb-0">100</h2>
                    </Card>
                  </Card>
                </Col>
                <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                  <Row className="location_wise_status">
                    <Col xl={3} lg={3} md={6} sm={6} xs={6}>
                      <Card className="single_location border-0 p-3 py-4 shadow-sm">
                        <p className="title mb-3">Telangana</p>
                        <h2 className="counts mb-2">200</h2>
                      </Card>
                      <Card className="border-0 p-3 shadow-sm flex-row align-items-center">
                        <p className="title">Branch-1</p>
                        <h2 className="counts ml-3">70</h2>
                        <i className="fas fa-info-circle" />
                      </Card>
                      <Card className="border-0 p-3 shadow-sm flex-row align-items-center">
                        <p className="title">Branch-2</p>
                        <h2 className="counts ml-3">70</h2>
                        <i className="fas fa-info-circle" />
                      </Card>
                      <Card className="border-0 p-3 shadow-sm flex-row align-items-center">
                        <p className="title">Branch-3</p>
                        <h2 className="counts ml-3">70</h2>
                        <i className="fas fa-info-circle" />
                      </Card>
                    </Col>
                    <Col xl={3} lg={3} md={6} sm={6} xs={6}>
                      <Card className="single_location border-0 p-3 py-4 shadow-sm">
                        <p className="title mb-3">Andhra Pradesh</p>
                        <h2 className="counts mb-2">200</h2>
                      </Card>
                      <Card className="border-0 p-3 shadow-sm flex-row align-items-center">
                        <p className="title">Branch-1</p>
                        <h2 className="counts ml-3">70</h2>
                        <i className="fas fa-info-circle" />
                      </Card>
                      <Card className="border-0 p-3 shadow-sm flex-row align-items-center">
                        <p className="title">Branch-2</p>
                        <h2 className="counts ml-3">70</h2>
                        <i className="fas fa-info-circle" />
                      </Card>
                      <Card className="border-0 p-3 shadow-sm flex-row align-items-center">
                        <p className="title">Branch-3</p>
                        <h2 className="counts ml-3">70</h2>
                        <i className="fas fa-info-circle" />
                      </Card>
                    </Col>
                    <Col xl={3} lg={3} md={6} sm={6} xs={6}>
                      <Card className="single_location border-0 p-3 py-4 shadow-sm">
                        <p className="title mb-3">Chennai</p>
                        <h2 className="counts mb-2">200</h2>
                      </Card>
                      <Card className="border-0 p-3 shadow-sm flex-row align-items-center">
                        <p className="title">Branch-1</p>
                        <h2 className="counts ml-3">70</h2>
                        <i className="fas fa-info-circle" />
                      </Card>
                      <Card className="border-0 p-3 shadow-sm flex-row align-items-center">
                        <p className="title">Branch-2</p>
                        <h2 className="counts ml-3">70</h2>
                        <i className="fas fa-info-circle" />
                      </Card>
                      <Card className="border-0 p-3 shadow-sm flex-row align-items-center">
                        <p className="title">Branch-3</p>
                        <h2 className="counts ml-3">70</h2>
                        <i className="fas fa-info-circle" />
                      </Card>
                    </Col>
                    <Col xl={3} lg={3} md={6} sm={6} xs={6}>
                      <Card className="single_location border-0 p-3 py-4 shadow-sm">
                        <p className="title mb-3">Delhi</p>
                        <h2 className="counts mb-2">200</h2>
                      </Card>
                      <Card className="border-0 p-3 shadow-sm flex-row align-items-center">
                        <p className="title">Branch-1</p>
                        <h2 className="counts ml-3">70</h2>
                        <i className="fas fa-info-circle" />
                      </Card>
                      <Card className="border-0 p-3 shadow-sm flex-row align-items-center">
                        <p className="title">Branch-2</p>
                        <h2 className="counts ml-3">70</h2>
                        <i className="fas fa-info-circle" />
                      </Card>
                      <Card className="border-0 p-3 shadow-sm flex-row align-items-center">
                        <p className="title">Branch-3</p>
                        <h2 className="counts ml-3">70</h2>
                        <i className="fas fa-info-circle" />
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col> */}

          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="card-title mb-0 text-danger">
              {this.state.status == 2 ? "Message deleted successfully" : ""}
            </div>
            <DataTableWithOutSearch
              parentData={this.tableData(getTeachers.getTeachers)}
              particlecolumns={this.articlecolumns}
              tableHeading={this.state.tableHeaderData}
              defaultSorted={defaultSorted}
              // branchData={getTeachers.getTeachers}
              name="Teachers"
            />
          </Col>
          <TeacherModal
            globals={globals.globals}
            show={this.state.modalShow}
            onHide={() => this.setState({ modalShow: false })}
          />
          <TeacherModalEdit
            handleFormSubmit={this.handleFormSubmit}
            parenthandleInputChange={this.handleInputChange}
            getDefaultValuesSection={this.getDefaultValuesSection}
            getValues={this.getValues}
            handleSelectInputChangeSection={this.handleSelectInputChangeSection}
            getDefaultValues={this.getDefaultValues}
            getClassValues={this.getClassValues}
            handleSelectInputChange={this.handleSelectInputChange}
            stateData={this.state}
            globals={globals.globals}
            show={this.state.modalShow1}
            onHide={() => this.setState({ modalShow1: false })}
          />
        </Row>
      </section>
    );
  }
}
export default withRouter(
  compose(
    graphql(FETCH_GLOBALS, {
      options: (props) => ({
        variables: {
          institution_id: parseInt(Cookies.get("institutionid")),
        },
      }),
      name: "globals",
    }),
    graphql(FETCH_TEACHERS, {
      options: (props) => ({
        variables: {
          institution_id: parseInt(Cookies.get("institutionid")),
        },
        fetchPolicy: "cache-and-network",
      }),
      name: "getTeachers",
    }),
    graphql(DELETE_TEACHER, {
      name: "handleDelete",
    }),
    graphql(EDIT_TEACHER, {
      name: "editTeacher",
    })
  )(TeachersSection)
);
