import React, { Component } from 'react'
import ViewQuestionAnswerSectionMiddle from '../components/exams/viewquestion_answer/ViewQuestionAnswerSectionMiddle';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as Cookies from "es-cookie";
import * as compose from 'lodash.flowright';
import PreloaderTwo from '../components/preloader/PreloaderTwo'
import { withRouter } from 'react-router-dom';

import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';

const FETCH_SESSION_DATA = gql`
  query(
          $mobile: String!, $exam_session_id: ID) {
            getStudentExamSessions(mobile: $mobile, exam_session_id: $exam_session_id){
              id
              correct
              wrong
              not_answered
              total_questions
              correct_marks
              negative_marks
              total_marks
              in_time
              less_time
              over_time
              accuracy
              speed
              type
              sub_type
              exam_type
              qtype_report{
                question_type
                question_type_name
                correct
                wrong
                not_answered
                accuracy
              }
              error_report{
                error
                error_name
                count
              }
              result_questions{
                
                id
                question
                option1
                option2
                option3
                option4
                subject
                explanation
                answer
                status
                slno
                qtype
                compquestion
                list1type
                list2type
                mat_question
                inputquestion
                attempt_answer
                reason_for_wrong
                comments
                
              }
              timestamp
            
          }
      }
`;

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

const FETCH_GLOBALS = gql` 
query($mobile: String) {
    studentGlobals(mobile: $mobile){
        reports{
            id
            report
        }
        errorReasons{
            id
            reason
          }
        
    }
}

`;

class ViewQuestionAnswer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submitError: ""

        }
    }

    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    componentDidMount() {
        this.addStudentAnalytics();

        if (this.props.history.location.state.examtype == "series_test") {
            const title = GoogleAnalyticsArray[0].MockTest_QandA_View;
            ReactGA.pageview('/student/view-question-answer', ["rizee"], title);
        }
        if (this.props.history.location.state.examtype == "error_exam") {
            const title = GoogleAnalyticsArray[0].MockTest_QandA_View;
            ReactGA.pageview('/student/view-question-answer', ["rizee"], title);
        }
        if (this.props.history.location.state.examtype == "schedule_exam") {
            const title = GoogleAnalyticsArray[0].Schedule_Exams_QandA_View;
            ReactGA.pageview('/student/view-question-answer', ["rizee"], title);
        }
        if (this.props.history.location.state.examtype == "custom") {
            const title = GoogleAnalyticsArray[0].Custom_Exams_QandA_View;
            ReactGA.pageview('/student/view-question-answer', ["rizee"], title);
        }
        if (this.props.history.location.state.examtype == "jeemainsprevious_exam") {
            const title = GoogleAnalyticsArray[0].Jee_Mains_2021_QandA_View;
            ReactGA.pageview('/student/view-question-answer', ["rizee"], title);
        }
        if (this.props.history.location.state.examtype == "eamcet_exam") {
            const title = GoogleAnalyticsArray[0].Eamcet_Mocktest_QA_View;
            ReactGA.pageview('/student/view-question-answer', ["rizee"], title);
        }
        if (this.props.history.location.state.examtype == "eamcet_practice") {
            const title = GoogleAnalyticsArray[0].Eamcet_Practice_QA_View;
            ReactGA.pageview('/student/view-question-answer', ["rizee"], title);
        }
        if (this.props.history.location.state.examtype == "eamcet_previous") {
            const title = GoogleAnalyticsArray[0].Eamcet_Previous_QA_View;
            ReactGA.pageview('/student/view-question-answer', ["rizee"], title);
        }
        if (this.props.history.location.state.examtype == "eamcet_previous") {
            const title = GoogleAnalyticsArray[0].Eamcet_Previous_QA_View;
            ReactGA.pageview('/student/view-question-answer', ["rizee"], title);
        }
        if (this.props.history.location.state.examtype == "eamcet_crash_course") {
            const title = GoogleAnalyticsArray[0].Eamcet_CrashCourse_QA_View;
            ReactGA.pageview('/student/view-question-answer', ["rizee"], title);
        }
        if (this.props.history.location.state.examtype == "quiz_exam") {
            const title = GoogleAnalyticsArray[0].Quiz_QA_View;
            ReactGA.pageview('/student/view-question-answer', ["rizee"], title);
        }


        const title = GoogleAnalyticsArray[0].Exams_QandA_View;
        ReactGA.pageview('/student/subject/exam-result', ["rizee"], title);


        window.addEventListener('contextmenu',
            event => event.preventDefault());


        // window.addEventListener("mousedown", e => {
        //     e.preventDefault();
        //     e.returnValue = "false";

        // });
        window.addEventListener("selectstart", e => {
            e.preventDefault();
            e.returnValue = "false";

        });
    }

    addStudentAnalytics = e => {
        let addStudentObj = "";
        if (this.props?.history?.location?.state?.examtype == "eamcet_previous") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 122,
                source: 2
            }
        } else if (this.props?.history?.location?.state.examtype == "eamcet_practice") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 116,
                source: 2
            }

        } else if (this.props.history.location.state.examtype == "eamcet_crash_course") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 146,
                source: 2
            }
        } else if (this.props.history.location.state.examtype == "eamcet_exam") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 110,
                source: 2
            }
        } else if (this.props.history.location.state.examtype == "series_test") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 12,
                source: 2
            }
        } else if (this.props.history.location.state.examtype == "jeemainsprevious_exam") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 101,
                source: 2
            }
        } else if (this.props.history.location.state.examtype == "series_test_NewNTA") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 12,
                source: 2
            }
        } else if (this.props.history.location.state.examtype == "error_exam") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 35,
                source: 2
            }
        }
        else if (this.props.history.location.state.examtype == "schedule_exam") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 47,
                source: 2
            }
        }
        else if (this.props.history.location.state.examtype == "custom") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 51,
                source: 2
            }
        }
        else if (this.props.history.location.state.examtype == "quiz_exam") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 152,
                source: 2
            }
        }
        else if (this.props.history.location.state.examtype == "series_test_pattern") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 158,
                source: 2
            }
        }
        else if (this.props.history.location.state.examtype == "previous") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 187,
                source: 2
            }
        }
        else if (this.props.history.location.state.examtype == "foundation_exam") {
            addStudentObj = {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: Cookies.get("mobile"),
                analytics_module_page_id: 214,
                source: 2
            }
        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };


    componentWillUnmount() {

        window.removeEventListener('contextmenu', () => { });
        //window.removeEventListener("mousedown", () => { });
        window.removeEventListener("selectstart", () => { });

    }
    render() {

        if (Cookies.get("studenttoken") == undefined)
            this.props.history.push("/student/login");
        const getStudentExamSessions = this.props.getStudentExamSessions;
        const loading1 = getStudentExamSessions.loading;
        const error1 = getStudentExamSessions.error;
        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        const studentGlobals = this.props.studentGlobals;
        const loading2 = studentGlobals.loading;
        const error2 = studentGlobals.error;

        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }

        console.log("studentGlobals", studentGlobals);
        return (
            <div className={Cookies.get("toggle")}>
                {(loading1 == true || loading2 == true) && (<PreloaderTwo />)}
                {
                    !loading1 && !loading2 && (
                        <ViewQuestionAnswerSectionMiddle
                            locdata={this.props.history.location.state}
                            changeToggle={this.menuToggler}
                            getData={getStudentExamSessions.getStudentExamSessions[0]}
                            getStudentExamSessions={getStudentExamSessions.getStudentExamSessions[0].result_questions}
                            studentGlobals={studentGlobals.studentGlobals}
                        />
                    )
                }
            </div>
        )
    }
}
export default
    withRouter(
        compose(graphql(FETCH_SESSION_DATA,
            {
                options: props => ({
                    variables: {
                        mobile: Cookies.get("mobile"),
                        exam_session_id: parseInt(props.history.location.state.sessionid),

                    },
                }), name: "getStudentExamSessions"
            }),
            graphql(UPDATE_STUDENT_ANALYTICS, {
                name: "submitpt"
            }),
            graphql(FETCH_GLOBALS, {
                options: (props) => ({
                    variables: {
                        mobile: Cookies.get("mobile"),
                    }
                    ,
                    fetchPolicy: "no-cache"
                }),
                name: "studentGlobals"
            })

        )(ViewQuestionAnswer));

