import React, { Component } from 'react'
import { Modal, Form, Button, Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import DataTableWithOutSearch from '../datatables/DataTableWithOutSearch';
import parse, { domToReact } from 'html-react-parser';

class ErrorSkippedModal extends Component {
    tablesectionData(data) {
        let returnArray = [];
        data.map((mapitem) => {
            if (mapitem != undefined) {
                const newObj = {
                    question: parse(mapitem.question),
                    subject: mapitem.subject_name,
                    chapter: mapitem.chapter_name,
                    error: mapitem.error_count,
                    skipped: mapitem.skipped_count
                }
                returnArray.push(newObj);
            }
        });

        return returnArray;
    }
    columnsFun() {
        let returnArray = [
            {
                dataField: "question",
                text: "Question ",
                sort: true
            },
            {
                dataField: "subject",
                text: "Subject ",
                sort: true
            },
            {
                dataField: "chapter",
                text: "Chapter ",
                sort: true
            }
        ];
        if (this.props.data == "error") {
            const newObj = {
                dataField: "error",
                text: "Error",
                sort: true
            }
            returnArray.push(newObj);
        }
        else if (this.props.data == "skipped") {
            const newObj = {
                dataField: "skipped",
                text: "Skipped",
                sort: true
            }
            returnArray.push(newObj);
        }
        else if (this.props.data == "errorskipped") {
            const newObj1 = {
                dataField: "error",
                text: "Error",
                sort: true
            }
            returnArray.push(newObj1);

            const newObj2 = {
                dataField: "skipped",
                text: "Skipped",
                sort: true
            }
            returnArray.push(newObj2);
        }
        return returnArray;
    }
    defaultSortedFun() {
        let returnArray = [];
        if (this.props.data == "error") {
            const newObj = {
                dataField: "error",
                order: "desc"
            }
            returnArray.push(newObj);
        }
        else if (this.props.data == "skipped") {
            const newObj = {
                dataField: "skipped",
                order: "desc"
            }
            returnArray.push(newObj);
        }
        else if (this.props.data == "errorskipped") {
            // const newObj1 = {
            //     dataField: "skipped",
            //     order: "desc"
            // }
            // returnArray.push(newObj1);

            const newObj2 = {
                dataField: "error",
                order: "desc"
            }
            returnArray.push(newObj2);
        }
        console.log("returnArraysort", returnArray);
        return returnArray;
    }
    render() {
        console.log("this.props.Data", this.props.data);
        if (this.props.geterrorskippeddata == undefined) {
            return null
        }
        let tablename = "";
        if (this.props.data == "error") {
            tablename = "Error Questions Data";
        }
        else if (this.props.data == "skipped") {
            tablename = "Skipped Questions Data";
        }
        else if (this.props.data == "errorskipped") {
            tablename = "Most Error and Skipped Questions Data";
        }
        return (
            <Modal {...this.props}
                size="lg" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">{this.props.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4"
                //style={{ height: 522, overflowY: 'auto' }}
                >
                    <DataTableWithOutSearch
                        parentData={this.tablesectionData(this.props.geterrorskippeddata)}
                        particlecolumns={this.columnsFun()}
                        defaultSorted={this.defaultSortedFun()}
                        //tableHeading={this.props.stateData.TableHeaderData}
                        name={tablename} />

                </Modal.Body>
            </Modal >
        )
    }
}

export default ErrorSkippedModal
