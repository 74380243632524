import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import * as Cookies from "es-cookie"
import { withRouter } from "react-router-dom";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import ChapterWiseLearningSection from '../components/ChapterWiseLearningEamcet/ChapterWiseLearningSection'

const FETCH_EAMCETPRATICETEST = gql` 
query($mobile: String,$chapterId:Int) {
    getChapterWisePracticeTests(mobile:$mobile chapterId:$chapterId){
        chapter_id
        class_id
        subject_id
        chapter_name
        exam_paper_one{
            id
            exam_name
            exam_type
            exam_model_id
            class_id
            exam_session_id
            sub_exam_type
            start_time
            end_time
            is_completed
            speed
            accuracy
            correct_marks
            negative_marks
            total_marks
            exam_started
            exam_expired
            syllabus
            exam_duration
            uid
            is_scheduled
            is_registered
            syllabus_desc
            is_started
            is_started_mobile
            exam_duration_min
            complexity_id
            enabled
        }
   }
}
`;

const CHAPTER_WISE_PREVIOUS_QUESTIONS = gql`
query($exam_id:Int,$chapter_id:Int){
    getChapterWiseGroupedQuestionsNew(exam_id: $exam_id, chapter_id: $chapter_id) {
        id
        slno
        question_name
        option1
        option2
        option3
        option4
        explanation
        answer
        qtype
        list2type
        list1type
        year
        pexamtype
        exam
        compquestion
        mat_question
      }
}
`

const IPE_PREVIOUS_QUESTIONS = gql`
query($chapter_id:Int){
    getIPEChapterWiseQuestions(chapter_id: $chapter_id) {
        id
        slno
        question_name
        explanation
        qtype
        marks
        exam
        option1
        option2
        option3
        option4
        year
      }
}
`


class ChapterWiseLearningEamcet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerBottomImg: {
                type: "eamcet_view",
                title: "Chapter Wise Learning"
            },
        }
    }
    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };

    render() {
        console.log("aaaaaaaaaaaaa", this.props.history.location.state)

        const getChapterWisePracticeTests = this.props.getChapterWisePracticeTests;
        const loading1 = getChapterWisePracticeTests.loading;
        const error1 = getChapterWisePracticeTests.error;

        const getChapterWiseGroupedQuestionsNew = this.props.getChapterWiseGroupedQuestionsNew;
        const loading2 = getChapterWiseGroupedQuestionsNew.loading;
        const error2 = getChapterWiseGroupedQuestionsNew.error;

        const getIPEChapterWiseQuestions = this.props.getIPEChapterWiseQuestions;
        const loading3 = getIPEChapterWiseQuestions.loading;
        const error3 = getIPEChapterWiseQuestions.error

        if (error1 !== undefined) {
            alert("Server Error. " + error1.message);
            return null;
        }

        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }
        if (error3 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }

        if (this.props.history.location.state == undefined) {
            this.props.history.push("/student/home")
            return null
        }
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className={Cookies.get("toggle")}>
                            <div className="header-area">
                                <Navbars
                                    headerBottom={this.state.headerBottomImg}
                                    onClick={() => this.menuToggler()}
                                />
                            </div>
                            <div className="main-wrapper">
                                <AsideNavbar onClick={() => this.menuToggler()} />
                                <div className="student-overlay" onClick={() => this.menuToggler()} />
                                <div className="content-wrapper" style={{ paddingTop: 10, minHeight: "80vh" }}>
                                    {
                                        loading1 || loading2 || loading3 ?
                                            (
                                                <Container>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            )
                                            :
                                            (
                                                <ChapterWiseLearningSection
                                                    getChapterWisePracticeTests={getChapterWisePracticeTests.getChapterWisePracticeTests}
                                                    getChapterWiseGroupedQuestionsNew={getChapterWiseGroupedQuestionsNew.getChapterWiseGroupedQuestionsNew}
                                                    getIPEChapterWiseQuestions={getIPEChapterWiseQuestions.getIPEChapterWiseQuestions}
                                                />
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(compose(
    graphql(FETCH_EAMCETPRATICETEST,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    chapterId: props?.history?.location?.state?.exam_type_value == 12 || props?.history?.location?.state?.exam_type_value == 13 ? parseInt(props?.history?.location?.state?.Ipe_chapter_id) : props?.history?.location?.state?.chapterData?.id
                },
                fetchPolicy: "network-only"
            }), name: "getChapterWisePracticeTests"
        }),
    graphql(CHAPTER_WISE_PREVIOUS_QUESTIONS,
        {
            options: props => ({
                variables: {
                    exam_id: parseInt(Cookies.get("examid")),
                    chapter_id: props?.history?.location?.state?.exam_type_value == 12 || props?.history?.location?.state?.exam_type_value == 13 ? parseInt(props?.history?.location?.state?.Ipe_chapter_id) : props?.history?.location?.state?.chapterData?.id
                },
                fetchPolicy: "network-only"
            }), name: "getChapterWiseGroupedQuestionsNew"
        }),
    graphql(IPE_PREVIOUS_QUESTIONS,
        {
            options: props => ({
                variables: {
                    chapter_id: props?.history?.location?.state?.exam_type_value == 12 || props?.history?.location?.state?.exam_type_value == 13 ? parseInt(props?.history?.location?.state?.Ipe_chapter_id) : props?.history?.location?.state?.chapterData?.id

                },
                fetchPolicy: "network-only"
            }), name: "getIPEChapterWiseQuestions"
        }),
)(ChapterWiseLearningEamcet));