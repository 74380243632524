import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AsideNavbar from '../components/navbars/AsideNavbar'
import Navbars from '../components/navbars/Navbars'
import * as Cookies from "es-cookie";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import PreloaderTwo from '../components/preloader/PreloaderTwo';
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from './GoogleAnalytics';
import EamcetVideosSection from '../components/EamcetVideos/EamcetVideosSection'

const GET_CHAPTER_NAMES = gql`
query($exam_id:Int,$mobile:String){
    getStateChapterNames(exam_id:$exam_id,mobile:$mobile){
        id
        subject_name
        state_chapters{
          id
          chapter_id
          class_id
          chapter_name
          enabled
          questionsCount
          previousChapterEnabled
          practiceEnabled
          revisionMaterialEnabled
          errorExamEnabled
          videoAccess
        }
      }
}
`;

const UPDATE_STUDENT_ANALYTICS = gql`
mutation(
  $params:AnalyticsStudents1  
  ) {
      studentAnalytics(
      params: $params
   )
}
`;

const GET_SUBJECTS = gql`

query($exam_id:Int){
getSubjectOrder(exam_id:$exam_id){
    id
    subject
}
}
`
class EamcetVideos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerBottomImg: {
                type: "eamcet_view",
                title: "Video Classes"
            },
            toggled: "wrapper sidebar-enable",
            submitError: ""
        }
    }

    menuToggler = () => {
        const toggled = Cookies.get("toggle");
        if (toggled === "wrapper") {
            this.setState({ toggled: "wrapper sidebar-enable" });
            Cookies.set("toggle", "wrapper sidebar-enable");
        } else {
            this.setState({ toggled: "wrapper" });
            Cookies.set("toggle", "wrapper");
        }
    };
    
    componentDidMount = () => {
        this.addStudentAnalytics();
    }

    addStudentAnalytics = e => {
        let addStudentObj = ""

        addStudentObj = {
            exam_id: parseInt(Cookies.get("examid")),
            mobile: Cookies.get("mobile"),
            analytics_module_page_id: 247,
            source: 2

        }
        console.log("addStudentObj", addStudentObj)

        this.submitpt(
            addStudentObj
        ).catch(error => {
            console.log("catch if error");
            console.log(error);
            this.setState({
                submitError: error.graphQLErrors.map(x => x.message)
            });
        });
    }

    submitpt = async (
        params) => {
        await this.props.submitpt({
            variables: {
                params
            },
        });
    };

    render() {
        const getSubjectOrder = this.props.getSubjectOrder
        const loading2 = getSubjectOrder.loading;
        const error2 = getSubjectOrder.error;

        const getStateChapterNames = this.props.getStateChapterNames
        const loading3 = getStateChapterNames.loading;
        const error3 = getStateChapterNames.error;

        if (error2 !== undefined) {
            alert("Server Error. " + error2.message);
            return null;
        }

        if (error3 !== undefined) {
            alert("Server Error. " + error3.message);
            return null;
        }

        let globalsubjects = "";
        if (JSON.parse(localStorage.getItem("globalsubjects")) != "") {
            globalsubjects = JSON.parse(localStorage.getItem("globalsubjects"));
        }
        else {
            this.props.history.push("/student/login");
        }


        let studentGlobals = "";
        if (JSON.parse(localStorage.getItem("studentglobals")) != "") {
            studentGlobals = JSON.parse(localStorage.getItem("studentglobals"));
        }
        else {
            this.props.history.push("/student/login");
        }
        return (
            <div className={Cookies.get("toggle")}>
                <div className="header-area">
                    <Navbars
                        headerBottom={this.state.headerBottomImg}
                        onClick={() => this.menuToggler()}
                    />
                </div>
                <div className="main-wrapper">
                    {/* {(loading1 == true) && (<PreloaderTwo />)} */}
                    <AsideNavbar onClick={() => this.menuToggler()} />
                    <div className="student-overlay" onClick={() => this.menuToggler()} />
                    <div className="content-wrapper" style={{ paddingTop: 10, minHeight: "80vh" }}>
                        {
                            loading2 || loading3 ?
                                (
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                                <Image src={require('../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                                :
                                (
                                    <EamcetVideosSection
                                        getStateChapterNames={getStateChapterNames.getStateChapterNames}
                                        globalsubjects={globalsubjects}
                                        getSubjectOrder={getSubjectOrder.getSubjectOrder}
                                    />
                                )
                        }

                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(compose(
    graphql(UPDATE_STUDENT_ANALYTICS, {
        name: "submitpt"
    }),
    graphql(GET_SUBJECTS, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
            },
            fetchPolicy: 'network-only'
        }), name: "getSubjectOrder"
    }),
    graphql(GET_CHAPTER_NAMES, {
        options: props => ({
            variables: {
                exam_id: parseInt(Cookies.get("examid")),
                mobile: (Cookies.get("mobile")),

            },
            fetchPolicy: 'network-only'
        }), name: "getStateChapterNames"
    }),
)(EamcetVideos));