import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Card, Table } from 'react-bootstrap';
import './_liveClassess.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import LiveClassesModal from '../liveClasses/LiveClassesModal';
import CardLessDataTableWithSearch from '../data_tables/CardLessDataTableWithSearch';

import FileText from "../../../images/images1/FileText.png";
import FileArrowDown from "../../../images/images1/FileArrowDown.png";
//import Group3172 from "../../../images/images1/Group3172.png";

import UserRestrictionAlertInstitute from '../home/UserRestrictionAlertInstitute';
import LockSimple from "../../../images/LockSimple.png";
import * as Cookies from "es-cookie";
import moment from 'moment';

//ENGNEERING CHEMISTRY
let QuestionDataCheEngg = [

    {
        DATE: "02-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Chemical Bonding",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("fZLk5oYI6HE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Introdution to Organic Chemistry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("TaNfEbiXRDw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Introduction to Physical Chemistry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("S1xcozxK59A"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Introdution to In-organic Chemistry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("oUNfpoSbWZ4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Chemical Bonding And Molecular Structure",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_5444_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_9313_Sol.pdf"),
        VideoLecture: ("UqzP4S1OQtQ"),
        Live: (""),
        Zoom: ("")
    },

    {
        DATE: "08-07-2021",
        ClassTimings: "2:30 PM to 4.00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Stoichiometry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_4747_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_7914_Sol.pdf"),
        VideoLecture: ("AROYrpTGVyw"),
        Live: (""),
        Zoom: (""),
    },

    {
        DATE: "09-07-2021",
        ClassTimings: "2:30 PM to 4.00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Equilibrium",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_6043_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_1632_Sol.pdf"),
        VideoLecture: ("g0If4LYHAEU"),
        Live: (""),
        Zoom: (""),
    },

    {
        DATE: "10-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Organic Chemistry Some Basic Principles and Techniques",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_9558_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_9995_Sol.pdf"),
        VideoLecture: ("pZJfVaN0IuI"),
        Live: (""),
        Zoom: (""),
    },

    {
        DATE: "12-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Structure of Atom",
        ShortNotes: "",
        Assignments: ("https://rizee.in/econtent/LiveClass/D5_3438_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_7856_Sol.pdf"),
        VideoLecture: ("LzhUwbaxP_8"),
        Live: (""),
        Zoom: (""),
    },

    {
        DATE: "13-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "The P-Block Elements(XI)",
        ShortNotes: "",
        Assignments: ("https://rizee.in/econtent/LiveClass/D6_1158_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_4455_Sol.pdf"),
        VideoLecture: ("Tpa6jV1imtQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Thermodynamics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_8527_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_2229_Sol.pdf"),
        VideoLecture: ("jb1b5nHkdRs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "States of Matter",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_6469_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_2401_Sol.pdf"),
        VideoLecture: ("Bz61Moge3sc"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Classification of Elements and Periodicity in Properties",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_945_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_9015_Sol.pdf"),
        VideoLecture: ("mFtgmIBSHVs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Hydrocarbons",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_5993_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_4467_Sol.pdf"),
        VideoLecture: ("Wtnl_RPkffQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "S-Block Elements",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_9772_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_6874_Sol.pdf"),
        VideoLecture: ("6UNLma0CTmY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Hydrogen",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_273_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_9222_Sol.pdf"),
        VideoLecture: ("X1GgazNYml4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Environmental Chemistry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_5249_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_9819_Sol.pdf"),
        VideoLecture: ("QJaFyN-Epno"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Co-Ordination Compounds",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_975_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_157_Sol.pdf"),
        VideoLecture: ("ihqd4algaXk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Alcohol, Phenol and Ethers",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_8908_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_3604_Sol.pdf"),
        VideoLecture: ("SUhCiMsDNe4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Aldehydes and Ketones",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_6694_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_9124_Sol.pdf"),
        VideoLecture: ("aDuoo_DgEB8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Carboxylic Acids",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_3971_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_3775_Sol.pdf"),
        VideoLecture: ("7QLsO94vg2Q"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "The P-Block Elements(XII)",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_6517_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_8181_Sol.pdf"),
        VideoLecture: ("d5pjxGMxkxM"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Solutions",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_3818_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_5297_Sol.pdf"),
        VideoLecture: ("NhC1YI85stU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Chemical Kinetics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_8139_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_5851_Sol.pdf"),
        VideoLecture: ("GMDQqavWKas"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Organic Compounds Containing Halogens",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_9401_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_1267_Sol.pdf"),
        VideoLecture: ("yBlFtAcuBuE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Solid State",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_148_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_4348_Sol.pdf"),
        VideoLecture: ("q-MuRrqYync"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Electrochemistry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_5399_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_7871_Sol.pdf"),
        VideoLecture: ("ceYlBOTe6bQ"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "D and F Block Elements",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_9423_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_3065_Sol.pdf"),
        VideoLecture: ("i8EWncpuA-I"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Surface Chemistry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_5988_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_5707_Sol.pdf"),
        VideoLecture: ("diSSe2e-wrU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Organic Chemistry Containing Nitrogen",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_732_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_7857_Sol.pdf"),
        VideoLecture: ("QU9yoF5tMGs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "2:30 PM to 4:00 PM",
        time: "14",
        Subject: "Chemistry",
        Chapter: "Biomolecules",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_5478_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_1364_Sol.pdf"),
        VideoLecture: ("IcFQKb_-Drk"),
        Live: (""),
        Zoom: (""),
    },
];

//AGRICULTURE CHEMISTRY

let QuestionDataCheAgri = [
    {
        DATE: "02-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Chemical Bonding",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("fZLk5oYI6HE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Introdution to Organic Chemistry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("TaNfEbiXRDw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Introduction to Physical Chemistry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("S1xcozxK59A"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-07-2021",
        ClassTimings: "10 AM to 11 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Introdution to In-organic Chemistry",

        Assignments: (""),
        Solutions: (""),
        VideoLecture: ("oUNfpoSbWZ4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "07-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Chemical Bonding And Molecular Structure",

        Assignments: ("https://rizee.in/econtent/LiveClass/D1_1622_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D1_8364_Sol.pdf"),
        VideoLecture: ("Dzf8MaBO6qc"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "08-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Stoichiometry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D2_6014_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D2_5108_Sol.pdf"),
        VideoLecture: ("Elym3AzmVbE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "09-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Equilibrium",

        Assignments: ("https://rizee.in/econtent/LiveClass/D3_2852_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D3_45_Sol.pdf"),
        VideoLecture: ("3SJVxAfCZVA"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "10-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Organic Chemistry Some Basic Principles and Techniques",

        Assignments: ("https://rizee.in/econtent/LiveClass/D4_1147_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D4_3091_Sol.pdf"),
        VideoLecture: ("lkCZ5N3PsKw"),
        Live: (""),
        Zoom: ("")
    },
    {
        DATE: "12-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Structure of Atom",

        Assignments: ("https://rizee.in/econtent/LiveClass/D5_2469_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D5_6174_Sol.pdf"),
        VideoLecture: ("xqeWPbvcAxU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "13-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "The P-Block Elements(XI)",

        Assignments: ("https://rizee.in/econtent/LiveClass/D6_3893_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D6_6415_Sol.pdf"),
        VideoLecture: ("Ricns0ke6-A"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "14-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Thermodynamics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D7_2701_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D7_9865_Sol.pdf"),
        VideoLecture: ("GFso2Jc8vlk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "15-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "States of Matter",

        Assignments: ("https://rizee.in/econtent/LiveClass/D8_1684_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D8_7275_Sol.pdf"),
        VideoLecture: ("Lvn2LlojGy0"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "16-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Classification of Elements and Periodicity in Properties",

        Assignments: ("https://rizee.in/econtent/LiveClass/D9_6537_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D9_7393_Sol.pdf"),
        VideoLecture: ("e7IC6txXpoI"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "17-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Hydrocarbons",

        Assignments: ("https://rizee.in/econtent/LiveClass/D10_9706_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D10_6971_Sol.pdf"),
        VideoLecture: ("Psf9a1yT1ZU"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "19-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "S-Block Elements",

        Assignments: ("https://rizee.in/econtent/LiveClass/D11_5669_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D11_8041_Sol.pdf"),
        VideoLecture: ("Mn3s4ZYWtV8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "20-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Hydrogen",

        Assignments: ("https://rizee.in/econtent/LiveClass/D12_5766_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D12_6324_Sol.pdf"),
        VideoLecture: ("6maE2QFiPn8"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "21-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Environmental Chemistry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D13_4881_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D13_3499_Sol.pdf"),
        VideoLecture: ("OgAZQBBvbhs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "22-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Co-Ordination Compounds",

        Assignments: ("https://rizee.in/econtent/LiveClass/D14_1225_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D14_4266_Sol.pdf"),
        VideoLecture: ("Nb2abN3LgVk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "23-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Alcohol, Phenol and Ethers",

        Assignments: ("https://rizee.in/econtent/LiveClass/D15_7334_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D15_2953_Sol.pdf"),
        VideoLecture: ("yit7VAVWRig"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "24-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Aldehydes and Ketones",

        Assignments: ("https://rizee.in/econtent/LiveClass/D16_313_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D16_726_Sol.pdf"),
        VideoLecture: ("bvP7k5iH7Ts"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "26-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Carboxylic Acids",

        Assignments: ("https://rizee.in/econtent/LiveClass/D17_3062_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D17_3829_Sol.pdf"),
        VideoLecture: ("225zTrnAKWk"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "27-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "The P-Block Elements(XII)",

        Assignments: ("https://rizee.in/econtent/LiveClass/D18_9156_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D18_5406_Sol.pdf"),
        VideoLecture: ("LB4_m2obTvY"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "28-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Solutions",

        Assignments: ("https://rizee.in/econtent/LiveClass/D19_7323_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D19_8796_Sol.pdf"),
        VideoLecture: ("0rizH0qSv3o"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "29-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Chemical Kinetics",

        Assignments: ("https://rizee.in/econtent/LiveClass/D20_3235_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D20_5538_Sol.pdf"),
        VideoLecture: ("0kWZEeLyVxs"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "30-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Organic Compounds Containing Halogens",

        Assignments: ("https://rizee.in/econtent/LiveClass/D21_1278_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D21_1443_Sol.pdf"),
        VideoLecture: ("_X5s9cg5N-I"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "31-07-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Solid State",

        Assignments: ("https://rizee.in/econtent/LiveClass/D22_5481_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D22_9106_Sol.pdf"),
        VideoLecture: ("EYHguXi_OB4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "02-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Electrochemistry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D23_4259_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D23_5979_Sol.pdf"),
        VideoLecture: ("fJhG0HmeN4k"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "03-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "D and F Block Elements",

        Assignments: ("https://rizee.in/econtent/LiveClass/D24_8159_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D24_4797_Sol.pdf"),
        VideoLecture: ("5iI6tMTOMm4"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "04-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Surface Chemistry",

        Assignments: ("https://rizee.in/econtent/LiveClass/D25_3393_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D25_3946_Sol.pdf"),
        VideoLecture: ("B6CClFfQPJE"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "05-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Organic Chemistry Containing Nitrogen",

        Assignments: ("https://rizee.in/econtent/LiveClass/D26_5933_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D26_3857_Sol.pdf"),
        VideoLecture: ("hczyCMjDKvw"),
        Live: (""),
        Zoom: (""),
    },
    {
        DATE: "06-08-2021",
        ClassTimings: "10 AM to 11.30 AM",
        time: "10",
        Subject: "Chemistry",
        Chapter: "Biomolecules",

        Assignments: ("https://rizee.in/econtent/LiveClass/D27_3612_Ass.pdf"),
        Solutions: ("https://rizee.in/econtent/LiveClass/D27_6314_Sol.pdf"),
        VideoLecture: ("hIj571VWDc4"),
        Live: (""),
        Zoom: (""),
    },

];

let QuestionData = []
if (Cookies.get("examid") == 7 || Cookies.get("examid") == 8) {
    QuestionData.push(...QuestionDataCheAgri)
} else {
    QuestionData.push(...QuestionDataCheEngg)
}


let LiveClassesDataChemistry = []

for (var i in QuestionData) {
    let currentDate = moment().format("DD/MM/YYYY");
    if ((QuestionData[i]["VideoLecture"] || QuestionData[i]["Live"] || QuestionData[i]["Zoom"]) && (currentDate == moment(QuestionData[i].DATE, "DD-MM-YYYY").format("DD/MM/YYYY"))) {
        LiveClassesDataChemistry.push(QuestionData[i]);
    }
}

var Zoom;
var Livevideo;


class ChemistryDataSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liveModalShow: false,
            dataLiveUrl: "",
            userRestionModalShow: false,
            allLiveClasses: true
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
    }
    modalaFun = (row) => {
        this.setState({
            liveModalShow: true,
            dataLiveUrl: row

        });

    }

    modalFunSubscribed = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7 && Cookies.get("institution_id") == 30 && this.isStudentUserValid.current_plan_id == "23") {
            this.setState({
                userRestionModalShow: true,
            });
        } else {
            this.setState({
                liveModalShow: true,
                dataLiveUrl: row.Live

            });
        }



    }
    sortFuncDate(a, b, order, dataField, rowA, rowB) {
        if (order == 'desc') {

            return moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;

        } else {
            return moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") > moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY") ? 1 : -1;
        }
    }
    checkForDate = (row) => {
        let date = moment(row.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let formattedDate = new Date(date);
        if (formattedDate.getDate() >= 7) {
            return true
        }
        return false
    }
    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }
    sortFuncMulti(a, b, order, dataField, rowA, rowB) {
        console.log(rowA, rowB)
        let date1 = moment(rowA.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        let date2 = moment(rowB.DATE, "DD-MM-YYYY").format("MM/DD/YYYY");
        if (order == 'asc') {
            if (rowA.DATE === rowB.DATE) {
                return rowA.time - rowB.time;
            }

            return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
        } else {
            if (rowA.DATE === rowB.DATE) {
                return rowB.time - rowA.time;
            }
            return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
        }

    }
    actionFormatterAssignments(cell, row, rowIndex) {
        return (
            <>
                {
                    row.Assignments != "" ?
                        <div className="Assignments">
                            <img src={FileText} alt="file-text-img" ></img>
                            <a href={row.Assignments} target="_blank"> Assignment Download</a>
                        </div> : <h6 className="text-center">-</h6>
                }

                {
                    row.Solutions != "" ?
                        <div className="Solutions">
                            <img src={FileArrowDown} alt="File-Arrowdown-img"></img>
                            <a href={row.Solutions} target="_blank" > Solutions</a>
                        </div> : ""
                }
            </>

        )
    }

    actionFormatterVideoLecture(cell, row, rowIndex) {

        return (
            <>
                <div className="allsubjects">
                    {
                        row.Live == "" ?

                            row.VideoLecture != "" ?
                                <button type="button" className="btn btn-primary effect effect-1" onClick={() => this.modalaFun(row.VideoLecture)}>Watch Now </button>

                                : "-"


                            :
                            <>

                                {
                                    <a href={row.Zoom} target="_blank" ><button type="button" className="btn btn-success zoom_effect effect-1">Zoom</button></a>
                                }
                                {
                                    <button type="button" className="btn btn-warning live live_effect effect-1" onClick={() => this.modalaFun(row.Live)}>Live</button>
                                }



                            </>
                    }

                </div>

            </>
        )
    }


    colFunction() {

        let columns = [
            // {
            //     dataField: "DATE",
            //     text: "Date",
            //     sort: true,
            //     headerStyle: {
            //         width: "13%"
            //     },
            //     style: {
            //         fontWeight: "bold !important"
            //     },
            //     sortFunc: this.sortFuncDate.bind(this)
            // },
            // {
            //     dataField: "ClassTimings",
            //     text: "Class Timings",
            //     sort: true,
            //     headerStyle: {
            //         width: "23%"
            //     },
            //     sortFunc: this.sortFuncMulti.bind(this)
            // },

            {
                dataField: "Subject",
                text: "Subject",
                style: (row, rowIndex) => {
                    return { fontWeight: "bold !important" };
                },
            },
            {
                dataField: "Chapter",
                text: "Chapter",
                headerStyle: {
                    width: "35%"
                }
            },
            {
                dataField: "Assignments/Solution",
                text: "Assignment/Solution",
                headerStyle: {
                    width: "55%"
                },

                formatter: this.actionFormatterAssignments.bind(this)
            },
            // {
            //     dataField: "Solutions",
            //     text: "Solutions",
            //     formatter: this.actionFormatterSolutions
            // },
            {
                dataField: "Videos/Recorded/Live",
                text: "Videos(Recorded/Live)",
                formatter: this.actionFormatterVideoLecture.bind(this)
            },
            // {
            //     dataField: "Live/Zoom",
            //     text: "Live/Zoom",
            //     formatter: this.actionFormatterLive
            // },
        ];
        return columns;
    }

    render() {
        return (
            <Row>
                <Col align="right" sm={12}>
                    <h6 className="switch_button_cls mt-res-4">
                        <p className="allClassesAlignment">All Classes</p>
                        <label class="switch">
                            <input type="checkbox"
                                onChange={() => {

                                    this.setState((state) => {

                                        return {
                                            allLiveClasses: !state.allLiveClasses
                                        }
                                    })
                                }}
                                checked={!this.state.allLiveClasses} />
                            <span class="slider round"></span>
                        </label>
                        <p className="liveClassesAlignment">Live Classes</p>
                    </h6>
                </Col>
                <Col sm={12}>
                    {
                        this.state.allLiveClasses ?
                            <CardLessDataTableWithSearch
                                parentData={QuestionData}
                                particlecolumns={this.colFunction()}
                                defaultSorted={[{
                                    dataField: "ClassTimings",
                                    order: "asc"
                                }]}
                            />
                            :
                            <CardLessDataTableWithSearch
                                parentData={LiveClassesDataChemistry}
                                particlecolumns={this.colFunction()}
                                defaultSorted={[{
                                    dataField: "ClassTimings",
                                    order: "asc"
                                }]}
                            />
                    }
                    {/* <Card>
                        <BootstrapTable
                            keyField="id"
                            columns={this.colFunction()}
                            data={QuestionData}
                            defaultSorted={[{
                                dataField: "ClassTimings",
                                order: "asc"
                            }]}
                            wrapperClasses="table-responsive"
                            bootstrap4

                        />
                    </Card> */}

                    <LiveClassesModal data={this.state.dataLiveUrl} show={this.state.liveModalShow} onHide={() => this.setState({ liveModalShow: false })} />
                    <UserRestrictionAlertInstitute
                        show={this.state.userRestionModalShow}
                        onHide={() => this.setState({ userRestionModalShow: false })} />
                </Col>
            </Row>

        )
    }

}
export default ChemistryDataSection