import React, { Component } from "react";
import SideNavbar from "../components/navbars/SideNavbar";
import NavbarOne from "../components/navbars/NavbarOne";
import Preloader from "../components/preloader/Preloader";
import SettingSection from "../components/settings/SettingSection";
import Footer from "../components/footer/Footer";
import * as Cookies from "es-cookie";

class Settings extends Component {
  render() {
    if (Cookies.get("token") == undefined) this.props.history.push("/login");
    return (
      <React.Fragment>
        <div className="left-side-menu">
          <SideNavbar onClick={() => this.props.changeToggle()} />
        </div>
        <div className="content-page">
          <Preloader />
          <NavbarOne onClick={() => this.props.changeToggle()} />
          <div className="overlay" onClick={() => this.props.changeToggle()} />
          <div className="main-content">
            <SettingSection />
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default Settings;
