import React, { Component } from "react";
import SideNavbar from "../components/navbars/SideNavbar";
import NavbarOne from "../components/navbars/NavbarOne";
import Preloader from "../components/preloader/Preloader";
import PackageSection from "../components/examinations/packages/PackageSection";
import Footer from "../components/footer/Footer";

class Package extends Component {
  render() {
    console.log("packagepage", this.props);

    return (
      <React.Fragment>
        <div className="left-side-menu">
          <SideNavbar onClick={() => this.props.changeToggle()} />
        </div>
        <div className="content-page">
          <Preloader />
          <NavbarOne onClick={() => this.props.changeToggle()} />
          <div className="overlay" onClick={() => this.props.changeToggle()} />
          <div className="main-content">
            <PackageSection />
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default Package;
