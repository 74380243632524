import React, { Component } from 'react'
import { Container, Row, Col, Card, Button, Image, Media } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import Chemistry from '../../../../images/chemistry_icon.png';
import Zoology from '../../../../images/zoology_icon.png';
import Physics from '../../../../images/physics_icon.png';
import Maths from '../../../../images/maths_icon.png';
import Botany from '../../../../images/botany_icon.png';
import All from '../../../../images/all_icon.png';
import foundationExams from '../../../../images/foundation_exams.png'
import './_foundationexams.scss'
import moment from 'moment';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import * as Cookies from "es-cookie";
import * as compose from 'lodash.flowright';
import { withRouter } from "react-router-dom";
import EamcetRestrictionAlert from "../../home/EamcetRestrictionAlert";
import UserRestrictionAlert from "../../home/UserRestrictionAlert";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";


class FoundationExams extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedSubjectVal: 0,
            getSubjectsData: [],
            TestType: "single",
            userRestionModalShow: false,
            defaultGroup: Cookies.get("examid") == "3" ? 8 : Cookies.get("examid") == "6" ? 9 : Cookies.get("examid") == "7" ? 10 : Cookies.get("examid") == "8" ? 11 : Cookies.get("examid") == "1" ? 12 : 13,
            mobile: Cookies.get("mobile"),
            foundationExamsArray: [],
            defaultclass: 1,
            classId: this.props?.location?.state?.classValue == "" || this.props?.location?.state?.classValue == undefined ? 1 : this?.props?.location?.state?.classValue,
        }
        localStorage.setItem("resumeExam", false);
        localStorage.setItem("uid", 0);
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }

    }

    componentDidMount = () => {
        console.log("klklklklkl")

        if (this.props?.location?.state?.classValue == undefined && this.props?.location?.state?.syllabusType == undefined) {
            this.getExamsdata();
        }

        localStorage.setItem("resumeExam", false);

        if (this.props.stateData.getSubjectsArr != undefined || this.props.stateData.getSubjectsArr != null) {
            const getSubjects = this.props.stateData.getSubjectsArr.map((gmap, index) => {
                if (index == 0) {
                    return { ...gmap, active: "active" }
                }
                else {
                    return { ...gmap, active: "" }
                }
            })
            this.setState({
                getSubjectsData: getSubjects
            });
        }
    }

    getExamsdata = async () => {
        const result = await this.props.client.query({
            query: gql`
                    query($mobile:String,$exam_group_id:Int){
                        getGroupWiseExams(mobile: $mobile,exam_group_id: $exam_group_id){
                            paidExams{
                                    id
                                    exam_name
                                    exam_type
                                    exam_session_id
                                    sub_exam_type
                                    is_completed
                                    is_started
                                    title
                                    short_name
                                    amount
                                    speed
                                    accuracy
                                    correct_marks
                                    negative_marks
                                    total_marks
                                    start_time
                                    end_time
                                    is_purchased
                                    exam_started
                                    is_scheduled
                                    is_registered
                                    exam_expired
                                    exam_duration
                                    syllabus
                                    class_id
                                    exam_class_id
                                    syllabus_desc
                                    subject_id
                                    enabled_XI
                                     enabled_XII
                                }   
                    }
                    }
                    `,
            variables: {
                mobile: this.state.mobile,
                exam_group_id: this.state.defaultGroup
            },
            fetchPolicy: 'network-only'
        })

        if (result.data.getGroupWiseExams[0].paidExams.length > 0) {
            this.setState({
                foundationExamsArray: result.data.getGroupWiseExams[0].paidExams
            })
        }
    }

    selectHandleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name == "testType") {
            if (value == "1") {
                this.setState({
                    TestType: "single"
                })
            } else {
                this.setState({
                    TestType: "cumulative"
                })
            }
        }
    }


    subjectClassName(data) {
        let classname = "";
        if (data.subject == "Botany") {
            classname = `block_white botany mobile_card_eamcet  ${data.active}`
        }
        else if (data.subject == "Physics") {
            classname = `block_white physics mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Chemistry") {
            classname = `block_white chemistry mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Zoology") {
            classname = `block_white zoology mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "Maths") {
            classname = `block_white maths mobile_card_eamcet ${data.active}`
        }
        else if (data.subject == "All") {
            classname = `block_white all mobile_card_eamcet ${data.active}`
        }
        return classname;
    }

    handleSelect = (subid) => {
        this.setState({

        });
        const getSubjects = this.props.stateData.getSubjectsArr.map((gmap, index) => {
            if (gmap.id == subid) {
                return { ...gmap, active: "active" }
            }
            else {
                return { ...gmap, active: "" }
            }
        })
        this.setState({
            getSubjectsData: getSubjects,
            selectedSubjectVal: subid,
        });
    }


    startExam = (data) => {
        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", examname);
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        localStorage.setItem("resumeExam", false);
        localStorage.setItem("examtype_pattern", "foundation_exam");


        if (isMobile == true && Cookies.get("institution_id") == 30) {
            window.location.replace("/student/subject/exam");
        } else {
            window.open("/student/subject/exam", "_blank")
        }

    }

    resumeExam = (data) => {
        let examname = "";
        if (data.short_name != "") {
            examname = data.exam_name + " (" + data.short_name + ")";
        }
        else {
            examname = data.exam_name;
        }

        localStorage.setItem("sessionid", "0");
        localStorage.setItem("type", examname);
        localStorage.setItem("stype", "schedule_exam");
        localStorage.setItem("exam_paper_id", data.id);
        localStorage.setItem("etype", "schedule");
        localStorage.setItem("examname", examname);
        localStorage.setItem("examtype_pattern", "foundation_exam");
        localStorage.setItem("resumeExam", true);


        if (isMobile == true && Cookies.get("institution_id") == 30) {
            window.location.replace("/student/subject/exam");
        } else {
            window.open("/student/subject/exam", "_blank")
        }
    }
    handleResultFunction = (id) => {
        this.props.history.push({
            pathname: "/student/subject/mock-exam-result",
            state: {
                sessionid: id,
                examtype: "foundation_exam",
                classValue: this.state.classId,
                foundationExams: this.props?.location?.state?.foundationExams
            }
        })
    }

    getAccessPlans = (planId, class_id) => {
        const epriryDateInunixTime = this.isStudentUserValid.expiry_date;
        const currentDateInUnixTime = parseInt((new Date().getTime() / 1000).toFixed(0))


        if(this.props?.location?.state?.foundationExams == "" || this.props?.location?.state?.foundationExams == undefined ){
            if (class_id == 1) {
                if (this.state.foundationExamsArray.length > 0) {
                    if (this.state.foundationExamsArray[0].enabled_XI == true) {
                        return false
                    } else {
                        return true
                    }
                }
    
            } else if (class_id == 2) {
                if (this.state.foundationExamsArray.length > 0) {
                    if (this.state.foundationExamsArray[0].enabled_XII == true) {
                        return false
                    } else {
                        return true
                    }
                }
            }
        }else{
            if (class_id == 1) {
                if (this.props?.location?.state?.foundationExams.length > 0) {
                    if (this.props?.location?.state?.foundationExams[0].enabled_XI == true) {
                        return false
                    } else {
                        return true
                    }
                }
    
            } else if (class_id == 2) {
                if (this.props?.location?.state?.foundationExams.length > 0) {
                    if (this.props?.location?.state?.foundationExams[0].enabled_XII == true) {
                        return false
                    } else {
                        return true
                    }
                }
            }
        }
        
    }

    modalFunAssignments = () => {
        this.setState({
            userRestionModalShow: true,
        });
    }



    render() {

        let currentTime_currentSec = Math.round((new Date()).getTime() / 1000);

        let foundationExamsData = [];

        if (this.props?.location?.state?.foundationExams == "" || this.props?.location?.state?.foundationExams == undefined) {
            foundationExamsData = this.state.foundationExamsArray
        } else {
            foundationExamsData = this.props.location.state.foundationExams;
        }

        // let foundationExamsData = this.props.location.state.foundationExams

        let examsList = []

        if (this.state.selectedSubjectVal != 0 && this.state.TestType == "single") {
            examsList = foundationExamsData.filter((item) => item.subject_id == this.state.selectedSubjectVal && item.class_id == this.state.classId && item.exam_class_id == 1)
        }
        else if (this.state.selectedSubjectVal != 0 && this.state.TestType == "cumulative") {
            examsList = foundationExamsData.filter((item) => item.subject_id == this.state.selectedSubjectVal && item.class_id == this.state.classId && item.exam_class_id == 0)
        }
        else if (this.state.selectedSubjectVal == 0 && this.state.TestType == "single") {
            examsList = foundationExamsData.filter((item) => item.class_id == this.state.classId && item.exam_class_id == 1)
        }
        else if (this.state.selectedSubjectVal == 0 && this.state.TestType == "cumulative") {
            examsList = foundationExamsData.filter((item) => item.class_id == this.state.classId && item.exam_class_id == 0)
        }


        if (examsList == "" && this.state.selectedSubjectVal != 0 && this.state.TestType == "single") {
            examsList = this.state.foundationExamsArray.filter((item) => item.subject_id == this.state.selectedSubjectVal && item.class_id == this.state.defaultclass && item.exam_class_id == 1)
        }
        else if (examsList == "" && this.state.selectedSubjectVal != 0 && this.state.TestType == "cumulative") {
            examsList = this.state.foundationExamsArray.filter((item) => item.subject_id == this.state.selectedSubjectVal && item.class_id == this.state.defaultclass && item.exam_class_id == 0)
        }
        else if (examsList == "" && this.state.selectedSubjectVal == 0 && this.state.TestType == "single") {
            examsList = this.state.foundationExamsArray.filter((item) => item.class_id == this.state.defaultclass && item.exam_class_id == 1)
        }
        else if (examsList == "" && this.state.selectedSubjectVal == 0 && this.state.TestType == "cumulative") {
            examsList = this.state.foundationExamsArray.filter((item) => item.class_id == this.state.defaultclass && item.exam_class_id == 0)
        }








        return (
            <Container className='foundation_exams'>
                <Row>
                    <Col sm={12} className="mt-2">
                        <div className='subject-blocks-view'>
                            {
                                this.state.getSubjectsData.map(sub =>
                                    <div className={this.subjectClassName(sub)} onClick={() => this.handleSelect(sub.id)}  >
                                        <Row>
                                            <Col sm={6}>
                                                <h5 className='mt-2'>{sub.subject}</h5>
                                            </Col>
                                            <Col sm={6}>
                                                {
                                                    sub.id == "0" ?
                                                        (<Card.Img variant="top" src={All} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "1" ?
                                                        (<Card.Img variant="top" src={Botany} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "2" ?
                                                        (<Card.Img variant="top" src={Physics} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "3" ?
                                                        (<Card.Img variant="top" src={Chemistry} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "5" ?
                                                        (<Card.Img variant="top" src={Zoology} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                                {
                                                    sub.id == "4" ?
                                                        (<Card.Img variant="top" src={Maths} className="mobile_sub_img" alt="subject" />) : ("")
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            }
                        </div>
                    </Col>
                </Row>

                <Row className='mt-5'>
                    <Col sm={6}>
                        <div className="custom-control custom-radio custom-control-inline margin_right0">
                            <input
                                type="radio"
                                id="subject_test"
                                value="1"
                                name="testType"
                                className="custom-control-input"
                                defaultChecked={true}
                                onChange={this.selectHandleInputChange}
                            />
                            <label
                                className="custom-control-label input_text"
                                htmlFor="subject_test"
                            >
                                Chapter Test
                            </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline margin_right0 right_pos">
                            <input
                                type="radio"
                                id="cumulative_test"
                                value="2"
                                name="testType"
                                className="custom-control-input"
                                onChange={this.selectHandleInputChange}

                            />
                            <label
                                className="custom-control-label input_text"
                                htmlFor="cumulative_test"
                            >
                                Cumulative Test
                            </label>
                        </div>
                    </Col>
                </Row>

                <Row className='mt-1'>
                    {
                        examsList.length > 0 ?
                            (
                                examsList.map((item) => {
                                    return (
                                        <Col sm={6} className="mt-2">
                                            <Card className="card-border-exams">
                                                <Card.Body>
                                                    <Media>
                                                        <div className="date_block mr-3 text-center">
                                                            <p className="mt-1 f-22 border-bootom2"> {moment.unix(item.start_time).format("MMM")}</p>
                                                            <p className="mt-2 f-22">{moment.unix(item.start_time).format("DD")}</p>
                                                        </div>
                                                        <Media.Body>
                                                            <Card.Title><h6 className="f-14">{item.exam_name}</h6></Card.Title>

                                                            <Row>
                                                                <Col sm={12}>
                                                                    <p className="f-14">Time : {moment.unix(item.start_time).format("LT")}</p>
                                                                    <p className="f-14 pos_top"> Duration : {item.exam_duration}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={6}>
                                                                    {/* <Button type="button" className="btn btn-blue-exam" onClick={() => this.handleShowModal(item)}>View Syllabus</Button>*/}
                                                                </Col>
                                                                <Col sm={6} className=''>
                                                                    {
                                                                        item.exam_expired == true ?
                                                                            ("")
                                                                            :
                                                                            (
                                                                                <>
                                                                                    {
                                                                                        item.is_completed == true ?
                                                                                            (
                                                                                                <Button type="button" className="btn btn-custom-blue float-right-resp" onClick={() => this.handleResultFunction(item.exam_session_id)}>View Results </Button>
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                item.is_started == true && currentTime_currentSec > item.start_time ?
                                                                                                    (<Button type="button" className="btn btn-custom-blue float-right-resp" onClick={() => this.resumeExam(item)}>Resume Test</Button>) :

                                                                                                    (
                                                                                                        this.getAccessPlans(this.isStudentUserValid.current_plan_id, this.state.classId) ?
                                                                                                           
                                                                                                            (
                                                                                                                item.exam_started == true ?
                                                                                                                    (<Button type="button" className="btn btn-custom-blue float-right-resp" onClick={() => this.startExam(item)}>Start Now</Button>)
                                                                                                                    :
                                                                                                                    (<Button type="button" className="btn btn-custom-blue float-right-resp disabledCursor" disabled>Start Now</Button>)
                                                                                                            )
                                                                                                            :
                                                                                                            (<Button type="button" className="btn btn-custom-subscribe float-right-resp" onClick={() => this.modalFunAssignments(item)}>Subscribe</Button>)
                                                                                                            
                                                                                                    )
                                                                                            )
                                                                                    }
                                                                                </>
                                                                            )
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Media.Body>
                                                    </Media>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })
                            )
                            :
                            (
                                <Col>
                                    <h5 className='text-center exams_text'>Tests will be available soon</h5>
                                </Col>


                            )

                    }
                </Row>
                {
                    Cookies.get("examid") == 1 || Cookies.get("examid") == 2 ?
                        (
                            <UserRestrictionAlert
                                show={this.state.userRestionModalShow}
                                onHide={() => this.setState({ userRestionModalShow: false })}
                                planDetailsShow="Neet_Jee_foundation"
                            />
                        )
                        :
                        (
                            <EamcetRestrictionAlert
                                show={this.state.userRestionModalShow}
                                onHide={() => this.setState({ userRestionModalShow: false })}
                            />
                        )
                }

            </Container>
        )
    }
}

export default withRouter(withApollo(compose(

)(FoundationExams)));